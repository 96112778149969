<template>
  <div>
    <button
      type="button"
      :class="[disabled ? 'text-gray-500' : 'text-blue-500']"
      :disabled="disabled"
      @click="toggle">
      <slot name="lable" />
      <b-icon
        icon-name="chevron"
        class="ml-1 transform font-medium"
        :class="{ rotateUp: isOpen }" />
    </button>
    <div v-if="isOpen">
      <div class="mt-4 text-xs text-gray-600">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapse'
};
</script>

<script setup>
import { defineProps, ref } from 'vue';

import BIcon from '../../atoms/Icon';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});
const isOpen = ref(false);
const toggle = () => {
  isOpen.value = !isOpen.value;
};
</script>
<style>
.rotateUp {
  transform: rotate(0.5turn);
  padding-bottom: 2px;
}

.transform {
  transition: transform 0.3s;
}
</style>
