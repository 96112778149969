import axios from 'axios';
import CryptoJS from 'crypto-js';
import { format } from 'date-fns';

import { ShipmentCreatedBy } from '@/constants';
import { ShipmentEventType, ShipmentPackageEventType } from '@/enums';
import { executeRequest, isCarrierAssignedToShipment, withLoading } from '@/utils';

const state = {
  items: [],
  shipmentDetails: {},
  selectedPackageId: null,
  fulfillmentItems: {},
  loading: false,
  error: ''
};

const getters = {
  getItemsByFulfillmentId:
    (state) =>
    (id, raw = false) => {
      const items = state.fulfillmentItems[id];

      if (raw) {
        return items;
      }

      return items || [];
    },
  getItemsByOrderId: (state, getters, rootState) => () => {
    return rootState['fulfillments'].items.reduce((result, { id }) => {
      result.push(...getters.getItemsByFulfillmentId(id));

      return result;
    }, []);
  },
  getPODs: (state) => {
    const events = state.shipmentDetails?.shipment_events;

    if (!events) {
      return [];
    }

    const event = events.find((event) => event.proof_of_delivery?.length);

    const shipmentPODs = event?.proof_of_delivery ?? [];

    const packages = state.shipmentDetails?.shipments_packages;

    const packagePODs =
      packages?.flatMap((pkg) =>
        pkg.events?.flatMap((event) => (event.proof_of_delivery?.length ? event.proof_of_delivery : []))
      ) ?? [];

    return [...packagePODs, ...shipmentPODs];
  },
  isProgressBarInShipmentMode: (state) => {
    const packages = state.shipmentDetails?.shipments_packages;
    const trackingNumber = state.shipmentDetails?.tracking_number;

    if (!packages) {
      return true;
    }

    if (packages.every((p) => p.tracking_number === trackingNumber)) {
      return true;
    }

    // if every package doesn't have any events, consider that only shipment has them
    return packages.every((p) => !p.events?.length);
  },
  getEventsForProgressBar: (state, getters, rootState, rootGetters) => {
    const getEvents = () => {
      const sortByTimestamp = (b, a) => new Date(a.timestamp) - new Date(b.timestamp);

      if (!getters['isProgressBarInShipmentMode']) {
        const packages = state.shipmentDetails?.shipments_packages;

        if (!packages) {
          return [];
        }

        const pkg = packages.find((p) => p.id === state.selectedPackageId);

        return (
          pkg?.events
            ?.sort(sortByTimestamp)
            .filter((item) =>
              [ShipmentPackageEventType.StatusUpdate, ShipmentPackageEventType.Checkpoint].includes(item.type)
            ) ?? []
        );
      } else {
        const events = state.shipmentDetails?.shipment_events;

        if (!events) {
          return [];
        }

        return [...events]
          .sort(sortByTimestamp)
          .filter((item) => [ShipmentEventType.StatusUpdate, ShipmentEventType.Checkpoint].includes(item.type));
      }
    };

    return getEvents().map((item) => {
      const shippingAccount = rootGetters['shippingAccounts/getShippingAccountById'](item.shipping_account_id);

      return {
        ...item,
        shipping_account_name: shippingAccount?.name,
        carrier_logo: shippingAccount?.carrier_logo,
        carrier: shippingAccount?.carrier_name
      };
    });
  }
};

const actions = {
  async loadShipments(options, params) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/shipments`,
        params
      },
      options,
      errorMessage: 'SHIPMENT_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setShipments', response.data);
      options.commit('setShipmentsTotal', Number(response.headers['total-count']));
    });
  },

  async filterShipments(options, data) {
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/shipments/filter`,
        data
      },
      options,
      errorMessage: 'SHIPMENT_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setShipments', response.data);
      options.commit('setShipmentsTotal', Number(response.headers['total-count']));
    });
  },

  async updateShipment(options, { orderId, fulfillmentId, shipmentId, data, waitForCallback }) {
    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/shipments/${shipmentId}`,
        data
      },
      options,
      errorMessage: 'SHIPMENT_UPDATE_FAIL',
      waitForCallback
    };

    return await executeRequest(request, async (response) => {
      options.commit('updateShipmentDetails', response.data);
    });
  },

  async cancelShipment(options, { orderId, fulfillmentId, shipmentId, data }) {
    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/shipments/${shipmentId}/cancel`,
        data
      },
      options,
      errorMessage: 'SHIPMENT_CANCELLATION_FAIL'
    };

    await executeRequest(request);
  },

  async loadOrderShipments(options, orderId) {
    await options.dispatch('fulfillments/loadOrderFulfillments', orderId, { root: true });

    const fulfillments = options.rootState['fulfillments'].items;

    await Promise.all(
      fulfillments.map(({ id: fulfillmentId }) =>
        options.dispatch('loadFulfillmentShipments', {
          orderId,
          fulfillmentId
        })
      )
    );
  },

  async simulateNextStatus(options, data) {
    const createHmac = (body, secret) => {
      return CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        .update(body)
        .finalize()
        .toString(CryptoJS.enc.Base64);
    };
    const payload = {
      checkpoints: data.checkpoints,
      carrier: data.carrier,
      delivered: data.delivered,
      delivery_date: format(new Date(), 'yyyy-MM-dd'),
      status: data.status,
      shipping_account: data.shippingAccount,
      tracking_number: data.trackingNumber,
      tracking_stage: data.trackingStage
    };
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/carrier_service/update_status`,
        data: payload,
        headers: {
          'X-Zenkraft-Hmac-SHA256': createHmac(
            JSON.stringify(payload),
            options.rootGetters['auth/deliveryhubAccountKey']
          )
        }
      },
      options,
      errorMessage: 'SIMULATE_NEXT_STATUS_FAIL'
    };

    await executeRequest(request);

    // const response = await fetch(`${options.rootGetters['auth/carrierServiceApiUrl']}/track`, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     zkkey: options.rootGetters['auth/deliveryhubAccountKey']
    //   },
    //   body: JSON.stringify({
    //     checkpoints: data.checkpoints,
    //     carrier: data.carrier,
    //     delivered: data.delivered,
    //     delivery_date: format(new Date(), 'yyyy-MM-dd'),
    //     status: data.status,
    //     shipping_account: data.shippingAccount,
    //     tracking_number: data.trackingNumber,
    //     tracking_stage: data.trackingStage
    //   })
    // });

    // if (response.status !== 200) {
    //   throw Error(`Request failed with ${response.status}`);
    // }

    // return await response.json();
  },

  async clearShipments(options) {
    options.commit('setShipments', []);
    options.commit('setShipmentsTotal', 0);
  },

  async loadFulfillmentShipments(options, { orderId, fulfillmentId }) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/shipments`
      },
      options,
      errorMessage: 'FULFILLMENT_SHIPMENTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) =>
      options.commit('setFulfillmentShipments', { fulfillmentId, value: response.data })
    );

    options.state.loading = true;
    const shipments = options.state.fulfillmentItems[fulfillmentId] || [];

    const shippingAccounts = {};

    for (let shipment of shipments) {
      const shippingAccountId = shipment.shipping_account_id;

      if (shippingAccountId) {
        const shippingAccount =
          shippingAccounts[shippingAccountId] ||
          (await options.dispatch('shippingAccounts/loadShippingAccountById', shippingAccountId, { root: true }));

        shippingAccounts[shippingAccountId] = shippingAccount;

        shipment.carrier = shippingAccount.carrier;
        shipment.carrier_logo = `https://cdn.zenkraft.com/static/images/carriers/${shippingAccount.carrier}.png`;
      }
    }

    options.state.loading = false;
  },

  async loadShipmentDetail({ commit, state, rootGetters, rootState, dispatch }, { orderId, fulfillmentId, id }) {
    commit('clearError');
    const apiUrl = rootGetters['auth/apiUrl'];

    await withLoading(state, async () => {
      try {
        const shipment = await axios.get(
          `${apiUrl}/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/shipments/${id}`
        );

        commit('setShipmentDetails', shipment.data);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(error);
        }

        state.error = 'SHIPMENT_DETAILS_LOADING_FAIL';
      }
    });
  },

  async createShipment(
    options,
    { orderId, fulfillmentId, selectedCarrier, packages, isPrinted, disableShippingRules, labelType, sourceId }
  ) {
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/shipments/`,
        data: {
          disable_shipping_rules: disableShippingRules,
          shipping_account_id: selectedCarrier.id,
          service: selectedCarrier.service,
          packages: packages.map((item) => {
            const result = {
              name: item.name,
              products: item.products.map((item) => ({
                ...item.product,
                count: item.count
              }))
            };

            const isProductWeightNull = item.products.every((item) => item.product?.weight === null);

            if (isProductWeightNull) {
              result['package_weight'] = item.weight;
              result['weight_units'] = item.weight_units;
            }

            return result;
          }),
          type: 'delivery',
          dim_units: 'cm',
          label_type: labelType,
          source_id: sourceId,
          created_by: ShipmentCreatedBy.Packer
        }
      },
      options,
      errorMessage: 'SHIPMENT_CREATION_FAIL',
      errorHandler(error) {
        if (
          error.response?.data?.errors?.reason === 'Duplicate record' &&
          error.response?.data?.data?.shipment_source_ids_duplicated
        ) {
          return 'CARRIER_ASSIGN_MODAL.DUPLICATE_SOURCE_ID';
        }

        return error?.response?.data?.errors?.reason || error?.response?.data?.title || 'SHIPMENT_CREATION_FAIL';
      }
    };

    return await executeRequest(request, async (response) => {
      options.commit('addPackage', response.data);

      if (isPrinted) {
        if (!isCarrierAssignedToShipment(response.data)) {
          return;
        }

        const { id: shipmentId } = response.data;

        await options.dispatch('printing/print', { shipmentId }, { root: true });
      }
    });
  },
  async retriggerWebhook(options, { id }) {
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/shipments/retrigger-webhook`,
        data: {
          shipment_event_id: id
        }
      },
      options
    };

    return await executeRequest(request, () => null);
  },

  selectPackage(options, packageId) {
    options.commit('selectPackage', packageId);
  }
};

const mutations = {
  setShipments(state, shipments) {
    state.items = shipments;
  },

  setShipmentsTotal(state, count) {
    state.total = count;
  },

  addPackage(state, value) {
    state.items.push(value);
  },

  setFulfillmentShipments(state, { fulfillmentId, value }) {
    state.fulfillmentItems[fulfillmentId] = value;
  },

  updateShipmentDetails(state, data) {
    state.shipmentDetails = {
      ...state.shipmentDetails,
      ...data
    };
  },

  setShipmentDetails(state, details) {
    state.shipmentDetails = details;
  },

  clearError(state) {
    state.error = '';
    state.modalError = '';
  },
  selectPackage(state, packageId) {
    state.selectedPackageId = packageId;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
