<template>
  <div :class="['w-full y-full', !active && 'hidden']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BTab'
};
</script>

<script setup>
import { computed, inject, onBeforeMount, onBeforeUnmount } from 'vue';

import { ActiveTabHashKey, RegisterTabKey, UnregisterTabKey } from './constants';

const props = defineProps({
  title: {
    type: String,
    required: true
  }
});

const hash = computed(() => '#' + props.title.toLowerCase().replaceAll(' ', '-'));

const registerTab = inject(RegisterTabKey);
const unregisterTab = inject(UnregisterTabKey);
const activeHash = inject(ActiveTabHashKey);

const active = computed(() => hash.value === activeHash.value);

onBeforeMount(() => {
  registerTab({
    hash: hash.value,
    title: props.title
  });
});

onBeforeUnmount(() => unregisterTab(hash));
</script>
