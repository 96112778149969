const storage = {};

const localStorageWrapper = {
  getItem: function (key) {
    return storage[key] || null;
  },
  setItem: function (key, value) {
    storage[key] = value;
  },
  removeItem: function (key) {
    delete storage[key];
  },
  clear: function () {
    for (const prop of Object.getOwnPropertyNames(storage)) {
      delete storage[prop];
    }
  }
};

export const fallbackLocalStorage = () => {
  Object.defineProperty(window, 'localStorage', {
    value: localStorageWrapper
  });
};
