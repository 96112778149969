<template>
  <div
    data-id="logsFilters"
    class="flex justify-between bg-white pb-3.5 pt-3">
    <div class="flex justify-left bg-white">
      <div class="flex items-center ml-8 w-69 whitespace-nowrap">
        <b-multi-select
          data-id="statusCodeFilter"
          with-search
          :options="statusCodesOptions"
          :label="t('FILTER.STATUS_CODE.NAME')"
          :placeholder-text="t('FILTER.STATUS_CODE.PLACEHOLDER_TEXT')"
          :all-placeholder-name="t('FILTER.STATUS_CODE.PLACEHOLDER_NAME')"
          with-badge
          :with-all="false"
          @select="handleSelectStatusCodes" />
      </div>
    </div>
  </div>
  <div class="pl-10 pr-6 pt-6 flex h-full flex-col overflow-hidden">
    <ModalLogDetails
      :value="state.logDetails"
      :is-shown="state.isShownModal"
      @close="state.isShownModal = false"></ModalLogDetails>
    <alert
      v-if="error"
      class="mb-8"
      type="danger"
      close
      @close="clearError">
      {{ t(error) }}
    </alert>

    <b-table
      class="logs"
      :loading="loading"
      :fields="fields"
      :data="logs"
      pagination
      :page="pageSettings.page"
      :limit="pageSettings.limit"
      :total="total"
      :last-page="pageSettings.page * pageSettings.limit >= total"
      row-key="request_id"
      last-update
      @update:page-settings="updatePage">
      <template #cell(url)="{ row }">
        <span
          class="text-blue-400 hover:text-blue-600 cursor-pointer"
          @click="showModal(row)">
          {{ row.pathname }}
        </span>
      </template>
      <template #cell(statusCode)="{ row }">
        <icon
          class="text-xl"
          :class="getStatusCodeClass(row.status_code)"
          :icon-name="BringgFontIcons.SmallCircle" />
        {{ row.status_code }}
      </template>
      <template #cell(ip)="{ row }">
        {{ row.ip }}
      </template>
      <template #cell(date)="{ row }">
        {{ formatDateTime(row.created_at) }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'Logs'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { StatusCodes } from 'http-status-codes';
import { useTranslation } from 'i18next-vue';
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';

import { usePagination } from '@/composables';

import { useFiltersAndSearch } from '../../../composables';
import { formatDateTime } from '../../../utils';
import Alert from '../../atoms/Alert';
import Icon from '../../atoms/Icon';
import BMultiSelect from '../../molecules/MultiSelect';
import ModalLogDetails from '../../organisms/ModalLogDetails';
import BTable from '../../organisms/Table';

const statusCodesOptions = computed(() => {
  return Object.keys(StatusCodes)
    .filter((statusCode) => typeof StatusCodes[statusCode] !== 'number')
    .map((statusCode) => ({ value: statusCode, label: statusCode, type: getStatusCodeBadgeType(statusCode) }));
});

const props = defineProps({
  page: {
    type: Number,
    required: true
  },
  limit: {
    type: [Number, null],
    required: true
  }
});

const store = useStore();
const { t } = useTranslation();
const { pageSettings, updatePage } = usePagination('logs/filterLogs');
const { handleBasicFilterOption } = useFiltersAndSearch({
  props,
  updatePage,
  pageSettings
});

const state = reactive({
  logDetails: {},
  isShownModal: false
});

const handleSelectStatusCodes = async (statusCodes) => {
  await handleBasicFilterOption({ status_code: statusCodes.map((statusCode) => Number(statusCode)) });
};

onMounted(async () => {
  const { page, limit } = props;

  await updatePage({ page, limit });
});

const logs = computed(() => store.state.logs.items);
const total = computed(() => store.state.logs.total);
const loading = computed(() => store.state.logs.loading);
const error = computed(() => store.state.logs.error);

const fields = computed(() => [
  { key: 'url', label: 'LOGS.URL_FIELD' },
  { key: 'statusCode', label: 'LOGS.STATUS_CODE_FIELD' },
  { key: 'ip', label: 'LOGS.IP_FIELD' },
  { key: 'date', label: 'LOGS.DATE_FIELD' }
]);

const clearError = () => store.commit('logs/clearError');

const getStatusCodeClass = (statusCode) => {
  return ['4', '5'].includes(String(statusCode)[0]) ? 'text-red-500' : 'text-green-500';
};

const getStatusCodeBadgeType = (statusCode) => {
  return ['4', '5'].includes(String(statusCode)[0]) ? 'danger' : 'success';
};

const showModal = (row) => {
  state.logDetails = {
    ...row
  };
  state.isShownModal = true;
};
</script>
