<template>
  <div class="flex flex-row mr-2">
    <div
      class="package-container flex-1"
      :class="[isSelected ? 'selected' : 'bg-white', !isProgressBarInShipmentMode && 'cursor-pointer']"
      @click="selectPackage">
      <div class="mt-0.5 flex flex-row items-center w-full">
        <span
          class="cursor-pointer"
          @click.stop="toggle">
          <icon
            :icon-name="collapsed ? 'chevron' : 'chevron-up'"
            class="w-4 h-4" />
        </span>
        <span class="ml-2 package-title">
          {{ title }}
          <span class="package-subtitle">{{ subTitle }}</span>
        </span>
        <div
          v-if="packageItem.status"
          class="ml-auto">
          <ShipmentPackageStatus :status="packageItem.status" />
        </div>
      </div>
      <div class="flex flex-row items-center ml-6">
        <span class="package-name">{{ packageName }}</span>
        <PackageDetailsPopover :package-item="packageItem" />
      </div>
      <transition>
        <div
          v-if="!collapsed"
          class="w-full mt-4 pl-6 pr-4">
          <div
            v-for="(item, productIndex) in packageItem?.shipments_products"
            :key="productIndex">
            <div class="flex items-center product">
              <div
                v-show="loadedImages[item.id]"
                class="w-6 h-6 flex items-center">
                <img
                  :src="item.product.image_url"
                  @load="() => loadImage(item.id)" />
              </div>
              <div class="flex-1 flex justify-between items-center px-2">
                <div>
                  <div class="product-name">{{ item.product.name }}</div>
                  <div
                    v-if="item?.product.sku"
                    class="text-gray-600">
                    {{ item.product.sku }}
                  </div>
                  <special-services :services="item?.product?.special_services"></special-services>
                </div>
                <div class="px-4 product-count">
                  {{ item.count }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div
      class="mt-6 beak"
      :class="!isSelected && 'invisible'" />
  </div>
</template>

<script>
export default {
  name: 'CollapsiblePackageDetails'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, ref } from 'vue';
import { useStore } from 'vuex';

import Icon from '@/components/atoms/Icon';
import ShipmentPackageStatus from '@/components/atoms/ShipmentPackageStatus';
import SpecialServices from '@/components/atoms/SpecialServices/index.vue';
import PackageDetailsPopover from '@/components/molecules/PackageDetailsPopover';

const { t } = useTranslation();

const props = defineProps({
  packageItem: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  collapsed: {
    type: Boolean,
    default: true
  }
});

const store = useStore();

const emit = defineEmits(['toggle', 'select']);

const itemsLength = computed(() =>
  props.packageItem.shipments_products.reduce((acc, product) => acc + product.count, 0)
);

const title = computed(() => `${t('SHIPMENT_DETAILS.PACKAGE')} #${props.index + 1}`);

const subTitle = computed(() => `(${itemsLength.value} ${itemsLength.value === 1 ? 'item' : 'items'})`);

const packageName = computed(() => props.packageItem.package.name);

const isProgressBarInShipmentMode = computed(() => store.getters['shipments/isProgressBarInShipmentMode']);

const selectPackage = () => {
  if (isProgressBarInShipmentMode.value) {
    return;
  }

  emit('select', props.packageItem.id, props.index);
};

const toggle = () => emit('toggle', props.index);

const isSelected = computed(
  () => store.state.shipments.selectedPackageId === props.packageItem.id && !isProgressBarInShipmentMode.value
);

const loadedImages = ref({});

const loadImage = (id) => (loadedImages.value[id] = true);
</script>

<style lang="scss" scoped>
.package-container {
  display: flex;
  padding: var(--Space-200, 16px) var(--Space-200, 16px) var(--Space-200, 16px) var(--Space-100, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-150, 12px);
  flex: 1 0 0;
  border-radius: 4px;
  box-shadow:
    0 2px 4px 0 rgba(0, 51, 80, 0.16),
    0 0 1px 0 rgba(0, 51, 80, 0.08);

  &:not(:last-child) {
    @apply mb-4;
  }
}

.package-title {
  color: var(--Color-Neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.package-subtitle {
  color: var(--Color-Neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.package-name {
  color: var(--Color-Neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.product {
  display: flex;
  padding: 4px 16px var(--Space-50, 4px) 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Color-Neutrals-200, #e1e9f0);
  border-bottom: 1px solid var(--Color-Neutrals-200, #e1e9f0);
  background: var(--Color-Neutrals-White, #fff);
}

.product-name {
  color: var(--Color-Neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.product-count {
  color: var(--Color-Neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.selected {
  border-left: 4px solid var(--Color-Primary-400, #3091ce);
  border-radius: var(--Radius-round-s, 4px);
  background: var(--Color-Primary-25, #edf5fd);
}

.beak {
  border-width: 8px;
  width: 20px;
  height: 10px;
  border-style: solid;
  border-color: transparent transparent transparent var(--Color-Primary-25, #edf5fd);
}
</style>
