<template>
  <b-modal
    :value="isOpen"
    @close="onClose">
    <template #title>
      <h3 class="px-6 py-4 text-lg leading-6 font-medium text-gray-900">{{ t('HTML_EDITOR_MODAL.TITLE') }}</h3>
      <b-divider />
    </template>

    <template #body>
      <div class="px-6">
        <v-form
          ref="form"
          :initial-values="{ subject, html }"
          :validation-schema="schema">
          <mentionable
            :limit="variables.length"
            :keys="['@']"
            offset="6"
            :items="variables">
            <b-input
              class="mb-4"
              name="subject"
              label="Subject" />
          </mentionable>

          <field
            v-slot="{ field, errorMessage }"
            name="html">
            <label class="block text-xs text-gray-600 mb-1">Body</label>

            <mentionable
              :limit="variables.length"
              :keys="['@']"
              offset="6"
              :items="variables">
              <code-editor
                font-size="13px"
                width="100%"
                border-radius="8px"
                height="414px"
                :languages="[['vbscript-html', 'HTML']]"
                :header="false"
                theme="hybrid"
                :model-value="field.value"
                @update:model-value="field.onChange" />
            </mentionable>

            <p
              v-if="errorMessage"
              class="mt-2 text-sm text-red-600">
              {{ errorMessage }}
            </p>
          </field>
        </v-form>
      </div>
    </template>

    <template #footer>
      <div class="mt-5 md:mt-4 md:flex md:flex-row-reverse px-6 py-2 shadow-top">
        <b-button
          class="px-6"
          @click="onSubmit">
          <b-icon
            v-if="state.isSanitizing"
            class="text-lg"
            :icon-name="BringgFontIcons.Loader"
            spin />

          <span v-else>
            {{ t('MODAL.SAVE') }}
          </span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalHtmlEmailEditor'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import purify from 'dompurify';
import { useTranslation } from 'i18next-vue';
import CodeEditor from 'simple-code-editor';
import { Field, Form as VForm } from 'vee-validate';
import { defineEmits, defineProps, reactive, ref } from 'vue';
import { Mentionable } from 'vue-mention';
import * as yup from 'yup';

import BIcon from '@/components/atoms/Icon/index.vue';
import BInput from '@/components/atoms/Input/index.vue';
import { delay } from '@/utils';

import BButton from '../../atoms/Button/index.vue';
import BDivider from '../../atoms/Divider/index.vue';
import BModal from '../../organisms/Modal/index.vue';

const { t } = useTranslation();

defineProps({
  html: {
    type: String,
    required: false,
    default: ''
  },
  subject: {
    type: String,
    required: false,
    default: ''
  },
  isOpen: {
    type: Boolean,
    required: false,
    default: false
  },
  variables: {
    type: Array,
    required: false,
    default: () => []
  }
});

const form = ref(null);

const state = reactive({
  isSanitizing: false
});

const schema = {
  subject: yup.string().required().label('Subject'),
  html: yup.string().required().label('Html')
};

const emit = defineEmits(['save', 'close']);

const onSubmit = async () => {
  const { getValues, setFieldValue, validate } = form.value;
  const { valid } = await validate();

  if (!valid) {
    return;
  }

  state.isSanitizing = true;
  await delay(500);

  setFieldValue('html', purify.sanitize(getValues().html));

  await delay(1000);
  state.isSanitizing = false;

  emit('save', getValues());
};

const onClose = async () => {
  emit('close');
};
</script>
