import { LexoRank } from 'lexorank';
import { isArray, isEmpty, isNil, isObject } from 'lodash';

import { RULE_TYPE, TRIGGERS } from '@/components/organisms/Rule/constants';

export const serializeData = (data) => {
  const serialized = {
    ...data
  };

  serialized.services = serialized.services.map((item) => {
    const [shipping_account_id, service] = item.split('::');

    return {
      shipping_account_id: Number(shipping_account_id),
      service
    };
  });

  serialized.attributes = serialized.attributes
    .filter((attribute) => attribute.operator)
    .map((attribute) => {
      const { type } = TRIGGERS[attribute.path];
      let { value, operator } = attribute;

      if (type === 'NUMBER') {
        value = value.map(Number);
      }

      if (type !== 'ARRAY' && operator !== 'IN_RANGE') {
        value = attribute.value[0];
      }

      return {
        ...attribute,
        value
      };
    });

  return JSON.parse(JSON.stringify(serialized));
};

export const deserializeData = (data) => {
  const serialized = {
    ...data
  };

  serialized.services = serialized.services.map((item) => `${item.shipping_account_id}::${item.service}`);

  serialized.attributes = serialized.attributes.map((attribute) => {
    const { value } = attribute;

    return {
      ...attribute,
      value: isArray(value) ? value : [value]
    };
  });

  return serialized;
};

export const isValidToSave = (data) => {
  if (data.type === RULE_TYPE.RETURN && !data.attributes?.length) {
    return false;
  }

  if (!data.services?.length) {
    return false;
  }

  return !data.attributes.some(({ value }) => {
    if (isObject(value) || isArray(value)) {
      return isEmpty(value);
    }

    return isNil(value);
  });
};

export const getPriority = (rawList, to, from) => {
  const list = rawList.sort((a, b) => a.priority.localeCompare(b.priority));

  if (!list.length) {
    return LexoRank.middle().toString();
  }

  let rank;

  const toStart = to === 0;
  const toEnd = isNil(to) || to === list.length - 1;
  const between = !toStart && !toEnd;

  if (toStart) {
    const first = LexoRank.parse(list[0].priority);

    rank = first.genPrev();
  }

  if (toEnd) {
    const last = LexoRank.parse(list[list.length - 1].priority);

    rank = last.genNext();
  }

  if (between) {
    const up = to < from;
    const down = to > from;

    let first;
    let second;

    if (up) {
      first = LexoRank.parse(list[to - 1].priority);
      second = LexoRank.parse(list[to].priority);
    }

    if (down) {
      first = LexoRank.parse(list[to].priority);
      second = LexoRank.parse(list[to + 1].priority);
    }

    rank = first.between(second);
  }

  return rank.toString();
};

export const getRuleName = (type, index) => {
  const name = type === RULE_TYPE.SHIPPING ? 'Shipping' : 'Return';

  return `${name} rule ${index}`;
};
