<template>
  <router-link
    :to="to"
    :class="[
      'inline-flex h-full px-2 items-center border-b-2 border-transparent pt-0.5',
      { 'cursor-pointer text-gray-800': !current },
      { 'font-semibold text-blue-500 cursor-default border-blue-500': current }
    ]">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'NavItem'
};
</script>

<script setup>
import { defineProps } from 'vue';

defineProps({
  to: {
    type: [String, Object],
    default: null
  },
  current: {
    type: Boolean,
    default: false
  }
});
</script>
