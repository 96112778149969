<template>
  <nav
    class="flex"
    aria-label="Breadcrumb">
    <ol
      role="list"
      class="flex items-center space-x-4">
      <li
        v-for="(page, index) in value"
        :key="page.label">
        <div class="flex items-center">
          <router-link
            v-if="index === 0"
            :to="{ name: 'orders' }">
            <OrdersIcon
              class="flex-shrink-0 h-5 w-5 text-gray-600"
              aria-hidden="true" />
          </router-link>
          <template v-else>
            <ChevronRightIcon
              class="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true" />
            <router-link
              :to="page.to"
              :class="index !== value.length - 1 ? 'hover:text-gray-700 cursor-pointer' : 'cursor-text'"
              class="ml-4 text-sm font-medium text-gray-500">
              {{ page.label }}
            </router-link>
          </template>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs'
};
</script>

<script setup>
import { ChevronRightIcon } from '@heroicons/vue/solid';
import { defineProps } from 'vue';

defineProps({
  value: {
    type: Array,
    required: true
  }
});
</script>
