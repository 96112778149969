import client from '../api';

class UserService {
  name = 'User';

  async get() {
    const { data } = await client.getUser();

    return [data];
  }

  async put(value) {
    const { data } = await client.putUser(value);

    return data;
  }
}

export default new UserService();
