<template>
  <fieldset>
    <div class="mt-1 -space-y-px rounded-md bg-white shadow-sm">
      <div class="flex -space-x-px">
        <div class="w-1/2 min-w-0 flex-1">
          <label
            for="custom-key"
            class="sr-only">
            {{ t('RETURNS.CUSTOM_KEY_FILTER') }}
          </label>
          <input
            id="custom-key"
            :value="data.key"
            type="text"
            class="relative block w-full rounded-none rounded-bl-md rounded-tl-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            :placeholder="t('RETURNS.CUSTOM_KEY_FILTER')"
            @input="updateKey" />
        </div>
        <div class="min-w-0 flex-1">
          <label
            for="custom-value"
            class="sr-only">
            {{ t('RETURNS.CUSTOM_VALUE_FILTER') }}
          </label>
          <input
            id="custom-value"
            :value="data.value"
            type="text"
            class="relative block w-full rounded-none rounded-br-md rounded-tr-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            :placeholder="t('RETURNS.CUSTOM_VALUE_FILTER')"
            @input="updateValue" />
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'CustomPairFilter'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { defineEmits, defineProps, reactive, watchEffect } from 'vue';

import { createDebounce } from '../../../utils';

const DELIMITER = '::';

const { t } = useTranslation();

const debounce = createDebounce();

const props = defineProps({
  value: {
    type: String,
    required: true
  },
  timeout: {
    type: Number,
    default: 500
  }
});

const data = reactive({
  key: '',
  value: ''
});

const emit = defineEmits(['update:value']);

watchEffect(() => {
  if (!props.value) {
    return;
  }
  if (props.value.indexOf(DELIMITER) === -1) {
    data.key = props.value;

    return;
  }
  const parts = props.value.split(DELIMITER, 2);

  data.key = parts[0];
  data.value = parts[1];
});

const updateKey = (event) => {
  data.key = event.target.value;
  update();
};

const updateValue = (event) => {
  data.value = event.target.value;
  update();
};

const update = () =>
  debounce(() => emit('update:value', data.key ? [data.key, data.value].join(DELIMITER) : ''), props.timeout);
</script>
