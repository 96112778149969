<template>
  <div class="relative h-screen flex flex-col justify-center items-center bg-gray-50">
    <img
      class="absolute left-10 top-12"
      src="../../../assets/img/bringg-logo.svg"
      alt="Bringg" />

    <h1 class="text-3xl text-gray-800 font-semibold">
      {{ t('SIGNUP.WELCOME') }}
    </h1>

    <span class="text-sm text-gray-600 py-4">
      {{ t('SIGNUP.WELCOME_DESCRIPTION') }}
    </span>

    <div class="form mt-10">
      <v-form
        :validation-schema="schema"
        @submit="onSubmit">
        <b-alert
          v-if="state.error"
          class="mb-6"
          type="danger">
          {{ state.error }}
        </b-alert>

        <b-input
          id="name"
          v-model:value="form.name"
          :label="t('SIGNUP.NAME')" />

        <b-input
          id="companyName"
          v-model:value="form.companyName"
          :label="t('SIGNUP.COMPANY_NAME')"
          class="mt-6" />

        <b-input
          id="email"
          v-model:value="form.email"
          :label="t('LOGIN.EMAIL')"
          class="mt-6" />

        <b-input
          id="password"
          v-model:value="form.password"
          :label="t('LOGIN.PASSWORD')"
          password
          class="mt-6">
          <template #error-message>
            <div class="absolute p-4 z-20 bg-white text-gray-600 rounded shadow-xl">
              <span>{{ t('PASSWORD_VALIDATION.TITLE') }}:</span>
              <ul class="pl-6 list-disc">
                <li
                  v-for="[func, labelKey] in passwordValidationRules"
                  :key="labelKey"
                  :class="{ 'text-red-900': !func(form.password) }">
                  {{ t(labelKey) }}
                </li>
              </ul>
            </div>
          </template>
        </b-input>

        <b-input
          id="passwordConfirmation"
          v-model:value="form.passwordConfirmation"
          :label="t('SIGNUP.CONFIRM_PASSWORD')"
          password
          class="mt-6" />

        <b-checkbox
          id="termsOfServiceAgreement"
          v-model:value="form.termsOfServiceAgreementAccepted"
          class="mt-6">
          <template #label>
            <span class="text-gray-700">
              {{ t('SIGNUP.AGREEMENT') }}
              <a
                href="https://go.bringg.com/hubfs/FOC-POC-T&C-August-2023.pdf"
                target="_blank"
                class="text-blue-400 inline-flex">
                <span class="whitespace-nowrap">{{ t('SIGNUP.TERMS_OF_SERVICE') }}</span>
              </a>
            </span>
          </template>
        </b-checkbox>

        <b-button
          class="mt-10 w-full text-base"
          :disabled="!form.termsOfServiceAgreementAccepted"
          :loading="state.loading"
          data-id="signup"
          submit>
          {{ t('SIGNUP.SIGNUP') }}
        </b-button>
      </v-form>
    </div>
    <div class="flex gap-2 pt-8 text-sm text-gray-600">
      <span>
        {{ t('SIGNUP.HAVE_AN_ACCOUNT') }}
      </span>
      <router-link
        class="text-blue-600 dark:text-blue-500 hover:underline"
        :to="{ name: 'login' }">
        {{ t('LOGIN.LOGIN') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignupPage'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { Form as VForm } from 'vee-validate';
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { string } from 'yup';

import { passwordValidationRules } from '@/validationRules';

import BAlert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import BCheckbox from '../../atoms/Checkbox';
import BInput from '../../atoms/Input';

const store = useStore();
const { t } = useTranslation();

const form = reactive({
  name: '',
  companyName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  termsOfServiceAgreementAccepted: false
});

const state = reactive({
  captchaRequired: false,
  error: null,
  merchantsData: [],
  loading: false
});

const schema = computed(() => ({
  name: string().min(3).required().label(t('SIGNUP.NAME')),
  companyName: string().min(3).required().label(t('SIGNUP.COMPANY_NAME')),
  email: string().email().required().label(t('LOGIN.EMAIL')),
  password: passwordValidationRules.reduce(
    (result, [func, errorLabelKey]) => result.test(errorLabelKey, errorLabelKey, func),
    string().required().label(t('LOGIN.PASSWORD'))
  ),
  passwordConfirmation: string()
    .test('passwords-match', t('SIGNUP.ERROR_SAME_PASSWORDS'), (value) => form.password === value)
    .label(t('SIGNUP.CONFIRM_PASSWORD'))
}));

const route = useRoute();

const onSubmit = async () => {
  state.loading = true;
  try {
    const response = await store.dispatch('auth/signup', {
      ...form,
      query: route.query
    });

    if (!response.data.success) {
      state.error = response.data.errors.reason;
    } else {
      state.error = null;
    }
  } catch (error) {
    state.error = error.response.data.errors.reason;
  }
};
</script>

<style lang="scss" scoped>
.form {
  width: 400px;
}
</style>
