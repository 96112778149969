<template>
  <div class="flex overflow-hidden flex-col overflow-hidden h-full">
    <b-modal-users
      v-if="state.isShownModal"
      :value="state.editUser"
      :is-edit="isEdit"
      :is-shown="state.isShownModal"
      @close="onClose"
      @create="onCreate"
      @edit="onEdit" />
    <div class="pl-10 pr-6 pt-6 flex h-full flex-col overflow-hidden">
      <alert
        v-if="error"
        class="mb-8"
        type="danger"
        :close="true"
        @close="clearError">
        {{ t(error) }}
      </alert>

      <b-table
        class="users"
        :loading="loading"
        :fields="fields"
        :data="users"
        row-key="id"
        last-update>
        <template #right-filter-bar>
          <b-button
            v-if="isAdmin"
            class="ml-2 px-6 py-2"
            data-id="addUser"
            @click="state.isShownModal = true">
            {{ t('USERS.ADD_USER') }}
          </b-button>
        </template>
        <template #cell(name)="{ row }">
          <span
            class="text-blue-400 hover:text-blue-600 cursor-pointer"
            @click="selectAction(row, 'edit-user')">
            {{ row.name }}
          </span>
        </template>
        <template #cell(email)="{ row }">
          {{ row.email }}
        </template>
        <template #cell(role)="{ row }">
          {{ formatRole(row) }}
        </template>
        <template #cell(actions)="{ row }">
          <dropdown
            :options="actionOptions"
            button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
            :left-align="false"
            @select="selectAction(row, $event)">
            <template #button>
              <icon
                class="mr-3 text-xl"
                icon-name="menu-horizontal" />
            </template>
          </dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Users'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';

import { Role } from '@/enums';
import { formatRole } from '@/utils';

import Alert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Dropdown from '../../molecules/Dropdown';
import BModalUsers from '../../organisms/ModalUsers';
import BTable from '../../organisms/Table';

const store = useStore();
const { t } = useTranslation();

const state = reactive({
  editUser: {},
  isShownModal: false
});

onMounted(async () => await loadUsers());

const users = computed(() => store.getters['users/items']);
const loading = computed(() => store.state.users.loading);
const error = computed(() => store.state.users.error);
const isEdit = computed(() => !(Object.keys(state.editUser).length === 0));
const isAdmin = computed(() => store.getters['auth/isAdmin']);

const fields = computed(() => {
  const fields = [
    { key: 'name', label: 'USERS.NAME_FIELD' },
    { key: 'email', label: 'USERS.EMAIL_FIELD' },
    { key: 'role', label: 'USERS.ROLE_FIELD' }
  ];

  if (isAdmin.value) {
    fields.push({ key: 'actions', label: 'ACTIONS_FIELD' });
  }

  return fields;
});

const actionOptions = [
  { bringgIcon: 'pencil', value: 'edit-user', label: 'QUICK_ACTIONS.EDIT' },
  { bringgIcon: 'trash', value: 'remove-user', label: 'QUICK_ACTIONS.REMOVE' }
];

const loadUsers = async () => await store.dispatch('users/loadUsers');
const clearError = () => store.commit('users/clearError');

const defineRole = (user) => {
  if (user.role === Role.Admin) {
    return Object.assign(user, { admin: true, dispatcher: false, driver: false });
  }

  if (user.role === Role.Packer) {
    return Object.assign(user, { admin: false, dispatcher: true, driver: false });
  }

  return Object.assign(user, { admin: false, dispatcher: true, driver: true });
};

const onCreate = async (value) => {
  await store.dispatch('users/createUser', defineRole(value));
};

const onEdit = async (value) => {
  await store.dispatch('users/editUser', defineRole(value));
  state.isShownModal = false;
  state.editUser = {};
};

const onClose = () => {
  state.isShownModal = false;
  state.editUser = {};
};

const selectAction = async (row, actionType) => {
  const actions = {
    'edit-user': async () => {
      state.editUser = {
        role: formatRole(row),
        ...row
      };
      state.isShownModal = true;
    },
    'remove-user': async () => {
      await store.dispatch('users/removeUser', { id: row.id });
    }
  };

  return await actions[actionType]();
};
</script>
