<template>
  <div class="flex inline-flex p-0.5 items-center bg-gray-100 rounded-md">
    <span
      :class="disabled ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400 cursor-pointer'"
      @click="minus">
      <b-icon
        icon-name="minus"
        class="text-sm" />
    </span>
    <div class="text-xs text-gray-700 leading-4 font-medium">
      <input
        ref="input"
        :disabled="disabled"
        class="bg-transparent text-center outline-none"
        :size="max.toString().length"
        :value="value"
        @change="onChange"
        @input="onInput"
        @click="onClick" />
    </div>
    <span
      class=""
      :class="disabled ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400 cursor-pointer'"
      @click="plus">
      <b-icon
        icon-name="plus"
        class="text-sm" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Counter'
};
</script>

<script setup>
import { defineEmits, defineProps, onBeforeUnmount, onMounted, ref } from 'vue';

import BIcon from '../Icon';

const input = ref(null);

const props = defineProps({
  value: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  },
  min: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:value']);

const minus = () => {
  if (props.disabled || props.value - 1 < props.min) {
    return;
  }

  emit('update:value', props.value - 1);
};
const plus = () => {
  if (props.disabled || props.value + 1 > props.max) {
    return;
  }

  emit('update:value', props.value + 1);
};

const onChange = (event) => {
  const { value } = event.target;

  emit('update:value', Number(value));
};

const onInput = (event) => {
  const { value } = event.target;

  if (value === '') {
    return;
  }

  if (isNaN(value)) {
    event.target.value = props.value;
  }

  if (value > props.max) {
    event.target.value = props.max;
  }

  if (value < props.min) {
    event.target.value = props.min;
  }
};

const onClick = (event) => {
  event.target.value = '';
};

const onClickOutside = (event) => {
  if (!input.value || input.value.contains(event.target)) {
    return;
  }

  if (input.value.value === '') {
    input.value.value = props.value;
  }
};

onMounted(() => {
  document.addEventListener('click', onClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', onClickOutside);
});
</script>
