<template>
  <modal
    :value="isShown"
    :validation="schema"
    size="huge"
    @close="closeModal"
    @submit="submit">
    <template #title>
      <slot name="title" />
    </template>
    <template
      v-if="localValue"
      #body>
      <div :class="localValue.service ? '' : 'h-60'">
        <z-select
          id="service"
          v-model:value="localValue.service"
          :options="serviceOptions"
          :disabled="!isCreateForm"
          class="mb-4">
          <template #label>
            {{ t('INTEGRATIONS_MODAL.INTEGRATION_FIELD') }}
          </template>
        </z-select>
        <template v-if="localValue.service">
          <salesforce-core-form
            v-if="localValue.service === '1' || localValue.service === 1"
            v-model:domain="localValue[localValue.service].custom_domain"
            v-model:environment="localValue[localValue.service].environment" />
          <salesforce-commerce-cloud-form
            v-else-if="localValue.service === '2' || localValue.service === 2"
            v-model:cloud-url="localValue[localValue.service].url" />
          <newstore-form
            v-else-if="localValue.service === '8' || localValue.service === 8"
            :id="value.id || ''"
            v-model:name="localValue[localValue.service].name"
            v-model:shipping_account_key="localValue[localValue.service].shipping_account_key"
            :shipping_account_id="localValue[localValue.service].shipping_account_id"
            :shipping-accounts="shippingAccounts"
            :shipping-account-blacklist="shippingAccountBlacklist[8]" />
          <ftp-form
            v-else-if="localValue.service === '9' || localValue.service === 9"
            v-model:name="localValue[localValue.service].name"
            v-model:shipping_account_id="localValue[localValue.service].shipping_account_id"
            v-model:ftp_username="localValue[localValue.service].ftp_username"
            :ftp_password="localValue[localValue.service].ftp_password"
            :ftp_key="localValue[localValue.service].ftp_key"
            :shipping-accounts="shippingAccounts"
            :shipping-account-blacklist="shippingAccountBlacklist[9]"
            :allowed-carriers="allowedFtpCarriers"
            @update:ftp_password="updateFtpPassword"
            @update:ftp_key="updateFtpKey" />
          <quantum-view-form
            v-else-if="localValue.service === '10' || localValue.service === 10"
            :id="value.id || ''"
            v-model:name="localValue[localValue.service].name"
            v-model:shipping_account_id="localValue[localValue.service].shipping_account_id"
            :shipping-accounts="shippingAccounts"
            :shipping-account-blacklist="shippingAccountBlacklist[10]" />
          <shopify-form
            v-else-if="localValue.service === '11' || localValue.service === 11"
            v-model:shop_url="localValue[localValue.service].shop_url"
            v-model:shipping_account="localValue[localValue.service].shipping_account"
            :shipping-accounts="shippingAccounts"
            :disabled="!isCreateForm" />
        </template>
      </div>
    </template>
    <template #footer>
      <z-button
        type="success"
        full-width
        submit>
        <slot name="send-button-label" />
      </z-button>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'IntegrationModal'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, nextTick, reactive, watch } from 'vue';
import { array, number, object, string } from 'yup';

// import ZButton from '../../atoms/Button';
// import ZSelect from '../../atoms/Select';
import Modal from '../../organisms/Modal';
// import FtpForm from '../FtpForm';
// import NewstoreForm from '../NewstoreForm';
// import QuantumViewForm from '../QuantumViewForm';
// import SalesforceCommerceCloudForm from '../SalesforceCommerceCloudForm';
// import SalesforceCoreForm from '../SalesforceCoreForm';
// import ShopifyForm from '../ShopifyForm';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  serviceOptions: {
    type: Array,
    required: true
  },
  shippingAccounts: {
    type: Array,
    required: true
  },
  shippingAccountBlacklist: {
    type: Object,
    required: true
  },
  isShown: {
    type: Boolean,
    default: false
  },
  isCreateForm: {
    type: Boolean,
    default: true
  },
  allowedFtpCarriers: {
    type: Array,
    default: () => []
  }
});

const initLocalValue = () => ({
  service: null,
  1: {
    environment: '',
    custom_domain: ''
  },
  2: {
    url: ''
  },
  8: {
    name: '',
    shipping_account_id: '',
    shipping_account_key: ''
  },
  9: {
    name: '',
    ftp_username: '',
    ftp_password: '',
    ftp_key: '',
    shipping_account_id: ''
  },
  10: {
    name: '',
    shipping_account_id: ''
  },
  11: {
    shop_url: '',
    shipping_account: []
  }
});
const localValue = reactive({ ...initLocalValue(), ...props.value });

watch(
  () => props.value,
  (value) => {
    Object.assign(localValue, {
      ...initLocalValue(),
      ...value
    });

    for (let service in localValue) {
      for (let field in localValue[service]) {
        if (field in value) {
          localValue[service][field] = value[field];
        }
      }
    }
  }
);

const schemaMapping = computed(() => {
  const service = number().nullable().required().label(t('INTEGRATIONS_MODAL.INTEGRATION_FIELD'));
  const shippingAccount = string().required().label(t('INTEGRATIONS_MODAL.SHIPPING_ACCOUNT_FIELD'));
  const name = string().required().label(t('INTEGRATIONS_MODAL.NAME_FIELD'));

  return {
    null: {
      service
    },
    1: {
      service,
      environment: string().required().label(t('INTEGRATIONS_MODAL.INTEGRATION_FIELD')),
      custom_domain: string().url().label(t('INTEGRATIONS_MODAL.DOMAIN_FIELD'))
    },
    2: {
      service,
      url: string().url().required().label(t('INTEGRATIONS_MODAL.CLOUD_URL_FIELD'))
    },
    8: {
      service,
      name,
      shipping_account_id: shippingAccount
    },
    10: {
      service,
      name,
      shipping_account_id: shippingAccount
    },
    11: {
      service,
      shop_url: string()
        .url()
        .matches(
          /[^.\s]+\.myshopify\.com/,
          [t('INTEGRATIONS_MODAL.SHOP_URL_FORMAT_MESSAGE'), '"store-name.myshopify.com"'].join(' ')
        )
        .label(t('INTEGRATIONS_MODAL.SHOP_URL_FIELD')),
      shipping_account: array().of(number()).min(1).label(t('INTEGRATIONS_MODAL.SHIPPING_ACCOUNT_LIST_FIELD'))
    }
  };
});

const ftpSchema = computed(() => ({
  service: number().nullable().required().label(t('INTEGRATIONS_MODAL.INTEGRATION_FIELD')),
  name: string().required().label(t('INTEGRATIONS_MODAL.NAME_FIELD')),
  shipping_account_id: string().required().label(t('INTEGRATIONS_MODAL.SHIPPING_ACCOUNT_FIELD')),
  ftp_username: string().required().label(t('FTP_INTEGRATION.USERNAME_FIELD')),
  ftp_password: string()
    .test('notBothAtTheSameTime', t('INTEGRATIONS_MODAL.FTP_PASSWORD_KEY_ERROR'), function (ftpPassword) {
      const { ftp_key: ftpKey } = this.parent;

      return !(ftpKey && ftpPassword);
    })
    .when(['ftp_key'], {
      is: (ftpKey) => !ftpKey,
      then: props.value.ftp_key ? string() : string().required().label(t('FTP_INTEGRATION.PASSWORD_FIELD'))
    }),
  ftp_key: string()
    .test('notBothAtTheSameTime', t('INTEGRATIONS_MODAL.FTP_PASSWORD_KEY_ERROR'), function (ftpKey) {
      const { ftp_password: ftpPassword } = this.parent;

      return !(ftpKey && ftpPassword);
    })
    .when(['ftp_password'], {
      is: (ftpPassword) => !ftpPassword,
      then: props.value.ftp_key ? string() : string().required().label(t('FTP_INTEGRATION.PRIVATE_KEY_FIELD'))
    })
}));

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const schema = computed(() => {
  // It doesn't make sense to redefine all schemas on every change of this computed.
  // Therefore FTP schema was moved to the separate computed value and added to the
  // static schema mapping.
  // TODO: if you get a better variant without mutation inside computed value, refactor it please.
  // Only check the case that in the edit mode FTP password and key fields are optional
  // when something is in the ftp key field.
  schemaMapping.value[9] = ftpSchema.value; // eslint-disable-line vue/no-side-effects-in-computed-properties
  const { service } = localValue;

  return service === '9' || service === 9
    ? object().shape(schemaMapping.value[service], [['ftp_password', 'ftp_key']])
    : object(schemaMapping.value[service]);
});

const emit = defineEmits(['close', 'send']);

const resetLocalValue = () => Object.assign(localValue, initLocalValue());

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const closeModal = async () => {
  resetLocalValue();
  await nextTick();
  emit('close');
};

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const submit = async () => {
  const value = {
    service: localValue.service,
    ...localValue[localValue.service]
  };

  if (props.value && props.value.id) {
    value.id = props.value.id;
  }
  if (!value.ftp_password) {
    delete value.ftp_password;
  }
  if (!value.ftp_key) {
    delete value.ftp_key;
  }
  if (value.shipping_account_key) {
    value.shipping_account_id = value.shipping_account_key;
    delete value.shipping_account_key;
  }

  resetLocalValue();
  await nextTick();
  emit('send', value);
};

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const updateFtpPassword = (value) => {
  localValue[localValue.service].ftp_password = value;
  localValue[localValue.service].ftp_key = '';
};

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const updateFtpKey = (value) => {
  localValue[localValue.service].ftp_key = value;
  localValue[localValue.service].ftp_password = '';
};
</script>
