<template>
  <div
    v-disable-if-not-admin="isAdmin"
    class="p-6 pl-10 h-full overflow-auto">
    <alert
      v-if="error"
      class="mb-8"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>
    <div class="flex justify-between items-center mb-8">
      <div class="pr-6 text-sm text-gray-600 flex items-center">
        <span>{{ t('SHIPPING_RULES.HEADING') }}</span>

        <a
          href="https://help.bringg.com/delivery-hub/docs/set-up-shipping-rules"
          target="_blank"
          class="text-blue-400 text-sm inline-flex font-semibold ml-4">
          <b-icon
            :icon-name="BringgFontIcons.ExternalLink"
            class="text-4xl w-4 h-4" />
          <span class="whitespace-nowrap">{{ t('SHIPPING_RULES.LEARN_MORE') }}</span>
        </a>
      </div>

      <div class="flex-shrink-0">
        <b-button
          v-if="isAdmin"
          class="px-6 py-2"
          data-id="addShippingRule"
          @click="store.dispatch('shippingRules/addRule', RULE_TYPE.SHIPPING)">
          {{ t('SHIPPING_RULES.ADD_SHIPPING_RULE') }}
        </b-button>
      </div>
    </div>

    <animated-placeholder
      v-if="!isMounted"
      :count="2" />

    <transition
      name="fade"
      mode="out-in">
      <div
        v-show="locationsAreAbsent"
        class="flex px-1 py-2 rounded locations-absent-container mb-4 items-center w-max">
        <b-icon
          :icon-name="BringgFontIcons.Warning"
          class="mt-0.5 text-lg text-danger-900 ml-2" />
        <span class="locations-absent-text mx-2">
          {{ t('SHIPPING_RULES.ABSENT_LOCATIONS_WARNING') }}
        </span>
      </div>
    </transition>

    <draggable
      v-if="isMounted"
      v-disable-if-not-admin="isAdmin"
      :list="store.getters['shippingRules/shipping']"
      handle=".rule-drag"
      item-key="name"
      @change="onMove">
      <template #item="{ element, index }">
        <rule
          :rule="element"
          :services="store.getters['shippingRules/services']"
          :locations="store.getters['shippingLocations/items']"
          :index="index"
          class="mb-6"
          @update:rule="onRuleChange"
          @settings="onSettings" />
      </template>
    </draggable>
  </div>
</template>

<script>
export default {
  name: 'ShippingRules'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, onBeforeMount, ref } from 'vue';
import Draggable from 'vuedraggable';
import { useStore } from 'vuex';

import Alert from '@/components/atoms/Alert';
import AnimatedPlaceholder from '@/components/atoms/AnimatedPlaceholder';
import BButton from '@/components/atoms/Button';
import BIcon from '@/components/atoms/Icon';
import Rule from '@/components/organisms/Rule';
import { ACTIONS, RULE_TYPE } from '@/components/organisms/Rule/constants';

const store = useStore();
const { t } = useTranslation();

const isMounted = ref(false);

const error = computed(() => store.state.shippingRules.error);

const isAdmin = computed(() => store.getters['auth/isAdmin']);

onBeforeMount(async () => {
  await Promise.all([
    store.dispatch('shippingRules/loadRules'),
    store.dispatch('shippingAccounts/loadShippingAccounts'),
    store.dispatch('shippingLocations/loadShippingLocations')
  ]);

  isMounted.value = true;
});

const onMove = (event) => {
  store.dispatch('shippingRules/moveRule', event.moved);
};

const onRuleChange = (rule) => {
  store.dispatch('shippingRules/saveRule', rule);
};

const onSettings = (value, element, index) => {
  if (value === ACTIONS.DUPLICATE) {
    store.dispatch('shippingRules/duplicateRule', element);
  }

  if (value === ACTIONS.DELETE) {
    store.dispatch('shippingRules/deleteRule', element);
  }

  if (value === ACTIONS.MOVE_UP && index > 0) {
    store.dispatch('shippingRules/moveRule', {
      element,
      newIndex: index - 1,
      oldIndex: index
    });
  }

  if (value === ACTIONS.MOVE_DOWN && index < store.state.shippingRules.rules.length - 1) {
    store.dispatch('shippingRules/moveRule', {
      element,
      newIndex: index + 1,
      oldIndex: index
    });
  }
};

const locationsAreAbsent = computed(() => store.getters['shippingRules/locationAbsent']);

const clearError = () => store.commit('shippingRules/clearError');
</script>

<style lang="scss" scoped>
.locations-absent-container {
  background: var(--danger-danger-50, #fee7df);
}

.locations-absent-text {
  color: var(--color-neutrals-800, #2d3748);

  font-family:
    Open Sans,
    serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
}
</style>
