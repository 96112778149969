<template>
  <SwitchGroup>
    <div
      :data-id="`switch_${dataId}`"
      class="flex items-center">
      <slot name="before" />
      <HeadlessSwitch
        :class="[value ? 'bg-blue-400' : 'bg-gray-200', disabled ? 'cursor-not-allowed' : '']"
        class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2"
        :model-value="Boolean(value)"
        :value="value ? 'checked' : ''"
        @update:model-value="update">
        <span
          :class="value ? 'translate-x-6' : 'translate-x-1'"
          class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full" />
      </HeadlessSwitch>
      <slot name="after">
        <SwitchLabel
          v-if="label"
          class="ml-2 text-gray-700 flex items-center">
          {{ label }}
        </SwitchLabel>
      </slot>
    </div>
  </SwitchGroup>
</template>

<script>
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Switch'
};
</script>

<script setup>
import { Switch as HeadlessSwitch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps } from 'vue';

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  value: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  dataId: {
    type: String,
    default: ''
  }
});

const field = useField(props.name);
const value = computed(() => (props.name ? field.value.value : props.value));
const emit = defineEmits(['update:value']);

const update = () => {
  if (props.disabled) {
    return;
  }

  field.handleChange(!field.value.value);
  emit('update:value', !props.value);
};
</script>
