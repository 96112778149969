<template>
  <return-details-template
    :loading="page.data.loading"
    :value="data.details"
    @update:status="changeStatus">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </return-details-template>
</template>

<script>
export default {
  name: 'ReturnDetails'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { defineProps, onMounted, reactive } from 'vue';

import client from '../../../api';
import usePage from '../../../mixins/usePage';
import { ReturnDetailsService } from '../../../services';
import { showFailMessage, withLoading } from '../../../utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Alert from '../../atoms/Alert';
import ReturnDetailsTemplate from '../../templates/ReturnDetails';

const { t } = useTranslation();

const page = usePage();

const props = defineProps({
  id: {
    type: String,
    required: true
  }
});

const data = reactive({
  details: {}
});

onMounted(async () => {
  await withLoading(page.data, async () => {
    try {
      data.details = await ReturnDetailsService.get(props.id);
    } catch (e) {
      await showFailMessage(page.data, t('RETURNS_DETAILS.RETURNS_DETAILS_LOADING_FAIL'));
    }
  });
});

const changeStatus = async (value) => {
  await withLoading(page.data, async () => {
    try {
      await client.patchReturnItem(props.id, {
        shipment: {
          status: value
        }
      });
      data.details.shipment.status = value;
    } catch (e) {
      await showFailMessage(page.data, t('RETURNS_DETAILS.STATUS_UPDATE_FAIL'));
    }
  });
};
</script>
