<template>
  <div class="relative h-screen flex flex-col justify-center items-center bg-gray-50">
    <img
      class="absolute left-10 top-12"
      src="../../../assets/img/bringg-logo.svg"
      alt="Bringg" />

    <h1 class="text-3xl text-gray-800 font-semibold">
      {{ t('RESET.TITLE') }}
    </h1>

    <div class="form mt-10">
      <v-form
        v-slot="{ values }"
        :validation-schema="schema"
        @submit="onSubmit">
        <b-input
          id="password"
          :label="t('RESET.PASSWORD')"
          password
          class="mt-6">
          <template #error-message>
            <div class="absolute p-4 z-20 bg-white text-gray-600 rounded shadow-xl">
              <span>{{ t('PASSWORD_VALIDATION.TITLE') }}:</span>
              <ul class="pl-6 list-disc">
                <li
                  v-for="[func, labelKey] in passwordValidationRules"
                  :key="labelKey"
                  :class="{ 'text-red-900': !func(values.password) }">
                  {{ t(labelKey) }}
                </li>
              </ul>
            </div>
          </template>
        </b-input>

        <b-alert
          v-if="state.error"
          class="mt-6"
          type="danger">
          {{ state.error }}
        </b-alert>

        <b-button
          class="w-full mt-10"
          :loading="state.loading"
          data-id="reset"
          submit>
          {{ t('RESET.SUBMIT') }}
        </b-button>
      </v-form>
      <div class="flex justify-center gap-2 pt-8 text-sm text-gray-600">
        <router-link
          :to="{ name: 'login' }"
          class="text-blue-600 dark:text-blue-500 hover:underline">
          {{ t('REMIND.BACK_TO_LOGIN') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetPage'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { Form as VForm } from 'vee-validate';
import { computed, defineProps, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { string } from 'yup';

import { passwordValidationRules } from '@/validationRules';

import BAlert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import BInput from '../../atoms/Input';

const store = useStore();
const router = useRouter();

const { t } = useTranslation();

const props = defineProps({
  email: {
    type: String,
    required: true
  },
  token: {
    type: String,
    required: true
  },
  region: {
    type: String,
    required: true
  }
});

const state = reactive({
  error: null,
  loading: false
});

const schema = computed(() => ({
  password: passwordValidationRules.reduce(
    (result, [func, errorLabelKey]) => result.test(errorLabelKey, errorLabelKey, func),
    string().required().label(t('LOGIN.PASSWORD'))
  )
}));

const onSubmit = async (data) => {
  state.loading = true;
  state.error = null;

  try {
    const response = await store.dispatch('auth/reset', {
      ...data,
      token: props.token,
      email: props.email,
      region: props.region
    });

    if (!response.data.success) {
      state.error = response.data.errors.reason;
    } else {
      await router.push({ name: 'login' });
    }
  } catch (error) {
    state.error = error.response?.data.errors.reason;
  } finally {
    state.loading = false;
  }
};
</script>

<style lang="scss" scoped>
.form {
  width: 400px;
}
</style>
