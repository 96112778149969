<template>
  <div
    :class="containerClass"
    class="alert-container rounded-md p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <component
          :is="icon"
          class="alert-icon h-5 w-5"
          aria-hidden="true" />
      </div>
      <div class="ml-3">
        <p class="alert-content text-sm font-medium">
          <slot />
        </p>
      </div>
      <div
        v-if="close"
        class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="alert-close inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
            @click="closeAlert">
            <span class="sr-only">Dismiss</span>
            <XIcon
              class="h-5 w-5"
              aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert'
};
</script>

<script setup>
import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XCircleIcon, XIcon } from '@heroicons/vue/solid';
import { computed, defineEmits, defineProps } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'info',
    validator: function (value) {
      return ['success', 'info', 'warning', 'danger'].indexOf(value) !== -1;
    }
  },
  close: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close']);

const icon = computed(
  () =>
    ({
      success: CheckCircleIcon,
      info: InformationCircleIcon,
      warning: ExclamationIcon,
      danger: XCircleIcon
    })[props.type]
);

const containerClass = computed(
  () =>
    ({
      success: 'alert-success',
      info: 'alert-info',
      warning: 'alert-warning',
      danger: 'alert-danger'
    })[props.type]
);

const closeAlert = () => emit('close');
</script>

<style lang="scss">
.alert-success {
  &.alert-container {
    @apply bg-green-100;
  }

  .alert-icon {
    @apply text-green-400;
  }

  .alert-content {
    @apply text-green-800;
  }

  .alert-close {
    @apply bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600;
  }
}

.alert-info {
  &.alert-container {
    @apply bg-blue-50;
  }

  .alert-icon {
    @apply text-blue-400;
  }

  .alert-content {
    @apply text-blue-800;
  }

  .alert-close {
    @apply bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600;
  }
}

.alert-warning {
  &.alert-container {
    @apply bg-yellow-50;
  }

  .alert-icon {
    @apply text-yellow-400;
  }

  .alert-content {
    @apply text-yellow-800;
  }

  .alert-close {
    @apply bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600;
  }
}

.alert-danger {
  &.alert-container {
    @apply bg-red-50;
  }

  .alert-icon {
    @apply text-red-400;
  }

  .alert-content {
    @apply text-red-800;
  }

  .alert-close {
    @apply bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600;
  }
}
</style>
