<template>
  <div v-if="mounted">
    <popover class="relative">
      <popover-button class="focus:outline-none">
        <slot name="button" />
      </popover-button>
      <popover-panel class="absolute z-10">
        <div class="w-max p-3 bg-white shadow-xl rounded">
          <div class="text-base font-medium">
            {{ [t('SHIPMENT_DETAILS.PACKAGE'), t('SHIPMENT_DETAILS.DETAILS')].join(' ') }}
          </div>
          <div class="mt-2 flex gap-1 items-center">
            <b-icon
              :icon-name="BringgFontIcons.MyLocation"
              class="text-base text-gray-600" />
            <span>{{ t('SHIPMENT_DETAILS.TRACKING') }}:</span>
            <Copy>
              <span class="text-gray-500">{{ value.tracking_number }}</span>
            </Copy>
          </div>
          <div class="mt-1 flex gap-1 items-center">
            <b-icon
              :icon-name="BringgFontIcons.Ruler"
              class="text-base text-gray-600" />
            <span>{{ t('SHIPMENT_DETAILS.DIMENSIONS') }}:</span>
            <span class="text-gray-500">{{ dimensions }} {{ CONST_DIMENSIONS_UNIT }}</span>
          </div>
          <div class="mt-1 flex gap-1 items-center">
            <b-icon
              :icon-name="BringgFontIcons.Weight"
              class="text-base text-gray-600" />
            <span>{{ t('SHIPMENT_DETAILS.WEIGHT') }}:</span>
            <span class="text-gray-500">{{ weight }} {{ CONST_WEIGHT_UNIT }}</span>
          </div>
        </div>
      </popover-panel>
    </popover>
  </div>
</template>

<script>
export default {
  name: 'ModalPackageDetails'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useTranslation } from 'i18next-vue';
import { computed, defineProps, onMounted, ref } from 'vue';

import Copy from '@/components/atoms/Copy';
import BIcon from '@/components/atoms/Icon';
import { convertUnits, round } from '@/utils';

const CONST_WEIGHT_UNIT = 'kg';
const CONST_DIMENSIONS_UNIT = 'cm';

const { t } = useTranslation();

const mounted = ref(false);

onMounted(() => {
  mounted.value = true;
});

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
});

const dimensions = computed(() => {
  const { height, width, length, dim_units } = props.value.package;
  const converted = [length, width, height].map((value) => convertUnits(value, dim_units, CONST_DIMENSIONS_UNIT));

  return converted.join(' x ');
});

const weight = computed(() => {
  const {
    package: { weight, weight_units: package_weight_units },
    package_weight,
    weight_units,
    shipments_products
  } = props.value;

  if (package_weight) {
    return convertUnits(package_weight, weight_units, CONST_WEIGHT_UNIT);
  }

  const boxWeight = convertUnits(weight, package_weight_units, CONST_WEIGHT_UNIT);
  const productsTotalWeight = shipments_products.reduce(
    (acc, item) => acc + item.count * convertUnits(item.product.weight, item.product.weight_units, CONST_WEIGHT_UNIT),
    0
  );

  return round(boxWeight + productsTotalWeight);
});
</script>
