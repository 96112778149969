<template>
  <div
    v-if="shippingView"
    class="px-10 pt-6 pb-1">
    <div>
      <span class="title">{{ t('SHIPPING_DOCUMENTS_MODAL.TITLE') }}</span>
      <span class="ml-2 total-packages">
        ({{ t('SHIPPING_DOCUMENTS_MODAL.TOTAL_PACKAGES_LABEL') }}: {{ documentsTotal }})
      </span>
    </div>
    <div
      v-for="(packageItem, index) in documentsForShippingView"
      :key="packageItem.tracking_number"
      class="mt-6">
      <span class="tracking-number">{{ packageItem.tracking_number }}</span>
      <div class="mt-4 ml-4">
        <div class="flex flex-row align-center items-center whitespace-nowrap">
          <span class="print-documents mr-4">{{ t('SHIPPING_DOCUMENTS_MODAL.PRINT_DOCUMENTS_LABEL') }}:</span>
          <div
            v-for="document in packageItem.documents"
            :key="document"
            class="mr-3">
            <span
              class="text-blue-400 text-sm font-semibold cursor-pointer"
              @click="print(document)">
              <icon
                icon-name="print"
                class="text-base" />
              {{ document.label }}
            </span>
          </div>
        </div>
        <div
          v-if="packageItem.documents.some(labelPredicate)"
          class="mt-7">
          <div class="flex pl-2 gap-4">
            <div
              v-for="(document, idx) in packageItem.documents.filter(labelPredicate)"
              :key="idx"
              class="flex flex-col items-center justify-center align-center mr-4">
              <div
                class="image-container overflow-hidden p-2 gap-0 flex justify-center items-center cursor-zoom-in"
                @click="onOpenImageModal(document)">
                <img
                  v-if="!document.value.typedArray || !document.value.returnTypedArray"
                  :src="document.value.label"
                  alt="Image" />
                <VuePdf
                  v-if="document.value.typedArray?.mimeType === MimeTypes.pdf"
                  :scale="1 / 4"
                  :src="
                    document.field === 'return_label'
                      ? document.value.returnTypedArray?.label
                      : document.value.typedArray?.label
                  "
                  :page="1" />
              </div>

              <div class="mt-3 label-under-img">
                {{
                  document.field === 'return_label'
                    ? t('SHIPPING_DOCUMENTS_MODAL.RETURN_LABEL')
                    : t('SHIPPING_DOCUMENTS_MODAL.SHIPPING_LABEL')
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="index !== documentsForShippingView.length - 1"
        class="mt-6 divider"></div>
    </div>
  </div>

  <div
    v-else
    class="px-6 pt-6 pb-12">
    <b-table
      :fields="fields"
      :data="tableData"
      :total="documentsTotal"
      :total-label="t('SHIPPING_DOCUMENTS_MODAL.TOTAL_LABEL')"
      :sticky="false">
      <template #cell(documents)="{ row }">
        <div class="flex">
          <div
            v-for="item in row.documents"
            :key="item"
            class="w-1/2 px-2">
            <span
              class="text-blue-400 text-sm font-semibold cursor-pointer"
              @click="print(item)">
              <icon
                icon-name="print"
                class="text-base" />
              {{ item.label }}
            </span>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ShippingDocumentsTable'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineProps } from 'vue';
import { VuePdf } from 'vue3-pdfjs';
import { useStore } from 'vuex';

import Icon from '@/components/atoms/Icon';
import BTable from '@/components/organisms/Table';
import { MimeTypes } from '@/constants';

const { t } = useTranslation();
const store = useStore();

const props = defineProps({
  tableData: {
    type: Array,
    required: true
  },
  documentsTotal: {
    type: Number,
    required: true
  },
  print: {
    type: Function,
    required: true
  },
  shippingView: {
    type: Boolean,
    default: false
  }
});

const fields = computed(() => [
  { key: 'tracking_number', label: 'SHIPPING_DOCUMENTS_MODAL.TRACKING_NUMBER_FIELD' },
  { key: 'documents', label: 'SHIPPING_DOCUMENTS_MODAL.DOCUMENTS_FIELD' }
]);

const documentsForShippingView = props.tableData.filter((data) => data.documents);

const openImageModal = (src, mimeType) => store.dispatch('imageModal/setSource', { src, mimeType });
const labelPredicate = (document) => ['label', 'return_label'].includes(document.field);

const onOpenImageModal = (document) => {
  const typedArray = document.field === 'return_label' ? document.value.returnTypedArray : document.value.typedArray;

  if (typedArray) {
    openImageModal(typedArray.label, typedArray.mimeType);
  } else {
    openImageModal(document.value.label);
  }
};
</script>

<style lang="scss">
.title {
  color: var(--color-neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.total-packages {
  color: var(--color-neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
}

.tracking-number {
  color: var(--color-neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
}

.print-documents {
  color: var(--color-neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
}

.image-container {
  width: 88px;
  height: 88px;
  border-radius: 7.5px;
  background: var(--color-surface-background, #fff);
  box-shadow:
    0px 3.75px 7.5px 0px rgba(0, 51, 80, 0.16),
    0px 0px 1.875px 0px rgba(0, 51, 80, 0.08);

  transition: filter 0.3s ease;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.image-container:hover {
  filter: brightness(70%);
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.label-under-img {
  color: var(--color-neutrals-600, #718096);
  font-family: Inter, serif;
  font-size: 12px;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.24px;
}

.divider {
  border: 1px solid var(--color-neutrals-100, #eff4f8);
}
</style>
