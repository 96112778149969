<template>
  <footer class="h-14 bg-white flex justify-end items-center px-10 py-4">
    <b-button
      data-id="discard"
      class="border-none bg-transparent text-blue-400"
      :disabled="!isDiscardable"
      @click="$emit('discard')">
      {{ t('DISCARD_BUTTON') }}
    </b-button>

    <b-button
      data-id="save"
      class="ml-8 px-6"
      :disabled="!isSubmittable"
      :loading="submitting"
      @click="$emit('submit')">
      {{ submitLabel || t('SAVE_BUTTON') }}
    </b-button>
  </footer>
</template>

<script>
export default {
  name: 'SaveDiscardFooter'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';

import BButton from '@/components/atoms/Button/index.vue';

const { t } = useTranslation();

defineProps({
  isDiscardable: {
    type: Boolean,
    required: false,
    default: true
  },
  isSubmittable: {
    type: Boolean,
    required: false,
    default: true
  },
  submitting: {
    type: Boolean,
    required: false,
    default: false
  },
  submitLabel: {
    type: String,
    required: false,
    default: null
  }
});

defineEmits(['discard', 'submit']);
</script>
