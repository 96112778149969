import { executeRequest } from '../../utils';

const state = {
  items: new Map(),
  loading: false,
  error: ''
};

const getters = {
  items(state) {
    return Array.from(state.items.values());
  },

  shippingLocationName(state) {
    return (id) => state.items.get(id)?.name;
  },

  shippingLocation(state) {
    return (id) => state.items.get(id);
  }
};

const actions = {
  async loadShippingLocations(options) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/shipping_locations`
      },
      options,
      errorMessage: 'SHIPPING_LOCATIONS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setShippingLocations', response.data));
  },

  async loadShippingLocation(options, data) {
    let location;
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/shipping_locations/${data.id}`
      },
      options,
      errorMessage: 'SHIPPING_LOCATIONS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      location = response.data;
    });

    return location;
  },

  async createLocation(options, data) {
    let shipping_location_id;
    const { address, ...shipping_location } = data;
    const requestAddress = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/addresses`,
        data: address
      },
      options,
      errorMessage: 'CREATE_ADDRESS_FAIL',
      errorHandler(error) {
        if (error.response.data.errors.phone === 'INVALID_PHONE_NUMBER') {
          return 'CREATE_ADDRESS_PHONE_FORMAT_FAIL';
        }
      }
    };

    await executeRequest(requestAddress, (response) => (shipping_location.address_id = response.data.id));

    if (!shipping_location.address_id) {
      return;
    }

    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/shipping_locations`,
        data: shipping_location
      },
      options,
      errorMessage: 'CREATE_SHIPPING_LOCATION_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setLocation', response.data);
      shipping_location_id = response.data.id;
    });

    return shipping_location_id;
  },

  async editLocation(options, data) {
    const { address, ...shipping_location } = data;

    const requestAddress = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/addresses/${address.id}`,
        data: address
      },
      options,
      errorMessage: 'UPDATE_ADDRESS_FAIL'
    };

    await executeRequest(requestAddress, () => null);

    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/shipping_locations/${shipping_location.id}`,
        data: shipping_location
      },
      options,
      errorMessage: 'SHIPPING_LOCATION_UPDATING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setLocation', response.data));
  },

  async removeShippingLocation(options, data) {
    const request = {
      request: {
        method: 'DELETE',
        url: `/deliveryhub-orders-service/v1/shipping_locations/${data.id}`,
        data
      },
      options,
      errorMessage: 'SHIPPING_LOCATION_DELETION_FAIL'
    };

    await executeRequest(request, () => options.commit('removeLocation', data));
  }
};

const mutations = {
  setShippingLocations(state, shippingLocations) {
    const shippingLocationsEntries = shippingLocations.map((location) => [location.id, location]);

    state.items = new Map(shippingLocationsEntries);
  },

  removeLocation(state, location) {
    state.items.delete(location.id);
  },

  setLocation(state, location) {
    state.items.set(location.id, location);
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
