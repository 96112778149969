<template>
  <a
    :class="[
      item.disabled && 'cursor-default text-gray-200',
      !item.disabled && (active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'),
      'group flex items-center px-4 py-2 text-sm'
    ]">
    <b-icon
      v-if="item.bringgIcon"
      :icon-name="item.bringgIcon"
      class="text-lg mr-2" />
    <component
      :is="item.icon"
      v-if="item.icon"
      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 hidden"
      aria-hidden="true" />
    {{ t(item.label) }}
  </a>
</template>

<script>
export default {
  name: 'MenuItemContent'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { defineProps } from 'vue';

import BIcon from '@/components/atoms/Icon/index.vue';

defineProps({
  active: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    required: true
  }
});

const { t } = useTranslation();
</script>
