<template>
  <div class="flex overflow-hidden flex-col overflow-hidden h-full">
    <div class="flex justify-between">
      <div class="p-6 pl-10 text-sm text-gray-600 flex">
        <span>{{ t('RETURN_REASONS.SUBTITLE') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnReasons'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { onMounted } from 'vue';

const { t } = useTranslation();

onMounted(async () => {
  return null;
});
</script>
