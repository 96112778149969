import { faker } from '@faker-js/faker';

import { Variables } from '@/components/organisms/CustomerNotification/constants';

const mapping = {
  [Variables.customer_name]: faker.person.fullName(),
  [Variables.delivery_address]: faker.location.streetAddress(true),
  [Variables.eta_date]: faker.date.soon().toLocaleDateString(),
  // [Variables.eta_hour]: faker.date.soon().toLocaleTimeString(),
  [Variables.fulfillment_id]: faker.string.uuid(),
  [Variables.order_id]: faker.string.uuid(),
  [Variables.support_email]: faker.internet.email(),
  [Variables.support_phone]: faker.phone.number(),
  [Variables.support_url]: faker.internet.url(),
  [Variables.tracking_number]: faker.string.numeric(5),
  [Variables.tracking_url]: faker.internet.url(),
  [Variables.carrier]: faker.company.name(),
  // [Variables.driver_name]: faker.name.lastName(),
  // [Variables.driver_phone_number]: faker.phone.number(),
  [Variables.shipment_id]: faker.string.uuid()
};

export const previewWithVariables = (text) => {
  return text.replace(
    new RegExp(
      Object.keys(mapping)
        .map((v) => `@${v}`)
        .join('|'),
      'g'
    ),
    (match) => mapping[match.substring(1)]
  );
};
