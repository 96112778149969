<template>
  <div
    v-if="loading"
    class="h-screen flex justify-center items-center">
    <Spinner
      :height="50"
      :width="50" />
  </div>
  <div
    v-else
    class="font-inter bg-slate-75">
    <div class="h-full pb-40 mx-auto max-w-sm xl:max-w-6xl lg:max-w-4xl md:max-w-2xl sm:max-w-md">
      <div class="my-10 flex items-center">
        <img
          class="w-6 h-6 mr-1.5"
          src="../../../assets/img/bringg-logo-small.svg"
          alt="Bringg" />
        <h1 class="text-lg text-gray-800 font-bold pl-1.5 border-l-2 border-gray-300">
          {{ state.value?.branding?.company_name || 'Bringg' }}
        </h1>
      </div>
      <b-box class="mb-10">
        <div class="flex flex-col mb-2 sm:flex-row sm:justify-between">
          <h2 class="font-semibold text-gray-800">
            {{ `${t('CUSTOMER_TRACKING.ORDER_DETAILS')} - #${state.value.source_id || state.value.id}` }}
          </h2>
          <p class="min-w-max">
            <span class="text-xs font-medium text-gray-800 sm:text-sm">{{ t('CUSTOMER_TRACKING.CREATED') }}:</span>
            <span class="ml-2 text-xs text-gray-700 sm:text-sm">
              {{ formatDateTime(state.value.created_at, 'MMM. d, Y') }}
            </span>
          </p>
        </div>
        <div class="mb-3">
          <span class="text-sm font-medium text-gray-800">
            {{ t('CUSTOMER_TRACKING.TOTAL') }} {{ t('CUSTOMER_TRACKING.ITEMS') }}:
          </span>
          <span class="ml-2 text-sm text-gray-700">{{ orderTotalItems }}</span>
          <badge
            v-if="isSeveralShipments"
            type="info"
            class="mt-2 p-0.5 lg:ml-6">
            <b-icon
              icon-name="info-filled"
              class="text-lg text-blue-400" />
            <span class="ml-1 font-normal">
              {{ t('CUSTOMER_TRACKING.SEVERAL_SHIPMENTS_MESSAGE') }}
            </span>
          </badge>
        </div>
        <div>
          <span class="text-sm font-medium text-gray-800">
            <b-icon icon-name="user" />
            {{ t('CUSTOMER_TRACKING.CUSTOMER') }}:
          </span>
          <span class="ml-2 mr-3 text-sm text-gray-700">
            {{ state.value?.customer?.name || '-' }}
          </span>
          <span class="text-sm text-gray-700">
            {{ state.value?.customer?.phone || '-' }}
          </span>
        </div>
        <div>
          <span class="text-sm font-medium text-gray-800">
            <b-icon icon-name="location " />
            {{ t('CUSTOMER_TRACKING.DELIVERY_ADDRESS') }}:
          </span>
          <span class="ml-2 text-sm text-gray-700">
            {{ deliveryAddress }}
          </span>
        </div>
      </b-box>
      <b-box
        v-for="(shipment, index) in state.value?.shipments"
        :key="shipment"
        class="mb-10">
        <div class="flex flex-col mb-2 md:flex-row md:justify-between">
          <h2 class="flex justify-between font-semibold text-lg text-gray-800 items-center sm:text-xl sm:flex-none">
            <span>
              {{ t('CUSTOMER_TRACKING.SHIPMENT') }}
              <span
                v-if="isSeveralShipments"
                class="ml-2">
                {{ `(${index + 1}/${state.value?.shipments.length})` }}
              </span>
            </span>
            <badge
              class="ml-4 h-5"
              :type="statusBadgeTypeMapping[shipment.status]">
              {{ t(shipment.status) }}
            </badge>
          </h2>
          <p>
            <span class="text-xs font-medium text-gray-800 sm:text-sm">{{ t('CUSTOMER_TRACKING.TRACKING') }}:</span>
            <Copy>
              <span class="ml-2 text-xs text-gray-700 sm:text-sm">{{ shipment?.tracking_number || '-' }}</span>
            </Copy>
          </p>
        </div>
        <div class="flex flex-col justify-start gap-2 mb-10 sm:flex-row sm:items-center">
          <div
            v-if="shipment?.carrier"
            class="flex items-center mr-2">
            <img
              :src="getCarrierLogo(shipment?.carrier)"
              class="w-6 h-6 mr-2 rounded"
              :alt="`${shipment?.carrier}-logo`" />
            <span class="font-semibold text-gray-800 sm:text-sm capitalize">{{ shipment?.carrier_name }}</span>
          </div>
          <div class="flex items-center">
            <span class="text-sm font-medium text-gray-800">{{ t('CUSTOMER_TRACKING.ESTIMATED_DELIVERY') }}:</span>
            <span class="ml-2 text-sm text-gray-700">{{ shipment?.deliver_by || '-' }}</span>
          </div>
        </div>
        <StatusBarCustomerTracking :status="shipment?.status" />
        <badge
          v-if="statusBadgeTypeMapping.Exception === statusBadgeTypeMapping[shipment.status]"
          type="warning"
          class="mt-6 p-0.5">
          <b-icon
            icon-name="info-filled"
            class="text-lg text-warning-600" />
          <span class="ml-1 font-normal text-gray-700">
            {{ t('CUSTOMER_TRACKING.EXCEPTION_MESSAGE') }}
          </span>
        </badge>
        <badge
          v-if="isCanceled(shipment)"
          type="danger"
          class="mt-6 p-0.5">
          <b-icon
            icon-name="warning"
            class="text-lg text-danger-900" />
          <span class="ml-1 font-normal text-gray-700">
            {{ t('CUSTOMER_TRACKING.CANCELLED_MESSAGE') }}
          </span>
        </badge>
        <Collapse
          class="mt-8"
          :disabled="!shipment.shipment_events.length">
          <template #lable>
            <span class="capitalize text-sm font-medium">{{ t('CUSTOMER_TRACKING.TRACKING_DETAILS') }}</span>
          </template>

          <template #content>
            <ul class="ml-6">
              <li
                v-for="event in getTrackingDetails(shipment.shipment_events)"
                :key="event"
                class="mb-4 flex">
                <div class="w-1.5 h-1.5 m-2 rounded-full border border-gray-700"></div>
                <div>
                  <div class="text-sm text-gray-700">{{ event.value }}</div>
                  <div class="text-sm text-gray-600">{{ formatDateTime(event.created_at, 'MMM. d, Y hh:mm') }}</div>
                </div>
              </li>
            </ul>
          </template>
        </Collapse>
        <div class="mt-8">
          <div class="flex text-gray-700">
            <h3 class="flex-1 text-base font-semibold">
              {{ t('CUSTOMER_TRACKING.TOTAL') }} {{ t('CUSTOMER_TRACKING.ITEMS') }}:
              <span class="ml-1 font-normal">{{ getShipmentTotalItems(shipment.shipments_products) }}</span>
            </h3>
            <h4 class="text-sm w-20 text-center sm:w-24">{{ t('CUSTOMER_TRACKING.QUANTITY') }}</h4>
            <h4 class="text-sm w-20 text-center sm:w-24">{{ t('CUSTOMER_TRACKING.TOTAL') }}</h4>
          </div>
          <div
            v-for="shipment_product in shipment?.shipments_products"
            :key="shipment_product">
            <div class="py-2 flex border-t border-b border-grey-300 items-center">
              <div class="flex-1">
                <div class="text-base font-semibold text-gray-700">{{ shipment_product.product.name }}</div>
                <div
                  v-if="shipment_product?.product.sku"
                  class="text-gray-600">
                  {{ shipment_product.product.sku }}
                </div>
              </div>
              <div class="w-20 text-gray-700 text-center sm:w-24">{{ shipment_product.count }}</div>
              <div class="w-20 text-gray-700 text-center sm:w-24">
                {{
                  convertMoneySubUnitToBaseUnit(
                    shipment_product.product.price * shipment_product.count,
                    state.value.currency
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </b-box>
      <b-box class="mb-10">
        <h2 class="font-semibold text-gray-800">
          {{ t('CUSTOMER_TRACKING.SUMMARY') }}
        </h2>
        <div class="mt-2">
          <div
            v-for="(item, idx) in summary"
            :key="item"
            class="flex mt-2 gap-2 text-gray-700">
            <div
              class="text-sm"
              :class="[idx === summary.length - 1 && 'text-gray-700 font-bold']">
              {{ item.text }}
            </div>
            <div class="mb-1.5 flex-1 border-b-2 border-dotted"></div>
            <div
              class="text-sm"
              :class="[idx === summary.length - 1 && 'text-gray-700 font-bold']">
              {{ item.value }}
            </div>
          </div>
        </div>
      </b-box>
    </div>
    <footer class="fixed bottom-0 inset-x-0 pt-4 pb-6 bg-gray-100 text-center text-sm text-gray-800">
      <div class="flex flex-col justify-center gap-4 flex-wrap sm:flex-row">
        <p class="font-serif">For more information, contact customer support:</p>
        <div class="flex flex-col gap-4 sm:flex-row">
          <a
            :href="`mailto:${state.value?.branding?.customer_support_email}`"
            class="ml-3">
            <b-icon icon-name="message-mail" />
            {{ state.value?.branding?.customer_support_email }}
          </a>
          <a
            :href="`tel:${state.value?.branding?.customer_support_phone}`"
            class="ml-3">
            <b-icon icon-name="phone" />
            {{ state.value?.branding?.customer_support_phone }}
          </a>
          <a
            :href="state.value?.branding?.customer_support_url"
            class="ml-3">
            <b-icon icon-name="globe" />
            {{ state.value?.branding?.customer_support_url_display_name }}
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'CustomerTracking'
};
</script>

<script setup>
import axios from 'axios';
import { format } from 'date-fns';
import { useTranslation } from 'i18next-vue';
import { computed, defineProps, onMounted, reactive, ref } from 'vue';

import { statusBadgeTypeMapping } from '../../../enums';
import { convertMoneySubUnitToBaseUnit, getApiUrl, getCarrierLogo } from '../../../utils';
import Badge from '../../atoms/Badge';
import BBox from '../../atoms/Box';
import Copy from '../../atoms/Copy';
import BIcon from '../../atoms/Icon';
import Spinner from '../../atoms/Spinner';
import Collapse from '../../molecules/Collapse';
import StatusBarCustomerTracking from '../../organisms/StatusBarCustomerTracking';

const { t } = useTranslation();

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  region: {
    type: String,
    required: true
  }
});

const state = reactive({
  value: {}
});

const loading = ref(false);

onMounted(async () => {
  const { region, id } = props;

  loading.value = true;

  const { data } = await axios.get(`${getApiUrl(region)}/deliveryhub-orders-service/v1/orders/track/${id}`);

  state.value = data;
  loading.value = false;
});

const isCanceled = (shipment) => {
  return [statusBadgeTypeMapping.CanceledByCarrier, statusBadgeTypeMapping.Canceled].includes(
    statusBadgeTypeMapping[shipment.status]
  );
};

const getTrackingDetails = (events) => {
  const result = events?.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at));

  return result;
};

const getProductDetails = (product) => {
  const {
    height,
    height_units = 'cm',
    width,
    width_units = 'cm',
    length,
    length_units = 'cm',
    weight,
    weight_units = 'kg'
  } = product;

  return [
    {
      label: 'Height',
      value: `${height} ${height_units}`
    },
    {
      label: 'Width',
      value: `${width} ${width_units}`
    },
    {
      label: 'Length',
      value: `${length} ${length_units}`
    },
    {
      label: 'Weight',
      value: `${weight} ${weight_units}`
    }
  ];
};

const deliveryAddress = computed(() => {
  const { billing_address: { street1 = '-', city = '-', state: nestedState = '-', country = '-' } = {} } = state.value;

  return [street1, city, nestedState, country].join(', ');
});

const isSeveralShipments = computed(() => state.value?.shipments?.length > 1);

const getShipmentTotalItems = (shipmentsProducts) => {
  return shipmentsProducts?.reduce((acc, curr) => acc + curr.count, 0);
};

const orderTotalItems = computed(() => {
  return state.value?.shipments?.reduce((acc, shipment) => {
    return (
      acc +
      shipment.shipments_products.reduce((acc, shipment_product) => {
        return acc + shipment_product.count;
      }, 0)
    );
  }, 0);
});

const summary = computed(() => {
  const { items_count } = state.value;

  const result = state.value?.shipments?.reduce(
    (acc, shipment) => {
      acc.delivery_cost += shipment.delivery_cost;
      acc.taxes += shipment.taxes;
      acc.items += shipment.shipments_products.reduce(
        (acc, shipment_product) => acc + shipment_product.count * shipment_product.product.price,
        0
      );

      return acc;
    },
    { items: 0, delivery_cost: 0, taxes: 0 }
  );

  const { items = 0, delivery_cost = 0, taxes = 0 } = result || {};

  const currency = state.value.currency || 'USD';

  return [
    {
      text: `${orderTotalItems.value} ${t('CUSTOMER_TRACKING.ITEMS')}`,
      value: convertMoneySubUnitToBaseUnit(items, currency)
    },
    {
      text: t('CUSTOMER_TRACKING.DELIVERY_COST'),
      value: convertMoneySubUnitToBaseUnit(delivery_cost, currency)
    },
    {
      text: t('CUSTOMER_TRACKING.TAXES'),
      value: convertMoneySubUnitToBaseUnit(taxes, currency)
    },
    {
      text: t('CUSTOMER_TRACKING.TOTAL'),
      value: convertMoneySubUnitToBaseUnit(items + delivery_cost + taxes, currency)
    }
  ];
});

const formatDateTime = (date, type) => format(Date.parse(date || new Date().toISOString()), type);
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.font-inter {
  font-family: 'Inter';
}
</style>
