<template>
  <span
    :class="classes"
    class="inline-flex items-center rounded font-medium text-center">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'Badge'
};
</script>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  primary: {
    type: Boolean,
    default: false
  },
  secondary: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'info',
    validator: function (value) {
      return (
        [
          'custom',
          'success',
          'info',
          'warning',
          'danger',
          'new',
          'partially-fulfilled',
          'ready-to-ship',
          'picked-up',
          'in-transit',
          'out-for-delivery',
          'delivered',
          'exception',
          'canceled-by-carrier',
          'canceled',
          'disable',
          'tag'
        ].indexOf(value) !== -1
      );
    }
  },
  size: {
    type: String,
    default: 'small',
    validator: function (value) {
      return ['small', 'large'].indexOf(value) !== -1;
    }
  }
});

const classes = computed(() => ({
  ['badge--primary']: props.primary,
  ['badge--secondary']: props.secondary,
  [`badge--${props.type || 'primary'}`]: true,
  [`badge--${props.size}`]: true
}));
</script>

<style lang="scss" scoped>
.badge--small {
  @apply px-2.5 text-xs;
}

.badge--large {
  @apply px-3 text-sm;
}

.badge--success {
  @apply bg-green-100 text-green-800;
}

.badge--info {
  @apply bg-blue-25 text-blue-800;
}

.badge--warning {
  @apply bg-yellow-100 text-yellow-800;
}

.badge--danger {
  @apply bg-red-100 text-red-800;
}

.badge--primary {
  @apply bg-indigo-100 text-indigo-800;
}

.badge--secondary {
  @apply bg-gray-100 text-gray-800;
}

.badge--new {
  @apply bg-purple-100 text-purple-500 leading-5;
}

.badge--partially-fulfilled {
  @apply bg-orange-100 text-orange-500 leading-5;
}

.badge--ready-to-ship {
  @apply bg-sky-100 text-sky-500 leading-5;
}

.badge--picked-up {
  @apply bg-cyan-100 text-cyan-500 leading-5;
}

.badge--in-transit {
  @apply bg-teal-100 text-teal-500 leading-5;
}

.badge--out-for-delivery {
  @apply bg-green-100 text-green-500 leading-5;
}

.badge--delivered {
  @apply bg-emerald-100 text-emerald-500 leading-5;
}

.badge--exception {
  @apply bg-pink-100 text-pink-500 leading-5;
}

.badge--canceled-by-carrier {
  @apply bg-rose-100 text-rose-500 leading-5;
}

.badge--canceled {
  @apply bg-rose-100 text-rose-500 leading-5;
}

.badge--disable {
  @apply bg-white border-2 border-gray-200 text-gray-200 leading-5;
}
</style>
