<template>
  <div class="flex-grow flex flex-col overflow-y-auto">
    <h1 class="sticky top-0 z-10 text-base text-gray-800 pb-2 flex flex-row items-center bg-gray-50">
      <div>
        <span class="items-title">
          <b-icon
            class="mr-1"
            :icon-name="BringgFontIcons.Layers" />
          {{ t('SHIPMENT_DETAILS.ITEMS') }}
        </span>
        <span class="items-subtitle mx-2">{{ title }}</span>
        <special-services
          with-contains
          :services="getAllSpecialServices"></special-services>
      </div>

      <b-icon
        v-if="isEditable"
        :icon-name="BringgFontIcons.Pencil"
        class="text-base text-blue-400 cursor-pointer ml-auto mr-7"
        @click="toggleEdit" />
    </h1>
    <div
      ref="itemsContainer"
      class="flex flex-col">
      <template
        v-for="(packageItem, packageIndex) in packages"
        :key="packageIndex">
        <CollapsiblePackageDetails
          :package-item="packageItem"
          :index="packageIndex"
          :collapsed="collapsibleState[packageIndex]"
          @toggle="toggleCollapse"
          @select="select" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentDetailsItems'
};
</script>
<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { orderBy } from 'lodash';
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';

import BIcon from '@/components/atoms/Icon';
import SpecialServices from '@/components/atoms/SpecialServices/index.vue';
import CollapsiblePackageDetails from '@/components/organisms/CollapsiblePackageDetails';
import { ToggleDrawerKey } from '@/components/pages/ShipmentDetails/constants';

const { t } = useTranslation();
const store = useStore();

const toggleDrawer = inject(ToggleDrawerKey);

const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);

const disableShipmentProductsEdit = store.getters['auth/accountSettings'].disable_shipment_products_edit;
const isCustomService = computed(() => store.getters['auth/isCustomService']);

const isEditable = computed(() => !disableShipmentProductsEdit && !isCustomService.value);

const packages = computed(() => orderBy(shipmentDetails.value.shipments_packages, 'id'));

const title = computed(() => {
  const totalItemsCount = packages.value.reduce(
    (acc, pkg) => acc + pkg.shipments_products.reduce((acc, product) => acc + product.count, 0),
    0
  );

  const packagesCount = packages.value.length;

  return `(${totalItemsCount} ${t('SHIPMENT_DETAILS.ITEMS')}, ${packagesCount} ${t('SHIPMENT_DETAILS.PACKAGES')})`;
});

const collapsibleState = ref(
  packages.value.reduce(
    (acc, _, i) => ({
      ...acc,
      [i]: true
    }),
    {}
  )
);

const toggleCollapse = (index) => (collapsibleState.value[index] = !collapsibleState.value[index]);

const select = (packageItemId, index) => {
  store.dispatch('shipments/selectPackage', packageItemId);

  collapsibleState.value[index] = false;

  const childDiv = itemsContainer.value.children[index];

  if (childDiv) {
    childDiv.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }
};

const toggleEdit = () => toggleDrawer('packages');

const itemsContainer = ref();

const getAllSpecialServices = computed(() => {
  return packages.value.reduce((allSpecialServices, packageItem) => {
    const packageSpecialServices = packageItem.shipments_products.reduce((pkgSpecialServices, { product }) => {
      if (product?.special_services) {
        Object.entries(product.special_services).forEach(([key, value]) => {
          if (value) {
            pkgSpecialServices[key] = value;
          }
        });
      }

      return pkgSpecialServices;
    }, {});

    Object.entries(packageSpecialServices).forEach(([key, value]) => {
      if (value) {
        allSpecialServices[key] = value;
      }
    });

    return allSpecialServices;
  }, {});
});
</script>

<style lang="scss" scoped>
.items-title {
  color: var(--Color-Neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}

.items-subtitle {
  color: var(--Color-Neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
</style>
