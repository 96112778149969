<template>
  <div
    v-if="shouldShowComponent"
    style="display: inline-flex"
    class="flex text-sm text-gray-700 text-xs flex-row items-start items-center ml-auto bg-blue-50 w-content rounded-md pl-0.5 pr-1 mt-1">
    <span
      v-if="withContains"
      class="pl-2">
      {{ `${t('SPECIAL_SERVICES.CONTAINS')}:` }}
    </span>
    <div
      v-if="services?.hazardous"
      class="flex flex-row ml-2 items-center"
      :class="shoudAddComma ? '' : 'mr-2'">
      <b-icon
        class="mr-1 text-lg"
        :icon-name="BringgFontIcons.WarningNotFilled" />
      <span>
        {{ t('SPECIAL_SERVICES.HAZARDOUS') }}
      </span>
    </div>
    <span v-if="shoudAddComma">,</span>
    <div
      v-if="services?.fragile"
      class="relative flex flex-row items-start"
      :class="services?.fragile && services?.age_restricted ? '' : 'mr-2'">
      <b-icon
        class="text-3xl align-center absolute"
        :icon-name="BringgFontIcons.Fragile" />
      <span class="ml-7">
        {{ t('SPECIAL_SERVICES.FRAGILE') }}
      </span>
    </div>
    <span v-if="services?.fragile && services?.age_restricted">,</span>
    <div
      v-if="services?.age_restricted"
      class="mr-2 flex-row ml-2">
      <span class="mr-1 font-semibold">{{ `${services?.age_restricted}+` }}</span>
      <span class="whitespace-nowrap">
        {{ t('SPECIAL_SERVICES.AGE_RESTRICTED') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialServices'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineProps } from 'vue';

import BIcon from '@/components/atoms/Icon';

const { t } = useTranslation();

const props = defineProps({
  services: {
    type: Object,
    default: () => ({})
  },
  withContains: {
    type: Boolean,
    default: false
  }
});

const shoudAddComma = computed(
  () => props.services?.hazardous && (props.services?.fragile || props.services?.age_restricted)
);

const shouldShowComponent = computed(
  () => props.services?.fragile || props.services?.hazardous || props.services?.age_restricted
);
</script>

<style scoped>
.align-center {
  align-self: center;
}
</style>
