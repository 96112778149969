<template>
  <div>
    <div class="border-b border-gray-300 h-10 box-content">
      <nav
        class="-mb-px flex h-10"
        aria-label="Tabs">
        <slot name="before" />

        <div class="relative overflow-auto flex">
          <div
            v-show="state.gradientShown"
            class="gradient gradient-left" />
          <div
            ref="wrapper"
            class="flex overflow-auto test">
            <a
              v-for="(item, index) in fullData"
              :key="`head-${item.key}`"
              class="h-10 whitespace-nowrap pt-2 border-b-2 border-transparent text-sm font-semibold cursor-pointer group"
              :class="{
                'border-blue-500': active === item.key,
                'hover:border-gray-300': !props.removable && active !== item.key,
                'px-3': !props.removable,
                'pl-3.5 pr-0': props.removable
              }"
              data-toggle="tab"
              role="tab"
              @click="onChange(item.key)">
              <div
                class="h-8 -mt-1.5 rounded flex items-center group"
                :class="{
                  '-ml-1 pl-2 pr-1 group-hover:bg-gray-100': props.removable
                }">
                <span
                  :class="{
                    'text-blue-500': active === item.key,
                    'text-gray-700': active !== item.key,
                    'group-hover:text-gray-600': !props.removable && active !== item.key,
                    'group-hover:text-blue-400': props.removable && active !== item.key
                  }">
                  {{ item.label }}
                </span>

                <b-icon
                  v-if="props.removable"
                  :icon-name="BringgFontIcons.Close"
                  class="text-blue-400 font-semibold mt-0.5 ml-0.5 opacity-0 group-hover:opacity-100 hover:text-red-400"
                  @click.stop="onRemove(item.key, index)" />
              </div>
            </a>
          </div>

          <div
            v-show="state.gradientShown"
            class="gradient gradient-right" />
        </div>
      </nav>
    </div>
    <div class="py-4">
      <template
        v-for="item in fullData"
        :key="`body-${item.key}`">
        <div
          v-if="active === item.key"
          role="tabpanel">
          <slot
            :name="item.key"
            :label="item.label"
            :content="item.content" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { computed, defineEmits, defineProps, nextTick, onMounted, reactive, ref, watch } from 'vue';

import BIcon from '@/components/atoms/Icon/index.vue';

const wrapper = ref(null);

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  active: {
    type: String,
    default: null
  },
  removable: {
    type: Boolean,
    default: false
  }
});

const state = reactive({
  active: props.data[0].key,
  gradientShown: false
});

const emit = defineEmits(['change', 'remove']);

const active = computed(() => props.active || state.active);

const fullData = computed(() =>
  props.data.map((item) => ({
    ...item,
    key: item.key || item.label.toLowerCase()
  }))
);

const onChange = (key) => {
  emit('change', key);
  state.active = key;
};

const onRemove = (key, index) => {
  emit('remove', key, index);
};

const calculateGradient = () => {
  nextTick(() => {
    state.gradientShown = wrapper.value?.scrollWidth > wrapper.value?.clientWidth;
  });
};

onMounted(() => {
  calculateGradient();
});

watch(
  () => props.data,
  () => {
    calculateGradient();
  }
);
</script>

<style lang="scss" scoped>
.gradient {
  &-left {
    left: 0;
    background: linear-gradient(180deg, transparent, #fff);
    background: -webkit-linear-gradient(180deg, transparent, #fff);
  }

  &-right {
    right: 0;
    background: linear-gradient(0, transparent, #fff);
    background: -webkit-linear-gradient(0, transparent, #fff);
  }

  @apply absolute top-0 bottom-0 w-5 pointer-events-none;
}
</style>
