<template>
  <div class="p-5">
    <div class="flex justify-between p-5">
      <div class="flex justify-start items-center">
        <div>
          {{ t('NOTES.TITLE') }}
        </div>
        <Tooltip
          v-if="props.withTooltip"
          class="ml-1"
          placement="topLeft">
          <template #content>
            <icon
              class="text-xl text-gray-600"
              :icon-name="BringgFontIcons.Info" />
          </template>
          <template #title>
            <span class="font-semibold text-sm">
              {{ props.tooltipText }}
            </span>
          </template>
        </Tooltip>
      </div>

      <button
        ref="closeDrawerButton"
        type="button"
        @click="closeDrawer">
        <icon
          class="text-m text-gray-800"
          :icon-name="BringgFontIcons.Close" />
      </button>
    </div>
    <div>
      <div
        ref="scrollContainer"
        class="overflow-auto pb-3"
        :style="{ maxHeight: `${computedHeight}px` }">
        <div
          v-for="(note, index) in state.notes"
          :key="index">
          <note
            :note="note"
            :index="index"
            @delete="deleteDraft"
            @save="saveNote"
            @edit="handleScroll"
            @draft="saveDraft" />
        </div>
      </div>
      <note
        v-if="state.addNewNote"
        :note="''"
        new-note
        :index="state.notes.length"
        @save="saveNote"
        @delete="deleteDraft"
        @discard="discardNewNote"
        @draft="saveDraft" />
      <div class="w-full flex justify-center items-center">
        <badge
          v-if="state.addNewNote"
          type="info"
          class="p-2 mt-5">
          <icon
            icon-name="info-filled"
            class="text-xl text-blue-400" />
          <span class="ml-1 font-normal">
            {{ t('NOTES.INFO_MESSAGE') }}
          </span>
        </badge>
      </div>

      <button
        v-if="!state.addNewNote"
        type="button"
        class="relative flex justify-start items-center text-blue-400 ml-4 top-6"
        @click="addNewNote">
        <div class="flex items-center justify-center h-7 w-7 mr-2 bg-blue-400 rounded-full">
          <icon
            class="text-white text-lg mt-0.5"
            :icon-name="BringgFontIcons.Plus" />
        </div>

        <div class="ml-1 text-m">{{ t('NOTES.ADD_NOTE_BUTTON') }}</div>
      </button>
    </div>
    <confirm-modal
      :is-shown="shouldShowDiscardSaveModal"
      @accept="saveNotesDrafts"
      @close="closeDiscardModal"
      @reject="discardSaveModal">
      <template #title>
        {{ t('NOTES.DISCARD_MODAL.TITLE') }}
      </template>
      <template #description>
        {{ t('NOTES.DISCARD_MODAL.DESCRIPTION') }}
      </template>
      <template #accept-label>
        {{ t('NOTES.DISCARD_MODAL.ACCEPT_BUTTON') }}
      </template>
      <template #reject-label>
        {{ t('DISCARD_BUTTON') }}
      </template>
    </confirm-modal>
    <confirm-modal
      :is-shown="state.showDeleteModal"
      @accept="deleteNote(state.noteToDelete)"
      @close="closeDeleteModal"
      @reject="closeDeleteModal">
      <template #title>
        {{ t('NOTES.DELETE_MODAL.TITLE') }}
      </template>
      <template #description>
        {{ t('NOTES.DELETE_MODAL.DESCRIPTION') }}
      </template>
      <template #accept-label>
        {{ t('NOTES.DELETE_MODAL.ACCEPT_BUTTON') }}
      </template>
      <template #reject-label>
        {{ t('NOTES.DELETE_MODAL.DISCARD_BUTTON') }}
      </template>
    </confirm-modal>
  </div>
</template>

<script>
export default {
  name: 'Notes'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watchEffect } from 'vue';

import Badge from '@/components/atoms/Badge';
import Icon from '@/components/atoms/Icon';
import Note from '@/components/atoms/Note/index.vue';
import Tooltip from '@/components/atoms/ToolTip/index.vue';
import ConfirmModal from '@/components/organisms/ConfirmModal';

const scrollContainer = ref(null);
const closeDrawerButton = ref(null);

const emit = defineEmits(['close', 'commit']);

const props = defineProps({
  withTooltip: {
    type: Boolean,
    default: false
  },
  tooltipText: {
    type: String,
    default: ''
  },
  notes: {
    type: Array,
    required: true
  }
});

const state = reactive({
  notes: props.notes,
  addNewNote: false,
  windowHeight: window.innerHeight,
  clickedElementClass: null,
  showDiscardSaveModal: false,
  showDeleteModal: false,
  drafts: {},
  noteToDelete: null
});

const { t } = useTranslation();

const ifDraftsIsEmpty = computed(() => Object.keys(state.drafts).length === 0);
const clickedOutsideDrawer = computed(() => state.clickedElementClass === 'rest');

const shouldShowDiscardSaveModal = computed(() => {
  return !ifDraftsIsEmpty.value && (state.showDiscardSaveModal || clickedOutsideDrawer.value);
});

const onClick = (event) => {
  state.clickedElementClass = event?.target?.className;
};

const updateHeight = () => {
  state.windowHeight = window.innerHeight;
};

const saveDraft = ({ note, index }) => {
  state.drafts[index] = note;
};

const discardSaveModal = () => {
  state.showDiscardSaveModal = false;
  state.drafts = {};

  emit('close');
};

const closeDiscardModal = () => {
  state.showDiscardSaveModal = false;
};

const closeDeleteModal = () => {
  state.showDeleteModal = false;
};

const saveNotesDrafts = () => {
  state.showDiscardSaveModal = false;

  Object.entries(state.drafts).forEach(([key, value]) => {
    state.notes[key] = value;
  });

  state.drafts = {};

  emit('commit', state.notes);
  emit('close');
};

onMounted(() => {
  updateHeight();
  window.addEventListener('resize', updateHeight);

  watchEffect(() => {
    if (state.addNewNote) {
      nextTick(() => {
        if (scrollContainer.value) {
          scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight;
        }
      });
    }
  });

  document.addEventListener('mousedown', onClick);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateHeight);
  document.removeEventListener('mousedown', onClick);
});

const computedHeight = computed(() => (state.addNewNote ? state.windowHeight - 420 : state.windowHeight - 270));

const deleteDraft = (index) => {
  state.noteToDelete = index;
  state.showDeleteModal = true;
};

const deleteNote = (index) => {
  if (index === state.notes.length) {
    state.addNewNote = false;

    return;
  }

  state.notes.splice(index, 1);
  state.noteToDelete = null;
  state.showDeleteModal = false;

  emit('commit', state.notes);
};

const saveNote = ({ note, index }) => {
  if (index === state.notes.length && note === '') {
    state.addNewNote = false;

    return;
  }

  state.addNewNote = false;
  state.notes[index] = note;
  delete state.drafts[index];

  emit('commit', state.notes);
};

const addNewNote = () => {
  state.addNewNote = true;
};

const discardNewNote = (index) => {
  if (index === state.notes.length) {
    state.addNewNote = false;
  }

  delete state.drafts[index];
};

const handleScroll = async (index) => {
  if (index === state.notes.length - 1 && scrollContainer.value) {
    setTimeout(() => {
      scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight;
    }, 0);
  }
};

const closeDrawer = () => {
  if (!ifDraftsIsEmpty.value) {
    state.showDiscardSaveModal = true;

    return;
  }

  emit('close');
};
</script>
