<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="pl-10 pr-6 pt-6 flex h-full flex-col overflow-hidden">
      <alert
        v-if="error"
        class="mb-8"
        type="danger"
        :close="true"
        @close="clearError">
        {{ t(error) }}
      </alert>

      <b-table
        class="locations"
        :loading="loading"
        :fields="fields"
        :data="shippingLocations"
        row-key="id"
        last-update>
        <template #right-filter-bar>
          <b-button
            v-if="isAdmin"
            class="ml-2 px-6 py-2"
            data-id="addLocation"
            @click="router.push('add-location')">
            {{ t('SHIPPING_LOCATIONS.ADD_LOCATION') }}
          </b-button>
        </template>
        <template #cell(name)="{ row }">
          <span
            class="text-blue-400 hover:text-blue-600 cursor-pointer"
            @click="selectAction(row, 'edit-shipping-location')">
            {{ row.name }}
          </span>
        </template>
        <template #cell(type)="{ row }">
          {{ row.type }}
        </template>
        <template #cell(city)="{ row }">
          {{ row.address.city }}
        </template>
        <template #cell(country)="{ row }">
          {{ row.address.country }}
        </template>
        <template #cell(lastUpdated)="{ row }">
          {{ formatLastUpdatedColumn(row.updated_at) }}
        </template>
        <template #cell(actions)="{ row }">
          <dropdown
            :options="getActionOptions(row)"
            button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
            :left-align="false"
            @select="selectAction(row, $event)">
            <template #button>
              <icon
                class="mr-3 text-xl"
                icon-name="menu-horizontal" />
            </template>
          </dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Packaging'
};
</script>

<script setup>
import format from 'date-fns/format';
import { useTranslation } from 'i18next-vue';
import { computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Alert from '@/components/atoms/Alert';
import BButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import Dropdown from '@/components/molecules/Dropdown';
import { ALL_LOCATIONS } from '@/components/organisms/Rule/constants';
import BTable from '@/components/organisms/Table';

const store = useStore();
const router = useRouter();
const { t } = useTranslation();

onBeforeMount(async () => {
  await Promise.all([
    store.dispatch('shippingLocations/loadShippingLocations'),
    store.dispatch('shippingRules/loadRules'),
    store.dispatch('shippingAccounts/loadShippingAccounts')
  ]);
});

const isAdmin = computed(() => store.getters['auth/isAdmin']);
const shippingLocations = computed(() => store.getters['shippingLocations/items']);
const ruleLocations = computed(() => {
  const set = new Set(store.state.shippingRules.rules.flatMap((rule) => rule.locations));
  const hasAll = set.has(ALL_LOCATIONS);

  return { hasAll, ids: [...set].map(Number) };
});
const shippingAccountsLocationsIds = computed(() =>
  store.state.shippingAccounts.shippingLocations.map((location) => location.shipping_location_id)
);
const loading = computed(() => store.state.shippingLocations.loading);
const error = computed(() => store.state.shippingLocations.error);

const fields = computed(() => {
  const fields = [
    { key: 'name', label: 'SHIPPING_LOCATIONS.NAME_FIELD' },
    { key: 'type', label: 'SHIPPING_LOCATIONS.TYPE_FIELD' },
    { key: 'source_id', label: 'FIELDS.SOURCE_ID_FIELD' },
    { key: 'city', label: 'SHIPPING_LOCATIONS.CITY_FIELD' },
    { key: 'country', label: 'SHIPPING_LOCATIONS.COUNTRY_FIELD' },
    { key: 'lastUpdated', label: 'SHIPPING_LOCATIONS.LAST_UPDATED_FIELD' }
  ];

  if (store.getters['auth/isAdmin']) {
    fields.push({ key: 'actions', label: 'ACTIONS_FIELD' });
  }

  return fields;
});

const getActionOptions = (row) => {
  const disabledByRule = ruleLocations.value.hasAll || ruleLocations.value.ids.includes(row.id);
  const disabledByShippingAccount = shippingAccountsLocationsIds.value.includes(row.id);

  const isRemoveDisabled = disabledByRule || disabledByShippingAccount;

  let tooltipText = '';

  if (disabledByRule) {
    tooltipText = t('SHIPPING_LOCATIONS.REMOVE_TOOLTIP');
  } else if (disabledByShippingAccount) {
    tooltipText = t('SHIPPING_LOCATIONS.REMOVE_TOOLTIP_SHIPPING_ACCOUNT');
  }

  return [
    {
      bringgIcon: 'pencil',
      value: 'edit-shipping-location',
      label: 'QUICK_ACTIONS.EDIT'
    },
    {
      bringgIcon: 'trash',
      value: 'remove-shipping-location',
      label: 'QUICK_ACTIONS.REMOVE',
      disabled: isRemoveDisabled,
      tooltip: tooltipText
    },
    {
      bringgIcon: 'copy',
      value: 'duplicate-shipping-location',
      label: 'QUICK_ACTIONS.DUPLICATE'
    }
  ];
};

const clearError = () => store.commit('shippingLocations/clearError');

const formatLastUpdatedColumn = (updatedAt) => format(Date.parse(updatedAt), 'MMM. d, Y, HH:mm');

const selectAction = async (row, value) => {
  const actions = {
    'edit-shipping-location': () => {
      // eslint-disable-next-line no-console
      router.push(`edit-location/${row.id}`);
    },
    'remove-shipping-location': async () => {
      await store.dispatch('shippingLocations/removeShippingLocation', { id: row.id });
    },
    'duplicate-shipping-location': async () => {
      router.push(`duplicate-location/${row.id}`);
    }
  };

  return await actions[value]();
};
</script>
