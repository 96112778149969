import { withLoading } from '../../utils';

const state = {
  items: [],
  loading: false,
  error: ''
};

const getters = {
  carrierNames: (state) =>
    state.items.reduce((result, item) => {
      result[item.slug] = item.display_name;

      return result;
    }, {}),

  packagingTypes(state) {
    const result = {};

    for (let i = 0; i < state.items.length; ++i) {
      const { slug, packaging_types: packagingTypes } = state.items[i];

      result[slug] = packagingTypes.map(({ name, slug }) => ({ label: name, value: slug }));
    }

    return result;
  },

  labelTypes(state) {
    const result = {};

    for (let i = 0; i < state.items.length; ++i) {
      const { slug, label_types: labelTypes } = state.items[i];

      result[slug] = labelTypes.map(({ type }) => type);
    }

    return result;
  },

  serviceTypes(state) {
    const result = {};

    for (let i = 0; i < state.items.length; ++i) {
      const { slug, service_types: serviceTypes } = state.items[i];

      result[slug] = serviceTypes.map(({ name, slug }) => ({ label: name, value: slug }));
    }

    return result;
  },

  shippingMethods(state) {
    const result = {};

    for (let i = 0; i < state.items.length; ++i) {
      const { slug, shipping_methods: shippingMethods } = state.items[i];

      result[slug] = shippingMethods;
    }

    return result;
  },

  getServiceTypeNameBySlugAndCarrier: (state, getters) => (slug, carrier) => {
    const services = getters.serviceTypes[carrier] || [];

    for (let i = 0; i < services.length; i++) {
      const item = getters.serviceTypes[carrier][i];

      if (item.value === slug) {
        return item.label;
      }
    }

    return slug;
  }
};

const actions = {
  async loadCarriers({ commit, state, rootGetters }) {
    commit('clearError');

    const params = {
      fields:
        'auth,name,display_name,description,short_description,shipping_methods,service_types,' +
        'pickup_service_types,private,has_master_account,master_account_countries,packaging_types,label_types'
    };

    const url = `${rootGetters['auth/carrierServiceApiUrl']}/meta/carriers?` + new URLSearchParams(params);

    await withLoading(state, async () => {
      try {
        const carriersMetaResponse = await fetch(url, {
          headers: { zkkey: rootGetters['auth/deliveryhubAccountKey'] }
        });

        if (carriersMetaResponse.status !== 200) {
          throw Error(`Request failed with ${carriersMetaResponse.status}`);
        }

        const carriers = await carriersMetaResponse.json();

        commit(
          'setCarriers',
          carriers.filter((carrier) => !carrier.private && Object.keys(carrier.auth).length)
        );
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(error);
        }

        state.error = 'CARRIERS_LOADING_FAIL';
      }
    });
  }
};

const mutations = {
  setCarriers(state, value) {
    state.items = value;
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
