<template>
  <span
    :class="classes"
    class="inline-flex items-center rounded font-medium text-center">
    {{ t(status) }}
  </span>
</template>

<script>
export default {
  name: 'ShipmentPackageStatus'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed } from 'vue';

import { ShipmentPackageStatus } from '@/enums';

const props = defineProps({
  status: {
    type: String,
    required: true,
    validator: (value) => Object.values(ShipmentPackageStatus).includes(value)
  }
});

const { t } = useTranslation();

const styleMapper = {
  [ShipmentPackageStatus.ReadyToShip]: 'ready-to-ship',
  [ShipmentPackageStatus.PickedUp]: 'picked-up',
  [ShipmentPackageStatus.InTransit]: 'in-transit',
  [ShipmentPackageStatus.OutForDelivery]: 'out-for-delivery',
  [ShipmentPackageStatus.Delivered]: 'delivered',
  [ShipmentPackageStatus.Exception]: 'exception',
  [ShipmentPackageStatus.CanceledByCarrier]: 'canceled-by-carrier'
};

const style = styleMapper[props.status];

const classes = computed(() => ({
  [`badge--${style}`]: true,
  [`badge--small`]: true
}));
</script>

<style scoped lang="scss">
.badge--small {
  @apply px-2.5 text-xs;
}

.badge--ready-to-ship {
  @apply bg-sky-100 text-sky-500 leading-5;
}

.badge--picked-up {
  @apply bg-cyan-100 text-cyan-500 leading-5;
}

.badge--in-transit {
  @apply bg-teal-100 text-teal-500 leading-5;
}

.badge--out-for-delivery {
  @apply bg-green-100 text-green-500 leading-5;
}

.badge--delivered {
  @apply bg-emerald-100 text-emerald-500 leading-5;
}

.badge--exception {
  @apply bg-pink-100 text-pink-500 leading-5;
}

.badge--canceled-by-carrier {
  @apply bg-rose-100 text-rose-500 leading-5;
}
</style>
