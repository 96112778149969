<template>
  <ul class="list-disc">
    <li
      v-for="(item, index) in data"
      :key="item"
      class="inline-block whitespace-nowrap">
      <template v-if="item.label">
        {{ t(item.label) + ' ' }}
      </template>
      <b>
        <b-editable
          v-if="item.onEdit"
          class="inline-flex"
          placeholder="-"
          :model-value="item.value"
          @update:model-value="item.onEdit" />

        <template v-else-if="item.href">
          <a
            class="text-blue-400"
            :href="item.href">
            {{ item.value }}
          </a>
        </template>

        <template v-else>
          {{ item.value }}
        </template>

        <span
          v-if="index !== data.length - 1"
          class="mx-2">
          &#x2022;
        </span>
      </b>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DetailsInfoLine'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineProps } from 'vue';

import BEditable from '@/components/molecules/Editable/index.vue';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Array,
    required: true
  }
});

const data = computed(() => props.value.filter((item) => item?.value || item?.onEdit));
</script>
