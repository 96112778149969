<template>
  <Combobox
    v-slot="{ open }"
    :data-id="dataId"
    as="div"
    :class="{ 'flex justify-center items-center gap-2': pagination }"
    :model-value="inputValue"
    :disabled="disabled"
    :multiple="multiple"
    :nullable="nullable"
    @update:model-value="handleInput">
    <ComboboxLabel
      v-if="$slots.label"
      class="block text-xs"
      :class="[pagination ? 'font-normal text-gray-700' : 'font-medium text-gray-600 mb-1']">
      <slot name="label" />
    </ComboboxLabel>
    <div class="relative">
      <template v-if="$slots['button']">
        <ComboboxButton>
          <slot name="button" />
        </ComboboxButton>
      </template>
      <template v-else>
        <div
          class="relative w-full bg-white border rounded pr-4 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          :class="[errorMessage ? 'border-red-400' : pagination ? 'border-gray-100' : 'border-gray-300']">
          <ComboboxInput
            :disabled="disabled"
            :display-value="displayValue"
            :placeholder="placeholder"
            :class="[
              'w-full border-none pl-3 text-sm leading-5 text-gray-900 placeholder-gray-400 focus:ring-0 rounded',
              {
                'cursor-not-allowed': disabled,
                'h-10 pl-1': size !== 'small',
                'h-8': size === 'small'
              }
            ]"
            @click="clickButton(open)"
            @change="data.searchFilter = $event.target.value" />
          <ComboboxButton
            ref="comboboxButton"
            :disabled="disabled"
            :class="{
              'cursor-not-allowed': disabled
            }"
            class="absolute inset-y-0 right-0 flex items-center pr-2">
            <b-icon
              v-if="open"
              icon-name="chevron-up"
              class="ml-3 text-base text-gray-600"
              aria-hidden="true" />
            <b-icon
              v-else
              icon-name="chevron"
              class="ml-3 text-base text-gray-600"
              aria-hidden="true" />
          </ComboboxButton>
        </div>
      </template>

      <transition
        :class="optionsTopPosition || pagination ? 'bottom-full' : ''"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        class="absolute mb-1 w-full rounded bg-white shadow-lg">
        <ComboboxOptions
          :class="[
            contentWidth ? 'w-min' : 'w-full',
            optionsRightPosition ? 'right-0' : 'left-0',
            pagination && 'p-2',
            optionsClass
          ]"
          class="absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <span
            v-if="!options.length"
            class="p-4">
            <slot name="nooptions">The list is empty</slot>
          </span>
          <ComboboxOption
            v-for="option in filteredOptions"
            v-slot="{ active, selected }"
            :key="option"
            :value="option.value || option"
            as="template">
            <li
              class="flex items-center"
              :class="[
                'cursor-pointer select-none relative',
                {
                  'text-white bg-blue-400': active,
                  'text-blue-500 bg-gray-100': pagination && active,
                  'text-gray-900': !active,
                  'h-10 py-2': size === 'large',
                  'h-8 py-1': size === 'small',
                  'pl-8 pr-4': !pagination,
                  'justify-center rounded': pagination
                }
              ]"
              :data-id="option.value || option">
              <div
                class="leading-8"
                :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ option.label || option.value || option }}
              </div>

              <div
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-blue-400',
                  'absolute inset-y-0 left-0 flex items-center pl-1.5'
                ]">
                <b-icon
                  v-if="!pagination"
                  icon-name="selected"
                  class="text-xl"
                  aria-hidden="true" />
              </div>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </transition>
    </div>
    <p
      v-if="errorMessage"
      class="mt-2 text-sm text-red-600">
      <slot name="error-message">
        {{ errorMessage }}
      </slot>
    </p>
  </Combobox>
</template>

<script>
export default {
  name: 'BSelect'
};
</script>

<script setup>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue';
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps, reactive, ref, watch } from 'vue';

import BIcon from '../Icon';

const comboboxButton = ref(null);

const props = defineProps({
  value: {
    type: null,
    required: true
  },
  id: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: '-- Blank --'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  contentWidth: {
    type: Boolean,
    default: false
  },
  optionsClass: {
    type: String,
    default: ''
  },
  optionsRightPosition: {
    type: Boolean,
    default: false
  },
  optionsTopPosition: {
    type: Boolean,
    default: false
  },
  search: {
    type: Boolean,
    default: true
  },
  multiple: {
    type: Boolean,
    default: false
  },
  nullable: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'large',
    validator: (value) => ['small', 'large'].includes(value)
  },
  pagination: {
    type: Boolean,
    default: false
  },
  dataId: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:value']);

const data = reactive({
  searchFilter: ''
});

const connectedField = useField(props.name || props.id, undefined, {
  initialValue: props.value
});

watch(
  () => props.value,
  (value) => connectedField.handleChange(value)
);

const inputValue = connectedField.value;
const errorMessage = connectedField.errorMessage;
// const meta = connectedField.meta;

const filteredOptions = computed(() =>
  props.options.filter(
    (item) => !data.searchFilter || (JSON.stringify(item).toLowerCase() || '').includes(data.searchFilter.toLowerCase())
  )
);

const optionsMapping = computed(() =>
  props.options.reduce((result, { label, value }) => {
    result[value] = label;

    return result;
  }, {})
);

const handleInput = (value) => {
  connectedField.handleChange(value);
  emit('update:value', value);
  data.searchFilter = '';
};

const displayValue = (value) => {
  if (value && Array.isArray(value)) {
    return value.map((item) => optionsMapping.value[item] || item).join(', ');
  }

  return optionsMapping.value[value] || value;
};

const clearSearchFilter = () => (data.searchFilter = '');

const clickButton = (isOpen) => {
  if (isOpen) {
    clearSearchFilter();
  }
  comboboxButton.value.el.click();
};
</script>
