<template>
  <div
    :id="pathName"
    class="h-screen">
    <sidebar
      :current-name="pathName"
      :is-visible="state.isSidebarVisible"
      :is-small="state.isSidebarSmall"
      @close="closeSidebar"
      @toggle="toggleSidebar" />
    <div :class="['h-full flex flex-col', state.isSidebarSmall ? 'small-sidebar' : 'md:pl-60']">
      <nav-bar
        :title="title"
        :items="tabs"
        @toggle-sidebar="openSidebar">
        <template #right>
          <b-dropdown
            value="two"
            button-classes="border-none shadow-none p-0 w-auto"
            :left-align="false"
            :options="[
              {
                value: 'manageProfile',
                label: t('PROFILE_DROPDOWN.MANAGE_PROFILE')
              },
              {
                value: 'logout',
                label: t('PROFILE_DROPDOWN.LOGOUT'),
                bringgIcon: BringgFontIcons.Logout
              }
            ]"
            @select="onDropdown">
            <template #button>
              <div class="flex items-center">
                <div
                  class="relative flex items-center justify-center bg-blue-400 w-8 h-8 text-white text-xs rounded-full">
                  {{ initials }}
                  <div
                    v-if="expiration"
                    class="w-2 h-2 bg-orange-600 absolute bottom-0 right-0 rounded-full border-2 border-gray-50" />
                </div>
                <b-icon
                  class="text-gray-600 ml-0.5"
                  :icon-name="BringgFontIcons.Chevron" />
              </div>
            </template>

            <template #header>
              <div class="p-4">
                <div class="flex">
                  <div class="">
                    <div
                      class="flex mr-2 items-center justify-center bg-blue-400 w-6 h-6 text-white text-xs rounded-full">
                      {{ initials }}
                    </div>
                  </div>

                  <div class="overflow-hidden">
                    <div
                      class="text-gray-800 overflow-hidden overflow-ellipsis"
                      :title="name">
                      {{ name }}
                    </div>
                    <div
                      class="text-gray-600 text-xs overflow-hidden overflow-ellipsis"
                      :title="email">
                      {{ email }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="expiration"
                  class="flex mt-2">
                  <img
                    src="../../../assets/img/warn-icon.svg"
                    alt="expire" />
                  <span class="ml-1 text-xs text-orange-600">{{ t('PROFILE_DROPDOWN.TRIAL') }} {{ expiration }}</span>
                </div>
              </div>
            </template>
          </b-dropdown>
        </template>
      </nav-bar>
      <main
        class="h-full overflow-hidden"
        @click="closeSidebar">
        <slot>
          <router-view v-slot="{ Component, route }">
            <transition
              name="fade"
              mode="out-in">
              <keep-alive v-if="route.meta.keep">
                <div
                  :key="route.name"
                  class="h-full">
                  <component :is="Component" />
                </div>
              </keep-alive>
              <div
                v-else
                :key="route.fullPath"
                class="h-full flex flex-col">
                <component :is="Component" />
              </div>
            </transition>
          </router-view>
        </slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTemplate'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { formatDistanceToNowStrict } from 'date-fns';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, reactive } from 'vue';
import { useStore } from 'vuex';

import router from '../../../router';
import BIcon from '../../atoms/Icon';
import BDropdown from '../../molecules/Dropdown';
import NavBar from '../../organisms/NavBar';
import Sidebar from '../../organisms/Sidebar';

const { t } = useTranslation();

defineProps({
  pathName: {
    type: String,
    required: true
  },
  title: {
    type: String,
    default: ''
  },
  tabs: {
    type: Array,
    default: () => []
  }
});

const store = useStore();
const emit = defineEmits(['sign-out']);

const state = reactive({
  isSidebarVisible: false,
  isSidebarSmall: false
});

const email = computed(() => store.state.auth.user?.email ?? '');
const name = computed(() => store.state.auth.user?.name ?? '');

const initials = computed(() => {
  const [firstName = '', secondName = ''] = name.value.split(' ');

  return `${firstName.charAt(0).toUpperCase()}${secondName.charAt(0).toUpperCase()}`;
});

const expiration = computed(() => {
  const activeUntil = store.state.auth.accountSettings?.active_until;

  if (!activeUntil) {
    return;
  }

  return formatDistanceToNowStrict(new Date(activeUntil), {
    unit: 'day'
  });
});

const openSidebar = () => (state.isSidebarVisible = true);
const closeSidebar = () => (state.isSidebarVisible = false);
const toggleSidebar = () => (state.isSidebarSmall = !state.isSidebarSmall);

const onDropdown = (value) => {
  if (value === 'logout') {
    emit('sign-out');
  }
  if (value === 'manageProfile') {
    router.push({ name: 'profile-management' });
  }
};
</script>

<style lang="scss" scoped>
.small-sidebar {
  margin-left: 72px;
}
</style>
