import { t } from 'i18next';
import { omit } from 'lodash';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { MimeTypes } from '@/constants';

import { createDebounce, decodeAndDetectMimeType, replacePaginationState } from './utils';

export const useShippingDocumentsModal = (shipments, store) => {
  const documents = computed(() =>
    shipments.value.map((shipment) => ({
      id: shipment.id,
      orderId: shipment?.order_id,
      fulfillmentId: shipment?.fulfillment_id,
      trackingNumber: shipment.tracking_number,
      commercial_invoice: shipment.commercial_invoice,
      packages: shipment?.shipments_packages?.map((shipmentPackage) => {
        const getTypedArrayData = (label) => {
          if (!label) {
            return null;
          }

          let typedArrayData = decodeAndDetectMimeType(label);

          if (typedArrayData?.mimeType === MimeTypes.zpl) {
            store.dispatch('templates/loadPDF', typedArrayData.base64DecodedValue);

            const pdfUint8Array = store.getters['templates/pdf'](typedArrayData.base64DecodedValue);

            if (pdfUint8Array) {
              typedArrayData.uint8Array = pdfUint8Array;
              typedArrayData.mimeType = MimeTypes.pdf;
            } else {
              typedArrayData = null;
            }
          }

          return typedArrayData
            ? {
                label: typedArrayData.uint8Array,
                mimeType: typedArrayData.mimeType
              }
            : null;
        };

        return {
          id: shipmentPackage.id,
          shipmentId: shipment.id,
          trackingNumber: shipmentPackage.tracking_number,
          label: shipmentPackage.label,
          return_label: shipmentPackage.return_label,
          typedArray: getTypedArrayData(shipmentPackage.label),
          returnTypedArray: getTypedArrayData(shipmentPackage.return_label)
        };
      })
    }))
  );

  const printDocument = async ({ shipmentId, data }) => await store.dispatch('printing/print', { shipmentId, data });

  const printAllDocuments = async () =>
    Promise.all(
      Object.values(shipments.value).map(({ id: shipmentId }) =>
        store.dispatch('printing/print', {
          shipmentId
        })
      )
    );

  return { documents, printDocument, printAllDocuments };
};

export const usePagination = (loadEntities) => {
  const store = useStore();

  const pageSettings = reactive({});

  const updatePageSettings = async (params) => {
    for (const key in params) {
      pageSettings[key] = params[key];
    }
  };

  const savePaginationSettings = () => {
    if (pageSettings?.limit) {
      store.dispatch('users/updateUserSettings', {
        page_settings: { [loadEntities.split('/')[0]]: { limit: pageSettings.limit } },
        filters: pageSettings.filters
      });
    }
  };

  const updatePage = async (params) => {
    await updatePageSettings(params);
    await replacePaginationState(pageSettings);
    savePaginationSettings();

    const filters = pageSettings?.filters?.[loadEntities.split('/')[0]];

    await store.dispatch(loadEntities, {
      limit: pageSettings.limit,
      offset: pageSettings.limit * pageSettings.page - pageSettings.limit,
      search: pageSettings.search,
      filters: filters
    });
  };

  return { pageSettings, updatePage };
};

export const useNavigateTo = () => {
  const router = useRouter();

  const navigateTo = async (params, pageSettings) => {
    const { page, limit } = pageSettings;

    await router.push({ query: { page, limit } });
    router.push(params);
  };

  return navigateTo;
};

export const useFiltersAndSearch = ({
  props,
  updatePage,
  clearEntityAction,
  entity,
  useFilters = true,
  pageSettings
}) => {
  const store = useStore();
  const debounce = createDebounce();

  const existingFilters = computed(() => store.state.users?.userSettings?.filters);
  const savedFilters = computed(() => existingFilters?.value?.[entity]);

  const savedLimit = computed(() => store.state.users?.userSettings?.pageSettings?.[entity]?.limit);

  // filters for shipments/fulfilments/orders (the same filters logic with search)
  const data = reactive({
    searchQuery: '',
    location: [],
    status: [],
    delivery_type: []
  });

  // for any other single basic filters
  const basicFilters = reactive({});

  const savedFiltersIsEmpty = computed(
    () => savedFilters.value === undefined || savedFilters.value?.location?.length === 0
  );

  const locationsIsEmpty = computed(() => data.location?.length === 0 && savedFiltersIsEmpty.value);

  const handleSaveFilters = (filters) => {
    if (filters?.fulfillments?.location?.length > 0) {
      updatePage({
        filters: filters,
        search: data.searchQuery,
        page: 1,
        limit: pageSettings.limit
      });
    } else {
      store.dispatch('users/updateUserSettings', { filters });
    }
  };

  const handleFilters = (filters) => {
    const initialFilters = {
      ...existingFilters.value,
      [entity]: filters
    };

    // need to sync location filter between entities
    if (!filters.location) {
      return initialFilters;
    }

    for (const entityName in existingFilters.value) {
      if (entityName === entity) {
        continue;
      }

      initialFilters[entityName] = existingFilters.value[entityName];
      initialFilters[entityName].location = filters.location;
    }

    return initialFilters;
  };

  const handleSelectLocations = (locations) => {
    if (locations.length === 0) {
      store.dispatch(clearEntityAction);
    }

    data.location = locations;

    const filters = { location: locations, ...omit(data, ['searchQuery', 'location']) };

    debounce(() => handleSaveFilters(handleFilters(filters)), props.timeout);
  };

  const handleSelectStatuses = (statuses) => {
    data.status = statuses;

    const filters = { status: statuses, ...omit(data, ['searchQuery', 'status']) };

    debounce(() => handleSaveFilters(handleFilters(filters)), props.timeout);
  };

  const handleSelectDeliveryTypes = (types) => {
    data.delivery_type = types;

    const filters = { delivery_type: types, ...omit(data, ['searchQuery', 'delivery_type']) };

    debounce(() => handleSaveFilters(handleFilters(filters)), props.timeout);
  };

  const handleCarrierService = (value) => {
    const timeOut = 1500;

    data.carrier_service = value;

    const filters = { carrier_service: value, ...omit(data, ['searchQuery', 'carrier_service']) };

    debounce(() => handleSaveFilters(handleFilters(filters)), timeOut);
  };

  // use for any other single basic filters
  const handleBasicFilterOption = (option) => {
    const [key, value] = Object.entries(option)[0];

    basicFilters[key] = value;

    debounce(
      () =>
        updatePage({
          filters: basicFilters,
          page: 1,
          limit: pageSettings.limit
        }),
      props.timeout
    );
  };

  const search = (value) => {
    data.searchQuery = value;

    debounce(
      () =>
        updatePage({
          search: value,
          page: 1,
          limit: pageSettings.limit,
          ...(useFilters ? { filters: { [entity]: omit(data, 'searchQuery') } } : {})
        }),
      props.timeout
    );
  };

  const handleInitialSavedFilters = (options) => {
    if (savedFilters?.value !== undefined && Object.keys(savedFilters?.value).length > 0) {
      for (const key in savedFilters.value) {
        data[key] = savedFilters.value[key];
      }

      options.filters = { [entity]: savedFilters.value };
    }
  };

  const handleInitialPagination = (options) => {
    if (savedLimit?.value !== undefined && !options.limit) {
      data.limit = savedLimit.value;
      options.limit = savedLimit.value;
    } else {
      data.limit = options.limit ?? 25;
      options.limit = data.limit;
    }
  };

  const setInitialValues = async (options) => {
    handleInitialSavedFilters(options);
    handleInitialPagination(options);

    data.searchQuery = options.search;
    data.limit = options.limit;

    await updatePage({ ...options });
  };

  const handleSelectCreatedAt = ({ createdAtFrom, createdAtTo }) => {
    data.created_at_from = createdAtFrom;
    data.created_at_to = createdAtTo;

    const filters = {
      created_at_from: createdAtFrom,
      created_at_to: createdAtTo,
      ...omit(data, ['searchQuery', 'created_at_from', 'created_at_to'])
    };

    debounce(() => handleSaveFilters(handleFilters(filters)), props.timeout);
  };

  return {
    handleSelectStatuses,
    handleSelectLocations,
    handleSelectDeliveryTypes,
    handleBasicFilterOption,
    handleCarrierService,
    search,
    setInitialValues,
    locationsIsEmpty,
    filterData: data,
    handleSelectCreatedAt
  };
};

export const useNotification = (time = 1500) => {
  const notification = ref(false);

  const notify = () => {
    notification.value = true;

    setTimeout(() => (notification.value = false), time);
  };

  return { notification, notify };
};

export const usePrintingModal = () => {
  const store = useStore();
  const router = useRouter();

  const printTypeLabels = {
    ['label']: t('PRINTING.SHIPPING_LABELS'),
    ['return_label']: t('PRINTING.SHIPPING_LABELS'),
    ['packing_slip']: t('PRINTING.PACKING_SLIPS'),
    ['commercial_invoice']: t('PRINTING.COMMERCIAL_INVOICES')
  };

  const state = reactive({
    showWarning: false,
    warningText: ''
  });

  const goToPrintSettings = async () => {
    await router.push({ name: 'printing' });
  };

  const checkIfPrinterReady = (type) => store.getters['users/isPrinterReadyForDocumentType'](type);

  const print = async ({ shipments, orderId, action }) => {
    await Promise.all(
      shipments.map(({ id: shipmentId, fulfillment_id: fulfillmentId }) =>
        store.dispatch('packages/loadShipmentPackages', {
          orderId,
          shipmentId,
          fulfillmentId
        })
      )
    );

    await Promise.all(
      shipments.reduce((result, { id: shipmentId }) => {
        store.getters['packages/getItemsByShipmentId'](shipmentId).forEach(({ id: packageId }) => {
          result.push(
            store.dispatch('printing/print', {
              shipmentId,
              data: {
                action,
                shipments_package_id: packageId
              }
            })
          );
        });

        return result;
      }, [])
    );
  };

  const getModalDescription = (type) => {
    const name = printTypeLabels[type];

    const defaultText = t('PRINT_CONFIRM_MODAL.SHORT_DESCRIPTION');
    const splitted = defaultText.split('%%%');

    return `${splitted[0]}${name}${splitted[1]}${name}${splitted.slice(2).join('%%%')}`;
  };

  const handleWarningModal = (action) => {
    state.warningText = getModalDescription(action);
    state.showWarning = true;
  };

  const closeModal = () => {
    state.showWarning = false;
    state.warningText = '';
  };

  return { printData: state, goToPrintSettings, checkIfPrinterReady, print, handleWarningModal, closeModal };
};
