<template>
  <tr
    v-for="n in 5"
    :key="`wide-loader-row-${n}`">
    <td
      :colspan="1"
      class="w-full">
      <div class="flex items-center space-x-2 p-4">
        <div class="w-1/10">
          <div class="h-5 w-5 bg-gray-200 rounded-full animate-pulse"></div>
        </div>
        <div class="flex-1">
          <div class="h-5 bg-gray-200 rounded animate-pulse ml-1"></div>
        </div>
      </div>
    </td>
    <td :colspan="3">
      <div class="flex-1">
        <div class="h-5 bg-gray-200 rounded animate-pulse mr-5"></div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'WideLoader'
};
</script>

<script setup>
import { defineProps } from 'vue';

defineProps({
  length: {
    type: Array,
    default: () => []
  }
});
</script>
