<template>
  <integrations-template
    :loading="page.data.loading"
    :data="page.data.items"
    :shipping-accounts="data.shippingAccounts"
    :service-name-mapping="nameMapping"
    :allowed-ftp-carriers="data.allowedFtpCarriers"
    @create="page.create"
    @update="page.update"
    @delete="page.remove">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </integrations-template>
</template>

<script>
export default {
  name: 'Integrations'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { onMounted, reactive } from 'vue';

import client from '../../../api';
import usePage from '../../../mixins/usePage';
import { IntegrationService, ShippingAccountService } from '../../../services';
import { showFailMessage, withLoading } from '../../../utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Alert from '../../atoms/Alert';
import IntegrationsTemplate from '../../templates/Integrations';

const { t } = useTranslation();

const nameMapping = {
  1: 'Salesforce Core',
  2: 'Salesforce Commerce Cloud',
  8: 'Newstore',
  9: 'FTP',
  10: 'UPS Quantum View',
  11: 'Shopify'
};

const formatItemName = (value) => value.name || nameMapping[value.service];
const page = usePage(IntegrationService, formatItemName);

const data = reactive({
  shippingAccounts: [],
  allowedFtpCarriers: []
});

onMounted(async () => {
  await withLoading(page, async () => {
    await page.read();

    try {
      data.shippingAccounts = await ShippingAccountService.get();
    } catch (e) {
      await showFailMessage(page, t('SHIPPING_ACCOUNT_LOADING_FAIL'));

      return;
    }

    try {
      data.allowedFtpCarriers = await client.getSftpCarriers();
    } catch (e) {
      await showFailMessage(page, t('CARRIER_LOADING_FAIL'));
    }
  });
});
</script>
