<template>
  <div
    :class="{ 'h-full overflow-hidden': sticky }"
    class="relative flex flex-col">
    <div
      v-if="$slots.title || $slots['filter-bar']"
      class="flex flex-row items-center">
      <div
        v-if="$slots.title"
        class="text-2xl font-medium leading-tight mr-4">
        <slot name="title" />
      </div>
      <div
        v-if="$slots['filter-bar']"
        class="my-2 flex sm:flex-row flex-col">
        <animated-placeholder
          v-if="loading && loadingFilterBar"
          :count="2"
          class="w-40" />
        <slot
          v-else
          name="filter-bar" />
      </div>
    </div>
    <template v-if="$slots['before-table']">
      <slot name="before-table" />
    </template>
    <div class="flex justify-between items-center mb-3 text-sm text-gray-600">
      <span v-if="formattedTotalLabel && showTotal">
        <slot
          name="total"
          :label="formattedTotalLabel">
          {{ formattedTotalLabel }}
        </slot>
      </span>
      <div class="flex items-center gap-2">
        <table-sort-modal
          v-if="sortFields.length"
          :value="state.selectedSortFields"
          :columns="sortFields"
          @update:value="updateSortFields" />
        <table-customization-modal
          v-if="customizeColumns"
          :unselected="customizationSettings.unselected"
          :selected="customizationSettings.selected"
          :init-value="fields"
          :date-picker="datePicker"
          @update="updateCustomization"
          @update:created-at="updateCreatedAt" />
        <slot name="right-filter-bar" />
      </div>
    </div>
    <div class="inline-flex">
      <slot name="info-bar" />
    </div>
    <div
      class="relative overflow-x-auto h-full rounded-t-lg bg-white"
      :class="[{ 'rounded-lg border border-gray-200 table-bottom-shadow': !hasFooter }, tableContainerClass]">
      <table
        :class="tableClass"
        class="table-auto min-w-full">
        <thead
          class="bg-gray-100"
          :class="{ 'sticky top-0 z-10': sticky }">
          <tr>
            <th
              v-for="(item, index) in header"
              :key="`header-${item.key}`"
              :data-id="`header-${item.key}`"
              class="py-2 text-left text-xm font-normal text-gray-800 whitespace-nowrap">
              <span
                :class="{ 'border-l border-gray-400': index > 0 && showDelimiter }"
                class="px-2">
                <slot
                  :name="`header(${item.key})`"
                  :column="item">
                  {{ t(item.label) }}
                </slot>
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="!tableData.length && !wideLoader">
            <td :colspan="header.length">
              <div class="flex justify-center items-center text-sm p-4 inset-0 top-12">
                {{ noDataPlaceholder ? noDataPlaceholder : t('TABLE.NO_DATA') }}
              </div>
            </td>
          </tr>

          <wide-loader v-if="!tableData.length && wideLoader"></wide-loader>

          <template
            v-for="(row, rowIndex) in tableData"
            :key="loading ? `loading-row-${rowIndex}` : row">
            <tr
              class="border-b border-gray-200 hover:bg-gray-50"
              :aria-expanded="row && row.children && row.opened"
              :data-id="getRowDataId(row, rowKey, rowIndex)"
              @click="onRowClicked(rowIndex, row?.children)"
              @mouseover="state.hoverRowIndex = rowIndex"
              @mouseleave="state.hoverRowIndex = -1">
              <td
                v-if="row && row.children && carrierTable"
                :key="`cell-${header[0].key}-${rowIndex}`"
                colspan="4"
                :class="[getCellClassList([rowIndex, null, 0]), header[0].key]"
                class="px-2 py-3 text-xm text-gray-900 leading-6">
                <template v-if="loading">
                  <animated-placeholder />
                </template>
                <template v-else>
                  <slot
                    :name="`cell(${header[0].key})`"
                    :row="row"
                    :row-index="rowIndex"
                    :column-index="0"
                    :hover-row-index="state.hoverRowIndex">
                    <b-icon
                      class="text-base mr-2 cursor-pointer"
                      :icon-name="row.opened ? BringgFontIcons.ChevronUp : BringgFontIcons.Chevron"
                      @click="row.opened = !row.opened" />
                    {{ handleCellValue(row, header[0].key) }}
                  </slot>
                </template>
              </td>
              <td
                v-for="(item, columnIndex) in header"
                v-else
                :key="`cell-${item.key}-${rowIndex}`"
                :class="[getCellClassList([rowIndex, null, columnIndex]), item.key]"
                class="px-2 py-3 text-xm text-gray-900 leading-6">
                <template v-if="loading">
                  <animated-placeholder />
                </template>
                <template v-else>
                  <slot
                    :name="`cell(${item.key})`"
                    :row="row"
                    :row-index="rowIndex"
                    :column-index="columnIndex"
                    :hover-row-index="state.hoverRowIndex">
                    {{ handleCellValue(row, item.key) }}
                  </slot>
                </template>
              </td>
            </tr>
            <template v-if="row && row.children && row.opened">
              <tr
                v-for="(subRow, subRowIndex) in row.children"
                :key="subRow"
                class="border-b border-gray-200 last:border-b-0 bg-white hover:bg-gray-50 text-gray-800"
                :data-id="rowKey && subRow && subRow[rowKey] ? subRow[rowKey] : ''"
                :parent-data-id="getRowDataId(row, rowKey, rowIndex)"
                @click="onRowClicked([rowIndex, subRowIndex])"
                @mouseover="state.hoverRowIndex = rowIndex + subRowIndex"
                @mouseleave="state.hoverRowIndex = -1">
                <td
                  v-if="subRow.unavailable"
                  class="flex flex-row w-full"
                  :class="[getCellClassList([rowIndex, subRowIndex, 0]), header[0].key]">
                  <slot
                    :name="`cell(${header[0].key})`"
                    :row="subRow"
                    :row-index="[rowIndex, subRowIndex]"
                    :column-index="0"
                    :hover-row-index="state.hoverRowIndex"
                    :parent="row">
                    <span class="ml-6">
                      {{ handleCellValue(subRow, header[0].key) }}
                    </span>
                  </slot>
                </td>
                <td
                  v-if="subRow.unavailable"
                  colspan="3"
                  :class="[getCellClassList([rowIndex, subRowIndex, 0]), header[0].key]">
                  <div class="text-xm">{{ subRow.error }}</div>
                </td>
                <td
                  v-for="(item, columnIndex) in header"
                  v-else
                  :key="`cell-${item.key}-${rowIndex}`"
                  :class="[getCellClassList([rowIndex, subRowIndex, columnIndex]), item.key]">
                  <slot
                    :name="`cell(${item.key})`"
                    :row="subRow"
                    :row-index="[rowIndex, subRowIndex]"
                    :column-index="columnIndex"
                    :hover-row-index="state.hoverRowIndex"
                    :parent="row">
                    <span :class="{ 'ml-6': columnIndex === 0 }">
                      {{ handleCellValue(subRow, item.key) }}
                    </span>
                  </slot>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <slot name="footer-container">
      <div
        v-if="hasFooter"
        class="h-12 py-2 rounded-b-lg border-t bg-white">
        <slot name="footer">
          <div class="flex justify-between">
            <div class="pl-6">
              <div v-if="pagination">
                <pagination
                  :page="props.page"
                  :limit="props.limit"
                  :total="props.total"
                  :last-page="props.lastPage"
                  @update:page-settings="updatePageSettings"></pagination>
              </div>
            </div>
            <div class="pr-6 flex items-center">
              <span
                v-if="lastUpdate"
                class="text-xs font-normal whitespace-nowrap">
                <span class="text-gray-600">{{ t('TABLE.LAST_UPDATE') }}:</span>
                {{ state.formattedLastUpdateDate }}
              </span>
            </div>
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'BTable'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import format from 'date-fns/format';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, onMounted, reactive, ref, useSlots, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';

import AnimatedPlaceholder from '@/components/atoms/AnimatedPlaceholder';
import BIcon from '@/components/atoms/Icon';
import WideLoader from '@/components/atoms/WideLoader';
import Pagination from '@/components/molecules/Pagination';
import TableCustomizationModal from '@/components/organisms/TableCustomizationModal';
import { useCustomizationModal } from '@/components/organisms/TableCustomizationModal/composables';
import TableSortModal from '@/components/organisms/TableSortModal';

import twMerge from '../../../twMerge';

const KEY_DELIMITER = '.';

const { t } = useTranslation();
const store = useStore();
const slots = useSlots();

const props = defineProps({
  fields: {
    type: Array,
    default: () => []
  },
  entityName: {
    type: String,
    default: ''
  },
  sortFields: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  loadingFilterBar: {
    type: Boolean,
    default: true
  },
  cellClass: {
    type: [String, Array],
    default: ''
  },
  cellClassFunction: {
    type: Function,
    default: () => ''
  },
  tableClass: {
    type: [String, Array, Object],
    default: ''
  },
  tableContainerClass: {
    type: [String, Array, Object],
    default: ''
  },
  loadingLinesNumber: {
    type: Number,
    default: 3
  },
  totalLabel: {
    type: String,
    default: '{num}'
  },
  total: {
    type: Number,
    default: -1
  },
  customizeColumns: {
    type: Boolean,
    default: false
  },
  lastUpdate: {
    type: Boolean,
    default: false
  },
  pagination: {
    type: Boolean,
    default: false
  },
  defaultExpand: {
    type: Boolean,
    default: false
  },
  sticky: {
    type: Boolean,
    default: true
  },
  page: {
    type: Number,
    default: 1
  },
  limit: {
    type: [Number, null],
    default: 50
  },
  lastPage: {
    type: Boolean,
    default: false
  },
  rowKey: {
    type: String,
    default: ''
  },
  withSaveCustomization: {
    type: Boolean,
    default: false
  },
  forceUpdate: {
    type: Number,
    default: 0
  },
  showTotal: {
    type: Boolean,
    default: true
  },
  showDelimiter: {
    type: Boolean,
    default: true
  },
  wideLoader: {
    type: Boolean,
    default: false
  },
  carrierTable: {
    type: Boolean,
    default: false
  },
  noDataPlaceholder: {
    type: String,
    default: ''
  },
  datePicker: {
    type: Boolean,
    default: false
  }
});

const savedSelections = computed(() => store.getters['users/userSettings']);

const shouldForceUpdate = computed(() => props.forceUpdate);
const forceUpdate = ref(0);

const { initColumns, updateColumns, customizationSettings } = useCustomizationModal({
  fields: props.fields,
  withSave: props.withSaveCustomization
});

const state = reactive({
  items: [],
  selectedSortFields: [],
  formattedLastUpdateDate: '',
  hoverRowIndex: -1
});

onMounted(async () => {
  if (props.withSaveCustomization) {
    if (!savedSelections?.value || Object.keys(savedSelections.value).length === 0) {
      await store.dispatch('users/loadUserSettings');
    }

    initColumns({
      savedSelections: savedSelections.value?.customizedColumns?.[props.entityName],
      entityName: props.entityName
    });
  }
});

// use when you need to update component after sync operations
watch(shouldForceUpdate, (newVal, oldVal) => {
  forceUpdate.value = newVal;
});

const emit = defineEmits(['click-row', 'update:sort', 'update:page-settings', 'update:created-at']);

const updatePageSettings = (data) => {
  emit('update:page-settings', data);
};

const header = computed(() =>
  customizationSettings.selected.map((item) => {
    if (typeof item === 'string') {
      const label = item.charAt(0).toUpperCase() + item.substr(1).toLowerCase();

      return { key: item, label };
    }

    item.label = t(item.label);

    return item;
  })
);

const getItems = () => {
  if (props.data?.length > 0) {
    return props.data.map((item) => {
      if (item.children) {
        item.opened = props.defaultExpand;
      }

      return item;
    });
  }

  return [];
};

watchEffect(() => {
  state.items = getItems();

  if (props.lastUpdate) {
    state.formattedLastUpdateDate = format(new Date(), 'HH:mm, MMM. d, yyyy');
  }
});

const tableData = computed(() => (props.loading ? Array(props.loadingLinesNumber) : state.items));

const formattedTotalLabel = computed(() => {
  const placeholder = '{num}';
  const value = props.total === -1 ? tableData.value.length : props.total;

  let initial = props.totalLabel;

  if (props.totalLabel === placeholder) {
    initial = `${placeholder} ${value === 1 ? t('TABLE.ITEM_IN_TOTAL') : t('TABLE.ITEMS_IN_TOTAL')}`;
  }

  return initial.replace(placeholder, value.toString());
});

const hasFooter = computed(() => props.pagination || props.lastUpdate || slots.footer || slots['footer-container']);

const getCellClassList = (value) =>
  twMerge('text-xm text-gray-800 leading-6', 'pl-2 pr-2 pt-3 pb-3', props.cellClass, props.cellClassFunction(value));

const handleCellValue = (row, key) => {
  if (key.indexOf(KEY_DELIMITER) === -1) {
    return row?.[key];
  }

  const parts = key.split(KEY_DELIMITER);

  for (let k of parts) {
    try {
      row = row[k];
    } catch {
      return '';
    }
  }

  return row;
};

const updateCustomization = (value) => {
  updateColumns(value);
};

const updateCreatedAt = (value) => emit('update:created-at', value);

const onRowClicked = (rowIndex, rowChildren = false) => {
  if (props.carrierTable && rowChildren) {
    return;
  }

  emit('click-row', rowIndex);
};

const updateSortFields = (value) => {
  state.selectedSortFields = value;
  emit('update:sort', value);
};

const getRowDataId = (row, rowKey, rowIndex) => (rowKey && row && row[rowKey] ? row[rowKey] : `${rowIndex}`);
</script>
