<template>
  <div
    :data-id="`fulfillment-card-${row.id}`"
    class="mb-4 bg-white rounded shadow">
    <div
      class="relative"
      @mouseenter="hover = true"
      @mouseleave="hover = false">
      <div
        v-if="hover"
        class="absolute inset-0 bg-blue-25 flex justify-center items-center">
        <router-link :to="{ name: 'fulfillment-details', params: { id: row.id, orderId } }">
          <div class="p-4 flex-1 text-sm font-semibold text-blue-400 flex items-center text-center">
            <div>
              <div>{{ t('VIEW') }} {{ t('FULFILLMENTS.NAME_FIELD') }} {{ t('DETAILS') }}</div>
              <div>{{ t('FULFILLMENTS.NAME_FIELD') }} #{{ row.id }}</div>
            </div>
            <Icon
              icon-name="chevron-right"
              class="text-2xl" />
          </div>
        </router-link>
      </div>

      <div class="p-4">
        <div class="flex mb-1 justify-between items-start">
          <div class="flex-1 text-sm font-semibold text-gray-800">
            {{ t('ORDER_DETAILS.FULFILLMENT_ITEM') }} #{{ row.id }}
          </div>
          <badge
            v-if="!row.shipments"
            class="flex-none"
            :type="statusBadgeTypeMapping[row.status]">
            {{ t(row.status) }}
          </badge>
        </div>
        <span
          :key="!row.shipments"
          data-id="fulfillment-description"
          class="text-xm text-gray-700">
          <slot
            name="description"
            row="row" />
        </span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FulFillmentCard'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { defineProps, ref } from 'vue';

import { statusBadgeTypeMapping } from '../../../enums';
import Badge from '../../atoms/Badge';
import Icon from '../../atoms/Icon';

const { t } = useTranslation();

defineProps({
  row: {
    type: Object,
    required: true
  },
  orderId: {
    type: String,
    required: true
  }
});

const hover = ref(false);
</script>
