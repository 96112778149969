<template>
  <div
    data-id="fulfillmentFilters"
    class="flex justify-between bg-white pb-3.5 pt-3">
    <div class="flex justify-left bg-white">
      <div class="flex items-center ml-11 w-112">
        <b-multi-select
          data-id="locations-filter"
          :options="locations"
          :label="t('FILTER.LOCATIONS.NAME')"
          :saved-filters-path="['fulfillments', 'location']"
          with-search
          wide-dropdown
          :all-placeholder-name="t('FILTER.LOCATIONS.PLACEHOLDER_NAME')"
          :placeholder-text="t('FILTER.LOCATIONS.PLACEHOLDER_TEXT')"
          @select="handleSelectLocations" />
      </div>
      <div class="flex items-center ml-8 w-69">
        <b-multi-select
          data-id="status-filter"
          :options="mappedStatuses"
          :label="t('FILTER.STATUS.NAME')"
          :saved-filters-path="['fulfillments', 'status']"
          :placeholder-text="t('FILTER.STATUS.PLACEHOLDER_TEXT')"
          :all-placeholder-name="t('FILTER.STATUS.PLACEHOLDER_NAME')"
          with-badge
          :with-all="false"
          :disabled="locationsIsEmpty"
          @select="handleSelectStatuses" />
      </div>
    </div>
    <div class="mr-7 w-96 ml-5">
      <search-input
        :search-query="filterData.searchQuery"
        @update-search-query="search"></search-input>
    </div>
  </div>
  <empty-entity
    v-if="locationsIsEmpty"
    :main-text="t('FULFILLMENTS.EMPTY_PAGE_MAIN_TEXT')"
    :sub-text="t('FULFILLMENTS.EMPTY_PAGE_SUB_TEXT')" />
  <div
    v-else
    class="pl-10 pr-6 pt-6 flex h-full flex-col overflow-hidden">
    <alert
      v-if="error"
      class="mb-8"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>

    <b-table
      class="fulfillments"
      :loading="loading"
      :fields="fields"
      :data="fulfillments"
      pagination
      :page="pageSettings.page"
      :limit="pageSettings.limit"
      :total="total"
      :last-page="pageSettings.page * pageSettings.limit >= total"
      row-key="id"
      customize-columns
      with-save-customization
      entity-name="fulfillments"
      last-update
      date-picker
      @update:page-settings="updatePageSettings"
      @update:created-at="updateCreatedAt">
      <template #cell(name)="{ row }">
        <div
          v-if="row?.id && row?.order_id"
          @click.capture="
            navigateTo({ name: 'fulfillment-details', params: { id: row.id, orderId: row.order_id } }, pageSettings)
          ">
          <router-link
            :to="{
              name: 'fulfillment-details',
              params: {
                id: row.id,
                orderId: row.order_id
              }
            }"
            class="text-blue-400 hover:text-blue-600 cursor-pointer">
            {{ row.name }}
          </router-link>
        </div>
      </template>
      <template #cell(source_id)="{ row }">
        <template v-if="row?.source_id?.length > 24">
          <tooltip
            width="w-72"
            placement="top"
            :hover="false">
            <template #content>
              <div class="max-w-40 truncate hover:text-clip">{{ row.source_id }}</div>
            </template>
            <template #title>
              {{ row.source_id }}
            </template>
          </tooltip>
        </template>
        <template v-else>
          <div class="max-w-40 truncate hover:text-clip">{{ row.source_id }}</div>
        </template>
      </template>
      <template #cell(created_at)="{ row }">
        {{ formatCreatedAtColumn(row) }}
      </template>
      <template #cell(deliver_by)="{ row }">
        {{ formatDeliverByColumn(row) }}
      </template>
      <template #cell(total_weight)="{ row }">
        {{ formatTotalWeight(row.total_weight) }}
      </template>
      <template #cell(carrier)="{ row, rowIndex }">
        <template v-if="formatCarrierColumn(row).length > 24">
          <tooltip
            :placement="placementCarrierTooltip(rowIndex)"
            :hover="false">
            <template #content>
              <div class="max-w-40 truncate">{{ formatCarrierColumn(row) }}</div>
            </template>
            <template #title>
              <div class="max-w-xs whitespace-normal break-words">{{ formatCarrierColumn(row) }}</div>
            </template>
          </tooltip>
        </template>
        <template v-else>
          <div>{{ formatCarrierColumn(row) }}</div>
        </template>
      </template>
      <template #cell(status)="{ row }">
        <div class="w-max">
          <badge :type="statusBadgeTypeMapping[row.status]">
            {{ t(row.status) }}
          </badge>
        </div>
      </template>
      <template #cell(actions)="{ row }">
        <dropdown
          :options="actionOptions"
          button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
          :left-align="false"
          @select="handleAction($event, row)">
          <template #button>
            <icon
              class="mr-3 text-xl"
              icon-name="menu-horizontal" />
          </template>
        </dropdown>
      </template>
    </b-table>
  </div>
  <confirm-modal
    data-id="print-warning"
    :is-shown="printData.showWarning"
    :use-ignore-button="false"
    @close="closeModal"
    @accept="goToPrintSettings">
    <template #title>
      {{ t('PRINT_CONFIRM_MODAL.TITLE') }}
    </template>
    <template #description>
      {{ printData.warningText }}
    </template>
    <template #accept-label>
      {{ t('PRINT_CONFIRM_MODAL.PRINT_BUTTON') }}
    </template>
  </confirm-modal>
</template>

<script>
export default {
  name: 'Fulfillments'
};
</script>

<script setup>
import format from 'date-fns/format';
import { useTranslation } from 'i18next-vue';
import { omit } from 'lodash';
import { computed, defineProps, onMounted } from 'vue';
import { useStore } from 'vuex';

import Alert from '@/components/atoms/Alert';
import Badge from '@/components/atoms/Badge';
import Icon from '@/components/atoms/Icon';
import Tooltip from '@/components/atoms/ToolTip/index.vue';
import Dropdown from '@/components/molecules/Dropdown';
import BMultiSelect from '@/components/molecules/MultiSelect';
import SearchInput from '@/components/molecules/Search';
import ConfirmModal from '@/components/organisms/ConfirmModal';
import BTable from '@/components/organisms/Table';
import { useFiltersAndSearch, useNavigateTo, usePagination, usePrintingModal } from '@/composables';
import { statusBadgeTypeMapping } from '@/enums';
import { formatTotalWeight } from '@/utils';

import EmptyEntity from '../EmptyEntity';

const props = defineProps({
  page: {
    type: Number,
    required: true
  },
  limit: {
    type: [Number, null],
    required: true
  },
  timeout: {
    type: Number,
    default: 100
  },
  search: {
    type: String,
    default: ''
  }
});

const store = useStore();
const { t } = useTranslation();
const { pageSettings, updatePage } = usePagination('fulfillments/filterFulfilllments');
const {
  filterData,
  search,
  handleSelectStatuses,
  handleSelectLocations,
  setInitialValues,
  locationsIsEmpty,
  handleSelectCreatedAt
} = useFiltersAndSearch({
  props,
  updatePage,
  clearEntityAction: 'fulfillments/clearFulfillments',
  entity: 'fulfillments',
  pageSettings
});
const navigateTo = useNavigateTo();
const { printData, goToPrintSettings, checkIfPrinterReady, print, handleWarningModal, closeModal } = usePrintingModal();

onMounted(async () => {
  const { page, limit, search } = props;

  await store.dispatch('shippingAccounts/loadShippingAccounts');

  await setInitialValues({
    page,
    limit,
    search
  });
});

const fulfillments = computed(() => store.getters['fulfillments/items']);

const total = computed(() => store.state.fulfillments.total);
const loading = computed(
  () =>
    store.state.fulfillments.loading || store.state.shippingAccounts.loading || store.state.shippingLocations.loading
);
const error = computed(() => store.state.fulfillments.error);

const locations = computed(() => {
  const locations = Array.from(store.state.shippingLocations.items.values()).map((location) => {
    const { id, name } = location;

    return {
      value: id,
      label: name
    };
  });

  return [{ value: 'null', label: 'No locations' }, ...locations];
});

const fields = computed(() => [
  { key: 'name', label: 'FULFILLMENTS.NAME_FIELD' },
  { key: 'id', label: 'FULFILLMENTS.ID_FIELD' },
  { key: 'source_id', label: 'FIELDS.SOURCE_ID_FIELD' },
  { key: 'created_at', label: 'FIELDS.CREATED_FIELD' },
  { key: 'deliver_by', label: 'FULFILLMENTS.DELIVER_BY_FIELD' },
  { key: 'items_count', label: 'FULFILLMENTS.ITEM_COUNT_FIELD' },
  { key: 'total_weight', label: 'TOTAL_WEIGHT' },
  { key: 'carrier', label: 'FULFILLMENTS.CARRIER_FIELD' },
  { key: 'status', label: 'FULFILLMENTS.STATUS_FIELD' },
  { key: 'actions', label: 'ACTIONS_FIELD' }
]);

const actionOptions = computed(() => [
  { value: 'label', label: t('FULFILLMENTS.PRINT_LABEL_ACTION') },
  { value: 'packing_slip', label: t('FULFILLMENTS.PRINT_SLIP_ACTION') }
]);

const clearError = () => store.commit('fulfillments/clearError');

const updatePageSettings = (pageSettings) =>
  updatePage({
    ...pageSettings,
    filters: { fulfillments: omit(filterData, 'searchQuery') },
    search: filterData.searchQuery
  });

const updateCreatedAt = (createdAtValues) => handleSelectCreatedAt(createdAtValues);

const loadFulfillmentShipments = (orderId, fulfillmentId) =>
  store.dispatch('shipments/loadFulfillmentShipments', {
    orderId,
    fulfillmentId
  });

const carrierNames = computed(() =>
  fulfillments.value.reduce((acc, fulfillment) => {
    acc[fulfillment.id] = fulfillment.shipment_account_ids?.map((shipment_account_id) =>
      store.getters['shippingAccounts/getCarrierNameByShippingAccountId'](shipment_account_id)
    );

    return acc;
  }, {})
);
const formatCarrierColumn = (row) => {
  const names = [...new Set(carrierNames.value[row.id])];

  return names?.length ? names.join(', ') : '-';
};
const formatCreatedAtColumn = (row) => {
  try {
    return format(Date.parse(row.created_at), 'MMM. d, Y, HH:mm');
  } catch (e) {
    return '';
  }
};
const formatDeliverByColumn = (row) => {
  try {
    return format(Date.parse(row.deliver_by), 'MMM. d, Y');
  } catch (e) {
    return '';
  }
};

const mappedStatuses = Object.entries(statusBadgeTypeMapping).map(([key, value]) => ({
  label: key,
  type: value,
  value: key
}));

const handleAction = async (action, fulfillment) => {
  if (!checkIfPrinterReady(action)) {
    handleWarningModal(action);

    return;
  }

  const { order_id: orderId, id: fulfillmentId } = fulfillment;

  await loadFulfillmentShipments(orderId, fulfillmentId);

  const shipments = store.getters['shipments/getItemsByFulfillmentId'](fulfillment.id);

  await print({ shipments, orderId, action });
};

const placementCarrierTooltip = (index) => (index + 1 === pageSettings.limit ? 'top' : 'bottom');
</script>
