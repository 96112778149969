<template>
  <div class="flex items-center px-6 py-3 shadow-top">
    <Transition
      enter-active-class="transition duration-500"
      leave-active-class="transition duration-500"
      enter-from-class="opacity-0"
      leave-to-class="opacity-0">
      <div v-if="printIndication">
        <icon
          class="text-green-500 mr-1"
          :icon-name="BringgFontIcons.SelectedOutline" />
        <span class="print-indication-text">{{ printIndication }}</span>
      </div>
    </Transition>
    <div class="ml-auto"></div>
    <b-button
      data-id="print-all"
      :disabled="isCustomService || !documentsTotal"
      class="h-8 px-6 py-1 bg-blue-400 text-white border-none text-base font-medium self-end"
      @click="printAll">
      {{ t('SHIPPING_DOCUMENTS_MODAL.PRINT_ALL_BUTTON') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'BFooter'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';

import BButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';

const { t } = useTranslation();

defineProps({
  printIndication: {
    type: String,
    required: true
  },
  isCustomService: {
    type: Boolean,
    required: true
  },
  documentsTotal: {
    type: Number,
    required: true
  },
  printAll: {
    type: Function,
    required: true
  }
});
</script>

<style lang="scss">
.print-indication-text {
  color: var(--colors-gray-800, #2d3748);
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.26px;
}
</style>
