<template>
  <div
    data-id="carrier-block"
    class="mr-7">
    <div class="flex items-center justify-start mb-2">
      <h1 class="text-base text-gray-800">
        <b-icon
          class="mr-1"
          :icon-name="BringgFontIcons.Truck" />
        {{ t('SHIPMENT_DETAILS.CARRIER') }}
      </h1>
      <b-button
        v-show="shipmentDetails.status === ShipmentStatus.ReadyToShip"
        data-id="replace-carrier"
        :disabled="isCustomService"
        class="h-auto p-0 text-blue-400 text-sm ml-5"
        type="blank"
        @click="showModal(ASSIGN_MODAL_ID)">
        {{ t('SHIPMENT_DETAILS.REPLACE_CARRIER') }}
      </b-button>
      <b-button
        v-show="canCancelShipment"
        data-id="cancel-carrier"
        :disabled="isCustomService"
        class="h-auto p-0 text-blue-400 text-sm ml-3"
        type="blank"
        @click="showModal(CANCEL_MODAL_ID)">
        {{ t('SHIPMENT_DETAILS.CANCEL') }}
      </b-button>
    </div>
    <div class="p-4 bg-white shadow">
      <div v-if="!shipmentDetails.carrier">
        <div class="flex flex-col">
          <div class="flex justify-between">
            <div class="flex items-center gap-1">
              <b-icon
                :icon-name="BringgFontIcons.Warning"
                class="mt-0.5 text-lg text-danger-900" />
              <h2 class="text-base text-gray-700 font-medium">
                {{ t('SHIPMENT_DETAILS.NO_CARRIER_ASSIGNED') }}
              </h2>
            </div>
            <b-button
              data-id="activity-log-button"
              type="blank"
              class="h-auto p-0 text-sm text-blue-400 font-semibold"
              @click="toggleDrawer('activityLog')">
              {{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.TITLE') }}
            </b-button>
          </div>
          <div
            v-if="rejectionReason"
            data-id="rejection-reason"
            class="text-sm pt-3 text-danger-900 truncate">
            {{ rejectionReason }}
          </div>
          <div class="flex items-center gap-1 pt-3 pb-4">
            <h2 class="text-sm text-gray-700 font-medium">
              {{ t('SHIPMENT_DETAILS.WARNING_TEXT') }}
            </h2>
          </div>
          <div class="flex">
            <b-button
              data-id="assign-carrier"
              :disabled="isCustomService"
              type="blank"
              class="h-auto bg-blue-400 -200 text-white rounded text-sm px-2 py-1 mr-2 mb-2"
              @click="showModal(ASSIGN_MODAL_ID)">
              {{ t('SHIPMENT_DETAILS.ASSIGN_CARRIER') }}
            </b-button>

            <b-button
              v-if="redispatchEnabled"
              type="blank"
              class="h-auto text-blue-400 text-sm border-blue-200 border rounded px-2 py-1 mr-2 mb-2 ml-2 justify-center inline-flex relative disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="isCustomService || redispatchLoading"
              data-id="redispatch"
              @click="redispatch">
              <div
                v-if="redispatchLoading"
                class="absolute inset-0 flex items-center justify-center">
                <b-icon
                  class="text-lg"
                  :icon-name="BringgFontIcons.Loader"
                  spin />
              </div>
              <span :class="{ 'opacity-0': redispatchLoading }">
                {{ t('SHIPMENT_DETAILS.REDISPATCH_CARRIER') }}
              </span>
            </b-button>
          </div>
        </div>
      </div>
      <div v-else-if="shipmentDetails.status === ShipmentStatus.CanceledByCarrier">
        <div class="flex flex-col gap-2">
          <div class="flex justify-between">
            <div class="flex items-center gap-1">
              <b-icon
                :icon-name="BringgFontIcons.Warning"
                class="mt-0.5 text-lg text-danger-900" />
              <h2 class="text-base text-red-400 font-medium">
                {{ t('SHIPMENT_DETAILS.SHIPMENT_CANCELLED_BY_CARRIER') }}
              </h2>
            </div>
            <b-button
              data-id="activity-log-button"
              type="blank"
              class="h-auto p-0 text-sm text-blue-400 font-semibold"
              @click="toggleDrawer('activityLog')">
              {{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.TITLE') }}
            </b-button>
          </div>
          <div class="text-sm text-gray-700">
            {{ cancellationReason || t('SHIPMENT_DETAILS.CANCELLED_DUE_UNFORESEEN_CIRCUMSTANCES') }}
          </div>
          <div class="text-sm text-gray-600">
            <span class="mr-0.5">{{ t('SHIPMENT_DETAILS.TRACKING') }} #:</span>
            <Copy>
              <span class="mr-0.5">
                {{ shipmentDetails?.tracking_number }}
              </span>
            </Copy>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex justify-between mb-3">
          <div
            data-id="carrier-data"
            class="flex items-center gap-2">
            <div class="shadow rounded-md">
              <img
                class="h-10 w-10 rounded-md shadow-bottom"
                :src="shippingAccount?.carrier_logo"
                :alt="`logo ${shippingAccount?.carrier_name}`" />
            </div>
            <div>
              <div>
                <span class="font-semibold text-gray-800 capitalize mr-2">
                  {{ shipmentDetails?.carrier }}
                </span>
                <span class="text-gray-700">{{ serviceName }}</span>
              </div>
              <div>
                <span class="text-sm text-gray-600">
                  {{ shippingAccount?.name ?? shippingAccount?.carrier_name }}
                </span>
              </div>
            </div>
          </div>
          <b-button
            v-if="activityLog.length"
            data-id="activity-log-button"
            type="blank"
            class="h-auto p-0 text-sm text-blue-400 font-semibold"
            @click="toggleDrawer('activityLog')">
            {{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.TITLE') }}
          </b-button>
        </div>
        <div>
          <div class="flex flex-row items-center">
            <span
              data-id="fulfillment-type"
              class="ml-1 text-xs text-gray-600 font-bold border-2 border-gray-400 rounded-full px-1.5 capitalize">
              {{ fulfillmentDetails?.fulfillment_type }}
            </span>
            <div class="flex text-xs text-gray-600 ml-4">
              <span class="mr-1 tracking-title">{{ t('SHIPMENT_DETAILS.TRACKING') }} #:</span>
              <Copy>
                <span class="mr-0.5 tracking-number">
                  {{ shipmentDetails?.tracking_number }}
                </span>
              </Copy>
            </div>
          </div>
        </div>
        <div class="mt-1 flex gap-2 text-xs text-gray-600">
          <div v-if="shipmentDetails?.estimated_delivery">
            <span>{{ t('SHIPMENT_DETAILS.ESTIMATED') }}:</span>
            <span>
              {{ shipmentDetails?.estimated_delivery }}
            </span>
          </div>
          <div
            v-if="shipmentDetails?.estimated_delivery"
            class="w-0.5 bg-gray-400"></div>
        </div>
        <div
          v-if="shipmentDetails.status !== ShipmentStatus.New"
          class="text-xs text-gray-600 mt-1">
          <span>{{ t('SHIPMENT_DETAILS.CREATED') }}:</span>
          <span class="ml-1">
            {{ formatCreatedData(shipmentDetails?.created_at) }}
          </span>
        </div>
        <b-button
          data-id="carrier-drawer-button"
          type="blank"
          class="mt-2 h-auto p-0 text-xs text-blue-400 font-semibold"
          @click="toggleDrawer('carrier')">
          {{ t('SHIPMENT_DETAILS.SEE_MORE') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentDetailsCarrier'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import format from 'date-fns/format';
import { useTranslation } from 'i18next-vue';
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';

import BButton from '@/components/atoms/Button';
import Copy from '@/components/atoms/Copy';
import BIcon from '@/components/atoms/Icon';
import {
  ActivityLogKey,
  FulfilmentCarrierModalKey,
  ModalIds,
  RejectionReasonKey,
  ReversedShipmentEventsKey,
  ShipmentEventsKey,
  ShowModalKey,
  ToggleDrawerKey
} from '@/components/pages/ShipmentDetails/constants';
import { ShipmentEventType, ShipmentStatus } from '@/enums';

const { t } = useTranslation();
const store = useStore();

const fulfilmentCarrierModal = inject(FulfilmentCarrierModalKey);

const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);
const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);
const isCustomService = computed(() => store.getters['auth/isCustomService']);

const shippingAccount = computed(() =>
  store.getters['shippingAccounts/getShippingAccountById'](shipmentDetails.value?.shipping_account_id)
);

const serviceName = computed(() =>
  store.getters['carriers/getServiceTypeNameBySlugAndCarrier'](
    shipmentDetails.value?.service,
    shipmentDetails.value?.carrier
  )
);

const shipmentEvents = inject(ShipmentEventsKey);

const reversedShipmentEvents = inject(ReversedShipmentEventsKey);

const rejectionReason = inject(RejectionReasonKey);

const cancellationReason = computed(() => {
  const lastCancelEvent = reversedShipmentEvents.value.findLast(
    (event) => event.type === ShipmentEventType.StatusUpdate && event.value === ShipmentStatus.CanceledByCarrier
  );

  if (!lastCancelEvent) {
    return null;
  }

  const firstCheckpointAfterCancel = shipmentEvents.value.find(
    (event) => event.type === ShipmentEventType.Checkpoint && event.created_at >= lastCancelEvent.created_at
  );

  return firstCheckpointAfterCancel?.value;
});

const canCancelShipment = computed(
  () =>
    ![ShipmentStatus.Canceled, ShipmentStatus.CanceledByCarrier, ShipmentStatus.Delivered].includes(
      shipmentDetails.value.status
    )
);

const activityLog = inject(ActivityLogKey);

const { ASSIGN_MODAL_ID, CANCEL_MODAL_ID } = ModalIds;

const redispatchLoading = ref(false);

const showModal = inject(ShowModalKey);

const toggleDrawer = inject(ToggleDrawerKey);

const redispatchEnabled = computed(() => Boolean(!shipmentDetails.value.carrier && rejectionReason.value));

const redispatch = async () => {
  redispatchLoading.value = true;

  await fulfilmentCarrierModal.value.redispatch({
    shipping_account_id: rejectionReason.value.shipping_account_id,
    service: rejectionReason.value.service
  });

  redispatchLoading.value = false;
};

const formatCreatedData = (data) => format(Date.parse(data), 'MMM. d, Y');
</script>

<style lang="scss" scoped>
.tracking-title {
  color: var(--Color-Neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}

.tracking-number {
  color: var(--Color-Neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
</style>
