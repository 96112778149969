<template>
  <div class="pl-10 pr-6 pt-6 flex h-full flex-col overflow-hidden">
    <shipping-account-modal
      :value="form.data"
      :carriers="carriers"
      :locations="locations"
      :country-options="countryOptions"
      :currency-options="currencyOptions"
      :packaging-types="packagingTypes"
      :service-types="serviceTypes"
      :label-types="labelTypes"
      :is-shown="form.isOpen"
      :is-create-modal="form.isCreateForm"
      @send="handle"
      @close="close" />
    <alert
      v-if="error"
      class="mb-8"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>
    <b-table
      :loading="loading"
      :fields="fields"
      :data="shippingAccounts"
      row-key="id"
      cell-class="pl-2 pr-2 pt-1 pb-1"
      customize-columns
      entity-name="shipping-accounts"
      last-update>
      <template #right-filter-bar>
        <b-button
          v-if="isAdmin"
          class="ml-2 px-6 py-2"
          :disabled="carriersLoading"
          data-id="addShippingAccount"
          @click="showCreateForm">
          {{ t('SHIPPING_ACCOUNTS.ADD_BUTTON') }}
        </b-button>
      </template>
      <template #cell(carrier)="{ row, columnIndex, parent }">
        <img
          v-if="row.top"
          class="w-10 h-10 inline-flex rounded"
          :src="row.carrier_logo"
          alt="Logo" />
        <template v-if="row && row.children && columnIndex === 0">
          <icon
            class="text-base mx-2 cursor-pointer"
            :icon-name="row.opened ? 'chevron-up' : 'chevron'"
            @click="row.opened = !row.opened" />
        </template>
        <span
          class="text-blue-400"
          :class="{ 'ml-18': parent, 'ml-2': !row.children && !parent }">
          {{ row.carrier_name }}
        </span>
      </template>
      <template #cell(accountName)="{ row }">
        {{ row.name }}
      </template>
      <template #cell(idNumber)="{ row }">
        {{ row.id }}
      </template>
      <template #cell(shippingLocation)="{ row }">
        {{ row.shipping_location_name }}
      </template>
      <template #cell(country)="{ row }">
        {{ row.country }}
      </template>
      <template #cell(actions)="{ row }">
        <dropdown
          v-if="!row.children"
          :options="getActionOptions(row)"
          button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
          :left-align="false"
          @select="selectAction(row, $event)">
          <template #button>
            <icon
              class="mr-3 text-xl"
              icon-name="menu-horizontal" />
          </template>
        </dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ShippingAccounts'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';

import countryMapping from '../../../assets/js/countries.json';
import currencyList from '../../../assets/js/currencies.json';
import Alert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Dropdown from '../../molecules/Dropdown';
import ShippingAccountModal from '../../organisms/ShippingAccountModal';
import BTable from '../../organisms/Table';

const currencyOptions = Object.keys(currencyList)
  .map((value) => ({ value, label: currencyList[value] }))
  .sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

const store = useStore();
const { t } = useTranslation();

const form = reactive({
  isOpen: false,
  isCreateForm: false,
  data: {}
});

onMounted(async () => {
  await Promise.all([loadLocations(), loadShippingAccounts(), loadWebhooks()]);
});

const countryOptions = computed(() =>
  Object.keys(countryMapping).reduce((result, value) => {
    const item = { value, label: countryMapping[value] };

    if (store.getters['auth/region'].toLowerCase().includes('eu-') && (value === 'GB' || value === 'FR')) {
      result.splice(0, 0, item);

      return result;
    }

    result.push(item);

    return result;
  }, [])
);

const blockedUniqueShippingAccountIDs = computed(
  () => new Set(store.getters['webhooks/items'].flatMap((webhook) => webhook.shipping_account_ids))
);

const shippingAccounts = computed(() => store.getters['shippingAccounts/items']);
const locations = computed(() => store.getters['shippingLocations/items']);
const carriers = computed(() => store.state.carriers.items);
const packagingTypes = computed(() => store.getters['carriers/packagingTypes']);
const labelTypes = computed(() => store.getters['carriers/labelTypes']);
const serviceTypes = computed(() => store.getters['carriers/serviceTypes']);
const isAdmin = computed(() => store.getters['auth/isAdmin']);

const loading = computed(() => store.state.shippingAccounts.loading);
const carriersLoading = computed(() => store.state.carriers.loading);
const error = computed(() => store.state.shippingAccounts.error || store.state.carriers.error);
const fields = computed(() => {
  const fields = [
    { key: 'carrier', label: 'SHIPPING_ACCOUNTS.CARRIER_FIELD' },
    { key: 'accountName', label: 'SHIPPING_ACCOUNTS.ACCOUNT_NAME_FIELD' },
    { key: 'idNumber', label: 'SHIPPING_ACCOUNTS.ID_NUMBER_FIELD' },
    { key: 'shippingLocation', label: 'SHIPPING_ACCOUNTS.SHIPPING_LOCATION_FIELD' },
    { key: 'country', label: 'SHIPPING_ACCOUNTS.COUNTRY_FIELD' }
  ];

  if (store.getters['auth/isAdmin']) {
    fields.push({ key: 'actions', label: 'ACTIONS_FIELD' });
  }

  return fields;
});

const enabledActionOptions = [
  { bringgIcon: 'pencil', value: 'edit-shipping-account', label: t('QUICK_ACTIONS.EDIT') },
  { bringgIcon: 'trash', value: 'disconnect-shipping-account', label: t('QUICK_ACTIONS.DISCONNECT') }
];

const disabledActionOptions = [
  { bringgIcon: 'pencil', value: 'edit-shipping-account', label: t('QUICK_ACTIONS.EDIT') },
  {
    bringgIcon: 'trash',
    value: 'disconnect-shipping-account',
    label: t('QUICK_ACTIONS.DISCONNECT'),
    disabled: true,
    tooltip: t('SHIPPING_ACCOUNTS.DISCONNECT_TOOLTIP')
  }
];

const getActionOptions = (account) => {
  const accountHasWebhook = blockedUniqueShippingAccountIDs.value.has(account.id);

  return accountHasWebhook ? disabledActionOptions : enabledActionOptions;
};

const loadShippingAccounts = async () => await store.dispatch('shippingAccounts/loadShippingAccounts');
const loadLocations = async () => await store.dispatch('shippingLocations/loadShippingLocations');
const loadWebhooks = async () => await store.dispatch('webhooks/loadWebhooks');

const clearError = () => {
  if (store.state.shippingAccounts.error) {
    store.commit('shippingAccounts/clearError');
  }
  if (store.state.carriers.error) {
    store.commit('carriers/clearError');
  }
};
const createShippingAccount = async (value) => await store.dispatch('shippingAccounts/createShippingAccount', value);
const updateShippingAccount = async (value) => await store.dispatch('shippingAccounts/updateShippingAccount', value);

const selectAction = async (row, value) => {
  const actions = {
    'edit-shipping-account': () => {
      showEditForm(row);
    },
    'disconnect-shipping-account': async () => {
      await store.dispatch('shippingAccounts/deleteShippingAccount', { id: row.id });
    }
  };

  return await actions[value]();
};

const clearValue = () => (form.data = {});

const showCreateForm = () => {
  clearValue();
  form.isOpen = true;
  form.isCreateForm = true;
};

const showEditForm = (value) => {
  form.data = store.getters['shippingAccounts/getShippingAccountById'](value.id);
  form.isOpen = true;
  form.isCreateForm = false;
};

const close = () => {
  clearValue();
  form.isOpen = false;
};

const handle = async (value) => {
  form.isOpen = false;

  if (form.isCreateForm) {
    await createShippingAccount({ ...value });
  } else {
    await updateShippingAccount({ ...value });
  }

  clearValue();
};
</script>
