<template>
  <modal
    data-id="carrier-booked"
    :value="isShown"
    @close="onClose">
    <template #body>
      <div class="flex flex-col items-center justify-center p-4 pb-10">
        <img
          src="./shipment-succeeded.svg"
          alt="Shipment succeeded" />
        <div class="mt-4 mb-2 title">{{ t('CARRIER_BOOKED_MODAL.TITLE') }}</div>
        <div class="description">
          <span
            class="text-blue-500 underline cursor-pointer"
            @click="goToShipment">
            {{ capitalizeFirstLetter(t('ENTITIES.SHIPMENT')) }}
          </span>
          {{ t('CONFIRMATION_TEXT') }}
          <span class="name">{{ name }}</span>
        </div>
        <span
          v-if="pudo"
          class="text-gray-600">
          [{{ pudo }}]
        </span>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'PopupCarrierBooked'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { defineEmits, defineProps } from 'vue';
import { useRouter } from 'vue-router';

import Modal from '@/components/organisms/Modal';
import { capitalizeFirstLetter } from '@/utils';

const { t } = useTranslation();
const router = useRouter();

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  isShown: {
    type: Boolean,
    default: false
  },
  shipment: {
    type: Object,
    default: () => ({})
  },
  pudo: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['close']);

const goToShipment = () => {
  router.push({
    name: 'shipment-details',
    params: { id: props.shipment.id, orderId: props.shipment.order_id, fulfillmentId: props.shipment.fulfillment_id }
  });
};

const onClose = () => emit('close');
</script>

<style lang="scss" scoped>
.title {
  color: var(--naturals-gray-800, #2d3748);
  font-family: Inter, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.6px;
}

.description {
  color: var(--naturals-gray-700, #4a5568);
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
}

.name {
  color: var(--naturals-gray-700, #4a5568);
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.pudo {
  color: var(--ray-600, #718096);
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
}
</style>
