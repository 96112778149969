<template>
  <collapse v-if="payload">
    <template #lable>
      <span class="text-sm font-medium">{{ t('SHIPMENT_DETAILS.DETAILS') }}</span>
    </template>

    <template #content>
      {{ JSON.stringify(payload, null, 2) }}
    </template>
  </collapse>
</template>

<script>
export default {
  name: 'Payload'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';

import Collapse from '@/components/molecules/Collapse';

defineProps({
  payload: {
    type: Object,
    required: true
  }
});

const { t } = useTranslation();
</script>
