<template>
  <TransitionRoot
    :show="open"
    as="template">
    <div :class="'inset-0 fixed z-50 flex justify-center content-center flex-wrap'">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          @click="close" />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
        enter-to="opacity-100 translate-y-0 md:scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 md:scale-100"
        leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
        <div class="flex flex-col">
          <div class="z-50 flex items-center text-white justify-between w-full mb-1">
            <button
              type="button"
              class="flex flex-row items-center"
              @click="rotate">
              <icon
                class="mr-1"
                :icon-name="BringgFontIcons.Rotate" />
              <span class="rotate-text">{{ t('IMAGE_MODAL.ROTATE') }}</span>
            </button>
            <div class="flex-grow"></div>
            <button
              type="button"
              @click="close">
              <XIcon
                class="h-4 w-4"
                aria-hidden="true" />
            </button>
          </div>
          <div
            :style="{ transform: `rotate(${rot}deg)` }"
            class="modal-container p-4 flex shadow-xl transform transition-all rounded-lg bg-white justify-center items-center">
            <div class="img-container flex justify-center items-center">
              <img
                v-if="mimeType === null"
                :src="src"
                alt="Image" />
              <VuePdf
                v-if="mimeType === MimeTypes.pdf"
                :src="src"
                :page="1" />
            </div>
          </div>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
</template>

<script>
export default {
  name: 'ImageModal'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { useTranslation } from 'i18next-vue';
import { defineEmits, ref, watch } from 'vue';
import { VuePdf } from 'vue3-pdfjs';

import Icon from '@/components/atoms/Icon';
import { MimeTypes } from '@/constants';

const rot = ref(0);

const props = defineProps({
  src: {
    // src could be string or Uint8Array
    type: [String, Object],
    required: true
  },
  open: {
    type: Boolean,
    required: true
  },
  mimeType: {
    type: String,
    default: null,
    validator: function (value) {
      // mime type is null src is image
      return [...Object.values(MimeTypes), null].includes(value);
    }
  }
});

const emit = defineEmits(['show', 'close']);

watch(
  () => props.open,
  (open) => {
    if (open) {
      emit('show');
      rot.value = 0;
    }
  }
);

const { t } = useTranslation();

const close = () => emit('close');

const rotate = () => (rot.value += 90);
</script>

<style lang="scss" scoped>
.rotate-text {
  color: var(--inverse, #fff);
  text-align: center;
  font-family:
    Open Sans,
    serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.modal-container {
  box-shadow:
    0 25.40625px 50.8125px 0 rgba(0, 51, 80, 0.16),
    0 0 12.70313px 0 rgba(0, 51, 80, 0.08);
}

.img-container {
  max-height: 800px;
  max-width: 800px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
