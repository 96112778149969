<template>
  <div class="inline-flex items-center">
    <input
      :id="id"
      :data-id="dataId"
      :name="name"
      :disabled="disabled"
      :value="value"
      :checked="check"
      type="checkbox"
      class="rounded-sm bg-white border focus:border-transparent focus:bg-blue-200 text-blue-400 focus:ring-1 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
      :class="[error ? 'border-red-300' : 'border-gray-700']"
      @click="updateValue" />
    <label
      :for="id"
      class="ml-2 cursor-pointer">
      <slot name="label" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox'
};
</script>

<script setup>
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps } from 'vue';

const props = defineProps({
  value: {
    type: null,
    default: false
  },
  id: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  validation: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  dataId: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:value']);

const connnectedField = useField(props.name, undefined, {
  type: 'checkbox',
  checkedValue: props.value
});

const check = computed(() => {
  if (props.validation) {
    return connnectedField.checked.value;
  } else {
    return props.value;
  }
});

const updateValue = () => {
  connnectedField.handleChange(props.value);
  emit('update:value', !props.value);
};
</script>
