<template>
  <div class="flex flex-col">
    <div
      v-if="$slots['label']"
      class="block text-xs mb-1 flex">
      <template v-if="required">
        <span class="text-xs text-red-600 mr-0.5">*</span>
      </template>
      <slot name="label" />
    </div>
    <div
      class="h-8 flex inline-flex p-1 gap-1.5 items-center bg-white border rounded-md"
      :class="[errorMessage ? 'focus:ring-red-500 focus:border-red-500 border-red-300' : 'border-gray-300']">
      <span
        :class="
          disabled || value === null || value === min
            ? 'text-gray-400 cursor-not-allowed'
            : 'text-blue-400 cursor-pointer'
        "
        @click="minus">
        <b-icon
          icon-name="minus"
          class="text-sm" />
      </span>
      <input
        ref="input"
        type="number"
        size="4"
        :value="value"
        class="h-7 w-9 p-0 border-0 block font-normal text-gray-700 text-center"
        :placeholder="placeholder"
        @change="onChange"
        @input="onInput"
        @click="onClick" />
      <span
        :class="disabled || value === max ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400 cursor-pointer'"
        @click="plus">
        <b-icon
          icon-name="plus"
          class="text-sm" />
      </span>
    </div>
    <p
      v-if="errorMessage"
      class="w-20 mt-2 text-red-600"
      :class="errorStyles ? errorStyles : 'text-sm'">
      <slot name="error-message">{{ errorMessage }}</slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'BCounter'
};
</script>

<script setup>
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps, onBeforeUnmount, onMounted, ref } from 'vue';

import BIcon from '../Icon';

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 9999
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  errorStyles: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:value']);
const input = ref(null);

const name = computed(() => props.name);

const { value, handleChange, errorMessage } = useField(name);

const minus = () => {
  if (value.value - 1 < props.min) {
    return;
  }

  const data = value.value - 1;

  emit('update:value', data);
  handleChange(data);
};
const plus = () => {
  if (value.value + 1 > props.max) {
    return;
  }

  const data = value.value + 1;

  emit('update:value', data);
  handleChange(data);
};

const onChange = (event) => {
  const { value } = event.target;

  handleChange(Number(value));
};

const onInput = (event) => {
  const { value } = event.target;

  if (value === '') {
    return;
  }

  if (isNaN(value)) {
    event.target.value = props.value;
  }

  if (value > props.max) {
    event.target.value = props.max;
  }

  if (value < props.min) {
    event.target.value = props.min;
  }
};

const onClick = (event) => {
  event.target.value = '';
};

const onClickOutside = (event) => {
  if (!input.value || input.value.contains(event.target)) {
    return;
  }

  if (input.value.value === '') {
    input.value.value = value.value;
  }
};

onMounted(() => {
  document.addEventListener('click', onClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', onClickOutside);
});
</script>

<style lang="scss" scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}
</style>
