export const defaultButtonClasses = `
  inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm
  font-medium text-gray-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed
  disabled:opacity-50 disabled:cursor-not-allowed
`;

export const defaultMenuClasses = `
  origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y
  divide-gray-100 focus:outline-none z-50
`;
