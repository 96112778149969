<template>
  <i :class="[ICON_BASE_NAME, `${ICON_BASE_NAME}-${iconName}`, { spin, circle }]" />
</template>

<script>
export default {
  name: 'Icon'
};
</script>

<script setup>
import '@bringg/bringg-icons/dist/bringg-font-icons.css';

import { defineProps } from 'vue';

import { ICON_BASE_NAME } from './constants';

defineProps({
  iconName: {
    type: String,
    required: true
  },
  spin: {
    type: Boolean,
    default: false
  },
  circle: {
    type: Boolean,
    default: false
  }
});
</script>
