import { executeRequest } from '../../utils';

const state = {
  items: [],
  itemDetails: {},
  loading: false,
  error: '',
  total: 0
};

const getters = {};

const actions = {
  async loadOrders(options, params) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders`,
        params
      },
      options,
      errorMessage: 'ORDERS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setOrders', response.data);
      options.commit('setOrdersTotal', Number(response.headers['total-count']));
    });
  },

  async loadOrdersById(options, orderId) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}`
      },
      options,
      errorMessage: 'ORDER_DETAILS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setOrderItem', response.data));
  },

  async createOrder(options, { returnError = true, ...data }) {
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/orders`,
        data
      },
      options,
      errorMessage: 'ORDER_CREATE_FAIL',
      returnError
    };

    return await executeRequest(request);
  },

  async updateOrder(options, { orderId, data }) {
    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}`,
        data
      },
      options,
      errorMessage: 'ORDER_UPDATE_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setOrderItem', response.data);
    });
  }
};

const mutations = {
  setOrders(state, orders) {
    state.items = orders;
  },

  setOrdersTotal(state, count) {
    state.total = count;
  },

  setOrderItem(state, value) {
    state.itemDetails = value;
  },

  clearError(state) {
    state.error = '';
  },

  setError(state, error) {
    state.error = error;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
