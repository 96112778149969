<template>
  <popover class="relative">
    <popover-button>
      <span class="p-1.5 px-2.5 pb-1 bg-gray-100 rounded-2xl">
        <icon
          class="text-xl text-gray-600"
          :icon-name="BringgFontIcons.SortDesc" />
        <div
          v-if="value.length"
          class="inline-flex justify-center items-center w-4 h-4 leading-5 text-2xs font-semibold text-white bg-blue-400 rounded-full">
          {{ value.length }}
        </div>
      </span>
    </popover-button>
    <popover-panel
      v-slot="{ close }"
      class="absolute right-0 z-10">
      <div class="p-8 bg-white tracking-tight rounded custom-shadow">
        <h3 class="mb-6 flex justify-between items-center text-xl">
          {{ t('TABLE.SORT_TITLE') }}
          <span
            class="p-2.5 cursor-pointer hover:bg-gray-50 rounded-full"
            @click="cancel(close)">
            <icon
              class="text-xl"
              :icon-name="BringgFontIcons.Close" />
          </span>
        </h3>
        <draggable
          v-model="data.localValue"
          class="mr-6"
          item-key="key"
          handle=".handle">
          <template #item="{ index }">
            <div class="flex items-center px-4 py-2 hover:bg-gray-50">
              <span class="handle p-1 mr-4 cursor-move">
                <icon
                  class="text-xl text-gray-600"
                  :icon-name="BringgFontIcons.Drag" />
              </span>
              <b-select
                v-model:value="data.localValue[index].key"
                class="w-52"
                :options="allowedSortColumns" />
              <toggle
                v-model:value="data.localValue[index].direction"
                class="ml-6"
                :left-value="ASC_DIRECTION"
                :right-value="DESC_DIRECTION"
                :left-label="toggleLeftLabel"
                :right-label="toggleRightLabel" />
              <span class="p-2.5 ml-12 cursor-pointer rounded-full hover:bg-gray-100">
                <icon
                  class="text-xl text-gray-600"
                  :icon-name="BringgFontIcons.Trash"
                  @click="remove(index)" />
              </span>
            </div>
          </template>
        </draggable>
        <div
          class="mt-3 text-blue-400 font-semibold cursor-pointer"
          @click="add">
          <span class="inline-flex justify-center items-center h-6 w-6 p-1.5 mr-2 rounded-full text-white bg-blue-400">
            <icon
              class="text-xl"
              :icon-name="BringgFontIcons.Plus" />
          </span>
          {{ t('TABLE.ADD_SORT_BUTTON') }}
        </div>
        <div class="flex justify-end mt-7">
          <span
            class="mr-7 px-6 py-3 border-none text-base text-blue-400 font-semibold cursor-pointer"
            @click="cancel(close)">
            {{ t('CANCEL') }}
          </span>
          <b-button
            type="info"
            size="large"
            @click="submit(close)">
            {{ t('TABLE.SORT_BUTTON') }}
          </b-button>
        </div>
      </div>
    </popover-panel>
  </popover>
</template>

<script>
export default {
  name: 'TableSortModal'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, reactive, watch } from 'vue';
import Draggable from 'vuedraggable';

import BButton from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import BSelect from '../../atoms/Select';
import Toggle from '../../atoms/Toggle';

const ASC_DIRECTION = 'asc';
const DESC_DIRECTION = 'desc';

const defaultItem = {
  key: '',
  direction: ASC_DIRECTION
};

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Array,
    required: true
  },
  columns: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['update:value']);

const data = reactive({
  localValue: [{ ...defaultItem }]
});

const allowedSortColumns = computed(() => props.columns.map(({ key: value, label }) => ({ value, label })));

const toggleLeftLabel = computed(() => `${t('TABLE.FIRST_TOGGLE_LABEL')} ➝ ${t('TABLE.LAST_TOGGLE_LABEL')}`);
const toggleRightLabel = computed(() => `${t('TABLE.LAST_TOGGLE_LABEL')} ➝ ${t('TABLE.FIRST_TOGGLE_LABEL')}`);

const copyValue = () => props.value.map((item) => ({ ...item }));

watch(
  () => props.value,
  () => {
    data.localValue = copyValue();
    if (!data.localValue.length) {
      data.localValue.push({ ...defaultItem });
    }
  }
);

const add = () => {
  if (!allowedSortColumns.value.length) {
    return;
  }
  data.localValue.push({ ...defaultItem });
};

const remove = (index) => data.localValue.splice(index, 1);

const submit = (close) => {
  close();
  emit('update:value', data.localValue);
};

const cancel = (close) => {
  close();
  data.localValue = copyValue() || [{ ...defaultItem }];
};
</script>

<style>
.custom-shadow {
  box-shadow:
    0 2px 8px rgba(0, 51, 80, 0.04),
    0 16px 24px rgba(0, 51, 80, 0.16);
}
</style>
