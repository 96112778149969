<template>
  <AnalyticsFrame :route-name="AnalyticsRouteName.reports"></AnalyticsFrame>
</template>

<script>
export default {
  name: 'BiDashboard'
};
</script>

<script setup>
import AnalyticsFrame from '@/components/organisms/AnalyticsFrame';

import { AnalyticsRouteName } from '../../../constants';
</script>
