import { reactive } from 'vue';

const EXCLUDED_FIELDS = ['updated'];

export default function (emit) {
  const data = reactive({
    isOpen: false,
    isCreateForm: false,
    form: {}
  });

  const clearValue = () => (data.form = {});

  const showCreateForm = () => {
    clearValue();
    data.isOpen = true;
    data.isCreateForm = true;
  };

  const showEditForm = (formData) => {
    data.form = formData;
    data.isOpen = true;
    data.isCreateForm = false;
  };

  const close = () => {
    clearValue();
    data.isOpen = false;
  };

  const handle = (formData, refreshList) => {
    refreshList = refreshList || false;

    EXCLUDED_FIELDS.forEach((field) => delete formData[field]);

    if (data.isCreateForm) {
      emit('create', formData);
    } else {
      emit('update', formData, refreshList);
    }

    clearValue();
    data.isOpen = false;
  };

  return {
    data,
    showCreateForm,
    showEditForm,
    close,
    handle
  };
}
