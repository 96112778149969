<template>
  <div
    :class="{ 'opacity-50 cursor-not-allowed': disabled }"
    class="w-fit relative p-1 z-0 flex justify-between border border-gray-300 rounded-md bg-white text-sm leading-7">
    <span
      class="w-24 text-center cursor-pointer rounded-md"
      :class="{ 'selected text-blue-500 bg-gray-100': value === leftValue, 'cursor-not-allowed': disabled }"
      @click="updateValue(leftValue)">
      {{ finalLeftLabel }}
    </span>
    <span
      class="w-24 text-center cursor-pointer rounded-md"
      :class="{ 'selected text-blue-500 bg-gray-100': value === rightValue, 'cursor-not-allowed': disabled }"
      @click="updateValue(rightValue)">
      {{ finalRightLabel }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Toggle'
};
</script>

<script setup>
import { computed, defineEmits, defineProps } from 'vue';

const props = defineProps({
  value: {
    type: String,
    required: true
  },
  leftValue: {
    type: String,
    required: true
  },
  rightValue: {
    type: String,
    required: true
  },
  leftLabel: {
    type: String,
    default: ''
  },
  rightLabel: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:value']);

function _title(txt) {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
}

const finalLeftLabel = computed(() => props.leftLabel || _title(props.leftValue));

const finalRightLabel = computed(() => props.rightLabel || _title(props.rightValue));

const updateValue = (value) => {
  if (props.disabled) {
    return;
  }
  emit('update:value', value);
};
</script>
