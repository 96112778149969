<template>
  <div
    v-if="isMounted"
    class="relative h-full">
    <teleport to="#pageTitle">
      <div class="flex items-top">
        <span
          class="mr-1cursor-pointer"
          @click="$router.back()">
          <b-icon
            icon-name="arrow-left"
            class="w-6 h-6 text-blue-500" />
        </span>
        <span class="mr-4">{{ title }}</span>
      </div>
    </teleport>
    <alert
      v-if="error"
      class="mb-9 absolute inset-x-0 top-0 z-10"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>
    <alert
      v-if="data.success"
      class="mb-9 absolute inset-x-0 top-0 z-10"
      type="success">
      <i18next :translation="$t('ADD_LOCATION.SUCCESSES_MESSAGE')">
        <template #action>
          {{ data.success }}
        </template>
      </i18next>
    </alert>
    <v-form
      v-slot="{ values }"
      class="h-full"
      :validation-schema="schema"
      :initial-values="data.localValue"
      @submit="onSubmit">
      <div class="h-full flex flex-col">
        <div
          v-disable-if-not-admin="isAdmin"
          class="flex-1 pl-10 pt-6 flex gap-12 overflow-auto">
          <div class="w-2/3">
            <h2 class="text-base text-gray-800 font-semibold">{{ t('ADD_LOCATION.LOCATION_DETAILS') }}</h2>
            <div>
              <div class="flex gap-4 mt-3">
                <b-input
                  id="name"
                  name="name"
                  input-class="w-72"
                  size="small"
                  :label="`${stylizeLabelAsRequired(t('ADD_LOCATION.NAME'))}`"></b-input>
                <b-select
                  data-id="type"
                  :value="values.type"
                  name="type"
                  class="w-72"
                  size="small"
                  :options="typeOptions"
                  placeholder="Select from list">
                  <template #label>
                    <span class="block text-xs text-gray-600 font-normal mb-1">
                      {{ stylizeLabelAsRequired(t('ADD_LOCATION.TYPE')) }}
                    </span>
                  </template>
                </b-select>
              </div>
              <div class="flex gap-4 mt-3">
                <b-input
                  id="description"
                  name="description"
                  input-class="w-72"
                  size="small"
                  :label="`${stylizeLabelAsRequired(t('ADD_LOCATION.DESCRIPTION'))}`"></b-input>
                <b-input
                  id="source_id"
                  name="source_id"
                  input-class="w-72"
                  size="small"
                  :label="t('ADD_LOCATION.SOURCE_ID')"></b-input>
              </div>
              <h2 class="mt-8 text-base text-gray-800 font-semibold">
                {{ stylizeLabelAsRequired(t('ADD_LOCATION.ADDRESS_DETAILS')) }}
              </h2>
              <div class="flex mt-3">
                <b-input
                  id="company"
                  name="address.company"
                  input-class="w-72"
                  size="small"
                  :label="`${stylizeLabelAsRequired(t('ADD_LOCATION.COMPANY'))}`"></b-input>
              </div>
              <div class="flex gap-4 mt-3">
                <b-input
                  id="street1"
                  name="address.street1"
                  input-class="w-72"
                  size="small"
                  :label="`${stylizeLabelAsRequired(t('ADD_LOCATION.ADDRESS_LINE_1'))}`"></b-input>
                <b-input
                  id="street2"
                  name="address.street2"
                  input-class="w-72"
                  size="small"
                  :label="`${t('ADD_LOCATION.ADDRESS_LINE_2')} (${t('OPTIONAL')})`"></b-input>
              </div>
              <div class="flex gap-4 mt-3">
                <b-input
                  id="city"
                  name="address.city"
                  input-class="w-72"
                  size="small"
                  :label="`${stylizeLabelAsRequired(t('ADD_LOCATION.CITY'))}`"></b-input>
                <b-input
                  id="state"
                  name="address.state"
                  input-class="w-72"
                  size="small"
                  :label="t('ADD_LOCATION.STATE')"></b-input>
              </div>
              <div class="flex gap-4 mt-3">
                <b-input
                  id="postal_code"
                  name="address.postal_code"
                  input-class="w-72"
                  size="small"
                  :label="`${stylizeLabelAsRequired(t('ADD_LOCATION.POST_CODE'))}`"></b-input>
                <b-select
                  data-id="country"
                  name="address.country"
                  class="w-72"
                  size="small"
                  :value="values.address.country"
                  :options="countryOptions"
                  placeholder="Select from list">
                  <template #label>
                    <span class="block text-xs text-gray-600 font-normal mb-1">
                      {{ stylizeLabelAsRequired(t('ADD_LOCATION.COUNTRY')) }}
                    </span>
                  </template>
                </b-select>
              </div>
              <h2 class="mt-8 text-base text-gray-800 font-semibold">
                {{ stylizeLabelAsRequired(t('ADD_LOCATION.CONTACT_DETAILS')) }}
              </h2>
              <div class="flex gap-4 mt-3 pb-10">
                <b-input
                  id="phone"
                  name="address.phone"
                  input-class="w-72"
                  size="small"
                  :label="`${stylizeLabelAsRequired(t('ADD_LOCATION.PHONE'))}`"></b-input>
                <b-input
                  id="email"
                  name="address.email"
                  input-class="w-72"
                  size="small"
                  :label="`${stylizeLabelAsRequired(t('EMAIL'))}`"></b-input>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isAdmin"
          class="sticky bottom-0 px-10 py-2 flex gap-6 justify-end bg-white">
          <b-button
            class="px-6 border-none bg-transparent text-blue-400 text-base"
            data-id="cancel"
            @click="onCancel">
            {{ t('CANCEL') }}
          </b-button>
          <b-button
            class="px-6 border-none text-base"
            :loading="data.fetching"
            :data-id="data.isEdit ? 'save' : 'save-location'"
            submit>
            {{ data.isEdit ? t('SAVE_BUTTON') : t('ADD_LOCATION.SAVE_LOCATION') }}
          </b-button>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'AddLocation'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { set } from 'lodash';
import { Form as VForm } from 'vee-validate';
import { computed, defineProps, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { object, string } from 'yup';

import countryList from '@/assets/js/countries.json';
import { phoneRegex, stylizeLabelAsRequired } from '@/utils';

import Alert from '../../atoms/Alert';
import BSelect from '../../atoms/BSelect';
import BButton from '../../atoms/Button';
import BIcon from '../../atoms/Icon';
import BInput from '../../atoms/Input';

const store = useStore();
const { t } = useTranslation();
const router = useRouter();

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  isEdit: {
    type: Boolean,
    default: false
  }
});

const isMounted = ref(false);
const isAdmin = computed(() => store.getters['auth/isAdmin']);

const getShippingLocation = async (id) =>
  store.getters['shippingLocations/shippingLocation'](id) ||
  (await store.dispatch('shippingLocations/loadShippingLocation', { id }));

const setLocation = async (id) => {
  if (!id) {
    return;
  }

  const {
    name,
    type,
    description,
    source_id,
    address: { id: address_id, company, street1, street2, city, state, postal_code, country, phone, email }
  } = await getShippingLocation(id);

  data.localValue = {
    name,
    type,
    description,
    source_id,
    address: { company, street1, street2, city, state, postal_code, country, phone, email }
  };
  data.address_id = address_id;
};
const localValueFactory = () => {
  return {
    name: '',
    type: '',
    description: '',
    source_id: '',
    address: {
      company: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      email: '',
      phone: ''
    }
  };
};

const data = reactive({
  localValue: localValueFactory(),
  success: '',
  isEdit: props.isEdit,
  id: props.id,
  fetching: false
});

// TODO: Investigate why the value in the .when() function always returns undefined

const schema = computed(() =>
  object({
    name: string().max(255).required().label(t('ADD_LOCATION.NAME')),
    type: string().required().label(t('ADD_LOCATION.TYPE')),
    description: string().required().label(t('ADD_LOCATION.DESCRIPTION')),
    source_id: string().nullable().label(t('ADD_LOCATION.SOURCE_ID')),
    address: object({
      company: string().min(2).max(35).required().label(t('ADD_LOCATION.COMPANY')),
      street1: string().min(2).max(35).required().label(t('ADD_LOCATION.ADDRESS_LINE_1')),
      street2: string()
        .when('address.street2', (_, schema, options) => {
          if (options.value?.length > 0) {
            return string().min(2).max(35).label(t('ADD_LOCATION.ADDRESS_LINE_2'));
          } else {
            return string().nullable();
          }
        })
        .label(t('ADD_LOCATION.ADDRESS_LINE_2')),
      city: string().min(2).max(35).required().label(t('ADD_LOCATION.CITY')),
      state: string()
        .when('address.state', (_, schema, options) => {
          if (options.value?.length > 0) {
            return string().min(2).max(35).label(t('ADD_LOCATION.STATE'));
          } else {
            return string().nullable();
          }
        })
        .label(t('ADD_LOCATION.STATE')),
      postal_code: string().min(2).max(20).required().label(t('ADD_LOCATION.POST_CODE')),
      country: string().required().label(t('ADD_LOCATION.COUNTRY')),
      email: string().required().email().label(t('EMAIL')),
      phone: string()
        .min(10)
        .max(15)
        .matches(phoneRegex, { message: t('INVALID_PHONE') })
        .required()
        .label(t('ADD_LOCATION.PHONE'))
    })
  })
);

onMounted(async () => {
  await setLocation(props.id);
  isMounted.value = true;
});

const error = computed(() => store.state.shippingLocations.error);

const title = computed(() => {
  const { isEdit, id } = data;

  if (isEdit) {
    return `${t('ADD_LOCATION.EDIT_LOCATION')} #${id}`;
  }

  if (id) {
    return t('ADD_LOCATION.DUPLICATE_LOCATION');
  }

  return t('ADD_LOCATION.ADD_LOCATION');
});

const typeOptions = computed(() => [
  {
    label: t('ADD_LOCATION.WAREHOUSE'),
    value: 'Warehouse'
  },
  {
    label: t('ADD_LOCATION.DESTRIBUTION_CENTER'),
    value: 'Distribution center'
  },
  {
    label: t('ADD_LOCATION.STORE'),
    value: 'Store'
  }
]);

const countryOptions = computed(() =>
  Object.keys(countryList).map((value) => ({
    value,
    label: countryList[value]
  }))
);

const onSubmit = async (value, actions) => {
  data.fetching = true;
  const { isEdit, id } = data;
  const action = isEdit ? 'shippingLocations/editLocation' : 'shippingLocations/createLocation';

  if (isEdit) {
    set(value, 'id', data.id);
    set(value, 'address.id', data.address_id);
  }

  set(value, 'address.address_type', 'shipping_location');

  const shipping_location_id = await store.dispatch(action, value);

  if (error.value) {
    data.fetching = false;

    return;
  }

  if (isEdit) {
    data.success = t('ADD_LOCATION.UPDATED');
  } else {
    data.success = id ? t('ADD_LOCATION.DUPLICATED') : t('ADD_LOCATION.CREATED');
  }

  if (!isEdit) {
    data.isEdit = true;
    data.id = shipping_location_id;
  }

  await setLocation(data.id);
  actions.resetForm(value);

  data.fetching = false;

  setTimeout(() => (data.success = ''), 1500);
};

const onCancel = () => {
  router.push({ name: 'shipping-locations' });
};

const clearError = () => store.commit('shippingLocations/clearError');
</script>

<style scoped></style>
