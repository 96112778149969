<template>
  <div class="animate-pulse flex space-x-4">
    <div class="flex-1 space-y-4 py-1">
      <div
        v-for="index in props.count"
        :key="index"
        class="h-4 bg-gray-400 rounded"
        :class="props.childrenClasses[index]" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnimatedPlaceholder'
};
</script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  count: {
    type: Number,
    default: 1
  },
  childrenClasses: {
    type: Object,
    default: () => ({})
  }
});
</script>
