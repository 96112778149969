<template>
  <div
    v-if="events?.length || selectDummyEnabled"
    data-id="progress-bar-block"
    class="flex-grow overflow-y-hidden">
    <h1 class="pl-6 pb-2 title">
      <b-icon
        class="mr-1"
        :icon-name="BringgFontIcons.Route" />
      {{ t('SHIPMENT_DETAILS.SHIPMENT') }} {{ t('SHIPMENT_DETAILS.PROGRESS') }}
    </h1>
    <div
      v-if="selectDummyEnabled"
      class="dummy-container ml-6 bg-white shadow">
      <div class="dummy-notice whitespace-pre-line">{{ t('SHIPMENT_DETAILS.SHIPMENT_TRACKING_AVAILABLE_NOTICE') }}</div>
      <div class="dummy-bg" />
    </div>
    <div
      v-else
      class="flex flex-row h-full pb-10 overflow-y-auto">
      <div
        ref="eventsContainer"
        class="relative ml-6 overflow-auto w-full">
        <div class="bg-white shadow pt-3">
          <div
            v-if="!isProgressBarInShipmentMode"
            class="header mt-2 px-4 mb-4">
            <span class="package-label">{{ t('SHIPMENT_DETAILS.PACKAGE') }} #{{ packageIndex }}</span>
            <span class="tracking-label">
              {{ t('SHIPMENT_DETAILS.TRACKING') }}:
              <Copy>
                <span class="tracking-value mr-1">{{ selectedPackage.tracking_number }}</span>
              </Copy>
            </span>
          </div>
          <div
            v-for="(event, index) in events"
            :key="index"
            class="mx-4 flex">
            <div class="flex flex-col items-center mr-3">
              <div>
                <b-icon
                  :icon-name="index === 0 ? BringgFontIcons.Circle : BringgFontIcons.Selected"
                  class="w-6 h-6 rounded-full border justify-center items-center"
                  :class="[index === 0 ? 'text-blue-400 border-blue-400' : 'text-gray-400 border-gray-400']" />
              </div>
              <div
                v-if="index !== events.length - 1"
                class="w-0.5 h-full my-1"
                :class="`bg-gray-${event?.exist ? '700' : '200'}`" />
            </div>
            <div class="bg-white border shadow rounded p-2 mb-4 flex-1">
              <div>
                <badge
                  v-if="isProgressBarInShipmentMode && event.type === ShipmentEventType.StatusUpdate"
                  :type="statusBadgeTypeMapping[event.value]">
                  {{ t(event.value) }}
                </badge>

                <ShipmentPackageStatusBadge
                  v-if="
                    !isProgressBarInShipmentMode && event.type === ShipmentPackageEventType.StatusUpdate && event.value
                  "
                  :status="event.value" />

                <div
                  v-if="eventIsStatusUpdate(event)"
                  class="text-xs m-1">
                  <img
                    v-show="event?.carrier_logo"
                    class="h-4 w-4 rounded shadow-bottom inline mr-1"
                    :src="event?.carrier_logo"
                    :alt="`logo ${event?.carrier}`" />

                  <span class="text-gray-600 font-medium align-middle">
                    {{ event.shipping_account_name }}
                  </span>
                  <span class="text-gray-600 font-light align-middle">
                    {{ event.service ? ' | ' : '' }} {{ event.service }}
                  </span>
                </div>
                <div
                  v-if="eventIsCheckpoint(event)"
                  class="text-xs">
                  {{ event.value }}
                </div>
                <div class="mt-2 flex justify-between text-xs text-gray-600">
                  <div>{{ t('SHIPMENT_DETAILS.LOCATION') }}</div>
                  <div>{{ formatDateTime(event.timestamp) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentProgressBar'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { orderBy } from 'lodash';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import Badge from '@/components/atoms/Badge';
import Copy from '@/components/atoms/Copy';
import BIcon from '@/components/atoms/Icon';
import ShipmentPackageStatusBadge from '@/components/atoms/ShipmentPackageStatus';
import {
  ShipmentEventType,
  ShipmentPackageEventType,
  ShipmentPackageStatus,
  ShipmentStatus,
  statusBadgeTypeMapping
} from '@/enums';
import { formatDateTime } from '@/utils';

const { t } = useTranslation();
const store = useStore();

const isProgressBarInShipmentMode = computed(() => store.getters['shipments/isProgressBarInShipmentMode']);

const selectDummyEnabled = computed(
  () => !isProgressBarInShipmentMode.value && !store.state.shipments.selectedPackageId
);

const shipmentStatusesForCarrierData = [
  ShipmentStatus.ReadyToShip,
  ShipmentStatus.CanceledByCarrier,
  ShipmentStatus.Canceled
];

const shipmentPackageStatusesForCarrierData = [
  ShipmentPackageStatus.ReadyToShip,
  ShipmentPackageStatus.CanceledByCarrier
];

const events = computed(() => store.getters['shipments/getEventsForProgressBar']);

const eventIsStatusUpdate = (event) => {
  if (isProgressBarInShipmentMode.value) {
    return event.type === ShipmentEventType.StatusUpdate && shipmentStatusesForCarrierData.includes(event.value);
  }

  return (
    event.type === ShipmentPackageEventType.StatusUpdate && shipmentPackageStatusesForCarrierData.includes(event.value)
  );
};

const eventIsCheckpoint = (event) => {
  if (isProgressBarInShipmentMode.value) {
    return event.type === ShipmentEventType.Checkpoint;
  }

  return event.type === ShipmentPackageEventType.Checkpoint;
};

const selectedPackage = computed(() => {
  if (isProgressBarInShipmentMode.value) {
    return null;
  }

  const selectedPackageId = store.state.shipments.selectedPackageId;

  return store.state.shipments.shipmentDetails.shipments_packages.find((p) => p.id === selectedPackageId);
});

const packageIndex = computed(() => {
  if (isProgressBarInShipmentMode.value) {
    return null;
  }

  const selectedPackageId = store.state.shipments.selectedPackageId;

  const index = orderBy(store.state.shipments.shipmentDetails.shipments_packages, 'id').findIndex(
    (p) => p.id === selectedPackageId
  );

  return index + 1;
});

const eventsContainer = ref(null);
</script>

<style lang="scss" scoped>
.title {
  color: var(--Color-Neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}

.dummy-container {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Space-600, 48px);

  border-radius: var(--Radius-round-s, 4px);
  background: #fff;
}

.dummy-notice {
  color: var(--Color-Neutrals-700, #4a5568);
  text-align: center;
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.dummy-bg {
  background-image: url('../../../assets/img/shipment-progress-dummy.svg');
  display: flex;
  width: 360px;
  height: 162px;
  padding: 0 2.255px 1.778px 1.948px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.package-label {
  color: var(--Color-Neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  text-transform: capitalize;
}

.tracking-label {
  color: var(--Color-Neutrals-600, #718096);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.tracking-value {
  color: var(--Color-Neutrals-600, #718096);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.beak {
  border-width: 12px;
  width: 20px;
  height: 10px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
}
</style>
