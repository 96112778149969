import { convertDimensions, convertWeightToKg, executeRequest } from '@/utils';

const state = {
  items: [],
  fulfillmentItems: {},
  packageItems: {},
  loading: false,
  packAllLoading: false,
  packLoading: false,
  error: ''
};

const getters = {
  getRawItemByFulfillmentId: (state) => (fulfillmentId, id) => {
    const result = state.fulfillmentItems[fulfillmentId] || [];

    for (let i = 0; i < result.length; i++) {
      if (result[i].id === id) {
        return result[i];
      }
    }
  },
  getItemsByAllFulfillmentShipmentsId: (state, getters, rootState, rootGetters) => (fulfillmentId) =>
    rootGetters['shipments/getItemsByFulfillmentId'](fulfillmentId).reduce((result, shipment) => {
      result.push(...getters.getItemsByShipmentId(shipment.id));

      return result;
    }, []),
  getItemsByFulfillmentId: (state, getters, rootState, rootGetters) => (id) => {
    const packedProducts = {};
    const shipmentProducts = getters.getItemsByAllFulfillmentShipmentsId(id);
    const fulfillmentPackages = rootGetters['packages/getItemsByFulfillmentId'](id);
    const fulfillmentProducts = state.fulfillmentItems[id] || [];

    Object.values(shipmentProducts).forEach((item) => {
      if (!(item.product_id in packedProducts)) {
        packedProducts[item.product_id] = 0;
      }

      packedProducts[item.product_id] += item.count;
    });

    Object.values(fulfillmentPackages).forEach(({ products }) =>
      products.forEach((item) => {
        if (!(item.product_id in packedProducts)) {
          packedProducts[item.product_id] = 0;
        }

        packedProducts[item.product_id] += item.count;
      })
    );

    return fulfillmentProducts
      .filter((item) => item)
      .map((product) => {
        product = { ...product, product: { ...product.product } };

        if (product.product_id in packedProducts) {
          product.count -= packedProducts[product.product_id];
        }

        if (product.pack_count > product.count) {
          product.pack_count = product.count;
        }

        return product;
      })
      .filter((item) => item.count > 0);
  },
  getItemsByPackageId: (state) => (id) => state.packageItems[id] || [],
  getItemsByShipmentId: (state, getters, rootState, rootGetters) => (id) =>
    rootGetters['packages/getItemsByShipmentId'](id).reduce((result, { id: packageId }) => {
      result.push(...getters.getItemsByPackageId(packageId));

      return result;
    }, [])
};

const getPackSuggestions = async ({ apiUrl, products, packages, zkkey }) => {
  const unit = 'cm';

  const formattedProducts = products.reduce((result, item) => {
    const { width, height, length } = convertDimensions(item.product, unit);

    for (let i = 0; i < item.pack_count; ++i) {
      result.push({
        id: item.id,
        width: width,
        weight: item.product.weight ? convertWeightToKg(item.product.weight, item.product.weight_units) : 0,
        packaged_in_own_box: false,
        length: length,
        description: item.description || 'description',
        depth: height
      });
    }

    return result;
  }, []);
  const formattedPackages = packages.map((item) => ({
    reference: item.name,
    package_id: item.id,
    empty_weight: item.weight,
    max_weight: 1000000,
    inner_width: item.width,
    inner_length: item.length,
    inner_depth: item.height
  }));

  const response = await fetch(`${apiUrl}/pack`, {
    method: 'POST',
    headers: {
      zkkey,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      products: formattedProducts,
      boxes: formattedPackages
    })
  });

  if (response.status !== 200) {
    throw Error(`Request failed with ${response.status}`);
  }

  return await response.json();
};

const actions = {
  async loadOrderProducts(options, orderId) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/products`
      },
      options,
      errorMessage: 'ORDER_PRODUCTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setProducts', response.data));
  },

  async loadFulfillmentProducts(options, { orderId, fulfillmentId }) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/fulfillments_products`
      },
      options,
      errorMessage: 'FULFILLMENT_PRODUCTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) =>
      options.commit('setFulfillmentProducts', { fulfillmentId, value: response.data })
    );
  },

  async loadShipmentProductsByFulfillmentId(options, { orderId, fulfillmentId }) {
    const shipments = options.rootGetters['shipments/getItemsByFulfillmentId'](fulfillmentId);

    const shipmentPackages = shipments.reduce((result, shipment) => {
      return { ...result, [shipment.id]: shipment.shipments_packages };
    }, {});

    await options.commit('packages/massSetShipmentPackages', { value: shipmentPackages }, { root: true });

    await Promise.all(
      shipments.map(async (shipment) => {
        await Promise.all(
          options.rootGetters['packages/getItemsByShipmentId'](shipment.id).map(async ({ id: packageId }) => {
            await options.dispatch(
              'products/loadShipmentPackageProducts',
              {
                orderId,
                fulfillmentId,
                shipmentId: shipment.id,
                packageId
              },
              { root: true }
            );
          })
        );
      })
    );
  },

  async loadShipmentPackageProducts(options, { orderId, fulfillmentId, packageId }) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/shipments_packages/${packageId}/shipments_products`
      },
      options,
      errorMessage: 'PACKAGE_PRODUCTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) =>
      options.commit('setPackageProducts', { packageId, value: response.data })
    );
  },

  async packFulfillmentProduct(options, { packageItem, product, validation }) {
    try {
      options.state.packLoading = true;

      validation = validation || false;

      if (validation) {
        const packedProducts = packageItem.products || [];
        const data = await getPackSuggestions({
          apiUrl: options.rootGetters['auth/carrierServiceApiUrl'],
          zkkey: options.rootGetters['auth/deliveryhubAccountKey'],
          products: [product, ...packedProducts],
          packages: [packageItem]
        });

        if (!data['success']) {
          options.state.error = 'FULFILLMENT_PRODUCT_PACKING_FAIL';
          options.state.packLoading = false;

          return;
        }
      }

      for (let i = 0; i < packageItem.products.length; ++i) {
        if (packageItem.products[i].id === product.id) {
          packageItem.products[i].count += product.pack_count;

          return;
        }
      }

      packageItem.products.push({ ...product, count: product.pack_count });
    } finally {
      options.state.packLoading = false;
    }
  },

  async unpackFulfillmentProduct(options, { packageItem, productIndex }) {
    packageItem.products.splice(productIndex, 1);
  },

  async autoPack(options, { fulfillmentId, products, packages }) {
    const data = await getPackSuggestions({
      apiUrl: options.rootGetters['auth/carrierServiceApiUrl'],
      zkkey: options.rootGetters['auth/deliveryhubAccountKey'],
      products,
      packages
    });

    if (!data['success']) {
      options.state.error = 'FULFILLMENT_AUTO_PACK_FAIL';

      return;
    }

    const packedPackages = data['packed_shipment_boxes'] || [];

    for (let i = 0; i < packedPackages.length; ++i) {
      const item = packedPackages[i];

      const packedProducts = item.items.reduce((result, item) => {
        if (!(item.id in result)) {
          result[item.id] = 0;
        }

        result[item.id]++;

        return result;
      }, {});
      const newFulfillmentPackage = packages.find((p) => p.id === item.box.package_id);

      await options.dispatch(
        'packages/createFulfillmentPackage',
        { fulfillmentId, value: newFulfillmentPackage },
        { root: true }
      );
      const fulfillmentPackages = options.rootGetters['packages/getItemsByFulfillmentId'](fulfillmentId);
      const packageItem = fulfillmentPackages[fulfillmentPackages.length - 1];

      for (let [productId, count] of Object.entries(packedProducts)) {
        productId = parseInt(productId) || productId;
        const product = options.getters.getRawItemByFulfillmentId(fulfillmentId, productId);

        await options.dispatch('packFulfillmentProduct', {
          packageItem,
          product: { ...product, pack_count: count }
        });
      }
    }
  },

  async packAllFulfillmentProducts(options, { fulfillmentId, products, packages, validation, packageItem }) {
    options.state.packAllLoading = true;
    options.state.packLoading = true;
    validation = validation || false;

    if (!validation) {
      for (const product of products) {
        for (let i = 0; i < packageItem.products.length; ++i) {
          if (packageItem.products[i].id === product.id) {
            packageItem.products[i].count += product.pack_count;

            return;
          }
        }

        packageItem.products.push({ ...product, count: product.pack_count });
      }
      options.state.packLoading = false;
      options.state.packAllLoading = false;

      return;
    }

    products.forEach((item) => (item.pack_count = item.count));

    const packedProducts = packages.reduce((result, item) => {
      result.push(...(item.products || []));

      return result;
    }, []);

    const data = await getPackSuggestions({
      apiUrl: options.rootGetters['auth/carrierServiceApiUrl'],
      zkkey: options.rootGetters['auth/deliveryhubAccountKey'],
      products: [...packedProducts, ...products],
      packages
    });

    if (!data['success']) {
      options.state.error = 'FULFILLMENT_PACK_ALL_FAIL';
      options.state.packAllLoading = false;

      return;
    }

    const packedPackages = data['packed_shipment_boxes'];

    for (let i = 0; i < packedPackages.length; ++i) {
      const item = packedPackages[i];
      const packageItem = options.rootGetters['packages/getItemsByFulfillmentId'](fulfillmentId)[item.box.package_id];

      const packedProducts = item.items.reduce((result, item) => {
        if (!(item.id in result)) {
          result[item.id] = 0;
        }

        result[item.id]++;

        return result;
      }, {});

      // we need to reset product list here because already packed products were included to the validation request
      packageItem.products = [];

      for (let [productId, count] of Object.entries(packedProducts)) {
        productId = parseInt(productId) || productId;
        const product = options.getters.getRawItemByFulfillmentId(fulfillmentId, productId);

        await options.dispatch('packFulfillmentProduct', {
          packageItem,
          product: { ...product, pack_count: count }
        });
      }
    }

    options.state.packAllLoading = false;
  },

  async unpackAllFulfillmentProducts(options, { fulfillmentId }) {
    const packages = options.rootGetters['packages/getItemsByFulfillmentId'](fulfillmentId);

    for (let i = 0; i < packages.length; i++) {
      packages[i].products = [];
    }
  },

  async updateProductDetails(options, { fulfillmentId, productId, data }) {
    const updatedData = {
      description: data.description,
      harmonized_code: data.harmonized_code,
      country_of_origin: data.country_of_origin,
      price: data.price
    };

    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/products/${productId}`,
        data: updatedData
      },
      options,
      errorMessage: 'FULFILLMENT_PRODUCT_UPDATE_FAIL'
    };

    await executeRequest(request, () => {
      options.commit('updateProductById', { fulfillmentId, productId, value: { product: updatedData } });
    });
  }
};

const mutations = {
  setProducts(state, value) {
    state.items = value;
  },

  setPackageProducts(state, { packageId, value }) {
    state.packageItems[packageId] = value;
  },

  setFulfillmentProducts(state, { fulfillmentId, value }) {
    state.fulfillmentItems[fulfillmentId] = value.map((item) => {
      item.pack_count = item.count;

      return item;
    });
  },

  updateProductById(state, { fulfillmentId, productId, value }) {
    const products = state.fulfillmentItems[fulfillmentId] || [];

    for (let i = 0; i < products.length; ++i) {
      if (products[i].product.id === productId) {
        if ('product' in value) {
          products[i].product = { ...products[i].product, ...value.product };
          delete value.product;
        }

        products[i] = { ...products[i], ...value };
      }
    }
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
