<template>
  <div class="documents">
    <b-button
      type="blank"
      :disabled="isDisableForCustomService"
      @click="toggle">
      <div class="flex items-center">
        <slot name="label" />
      </div>
    </b-button>

    <modal
      :scrollable="shippingView"
      data-id="documents"
      :class="state.localValue.isHidden && 'hidden'"
      :value="state.localValue.isShown"
      @close="close">
      <template
        v-if="!tabbedView"
        #title>
        <div class="px-6 py-4 font-semibold shadow-bottom">
          {{ t('SHIPPING_DOCUMENTS_MODAL.TITLE') }}
        </div>
      </template>

      <template #body>
        <b-tabs
          v-if="tabbedView"
          ref="tabsRef">
          <b-tab :title="t('PROOF_OF_DELIVERY_MODAL.TITLE')">
            <proof-of-delivery :documents="documents" />
          </b-tab>
          <b-tab :title="t('SHIPPING_DOCUMENTS_MODAL.TITLE')">
            <shipping-documents-table
              :shipping-view="shippingView"
              :print="print"
              :documents-total="documentsTotal"
              :table-data="tableData" />
          </b-tab>
        </b-tabs>
        <shipping-documents-table
          v-else
          :shipping-view="shippingView"
          :print="print"
          :documents-total="documentsTotal"
          :table-data="tableData" />
      </template>

      <template #footer>
        <!--
          This footer applied only to shipping documents component, not POD. POD has own footer in his component.
          Shipping documents body should be scrollable, so it is done in this way.
        -->
        <b-footer
          v-if="!tabbedView || tabsRef?.activeHash === '#shipping-documents'"
          :print-all="printAll"
          :documents-total="documentsTotal"
          :is-custom-service="isCustomService"
          :print-indication="state.localValue.printIndication.text" />
      </template>
    </modal>
  </div>
  <confirm-modal
    :is-shown="printData.showWarning"
    :use-ignore-button="false"
    @close="closeModal"
    @accept="goToPrintSettings">
    <template #title>
      {{ t('PRINT_CONFIRM_MODAL.TITLE') }}
    </template>
    <template #description>
      {{ printData.warningText }}
    </template>
    <template #accept-label>
      {{ t('PRINT_CONFIRM_MODAL.PRINT_BUTTON') }}
    </template>
  </confirm-modal>
  <image-modal
    :src="store.getters['imageModal/src']"
    :mime-type="store.getters['imageModal/mimeType']"
    :open="store.getters['imageModal/show']"
    @close="closeImageModal"
    @show="showImageModal"></image-modal>
</template>

<script>
export default {
  name: 'ModalShippingDocuments'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, nextTick, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import { BTab, BTabs } from '@/components/atoms/Tabs';
import ConfirmModal from '@/components/organisms/ConfirmModal';
import ImageModal from '@/components/organisms/ImageModal';
import Modal from '@/components/organisms/Modal';
import { usePrintingModal } from '@/composables';

import BButton from '../../atoms/Button';
import BFooter from './Footer.vue';
import ProofOfDelivery from './ProofOfDelivery.vue';
import ShippingDocumentsTable from './ShippingDocumentsTable.vue';

const { t } = useTranslation();

const { printData, goToPrintSettings, checkIfPrinterReady, handleWarningModal, closeModal } = usePrintingModal();
const store = useStore();

const props = defineProps({
  documents: {
    type: Array,
    required: true
  },
  tabbedView: {
    type: Boolean,
    default: false
  },
  shippingView: {
    type: Boolean,
    default: false
  }
});

const tabsRef = ref(null);

const localValueFactory = () => ({
  isShown: false,
  isHidden: false,

  printIndication: {
    text: '',
    timeout: null
  },

  isPrinterWarningShown: false
});

const state = reactive({ localValue: localValueFactory() });

const emit = defineEmits(['printAll', 'print']);
const isCustomService = computed(() => store.getters['auth/isCustomService']);

const isDisableForCustomService = computed(
  () => isCustomService.value && store.getters['auth/featureFlags'].cc_printing_disabled
);

const addPrintIndication = async (label) => {
  state.localValue.printIndication.text = null;
  await nextTick();
  state.localValue.printIndication.text = `${label} ${t('SHIPPING_DOCUMENTS_MODAL.PRINT_INDICATION_SUFFIX')}`;

  clearTimeout(state.localValue.printIndication.timeout);

  state.localValue.printIndication.timeout = setTimeout(() => {
    state.localValue.printIndication.text = null;
  }, 3000);
};

const packingSlipsPrintingEnabled = computed(() => store.getters['auth/isPackingSlipsPrintingEnabled']);

const tableData = computed(() => {
  const result = [];
  const shipments = props.documents || [];

  for (let i = 0; i < shipments.length; ++i) {
    const item = shipments[i];

    const documents = parseShipmentDocuments(item);

    result.push({ tracking_number: `${t('SHIPPING_DOCUMENTS_MODAL.SHIPMENT_LABEL')} #${item.trackingNumber}` });

    const featureFlags = store.getters['auth/featureFlags'];

    if (featureFlags.deliveryhub_print_modal_packingslip === true && packingSlipsPrintingEnabled.value) {
      result.push({
        documents: [
          {
            label: t('SHIPPING_DOCUMENTS_MODAL.PACKING_SLIP'),
            field: 'packing_slip',
            value: {
              shipmentId: item.id
            }
          }
        ]
      });
    }

    if (documents.length > 2) {
      result.push({ documents: [documents[0], documents[1]] });
      result.push({ documents: documents.slice(2) });
    } else if (documents.length) {
      result.push({ documents });
    }

    for (let j = 0; j < item.packages.length; ++j) {
      result.push({
        tracking_number: `${t('SHIPPING_DOCUMENTS_MODAL.PACKAGE_LABEL')} #${item.packages[j].trackingNumber}`,
        documents: parsePackageDocuments(item.packages[j])
      });
    }
  }

  return result;
});

const documentsTotal = computed(() =>
  tableData.value.reduce((result, item) => result + (item.documents || []).length, 0)
);

const toggle = () => (state.localValue.isShown = !state.localValue.isShown);
const print = async (item) => {
  const data = { type: item.field };
  const shipmentId = item.value.packages ? item.value.id : item.value.shipmentId;

  if (!item.value.packages) {
    data['shipments_package_id'] = item.value.id;
  }

  if (!checkIfPrinterReady(item.field)) {
    handleWarningModal(item.field);

    return;
  }

  await addPrintIndication(item.label);
  emit('print', { shipmentId, data });
};
const printAll = async () => {
  const documents = tableData.value.flatMap((row) => row.documents ?? []);
  const uniqueFields = [...new Set(documents.map((d) => d.field))];

  for (const field of uniqueFields) {
    if (!checkIfPrinterReady(field)) {
      handleWarningModal(field);

      return;
    }
  }

  await addPrintIndication(t('SHIPPING_DOCUMENTS_MODAL.PRINT_ALL_LABEL'));

  emit('printAll');
};
const close = () => (state.localValue = localValueFactory());

const parseShipmentDocuments = (value) => {
  const result = [
    { label: t('SHIPPING_DOCUMENTS_MODAL.COMMERCIAL_INVOICE_LABEL'), field: 'commercial_invoice', value },
    { label: t('SHIPPING_DOCUMENTS_MODAL.RETURN_LABEL'), field: 'return_label', value },
    { label: t('SHIPPING_DOCUMENTS_MODAL.SIGNATURE_LABEL'), field: 'signature', value },
    { label: t('SHIPPING_DOCUMENTS_MODAL.PICTURE_LABEL'), field: 'picture', value }
  ];

  return result.filter((item) => !!item.value[item.field]);
};

const parsePackageDocuments = (value) => {
  const result = [
    { label: t('SHIPPING_DOCUMENTS_MODAL.SHIPPING_LABEL'), field: 'label', value },
    { label: t('SHIPPING_DOCUMENTS_MODAL.RETURN_LABEL'), field: 'return_label', value }
  ];

  return result.filter((item) => !!item.value[item.field]);
};

const showImageModal = () => (state.localValue.isHidden = true);

const closeImageModal = () => {
  store.dispatch('imageModal/close');
  state.localValue.isHidden = false;
};
</script>

<style lang="scss">
.documents {
  .modal-content {
    width: 45% !important;
  }
}
</style>
