<template>
  <b-alert
    v-if="error && props.edit"
    class="z-10 mb-8 absolute inset-x-0 top-0"
    type="danger"
    :close="true"
    @close="clearError">
    {{ t(error) }}
  </b-alert>
  <div class="flex flex-col h-full">
    <div
      v-if="props.edit"
      class="flex-shrink-0 flex justify-between p-4">
      <h1 class="text-lg text-gray-800 font-medium">Edit {{ t('SHIPMENT_DETAILS.ITEMS') }}</h1>
      <button
        type="button"
        @click="onClose">
        <b-icon
          class="text-m text-gray-800"
          :icon-name="BringgFontIcons.Close" />
      </button>
    </div>

    <div
      class="flex-1 relative p-4 bg-white border-gray-200"
      :class="[edit ? 'overflow-auto' : 'max-h-88 shadow']">
      <div class="text-xs text-gray-800 flex items-center justify-between">
        <span>{{ title }}</span>
        <b-icon
          v-if="isEditable"
          :icon-name="BringgFontIcons.Pencil"
          class="text-base text-blue-400 cursor-pointer"
          @click="toggleEdit" />
      </div>
      <div class="mt-4 rounded-t-lg">
        <div
          class="relative"
          :class="{
            'h-48 max-h-64 overflow-y-auto': !edit
          }">
          <table class="table-auto w-full text-sm">
            <thead class="sticky-0">
              <tr class="bg-gray-100 border-b">
                <th class="text-sm text-gray-800 font-normal text-left px-2 py-1.5">
                  {{ t('SHIPMENT_DETAILS.ITEMS') }}
                </th>
                <th class="text-sm text-gray-800 font-normal text-left px-2 py-1.5 w-20">
                  <span class="px-2 border-l border-gray-400">
                    {{ t('SHIPMENT_DETAILS.QUANTITY') }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(packageItem, packageIndex) in edit ? copiedPackages.localValue : packages"
                :key="packageIndex">
                <tr class="bg-gray-50 border-b">
                  <td class="py-1 px-2 flex align-middle">
                    <span class="pt-0.5 text-gray-700">
                      {{ t('SHIPMENT_DETAILS.PACKAGE') }} #{{ packageIndex + 1 }}
                    </span>
                    <modal-package-details :value="packageItem">
                      <template #button>
                        <b-icon
                          :icon-name="BringgFontIcons.Info"
                          class="ml-2 text-2xl text-blue-400" />
                      </template>
                    </modal-package-details>
                  </td>
                  <td></td>
                </tr>
                <tr
                  v-for="(item, productIndex) in packageItem?.shipments_products"
                  :key="productIndex"
                  class="p-2 border-b">
                  <td class="flex items-center">
                    <div class="flex-1 flex justify-between items-center px-2">
                      <div>
                        <div>{{ item.product.name }}</div>
                        <div
                          v-if="item?.product.sku"
                          class="text-gray-600">
                          {{ item.product.sku }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-4">
                    <template v-if="!edit">
                      {{ item.count }}
                    </template>
                    <template v-else>
                      <b-counter
                        :value="item.count"
                        :name="`packages[${packageIndex}].shipments_products[${productIndex}].count`"
                        :min="0"
                        :max="item.count" />
                    </template>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div
            v-if="!edit"
            class="sticky bottom-0 inset-x-0 blur h-14"></div>
        </div>
      </div>
    </div>
    <footer
      v-if="edit"
      class="sticky bottom-0 px-8 py-4 border-t border-gray-300 bg-white">
      <div class="w-fit flex justify-center items-center gap-1 px-2 py-1 bg-blue-50 rounded">
        <b-icon
          :icon-name="BringgFontIcons.Info"
          class="text-2xl text-gray-500" />
        <div class="text-xs text-gray-700">{{ t('SHIPMENT_DETAILS.EDITS_INVENTORY_AFFECT_FULFILLMENT') }}</div>
      </div>
      <div class="mt-8 flex justify-between">
        <b-button
          :disabled="!meta?.dirty"
          class="border-none bg-transparent text-blue-400"
          @click="resetForm">
          {{ t('DISCARD_BUTTON') }}
        </b-button>
        <b-button
          :disabled="!meta?.dirty || !meta?.valid"
          :loading="state.isSaving"
          @click="onSave">
          {{ t('SAVE_AND_CONTINUE') }}
        </b-button>
      </div>
    </footer>
  </div>
  <modal-unsaved-changes
    v-if="props.edit"
    data-id="items-unsaved-changes"
    :is-dirty="meta?.dirty"
    :is-valid="meta?.valid"
    :is-saving="state.isSaving"
    :open="state.isOpenUnsavedChanges"
    @close="onDiscard"
    @discard="onDiscard"
    @save="onSave" />
  <confirm-modal
    v-if="props.edit"
    data-id="items-dialog-select-carrier-modal"
    :is-shown="state.isOpenDialogSelectCarrier"
    class="fixed w-content"
    @close="closeDialogCarrierModal"
    @accept="submitAndOpenSetCarrier"
    @reject="submitAndOpenPartiallyFulfilledModal">
    <template #title>{{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.FINALIZE_ITEM_CHANGES') }}</template>
    <template #description>
      <div class="mt-4">
        {{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.DESCRIPTION_1') }}
        <span class="font-semibold">{{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.SAVE_CHANGES_ONLY') }}</span>
        {{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.DESCRIPTION_2') }}
        <span class="font-semibold">{{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.REASSIGN_CARRIER') }}</span>
        {{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.DESCRIPTION_3') }}
      </div>
      <div class="text-xs text-gray-700 mt-4">
        {{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.FEES_MAY_APPLY') }}
      </div>
    </template>
    <template #reject-label>{{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.SAVE_CHANGES_ONLY') }}</template>
    <template #accept-label>{{ t('SHIPMENT_DETAILS.DIALOG_CARRIER_MODAL.REASSIGN_CARRIER') }}</template>
  </confirm-modal>
  <confirm-modal
    v-if="props.edit"
    data-id="items-partially-fulfilled-modal"
    :is-shown="state.partiallyFulfilledModalShown"
    class="fixed w-content"
    @close="closePartiallyFulfilledModal"
    @accept="redirectToFulfillment"
    @reject="emit('close')">
    <template #title>
      {{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.TITLE') }}
    </template>
    <template #description>
      <div
        v-if="shipmentOnRoad"
        class="flex">
        <div class="mr-2 ml-1">
          <b-icon
            icon-name="selected"
            class="text-base text-green-500" />
        </div>
        <div>{{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.CHANGES_SAVED_LOCALLY') }}</div>
      </div>
      <div class="mt-5">{{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.DESCRIPTION') }}</div>
    </template>
    <template #accept-label>
      {{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.ACCEPT') }}
    </template>
    <template #reject-label>
      {{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.DISMISS') }}
    </template>
  </confirm-modal>
</template>

<script>
export default {
  name: 'ItemsTable'
};
</script>
<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { orderBy } from 'lodash';
import { useForm } from 'vee-validate';
import { computed, defineEmits, defineProps, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BCounter from '@/components/atoms/BCounter';
import BButton from '@/components/atoms/Button';
import BIcon from '@/components/atoms/Icon/index.vue';
import ConfirmModal from '@/components/organisms/ConfirmModal';
import ModalPackageDetails from '@/components/organisms/ModalPackageDetails/index.vue';
import ModalUnsavedChanges from '@/components/organisms/ModalUnsavedChanges/index.vue';
import { ShipmentStatus } from '@/enums';

import BAlert from '../../atoms/Alert';

const { t } = useTranslation();
const store = useStore();
const router = useRouter();
const props = defineProps({
  shipmentDetails: {
    type: Object,
    default: () => new Object()
  },
  edit: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['toggle-drawer', 'close', 'open-carrier-modal']);
const disableShipmentProductsEdit = store.getters['auth/accountSettings'].disable_shipment_products_edit;
const isCustomService = computed(() => store.getters['auth/isCustomService']);

const isEditable = computed(() => {
  return !props.edit && !disableShipmentProductsEdit && !isCustomService.value;
});

const shipmentStatus = computed(() => props.shipmentDetails.status);
const shipmentOnRoad = computed(() =>
  [ShipmentStatus.ReadyToShip, ShipmentStatus.New].every((status) => status !== shipmentStatus.value)
);
const packages = computed(() => orderBy(props.shipmentDetails.shipments_packages, 'id'));
const title = computed(() => {
  const totalItems = packages.value.reduce((acc, pkg) => {
    return (
      acc +
      pkg.shipments_products.reduce((acc, product) => {
        return acc + product.count;
      }, 0)
    );
  }, 0);

  const numPackages = packages.value.length;

  return `${totalItems} ${t('SHIPMENT_DETAILS.ITEMS')}, ${numPackages} ${t('SHIPMENT_DETAILS.PACKAGES')}`;
});

const error = computed(() => store.state.shipments.error);

const copiedPackages = reactive({
  localValue: JSON.parse(JSON.stringify(packages.value))
});

const state = reactive({
  isSaving: false,
  isOpenUnsavedChanges: false,
  isOpenDialogSelectCarrier: false,
  partiallyFulfilledModalShown: false
});

const createForm = (data) => {
  return props.edit ? useForm(data) : {};
};

const {
  values,
  resetForm,
  meta,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleSubmit = () => {}
} = createForm({
  initialValues: { packages: packages.value }
});

const onClose = () => {
  if (props.edit && meta.value.dirty && meta.value.valid) {
    state.isOpenUnsavedChanges = true;
  } else {
    emit('close');
  }
};

const closePartiallyFulfilledModal = () => {
  state.partiallyFulfilledModalShown = false;
  emit('close');
};

const toggleEdit = () => {
  emit('toggle-drawer');
};

const onSave = async () => {
  store.dispatch('shippingAccounts/resetShippingAccountsByRule');

  if (shipmentStatus.value === ShipmentStatus.ReadyToShip) {
    state.isOpenDialogSelectCarrier = true;

    return;
  }

  if (shipmentOnRoad.value) {
    await submitAndOpenPartiallyFulfilledModal();

    return;
  }

  await submitAndOpenSetCarrier();
};

const submitAndOpenSetCarrier = async () => {
  const success = await onSubmit();

  if (!success) {
    return;
  }

  emit('open-carrier-modal');
  emit('close');
};

const submitAndOpenPartiallyFulfilledModal = async () => {
  const success = await onSubmit();

  if (!success) {
    return;
  }

  state.partiallyFulfilledModalShown = true;
};

const onSubmit = handleSubmit(async (values, actions) => {
  try {
    state.isSaving = true;

    const { order_id, fulfillment_id, shipment_id } = values.packages[0];

    const data = {
      source_id: props.shipmentDetails?.source_id,
      packages: values.packages
        .map((pck) => ({
          products: pck.shipments_products
            .map((prd) => ({
              ...prd.product,
              count: prd.count
            }))
            .filter((prd) => prd.count !== 0),
          name: pck.package.name,
          id: pck.id,
          package_id: pck.package_id,
          order_id: pck.order_id,
          fulfillment_id: pck.fulfillment_id,
          shipment_id: pck.shipment_id,
          package_weight: pck.package_weight,
          weight_units: pck.weight_units
        }))
        .filter((pkg) => !!pkg.products.length)
    };

    await store.dispatch('shipments/updateShipment', {
      orderId: order_id,
      fulfillmentId: fulfillment_id,
      shipmentId: shipment_id,
      data: data
    });

    if (!error.value) {
      actions.resetForm({
        values
      });
      state.isSaving = false;
      state.isOpenUnsavedChanges = false;
    } else {
      actions.resetForm();
    }

    return !error.value;
  } finally {
    state.isSaving = false;
    state.isOpenUnsavedChanges = false;
  }
});

const onDiscard = () => {
  resetForm();
  state.isOpenUnsavedChanges = false;
  emit('close');
};

const clearError = () => {
  store.commit('shipments/clearError');
};

const closeDialogCarrierModal = () => {
  state.isOpenDialogSelectCarrier = false;
};

const redirectToFulfillment = () => {
  const { order_id, fulfillment_id } = values.packages[0];

  router.push({
    name: 'fulfillment-details',
    params: { orderId: order_id, id: fulfillment_id }
  });
};
</script>

<style scoped lang="scss">
.w-fit {
  width: fit-content;
}

.blur {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
}
</style>
