<template>
  <b-modal
    :value="isShown"
    @close="onClose">
    <template #title>
      <h3 class="px-6 py-4 text-base leading-6 font-medium text-gray-900">{{ t('COPY_RULES_MODAL.TITLE') }}</h3>
      <b-divider />
    </template>
    <template #body>
      <div class="px-6">
        <div class="text-gray-700 text-sm mb-5">
          <span>{{ t('COPY_RULES_MODAL.DESCRIPTION_1') }}</span>
          <strong>{{ t('COPY_RULES_MODAL.DESCRIPTION_2') }}</strong>
          <span>{{ t('COPY_RULES_MODAL.DESCRIPTION_3') }}</span>
          <strong>{{ t('COPY_RULES_MODAL.DESCRIPTION_4') }}</strong>
        </div>

        <div class="mt-3">
          <div
            v-for="rule in validRules"
            :key="rule.id"
            class="mb-2">
            <b-checkbox v-model:value="form.toCopy[rule.id]">
              <template #label>
                <span class="text-gray-800 text-sm">{{ rule.name }}</span>
              </template>
            </b-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="mt-5 md:mt-4 md:flex md:flex-row-reverse px-6 py-2 shadow-top">
        <b-button
          class="px-6"
          :disabled="isSaveDisabled || isSaving"
          @click="onSave">
          <b-icon
            v-if="isSaving"
            class="text-lg"
            :icon-name="BringgFontIcons.Loader"
            spin />

          <span v-else>
            {{ t('SAVE_BUTTON') }}
          </span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalCopyRules'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { some } from 'lodash';
import { computed, defineEmits, reactive, watch } from 'vue';

import { ALL_LOCATIONS, RULE_TYPE, TRIGGERS } from '@/components/organisms/Rule/constants';

import BButton from '../../atoms/Button/index.vue';
import BCheckbox from '../../atoms/Checkbox/index.vue';
import BDivider from '../../atoms/Divider/index.vue';
import BIcon from '../../atoms/Icon/index.vue';
import BModal from '../../organisms/Modal/index.vue';

const { t } = useTranslation();

const props = defineProps({
  isShown: {
    type: Boolean,
    required: true
  },
  isSaving: {
    type: Boolean,
    required: true
  },
  rules: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['save', 'close']);

const form = reactive({
  toCopy: {}
});

const isSaveDisabled = computed(() => !some(form.toCopy, Boolean));

const validRules = computed(() =>
  props.rules.filter((rule) => {
    const isValidLocations = rule.locations.length === 1 && rule.locations[0] === ALL_LOCATIONS;
    const isValidAttributes = !!rule.attributes.filter(({ path }) => TRIGGERS[path].ruleType !== RULE_TYPE.SHIPPING);

    return isValidLocations && isValidAttributes;
  })
);

const onClose = () => {
  emit('close');
};

const onSave = () => {
  const serialized = props.rules.filter((rule) => form.toCopy[rule.id]);

  emit('save', serialized);
};

watch(
  () => props.isShown,
  (isShown) => {
    if (!isShown) {
      form.toCopy = {};
    }
  }
);
</script>
