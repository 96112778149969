<template>
  <div>
    <label
      v-if="$slots.label"
      :for="id"
      class="block text-sm font-medium text-gray-700">
      <slot name="label" />
    </label>
    <div class="mt-1 relative shadow-sm">
      <div
        v-if="$slots['start-icon']"
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
        <slot name="start-icon" />
      </div>
      <datepicker
        :id="id"
        :model-value="value"
        :class="classes"
        :disabled="disabled"
        :placeholder="placeholder"
        :lower-limit="lowerLimit"
        :upper-limit="upperLimit"
        class="focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 shadow-sm block w-full sm:text-sm rounded-md disabled:cursor-not-allowed"
        @update:model-value="emit('update:value', $event)" />
      <div
        v-if="$slots['end-icon']"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none z-10">
        <slot name="end-icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DatePicker'
};
</script>

<script setup>
import { computed, defineEmits, defineProps, useSlots } from 'vue';
import Datepicker from 'vue3-datepicker';

const slots = useSlots();

defineProps({
  value: {
    type: Date,
    default: null
  },
  id: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  lowerLimit: {
    type: Date,
    default: null
  },
  upperLimit: {
    type: Date,
    default: null
  }
});

const emit = defineEmits(['update:value']);

const classes = computed(() => ({
  'pl-10': slots['start-icon'],
  'pr-10': slots['end-icon']
}));
</script>

<style>
.v3dp__popout {
  z-index: 100 !important;
}
</style>
