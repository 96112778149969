import axios from 'axios';
import { t } from 'i18next';
import { truncate } from 'lodash';

import {
  calculateProductsTotalWeight,
  convertDimensions,
  convertWeightToKg,
  executeRequest,
  getCarrierNameByShippingAccount,
  withLoading
} from '../../utils';

const state = {
  rawItems: [],
  list: new Map(),
  rates: [],
  loading: false,
  ratesLoading: false,
  error: '',
  shippingLocations: [],
  shippingAccountsByRule: null,
  ruleName: '',
  pudoLocations: [],
  pudoError: ''
};

const getters = {
  items(state, getters, rootState, rootGetters) {
    if (rootState.carriers.loading) {
      return [];
    }

    const result = state.rawItems
      .reduce((result, shippingAccount) => {
        const carrier = shippingAccount.shipping_account.carrier;
        let masterAccount = result.find((account) => account.carrier === carrier);

        if (!masterAccount) {
          masterAccount = {
            carrier,
            children: [],
            top: true
          };
          result.push(masterAccount);
        }

        const shippingLocations = state.shippingLocations.filter(
          (locationsAccount) => locationsAccount.shipping_account_id === shippingAccount.shipping_account.id
        );

        if (!shippingLocations.length) {
          masterAccount.children.push({ ...shippingAccount.shipping_account });

          return result;
        }

        masterAccount.children = [
          ...masterAccount.children,
          ...shippingLocations.map((shippingLocation) => ({
            ...shippingAccount.shipping_account,
            shipping_location_id: shippingLocation.shipping_location_id,
            shipping_location_name: rootGetters['shippingLocations/items'].find(
              (location) => location.id === shippingLocation.shipping_location_id
            )?.name
          }))
        ];

        return result;
      }, [])
      .map((shippingAccount) => {
        if (shippingAccount.children.length === 1) {
          return { ...shippingAccount, ...shippingAccount.children[0], children: undefined };
        }

        return shippingAccount;
      });

    const carriersNames = rootGetters['carriers/carrierNames'];

    return result.map((item) => {
      let children = item.children;

      if (children) {
        children = item.children.map((child) => ({
          ...child,
          carrier_name: carriersNames[child.carrier],
          carrier_logo: `https://cdn.zenkraft.com/static/images/carriers/${child.carrier}.png`
        }));
      }

      return {
        ...item,
        carrier_name: carriersNames[item.carrier],
        carrier_logo: `https://cdn.zenkraft.com/static/images/carriers/${item.carrier}.png`,
        children
      };
    });
  },
  getShippingAccountById: (state, getters, rootState, rootGetters) => (id) => {
    let result = {};
    const carriersNames = rootGetters['carriers/carrierNames'];

    for (let i = 0; i < state.rawItems.length; ++i) {
      if (state.rawItems[i].shipping_account.id !== id) {
        continue;
      }
      result = state.rawItems[i].shipping_account;
      result.location_id = state.shippingLocations
        .filter((item) => item.shipping_account_id === id)
        .map((item) => item.shipping_location_id);
      result.carrier_name = carriersNames[result.carrier];
      result.carrier_logo = `https://cdn.zenkraft.com/static/images/carriers/${result.carrier}.png`;
    }

    return result;
  },
  carriers(state, getters) {
    return getters.items.map((item) => (item.children ? item.children : [item])).flat();
  },
  getCarrierNameByShippingAccountId: (state, getters, rootState, rootGetters) => (shipment_account_id) =>
    getCarrierNameByShippingAccount(
      getters.getShippingAccountById(shipment_account_id),
      rootGetters['carriers/carrierNames']
    )
};

async function getShippingAccounts(apiUrl, zkkey) {
  const response = await fetch(`${apiUrl}/shippingaccount`, {
    headers: { zkkey }
  });

  if (response.status !== 200) {
    throw Error(`Request failed with ${response.status}`);
  }

  return await response.json();
}

const actions = {
  async loadShippingLocationsShippingAccounts(options) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/shipping_locations/shipping_accounts`
      },
      options,
      errorMessage: 'SHIPPING_ACCOUNTS_LOCATIONS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setShippingLocations', response.data));
  },

  async loadShippingAccounts(options) {
    const dependencies = [
      ['loadShippingLocationsShippingAccounts'],
      ['shippingLocations/loadShippingLocations', null, { root: true }]
    ];

    for (let i = 0; i < dependencies.length; ++i) {
      await options.dispatch(...dependencies[i]);

      if (options.state.error || options.rootState.shippingLocations.error) {
        return;
      }
    }

    await withLoading(options.state, async () => {
      try {
        // TODO https://bringg.atlassian.net/browse/BRNGG-17567
        const shippingAccounts = await getShippingAccounts(
          options.rootGetters['auth/carrierServiceApiUrl'],
          options.rootGetters['auth/deliveryhubAccountKey']
        );

        options.commit('setShippingAccounts', shippingAccounts);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(error);
        }

        options.state.error = 'SHIPPING_ACCOUNTS_LOADING_FAIL';
      }
    });
  },

  async loadShippingAccountsByRule(options, { orderId, fulfillmentId, packages, labelType }) {
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/run_shipping_rules`,
        data: {
          shipments_packages: packages,
          label_type: labelType
        }
      },
      options,
      errorMessage: 'SHIPPING_ACCOUNTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setShippingAccountsByRule', response.data.services);
      options.commit('setRuleName', response.data.name);
    });
  },

  async loadShippingAccountById({ state, rootGetters }, shippingAccountId) {
    const url = `${rootGetters['auth/carrierServiceApiUrl']}/shippingaccount/${shippingAccountId}`;

    const response = await fetch(url, { headers: { zkkey: rootGetters['auth/deliveryhubAccountKey'] } });

    if (response.status !== 200) {
      state.error = 'SHIPPING_ACCOUNT_ITEM_LOADING_FAIL';

      return {};
    }

    const result = await response.json();

    return result.shipping_account;
  },

  async loadShippingRates(options, update) {
    options.state.updated = 0;

    const result = [];
    const weightUnit = 'kg';
    const dimUnit = 'cm';

    const clearLoading = (items) => items.forEach((item) => (item['loading'] = false));

    const processLoading = (items, state) => {
      clearLoading(items);

      state.updated = state.updated + 1;
    };

    const getRawRates = async (body, result, state) => {
      let response;

      try {
        response = await fetch(`${options.rootGetters['auth/carrierServiceApiUrl']}/rate`, {
          method: 'POST',
          headers: {
            zkkey: options.rootGetters['auth/deliveryhubAccountKey'],
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
      } catch {
        processLoading(result.children, state);

        return;
      }

      const data = await response.json();

      if (response.status === 400 && data.error.code === 'carrier_exception') {
        result.children.forEach((item) => {
          item['unavailable'] = true;
          item['error'] = `${t('CARRIER_ASSIGN_MODAL.SERVICE_NOT_AVAILABLE')} (${data.error.message})`;
        });
      }

      if (response.status !== 200) {
        processLoading(result.children, state);

        return;
      }

      if ('error' in data) {
        processLoading(result.children, state);

        return;
      }

      const rates = data.rates || [];

      const dataRatesMap = Object.fromEntries(rates.map((item) => [item.service_type, item]));

      // Only available services are included in the API response. Therefore, we should mark other services as 'unavailable'.
      result.children.forEach(async (child) => {
        const rateItem = dataRatesMap[child.type];

        if (rateItem) {
          child['unavailable'] = false;
          child['cost'] = rateItem.total_cost;
          child['date'] = rateItem.estimated_date || '';
        } else {
          child['unavailable'] = true;
          child['error'] = t('CARRIER_ASSIGN_MODAL.SERVICE_NOT_AVAILABLE');
        }

        child['loading'] = false;
      });

      state.updated = state.updated + 1;
    };

    const handleShippingAccount = (account) => {
      const accountResults = {
        name: account.name,
        carrier_name: account.carrier_name,
        carrier: account.carrier,
        country: account.country,
        children: []
      };
      let services = options.rootGetters['carriers/serviceTypes'][account.carrier];
      const body = {
        shipment: {
          carrier: account.carrier,
          shipping_account: account.id,
          debug: true,
          test: account.test,
          type: 'outbound',
          special_services: [],
          packaging: 'your_packaging',
          dim_units: dimUnit.toUpperCase(),
          weight_units: weightUnit.toUpperCase(),
          currency: 'USD',
          sender: {
            name: 'Name',
            company: truncateCompanyName(senderAddress.company) || '',
            street1: senderAddress.street1,
            street2: senderAddress.street2,
            street3: senderAddress.street3,
            city: senderAddress.city,
            state: senderAddress.state,
            postal_code: senderAddress.postal_code,
            country: senderAddress.country,
            phone: senderAddress.phone,
            email: senderAddress.email
          },
          recipient: {
            name: 'Name',
            company: truncateCompanyName(recipientAddress.company) || '',
            street1: recipientAddress.street1,
            street2: recipientAddress.street2,
            street3: recipientAddress.street3,
            city: recipientAddress.city,
            state: recipientAddress.state,
            postal_code: recipientAddress.postal_code,
            country: recipientAddress.country,
            phone: senderAddress.phone,
            email: senderAddress.email
          },
          packages: packages
        }
      };

      const selectedServicesTypes = account.extras?.selected_services ?? null;

      accountResults.children.push(
        ...services.map((item) => ({
          id: account.id,
          name: item.label,
          type: item.value,
          carrier: account.carrier,
          country: account.country,
          loading: true,
          isPredefined: !Array.isArray(selectedServicesTypes) || selectedServicesTypes.includes(item.value)
        }))
      );

      getRawRates(body, accountResults, options.state);

      if (!accountResults.children.length) {
        return;
      }

      result.push(accountResults);
    };

    options.state.ratesLoading = true;

    const fulfillmentDetails = options.rootState.fulfillments.itemDetails;
    let shippingAccounts = options.getters.items;

    if (fulfillmentDetails.sender_location_id) {
      shippingAccounts = shippingAccounts
        .map((account) => {
          const location_id = fulfillmentDetails.sender_location_id;

          if (account.id && account.shipping_location_id === location_id) {
            return account;
          }

          const shallowAccount = { ...account }; // Create a shallow copy of the object

          if (shallowAccount.children) {
            shallowAccount.children = shallowAccount.children.filter(
              (child) => child.shipping_location_id === location_id
            ); // Filter the children array
          }

          return shallowAccount;
        })
        .filter(
          (account) =>
            account.shipping_location_id === fulfillmentDetails.sender_location_id ||
            (account.children && account.children.length > 0)
        );

      if (!shippingAccounts.length) {
        shippingAccounts = options.getters.items;
      }
    }

    const getPackages = async () => {
      // in a case of update carrier we have packages in shipmentDetails but still have no package details
      if (update) {
        await options.dispatch('packages/loadPackages', null, { root: true });

        const packagesCatalog = options.rootGetters['packages/items'];

        const packages = options.rootState.shipments.shipmentDetails.shipments_packages.filter(
          (item) => item.shipments_products.length
        );

        return packages.map((item) => {
          const packageBox = packagesCatalog.find(({ id }) => id === item.package_id);

          return {
            ...item,
            ...packageBox,
            products: item.shipments_products
          };
        });
      }

      return options.rootGetters['packages/getItemsByFulfillmentId'](fulfillmentDetails.id).filter(
        (item) => item.children.length
      );
    };

    const packagesToMap = await getPackages();

    if (!packagesToMap.length) {
      return [];
    }

    const senderAddress = fulfillmentDetails.sender_address || {};
    const recipientAddress = fulfillmentDetails.recipient_address || {};

    const packages = packagesToMap.map((item) => {
      const { width, height, length } = convertDimensions(item, dimUnit);

      const packageWeight = convertWeightToKg(item.weight, item.weight_units || weightUnit);
      const totalProductWeight = calculateProductsTotalWeight({ products: item.products, weightUnit });

      return {
        weight: packageWeight + totalProductWeight,
        length,
        width,
        height,
        value: 0
      };
    });

    shippingAccounts
      .reduce((result, account) => {
        const items = {};
        const rawItems = account.children ? account.children : [account];

        rawItems.forEach((item) => {
          const shippingAccountId = item.id;

          if (!(shippingAccountId in items)) {
            items[shippingAccountId] = item;
          }
        });

        result.push(...Object.values(items));

        return result;
      }, [])
      .forEach(handleShippingAccount);

    options.commit('setShippingRates', result);

    options.state.ratesLoading = false;
  },

  async createShippingAccount({ commit, dispatch, state, rootGetters }, data) {
    commit('clearError');

    const locationIdList = data.location_id;

    delete data.location_id;

    await withLoading(state, async () => {
      try {
        const response = await fetch(`${rootGetters['auth/carrierServiceApiUrl']}/shippingaccount`, {
          method: 'POST',
          headers: {
            zkkey: rootGetters['auth/deliveryhubAccountKey'],
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            shipping_account: data
          })
        });

        if (response.status !== 200) {
          throw Error(`Request failed with ${response.status}`);
        }

        const { shipping_account: shippingAccount } = await response.json();

        for (let i = 0; i < locationIdList.length; ++i) {
          const locationConnectionResponse = await axios.post(
            `${rootGetters['auth/apiUrl']}/deliveryhub-orders-service/v1/shipping_locations/${locationIdList[i]}/shipping_accounts/${shippingAccount.id}`
          );

          if (locationConnectionResponse.data.success === false) {
            throw new Error(JSON.stringify(locationConnectionResponse.data));
          }
        }

        await dispatch('loadShippingAccounts');
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(error);
        }

        state.error = 'SHIPPING_ACCOUNTS_CREATION_FAIL';
      }
    });
  },

  async updateShippingAccount({ commit, dispatch, state, getters, rootGetters }, data) {
    commit('clearError');

    const locationIdList = data.location_id;
    const oldLocationIdList = getters['getShippingAccountById'](data.id).location_id;

    delete data.location_id;

    await withLoading(state, async () => {
      try {
        const response = await fetch(`${rootGetters['auth/carrierServiceApiUrl']}/shippingaccount/${data.id}`, {
          method: 'PUT',
          headers: {
            zkkey: rootGetters['auth/deliveryhubAccountKey'],
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            shipping_account: data
          })
        });

        if (response.status !== 200) {
          throw Error(`Request failed with ${response.status}`);
        }

        const { shipping_account: shippingAccount } = await response.json();

        const relations = [
          [oldLocationIdList, locationIdList, axios.delete],
          [locationIdList, oldLocationIdList, axios.post]
        ];

        for (let [collection, skipIdList, request] of relations) {
          for (let i = 0; i < collection.length; ++i) {
            const locationId = collection[i];

            if (skipIdList.includes(locationId)) {
              continue;
            }

            const locationResponse = await request(
              `${rootGetters['auth/apiUrl']}/deliveryhub-orders-service/v1/shipping_locations/${locationId}/shipping_accounts/${shippingAccount.id}`
            );

            if (locationResponse.data.success === false) {
              throw new Error(JSON.stringify(locationResponse.data));
            }
          }
        }

        await dispatch('loadShippingAccounts');
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(error);
        }

        state.error = 'SHIPPING_ACCOUNTS_UPDATING_FAIL';
      }
    });
  },

  async deleteShippingAccount({ commit, state, getters, rootGetters }, data) {
    commit('clearError');

    await withLoading(state, async () => {
      try {
        await fetch(`${rootGetters['auth/carrierServiceApiUrl']}/shippingaccount/${data.id}`, {
          method: 'DELETE',
          headers: {
            zkkey: rootGetters['auth/deliveryhubAccountKey'],
            'Content-Type': 'application/json; charset=utf-8'
          }
        });

        commit(
          'setShippingAccounts',
          getters.items.reduce((result, item) => {
            if (item.children) {
              item.children = item.children.filter((child) => child.id !== data.id);
            }

            if (item.id !== data.id) {
              result.push(item);
            }

            return result;
          }, [])
        );
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(error);
        }

        state.error = 'SHIPPING_ACCOUNTS_DELETION_FAIL';
      }
    });
  },

  resetShippingAccountsByRule({ commit }) {
    commit('setShippingAccountsByRule', null);
    commit('setRuleName', '');
  },

  async getPudoLocations({ commit, dispatch, state, rootGetters }, data) {
    commit('clearError');
    state.pudoError = null;

    await withLoading(state, async () => {
      try {
        const response = await fetch(`${rootGetters['auth/carrierServiceApiUrl']}/dopu`, {
          method: 'POST',
          headers: {
            zkkey: rootGetters['auth/deliveryhubAccountKey'],
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(data)
        });

        const responseJson = await response.json();

        if (response.status !== 200) {
          commit('setPudoLocations', []);
          const messages = {
            carrier_unsupported_method: t('PUDO.CARRIER_UNSUPPORTED_METHOD'),
            carrier_exception: t('PUDO.CARRIER_EXCEPTION'),
            carrier_failed_connection: t('PUDO.CARRIER_FAILED_CONNECTION')
          };

          const error = messages[responseJson.error.code] || responseJson?.error?.message || t('SOMETHING_WENT_WRONG');

          commit('setPudoError', error);

          return;
        }

        commit('setPudoLocations', responseJson.locations.slice(0, 10));
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    });
  }
};

const mutations = {
  setShippingLocations(state, value) {
    state.shippingLocations = value;
  },

  setShippingAccountsByRule(state, value) {
    state.shippingAccountsByRule = value;
  },

  setRuleName(state, value) {
    state.ruleName = value;
  },

  setShippingAccounts(state, shippingAccounts) {
    state.rawItems = shippingAccounts;
  },

  setShippingAccountsList(state, shippingAccountsList) {
    const shippingAccountsListEntries = shippingAccountsList.map((location) => [location.id, location]);

    state.list = new Map(shippingAccountsListEntries);
  },

  setShippingRates(state, value) {
    state.rates = value;
  },

  setPudoLocations(state, value) {
    state.pudoLocations = value;
  },

  setPudoError(state, value) {
    state.pudoError = value;
  },

  clearPudoError(state) {
    state.pudoError = '';
  },

  clearError(state) {
    state.error = '';
  }
};

const truncateCompanyName = (companyName) => {
  if (!companyName) {
    return companyName;
  }

  return truncate(companyName, { length: 35, omission: '' });
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
