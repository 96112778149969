<template>
  <div>
    <b-button
      v-if="!updateMode"
      :data-id="dataId"
      class="h-10 px-6 py-1 font-semibold"
      :disabled="isCustomService || !packedProductCount || shippingAccountLoading || containsEmptyPackage"
      :disabled-class="isCustomService || !packedProductCount || shippingAccountLoading ? 'bg-gray-400' : ''"
      type="info"
      @click="showModal">
      {{ t('FULFILLMENT_DETAILS.CREATE_SHIPMENT') }}
    </b-button>
    <modal
      :scrollable="shippingRulesEnabled"
      :value="state.isModalShown || isOpen"
      :hide-modal="state.print.showWarning || state.showFailedCancelModal"
      @close="closeModal">
      <template #title>
        <div class="px-6 py-4 font-semibold shadow-bottom">
          {{ t('CARRIER_ASSIGN_MODAL.TITLE') }}
        </div>
      </template>
      <template #body>
        <div class="px-6">
          <details-info-line
            class="text-xs mt-2 mb-4 text-gray-600"
            :value="infoLineData" />
          <special-services
            v-if="!updateMode"
            :services="specialServicesFromFF"
            with-contains></special-services>
          <carriers-table
            v-if="shippingRulesEnabled"
            use-rules
            :after-editing-items="afterEditingItems"
            :update-mode="updateMode"
            :error="state.commonError"
            :label-type="state.labelType"
            :clear-picked="clearPickedWithRules"
            @predefine-carrier="processPredefinedDefaultValue"
            @select-carrier="handleSelectCarrier"
            @search="handleSearch"></carriers-table>
          <carriers-table
            :with-rules="shippingRulesEnabled"
            :after-editing-items="afterEditingItems"
            :update-mode="updateMode"
            :error="state.commonError"
            :clear-picked="clearPicked"
            :search="state.search"
            :label-type="state.labelType"
            @predefine-carrier="processPredefinedDefaultValue"
            @select-carrier="handleSelectCarrier"></carriers-table>
        </div>
      </template>

      <template #footer>
        <div>
          <div
            v-if="allowSelectPudo"
            class="mt-5 px-8 py-3 bg-gray-50 border border-t border-gray-300"
            :class="{ 'opacity-50': !isCarrierSelected }">
            <div class="flex flex-row items-center gap-1">
              <div class="text-sm text-gray-700">
                {{ t('CARRIER_ASSIGN_MODAL.PUDO_DETAILS') }}
              </div>
              <Tooltip
                placement="top"
                width="w-96">
                <template #content>
                  <icon
                    :icon-name="BringgFontIcons.Info"
                    class="text-xl text-gray-600" />
                </template>
                <template #title>
                  <span class="font-semibold text-sm">
                    {{
                      updateMode
                        ? t('CARRIER_ASSIGN_MODAL.PUDO_TOOLTIP_UPDATE_MODE')
                        : t('CARRIER_ASSIGN_MODAL.PUDO_TOOLTIP')
                    }}
                  </span>
                </template>
              </Tooltip>
              <b-select
                top
                class="ml-1 flex-1"
                :value="state.pudoLocationId"
                :disabled="!isCarrierSelected || !!pudoError"
                :loading="shippingAccountLoading"
                :options="pudoLocationsOptions"
                @update:value="updatePudoLocationId" />
            </div>
            <p
              v-if="pudoError"
              class="mt-2 text-sm text-red-500">
              {{ pudoError }}
            </p>
          </div>
          <div class="px-6 py-3 flex justify-between items-center shadow-top">
            <div class="flex items-center gap-2">
              <icon
                v-if="!state.labelType"
                class="text-lg"
                :icon-name="BringgFontIcons.Loader"
                spin />
              <template v-else>
                <checkbox
                  v-model:value="state.print.labels"
                  data-id="print-labels">
                  <template #label>
                    <span class="text-sm text-gray-700">{{ t('CARRIER_ASSIGN_MODAL.PRINT_LABELS') }}</span>
                  </template>
                </checkbox>
                <div class="w-20">
                  <b-select
                    v-model:value="state.labelType"
                    data-id="label-type"
                    :disabled="!state.print.labels"
                    name="labelType"
                    :searchable="false"
                    top
                    :options="labelTypeOptions"
                    un-select />
                </div>
              </template>
            </div>

            <div class="flex items-center whitespace-nowrap">
              <b-button
                v-if="!updateMode"
                class="h-8 mr-6 px-6 border-none bg-white text-blue-400 text-base"
                data-id="save-without-booking"
                @click="submit('save-without-booking')">
                {{ t('CARRIER_ASSIGN_MODAL.SAVE_WITHOUT_BOOKING') }}
              </b-button>
              <b-button
                v-if="afterEditingItems"
                class="h-8 mr-6 px-6 border-none bg-white text-blue-400 text-base"
                data-id="saveAfterEditingItems"
                @click="closeModalAfterEditingItems">
                {{ t('CARRIER_ASSIGN_MODAL.SAVE_WITHOUT_BOOKING') }}
              </b-button>
              <b-button
                :loading="state.loading"
                class="h-8 mr-0 px-6 border-none bg-blue-50 text-blue-400 text-base"
                :disabled="disabledSubmitButton"
                data-id="book"
                @click="submit('book')">
                {{ submitButtonValue }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </modal>
    <confirm-modal
      data-id="print-warning"
      :is-shown="state.print.showWarning"
      @close="state.print.showWarning = false"
      @accept="goToPrintSettings"
      @reject="book">
      <template #title>
        {{ t('PRINT_CONFIRM_MODAL.TITLE') }}
      </template>
      <template #description>
        {{ printerModalDescription }}
      </template>
      <template #accept-label>
        {{ t('PRINT_CONFIRM_MODAL.PRINT_BUTTON') }}
      </template>
      <template #reject-label>
        {{ updateMode ? t('PRINT_CONFIRM_MODAL.UPDATE_BUTTON') : t('PRINT_CONFIRM_MODAL.BOOK_BUTTON') }}
      </template>
    </confirm-modal>
    <confirm-modal
      data-id="missed-items-warning"
      :is-shown="state.missedItemsWarning.isShown"
      @accept="ignoreMissedItemsWarning"
      @close="closeMissedItemsWarning"
      @reject="closeMissedItemsWarning">
      <template #title>
        <icon
          icon-name="promped-message"
          class="text-2xl text-orange-600" />
        {{ t('BOOK_CONFIRM_MODAL.TITLE') }}
      </template>
      <template #description>
        <i18next :translation="t('BOOK_CONFIRM_MODAL.DESCRIPTION')">
          <template #itemCount>
            {{ notPackedProductTotal }}
          </template>
        </i18next>
      </template>
      <template #accept-label>
        {{ t('BOOK_CONFIRM_MODAL.SET_CARRIER_BUTTON') }}
      </template>
      <template #reject-label>
        {{ t('BOOK_CONFIRM_MODAL.RETURN_BUTTON') }}
      </template>
    </confirm-modal>
    <confirm-modal
      data-id="partially-fulfilled-modal"
      :is-shown="state.showPartiallyFulfilledModal"
      class="fixed w-content"
      @close="state.showPartiallyFulfilledModal = false"
      @accept="goToFulfillment"
      @reject="onClosePartiallyFulfilledModal">
      <template #title>
        {{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.TITLE') }}
      </template>
      <template #description>
        <div
          v-if="getCarrierName(carrier) && service"
          class="flex">
          <div class="mr-2 ml-1">
            <icon
              icon-name="selected"
              class="text-base text-green-500" />
          </div>
          <div>{{ `${capitalizeFirstLetter(t('ENTITIES.SHIPMENT'))} ${t('CONFIRMATION_TEXT')}` }}</div>
          <div class="font-bold ml-1">
            {{ `${getCarrierName(carrier)} - ${service}` }}
          </div>
        </div>
        <div class="mt-5">{{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.DESCRIPTION') }}</div>
      </template>
      <template #accept-label>
        {{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.ACCEPT') }}
      </template>
      <template #reject-label>
        {{ t('SHIPMENT_DETAILS.PARTIAL_FULFILLMENT_MODAL.DISMISS') }}
      </template>
    </confirm-modal>
    <confirm-modal
      :is-shown="state.showFailedCancelModal && !isPacker"
      @close="closeCancelModal"
      @accept="acceptForceUpdate"
      @reject="rejectForceUpdate">
      <template #title>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_HEADER') }}
      </template>
      <template #description>
        <div>
          {{
            getFailedCancelDescription(
              t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_TEXT_CONFIRMATION'),
              shipmentDetails.carrier
            )
          }}
        </div>
        <div class="text-red-500">{{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_TEXT_WARNING') }}</div>
      </template>
      <template #accept-label>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.CONFIRM') }}
      </template>
      <template #reject-label>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECT') }}
      </template>
    </confirm-modal>
    <confirm-modal
      :is-shown="state.showFailedCancelModal && isPacker"
      :use-ignore-button="false"
      @close="closeCancelModal"
      @accept="rejectForceUpdate">
      <template #title>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_HEADER') }}
      </template>
      <template #description>
        <div>
          {{
            getFailedCancelDescription(
              t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_TEXT_PACKER'),
              shipmentDetails.carrier
            )
          }}
        </div>
      </template>
      <template #accept-label>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECT') }}
      </template>
    </confirm-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalFulfillmentCarrier'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BSelect from '@/components/atoms/BSelect/index.vue';
import BButton from '@/components/atoms/Button';
import Checkbox from '@/components/atoms/Checkbox/index.vue';
import Icon from '@/components/atoms/Icon';
import SpecialServices from '@/components/atoms/SpecialServices';
import Tooltip from '@/components/atoms/ToolTip/index.vue';
import CarriersTable from '@/components/molecules/CarriersTable';
import DetailsInfoLine from '@/components/molecules/DetailsInfoLine';
import ConfirmModal from '@/components/organisms/ConfirmModal';
import Modal from '@/components/organisms/Modal';
import { FulfillmentMethod, printLabelTypes, ShipmentStatus } from '@/enums';
import {
  capitalizeFirstLetter,
  delay,
  formatAddress,
  formatDeliverByColumn,
  getFailedCancelDescription,
  getLastEvent,
  getPudoLocationLabel,
  isCarrierAssignedToShipment,
  trimErrorText
} from '@/utils';

const { t } = useTranslation();
const router = useRouter();

const props = defineProps({
  orderId: {
    type: String,
    required: true
  },
  fulfillmentId: {
    type: String,
    required: true
  },
  updateMode: {
    type: Boolean,
    default: false
  },
  afterEditingItems: {
    type: Boolean,
    default: false
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  shipmentDetails: {
    type: Object,
    default: () => ({})
  },
  dataId: {
    type: String,
    default: ''
  },
  isPacker: {
    type: Boolean,
    default: false
  }
});

const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);

const rejectionReason = computed(() => {
  if (shipmentDetails.value?.shipment_events && Object.keys(shipmentDetails.value?.shipment_events).length) {
    return getLastEvent({ events: shipmentDetails.value?.shipment_events || [], type: 'carrier_assignment_failure' });
  }

  return null;
});

const clearPicked = ref(false);
const clearPickedWithRules = ref(false);

const emit = defineEmits([
  'open-loader',
  'shipment-creation-end',
  'close',
  'update-fulfillment',
  'redispatched',
  'close-loader'
]);

const store = useStore();

const state = reactive({
  isModalShown: false,
  touched: false,
  loading: false,
  commonError: null,
  force: false,
  search: '',
  sourceId: '',
  pudoLocationId: '',
  selectedCarrier: {
    carrier: '',
    id: ''
  },
  print: {
    labels: true,
    labelType: null,
    showWarning: false
  },
  missedItemsWarning: {
    isShown: false,
    accepted: false,
    next: null,
    name: ''
  },
  showPartiallyFulfilledModal: false,
  showFailedCancelModal: false
});

const isCarrierSelected = computed(() => {
  return state.selectedCarrier?.carrier && state.selectedCarrier?.id;
});

const loadFulfillmentDetails = async () =>
  await store.dispatch('fulfillments/loadFulfillmentById', fulfillmentParams.value);
const loadShippingRules = async () => await store.dispatch('shippingRules/loadRules');

const shippingRules = computed(() => store.state.shippingRules.rules);

const shippingRulesEnabled = computed(() => store.state.shippingRules.rules.some((rule) => rule.enabled === true));

onMounted(async () => {
  await loadFulfillmentDetails();

  if (!shippingRules.value?.length > 0) {
    await loadShippingRules();
  }
  updatePudoLocationId(fulfillmentDetails.value?.pudo_location_id);

  if (props.updateMode) {
    state.labelType = props.shipmentDetails.label_type;
  } else {
    state.labelType = store.getters['users/printersSettings']?.label_type;
  }
  state.labelType ??= printLabelTypes.ZPL;
});

const isCustomService = computed(() => store.getters['auth/isCustomService']);

const submitButtonValue = computed(() =>
  props.updateMode ? t('CARRIER_ASSIGN_MODAL.UPDATE_CARRIER') : t('CARRIER_ASSIGN_MODAL.BOOK_CARRIER')
);

const clearError = () => {
  store.commit('shipments/clearError');
};

const getCarrierName = (carrier) => store.getters['carriers/carrierNames'][carrier] || carrier;

const onClosePartiallyFulfilledModal = () => {
  state.showPartiallyFulfilledModal = false;
  emit('close');
};
const goToFulfillment = () => {
  state.showPartiallyFulfilledModal = false;

  router.push({
    name: 'fulfillment-details',
    params: {
      orderId: props.orderId,
      id: props.fulfillmentId
    }
  });
};

const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);
const fulfillmentMethodIsPudo = computed(() => fulfillmentDetails.value.fulfillment_method === FulfillmentMethod.PUDO);
const allowSelectPudo = computed(() => {
  const allowSelectPudoShipmentScreen =
    [ShipmentStatus.New, ShipmentStatus.ReadyToShip].includes(shipmentDetails.value.status) &&
    fulfillmentMethodIsPudo.value;

  return props.updateMode ? allowSelectPudoShipmentScreen : fulfillmentMethodIsPudo.value;
});

const fulfillmentParams = computed(() => ({
  orderId: props.orderId,
  fulfillmentId: props.fulfillmentId
}));

const shippingAccountLoading = computed(() => store.state.shippingAccounts.loading);

const billingAddress = computed(() => formatAddress(fulfillmentDetails.value.recipient_address));

const featureFlags = store.getters['auth/featureFlags'];

const infoLineData = computed(() => [
  { label: '', value: fulfillmentDetails.value.name },
  { label: 'FULFILLMENT_DETAILS.PACKAGES_COUNT_FIELD', value: 0 },
  { label: 'FULFILLMENT_DETAILS.ITEM_COUNT_FIELD', value: fulfillmentDetails.value.items_count },
  { label: 'FULFILLMENT_DETAILS.PROMISED_TIME_FIELD', value: formatDeliverByColumn(fulfillmentDetails.value) },
  { label: 'FULFILLMENT_DETAILS.ADDRESS_FIELD', value: billingAddress.value },
  !props.updateMode &&
    featureFlags.edit_shipment_source_id && {
      label: 'FULFILLMENT_DETAILS.SHIPMENT_SOURCE_ID',
      value: state.sourceId,
      onEdit: handleSourceIdUpdate
    }
  // { label: 'FULFILLMENT_DETAILS.SHIPPING_METHOD_FIELD', value: 'Expedited ($21.00)' }
]);

const packages = computed(() => store.getters['packages/getItemsByFulfillmentId'](props.fulfillmentId));

const error = computed(
  () =>
    store.state.fulfillments.error ||
    store.state.products.error ||
    store.state.packages.error ||
    store.state.shipments.error ||
    store.state.shipments.modalError ||
    store.state.shippingAccounts.error
);

const pudoError = computed(() => store.state.shippingAccounts.pudoError);
const products = computed(() => store.getters['products/getItemsByFulfillmentId'](props.fulfillmentId));

const specialServicesFromFF = computed(() =>
  packages.value.reduce((acc, pkg) => {
    const servicesFromProduct = (pkg.products || []).reduce((productAcc, product) => {
      return { ...productAcc, ...(product.product?.special_services || {}) };
    }, {});

    return { ...acc, ...servicesFromProduct };
  }, {})
);
const containsEmptyPackage = computed(() => packages.value.some((item) => !item.children.length));
const packedProductCount = computed(() =>
  packages.value.reduce((result, item) => result + (item.children || []).length, 0)
);
const notPackedProductTotal = computed(() => {
  return products.value.reduce((result, item) => result + item.count, 0);
});

const carrier = computed(
  () =>
    props.shipmentDetails?.carrier || (!props.afterEditingItems && fulfillmentDetails.value?.shipping_account?.carrier)
);
const service = computed(
  () => props.shipmentDetails?.service || (!props.afterEditingItems && fulfillmentDetails.value?.service)
);

const showMissedItemsWarning = computed(() => products.value.length);

const printSettings = computed(() => {
  const { ready, emptySettings = null } = store.getters['users/printersReady'](fulfillmentDetails);

  return { ready, emptySettings };
});

const printerModalDescription = computed(() => {
  if (!printSettings.value) {
    return '';
  }

  const { emptySettings } = printSettings.value;

  const defaultText = t('PRINT_CONFIRM_MODAL.DESCRIPTION');
  const splitted = defaultText.split('%%%');
  const entity = props.updateMode ? t('ENTITIES.SHIPMENT') : t('ENTITIES.FULFILLMENT');

  return `${splitted[0]}${t(`PRINTING.${emptySettings}`)}${splitted[1]}${t(`PRINT_CONFIRM_MODAL.${emptySettings}`)}${
    splitted[2]
  }${entity}${splitted.slice(3).join('%%%')}`;
});

const updatePudoLocationId = (value) => {
  state.pudoLocationId = value;
};

const updateFulfillment = async () => {
  const data = {
    shipping_account_id: state.selectedCarrier.id,
    service: state.selectedCarrier.service,
    fulfillment_method: state.pudoLocationId ? FulfillmentMethod.PUDO : FulfillmentMethod.DIRECT,
    ...(state.pudoLocationId && {
      pudo_location_id: state.pudoLocationId,
      pudo:
        pudoLocationsOptions.value.find((location) => location.value === state.pudoLocationId)?.label ||
        state.pudoLocationId
    })
  };

  await store.dispatch('fulfillments/updateFulfillmentDetails', {
    ...fulfillmentParams.value,
    data
  });
};

const updateShipments = async (value, waitForCallback = false) => {
  const shipmentId = shipmentDetails.value.id;

  const response = await store.dispatch('shipments/updateShipment', {
    ...fulfillmentParams.value,
    shipmentId: shipmentId,
    data: {
      shipping_account_id: value.id,
      service: value.service,
      label_type: state.labelType,
      source_id: state.sourceId,
      force: state.force
    },
    waitForCallback
  });

  return response;
};

const createShipment = async ({ isPrinted, disableShippingRules }) => {
  emit('open-loader');

  !disableShippingRules && (await updateFulfillment());

  const response = await store.dispatch('shipments/createShipment', {
    ...fulfillmentParams.value,
    selectedCarrier: !disableShippingRules ? state.selectedCarrier : {},
    packages: packages.value,
    isPrinted,
    disableShippingRules,
    labelType: state.labelType,
    sourceId: state.sourceId
  });

  if (error.value) {
    handleError(error);
    state.isModalShown = true;

    emit('close-loader');

    return;
  }

  await store.commit('shipments/setShipmentDetails', response.data);

  store.dispatch('shipments/loadFulfillmentShipments', {
    orderId: props.orderId,
    fulfillmentId: props.fulfillmentId
  });

  const isCarrierBooked = response ? isCarrierAssignedToShipment(response.data) : false;

  if (!disableShippingRules && !isCarrierBooked) {
    state.loading = false;

    const { value } = getLastEvent({ events: response.data.shipment_events, type: 'carrier_assignment_failure' });

    handleError(value);

    state.isModalShown = true;

    emit('update-fulfillment');
  } else {
    state.commonError = null;
    state.isModalShown = false;
  }

  emit('shipment-creation-end', {
    disableShippingRules,
    selectedCarrier: state.selectedCarrier,
    isCarrierBooked
  });
};

const handleBookingTryouts = async (options) => {
  if (Object.keys(shipmentDetails.value).length > 0 && !options.disableShippingRules) {
    const updated = await update();

    if (updated) {
      emit('shipment-creation-end', {
        disableShippingRules: options.disableShippingRules,
        selectedCarrier: state.selectedCarrier,
        isCarrierBooked: true
      });

      return;
    }

    emit('shipment-creation-end', {
      disableShippingRules: options.disableShippingRules,
      selectedCarrier: state.selectedCarrier,
      isCarrierBooked: false
    });

    return;
  }

  await createShipment(options);
};

const book = async (disableShippingRules = false) => {
  emit('open-loader');

  state.print.showWarning = false;

  if (props.updateMode) {
    await update();

    return;
  } else {
    if (showMissedItemsWarning.value && !state.missedItemsWarning.accepted) {
      state.missedItemsWarning.isShown = true;

      emit('close-loader');

      state.missedItemsWarning.next = async () => book(disableShippingRules);

      return;
    }
  }

  await handleBookingTryouts({ isPrinted: false, disableShippingRules });
};

const bookAndPrint = async () => {
  if (showMissedItemsWarning.value) {
    if (!state.missedItemsWarning.accepted) {
      state.missedItemsWarning.isShown = true;
      state.missedItemsWarning.next = bookAndPrint;

      return;
    }
  }

  if (printSettings.value?.ready === false) {
    state.print.showWarning = true;

    return;
  }

  await handleBookingTryouts({ isPrinted: true });
};

const updateAndPrint = async () => {
  if (printSettings.value?.ready === false) {
    state.print.showWarning = true;

    return;
  }

  await update(true);
};

const handleCancelError = (error) => {
  const CANCEL_ERROR = 'The shipment was not cancelled';

  if (error === CANCEL_ERROR) {
    state.showFailedCancelModal = true;

    return true;
  }

  return false;
};

const handleError = (error) => {
  const errorToParse = error?.value || error;

  if (errorToParse && !handleCancelError(errorToParse)) {
    const trimmedError = trimErrorText(errorToParse);

    if (errorToParse === trimmedError) {
      state.commonError = t(errorToParse);
    }
  }
};

const handlePartialFulfillment = () => {
  const status = store.state.fulfillments.itemDetails?.status;

  if (status === ShipmentStatus.PartiallyFulfilled) {
    state.showPartiallyFulfilledModal = true;
  }
};

const handlePrint = async (print) => {
  if (print) {
    await store.dispatch('printing/print', { shipmentId: props.shipmentDetails.id }, { root: true });
  }
};

const handleServiceSelection = (withRules) => {
  if (withRules) {
    clearPicked.value = true;
    clearPickedWithRules.value = false;
  } else {
    clearPickedWithRules.value = true;
    clearPicked.value = false;
  }
};

const handleSelectCarrier = async ({ value, useRules }) => {
  handleServiceSelection(useRules);
  state.selectedCarrier = value;
  updatePudoLocationId('');
  await getPudoLocations();
};

const handleSearch = (value) => {
  state.search = value;
};

const handleSourceIdUpdate = async (value) => {
  if (state.sourceId === value) {
    return;
  }

  clearError();
  state.commonError = null;

  state.sourceId = value;
};

const update = async (print = false) => {
  // wait exact modal animation duration to ensure background is animated
  await delay(300);
  state.isModalShown = true;
  state.loading = true;

  await updateFulfillment();
  const response = await updateShipments(state.selectedCarrier, true);

  const isCarrierBooked = response ? isCarrierAssignedToShipment(response.data) : false;

  state.loading = false;

  if (error.value) {
    handleError(error);

    return false;
  }

  if (!isCarrierBooked) {
    handleError(rejectionReason.value);

    return false;
  }

  state.isModalShown = false;
  state.commonError = null;
  state.force = false;

  await handlePrint(print);

  handlePartialFulfillment();

  emit('close');

  return true;
};

const goToPrintSettings = async () => {
  await router.push({ name: 'printing' });
};

const ignoreMissedItemsWarning = async () => {
  state.missedItemsWarning.isShown = false;
  state.missedItemsWarning.accepted = true;

  await state.missedItemsWarning.next();

  state.missedItemsWarning.next = null;
};

const closeMissedItemsWarning = async () => {
  state.missedItemsWarning.isShown = false;
  state.missedItemsWarning.next = null;
};

const showModal = () => (state.isModalShown = true);

const closeModal = () => {
  state.isModalShown = false;
  state.commonError = null;
  store.commit('shippingAccounts/clearPudoError');
  if (props.updateMode) {
    if (props.afterEditingItems) {
      closeModalAfterEditingItems();
    } else {
      emit('close');
    }
  } else {
    updatePudoLocationId(fulfillmentDetails.value?.pudo_location_id);
  }
};

const closeModalAfterEditingItems = () => {
  state.showPartiallyFulfilledModal = true;
  emit('close');
};

const submit = async (actionName) => {
  state.isModalShown = false;
  state.commonError = null;
  store.commit('shippingAccounts/clearPudoError');

  if (actionName === 'book') {
    if (state.print.labels) {
      props.updateMode ? await updateAndPrint() : await bookAndPrint();
    } else {
      await book();
    }
  } else if (actionName === 'save-without-booking') {
    if (shipmentDetails.value?.id) {
      emit('shipment-creation-end', {
        disableShippingRules: true,
        selectedCarrier: state.selectedCarrier,
        isCarrierBooked: false
      });

      return;
    }

    await book(true);
  }
};

const labelTypeOptions = [printLabelTypes.PDF, printLabelTypes.ZPL];

const redispatch = async ({ shipping_account_id, service }) => {
  state.loading = true;

  await updateShipments({ id: shipping_account_id, service }, true);

  state.loading = false;

  if (error.value) {
    handleError(error);
  } else {
    emit('redispatched', state.selectedCarrier);
  }
};

const closeCancelModal = () => {
  state.showFailedCancelModal = false;
  state.isModalShown = true;
};

const acceptForceUpdate = async () => {
  state.showFailedCancelModal = false;
  state.force = true;

  await update();
};

const rejectForceUpdate = () => {
  state.showFailedCancelModal = false;
  state.isModalShown = true;
};

const processPredefinedDefaultValue = async (value) => {
  state.selectedCarrier = value;
  await getPudoLocations();
};

watch(
  () => props.isOpen,
  async (newValue) => {
    if (newValue) {
      updatePudoLocationId(fulfillmentDetails.value.pudo_location_id);
    }
  }
);

const getPudoLocations = async () => {
  if (!fulfillmentMethodIsPudo.value) {
    return;
  }

  const { city, country, postal_code, state: recipientState, street1 } = fulfillmentDetails.value.recipient_address;
  const { selectedCarrier } = state;

  if (!selectedCarrier.id) {
    return;
  }

  const payload = {
    dopu: {
      carrier: selectedCarrier.carrier,
      location: {
        city,
        country,
        postal_code,
        state: recipientState,
        street1
      },
      shipping_account: selectedCarrier.id,
      debug: true
    }
  };

  await store.dispatch('shippingAccounts/getPudoLocations', payload);

  state.commonError = error.value || '';
};

const pudoLocationsOptions = computed(() => {
  return store.state.shippingAccounts.pudoLocations.map((location) => ({
    label: getPudoLocationLabel(location),
    value: location.location_code
  }));
});

const disabledSubmitButton = computed(() => {
  const { selectedCarrier, loading, pudoLocationId } = state;
  const pudoLocationIdIsRequired = props.updateMode && fulfillmentMethodIsPudo.value && !pudoLocationId;

  return !selectedCarrier.carrier || !selectedCarrier.id || loading || pudoLocationIdIsRequired;
});

defineExpose({ redispatch });
</script>
