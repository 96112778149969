const state = { show: false, src: '', mimeType: null };

const getters = {
  show(state) {
    return state.show;
  },
  src(state) {
    return state.src;
  },
  mimeType(state) {
    return state.mimeType;
  }
};

const actions = {
  async setSource({ commit }, { src, mimeType }) {
    commit('setSource', { src, mimeType });
  },
  async close({ commit }) {
    commit('close');
  }
};

const mutations = {
  setSource(state, { src, mimeType = null }) {
    state.src = src;
    state.show = true;
    state.mimeType = mimeType;
  },

  close(state) {
    state.show = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
