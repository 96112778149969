<template>
  <modal
    :value="isShown"
    :validation="schema"
    @close="onClose"
    @submit="onSubmit">
    <template #title>
      <h3 class="px-6 py-4 text-lg leading-6 font-medium text-gray-900">
        {{ props.isEdit ? t('PACKAGES_MODAL.EDIT_PACKAGE') : t('PACKAGES_MODAL.NEW_PACKAGE') }}
      </h3>
      <divider></divider>
    </template>
    <template
      v-if="data.localValue"
      #body>
      <div class="px-6 py-4 w-4/5">
        <b-input
          id="name"
          v-model:value="data.localValue.name"
          size="small"
          :placeholder="t('PACKAGES_MODAL.PACKAGE_PLACEHOLDER')"
          class="mb-4">
          <template #label>
            {{ stylizeLabelAsRequired(t('PACKAGES_MODAL.PACKAGE')) }}
          </template>
        </b-input>
        <div class="grid gap-4 grid-cols-2">
          <b-input
            id="length"
            v-model:value="data.localValue.length"
            size="small"
            :placeholder="t('PACKAGES_MODAL.LENGTH_PLACEHOLDER')"
            class="mb-4">
            <template #label>
              {{ stylizeLabelAsRequired(t('PACKAGES_MODAL.LENGTH')) }}
            </template>
          </b-input>
          <b-input
            id="width"
            v-model:value="data.localValue.width"
            size="small"
            :placeholder="t('PACKAGES_MODAL.WIDTH_PLACEHOLDER')"
            class="mb-4">
            <template #label>
              {{ stylizeLabelAsRequired(t('PACKAGES_MODAL.WIDTH')) }}
            </template>
          </b-input>
          <b-input
            id="height"
            v-model:value="data.localValue.height"
            size="small"
            :placeholder="t('PACKAGES_MODAL.HEIGHT_PLACEHOLDER')"
            class="mb-4">
            <template #label>
              {{ stylizeLabelAsRequired(t('PACKAGES_MODAL.HEIGHT')) }}
            </template>
          </b-input>
          <b-input
            id="weight"
            v-model:value="data.localValue.weight"
            size="small"
            :placeholder="t('PACKAGES_MODAL.WEIGHT_EMPTY_PACKAGE_PLACEHOLDER')"
            class="mb-4">
            <template #label>
              {{ stylizeLabelAsRequired(t('PACKAGES_MODAL.WEIGHT_EMPTY_PACKAGE')) }}
            </template>
          </b-input>
        </div>
      </div>
    </template>
    <template #footer="{ validate, submit, resetForm, isSubmittable }">
      <div class="mt-7 px-6 py-4 flex justify-end gap-6 shadow-top">
        <template v-if="props.isEdit">
          <b-button
            data-id="save"
            :disabled="!isSubmittable"
            submit>
            {{ t('SAVE_BUTTON') }}
          </b-button>
        </template>
        <template v-else>
          <b-button
            class="h-8 px-4 py-1 bg-blue-50 text-blue-400 border-none text-base font-medium"
            :disabled="!isSubmittable"
            data-id="saveAndAddNew"
            submit>
            {{ t('MODAL.SAVE_AND_ADD_NEW') }}
          </b-button>
          <b-button
            :disabled="!isSubmittable"
            data-id="save"
            class="h-8 px-4 py-1 bg-blue-400 text-white border-none text-base font-medium"
            @click="onSubmitAndClose({ validate, submit, resetForm })">
            {{ t('SAVE_BUTTON') }}
          </b-button>
        </template>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ModalPackages'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, nextTick, reactive, watch } from 'vue';
import { number, object, string } from 'yup';

import { stylizeLabelAsRequired } from '@/utils';

import BButton from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import BInput from '../../atoms/Input';
import Modal from '../../organisms/Modal';
import { MAX_PACKAGE_SIZE, MAX_PACKAGE_WEIGHT } from './constants';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  isEdit: {
    type: Boolean,
    required: true
  },
  isShown: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['create', 'edit', 'close']);

const localValueFactory = () => {
  return {
    name: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    dim_units: 'cm',
    weight_units: 'g'
  };
};

const data = reactive({
  localValue: localValueFactory()
});

const schema = computed(() =>
  object({
    name: string().trim().max(50).required().label(t('PACKAGES_MODAL.PACKAGE')),
    length: number()
      .positive()
      .typeError(t('ERROR_NUMBER_TYPE'))
      .required()
      .max(MAX_PACKAGE_SIZE)
      .label(t('PACKAGES_MODAL.LENGTH')),
    width: number()
      .positive()
      .typeError(t('ERROR_NUMBER_TYPE'))
      .required()
      .max(MAX_PACKAGE_SIZE)
      .label(t('PACKAGES_MODAL.WIDTH')),
    height: number()
      .positive()
      .typeError(t('ERROR_NUMBER_TYPE'))
      .required()
      .max(MAX_PACKAGE_SIZE)
      .label(t('PACKAGES_MODAL.HEIGHT')),
    weight: number()
      .positive()
      .typeError(t('ERROR_NUMBER_TYPE'))
      .required()
      .max(MAX_PACKAGE_WEIGHT)
      .label(t('PACKAGES_MODAL.WEIGHT_EMPTY_PACKAGE'))
  })
);

watch(
  () => props.value,
  (value) => {
    data.localValue = {
      ...localValueFactory(),
      ...value
    };
  }
);

const onSubmit = async () => {
  const event = props.isEdit ? 'edit' : 'create';
  const value = data.localValue;

  data.localValue = localValueFactory();

  await nextTick(() => {
    emit(event, value);
  });
};

const onSubmitAndClose = async (form) => {
  const valid = await form.validate();
  const value = data.localValue;

  if (valid.valid) {
    await form.submit(value, { resetForm: form.resetForm });
    emit('close');
  }
};

const onClose = async () => {
  data.localValue = localValueFactory();
  await nextTick(() => {
    emit('close');
  });
};
</script>
