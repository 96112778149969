<template>
  <div class="relative">
    <highlightjs
      :autodetect="false"
      :code="formattedValue"
      :class="$style.codeBlock"
      class="w-full h-full whitespace-pre bg-transparent"
      language="json" />
    <template v-if="copied">
      <b-icon
        class="absolute right-3 top-0 text-2xl text-green-400"
        icon-name="suffix" />
    </template>
    <template v-else>
      <b-icon
        class="absolute right-3 top-0 text-2xl text-blue-400 hover:text-blue-500 cursor-pointer"
        icon-name="copy"
        @click="copy" />
    </template>
  </div>
</template>

<script>
import 'highlight.js/styles/vs.css';

import hljsVuePlugin from '@highlightjs/vue-plugin';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';

hljs.registerLanguage('json', json);

export default {
  name: 'JsonFormatter',

  components: {
    highlightjs: hljsVuePlugin.component
  }
};
</script>

<script setup>
import { computed, defineProps, ref } from 'vue';

import { copyToClipboard } from '../../../utils';
import BIcon from '../../atoms/Icon';

const props = defineProps({
  value: {
    type: [Object, String],
    default: () => ({})
  }
});

const copied = ref(false);

const formattedValue = computed(() => {
  let value = typeof props.value === 'string' ? JSON.parse(props.value) : props.value;

  return JSON.stringify(value, null, 4);
});

const copy = () => {
  copyToClipboard(formattedValue.value);
  copied.value = true;
  setTimeout(() => (copied.value = false), 1500);
};
</script>

<style module>
.codeBlock span {
  @apply text-sm !important;
}
</style>
