<template>
  <div>
    <template
      v-for="item in items"
      :key="item.slug">
      <div v-if="!item.children">
        <router-link
          :to="item.to"
          :class="[
            item.current ? 'bg-gray-100 text-blue-500 font-semibold' : 'text-gray-600 hover:bg-gray-100',
            'group flex items-center px-2 py-2 text-sm font-normal rounded no-underline hover:no-underline mb-1'
          ]"
          @click="clickMenuItem">
          <icon
            :icon-name="item.icon"
            :class="[item.current ? 'text-blue-500' : 'text-gray-600']"
            class="mr-3 flex-shrink-0 text-2xl"
            aria-hidden="true" />
          <span v-show="!isSmall">{{ item.name }}</span>
        </router-link>
      </div>
      <template v-else>
        <Disclosure
          as="div"
          class="space-y-1">
          <DisclosureButton
            as="span"
            :class="[item.children.some((e) => e.current) ? 'text-blue-500' : 'text-gray-600']"
            class="group flex items-center px-2 py-2 text-sm font-normal rounded-md no-underline hover:no-underline cursor-pointer"
            @click="toggle(item)">
            <icon
              :icon-name="item.icon"
              :class="[item.current ? 'text-blue-500' : 'text-gray-600']"
              class="mr-3 flex-shrink-0 text-2xl"
              aria-hidden="true" />
            <span
              v-show="!isSmall"
              class="flex-1">
              {{ item.name }}
            </span>
            <template v-if="!isSmall">
              <icon
                v-if="isItemOpen(item)"
                icon-name="chevron-up"
                class="ml-3 text-base"
                aria-hidden="true"></icon>
              <icon
                v-else
                icon-name="chevron"
                class="ml-3 text-base"
                aria-hidden="true" />
            </template>
          </DisclosureButton>
          <div v-show="isItemOpen(item) && !isSmall">
            <DisclosurePanel
              class="space-y-1"
              static>
              <DisclosureButton
                v-for="subItem in item.children"
                :key="subItem.slug"
                as="span">
                <router-link
                  :to="subItem.to"
                  :class="[
                    subItem.current ? 'bg-gray-100 text-blue-500 font-semibold' : 'text-gray-600 hover:bg-gray-100',
                    'group flex items-center pl-3 pr-2 -mx-1 whitespace-nowrap py-2 text-sm font-medium rounded-md no-underline hover:no-underline'
                  ]"
                  @click="clickSubMenuItem(item)">
                  <div
                    class="mr-3 flex-shrink-0 h-6 w-6 text-gray-600"
                    aria-hidden="true" />
                  {{ subItem.name }}
                </router-link>
              </DisclosureButton>
            </DisclosurePanel>
          </div>
        </Disclosure>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenu'
};
</script>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { defineEmits, defineProps, onMounted } from 'vue';

import Icon from '../../atoms/Icon';

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  isSmall: {
    type: Boolean,
    required: false,
    default: false
  },
  openMenus: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  const current = props.items.find((item) => item.current);

  if (current?.children) {
    props.openMenus.add(current.slug);
  }
});

const isItemOpen = (item) => props.openMenus.has(item.slug);

const emit = defineEmits(['toggle']);

const toggle = (item) => {
  const isOpen = isItemOpen(item);

  if (isOpen) {
    props.openMenus.delete(item.slug);
  } else {
    props.openMenus.add(item.slug);
  }

  if (props.isSmall) {
    props.openMenus.add(item.slug);
    emit('toggle');
  }
};
const clickMenuItem = () => {
  props.openMenus.clear();
};
const clickSubMenuItem = (item) => {
  props.openMenus.clear();
  props.openMenus.add(item.slug);
};
</script>

<style lang="scss" scoped></style>
