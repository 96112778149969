import ISO6391 from 'iso-639-1';
import { omit } from 'lodash';

export const CustomerNotificationProvider = {
  SMS: 'sms',
  EMAIL: 'email'
};

export const CustomerNotificationEventType = {
  OrderCreated: 'order:created',
  OrderDelivered: 'order:delivered',
  OrderCanceled: 'order:canceled',

  ShipmentCreated: 'shipment:created',
  ShipmentCanceled: 'shipment:canceled',
  ShipmentInTransit: 'shipment:inTransit',
  ShipmentCanceledByCarrier: 'shipment:canceledByCarrier',
  ShipmentReadyToBeShipped: 'shipment:readyToBeShipped',
  ShipmentDelivered: 'shipment:delivered',
  ShipmentOutForDelivery: 'shipment:outForDelivery',

  Exception: 'exception',
  PackageException: 'package:exception'
};

const EmailVariables = {
  products_table: 'products_table',
  service: 'service'
};

const EmailOrderVariables = {
  products_table: 'products_table'
};

export const Variables = {
  // driver_name: 'driver_name',
  // driver_phone_number: 'driver_phone_number',
  // eta_hour: 'eta_hour',
  carrier: 'carrier',
  customer_name: 'customer_name',
  delivery_address: 'delivery_address',
  eta_date: 'eta_date',
  fulfillment_id: 'fulfillment_id',
  fulfillment_source_id: 'fulfillment_source_id',
  order_id: 'order_id',
  order_source_id: 'order_source_id',
  shipment_id: 'shipment_id',
  shipment_source_id: 'shipment_source_id',
  support_email: 'support_email',
  support_phone: 'support_phone',
  support_url: 'support_url',
  tracking_number: 'tracking_number',
  tracking_url: 'tracking_url'
};

export const OrderVariables = omit(Variables, [
  Variables.carrier,
  // Variables.driver_name,
  // Variables.driver_phone_number,
  Variables.shipment_id,
  Variables.tracking_number,
  Variables.tracking_url,
  // Variables.eta_hour,
  Variables.eta_date,
  Variables.shipment_source_id,
  Variables.fulfillment_source_id
]);

export const CustomerNotificationVariables = {
  [CustomerNotificationEventType.OrderCreated]: OrderVariables,
  [CustomerNotificationEventType.OrderDelivered]: OrderVariables,
  [CustomerNotificationEventType.OrderCanceled]: OrderVariables,
  [CustomerNotificationEventType.ShipmentCreated]: Variables,
  [CustomerNotificationEventType.ShipmentCanceled]: Variables,
  [CustomerNotificationEventType.ShipmentInTransit]: Variables,
  [CustomerNotificationEventType.ShipmentCanceledByCarrier]: Variables,
  [CustomerNotificationEventType.ShipmentReadyToBeShipped]: Variables,
  [CustomerNotificationEventType.ShipmentDelivered]: Variables,
  [CustomerNotificationEventType.ShipmentOutForDelivery]: Variables,
  [CustomerNotificationEventType.Exception]: Variables
};

export const CustomerNotificationEmailVariables = {
  [CustomerNotificationEventType.OrderCreated]: EmailOrderVariables,
  [CustomerNotificationEventType.OrderDelivered]: EmailOrderVariables,
  [CustomerNotificationEventType.OrderCanceled]: EmailOrderVariables,
  [CustomerNotificationEventType.ShipmentCreated]: EmailVariables,
  [CustomerNotificationEventType.ShipmentCanceled]: EmailVariables,
  [CustomerNotificationEventType.ShipmentInTransit]: EmailVariables,
  [CustomerNotificationEventType.ShipmentCanceledByCarrier]: EmailVariables,
  [CustomerNotificationEventType.ShipmentReadyToBeShipped]: EmailVariables,
  [CustomerNotificationEventType.ShipmentDelivered]: EmailVariables,
  [CustomerNotificationEventType.ShipmentOutForDelivery]: EmailVariables,
  [CustomerNotificationEventType.Exception]: EmailVariables,
  [CustomerNotificationEventType.PackageException]: EmailVariables
};

export const CustomerNotificationProviderByRoute = {
  'sms-notifications': CustomerNotificationProvider.SMS,
  'email-notifications': CustomerNotificationProvider.EMAIL
};

export const DEFAULT_LOCALE = ISO6391.getCode('English');

export const LOCALE_OPTIONS = ISO6391.getLanguages(ISO6391.getAllCodes()).map(({ name, code }) => ({
  label: name,
  value: code
}));
