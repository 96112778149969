<template>
  <div
    v-if="!isMounted"
    class="h-full flex justify-center items-center">
    <spinner />
  </div>
  <div
    v-show="isMounted"
    class="relative h-full flex flex-col">
    <teleport
      v-if="isMounted && fulfillmentDetails.name"
      to="#pageTitle">
      <div class="flex items-top">
        <span
          class="mr-1 cursor-pointer"
          @click="$router.back()">
          <icon
            icon-name="arrow-left"
            class="w-6 h-6 text-blue-500" />
        </span>
        <span class="mr-4">
          {{ fulfillmentDetails.name }} #{{ fulfillmentDetails.source_id ?? fulfillmentDetails.id }}
        </span>
        <badge
          class="h-5 mt-0.5"
          :type="statusBadgeTypeMapping[fulfillmentDetails.status]">
          {{ t(fulfillmentDetails.status) }}
        </badge>
      </div>
    </teleport>
    <div class="flex justify-between items-end bg-white">
      <div class="flex flex-row">
        <details-info-line
          class="px-10 pt-5 pb-3 bg-white text-gray-600 text-sm"
          :value="infoLineData" />
        <b-button
          data-id="fulfillment-edit-address-button"
          type="blank"
          :disabled="isCustomService"
          @click="openDrawerAddress">
          <div class="pt-5 flex items-center text-blue-400">{{ t('FULFILLMENT_DETAILS.VIEW_EDIT_DETAILS') }}</div>
        </b-button>
      </div>

      <b-button
        data-id="fulfillment-notes-button"
        type="blank"
        @click="openDrawer">
        <div class="flex items-center text-blue-400">
          <icon
            icon-name="notes"
            class="text-lg mr-1" />
          <div class="text-base">{{ `${t('NOTES.TITLE')} (${orderDetails?.notes?.length || 0})` }}</div>
        </div>
      </b-button>
    </div>
    <div class="flex-1 overflow-auto">
      <alert
        v-if="error"
        class="mx-6 my-5"
        type="danger"
        :close="true"
        @close="clearError">
        {{ t(error) }}
      </alert>
      <drawer
        :open="state.showDrawer"
        :top="drawerTop"
        @close="closeDrawer">
        <template #content>
          <notes
            with-tooltip
            :notes="orderDetails?.notes || []"
            :tooltip-text="t('NOTES.TOOLTIP')"
            @commit="commitNotesChanges"
            @close="closeDrawer"></notes>
        </template>
      </drawer>

      <drawer
        :top="drawerTop"
        :open="state.showDrawerAddress"
        @close="closeDrawerAddress">
        <template #content>
          <addresses-details
            :value="{
              fulfillmentId: props.id,
              orderId: props.orderId
            }"
            @close="closeDrawerAddress" />
        </template>
      </drawer>

      <div
        v-if="!shipmentsLoading && shipments.length"
        class="px-10 py-6 flex flex-row justify-between">
        <div class="w-1/2">
          <div class="mb-6">{{ shipments.length }} {{ t('ORDER_DETAILS.SHIPMENTS_COUNT_FIELD') }}</div>
          <div
            v-for="(shipment, index) in shipments"
            :key="shipment.id"
            class="bg-white rounded shadow mr-2">
            <shipment-card
              :class="{ 'mb-4': index !== shipments.length - 1 }"
              :value="
                formatShipment({
                  ...shipment,
                  carrier: getCarrierNameByShipment(shipment, store),
                  service: getServiceTypeNameBySlugAndCarrier(shipment.service, shipment.carrier),
                  items: getProductsByShipmentId(shipment.id)
                })
              " />
          </div>
        </div>
        <modal-shipping-documents
          :documents="documents"
          @print="printDocument"
          @print-all="printAllDocuments">
          <template #label>
            <span
              data-id="documents-button"
              class="text-base text-blue-400 font-semibold cursor-pointer">
              <icon
                icon-name="link"
                class="text-xl" />
              {{ t('FULFILLMENT_DETAILS.DOCUMENTS_LABEL') }}
            </span>
          </template>
        </modal-shipping-documents>
      </div>

      <div class="py-10 flex">
        <div class="w-1/2 px-10 border-r border-gray-200">
          <div class="mb-6 flex justify-between items-center">
            <span v-if="products.length">{{ products.length }} {{ t('FULFILLMENT_DETAILS.ITEMS_TO_PACK') }}</span>
            <span v-else>
              {{ t('FULFILLMENT_DETAILS.ALL_PACKED_ITEMS') }}
              <icon
                icon-name="selected"
                class="ml-0.5 text-xl text-green-500" />
            </span>
            <b-button
              :disabled="isCustomService || !products.length || !packages.length"
              :loading="packAllLoading"
              class="h-8 py-0 px-4 bg-blue-50 text-blue-400 text-base border-none font-semibold"
              data-id="packAll"
              @click="packAll">
              {{ t('FULFILLMENT_DETAILS.PACK_ALL_BUTTON') }}
            </b-button>
          </div>
          <div class="h-4" />
          <!--        <dropdown-->
          <!--          button-classes="w-full py-0 focus:ring-0 focus:ring-offset-0"-->
          <!--          menu-classes="px-3 py-2"-->
          <!--          :options="specialServiceOptions"-->
          <!--          @select="selectSpecialService">-->
          <!--          <template #default>-->
          <!--            {{ t('FULFILLMENT_DETAILS.SPECIAL_SERVICE_DROPDOWN') }}-->
          <!--          </template>-->
          <!--          <template #option="{ item }">-->
          <!--            <div>-->
          <!--              <checkbox-->
          <!--                class="leading-8"-->
          <!--                :value="state.specialServices.includes(item.value)">-->
          <!--                <template #label>-->
          <!--                  <span class="ml-1">-->
          <!--                    {{ item.label }}-->
          <!--                  </span>-->
          <!--                </template>-->
          <!--              </checkbox>-->
          <!--            </div>-->
          <!--          </template>-->
          <!--        </dropdown>-->
          <b-table
            id="productsTable"
            :loading="productsLoading"
            :fields="packItemsFields"
            :data="products"
            row-key="id"
            total-label=""
            :sticky="false">
            <template #cell(name)="{ row, rowIndex }">
              <span></span>
              <modal-product-details
                v-if="isMounted"
                :disabled="isCustomService"
                :value="row.product"
                :index="rowIndex"
                :required="isInternational"
                class="w-36"
                @update:value="updateProductDetails(row.product.id, $event)" />
              <div
                v-if="row?.product.sku"
                class="text-gray-600">
                {{ row.product.sku }}
              </div>
              <special-services :services="row?.product?.special_services"></special-services>
            </template>
            <template #cell(quantity-to-pack)="{ row }">
              <counter
                :disabled="isCustomService"
                :value="row.pack_count"
                :min="1"
                :max="row.count"
                @update:value="updateProductPackCount(row, $event)" />
            </template>
            <template #cell(total_weight)="{ row }">
              {{ formatProductWeight(row) }}
            </template>
            <template #cell(dimensions)="{ row }">
              {{ formatItemDimensions(row.product) }}
            </template>
            <template #cell(actions)="{ rowIndex }">
              <div class="w-20 text-center">
                <b-button
                  :disabled="isCustomService || !products.length || !packages.length"
                  class="h-6 p-0 bg-transparent text-blue-400 text-sm font-semibold"
                  :loading="packLoading"
                  :class="{ 'cursor-not-allowed': !packages.length }"
                  data-id="pack"
                  @click="pack(rowIndex)">
                  {{ t('FULFILLMENT_DETAILS.PACK_BUTTON') }}
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
        <div class="w-1/2 px-10">
          <div class="mb-6 flex">
            {{ t('FULFILLMENT_DETAILS.PACKAGES_COUNT_FIELD') }} ({{ packages.length }})
            <div
              v-if="hasSmartPack"
              class="ml-3 flex items-center gap-1">
              <div class="w-2 h-2 bg-green-400 rounded-full" />
              <span class="text-2xs">
                {{ t('FULFILLMENT_DETAILS.SMART_PACK_BUTTON') }}
              </span>
              <tooltip
                placement="top"
                :hover="false">
                <template #content>
                  <icon
                    data-id="smart-pack-info"
                    icon-name="info"
                    class="pt-0.5 text-base text-gray-600 cursor-pointer" />
                </template>
                <template #title>
                  <span class="font-semibold text-xm">
                    <i18next :translation="t('FULFILLMENT_DETAILS.SMART_PACK_INFO_LABEL')">
                      <template #path>
                        <router-link
                          data-id="smart-pack-tooltip-button"
                          class="text-blue-400"
                          :to="{ name: 'packaging' }">
                          {{ t('FULFILLMENT_DETAILS.PACKAGE_SETTINGS_PATH') }}
                        </router-link>
                      </template>
                    </i18next>
                  </span>
                </template>
              </tooltip>
            </div>
          </div>
          <div class="flex gap-1">
            <b-button
              :disabled="isCustomService || !hasSmartPack"
              class="h-6 border border-gray-300 shadow-sm px-4 py-0 bg-white font-medium text-gray-700 hover:bg-gray-50"
              data-id="smartPack"
              @click="clickSmartButton">
              {{
                t(
                  hasSmartPack
                    ? !packedProductCount
                      ? 'FULFILLMENT_DETAILS.SMART_PACK_BUTTON'
                      : 'FULFILLMENT_DETAILS.UNDO_SMART_PACK_BUTTON'
                    : 'FULFILLMENT_DETAILS.SMART_PACK_BUTTON'
                )
              }}
            </b-button>
          </div>
          <b-table
            id="packagesTable"
            table-container-class="border border-b-0 border-gray-200"
            :table-class="{ 'mb-32': packages.length }"
            :fields="packagesFields"
            :data="packages"
            :sticky="false"
            total-label=""
            :cell-class-function="getPackageRowClass"
            default-expand
            @click-row="selectPackage">
            <template #cell(name)="{ row, rowIndex, columnIndex }">
              <template v-if="row && row.children && columnIndex === 0">
                <span class="mr-2 font-semibold">{{ rowIndex + 1 }}.</span>
                <icon
                  class="text-base mr-2 cursor-pointer"
                  :icon-name="row.opened ? 'chevron-up' : 'chevron'"
                  @click="row.opened = !row.opened" />
              </template>
              <span :class="{ 'ml-11': columnIndex === 0 && !row.children, 'text-blue-400': !row.children }">
                <template v-if="row.children">
                  {{ availablePackages.get(row.id).name }}
                </template>
                <template v-else>
                  {{ row.product.name }}
                  <div
                    v-if="row?.product.sku"
                    class="text-gray-600 ml-11">
                    {{ row.product.sku }}
                  </div>
                  <div class="ml-10">
                    <special-services :services="row?.product?.special_services"></special-services>
                  </div>
                </template>
              </span>
            </template>
            <template #cell(price)="{ row }">
              <div
                v-if="!row.children"
                class="flex items-center gap-2">
                <template v-if="row.product && row.product.price">
                  {{ formatPackageDeclaredValue(row.product) }}
                </template>
                <!--              <b-input-->
                <!--                :value="rowIndex"-->
                <!--                input-class="w-14 h-8 sm:text-xm" />-->
              </div>
            </template>
            <template #cell(total_weight)="{ row }">
              <div
                v-if="row.children"
                class="flex items-center gap-2">
                <template v-if="isFulfillmentWithNoProductWeight">
                  <b-input
                    decimal
                    type="number"
                    :value="formatPackageWeight(row)"
                    input-class="w-14 h-8 sm:text-xm appearance-none"
                    @update:value="updatePackageWeight(row, $event)" />
                </template>
                <span v-else>
                  {{ calculatePackageTotalWeight(row) }}
                </span>
                <span>kg</span>
              </div>
              <template v-else>
                {{ formatProductWeight(row) }}
              </template>
            </template>
            <template #cell(count)="{ row }">
              <template v-if="row.children">
                {{ calculatePackageItemCount(row.children) || '' }}
              </template>
              <template v-else>x {{ row.count }}</template>
            </template>
            <template #cell(actions)="{ row, rowIndex }">
              <dropdown
                :options="row.children ? packageActionOptions : packedProductActionOptions"
                button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
                :left-align="false"
                @select="
                  handleAction(
                    $event,
                    row.children
                      ? { fulfillmentPackageIndex: rowIndex }
                      : { fulfillmentPackageIndex: rowIndex[0], productIndex: rowIndex[1] }
                  )
                ">
                <template #button>
                  <icon
                    class="mr-3 text-xl"
                    icon-name="menu-horizontal" />
                </template>
              </dropdown>
            </template>
            <template #footer-container>
              <div
                class="flex gap-1 align-middle pl-4 py-3 rounded-b-lg border border-t-0 border-gray-200 table-bottom-shadow">
                <dropdown
                  data-id="add-package"
                  button-classes="px-2 py-0 bg-blue-400 text-white hover:bg-blue-500 border
                focus:ring-0 focus:ring-offset-0"
                  menu-classes="w-96"
                  :options="packageOptions"
                  :disabled="isCustomService"
                  @select="addPackage">
                  <template #default>
                    {{ t('FULFILLMENT_DETAILS.PACKAGE_DROPDOWN') }}
                  </template>
                  <template #option="{ item, active }">
                    <a
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center gap-1 px-4 py-2 text-sm'
                      ]">
                      <b>{{ item.label.name }}</b>
                      <span class="inline">{{ formatPackageDimensions(item.label) }}</span>
                    </a>
                  </template>
                </dropdown>

                <tooltip
                  placement="top"
                  :hover="false">
                  <template #content>
                    <icon
                      icon-name="info"
                      class="pt-0.5 text-xl text-gray-600 cursor-pointer" />
                  </template>
                  <template #title>
                    <span class="font-semibold text-xm">
                      <i18next :translation="t('FULFILLMENT_DETAILS.NEW_PACKAGE_INFO_LABEL')">
                        <template #path>
                          <router-link
                            class="text-blue-400"
                            :to="{ name: 'packaging' }">
                            {{ t('FULFILLMENT_DETAILS.PACKAGE_SETTINGS_PATH') }}
                          </router-link>
                        </template>
                      </i18next>
                    </span>
                  </template>
                </tooltip>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <footer class="sticky bottom-0 inset-x-0 px-10 py-2 flex justify-between bg-white">
      <b-button
        class="px-6 border-none bg-transparent text-blue-400 text-base"
        :disabled="!isPackagesChanged"
        :loading="discardingLoading"
        data-id="fulfillment-discard"
        @click="discard">
        {{ t('DISCARD_BUTTON') }}
      </b-button>
      <span>
        <fulfillment-carrier-modal
          data-id="fulfillmentCreateShipment"
          :order-id="props.orderId"
          :fulfillment-id="props.id"
          @open-loader="openLoader"
          @shipment-creation-end="handleShipmentCreationEnd"
          @close-loader="closeLoader"
          @update-fulfillment="updateFulfillmentDetails" />
      </span>
    </footer>
  </div>
  <popup-carrier-booked
    :is-shown="state.showPopup"
    :name="carrierBookedName"
    :shipment="shipmentDetails"
    :pudo="pudo"
    @close="handleClosedPopup"></popup-carrier-booked>
</template>

<script>
export default {
  name: 'FulfillmentDetails'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineProps, nextTick, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Alert from '@/components/atoms/Alert';
import Badge from '@/components/atoms/Badge';
import BButton from '@/components/atoms/Button';
import Counter from '@/components/atoms/Counter';
import Icon from '@/components/atoms/Icon';
import BInput from '@/components/atoms/Input';
import SpecialServices from '@/components/atoms/SpecialServices';
import Spinner from '@/components/atoms/Spinner';
import Tooltip from '@/components/atoms/ToolTip';
import AddressesDetails from '@/components/molecules/AddressesDetails/index.vue';
import DetailsInfoLine from '@/components/molecules/DetailsInfoLine';
import Dropdown from '@/components/molecules/Dropdown';
import Notes from '@/components/molecules/Notes';
import ShipmentCard from '@/components/molecules/ShipmentCard';
import Drawer from '@/components/organisms/Drawer';
import FulfillmentCarrierModal from '@/components/organisms/ModalFulfillmentCarrier';
import ModalProductDetails from '@/components/organisms/ModalProductDetails';
import ModalShippingDocuments from '@/components/organisms/ModalShippingDocuments';
import PopupCarrierBooked from '@/components/organisms/PopupCarrierBooked';
import BTable from '@/components/organisms/Table';
import { useShippingDocumentsModal } from '@/composables';
import { statusBadgeTypeMapping } from '@/enums';
import {
  calculateProductsTotalWeight,
  checkIfIsInternational,
  convertWeightFromKg,
  convertWeightToKg,
  formatAddress,
  formatDeliverByColumn,
  formatItemDimensions,
  formatPackageDimensions,
  formatShipment,
  getCarrierNameByShipment,
  round
} from '@/utils';

import { packageActionOptions, packagesFields, packedProductActionOptions, packItemsFields } from './constants';

const store = useStore();
const { t } = useTranslation();
const router = useRouter();

const isMounted = ref(false);

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  orderId: {
    type: String,
    required: true
  }
});

const state = reactive({
  selectedPackageIndex: null,
  showDrawer: false,
  showDrawerAddress: false,
  showPopup: false,
  selectedCarrier: null
});

const loadInitialData = async (loadOptions) => {
  await deleteAllFulfillmentPackages();

  await Promise.all(loadOptions);

  await loadShipmentProductsByFulfillmentId();

  await nextTick();
  if (hasSmartPack.value && products.value.length) {
    await autoPack();
  }
};

onMounted(async () => {
  store.dispatch('shippingAccounts/resetShippingAccountsByRule');

  await loadInitialData([
    loadPackages(),
    loadFulfillmentDetails(),
    loadFulfillments(),
    loadFulfillmentProducts(),
    loadFulfillmentShipments(),
    loadShippingAccounts(),
    loadOrderDetails(),
    loadUserSettings()
  ]);
  isMounted.value = true;
});

const drawerTop = 109;

const isCustomService = computed(() => store.getters['auth/isCustomService']);
const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);
const availablePackages = computed(() => store.state.packages.items);
const packages = computed(() => store.getters['packages/getItemsByFulfillmentId'](props.id));
const shipments = computed(() => store.getters['shipments/getItemsByFulfillmentId'](props.id));
const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);
const products = computed(() => store.getters['products/getItemsByFulfillmentId'](props.id));
const orderDetails = computed(() => store.state.orders.itemDetails);
const hasSmartPack = computed(() => store.state.auth.accountSettings.autopack);
const validatePack = computed(() => store.state.auth.accountSettings.validate_packing);
const isFulfillmentWithNoProductWeight = computed(() => {
  const availableProducts = products.value || [];
  const availablePackedProducts = packages.value.reduce((result, item) => result.concat(item.products), []);

  if (!availableProducts.length && !availablePackedProducts.length) {
    return false;
  }

  return [...availableProducts, ...availablePackedProducts].every(
    (product) => product.product.weight === null || product.product.weight === 0
  );
});

const error = computed(
  () =>
    store.state.fulfillments.error ||
    store.state.products.error ||
    store.state.packages.error ||
    store.state.shipments.error ||
    store.state.shippingAccounts.error
);

const productsLoading = computed(() => store.state.products.loading);
const shipmentsLoading = computed(() => store.state.shipments.loading);
const packAllLoading = computed(() => store.state.products.packAllLoading);
const packLoading = computed(() => store.state.products.packLoading);

const packageOptions = computed(() =>
  Array.from(availablePackages.value.values()).map((item) => ({
    value: item.id,
    label: item
  }))
);

const packedProductCount = computed(() => {
  return packages.value.reduce((result, item) => result + (item.children || []).length, 0);
});

const billingAddress = computed(() => formatAddress(fulfillmentDetails.value.recipient_address));

const infoLineData = computed(() => [
  {
    label: 'SHIPMENT_DETAILS.ORDER',
    value: orderDetails.value.source_id ?? orderDetails.value.id,
    href: router.resolve({ name: 'order-details', params: { id: orderDetails.value.id } }).href
  },
  { label: 'FULFILLMENT_DETAILS.PACKAGES_COUNT_FIELD', value: 0 },
  { label: 'FULFILLMENT_DETAILS.ITEM_COUNT_FIELD', value: fulfillmentDetails.value.items_count },
  { label: 'FULFILLMENT_DETAILS.PROMISED_TIME_FIELD', value: formatDeliverByColumn(fulfillmentDetails.value) },
  { label: 'FULFILLMENT_DETAILS.ADDRESS_FIELD', value: billingAddress.value }
  // { label: 'FULFILLMENT_DETAILS.SHIPPING_METHOD_FIELD', value: 'Expedited ($21.00)' }
]);

const fulfillmentParams = computed(() => ({
  orderId: props.orderId,
  fulfillmentId: props.id
}));

const isInternational = computed(() => checkIfIsInternational(fulfillmentDetails));

const { documents, printDocument, printAllDocuments } = useShippingDocumentsModal(shipments, store);

const loadFulfillmentDetails = async () =>
  await store.dispatch('fulfillments/loadFulfillmentById', fulfillmentParams.value);
const loadFulfillments = async () => await store.dispatch('fulfillments/loadOrderFulfillments', props.orderId);

const loadOrderDetails = async () => await store.dispatch('orders/loadOrdersById', props.orderId);
const loadUserSettings = async () => await store.dispatch('users/loadUserSettings');
const loadPackages = async () => await store.dispatch('packages/loadPackages');
const loadFulfillmentProducts = async () =>
  await store.dispatch('products/loadFulfillmentProducts', fulfillmentParams.value);
const loadFulfillmentShipments = async () =>
  await store.dispatch('shipments/loadFulfillmentShipments', fulfillmentParams.value);
const loadShipmentProductsByFulfillmentId = async () =>
  await store.dispatch('products/loadShipmentProductsByFulfillmentId', fulfillmentParams.value);
const loadShippingAccounts = async () => await store.dispatch('shippingAccounts/loadShippingAccounts');
const clearError = () => {
  store.commit('fulfillments/clearError');
  store.commit('packages/clearError');
  store.commit('products/clearError');
  store.commit('shipments/clearError');
  store.commit('shippingAccounts/clearError');
};

const getProductsByShipmentId = (id) => store.getters['products/getItemsByShipmentId'](id);
const getServiceTypeNameBySlugAndCarrier = (service, carrier) =>
  store.getters['carriers/getServiceTypeNameBySlugAndCarrier'](service, carrier);

const updateProductDetails = async (productId, data) =>
  await store.dispatch('products/updateProductDetails', {
    ...fulfillmentParams.value,
    productId,
    data
  });

const updateProductPackCount = async (product, count) => {
  store.commit('products/updateProductById', {
    fulfillmentId: props.id,
    productId: product.product.id,
    value: { pack_count: count }
  });
};

const addPackage = async (packageId) => {
  await store.dispatch('packages/createFulfillmentPackage', {
    ...fulfillmentParams.value,
    value: availablePackages.value.get(packageId)
  });
  selectPackage(packages.value.length - 1);
};

const updateFulfillmentDetails = async () => {
  isMounted.value = false;

  await loadInitialData([
    loadFulfillmentShipments(),
    loadFulfillmentDetails(),
    loadPackages(),
    loadFulfillmentProducts()
  ]);

  isMounted.value = true;
};

const deleteAllFulfillmentPackages = async () =>
  await store.dispatch('packages/deleteAllFulfillmentPackages', props.id);

const isPackagesChanged = computed(() => !!packages.value.length);
const discardingLoading = ref(false);
const discard = async () => {
  discardingLoading.value = true;

  await deleteAllFulfillmentPackages();

  discardingLoading.value = false;
};

const formatProductWeight = (item) => {
  const count = item.count;
  const product = item.product || item;

  return product.weight ? `${round(convertWeightToKg(product.weight, product.weight_units) * count)} kg` : 'n/a';
};

const formatPackageDeclaredValue = (item) => {
  const { price } = item;
  const currency = orderDetails.value.currency || 'USD';

  return `${(price / 100).toFixed(2)} ${currency}`;
};

const calculatePackageItemCount = (items) =>
  (items || []).reduce((partialSum, value) => partialSum + (value.count || 0), 0);

const calculatePackageWeight = (row, unit) => {
  unit = unit || 'kg';

  const _package = Array.from(availablePackages.value.values()).find(
    (availablePackage) => availablePackage.id === row.id
  );

  return convertWeightToKg(_package.weight, _package.weight_units || unit);
};

const calculatePackageTotalWeight = (row) => {
  const unit = 'kg';
  const productsResultWeight = calculateProductsTotalWeight({ products: row.children || [], weightUnit: unit });
  const packageWeight = calculatePackageWeight(row, unit);

  return round(packageWeight + productsResultWeight);
};

const pack = async (index) => {
  if (!packages.value.length) {
    return;
  }
  clearError();

  const packageItem =
    packages.value[state.selectedPackageIndex === null ? packages.value.length - 1 : state.selectedPackageIndex];

  await store.dispatch('products/packFulfillmentProduct', {
    packageItem,
    product: products.value[index],
    validation: Boolean(validatePack.value)
  });

  await store.commit('shipments/setShipmentDetails', {});
};

const packAll = async () => {
  if (!packages.value.length) {
    return;
  }
  clearError();

  const packageIndex = packages.value.length - 1;
  const fulfillmentPackage = { ...packages.value[packageIndex], id: packageIndex };
  // to do: refactor this code
  const packageItem =
    packages.value[state.selectedPackageIndex === null ? packages.value.length - 1 : state.selectedPackageIndex];

  await store.dispatch('products/packAllFulfillmentProducts', {
    fulfillmentId: props.id,
    products: products.value,
    packages: [fulfillmentPackage],
    validation: Boolean(validatePack.value),
    packageItem
  });

  await store.commit('shipments/setShipmentDetails', {});
};

const autoPack = async () => {
  await store.dispatch('products/autoPack', {
    ...fulfillmentParams.value,
    products: products.value,
    packages: store.getters['packages/items']
  });
};

const unpackAll = async () => {
  await deleteAllFulfillmentPackages();
};

const removePackage = async (value) => {
  await store.dispatch('packages/deleteFulfillmentPackage', {
    ...fulfillmentParams.value,
    value
  });

  state.selectedPackageIndex = null;
};

const unpackProduct = async (fulfillmentPackageIndex, productIndex) => {
  const packageItem = packages.value[fulfillmentPackageIndex];

  await store.dispatch('products/unpackFulfillmentProduct', {
    packageItem,
    productIndex
  });
};

const formatPackageWeight = (row) => convertWeightToKg(row.weight, row.weight_units || 'kg');
const updatePackageWeight = (row, value) => (row.weight = convertWeightFromKg(value, row.weight_units || 'kg'));

const handleAction = async (action, { fulfillmentPackageIndex, productIndex }) => {
  if (action === 'unpack') {
    await unpackProduct(fulfillmentPackageIndex, productIndex);
  } else if (action === 'move') {
    //
  } else if (action === 'remove-package') {
    await removePackage(fulfillmentPackageIndex);
  } else if (action === 'change-package-size') {
    //
  } else if (action === 'print-package-slip') {
    //
  }
};

const clickSmartButton = async () => {
  if (hasSmartPack.value && products.value.length) {
    await autoPack();
  } else {
    await unpackAll();
  }
};
const selectPackage = (value) => {
  state.selectedPackageIndex = Array.isArray(value) ? value[0] : value;
};

const getPackageRowClass = (value) => {
  const [rowIndex, subRowIndex, columnIndex] = value;
  const isSelected = rowIndex === state.selectedPackageIndex;
  const isPackage = subRowIndex === null;
  const isFirstColumn = columnIndex === 0;

  return isSelected && isPackage && isFirstColumn ? 'border-l-4 border-blue-400' : '';
};

const openDrawer = () => {
  state.showDrawer = true;
};

const closeDrawer = () => {
  state.showDrawer = false;
};
const openDrawerAddress = () => {
  state.showDrawerAddress = true;
};
const closeDrawerAddress = () => {
  state.showDrawerAddress = false;
};

const commitNotesChanges = async (notes) => {
  await store.dispatch('orders/updateOrder', { orderId: props.orderId, data: { notes } });
};

const openLoader = () => {
  isMounted.value = false;
};

const routeToFulfillments = async () => router.push({ name: 'fulfillments' });

const handleShipmentCreationEnd = async ({ disableShippingRules, selectedCarrier, isCarrierBooked }) => {
  if (!disableShippingRules && isCarrierBooked) {
    showPopup(selectedCarrier);

    return;
  }

  if (disableShippingRules) {
    await routeToFulfillments();

    return;
  }

  isMounted.value = true;
};

const closeLoader = () => {
  isMounted.value = true;
};

const carrierBookedName = computed(() => {
  if (!state.selectedCarrier?.id || !state.selectedCarrier.service) {
    return '';
  }
  const carrierName = store.getters['shippingAccounts/getCarrierNameByShippingAccountId'](state.selectedCarrier.id);
  const service = store.getters['carriers/getServiceTypeNameBySlugAndCarrier'](
    state.selectedCarrier.service,
    state.selectedCarrier.carrier
  );

  return `${carrierName} - ${service}`;
});

const pudo = computed(() => fulfillmentDetails.value?.pudo);

const showPopup = (selectedCarrier) => {
  state.selectedCarrier = selectedCarrier;
  state.showPopup = true;
  isMounted.value = true;
};

const handleClosedPopup = async () => {
  isMounted.value = false;
  state.showPopup = false;

  await routeToFulfillments();
};
</script>

<style scoped>
.shadow {
  box-shadow:
    0 0 1px rgba(0, 51, 80, 0.24),
    0 1px 2px rgba(0, 51, 88, 0.16);
}
</style>
