<template>
  <div
    class="rule-wrapper rule-shadow bg-white rounded p-4 border-t-2 border-transparent"
    :class="{
      'hover:border-blue-400': form.enabled
    }">
    <v-form>
      <div class="flex justify-between items-center">
        <div
          class="flex items-center"
          :class="{
            'opacity-30 pointer-events-none': !form.enabled
          }">
          <b-icon
            class="text-gray-800 px-1 mr-1 cursor-pointer rule-drag"
            :icon-name="BringgFontIcons.Drag" />

          <div class="w-8 h-8 bg-blue-50 text-blue-400 flex items-center justify-center rounded-full mr-2">
            {{ index + 1 }}
          </div>
          <b-editable v-model="form.name" />
        </div>

        <div class="flex items-center">
          <Tooltip
            v-show="locationsAreAbsent"
            placement="topLeft">
            <template #content>
              <b-icon
                v-show="locationsAreAbsent"
                :icon-name="BringgFontIcons.Warning"
                class="mt-0.5 text-lg text-danger-900 mr-2" />
            </template>
            <template #title>
              <span class="font-semibold text-sm">
                {{ t('SHIPPING_RULES.ABSENT_LOCATIONS_TOOLTIP_WARNING') }}
              </span>
            </template>
          </Tooltip>

          <b-switch
            v-model:value="form.enabled"
            :data-id="'enabled_rule'" />

          <b-dropdown
            data-id="actions"
            value="two"
            button-classes="mx-4 border-none shadow-none p-0 w-auto"
            :left-align="false"
            :options="actionOptions"
            @select="onSettings">
            <template #button>
              <b-icon
                class="text-xl text-gray-600"
                :icon-name="BringgFontIcons.Menu" />
            </template>
          </b-dropdown>

          <b-button
            class="bg-transparent p-0 h-auto"
            data-id="open_close">
            <b-icon
              class="text-xl text-gray-600"
              :icon-name="state.isOpen ? BringgFontIcons.ChevronUp : BringgFontIcons.Chevron"
              @click="state.isOpen = !state.isOpen" />
          </b-button>
        </div>
      </div>

      <div
        v-show="state.isOpen"
        class="flex mt-4"
        :class="{
          'opacity-30 pointer-events-none': !form.enabled
        }">
        <div class="w-7/12 border-r pr-8">
          <h3 class="text-gray-800 font-semibold">{{ t('SHIPPING_RULES.TITLE_1') }}</h3>
          <p class="text-gray-600 text-sm mt-2">{{ t('SHIPPING_RULES.SUBTITLE_1') }}</p>

          <div
            v-if="rule.type === RULE_TYPE.SHIPPING"
            class="hover:bg-gray-100 -ml-4 -mr-4 pl-4 pr-4 group">
            <div class="h-20 flex items-center border-b">
              <span class="w-40 flex-shrink-0 text-gray-800 text-sm">
                {{ t('FILTER.LOCATIONS.NAME') }}
                <span class="text-red-600 ml-1">*</span>
              </span>

              <b-multi-select
                data-id="select_locations"
                with-search
                :allow-empty="false"
                :value="form.locations"
                :options="locationOptions"
                :all-placeholder-name="t('FILTER.LOCATIONS.PLACEHOLDER_NAME')"
                :placeholder-text="t('FILTER.LOCATIONS.PLACEHOLDER_TEXT')"
                absent-data-warning="SHIPPING_RULES.ABSENT_LOCATIONS_MULTI_SELECT_WARNING"
                @select="onAddLocations" />
            </div>
          </div>

          <div
            v-for="attribute in form.attributes"
            :key="attribute.path"
            class="hover:bg-gray-100 -ml-4 -mr-4 pl-4 pr-4 group">
            <div class="h-20 flex items-center border-b">
              <span class="w-40 flex-shrink-0 text-gray-800 text-sm">
                {{ t(`SHIPPING_RULES.TRIGGERS.${attribute.path}`) }}
              </span>
              <div class="flex w-full">
                <div class="w-6/12 pr-2 max-w-xs">
                  <b-select
                    :id="`${attribute.path}-operator`"
                    v-model:value="attribute.operator"
                    :data-id="`${attribute.path}_OPERATOR`"
                    :options="getOperatorsOptions(attribute.path)"
                    :searchable="false"
                    size="small"
                    :placeholder="t('SHIPPING_RULES.SELECT_OPERATOR_PLACEHOLDER')" />
                </div>

                <div
                  :class="[
                    'pl-2 w-6/12 max-w-xs',
                    {
                      'flex items-center': TRIGGERS[attribute.path].type === 'NUMBER'
                    }
                  ]">
                  <template v-if="attribute.operator && TRIGGERS[attribute.path].type === 'ARRAY'">
                    <b-multi-select
                      :data-id="`SELECT_${attribute.path}`"
                      :with-search="true"
                      :with-all="false"
                      :value="attribute.value"
                      :options="TRIGGERS[attribute.path].values"
                      :placeholder-text="t(`SHIPPING_RULES.TRIGGERS_PLACEHOLDER.${attribute.path}`)"
                      @select="(value) => (attribute.value = value)" />
                  </template>

                  <template v-if="attribute.operator && TRIGGERS[attribute.path].type === 'NUMBER'">
                    <div class="w-6/12">
                      <b-input
                        :id="`${attribute.path}-value-0`"
                        v-model:value="attribute.value[0]"
                        :data-id="`${attribute.path}_VALUE_0`"
                        :min="0"
                        :max="
                          attribute.operator === 'IN_RANGE' && attribute.value[1] ? attribute.value[1] - 1 : undefined
                        "
                        size="small"
                        type="number"
                        :placeholder="t(`SHIPPING_RULES.TRIGGERS_PLACEHOLDER.${attribute.path}`)" />
                    </div>

                    <div class="px-1">
                      <b-icon
                        :class="['text-gray-600 text-sm', { 'opacity-0': attribute.operator !== 'IN_RANGE' }]"
                        class="text-gray-600 text-sm"
                        :icon-name="BringgFontIcons.Minus" />
                    </div>

                    <div class="w-6/12">
                      <b-input
                        v-if="attribute.operator === 'IN_RANGE'"
                        :id="`${attribute.path}-value-1`"
                        v-model:value="attribute.value[1]"
                        :data-id="`${attribute.path}_VALUE_1`"
                        :min="attribute.value[0] ? attribute.value[0] + 1 : 1"
                        size="small"
                        type="number"
                        :placeholder="t(`SHIPPING_RULES.TRIGGERS_PLACEHOLDER.${attribute.path}`)" />
                    </div>
                  </template>

                  <template v-if="attribute.operator && TRIGGERS[attribute.path].type === 'STRING'">
                    <b-input
                      :id="`${attribute.path}-value`"
                      v-model:value="attribute.value[0]"
                      :data-id="`${attribute.path}_VALUE`"
                      size="small"
                      :placeholder="t(`SHIPPING_RULES.TRIGGERS_PLACEHOLDER.${attribute.path}`)" />
                  </template>
                </div>

                <div
                  v-if="rule.type === RULE_TYPE.SHIPPING || form.attributes.length > 1"
                  class="flex opacity-0 h-full items-center group-hover:opacity-100">
                  <b-button
                    data-id="delete_attribute"
                    class="bg-transparent text-gray-600 h-8"
                    @click="onDeleteAttribute(attribute)">
                    <b-icon :icon-name="BringgFontIcons.Trash" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6 flex items-center">
            <select-rule-attributes
              id="attribute"
              data-id="rule_attribute"
              :value="attributesValues"
              multiple
              :options="attributesOptions"
              @update:value="onAddAttribute">
              <template #buttonText>{{ t('SHIPPING_RULES.ATTRIBUTE') }}</template>
              <template #submitText>{{ t('DONE') }}</template>
            </select-rule-attributes>
          </div>
        </div>
        <div class="w-5/12 pl-8">
          <h3 class="text-gray-800 font-semibold">{{ t('SHIPPING_RULES.TITLE_2') }}</h3>

          <div class="mt-5">
            <p class="text-gray-700 text-sm mb-2">{{ t('SHIPPING_RULES.SUBTITLE_2') }}</p>

            <b-multi-select
              data-id="select_services"
              :disabled="rule.type === RULE_TYPE.RETURN && !form.attributes.length"
              :with-search="true"
              :with-all="false"
              with-tooltip
              :value="form.services"
              :options="serviceOptions"
              :placeholder-text="t('SELECT_PLACEHOLDER')"
              @select="(value) => (form.services = value)" />
          </div>

          <div class="mt-8">
            <p class="text-gray-700 text-sm mb-2">{{ t('SHIPPING_RULES.SUBTITLE_3') }}</p>
            <div class="flex text-sm text-gray-600 h-8 mb-3">
              <label
                :title="t('SHIPPING_RULES.PRIORITY')"
                :class="[
                  'flex items-center mr-10',
                  {
                    'cursor-not-allowed opacity-50': !form.services.length
                  }
                ]">
                <input
                  v-model="form.cheapest"
                  data-id="priority"
                  :disabled="!form.services.length"
                  type="radio"
                  name="cheapest"
                  :value="false"
                  class="cursor-pointer text-blue-400 mr-2" />
                <span class="leading-none">{{ t('SHIPPING_RULES.PRIORITY') }}</span>
              </label>
              <label
                :title="t('SHIPPING_RULES.CHEAPEST')"
                :class="[
                  'flex items-center',
                  {
                    'cursor-not-allowed opacity-50': !form.services.length
                  }
                ]">
                <input
                  v-model="form.cheapest"
                  data-id="cheapest"
                  :disabled="!form.services.length"
                  type="radio"
                  name="cheapest"
                  :value="true"
                  class="mr-1 cursor-pointer text-blue-400 mr-2" />
                <span class="leading-none">{{ t('SHIPPING_RULES.CHEAPEST') }}</span>
              </label>
            </div>
            <draggable
              v-model="services"
              item-key="name"
              handle=".service-drag"
              :disabled="form.cheapest"
              class="rule-shadow rounded mb-5">
              <template #item="{ element, index: serviceIndex }">
                <div
                  v-if="rule.type === RULE_TYPE.SHIPPING || form.attributes.length"
                  class="h-8 px-2 bg-white text-gray-600 text-sm border-b last:border-b-0 whitespace-nowrap">
                  <div
                    class="flex items-center h-8"
                    :title="getServiceName(element)">
                    <b-icon
                      v-show="!form.cheapest"
                      class="text-gray-600 pr-2 cursor-pointer service-drag"
                      :icon-name="BringgFontIcons.Drag" />
                    <span
                      v-if="!form.cheapest"
                      class="overflow-hidden overflow-ellipsis">
                      {{ serviceIndex + 1 }}. {{ getServiceName(element) }}
                    </span>
                    <span
                      v-else
                      class="overflow-hidden overflow-ellipsis">
                      {{ getServiceName(element) }}
                    </span>
                    <Tooltip
                      v-if="!getRatesSupport(element)"
                      class="ml-auto"
                      placement="topLeft">
                      <template #content>
                        <b-icon
                          :icon-name="BringgFontIcons.Info"
                          class="text-m text-grey-400" />
                      </template>
                      <template #title>
                        <span class="font-semibold text-sm">
                          {{ t('SHIPPING_RULES.RATES_SUPPORT_INFO_TEXT') }}
                        </span>
                      </template>
                    </Tooltip>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </v-form>

    <b-modal
      :value="state.isLocationModalOpen"
      @close="onDiscardLocationAdd">
      <template #title>
        <h3 class="px-6 py-4 text-xl leading-6 font-medium text-gray-800">{{ t('SHIPPING_RULES.UPDATED') }}</h3>
      </template>

      <template #body>
        <div class="px-6 -mt-2">
          <p class="text-sm text-gray-700">
            {{ t('SHIPPING_RULES.CHANGE') }}
          </p>
        </div>
      </template>

      <template #footer>
        <div class="flex mt-3 flex-row-reverse px-6 py-6">
          <b-button
            data-id="discard"
            class="px-6"
            @click="onDiscardLocationAdd">
            {{ t('DISCARD_BUTTON') }}
          </b-button>

          <b-button
            data-id="submit"
            class="px-6 mr-4"
            type="secondary"
            @click="onSubmitLocationAdd">
            {{ t('UNSAVED_CHANGES_MODAL.SAVE_CHANGES') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Rule'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { debounce, intersection } from 'lodash';
import { Form as VForm } from 'vee-validate';
import { computed, defineProps, nextTick, reactive, watch } from 'vue';
import Draggable from 'vuedraggable';
import { useStore } from 'vuex';

import BSelect from '@/components/atoms/BSelect';
import BButton from '@/components/atoms/Button';
import BIcon from '@/components/atoms/Icon';
import BInput from '@/components/atoms/Input';
import BSwitch from '@/components/atoms/Switch';
import Tooltip from '@/components/atoms/ToolTip';
import BDropdown from '@/components/molecules/Dropdown';
import BEditable from '@/components/molecules/Editable';
import BMultiSelect from '@/components/molecules/MultiSelect';
import BModal from '@/components/organisms/Modal';
import SelectRuleAttributes from '@/components/organisms/SelectRuleAttributes';

import { actionOptions, ALL_LOCATIONS, RULE_TYPE, TRIGGERS } from './constants';

const updateInterval = 500;

const store = useStore();
const { t } = useTranslation();

const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  rule: {
    type: Object,
    required: true
  },
  services: {
    type: Array,
    required: true
  },
  locations: {
    type: Array,
    required: false,
    default: () => []
  }
});

const emit = defineEmits(['settings', 'update:rule']);

const form = reactive({
  ...props.rule
});

const state = reactive({
  isOpen: true,
  isLocationModalOpen: false
});

/**
 * Services
 */

const getOperatorsOptions = (trigger) => {
  return TRIGGERS[trigger].operators.map((operator) => ({
    label: t(`SHIPPING_RULES.OPERATORS.${TRIGGERS[trigger].type}.${operator}`),
    value: operator
  }));
};

const getService = (value) => {
  return props.services.find((service) => service.value === value);
};

const getServiceName = (value) => {
  const service = getService(value);

  return service ? service.label : value;
};

const getRatesSupport = (element) => {
  const service = getService(element);

  return service ? service.supportRates : false;
};

/**
 * Locations
 */

const serviceOptions = computed(() =>
  form.locations[0] === ALL_LOCATIONS
    ? props.services
    : props.services.filter((service) => intersection(service.locations, form.locations).length)
);

const locationOptions = computed(() =>
  props.locations.map((location) => ({ value: location.id, label: location.name }))
);

const labelMap = computed(() =>
  serviceOptions.value.reduce((acc, option) => {
    acc[option.value] = option.label;

    return acc;
  }, {})
);

const services = computed({
  get: () => {
    if (form.cheapest) {
      // service example: 845322173::standard
      const sorted = [...form.services].sort((a, b) => {
        const labelA = labelMap.value[a];
        const labelB = labelMap.value[b];

        if (labelA !== labelB) {
          return labelA.localeCompare(labelB);
        }

        return 0;
      });

      return sorted;
    }

    return form.services;
  },
  set: (value) => {
    form.services = value;
  }
});

const onAddLocations = (locations) => {
  if (form.services.length) {
    state.isLocationModalOpen = true;
    state.oldLocations = form.locations;
  }

  form.locations = locations.map(String);
};

const onSubmitLocationAdd = () => {
  state.oldLocations = [];
  state.isLocationModalOpen = false;

  form.services = form.services.filter((service) => serviceOptions.value.find((option) => option.value === service));

  update();
};

const onDiscardLocationAdd = () => {
  form.locations = state.oldLocations;
  state.oldLocations = [];

  nextTick(() => {
    state.isLocationModalOpen = false;
  });
};

/**
 * Add / Delete attributes
 */

const attributesOptions = computed(() =>
  Object.entries(TRIGGERS)
    .filter(([_, meta]) => (meta.ruleType ? meta.ruleType === props.rule.type : true))
    .map(([trigger]) => ({ label: t(`SHIPPING_RULES.TRIGGERS.${trigger}`), value: trigger }))
);
const attributesValues = computed(() => form.attributes.map((attribute) => attribute.path));

const onAddAttribute = (items) => {
  form.attributes = items.map((trigger, index) => {
    const exist = form.attributes[index];

    if (!exist) {
      return {
        path: trigger,
        operator: null,
        value: []
      };
    }

    return exist;
  });
};

const onDeleteAttribute = (attribute) => {
  form.attributes = form.attributes.filter((item) => item !== attribute);
};

/**
 * END
 */

const onSettings = (value) => {
  emit('settings', value, props.rule, props.index);
};

/**
 * Remove selected services when attributes are empty
 */
watch(
  () => form.attributes.length,
  (curr) => {
    if (curr === 0) {
      form.shipping_account_ids = [];
    }
  }
);

/**
 * On update
 */
const debouncedUpdate = debounce((value) => {
  emit('update:rule', value);
}, updateInterval);

const update = () => {
  debouncedUpdate({
    ...form,
    id: props.rule.id
  });
};

watch(form, () => {
  if (!state.isLocationModalOpen) {
    update();
  }
});

const locationsAreAbsent = computed(() => store.getters['shippingRules/locationAbsentForRule'](props.rule.id));
</script>

<style lang="scss" scoped>
.rule-shadow {
  box-shadow:
    0 0 1px rgba(0, 51, 80, 0.24),
    0 1px 2px rgba(0, 51, 80, 0.16);
}

.rule-wrapper {
  padding-top: 14px;

  &:hover {
    .rule-drag {
      display: block;
    }
  }
}

.rule-row-inputs {
  max-width: 576px;
}

.rule-select {
  max-width: 280px;
}

.rule-drag {
  display: none;
}
</style>
