<template>
  <div class="px-6 pt-6">
    <alert
      v-if="error"
      class="mb-8"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>

    <b-table
      :loading="loading"
      :fields="fields"
      :sort-fields="fields"
      :data="packageTypes"
      customize-columns
      entity-name="package-types"
      last-update>
      <template #cell(name)="{ row }">
        {{ row.name }}
      </template>
      <template #cell(dimensions)="{ row }">
        {{ formatDimensionsColumn(row) }}
      </template>
      <template #cell(weight)="{ row }">
        {{ formatWeightColumn(row) }}
      </template>
      <template #cell(actions)="{ row }">
        <dropdown
          :options="actionOptions"
          button-classes=""
          :left-align="false"
          @select="selectAction(row, $event)">
          <template #button>
            <icon
              class="mr-3 text-xl"
              icon-name="menu-horizontal" />
          </template>
        </dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'Packaging'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { curryRight } from 'lodash';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import { convertUnits } from '../../../utils';
import Alert from '../../atoms/Alert';
import Icon from '../../atoms/Icon';
import Dropdown from '../../molecules/Dropdown';
import BTable from '../../organisms/Table';

const store = useStore();
const { t } = useTranslation();

onMounted(async () => await loadPackageTypes());

const packageTypes = computed(() => store.state.packageTypes.items);
const loading = computed(() => store.state.packageTypes.loading);
const error = computed(() => store.state.packageTypes.error);

const fields = computed(() => {
  const fields = [
    { key: 'name', label: 'PACKAGING.NAME_FIELD' },
    { key: 'dimensions', label: 'PACKAGING.DIMENSIONS_FIELD' },
    { key: 'weight', label: 'PACKAGING.WEIGHT_FIELD' }
  ];

  if (store.getters['auth/isAdmin']) {
    fields.push({ key: 'actions', label: 'ACTIONS_FIELD' });
  }

  return fields;
});

const actionOptions = computed(() => [
  { value: 'edit-package-type', label: t('QUICK_ACTIONS.EDIT') },
  { value: 'delete-package-type', label: t('QUICK_ACTIONS.DELETE') }
]);

const loadPackageTypes = async () => await store.dispatch('packageTypes/loadPackageTypes');
const clearError = () => store.commit('packaging/clearError');

const formatDimensionsColumn = (row) => {
  const unit = 'cm';
  const convertToCm = curryRight(convertUnits)(unit);

  return `${convertToCm(row.length, row.dim_units)} (L) x ${convertToCm(row.width, row.dim_units)} (W) x ${convertToCm(
    row.height,
    row.dim_units
  )} (H) ${unit}`;
};

const formatWeightColumn = (row) => {
  const unit = 'kg';

  return `${convertUnits(row.weight, row.weight_unit, unit)} ${unit}`;
};

const selectAction = async (row, value) => {
  const actions = {
    'edit-package-type': () => {
      // eslint-disable-next-line no-console
      console.log('Should open edit modal');
    },
    'delete-package-type': async () => {
      await store.dispatch('packageTypes/deletePackageType', { id: row.id });
    }
  };

  return await actions[value]();
};
</script>
