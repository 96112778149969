<template>
  <nav
    data-id="pagination"
    class="flex items-center justify-between">
    <div class="w-28">
      <b-select
        data-id="limit"
        size="small"
        :value="limit"
        :options="limitOptions()"
        :pagination="true"
        @update:value="changeLimit">
        <template #label>{{ t('PAGINATION.SHOW') }}:</template>
      </b-select>
    </div>
    <div
      data-id="pagination-description"
      class="h-8 ml-2 pr-4 text-xs font-normal text-gray-700 border-r border-gray-200 flex items-center">
      {{ formatPaginationDescription() }}
    </div>
    <div class="ml-4 flex">
      <b-icon
        data-id="prev"
        :class="{ 'cursor-not-allowed text-gray-400': page === 1, 'text-gray-600': page !== 1 }"
        class="text-xl cursor-pointer pt-0.5"
        icon-name="chevron-left"
        @click="prev(1)" />
      <div
        class="flex"
        @click="selectPage">
        <div
          v-for="item in pages"
          :key="item"
          class="relative min-w-9 rounded text-gray-700 text-center cursor-pointer"
          :class="{
            'bg-gray-100 font-semibold': item === page
          }">
          <b-icon
            v-if="item === DOTS_LEFT"
            data-id="prev-5"
            class="absolute flex justify-center items-center inset-x-0 -bottom-0.5 text-xl"
            icon-name="menu-horizontal"
            @click="prev(5)" />
          <b-icon
            v-else-if="item === DOTS_RIGHT"
            data-id="next-5"
            class="absolute flex justify-center items-center inset-x-0 -bottom-0.5 text-xl"
            icon-name="menu-horizontal"
            @click="next(5)" />
          <template v-else>
            {{ item }}
          </template>
        </div>
      </div>
      <b-icon
        data-id="next"
        :class="{ 'cursor-not-allowed text-gray-400': lastPage, 'text-gray-600': !lastPage }"
        class="text-xl cursor-pointer pt-0.5"
        icon-name="chevron-right"
        @click="next(1)" />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Pagination'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, onBeforeUnmount, onMounted, ref } from 'vue';

import BIcon from '../../atoms/Icon';
import BSelect from '../../atoms/Select';

const { t } = useTranslation();

const props = defineProps({
  page: {
    type: Number,
    default: 1
  },
  limit: {
    type: [Number, null],
    default: null
  },
  total: {
    type: Number,
    default: 0
  },
  lastPage: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:page-settings']);

const DOTS_LEFT = 'left';
const DOTS_RIGHT = 'right';
const limitOptions = () => [
  { value: 25, label: '25' },
  { value: 80, label: '80' },
  { value: 100, label: '100' }
];

const changeLimit = (limit) => {
  emit('update:page-settings', { page: 1, limit });
};
const prev = (addPages) => {
  const { page, limit } = props;
  let newPage = page - addPages;

  if (newPage < 1) {
    newPage = 1;
  }

  emit('update:page-settings', { page: newPage, limit });
};

const next = (addPages) => {
  const { page, lastPage, limit, total } = props;
  let newPage = page + addPages;
  const totalPageCount = Math.ceil(total / limit);

  if (newPage > totalPageCount) {
    newPage = totalPageCount;
  }

  emit('update:page-settings', { page: newPage, limit });
};

const selectPage = (event) => {
  const newPage = event.target.innerText;

  if (!newPage || +newPage === props.page) {
    return;
  }

  emit('update:page-settings', { page: +newPage, limit: props.limit });
};

const formatPaginationDescription = () => {
  const { page, lastPage, limit, total } = props;

  return `${page * limit - (limit - 1)}-${lastPage ? total : page * limit} out of ${total}`;
};

const screenWidth = ref(window.innerWidth);

const resizeHandler = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', resizeHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandler);
});

const pages = computed(() => {
  if (screenWidth.value < 1100) {
    return [props.page];
  }

  const range = (start, end) => {
    let length = end - start + 1;

    return Array.from({ length }, (_, idx) => idx + start);
  };

  const { page, limit, total } = props;
  const siblingCount = 1;
  const totalPageCount = Math.ceil(total / limit);

  const totalPageNumbers = 5 + siblingCount;

  if (totalPageNumbers >= totalPageCount) {
    return range(1, totalPageCount);
  }

  const leftSiblingIndex = Math.max(page - siblingCount, 1);
  const rightSiblingIndex = Math.min(page + siblingCount, totalPageCount);

  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;

  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = 3 + 2 * siblingCount;
    let leftRange = range(1, leftItemCount);

    return [...leftRange, DOTS_RIGHT, totalPageCount];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 3 + 2 * siblingCount;
    let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);

    return [firstPageIndex, DOTS_LEFT, ...rightRange];
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    let middleRange = range(leftSiblingIndex, rightSiblingIndex);

    return [firstPageIndex, DOTS_LEFT, ...middleRange, DOTS_RIGHT, lastPageIndex];
  }

  return [props.page];
});
</script>
