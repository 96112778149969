<template>
  <modal
    :value="isShown"
    @close="onClose">
    <template #title>
      <h3 class="px-6 py-4 text-lg leading-6 font-medium text-gray-900">{{ t('LOG_DETAILS_MODAL.TITLE') }}</h3>
      <divider></divider>
    </template>
    <template #body>
      <div class="px-6 py-4">
        <div class="grid grid-cols-2 gap-5 w-3/5">
          <div
            v-for="item in detailsDataRender"
            :key="item"
            class="flex flex-col">
            <span class="text-xs text-gray-600 leading-6">{{ item.label }}</span>
            <template v-if="item.label === t('LOG_DETAILS_MODAL.STATUS')">
              <span class="text-sm text-gray-800 leading-6">
                <icon
                  class="text-sm"
                  :class="getStatusCodeClass(item.value)"
                  :icon-name="BringgFontIcons.SmallCircle" />
                {{ item.value }}
              </span>
            </template>
            <template v-else>
              <span class="text-sm text-gray-800 leading-6">{{ item.value }}</span>
            </template>
          </div>
        </div>

        <tabs
          class="mt-10"
          :data="tabsDataRender">
          <template #request="{ content }">
            <json-formatter :value="content" />
          </template>
          <template #response="{ content }">
            <json-formatter :value="content" />
          </template>
        </tabs>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ModalLogDetails'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps } from 'vue';

import { formatDateTime } from '../../../utils';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import JsonFormatter from '../../atoms/JsonFormatter';
import Modal from '../../organisms/Modal';
import Tabs from '../../organisms/Tabs';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  isShown: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close']);

const formatJsonValue = (value) => {
  return Object.entries(value).reduce((acc, [key, value]) => {
    acc[key] = typeof value === 'string' && value !== '' ? safeParse(value) : value;

    return acc;
  }, {});
};

// todo: Solve truncated labels issue
const safeParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return { error: 'Too big log dataset' };
  }
};

const getStatusCodeClass = (statusCode) => {
  return ['4', '5'].includes(String(statusCode)[0]) ? 'text-red-500' : 'text-green-500';
};

const detailsDataRender = computed(() => {
  const { pathname, request, status_code, method, ip, created_at, content_type } = props.value;
  const headers = JSON.parse(request.headers);

  return [
    { label: t('LOG_DETAILS_MODAL.STATUS'), value: status_code },
    { label: t('LOG_DETAILS_MODAL.URL'), value: pathname },
    { label: t('LOG_DETAILS_MODAL.HTTP_METHOD'), value: method },
    { label: t('LOG_DETAILS_MODAL.USER_AGENT'), value: headers['user-agent'] },
    { label: t('LOG_DETAILS_MODAL.IP_ADDRESS'), value: ip },
    { label: t('LOG_DETAILS_MODAL.CREATED'), value: formatDateTime(created_at) },
    { label: t('LOG_DETAILS_MODAL.CONTENT_TYPE'), value: content_type }
  ];
});

const tabsDataRender = computed(() => {
  return [
    { label: t('LOG_DETAILS_MODAL.REQUEST'), content: formatJsonValue(props.value.request) },
    { label: t('LOG_DETAILS_MODAL.RESPONSE'), content: formatJsonValue(props.value.response) }
  ];
});

const onClose = () => {
  emit('close');
};
</script>
