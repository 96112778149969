import { reactive } from 'vue';
import { useStore } from 'vuex';

export const useCustomizationModal = ({ fields, withSave }) => {
  const store = useStore();

  const mapArray = (keys) => keys.map((key) => fields.find((item) => item.key === key)).filter(Boolean);

  const updateLocalState = (data) => {
    for (const property in data) {
      const value = data[property];

      if (value !== undefined) {
        state[property] = value;
      }
    }
  };

  const state = reactive({
    unselected: [],
    selected: fields.value || fields,
    save: withSave ?? false,
    entityName: ''
  });

  const initColumns = async ({ savedSelections, entityName }) => {
    state.entityName = entityName;

    const saveFiltersExists = savedSelections !== undefined;

    if (saveFiltersExists) {
      state.unselected = mapArray([...savedSelections.unselected]);
      state.selected = mapArray([...savedSelections.selected]);
    }
  };

  const updateColumns = async (data) => {
    updateLocalState(data);

    const body = {
      [state.entityName]: {
        selected: state.selected.map((item) => item.key),
        unselected: state.unselected.map((item) => item.key)
      }
    };

    if (state.save) {
      await store.dispatch('users/updateUserSettings', { customized_columns: body });
    }

    store.commit('users/setCustomizedColumns', body);
  };

  return { initColumns, updateColumns, customizationSettings: state };
};
