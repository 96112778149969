<template>
  <div class="h-full flex flex-col items-center">
    <div class="text-gray-900 mt-20 w-96 bg-white p-7 rounded shadow-2xl flex flex-col items-center">
      <template v-if="showSubscriptionPage && !showRedirectMessage">
        <h1 class="text-2xl font-bold">{{ t('SUBSCRIBE.DELIVERY_HUB') }}</h1>
        <div class="mt-2 flex items-end gap-1">
          <span class="pl-14 text-2xl font-bold">$100</span>
          <span class="pb-0.5">{{ t('SUBSCRIBE.MONTH') }}</span>
        </div>
        <div>+ {{ t('SUBSCRIBE.USAGE_FEES') }}</div>
        <b-button
          class="mt-4 mb-5 rounded-xl px-12"
          size="large"
          type="danger"
          @click="subscribe">
          {{ t('SUBSCRIBE.SUBSCRIBE_NOW') }}
        </b-button>
        <ul class="flex text-base font-medium text-gray-800 tracking-wide flex-col list-disc list-inside gap-4">
          <li>{{ t('SUBSCRIBE.EFFICIENT_PACKAGING') }}</li>
          <li>{{ t('SUBSCRIBE.DIVERSIFIED_SHIPPING_ACCOUNTS') }}</li>
          <li>{{ t('SUBSCRIBE.AUTOMATED_CARRIER_ASSIGNMENT') }}</li>
          <li>{{ t('SUBSCRIBE.STREAMLINED_INTERNATIONAL_SHIPPING') }}</li>
          <li>{{ t('SUBSCRIBE.REAL_TIME_SHIPMENT_TRACKING') }}</li>
        </ul>
      </template>
      <template v-else-if="showRedirectMessage">
        <div
          v-if="countdown !== 0"
          class="text-blue-700 text-2xl font-bold">
          <i18next :translation="t('SUBSCRIBE.REDIRECT_COUNTDOWN_MESSAGE')">
            <template #countdown>
              {{ countdown }}
            </template>
          </i18next>
        </div>
        <div
          v-else
          class="text-blue-700 text-2xl font-bold">
          {{ t('SUBSCRIBE.REDIRECT_CLICK_MESSAGE') }}
          <a
            class="text-blue-800 underline"
            :href="confirmationLink"
            target="_blank"
            @click="redirectToOrders">
            {{ t('SUBSCRIBE.LINK') }}
          </a>
        </div>
      </template>
      <template v-else>{{ t('SUBSCRIBE.SUBSCRIPTION_ACTIVE') }}</template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Subscription'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BButton from '@/components/atoms/Button';

const store = useStore();
const { t } = useTranslation();
const router = useRouter();

const showSubscriptionPage = computed(() => store.getters['auth/showSubscriptionPage']);
const confirmationLink = ref('');
const showRedirectMessage = ref(false);
const countdown = ref(5);
let countdownInterval;

const startCountdown = () => {
  countdownInterval = setInterval(() => {
    countdown.value--;
    if (countdown.value === 0) {
      clearInterval(countdownInterval);
      window.open(confirmationLink.value, '_blank');
    }
  }, 1000);
};

const subscribe = async () => {
  const pendingSubscription = store.state.auth.shopifySubscriptions.find(
    (subscription) => subscription.status === 'pending'
  );

  if (pendingSubscription) {
    createSubscription(pendingSubscription?.confirmation_url);

    return;
  }
  await store.dispatch('shopify/createSubscription');
  createSubscription(store.state.shopify.confirmationUrl);
};

const createSubscription = (url) => {
  showRedirectMessage.value = true;
  confirmationLink.value = url;
  startCountdown();
};

onUnmounted(() => {
  clearInterval(countdownInterval);
});

onMounted(() => {
  store.dispatch('auth/getSubscription');
});

const redirectToOrders = () => {
  router.push({ name: 'orders' });
};
</script>
