<template>
  <teleport to="#pageTitle">
    <div class="flex flex-col flex-grow mt-4">
      <div
        data-id="page-title"
        class="flex items-top flex-row items-center">
        <span
          class="mr-1 cursor-pointer"
          @click="$router.back()">
          <b-icon
            :icon-name="BringgFontIcons.ArrowLeft"
            class="w-6 h-6 text-blue-500" />
        </span>
        <span class="mr-4">
          {{ t('SHIPMENT_DETAILS.SHIPMENT') }}
          <template v-if="shipmentDetails.tracking_number">#{{ shipmentDetails.tracking_number }}</template>
        </span>
        <badge
          class="h-5 mt-0.5"
          :type="statusBadgeTypeMapping[shipmentDetails.status]">
          {{ t(shipmentDetails.status) }}
        </badge>
        <badge
          v-if="statusSimulationEnabled && !deadendShipmentStatuses.includes(shipmentDetails.status)"
          class="h-5 mt-0.5 mx-3 cursor-pointer"
          :type="statusBadgeTypeMapping[shipmentDetails.status]"
          @click="onSimulate">
          <b-icon
            v-if="nextStatusLoading"
            class="text-lg"
            :icon-name="BringgFontIcons.Loader"
            spin />
          <span v-else>Simulate next status</span>
        </badge>
        <badge
          v-if="statusSimulationEnabled && !deadendShipmentStatuses.includes(shipmentDetails.status)"
          class="h-5 mt-0.5 mx-3 cursor-pointer"
          :type="statusBadgeTypeMapping[ShipmentStatus.CanceledByCarrier]"
          @click="onSimulateCancellation">
          <b-icon
            v-if="cancelLoading"
            class="text-lg"
            :icon-name="BringgFontIcons.Loader"
            spin />
          <span v-else>Simulate carrier cancellation</span>
        </badge>
      </div>
      <div
        class="flex flex-row"
        data-id="links-title">
        <div class="mt-6 mb-2 ml-7">
          <ShipmentDetailsHeaderOrder />
        </div>
      </div>
    </div>
  </teleport>
  <teleport to="#additionalItems">
    <modal-shipping-documents
      shipping-view
      :tabbed-view="!!pods.length"
      :documents="documents"
      @print="printDocument"
      @print-all="printAllDocuments">
      <template #label>
        <div
          data-id="documents-button"
          class="flex items-center text-blue-400 cursor-pointer">
          <b-icon
            icon-name="file"
            class="text-lg mr-1" />
          <div class="text-sm whitespace-nowrap">{{ `${t('SHIPMENT_DETAILS.DOCUMENTS_LABEL')}` }}</div>
        </div>
      </template>
    </modal-shipping-documents>
    <b-button
      data-id="shipment-notes-button"
      type="blank"
      @click="openNotes">
      <div class="flex items-center text-blue-400">
        <b-icon
          icon-name="notes"
          class="text-lg mr-1" />
        <div class="text-sm whitespace-nowrap">{{ `${t('NOTES.TITLE')} (${orderDetails?.notes?.length || 0})` }}</div>
      </div>
    </b-button>
  </teleport>
  <teleport to="#additionalItemsFooter">
    <div
      v-if="lastUpdatedByUserName"
      class="text-sm font-medium text-gray-600 mt-4">
      {{ t('SHIPMENT_DETAILS.LAST_UPDATED_BY') }}:
      <span class="font-normal">{{ lastUpdatedByUserName }}</span>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'ShipmentDetailsHeader'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, ref } from 'vue';
import { useStore } from 'vuex';

import Badge from '@/components/atoms/Badge';
import BButton from '@/components/atoms/Button';
import BIcon from '@/components/atoms/Icon';
import ShipmentPackageStatusBadge from '@/components/atoms/ShipmentPackageStatus';
import ModalShippingDocuments from '@/components/organisms/ModalShippingDocuments';
import ShipmentDetailsHeaderOrder from '@/components/organisms/ShipmentDetailsHeaderOrder';
import { useShippingDocumentsModal } from '@/composables';
import { ShipmentTrackingStages } from '@/constants';
import { accountEnvs, ShipmentStatus, statusBadgeTypeMapping } from '@/enums';
import { ShipmentPackageStatusComparator } from '@/utils';

import { checkpoints, deliveryStatusTrackingStageFlowMap, getCancelCheckpoints } from './constants';

const { t } = useTranslation();
const store = useStore();

const nextStatusLoading = ref(false);
const cancelLoading = ref(false);

const orderDetails = computed(() => store.state.orders.itemDetails);
const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);
const isCustomService = computed(() => store.getters['auth/isCustomService']);

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  orderId: {
    type: String,
    required: true
  },
  fulfillmentId: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['open-notes']);

const openNotes = () => emit('open-notes');

const deadendShipmentStatuses = [
  ShipmentStatus.New,
  ShipmentStatus.Delivered,
  ShipmentStatus.Exception,
  ShipmentStatus.CanceledByCarrier,
  ShipmentStatus.Canceled
];

const accountEnvType = computed(() => store.getters['auth/accountSettings'].account_env_type);

// Only for internal and staging accounts
const statusSimulationEnabled = computed(() => {
  return [accountEnvs.internal, accountEnvs.test].includes(accountEnvType.value);
});

const onSimulate = async () => {
  checkpoints.forEach((checkpoint) => {
    checkpoint.time = new Date().toISOString();
  });

  const shipment = shipmentDetails.value;

  const trackingStage = deliveryStatusTrackingStageFlowMap[shipment.status].nextTrackingStage;

  nextStatusLoading.value = true;
  await store.dispatch('shipments/simulateNextStatus', {
    carrier: shipment.carrier,
    shippingAccount: shipment.shipping_account_id,
    trackingNumber: shipment.tracking_number,
    status: deliveryStatusTrackingStageFlowMap[shipment.status].nextStatus,
    checkpoints: deliveryStatusTrackingStageFlowMap[shipment.status].checkpoints,
    delivered: trackingStage === ShipmentTrackingStages.DELIVERED,
    trackingStage
  });
  await new Promise((resolve) => setTimeout(resolve, 500));

  await store.dispatch('shipments/loadShipmentDetail', { ...props });
  nextStatusLoading.value = false;
};

const onSimulateCancellation = async () => {
  const shipment = shipmentDetails.value;

  await store.dispatch('shipments/simulateNextStatus', {
    carrier: shipment.carrier,
    shippingAccount: shipment.shipping_account_id,
    trackingNumber: shipment.tracking_number,
    status: deliveryStatusTrackingStageFlowMap[shipment.status].nextStatus,
    checkpoints: getCancelCheckpoints(),
    delivered: false,
    trackingStage: 'SHIPMENT_VOIDED'
  });
  cancelLoading.value = true;

  await new Promise((resolve) => setTimeout(resolve, 500));

  await store.dispatch('shipments/loadShipmentDetail', { ...props });
  cancelLoading.value = false;
};

const lastUpdatedByUserName = computed(() => {
  const userId = shipmentDetails.value?.last_updated_by;

  return store.getters['users/user'](userId)?.name || '';
});

const pods = computed(() => store.getters['shipments/getPODs']);

const shipments = computed(() => [shipmentDetails.value]);
const { documents, printDocument, printAllDocuments } = useShippingDocumentsModal(shipments, store);
</script>
