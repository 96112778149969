import { settingNames } from '@/components/pages/Printing/constants';
import { checkIfIsInternational, checkReadyPrinterSettings, executeRequest } from '@/utils';

const state = {
  items: new Map(),
  loading: false,
  error: ''
};

const getters = {
  items(state) {
    return Array.from(state.items.values());
  },
  userSettings(state) {
    return state.userSettings;
  },
  printersSettings(state) {
    return { ...state.userSettings?.printersSettings, label_type: state.userSettings?.labelType } || {};
  },
  user: (state) => (id) => {
    return state.items.get(id);
  },
  printersReady: (state, rootState, getters, rootGetters) => (fulfillmentDetails) => {
    const readyLabelSettings = checkReadyPrinterSettings({ type: settingNames[0], state });

    if (!readyLabelSettings) {
      return { ready: false, emptySettings: settingNames[0] };
    }

    if (rootGetters['auth/accountSettings'].print_packing_slips) {
      const readySlipsSettings = checkReadyPrinterSettings({ type: settingNames[1], state });

      if (!readySlipsSettings) {
        return { ready: false, emptySettings: settingNames[1] };
      }
    }

    if (fulfillmentDetails && checkIfIsInternational(fulfillmentDetails)) {
      const readyInvoiceSettings = checkReadyPrinterSettings({ type: settingNames[2], state });

      if (!readyInvoiceSettings) {
        return { ready: false, emptySettings: settingNames[2] };
      }
    }

    return { ready: true };
  },
  isPrinterReadyForDocumentType: (state) => (documentType) => {
    const map = {
      ['label']: settingNames[0],
      ['return_label']: settingNames[0],
      ['packing_slip']: settingNames[1],
      ['commercial_invoice']: settingNames[2]
    };

    if (!map[documentType]) {
      return true;
    }

    return checkReadyPrinterSettings({ type: map[documentType], state });
  }
};

const actions = {
  async loadUsers(options) {
    const request = {
      request: {
        method: 'GET',
        url: '/users',
        params: { page: 1 }
      },
      options,
      errorMessage: 'USERS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setUsers', response.data.users));
  },

  async loadUserSettings(options) {
    const request = {
      request: {
        method: 'GET',
        url: '/deliveryhub-orders-service/v1/user_settings'
      },
      options,
      errorMessage: 'USER_SETTINGS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setUserSettings', response.data));
  },

  async createUser(options, data) {
    const request = {
      request: {
        method: 'POST',
        url: `/users`,
        data
      },
      options,
      errorMessage: 'USERS_CREATION_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setUser', response.data.user));
  },

  async editUser(options, data) {
    const request = {
      request: {
        method: 'PUT',
        url: `/users/${data.id}`,
        data
      },
      options,
      errorMessage: 'USERS_EDITING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setUser', response.data.user));
  },

  async removeUser(options, data) {
    const request = {
      request: {
        method: 'DELETE',
        url: `/users/${data.id}`,
        data
      },
      options,
      errorMessage: 'USERS_DELETION_FAIL'
    };

    await executeRequest(request, () => options.commit('removeUser', data));
  },

  async updateUserSettings(options, data) {
    if (data.filters?.shipments?.location) {
      if (data.filters.fulfillments === undefined) {
        data.filters.fulfillments = {};
      }

      data.filters.fulfillments.location = data.filters.shipments.location;
    }

    const request = {
      request: {
        method: 'PUT',
        url: '/deliveryhub-orders-service/v1/user_settings',
        data
      },
      options,
      errorMessage: 'USER_SETTINGS_UPDATING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setUserSettings', response.data));
  }
};

const mutations = {
  setUsers(state, users) {
    const usersEntries = users.map((user) => [user.id, user]);

    state.items = new Map(usersEntries);
  },

  setUserSettings(state, userSettings) {
    const {
      label_type: labelType,
      print_node_account_id: printNodeAccountId,
      customized_columns: customizedColumns,
      page_settings: pageSettings,
      filters,
      ...printersSettings
    } = userSettings;

    // should get shipments location filter from fulfillments
    if (filters?.fulfillments?.location) {
      if (!filters.shipments) {
        filters.shipments = {};
      }

      filters.shipments.location = filters.fulfillments.location;
    }

    state.userSettings = {
      filters,
      printersSettings,
      customizedColumns,
      pageSettings,
      labelType,
      printNodeAccountId
    };
  },

  setCustomizedColumns(state, customizedColumns) {
    state.userSettings.customizedColumns = {
      ...state.userSettings.customizedColumns,
      ...customizedColumns
    };
  },

  removeUser(state, user) {
    state.items.delete(user.id);
  },

  setUser(state, user) {
    state.items.set(user.id, user);
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
