<template>
  <b-modal
    :value="isShown"
    @close="onClose">
    <div class="border-b border-gray-300">
      <h3 class="px-6 py-4 text-lg leading-6 font-medium text-gray-900">
        {{ props.isEdit ? t('USERS_MODAL.EDIT_USER') : t('USERS_MODAL.NEW_USER') }}
      </h3>
    </div>

    <div class="px-6 py-4 w-4/5">
      <b-input
        id="name"
        name="name"
        class="mb-4">
        <template #label>
          {{ t('USERS_MODAL.USERNAME') }}
        </template>
      </b-input>
      <b-input
        id="email"
        name="email"
        class="mb-4">
        <template #label>
          {{ t('EMAIL') }}
        </template>
      </b-input>
      <b-input
        v-if="!isEdit"
        id="password"
        name="password"
        class="mb-4"
        password>
        <template #error-message>
          <div class="absolute p-4 z-20 bg-white text-gray-600 rounded shadow-xl">
            <span>{{ t('PASSWORD_VALIDATION.TITLE') }}:</span>
            <ul class="pl-6 list-disc">
              <li
                v-for="[func, labelKey] in passwordValidationRules"
                :key="labelKey"
                :class="{ 'text-red-900': !func(values.password) }">
                {{ t(labelKey) }}
              </li>
            </ul>
          </div>
        </template>
        <template #label>
          {{ t('PASSWORD') }}
        </template>
      </b-input>
      <b-select
        id="role"
        name="role"
        size="large"
        :value="values.role"
        :options="roleOptions"
        placeholder="Choose from list"
        class="mb-4 w-full">
        <template #label>
          {{ t('USERS_MODAL.ROLE') }}
        </template>
      </b-select>
    </div>

    <save-discard-footer
      class="shadow-top rounded-b-lg"
      :is-discardable="meta.dirty"
      :is-submittable="meta.dirty && meta.valid"
      @submit="onSubmit"
      @discard="resetForm" />
  </b-modal>
</template>

<script>
export default {
  name: 'ModalUsers'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { useForm } from 'vee-validate';
import { computed, defineEmits, defineProps } from 'vue';
import { object, string } from 'yup';

import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter';
import BModal from '@/components/organisms/BModal';
import { Role } from '@/enums';
import { passwordValidationRules } from '@/validationRules';

import BSelect from '../../atoms/BSelect';
import BInput from '../../atoms/Input';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  isEdit: {
    type: Boolean,
    required: true
  },
  isShown: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['create', 'edit', 'close']);

const localValueFactory = () => {
  const localValue = {
    name: '',
    email: '',
    role: '',
    ...props.value
  };

  if (!props.isEdit) {
    localValue.password = '';
  }

  return localValue;
};

const schema = computed(() =>
  object({
    name: string()
      .matches(/^[a-zA-Z0-9- _./:']+$/, t('USERS_MODAL.ERROR_FOR_USER_NAME'))
      .max(50)
      .required()
      .label(t('USERS_MODAL.USERNAME')),
    email: string().required().email().label(t('EMAIL')),
    role: string().required().label(t('USERS_MODAL.ROLE')),
    password:
      !props.isEdit &&
      passwordValidationRules.reduce(
        (result, [func, errorLabelKey]) => result.test(errorLabelKey, errorLabelKey, func),
        string().required().label(t('LOGIN.PASSWORD'))
      )
  })
);

const roleOptions = computed(() => [
  {
    label: t('ADMIN'),
    value: Role.Admin
  },
  {
    label: t('PACKER'),
    value: Role.Packer
  },
  {
    label: t('CUSTOMER_SERVICE'),
    value: Role.CustomerService
  }
]);

const { values, handleSubmit, resetForm, meta } = useForm({
  validationSchema: schema,
  initialValues: localValueFactory()
});

const onSubmit = handleSubmit((values) => {
  emit(props.isEdit ? 'edit' : 'create', values);
  onClose();
});

const onClose = () => {
  emit('close');
};
</script>
