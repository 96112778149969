import client from '../api';

class ShippingAccountService {
  name = 'Shipping account';

  async get() {
    const { data: accounts } = await client.getShippingAccounts();

    return accounts.map((account) => account.shipping_account);
  }

  async post(value) {
    const {
      data: { shipping_account: shippingAccount }
    } = await client.postShippingAccount({
      shipping_account: value
    });

    delete shippingAccount.defaults.currency;

    return shippingAccount;
  }

  async put(value) {
    const {
      data: { shipping_account: shippingAccount }
    } = await client.putShippingAccount(value.id, { shipping_account: value });

    delete shippingAccount.defaults.currency;
    delete shippingAccount.extras;

    return shippingAccount;
  }

  async delete(id) {
    await client.deleteShippingAccount(id);
  }
}

export default new ShippingAccountService();
