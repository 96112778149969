export const MimeTypes = {
  pdf: 'application/pdf',
  zpl: 'x-application/zpl'
};

export const ShipmentCreatedBy = {
  API: 'api',
  Packer: 'packer',
  HUB: 'hub'
};

export const ShipmentTrackingStages = {
  CREATED: 'CREATED',
  PICKED_UP: 'PICKED_UP',
  IN_TRANSIT: 'IN_TRANSIT',
  OUT_OF_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED'
};

export const AnalyticsRouteName = {
  biDashboard: 'bi-dashboard',
  reports: 'reports'
};
