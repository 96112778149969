import client from '../api';

function createCsvFile(content) {
  let csvContent = 'data:text/csv;charset=utf-8,' + content;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');

  link.setAttribute('style', 'display:none');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'report.csv');
  document.body.appendChild(link); // Required for FF

  link.click();
  link.remove();
}

class AnalyticsShipmentService {
  name = 'Shipment analytics';

  async get(filters) {
    const {
      data: { events }
    } = await client.getShipmentEvents(filters);
    let result = [];

    for (let i = 0; i < 1; ++i) {
      result.push(...events);
    }

    return result;
  }

  async export(filters) {
    const exportFilters = { ...filters, format: 'csv' };
    const { data } = await client.getShipmentEvents(exportFilters);

    createCsvFile(data);
  }
}

export default new AnalyticsShipmentService();
