<template>
  <div class="flex overflow-hidden flex-col overflow-hidden h-full">
    <div class="flex justify-between">
      <div class="p-6 pl-10 text-sm text-gray-600 flex">
        <span>{{ t('API_APPS.SUBTITLE') }}</span>
        <a
          href="https://developers.bringg.com/v2/docs/authentication"
          target="_blank"
          class="text-blue-400 text-sm inline-flex font-semibold ml-4">
          <b-icon
            :icon-name="BringgFontIcons.ExternalLink"
            class="text-4xl w-4 h-4" />
          <span>Learn More</span>
        </a>
      </div>
      <div
        v-if="isAdmin"
        class="flex p-6">
        <b-button
          class="ml-2 px-6 py-2"
          data-id="addApiApp"
          @click="isShownModal = true">
          {{ t('API_APPS.ADD_API_APP') }}
        </b-button>
        <b-modal-api-apps
          :value="state.editApiApp"
          :is-edit="isEdit"
          :is-shown="isShownModal"
          @close="onClose"
          @create="onCreate"
          @edit="onEdit"></b-modal-api-apps>
      </div>
    </div>

    <div class="pl-10 pr-6 flex h-full flex-col overflow-hidden">
      <alert
        v-if="error"
        class="mb-8"
        type="danger"
        :close="true"
        @close="clearError">
        {{ t(error) }}
      </alert>

      <b-table
        class="integrations"
        :loading="loading"
        :fields="fields"
        :data="apiApps"
        row-key="id"
        last-update>
        <template #cell(name)="{ row }">
          <span
            class="text-blue-400 hover:text-blue-600 cursor-pointer"
            @click="selectAction(row, 'edit-api-app')">
            {{ row.name }}
          </span>
        </template>
        <template #cell(status)="{ row }">
          <b-icon
            class="text-xl"
            :class="getStatusClass(row.enabled)"
            :icon-name="BringgFontIcons.SmallCircle" />
          {{ formatStatus(row.enabled) }}
        </template>
        <template #cell(actions)="{ row }">
          <dropdown
            :options="actionOptions"
            button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
            :left-align="false"
            @select="selectAction(row, $event)">
            <template #button>
              <b-icon
                class="mr-3 text-xl"
                icon-name="menu-horizontal" />
            </template>
          </dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiApps'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import Alert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import BIcon from '../../atoms/Icon';
import Dropdown from '../../molecules/Dropdown';
import BModalApiApps from '../../organisms/ModalApiApps';
import BTable from '../../organisms/Table';

const store = useStore();
const { t } = useTranslation();

const isShownModal = ref(false);
const state = reactive({
  editApiApp: {}
});

onMounted(async () => await loadApiApps());

const apiApps = computed(() => store.getters['apiApps/items']);
const loading = computed(() => store.state.apiApps.loading);
const error = computed(() => store.state.apiApps.error);
const isEdit = computed(() => !(Object.keys(state.editApiApp).length === 0));
const isAdmin = computed(() => store.getters['auth/isAdmin']);

const fields = computed(() => {
  const fields = [
    { key: 'name', label: 'API_APPS.NAME_FIELD' },
    { key: 'status', label: 'API_APPS.STATUS_FIELD' }
  ];

  if (isAdmin.value) {
    fields.push({ key: 'actions', label: 'ACTIONS_FIELD' });
  }

  return fields;
});

const actionOptions = [
  { bringgIcon: 'pencil', value: 'edit-api-app', label: 'QUICK_ACTIONS.EDIT' },
  { bringgIcon: 'trash', value: 'delete-api-app', label: 'QUICK_ACTIONS.DELETE' }
];

const loadApiApps = async () => await store.dispatch('apiApps/loadApiApps');
const clearError = () => store.commit('apiApps/clearError');

const onCreate = async (value) => {
  await store.dispatch('apiApps/createApiApp', value);
};

const onEdit = async (value) => {
  await store.dispatch('apiApps/editApiApp', value);
  isShownModal.value = false;
  state.editApiApp = {};
};

const onClose = () => {
  isShownModal.value = false;
  state.editApiApp = {};
};

const selectAction = async (row, actionType) => {
  const actions = {
    'edit-api-app': () => {
      state.editApiApp = row;
      isShownModal.value = true;
    },
    'delete-api-app': async () => {
      await store.dispatch('apiApps/removeApiApp', row);
    }
  };

  return await actions[actionType]();
};

const formatStatus = (enabled) => {
  return enabled ? 'Enabled' : 'Disabled';
};

const getStatusClass = (enabled) => {
  return enabled ? 'text-green-500' : 'text-red-500';
};
</script>
