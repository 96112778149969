<template>
  <popover class="relative">
    <popover-button class="focus:outline-none">
      <icon
        :icon-name="BringgFontIcons.Info"
        class="ml-2 text-xl text-blue-400" />
    </popover-button>

    <popover-panel class="absolute z-10 left-12 bottom-pos popover-root">
      <span class="top-3 -left-3.5 arrow-down"></span>
      <div class="w-max bg-white shadow-xl rounded popover-container">
        <div class="text-sm text-gray-800 font-medium">
          {{ t('SHIPMENT_DETAILS.PACKAGE') }} {{ t('SHIPMENT_DETAILS.DETAILS') }}
        </div>
        <div class="text-xs mt-4 flex flex-row items-center">
          <icon
            :icon-name="BringgFontIcons.Weight"
            class="text-base text-gray-600" />
          <span class="ml-2">{{ t('SHIPMENT_DETAILS.TOTAL_WEIGHT') }}:</span>
          <span class="text-gray-500 ml-2">{{ weight }} {{ CONST_WEIGHT_UNIT }}</span>
        </div>
        <div class="mt-3 text-xs flex flex-row items-center">
          <icon
            :icon-name="BringgFontIcons.Ruler"
            class="text-base text-gray-600" />
          <span class="ml-2">{{ t('SHIPMENT_DETAILS.DIMENSIONS') }}:</span>
          <span class="text-gray-500 ml-2">{{ dimensions }} {{ CONST_DIMENSIONS_UNIT }}</span>
        </div>
      </div>
    </popover-panel>
  </popover>
</template>

<script>
export default {
  name: 'PackageDetailsPopover'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useTranslation } from 'i18next-vue';
import { computed, defineProps } from 'vue';

import Icon from '@/components/atoms/Icon';
import { convertUnits, round } from '@/utils';

const CONST_WEIGHT_UNIT = 'kg';
const CONST_DIMENSIONS_UNIT = 'cm';

const { t } = useTranslation();

const props = defineProps({
  packageItem: {
    type: Object,
    required: true
  }
});

const dimensions = computed(() => {
  const { height, width, length, dim_units } = props.packageItem.package;
  const converted = [length, width, height].map((value) => convertUnits(value, dim_units, CONST_DIMENSIONS_UNIT));

  return converted.join(' x ');
});

const weight = computed(() => {
  const {
    package: { weight, weight_units },
    shipments_products
  } = props.packageItem;

  const boxWeight = convertUnits(weight, weight_units, CONST_WEIGHT_UNIT);

  const productsTotalWeight = shipments_products.reduce(
    (acc, item) => acc + item.count * convertUnits(item.product.weight, item.product.weight_units, CONST_WEIGHT_UNIT),
    0
  );

  return round(boxWeight + productsTotalWeight);
});
</script>

<style lang="scss" scoped>
.popover-root {
  box-shadow:
    0 16px 24px 0 rgba(0, 51, 80, 0.16),
    0 2px 8px 0 rgba(0, 51, 80, 0.04);
}

.popover-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--whites-white-bg, #fff);
}

.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  border-width: 8px;
  rotate: 90deg;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
.bottom-pos {
  bottom: -5.5rem;
}
</style>
