const ShipmentStatus = {
  New: 'New',
  PartiallyFulfilled: 'PartiallyFulfilled',
  ReadyToShip: 'ReadyToShip',
  PickedUp: 'PickedUp',
  InTransit: 'InTransit',
  OutForDelivery: 'OutForDelivery',
  Delivered: 'Delivered',
  Exception: 'Exception',
  CanceledByCarrier: 'CanceledByCarrier',
  Canceled: 'Canceled'
};

const FulfillmentType = {
  Delivery: 'delivery',
  Return: 'return'
};

const statusBadgeTypeMapping = {
  [ShipmentStatus.New]: 'new',
  [ShipmentStatus.PartiallyFulfilled]: 'partially-fulfilled',
  [ShipmentStatus.ReadyToShip]: 'ready-to-ship',
  [ShipmentStatus.PickedUp]: 'picked-up',
  [ShipmentStatus.InTransit]: 'in-transit',
  [ShipmentStatus.OutForDelivery]: 'out-for-delivery',
  [ShipmentStatus.Delivered]: 'delivered',
  [ShipmentStatus.Exception]: 'exception',
  [ShipmentStatus.CanceledByCarrier]: 'canceled-by-carrier',
  [ShipmentStatus.Canceled]: 'canceled'
};

const statusDescription = {
  [ShipmentStatus.New]: 'Order created in the system and does not have at least one shipment with the following',
  [ShipmentStatus.PartiallyFulfilled]: 'Order created in the system and has some of the inventory packaged and booked',
  [ShipmentStatus.ReadyToShip]: 'All inventory in order are packaged and booked.',
  [ShipmentStatus.PickedUp]: 'All packages in the order are picked up by the carrier.',
  [ShipmentStatus.InTransit]: 'Based on the carrier tracking information',
  [ShipmentStatus.OutForDelivery]: 'Based on the carrier tracking information',
  [ShipmentStatus.Delivered]: 'Based on the carrier tracking information',
  [ShipmentStatus.Exception]: 'Based on the carrier tracking information',
  [ShipmentStatus.CanceledByCarrier]: 'When the carrier cancels the delivery before they picked up the order',
  [ShipmentStatus.Canceled]: 'When the order is cancelled by the retailer'
};

const accountEnvs = {
  production: 0,
  test: 1,
  internal: 2,
  carrier: 3
};

const FulfillmentMethod = {
  DIRECT: 'direct',
  PUDO: 'pudo'
};

const ShipmentEventType = {
  StatusUpdate: 'status_update',
  Checkpoint: 'checkpoint',
  CarrierAssignmentFailure: 'carrier_assignment_failure',
  CarrierCancellationFailure: 'carrier_cancellation_failure',
  Created: 'created',
  Updated: 'updated',
  CarrierAssignmentSuccess: 'carrier_assignment_success',
  CarrierCancellationSuccess: 'carrier_cancellation_success',
  WebhookSent: 'webhook_sent',
  PrintJobCreated: 'print_job_created'
};

const printLabelTypes = {
  PDF: 'PDF',
  ZPL: 'ZPL'
};

const Role = {
  Admin: 'Admin',
  Packer: 'Packer',
  CustomerService: 'Customer service'
};

const SpecialServices = {
  hazardous: 'HAZARDOUS',
  fragile: 'FRAGILE',
  age_restricted: 'AGE_RESTRICTED'
};

const AddressType = {
  SENDER: 'sender',
  RECIPIENT: 'recipient',
  BILLING: 'billing',
  OTHER: 'other'
};

const ShipmentPackageStatus = {
  ReadyToShip: 'ReadyToShip',
  PickedUp: 'PickedUp',
  InTransit: 'InTransit',
  OutForDelivery: 'OutForDelivery',
  Delivered: 'Delivered',
  Exception: 'Exception',
  CanceledByCarrier: 'CanceledByCarrier'
};

const ShipmentPackageEventType = {
  StatusUpdate: 'status_update',
  Checkpoint: 'checkpoint'
};

export {
  accountEnvs,
  AddressType,
  FulfillmentMethod,
  FulfillmentType,
  printLabelTypes,
  Role,
  ShipmentEventType,
  ShipmentPackageEventType,
  ShipmentPackageStatus,
  ShipmentStatus,
  SpecialServices,
  statusBadgeTypeMapping,
  statusDescription
};
