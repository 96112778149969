<template>
  <span>
    <slot />
    <b-icon
      class="ml-0.5 cursor-pointer sm:text-sm"
      :class="[copied ? 'text-green-400 text-sm' : 'text-blue-400 text-xs']"
      :icon-name="copied ? 'suffix' : 'copy'"
      @click="copyText" />
  </span>
</template>

<script>
export default {
  name: 'Copy'
};
</script>

<script setup>
import { ref } from 'vue';

import { copyToClipboard } from '../../../utils';
import BIcon from '../../atoms/Icon';

const copied = ref(false);

const copyText = (e) => {
  copyToClipboard(e.target.parentElement.children[0].textContent);
  copied.value = true;
  setTimeout(() => (copied.value = false), 1500);
};
</script>
