<template>
  <div
    v-if="isMounted"
    class="relative flex flex-col overflow-hidden h-full">
    <alert
      v-if="error"
      class="mb-8 absolute inset-x-0 top-0"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>
    <alert
      v-if="notification"
      class="mb-8 absolute inset-x-0 top-0"
      type="success">
      {{ t('BRANDING.SUCCESSES_MESSAGE') }}
    </alert>

    <div class="flex-1 pl-10 pt-6 flex flex-col gap-12 overflow-auto">
      <div class="text-sm text-gray-600 flex">
        {{ t('BRANDING.SUBTITLE') }}
      </div>
      <div class="w-2/3">
        <h2 class="text-base text-gray-800 font-semibold">{{ t('BRANDING.COMPANY_DETAILS') }}</h2>
        <div>
          <div class="flex gap-4 mt-3">
            <b-input
              id="company_name"
              data-id="company_name"
              name="company_name"
              input-class="w-72"
              size="small">
              <template #label>
                <span class="text-red-600">{{ `* ` }}</span>
                <span>{{ t('BRANDING.COMPANY_NAME') }}</span>
              </template>
            </b-input>
          </div>
          <div class="flex gap-4 mt-3">
            <b-input
              v-if="false"
              id="company_logo"
              data-id="company_logo"
              name="company_logo"
              input-class="w-72"
              size="small"
              :label="t('BRANDING.COMPANY_LOGO')"></b-input>
          </div>
          <h2 class="mt-8 text-base text-gray-800 font-semibold">
            {{ t('BRANDING.CUSTOMER_SUPPORT_CONTACT_DETAILS') }}
          </h2>
          <div class="flex mt-3">
            <b-input
              id="cs_email"
              data-id="customer_support_email"
              name="customer_support_email"
              :placeholder="t('BRANDING.CUSTOMER_SUPPORT_EMAIL_PLACEHOLDER')"
              input-class="w-72"
              size="small"
              :label="t('BRANDING.CUSTOMER_SUPPORT_EMAIL')">
              <template #start-icon>
                <MailIcon class="h-5 w-5 text-gray-500" />
              </template>
            </b-input>
          </div>
          <div class="flex gap-4 mt-3">
            <b-input
              id="cs_phone"
              data-id="customer_support_phone"
              name="customer_support_phone"
              :placeholder="t('BRANDING.CUSTOMER_SUPPORT_PHONE_PLACEHOLDER')"
              input-class="w-72"
              size="small"
              :label="t('BRANDING.CUSTOMER_SUPPORT_PHONE')">
              <template #start-icon>
                <PhoneIcon class="h-5 w-5 text-gray-500" />
              </template>
            </b-input>
          </div>
          <div class="flex gap-4 mt-3">
            <b-input
              id="cs_url"
              data-id="customer_support_url"
              name="customer_support_url"
              :placeholder="t('BRANDING.CUSTOMER_SUPPORT_URL_PLACEHOLDER')"
              input-class="w-72"
              size="small"
              :label="t('BRANDING.CUSTOMER_SUPPORT_URL')">
              <template #start-icon>
                <GlobeAltIcon class="h-5 w-5 text-gray-500" />
              </template>
            </b-input>
            <b-input
              id="cs_url_display_name"
              data-id="customer_support_url_display_name"
              name="customer_support_url_display_name"
              :placeholder="t('BRANDING.CUSTOMER_SUPPORT_URL_DISPLAY_NAME_PLACEHOLDER')"
              input-class="w-72"
              size="small"
              :tooltip-label="t('BRANDING.TOOLTIP_URL_DISPLAY_NAME')"
              :label="t('BRANDING.CUSTOMER_SUPPORT_URL_DISPLAY_NAME')"></b-input>
          </div>
          <h2 class="mt-8 text-base text-gray-800 font-semibold">
            {{ t('BRANDING.DELIVERY_NOTIFICATIONS') }}
          </h2>
          <div class="flex flex-col gap-4 mt-3">
            <b-input
              id="notifications_from_email"
              data-id="notifications_from_email"
              name="notifications_from_email"
              input-class="w-72"
              :placeholder="t('BRANDING.NOTIFICATIONS_EMAIL_PLACEHOLDER')"
              size="small"
              :tooltip-label="t('BRANDING.TOOLTIP_SENT_FROM_EMAIL')"
              :label="t('BRANDING.SENT_FROM_EMAIL')"></b-input>
            <b-chip-input
              id="notifications_bcc_emails"
              v-model:value="values.notifications_bcc_emails"
              data-id="notifications_bcc_emails"
              name="notifications_bcc_emails"
              :validation-schema="chipsValidationSchema"
              :placeholder="t('BRANDING.NOTIFICATIONS_EMAIL_PLACEHOLDER')"
              :tooltip-label="t('BRANDING.TOOLTIP_BCC_EMAIL')"
              :label="t('BRANDING.BCC_EMAIL')"></b-chip-input>
          </div>
        </div>
      </div>
    </div>
    <save-discard-footer
      class="sticky bottom-0"
      :submitting="saving"
      :is-discardable="meta.dirty"
      :is-submittable="meta.dirty && meta.valid"
      @submit="onSubmit"
      @discard="resetForm" />
  </div>
</template>

<script>
export default {
  name: 'Branding'
};
</script>

<script setup>
import { GlobeAltIcon, MailIcon, PhoneIcon } from '@heroicons/vue/solid';
import { useTranslation } from 'i18next-vue';
import { useForm } from 'vee-validate';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import * as yup from 'yup';

import Alert from '@/components/atoms/Alert';
import BInput from '@/components/atoms/Input';
import BChipInput from '@/components/molecules/ChipInput';
import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter';
import { useNotification } from '@/composables';

const store = useStore();
const { t } = useTranslation();
const { notification, notify } = useNotification();

const { object, string, array } = yup;

const isMounted = ref(false);
const saving = ref(false);

onMounted(async () => {
  isMounted.value = true;
});

const error = computed(() => store.state.auth.error);

const schema = computed(() =>
  object({
    company_name: string().max(255).required().label(t('BRANDING.COMPANY_NAME')),
    customer_support_email: string().email().label(t('BRANDING.CUSTOMER_SUPPORT_EMAIL')),
    customer_support_phone: string().phone(),
    customer_support_url: string().url().label(t('BRANDING.CUSTOMER_SUPPORT_URL')),
    customer_support_url_display_name: string().max(255).label(t('BRANDING.CUSTOMER_SUPPORT_URL_DISPLAY_NAME')),
    notifications_from_email: string().email().bringgEmail().label(t('BRANDING.NOTIFICATIONS_FROM_EMAIL')),
    notifications_bcc_emails: array()
      .of(string().email(t('INVALID_EMAIL')))
      .label(t('BRANDING.BCC_EMAIL'))
  })
);

const chipsValidationSchema = yup.string().email(t('INVALID_EMAIL')).label(t('BRANDING.BCC_EMAIL'));

const { handleSubmit, resetForm, meta, values } = useForm({
  validationSchema: schema,
  initialValues: { ...store.getters['auth/branding'] }
});

const onSubmit = handleSubmit(async (values, actions) => {
  saving.value = true;

  await updateAccountSettings(values);

  if (!error.value) {
    actions.resetForm({ values });
  } else {
    actions.resetForm();
  }

  saving.value = false;
});

const updateAccountSettings = async (payload) => {
  await store.dispatch('auth/updateAccountSettings', payload);

  notify();
};

const clearError = () => store.commit('auth/clearError');
</script>
