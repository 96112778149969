<template>
  <div class="customer-notification bg-white rounded shadow p-6">
    <div class="flex justify-between">
      <h3 class="text-gray-800 font-semibold">
        {{ t(`CUSTOMER_NOTIFICATIONS.EVENT_TYPES.${type.replace(':', '_')}`) }}
      </h3>
      <b-switch name="enabled" />
    </div>

    <div
      v-show="state.isShown"
      class="mt-4">
      <b-tabs
        class="mb-4"
        :showable="false"
        :removable="true"
        :active="state.activeTab"
        :data="tabsData"
        @change="onChangeTab"
        @remove="onRemoveTab">
        <template #before>
          <b-multi-select
            :with-all="false"
            :with-search="true"
            :options="LOCALE_OPTIONS"
            :value="selectedLocales"
            @select="onAddLocale">
            <template #button>
              <button
                type="button"
                class="px-3 mr-2 group">
                <b-icon
                  :icon-name="BringgFontIcons.Plus"
                  class="flex items-center text-blue-400 text-xl h-10 group-hover:text-gray-600" />
              </button>
            </template>
          </b-multi-select>
        </template>

        <template
          v-for="(element, index) in values.body"
          :key="element.locale"
          #[element.locale]>
          <div
            v-if="provider === CustomerNotificationProvider.SMS"
            class="mb-4">
            <div class="flex justify-between mb-2">
              <span class="text-gray-700 text-sm">{{ t('CUSTOMER_NOTIFICATIONS.SMS.TEXTAREA_HEADING') }}</span>
              <button
                type="button"
                class="text-sm text-blue-400 font-semibold"
                @click="onShowPreview">
                {{ t('CUSTOMER_NOTIFICATIONS.PREVIEW_BUTTON') }}
              </button>
            </div>

            <mentionable
              :keys="['@']"
              offset="6"
              :limit="variables.length"
              :items="variables">
              <b-input
                :name="`body[${index}].data.text`"
                input-class="sms-text"
                multiline
                @focus="onFocus('text')" />
            </mentionable>

            <div class="text-right text-sm text-gray-500">
              {{ element.data.text.length }} / {{ t('CUSTOMER_NOTIFICATIONS.SMS.CHARACTERS_LIMIT') }}
            </div>

            <div>
              <p class="text-gray-700 text-sm mb-1">{{ t('CUSTOMER_NOTIFICATIONS.TAGS_HEADING') }}</p>
              <div>
                <button
                  v-for="variable in variables"
                  :key="variable.value"
                  type="button"
                  class="bg-gray-200 text-gray-600 rounded text-xs px-1 mr-2 mb-2 py-0.5"
                  @click="onAddTag(element, variable.value)">
                  @{{ variable.value }}
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="provider === CustomerNotificationProvider.EMAIL"
            class="mb-4 relative">
            <div class="flex justify-between h-6">
              <div>
                <b-button
                  v-if="!activeBody.data.html"
                  size="small"
                  type="secondary"
                  class="h-6 px-2"
                  @click="onOpenHtmlEditor">
                  {{ t('CUSTOMER_NOTIFICATIONS.EMAIL.CREATE_HTML_BUTTON') }}
                </b-button>
              </div>

              <button
                type="button"
                class="text-sm text-blue-400 font-semibold"
                @click="onShowPreview">
                {{ t('CUSTOMER_NOTIFICATIONS.PREVIEW_BUTTON') }}
              </button>
            </div>

            <div
              v-if="activeBody.data.html"
              class="html-edit-wrapper pt-12 pb-6">
              <div class="edit-view px-10 border border-gray-300 text-center relative">
                <p class="mt-14 text-gray-700">{{ t('CUSTOMER_NOTIFICATIONS.EMAIL.PLACEHOLDER_HTML_TEMPLATE') }}</p>

                <b-button
                  size="small"
                  type="secondary"
                  class="h-6 px-2 mt-8 mb-18"
                  @click="onOpenHtmlEditor">
                  {{ t('CUSTOMER_NOTIFICATIONS.EMAIL.EDIT_HTML_BUTTON') }}
                </b-button>

                <button
                  class="absolute right-4 top-3"
                  type="button"
                  @click="onDeleteHtml">
                  <b-icon
                    class="text-gray-700"
                    :icon-name="BringgFontIcons.Trash" />
                </button>
              </div>
            </div>

            <modal-html-email-editor
              :is-open="state.isHtmlEditorOpen"
              :subject="activeBody.data.subject"
              :html="activeBody.data.html || activeBody.data.content"
              :variables="variables"
              @close="onCloseHtmlEditor"
              @save="onSaveHtml" />

            <template v-if="!element.data.html">
              <mentionable
                :limit="variables.length"
                :keys="['@']"
                offset="6"
                :items="variables">
                <b-input
                  :name="`body[${index}].data.subject`"
                  class="mb-2 mt-4"
                  input-class="email-subject"
                  label="Subject"
                  @focus="onFocus('subject')" />
              </mentionable>

              <mentionable
                :keys="['@']"
                :limit="variables.length"
                offset="6"
                :items="variables">
                <b-input
                  :name="`body[${index}].data.content`"
                  multiline
                  class="mb-4"
                  input-class="email-content"
                  label="Content"
                  @focus="onFocus('content')" />
              </mentionable>
            </template>

            <div v-if="!element.data.html">
              <p class="text-gray-700 text-sm mb-1">{{ t('CUSTOMER_NOTIFICATIONS.TAGS_HEADING') }}</p>

              <div>
                <button
                  v-for="variable in variables"
                  :key="variable.value"
                  type="button"
                  class="bg-gray-200 text-gray-600 rounded text-xs px-1 mr-2 mb-2 py-0.5"
                  @click="onAddTag(element, variable.value)">
                  @{{ variable.value }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </b-tabs>
    </div>

    <div
      v-if="values.enabled"
      class="mt-4">
      <button
        type="button"
        class="flex items-end"
        @click="onToggle">
        <span class="text-blue-500 text-sm mr-1 font-semibold">
          {{ t(`CUSTOMER_NOTIFICATIONS.${state.isShown ? 'HIDE_DETAILS' : 'SHOW_DETAILS'}`) }}
        </span>
        <b-icon
          :icon-name="state.isShown ? BringgFontIcons.ChevronUp : BringgFontIcons.Chevron"
          class="text-blue-500 text-xl" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerNotification'
};
</script>

<script setup>
import 'floating-vue/dist/style.css';

import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import ISO6391 from 'iso-639-1';
import { difference, has } from 'lodash';
import { useFieldArray, useForm, useIsFormDirty, useIsFormValid } from 'vee-validate';
import { computed, nextTick, reactive, watch } from 'vue';
import { Mentionable } from 'vue-mention';
import * as yup from 'yup';

import BButton from '@/components/atoms/Button';
import BIcon from '@/components/atoms/Icon';
import BInput from '@/components/atoms/Input';
import BSwitch from '@/components/atoms/Switch';
import BMultiSelect from '@/components/molecules/MultiSelect';
import {
  CustomerNotificationEmailVariables,
  CustomerNotificationProvider,
  CustomerNotificationVariables,
  DEFAULT_LOCALE,
  LOCALE_OPTIONS
} from '@/components/organisms/CustomerNotification/constants';
import ModalHtmlEmailEditor from '@/components/organisms/ModalHtmlEmailEditor';
import BTabs from '@/components/organisms/Tabs';

const { t } = useTranslation();

const props = defineProps({
  provider: {
    type: String,
    required: true,
    validator: (value) => Object.values(CustomerNotificationProvider).includes(value)
  },
  type: {
    type: String,
    required: true
  },
  item: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

/**
 * SMS form config
 */
const smsInitialValues = {
  enabled: false,
  provider: props.provider,
  event_type: props.type,
  body: [
    {
      locale: DEFAULT_LOCALE,
      data: {
        text: ''
      }
    }
  ],
  ...props.item
};

const smsValidation = yup.object({
  enabled: yup.boolean(),
  body: yup.array().of(
    yup.object({
      locale: yup.string().required(),
      data: yup.object({
        text: yup.string().required().max(180).label('Text')
      })
    })
  )
});

/**
 * Email form config
 */

const emailInitialValues = {
  enabled: false,
  provider: props.provider,
  event_type: props.type,
  body: [
    {
      locale: DEFAULT_LOCALE,
      data: {
        subject: '',
        content: ''
      }
    }
  ],
  ...props.item
};

const emailValidation = yup.object({
  enabled: yup.boolean(),
  body: yup.array().of(
    yup.object({
      locale: yup.string().required(),
      data: yup.object({
        subject: yup.string().label('Subject').required(),
        html: yup
          .string()
          .label('Html')
          .test('is-html', '${label} is required', (value, { parent }) => !has(parent, 'html') || Boolean(value)),
        content: yup
          .string()
          .label('Content')
          .test('is-html', '${label} is required', (value, { parent }) => has(parent, 'html') || Boolean(value))
      })
    })
  )
});

/**
 * Form
 */
const { handleSubmit, values, resetForm, validate } = useForm({
  keepValuesOnUnmount: true,
  initialValues: props.provider === CustomerNotificationProvider.SMS ? smsInitialValues : emailInitialValues,
  validationSchema: props.provider === CustomerNotificationProvider.SMS ? smsValidation : emailValidation
});

const state = reactive({
  isShown: values.enabled,
  activeTab: values.body[0].locale,
  focused: props.provider === CustomerNotificationProvider.SMS ? 'text' : 'content',
  isHtmlEditorOpen: false
});

const { push, remove } = useFieldArray('body');
const isDirty = useIsFormDirty();
const isValid = useIsFormValid();

const emit = defineEmits(['submit', 'show-preview', 'change-preview', 'test']);

defineExpose({
  submit: () => {
    if (isDirty.value) {
      onSubmit();
    }
  },
  reset: () => {
    if (isDirty.value) {
      resetForm();
      onChangeTab();
    }
  },
  isDirty: () => isDirty.value,
  isValid: () => (values.enabled ? isValid.value : true)
});

const activeBody = computed(() => values.body.find(({ locale }) => locale === state.activeTab));
const tabsData = computed(() => values.body.map(({ locale }) => ({ key: locale, label: ISO6391.getName(locale) })));
const selectedLocales = computed(() => values.body.map(({ locale }) => locale));

const variables = computed(() => {
  let variables = CustomerNotificationVariables[props.type];

  if (props.provider === CustomerNotificationProvider.EMAIL) {
    const emailVariables = CustomerNotificationEmailVariables[props.type];

    variables = {
      ...variables,
      ...emailVariables
    };
  }

  return Object.values(variables).map((value) => ({ value }));
});

const onSubmit = handleSubmit((values) => {
  emit('submit', { item: props.item, values });
});

const onToggle = () => {
  state.isShown = !state.isShown;
};

const onChangeTab = (tab) => {
  if (!tab) {
    state.activeTab = values.body[0].locale;
  } else {
    state.activeTab = tab;
  }

  nextTick(() => {
    validate();
    onChangePreview();
  });
};

const onAddLocale = (locales) => {
  const toAdd = difference(locales, selectedLocales.value);
  const toRemove = difference(selectedLocales.value, locales);

  toAdd.forEach((locale) => {
    if (props.provider === CustomerNotificationProvider.SMS) {
      push({
        locale,
        data: {
          text: ''
        }
      });
    } else {
      push({
        locale,
        data: {
          subject: '',
          content: ''
        }
      });
    }
  });

  if (selectedLocales.value.length > 1) {
    toRemove.forEach((locale) => {
      remove(selectedLocales.value.indexOf(locale));
    });
  }

  onChangeTab(locales[locales.length - 1]);
};

const onRemoveTab = (tab, index) => {
  if (values.body.length === 1) {
    return;
  }

  remove(index);

  if (tab === state.activeTab) {
    onChangeTab();
  }
};

const onFocus = (name) => {
  state.focused = name;
};

const onAddTag = (item, tag) => {
  if (!state.focused) {
    return;
  }

  const { data } = activeBody.value;

  data[state.focused] = `${data[state.focused]} @${tag}`;
};

const onOpenHtmlEditor = () => {
  state.isHtmlEditorOpen = true;
};

const onCloseHtmlEditor = async () => {
  state.isHtmlEditorOpen = false;
};

const onSaveHtml = (values) => {
  const { data } = activeBody.value;

  delete data.content;
  data.html = values.html;
  data.subject = values.subject;

  onCloseHtmlEditor();

  nextTick(() => {
    validate();
  });
};

const onDeleteHtml = () => {
  const { data } = activeBody.value;

  delete data.html;

  nextTick(() => {
    validate();
  });
};

const onShowPreview = () => {
  onChangePreview();

  emit('show-preview');
};

const onChangePreview = () => {
  const { data } = activeBody.value;
  const { type } = props;

  emit('change-preview', { type, data });
};

watch(
  () => values.enabled,
  (enabled) => {
    state.isShown = enabled;

    if (enabled) {
      validate();
    }
  }
);

watch(
  () => props.item,
  (item) => {
    resetForm({ values: item });

    onChangePreview();
  }
);
</script>

<style lang="scss" scoped>
.html-edit-wrapper {
  margin: 0 auto;
  max-width: 594px;
}
</style>

<style>
.sms-text {
  min-height: 66px;
}

.email-content {
  min-height: 67px;
}

.mention-item {
  @apply py-1 px-2 text-sm;
}

.mention-selected {
  @apply cursor-pointer bg-blue-400 text-white;
}
</style>
