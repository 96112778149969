import { createLogger, Store } from 'vuex';
import { createStore } from 'vuex-extensions';

import config from '../config';
import apiApps from './modules/apiApps';
import auth from './modules/auth';
import carriers from './modules/carriers';
import customerNotifications from './modules/customerNotifications';
import fulfillments from './modules/fulfillments';
import imageModal from './modules/imageModal';
import logs from './modules/logs';
import orders from './modules/orders';
import packages from './modules/packages';
import printing from './modules/printing';
import products from './modules/products';
import shipments from './modules/shipments';
import shippingAccounts from './modules/shippingAccounts';
import shippingLocations from './modules/shippingLocations';
import shippingRules from './modules/shippingRules';
import shopify from './modules/shopify';
import templates from './modules/templates';
import users from './modules/users';
import webhooks from './modules/webhooks';

const store = createStore(Store, {
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
  modules: {
    auth,
    orders,
    shipments,
    packages,
    logs,
    shippingAccounts,
    shippingLocations,
    shopify,
    users,
    shippingRules,
    fulfillments,
    webhooks,
    carriers,
    printing,
    products,
    apiApps,
    customerNotifications,
    imageModal,
    templates
  },
  state: {
    apiBaseUrl: config.get('ZENKRAFT_API_US_BASE_URL')
  }
});

export default store;

export const resetStore = () => store.reset();
