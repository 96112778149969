<template>
  <div
    data-id="tabs-container"
    class="flex flex-col gap-6 items-center">
    <div
      data-id="tabs-headers"
      class="flex pl-2 pt-2 pr-2 gap-0 w-full">
      <div class="flex pt-2 pr-2 pb-0 pl-4 items-start gap-6 w-full">
        <div class="flex flex-start justify-between items-start flex-grow cursor-pointer">
          <div
            v-for="(tab, index) in tabs"
            :key="tab.hash"
            :class="[
              'flex pb-0 justify-center items-start gap-1 flex-grow',
              activeTabIndex === index ? 'active-tab' : 'inactive-tab'
            ]"
            @click="selectTab(index)">
            <div class="tab-content flex py-1 px-3 justify-center items-center">
              <span :class="['text', activeTabIndex === index ? 'active-text' : 'inactive-text']">
                {{ tab.title }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BTabs'
};
</script>

<script setup>
import { computed, provide, ref } from 'vue';

import { ActiveTabHashKey, RegisterTabKey, UnregisterTabKey } from './constants';

const tabs = ref([]);
const activeTabIndex = ref(0);

const activeHash = computed(() => tabs.value.at(activeTabIndex.value)?.hash);

const registerTab = (tab) => tabs.value.push(tab);

const unregisterTab = (hash) => {
  const tabIndex = tabs.value.findIndex((tab) => tab.hash === hash);

  tabs.value.splice(tabIndex, 1);
};

provide(RegisterTabKey, registerTab);
provide(UnregisterTabKey, unregisterTab);
provide(ActiveTabHashKey, activeHash);

const selectTab = (index) => {
  activeTabIndex.value = index;
};

defineExpose({ activeHash });
</script>

<style lang="scss">
.tab-content {
  gap: 6px;
}
.text {
  font-family: Inter, serif;
  font-weight: 500;
  letter-spacing: -0.48px;
}

.active-text {
  color: #067eba;
}

.inactive-text {
  color: #718096;
}

.active-tab {
  border-bottom: 2px solid #3091ce;
}

.inactive-tab {
  border-bottom: 2px solid #e1e9f0;
}
</style>
