import axios from 'axios';
import i18next from 'i18next';
import { isEmpty, pick } from 'lodash';
import sha1 from 'sha1';

import { fallbackLocalStorage } from '@/localStorageWrapper';
import router from '@/router';
import { resetStore } from '@/store';
import { executeRequest, formatRole, getApiUrl, getCarrierServiceApiUrl, getQuery } from '@/utils';

const state = {
  user: null,
  merchantConfiguration: null,
  accountSettings: {
    autopack: false,
    print_packing_slips: false,
    create_return_labels: false,
    customer_notifications_enabled: false,
    notifications_from_email: '',
    notifications_bcc_emails: []
  },
  featureFlags: {},
  initQuery: {},
  error: '',
  shopifySubscriptions: []
};

const getters = {
  isAdmin(state) {
    return state.user?.admin;
  },
  isPacker(state) {
    return state.user?.dispatcher && !state.user?.driver;
  },
  isCustomService(state) {
    return state.user?.dispatcher && state.user?.driver;
  },
  showSubscriptionPage(state) {
    const isShopifyMode = state.initQuery?.platform === 'shopify';

    if (!isShopifyMode) {
      return false;
    }

    if (!state.shopifySubscriptions.length) {
      return true;
    }

    if (
      state.shopifySubscriptions.some((subscription) => {
        return subscription.status === 'active' && !!subscription?.billing_on;
      })
    ) {
      return false;
    }

    return true;
  },
  user(state) {
    return state.user;
  },
  merchantConfiguration(state) {
    return state.merchantConfiguration;
  },
  featureFlags(state) {
    return state.featureFlags;
  },
  apiUrl(state, getters) {
    return getApiUrl(getters.region);
  },
  carrierServiceApiUrl(state, getters) {
    return getCarrierServiceApiUrl(getters.region);
  },
  region(state) {
    return state.user?.region || localStorage.getItem('authentication_region');
  },
  token(state) {
    return state.user?.authentication_token || localStorage.getItem('authentication_token');
  },
  deliveryhubAccountKey(state) {
    return state.accountSettings?.deliveryhubAccountKey || localStorage.getItem('authentication_token');
  },
  accountSettings(state) {
    return state.accountSettings;
  },
  branding(state) {
    const settings = pick(state.accountSettings, [
      'company_name',
      'customer_support_email',
      'customer_support_phone',
      'customer_support_url',
      'customer_support_url_display_name',
      'notifications_from_email',
      'notifications_bcc_emails'
    ]);

    for (const key in settings) {
      if (settings[key] === null) {
        settings[key] = '';
      }
    }

    return settings;
  },
  isPackingSlipsPrintingEnabled(state) {
    return state.accountSettings.print_packing_slips;
  }
};

const actions = {
  async preInit({ commit }) {
    const query = getQuery();

    if (query.platform === 'shopify') {
      fallbackLocalStorage();

      if (query.userData) {
        commit('setUser', JSON.parse(query.userData));
        delete query.userData;
      }

      commit('setInitQuery', query);
    }
  },

  async init({ commit, dispatch, getters }, data) {
    let userData = data;

    if (isEmpty(userData) && !getters.token) {
      return;
    }

    if (isEmpty(userData)) {
      try {
        const response = await axios.get(`${getters.apiUrl}/tokens`, {
          ignoreLogout: true
        });

        userData = response.data;
      } catch (error) {
        if (error.response?.status === 401) {
          return dispatch('logout');
        }
      }
    }

    await i18next.changeLanguage(userData.language);

    commit('setUser', userData);
    commit('setFeatureFlags', userData.feature_flags);

    try {
      await dispatch('getAccountSettings');
    } catch (error) {
      dispatch('logout');
      throw error;
    }

    dispatch('carriers/loadCarriers', null, { root: true });

    dispatch('getSubscription');

    localStorage.setItem('authentication_token', userData.authentication_token);
    localStorage.setItem('authentication_region', userData.region);
    localStorage.setItem('deliveryhubAccountKey', getters.deliveryhubAccountKey);
    dispatch('setGtmData');
  },

  setGtmData({ state }) {
    const { user, accountSettings } = state;

    window.gtmData = {
      userId: user.id,
      userName: user.name,
      merchantId: user.merchant_id,
      merchantName: accountSettings.account_company_name,
      userRole: formatRole(user),
      userRegion: user.region,
      isBringgMerchant: user.merchant_id === 1,
      isBringgEmployee: user.email?.endsWith('@bringg.com'),
      isImpersonated:
        typeof localStorage !== 'undefined' && Boolean(localStorage.getItem('ls.impersonate_original_user'))
    };
  },

  async getAccountSettings({ commit, getters }) {
    const response = await axios.get(`${getters.apiUrl}/deliveryhub-orders-service/v1/accounts`, {
      headers: { Authorization: `Bearer ${getters.token}` }
    });

    commit('setAccountSettings', response.data);
  },

  async updateAccountSettings(options, data) {
    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/accounts/account_settings`,
        data
      },
      options,
      errorMessage: 'ACCOUNT_SETTINGS_UPDATING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setAccountSettings', response.data);
    });
  },

  async getMerchantConfiguration({ getters, commit }) {
    const { data } = await axios.get(`${getters.apiUrl}/merchant/merchant_configuration`, {
      headers: { Authorization: `Bearer ${getters.token}` }
    });

    commit('setMerchantConfiguration', data);

    return data;
  },

  async login({ dispatch }, data) {
    const url = getApiUrl(data.merchant?.region);

    const response = await axios.post(
      `${url}/tokens`,
      {
        remember_me: true,
        email: sha1(data.email.toLowerCase()),
        password: data.password,
        merchant_uuid: data.merchant?.uuid,
        recaptcha: data.recaptcha
      },
      {
        ignoreLogout: true
      }
    );

    if (response.data.merchants_data?.length === 1) {
      if (response.data.merchants_data[0].region !== 'eu-west-1-gcp') {
        return { data: { success: false, message: 'This account is not supported' } };
      }

      const merchant = response.data.merchants_data[0];

      return dispatch('login', { ...data, merchant });
    }

    if (response.data.authentication_token) {
      await dispatch('init', response.data);
      await dispatch('postLogin', response.data);
      await router.push({ name: 'orders' });
    }

    return response;
  },

  async postLogin({ getters }, data) {
    const query = getQuery();

    if (query.platform === 'shopify') {
      await axios.put(`${getters.apiUrl}/deliveryhub-orders-service/v1/integrations`, {
        type: 1,
        shop: query.shop,
        credentials: {
          [query.id]: {
            authentication_token: data.authentication_token,
            region: data.region
          }
        }
      });
    }
  },

  async getSubscription({ getters, commit, state }, query) {
    if (state.initQuery.platform !== 'shopify') {
      return;
    }

    const subscriptions = await axios.get(
      `${getters.apiUrl}/deliveryhub-orders-service/v1/integrations/shopify/subscriptions?shop=${state.initQuery.shop}`
    );

    commit('setShopifySubscriptions', subscriptions.data);
  },

  async signup(_, data) {
    const response = await axios.post(`${getApiUrl(data)}/deliveryhub-orders-service/v1/accounts`, {
      email: data.email.toLowerCase(),
      password: data.password,
      name: data.name,
      passwordConfirmation: data.passwordConfirmation,
      companyName: data.companyName,
      shop: data.query?.shop
    });

    if (response.data.success) {
      await router.push({ name: 'login', query: data.query || {} });
    }

    return response;
  },

  async remind(_, data) {
    const url = getApiUrl();

    const response = await axios.post(`${url}/users/remind`, {
      email: data.email.toLowerCase(),
      recaptcha: data.recaptcha
    });

    return response;
  },

  async reset(_, data) {
    const url = getApiUrl(data.region);

    const response = await axios.post(`${url}/users/reset`, {
      email: data.email.toLowerCase(),
      password: data.password,
      token: data.token
    });

    return response;
  },

  async preLogout(options) {
    const query = options.state.initQuery;

    if (query.platform === 'shopify') {
      await executeRequest({
        request: {
          ignoreLogout: true,
          method: 'PUT',
          url: '/deliveryhub-orders-service/v1/integrations',
          data: {
            type: 1,
            shop: query.shop,
            credentials: {
              [query.id]: null
            }
          }
        },
        options
      });
    }

    if (localStorage.getItem('authentication_token')) {
      await executeRequest({
        request: {
          ignoreLogout: true,
          method: 'DELETE',
          url: '/tokens'
        },
        options
      });
    }
  },

  async logout(options) {
    options.commit('setUser', null);

    await options.dispatch('preLogout');

    localStorage.removeItem('authentication_token');
    localStorage.removeItem('authentication_region');
    localStorage.removeItem('deliveryhubAccountKey');

    await router.push({ name: 'login', query: options.state.initQuery });
    resetStore();
  },

  async updateUser(options, data) {
    await i18next.changeLanguage(data.language);

    options.commit('setUser', data);
  }
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },

  setFeatureFlags(state, featureFlags) {
    state.featureFlags = featureFlags;
  },

  setAccountSettings(state, accountSettings) {
    state.accountSettings = { ...state.accountSettings, ...accountSettings };
  },

  setMerchantConfiguration(state, merchantConfiguration) {
    state.merchantConfiguration = merchantConfiguration;
  },

  setInitQuery(state, query) {
    state.initQuery = query;
  },
  setShopifySubscriptions(state, data) {
    state.shopifySubscriptions = data;
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
