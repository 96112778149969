<template>
  <div>
    <TransitionRoot
      as="template"
      :show="isVisible">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay
            class="fixed inset-0 bg-gray-600 bg-opacity-75"
            @click="close" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-white">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="close">
                  <span class="sr-only">{{ t('MENU.CLOSE_BUTTON') }}</span>
                  <XIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex flex-shrink-0 px-4">
              <img
                class="w-3/5 pb-20"
                src="../../../assets/img/bringg-logo.svg"
                alt="Logo" />
            </div>
            <div class="flex-1 h-0 overflow-y-auto">
              <nav class="px-4 space-y-1">
                <sidebar-menu
                  :items="navigation"
                  :open-menus="openMenus" />
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div
          class="flex-shrink-0 w-14"
          aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <div :class="['hidden md:flex md:w-60 md:flex-col md:fixed md:inset-y-0 border-r', { small: isSmall }]">
      <div class="flex flex-col flex-grow bg-white relative">
        <div class="h-20 px-4 pt-3.5 mb-10 relative">
          <img
            v-show="!isSmall"
            src="../../../assets/img/bringg-logo.svg"
            class="mb-2"
            alt="Logo" />
          <img
            v-show="isSmall"
            src="../../../assets/img/bringg-logo-small.svg"
            class="mt-1"
            alt="Logo" />
          <p
            v-show="!isSmall"
            class="mt-5 line-clamp-2">
            {{ settings.company_name }}
          </p>
          <b-button
            :icon="isSmall ? 'chevron-right' : 'chevron-left'"
            class="toggle-button"
            @click="toggle" />
        </div>
        <div class="flex-1 flex flex-col">
          <nav class="flex-1 px-4 pb-4 space-y-5">
            <sidebar-menu
              :items="navigation"
              :is-small="isSmall"
              :open-menus="openMenus"
              @toggle="toggle" />
          </nav>
        </div>
        <div class="p-4 text-gray-600 flex items-center">
          <img
            class="w-4 h-4"
            src="../../../assets/img/bringg-logo-small.svg"
            alt="Logo" />
          <span
            v-show="!isSmall"
            class="px-1 text-xs">
            {{ new Date().getFullYear() }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/solid';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, ref } from 'vue';
import { useStore } from 'vuex';

import { accountEnvs } from '@/enums';

import { AnalyticsRouteName } from '../../../constants';
import BButton from '../../atoms/Button';
import SidebarMenu from '../SidebarMenu';

const { t } = useTranslation();
const store = useStore();

const settings = computed(() => store.state.auth.accountSettings);

const props = defineProps({
  currentName: {
    type: String,
    required: true
  },
  isVisible: {
    type: Boolean,
    default: false
  },
  isSmall: {
    type: Boolean,
    default: false
  }
});

const openMenus = ref(new Set());

const emit = defineEmits(['close', 'toggle']);

const navigation = computed(() => {
  let reports = null;
  let biDashboard = null;
  let analytics = null;
  let subscription = null;
  let createOrders = null;

  if (store.getters['auth/showSubscriptionPage']) {
    subscription = {
      name: t('MENU.SUBSCRIPTION'),
      icon: BringgFontIcons.Cashier,
      current: props.currentName === 'subscription',
      to: { name: 'subscription' },
      slug: 'subscription'
    };
  }
  const featureFlags = store.getters['auth/featureFlags'];

  const accountEnvType = store.getters['auth/accountSettings'].account_env_type;

  if (featureFlags.deliveryhub_analytics) {
    reports = {
      name: t('MENU.REPORTS'),
      current: props.currentName === 'reports',
      to: { name: AnalyticsRouteName.reports, params: { paths: ['my'] } },
      slug: 'reports'
    };
  }
  if (featureFlags.deliveryhub_bi_dashboard) {
    biDashboard = {
      name: t('MENU.BI_DASHBOARD'),
      current: props.currentName === 'bi-dashboard',
      to: { name: AnalyticsRouteName.biDashboard, params: { paths: ['dashboards'] } },
      slug: 'bi-dashboard'
    };
  }
  const analyticsItems = [reports, biDashboard].filter(Boolean);

  if (analyticsItems.length > 0) {
    analytics = {
      name: t('MENU.ANALYTICS'),
      icon: BringgFontIcons.Report,
      current: analyticsItems.some((item) => item.current),
      children: analyticsItems,
      slug: 'menu-analytics'
    };
  }

  // Only for internal accounts
  if (accountEnvType === accountEnvs.internal) {
    createOrders = {
      name: t('MENU.CREATE_ORDERS'),
      icon: BringgFontIcons.Plus,
      current: props.currentName === 'create-order',
      to: { name: 'create-order' },
      slug: 'create-order'
    };
  }

  const settingsItems = [
    {
      name: t('MENU.SHIPPING_N_RETURNS'),
      current: ['shipping-accounts', 'shipping-rules', 'return-rules'].includes(props.currentName),
      to: { name: 'shipping-accounts' },
      slug: 'menu-shipping-n-returns'
    },
    {
      name: t('MENU.SHIPPING_LOCATIONS'),
      current: ['shipping-locations', 'add-location', 'edit-location', 'duplicate-location'].includes(
        props.currentName
      ),
      to: { name: 'shipping-locations' },
      slug: 'shipping-locations'
    },
    {
      name: t('MENU.PRINTING'),
      current: props.currentName === 'printing',
      to: { name: 'printing' },
      slug: 'printing'
    },
    {
      name: t('MENU.PACKAGING'),
      current: props.currentName === 'packaging',
      to: { name: 'packaging' },
      slug: 'packaging'
    },
    {
      name: t('MENU.USERS'),
      current: props.currentName === 'users',
      to: { name: 'users' },
      slug: 'users'
    },
    {
      name: t('MENU.DELIVERY_NOTIFICATIONS'),
      current: ['sms-notifications', 'email-notifications'].includes(props.currentName),
      to: { name: 'sms-notifications' },
      slug: 'sms-notifications'
    },
    {
      name: t('MENU.INTEGRATIONS'),
      current: ['webhooks', 'logs', 'api-apps'].includes(props.currentName),
      to: { name: 'webhooks' },
      slug: 'webhooks'
    },
    {
      name: t('MENU.ACCOUNT'),
      current: ['branding', 'return-reasons'].includes(props.currentName),
      to: { name: 'branding' },
      slug: 'branding'
    }
  ].filter(Boolean);

  return [
    {
      name: t('MENU.ORDERS'),
      icon: BringgFontIcons.Pallets,
      current: props.currentName === 'orders' || props.currentName === 'order-details',
      to: { name: 'orders' },
      slug: 'orders'
    },
    {
      name: t('MENU.FULFILLMENTS'),
      icon: BringgFontIcons.Package,
      current: props.currentName === 'fulfillments' || props.currentName === 'fulfillment-details',
      to: { name: 'fulfillments' },
      slug: 'fulfillments'
    },
    {
      name: t('MENU.SHIPMENTS'),
      icon: BringgFontIcons.LocalShipping,
      slug: 'shipments',
      current:
        props.currentName === 'shipments' ||
        props.currentName === 'shipment-details' ||
        props.currentName === 'create-shipment',
      to: { name: 'shipments' }
    },
    analytics,
    createOrders,
    {
      name: t('MENU.SETTINGS'),
      icon: BringgFontIcons.Settings,
      current: settingsItems.map((item) => item.current).includes(true),
      children: settingsItems,
      slug: 'menu-settings'
    },
    subscription
  ].filter(Boolean);
});

const close = () => emit('close');
const toggle = () => emit('toggle');
</script>

<style lang="scss" scoped>
.small {
  width: 72px;
}

.toggle-button {
  position: absolute;
  z-index: 9999;
  top: 52px;
  right: -12px;
}
</style>
