<template>
  <Menu
    :id="id"
    :data-id="dataId"
    as="div"
    class="relative inline-block text-left">
    <div>
      <MenuButton
        :class="buttonClassList"
        :disabled="disabled">
        <slot name="button">
          <slot />
          <ChevronDownIcon
            class="-mr-1 ml-2 h-5 w-5"
            aria-hidden="true" />
        </slot>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95">
      <MenuItems :class="[leftAlign ? 'left-0' : 'right-0', menuClassList]">
        <slot name="header" />
        <div class="p-2">
          <MenuItem
            v-for="item in options"
            v-slot="{ active }"
            :key="item.value"
            :data-id="item.value"
            :disabled="item.disabled"
            :class="['hover:text-blue-400 hover:bg-gray-100 rounded', { 'cursor-pointer': !item.disabled }]"
            @click.stop="selectItem(item.value)">
            <slot
              name="option"
              :item="item"
              :active="active">
              <tooltip
                v-if="item.tooltip"
                class="flex"
                placement="bottom">
                <template #content>
                  <menu-item-content
                    :item="item"
                    :active="active"></menu-item-content>
                </template>
                <template #title>
                  <span class="font-semibold text-sm whitespace-pre-line">
                    {{ item.tooltip }}
                  </span>
                </template>
              </tooltip>
              <menu-item-content
                v-else
                :item="item"
                :active="active"></menu-item-content>
            </slot>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
export default {
  name: 'Dropdown'
};
</script>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { computed, defineEmits, defineProps } from 'vue';

import Tooltip from '@/components/atoms/ToolTip';

import twMerge from '../../../twMerge';
import { defaultButtonClasses, defaultMenuClasses } from './constants';
import MenuItemContent from './MenuItemContent.vue';

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  id: {
    type: String,
    default: ''
  },
  leftAlign: {
    type: Boolean,
    default: true
  },
  buttonClasses: {
    type: String,
    default: defaultButtonClasses
  },
  menuClasses: {
    type: String,
    default: defaultMenuClasses
  },
  disabled: {
    type: Boolean,
    default: false
  },
  dataId: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['select']);

const buttonClassList = computed(() => twMerge(defaultButtonClasses, props.buttonClasses));
const menuClassList = computed(() => twMerge(defaultMenuClasses, props.menuClasses));

const selectItem = (value) => {
  emit('select', value);
};
</script>
