<template>
  <div class="h-full">
    <div class="relative h-full flex flex-col overflow-auto">
      <alert
        v-if="error && error !== 'Record not found'"
        class="mx-10 mt-6"
        type="danger"
        :close="true"
        @close="clearError">
        {{ t(error) }}
      </alert>
      <alert
        v-if="notification"
        class="mb-8 absolute inset-x-0 top-0"
        type="success">
        {{ t('PRINTING.SUCCESSES_MESSAGE') }}
      </alert>
      <div class="flex-1 px-10 pt-6 pb-24 xl:w-1/2">
        <p
          v-if="isPrintingPageDisable"
          class="text-red-400 text-sm">
          {{ t('PRINTING.WARNING_MESSAGE') }}
        </p>
        <div
          v-if="!store.state.printing.loading"
          :class="{ 'opacity-50': isPrintingPageDisable }">
          <div
            v-for="item in formInputsItems"
            :key="item.id"
            class="mt-8">
            <h2 class="text-gray-700 text-sm font-semibold">{{ item.lable }}</h2>
            <div
              v-if="item.type === 'label'"
              class="mt-2">
              <b-select
                :searchable="false"
                :label="t('PRINTING.LABEL_TYPE')"
                class="mb-4 w-62.5"
                name="label_type"
                :value="values['label_type']"
                :disabled="isPrintingPageDisable"
                :options="[printLabelTypes.PDF, printLabelTypes.ZPL]" />
            </div>
            <div class="mt-2 flex gap-6">
              <b-select
                :name="`${item.type}_printer_id`"
                :value="values[`${item.type}_printer_id`]"
                :disabled="isPrintingPageDisable"
                :options="printerOptions"
                :placeholder="t('SELECT_PLACEHOLDER')"
                class="mb-4 w-62.5"
                size="small"
                :label="t('PRINTING.PRINTER')" />
              <b-select
                :name="`${item.type}_tray_id`"
                :value="values[`${item.type}_tray_id`]"
                :disabled="isPrintingPageDisable || isTrayDisable(item.type)"
                :options="trayOptions(item.type)"
                :placeholder="t('SELECT_PLACEHOLDER')"
                class="mb-4 w-62.5"
                size="small"
                :label="t('PRINTING.TRAY')" />
            </div>
          </div>
        </div>
      </div>
      <save-discard-footer
        class="shadow-top"
        :submitting="data.isSaving"
        :is-discardable="meta.dirty"
        :is-submittable="meta.dirty && meta.valid"
        @submit="onSubmit"
        @discard="resetForm" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Printing'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { isEmpty, isNil, omitBy, sortBy } from 'lodash';
import { useForm } from 'vee-validate';
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { object, string } from 'yup';

import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter';
import { useNotification } from '@/composables';
import { printLabelTypes } from '@/enums';

import Alert from '../../atoms/Alert';
import BSelect from '../../atoms/BSelect';

const store = useStore();
const { t } = useTranslation();
const { notification, notify } = useNotification();

const formInputsItems = computed(() => [
  {
    type: 'label',
    lable: t('PRINTING.SHIPPING_LABELS')
  },
  {
    type: 'packing_slip',
    lable: t('PRINTING.PACKING_SLIPS')
  },
  {
    type: 'commercial_invoice',
    lable: t('PRINTING.COMMERCIAL_INVOICE')
  }
]);

const data = reactive({
  localValue: {
    label_type: '',
    label_printer_id: '',
    label_tray_id: '',
    packing_slip_printer_id: '',
    packing_slip_tray_id: '',
    commercial_invoice_printer_id: '',
    commercial_invoice_tray_id: ''
  },
  isSaving: false
});

onMounted(async () => {
  await Promise.all([loadPrinters(), loadUserSettings()]);

  Object.assign(data.localValue, omitBy({ ...store.getters['users/printersSettings'] }, isNil));

  resetForm({ values: data.localValue });
});

const loadPrinters = async () => await store.dispatch('printing/loadPrinters');
const loadUserSettings = async () => await store.dispatch('users/loadUserSettings');

const error = computed(() => store.state.printing.error || store.state.users.error);

const isPrintingPageDisable = computed(() => {
  return isEmpty(store.getters['printing/printersItems']);
});

const isTrayDisable = (type) => {
  const data = !values[`${type}_printer_id`];

  data && setFieldValue(`${type}_tray_id`, '');

  return data;
};

const printerOptions = computed(() => {
  return sortBy(
    store.getters['printing/printersItems'].map((item) => {
      return { label: item.name, value: item.id };
    }),
    'label'
  );
});

const schema = computed(() =>
  object({
    label_type: string().required().label(t('PRINTING.LABEL_TYPE'))
  })
);

const { values, handleSubmit, resetForm, meta, setFieldValue } = useForm({
  initialValues: data.localValue,
  validationSchema: schema
});

const trayOptions = (type) => {
  const printerId = values[`${type}_printer_id`];

  if (printerId) {
    const printer = store.getters['printing/printers'].get(printerId);

    if (printer && printer.bins) {
      return printer.bins;
    }
  }

  return [];
};

const onSubmit = handleSubmit(async (values, action) => {
  try {
    data.isSaving = true;

    await store.dispatch('users/updateUserSettings', values);

    if (!error.value) {
      action.resetForm({
        values
      });
      notify();
    }
  } finally {
    data.isSaving = false;
  }
});

const clearError = () => {
  store.commit('users/clearError');
  store.commit('printing/clearError');
};
</script>

<style scoped></style>
