import { isEmpty } from 'lodash';
import { createRouter, createWebHashHistory } from 'vue-router';

import SubscriptionPage from '@/components/pages/Subscription/index.vue';
import { getPageSettingsFromRoute } from '@/utils';

import AddLocationsPage from '../components/pages/AddLocation';
import ApiAppsPage from '../components/pages/ApiApps';
import BiDashboard from '../components/pages/BiDashboard';
import BrandingPage from '../components/pages/Branding';
import CreateOrderPage from '../components/pages/CreateOrder';
import CreateShipmentPage from '../components/pages/CreateShipment';
import CustomerNotifications from '../components/pages/CustomerNotifications/index.vue';
import CustomerTracking from '../components/pages/CustomerTracking';
import FulfillmentDetailsPage from '../components/pages/FulfillmentDetails';
import FulfillmentsPage from '../components/pages/Fulfillments';
import IntegrationsPage from '../components/pages/Integrations';
import LoginPage from '../components/pages/Login';
import LogsPage from '../components/pages/Logs';
import OrderDetailsPage from '../components/pages/OrderDetails';
import OrdersPage from '../components/pages/Orders';
import PackagingPage from '../components/pages/Packaging';
import PrintingPage from '../components/pages/Printing';
import ProfileManagementPage from '../components/pages/ProfileManagement';
import RemindPage from '../components/pages/Remind';
import ReportsPage from '../components/pages/Reports';
import ResetPage from '../components/pages/Reset';
import ReturnDetailsPage from '../components/pages/ReturnDetails';
import ReturnMethodPage from '../components/pages/ReturnMethods';
import ReturnReasonsPage from '../components/pages/ReturnReasons';
import ReturnRulesPage from '../components/pages/ReturnRules';
import Returns from '../components/pages/Returns';
import ShipmentDetailsPage from '../components/pages/ShipmentDetails';
import ShipmentsPage from '../components/pages/Shipments';
import ShippingAccountsPage from '../components/pages/ShippingAccounts';
import ShippingLocationsPage from '../components/pages/ShippingLocations';
import ShippingRulesPage from '../components/pages/ShippingRules';
import SignupPage from '../components/pages/Signup';
import UsersPage from '../components/pages/Users';
import WebhooksPage from '../components/pages/Webhooks';
import PublicPage from '../components/templates/PublicPage';
import store from '../store';

const shippingTabs = [
  {
    name: 'MENU.SHIPPING_ACCOUNTS',
    to: { name: 'shipping-accounts' }
  },
  {
    name: 'MENU.SHIPPING_RULES',
    to: { name: 'shipping-rules' }
  },
  {
    name: 'MENU.RETURN_RULES',
    to: { name: 'return-rules' },
    // TODO enable for all, but make disabled
    showFor: (user) => user?.admin
  }
];

const integrationTabs = [
  {
    name: 'MENU.WEBHOOKS',
    to: { name: 'webhooks' }
  },
  {
    name: 'MENU.LOGS',
    to: { name: 'logs' }
  },
  {
    name: 'MENU.API',
    to: { name: 'api-apps' },
    // TODO enable for all, but make disabled
    showFor: (user) => user.admin
  }
];

const accountTabs = [
  {
    name: 'MENU.BRANDING',
    to: { name: 'branding' }
  },
  {
    name: 'MENU.RETURN_REASONS',
    to: { name: 'return-reasons' }
  }
];

const customerNotificationsTabs = [
  {
    name: 'MENU.SMS',
    to: { name: 'sms-notifications' }
  },
  {
    name: 'MENU.EMAIL',
    to: { name: 'email-notifications' }
  }
];

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      showFor: (user) => !user
    }
  },
  {
    path: '/remind',
    name: 'remind',
    component: RemindPage,
    meta: {
      showFor: (user) => !user
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPage,
    props: (route) => ({
      token: route.query.token,
      region: route.query.region,
      email: route.query.email
    }),
    meta: {
      showFor: (user) => !user
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
    meta: {
      showFor: (user) => !user
    }
  },
  {
    path: '/track/:id',
    name: 'customer-tracking',
    component: CustomerTracking,
    props: (route) => ({
      id: route.params.id,
      region: route.query.region
    }),
    meta: {
      showFor: () => true
    }
  },
  {
    path: '/',
    component: PublicPage,
    props: (route) => ({
      title:
        route.meta.title === undefined
          ? route.name
              .replace('-', ' ')
              .split(' ')
              .map((item) => item.charAt(0).toUpperCase() + item.substr(1).toLowerCase())
              .join(' ')
          : route.meta.title,
      pathName: route.name,
      tabs: route.meta.tabs
    }),
    meta: {
      showFor: (user) => user
    },
    children: [
      {
        path: '',
        name: 'index',
        redirect: {
          name: 'orders'
        }
      },
      {
        path: '/account/shipments',
        name: 'shipments',
        component: ShipmentsPage,
        props: (route) => getPageSettingsFromRoute(route),
        meta: {}
      },
      {
        path: '/account/reports/:paths+',
        name: 'reports',
        component: ReportsPage,
        props: true,
        meta: {
          keep: true
        }
      },
      {
        path: '/account/bi-dashboard/:paths+',
        name: 'bi-dashboard',
        component: BiDashboard,
        props: true,
        meta: {
          keep: true
        }
      },
      {
        path: '/account/shipments/:orderId/:fulfillmentId/:id',
        name: 'shipment-details',
        component: ShipmentDetailsPage,
        props: (route) => ({
          id: route.params.id,
          orderId: route.params.orderId,
          fulfillmentId: route.params.fulfillmentId
        }),
        meta: {
          title: ''
        }
      },
      {
        path: '/account/create-shipment',
        name: 'create-shipment',
        component: CreateShipmentPage,
        meta: {
          title: ''
        }
      },
      {
        path: '/account/integrations',
        name: 'integrations',
        component: IntegrationsPage,
        meta: {}
      },
      {
        path: '/account/setup/users',
        name: 'users',
        component: UsersPage,
        meta: {}
      },
      {
        path: '/account/setup/shipping-accounts',
        name: 'shipping-accounts',
        component: ShippingAccountsPage,
        meta: {
          title: 'SHIPPING_N_RETURNS',
          tabs: shippingTabs
        }
      },
      {
        path: '/account/setup/shipping-rules',
        name: 'shipping-rules',
        component: ShippingRulesPage,
        meta: {
          title: 'SHIPPING_N_RETURNS',
          tabs: shippingTabs
        }
      },
      {
        path: '/account/setup/return-rules',
        name: 'return-rules',
        component: ReturnRulesPage,
        meta: {
          title: 'SHIPPING_N_RETURNS',
          tabs: shippingTabs
        }
      },
      {
        path: '/account/setup/sms-notifications',
        name: 'sms-notifications',
        component: CustomerNotifications,
        meta: {
          title: 'DELIVERY_NOTIFICATIONS',
          tabs: customerNotificationsTabs
        }
      },
      {
        path: '/account/setup/email-notifications',
        name: 'email-notifications',
        component: CustomerNotifications,
        meta: {
          title: 'DELIVERY_NOTIFICATIONS',
          tabs: customerNotificationsTabs
        }
      },
      {
        path: '/account/setup/webhooks',
        name: 'webhooks',
        component: WebhooksPage,
        meta: {
          title: 'INTEGRATIONS',
          tabs: integrationTabs
        }
      },
      {
        path: '/account/setup/logs',
        name: 'logs',
        component: LogsPage,
        props: (route) => getPageSettingsFromRoute(route),
        meta: {
          title: 'INTEGRATIONS',
          tabs: integrationTabs
        }
      },
      {
        path: '/account/setup/api-apps',
        name: 'api-apps',
        component: ApiAppsPage,
        meta: {
          title: 'INTEGRATIONS',
          tabs: integrationTabs
        }
      },
      {
        path: '/account/setup/branding',
        name: 'branding',
        component: BrandingPage,
        meta: {
          title: 'ACCOUNT',
          tabs: accountTabs
        }
      },
      {
        path: '/account/setup/return-reasons',
        name: 'return-reasons',
        component: ReturnReasonsPage,
        meta: {
          title: 'ACCOUNT',
          tabs: accountTabs
        }
      },
      {
        path: '/account/setup/shipping-locations',
        name: 'shipping-locations',
        component: ShippingLocationsPage,
        meta: {}
      },
      {
        path: '/account/setup/add-location',
        name: 'add-location',
        component: AddLocationsPage,
        meta: {
          title: ''
        }
      },
      {
        path: '/account/setup/duplicate-location/:id',
        name: 'duplicate-location',
        component: AddLocationsPage,
        props: (route) => ({ id: route.params.id }),
        meta: {
          title: ''
        }
      },
      {
        path: '/account/setup/edit-location/:id',
        name: 'edit-location',
        component: AddLocationsPage,
        props: (route) => ({ id: route.params.id, isEdit: true }),
        meta: {
          title: ''
        }
      },
      {
        path: '/account/setup/packaging',
        name: 'packaging',
        component: PackagingPage,
        meta: {}
      },
      {
        path: '/account/setup/printing',
        name: 'printing',
        component: PrintingPage,
        meta: {}
      },
      {
        path: '/account/setup/return-methods',
        name: 'return-methods',
        component: ReturnMethodPage,
        meta: {
          title: ''
        }
      },
      {
        path: '/account/returns',
        name: 'returns',
        component: Returns,
        meta: {
          title: ''
        }
      },
      {
        path: '/account/returns/:id',
        name: 'return-details',
        component: ReturnDetailsPage,
        props: (route) => ({ id: route.params.id }),
        meta: {}
      },
      {
        path: '/account/orders',
        name: 'orders',
        props: (route) => getPageSettingsFromRoute(route),
        component: OrdersPage,
        meta: {}
      },
      {
        path: '/account/subscription',
        name: 'subscription',
        component: SubscriptionPage
      },
      {
        path: '/account/orders/:id',
        name: 'order-details',
        component: OrderDetailsPage,
        props: (route) => ({ id: route.params.id }),
        meta: {
          title: ''
        }
      },
      {
        path: '/account/fulfillments',
        name: 'fulfillments',
        props: (route) => getPageSettingsFromRoute(route),
        component: FulfillmentsPage,
        meta: {}
      },
      {
        path: '/account/fulfillments/:orderId/:id',
        name: 'fulfillment-details',
        component: FulfillmentDetailsPage,
        props: (route) => ({ id: route.params.id, orderId: route.params.orderId }),
        meta: {
          title: ''
        }
      },
      {
        path: '/account/profile-management',
        name: 'profile-management',
        component: ProfileManagementPage,
        meta: {
          title: ''
        }
      },
      {
        path: '/account/create-order',
        name: 'create-order',
        component: CreateOrderPage,
        meta: {
          title: ''
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const { user } = store.state.auth;

  /**
   * Pass query params from signup to login page (for Shopify)
   */
  if (from.name === 'signup' && isEmpty(to.query) && !isEmpty(from.query)) {
    return next({ name: to.name, query: from.query });
  }

  /**
   * If user tries to access a page that is not allowed —
   * redirect to orders page (if logged in) or login page (if not logged in)
   */
  if (to.matched.some((item) => item.meta.showFor && !to.meta.showFor(user))) {
    return next({ name: user ? 'orders' : 'login' });
  }

  /**
   * Hide tabs that are not allowed for user
   */
  if (to.meta.tabs) {
    to.meta.tabs = to.meta.tabs.filter((item) => (item.showFor ? item.showFor(user) : true));
  }

  next();
});

export default router;
