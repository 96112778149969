<template>
  <b-modal
    :value="isShown"
    @close="onClose">
    <div class="flex flex-col custom-max-height">
      <div class="border-b border-gray-300">
        <h3 class="px-6 py-4 text-lg leading-6 font-medium text-gray-900">
          {{ props.isEdit ? t('WEBHOOKS_MODAL.EDIT_WEBHOOK') : t('WEBHOOKS_MODAL.NEW_WEBHOOK') }}
        </h3>
      </div>
      <div class="px-6 py-4 flex-1 overflow-y-auto">
        <b-input
          data-id="name"
          name="name"
          class="mb-4 w-4/5"
          :label="`* ${t('WEBHOOKS_MODAL.NAME')}`" />
        <b-input
          data-id="description"
          name="description"
          class="mb-4 w-4/5"
          :label="t('WEBHOOKS_MODAL.DESCRIPTION')" />
        <div class="mb-4 text-xs w-4/5">
          <b-multi-select
            ref="accountsMultiSelectRef"
            :options="shippingAccountList"
            custom-input-class="py-5 mt-1"
            :label="`* ${t('WEBHOOKS_MODAL.SHIPPING_ACCOUNT')}`"
            :value="values['shipping_account_ids']"
            with-all
            :allow-empty="false"
            :all-placeholder-name="t('FILTER.STATUS.PLACEHOLDER_NAME')"
            :placeholder-text="t('SELECT_PLACEHOLDER')"
            absent-data-warning="WEBHOOKS_MODAL.ABSENT_ACCOUNTS_WARNING"
            @select="selectAccounts" />
        </div>
        <div>
          <h2 class="mt-7 font-semibold text-sm">* {{ t('WEBHOOKS_MODAL.SELECT_EVENTS') }}</h2>
          <b-checkbox
            class="mt-5"
            :value="allSelected"
            :error="!!errors.events"
            @update:value="toggleAll">
            <template #label>
              <span class="text-gray-700">{{ t('WEBHOOKS_MODAL.SELECT_ALL') }}</span>
            </template>
          </b-checkbox>
          <ul class="mt-5 grid grid-cols-3 gap-4">
            <li
              v-for="webhook in webhooksEventsList"
              :key="webhook"
              class="flex items-center">
              <b-checkbox
                :data-id="webhook.name"
                name="events"
                :value="webhook"
                :error="!!errors.events"
                validation>
                <template #label>
                  <span class="text-gray-700">{{ webhook.name }}</span>
                </template>
              </b-checkbox>
            </li>
          </ul>
          <div
            v-if="errors.events"
            class="mt-2 text-sm text-red-600">
            {{ errors.events }}
          </div>
        </div>
        <b-input
          data-id="url"
          name="url"
          class="mt-7 w-4/5"
          :label="`* ${t('WEBHOOKS_MODAL.URL')}`"
          :placeholder="t('WEBHOOKS_MODAL.URL_PLACEHOLDER')" />

        <FieldArray
          v-slot="{ fields, push, remove }"
          name="headers">
          <b-button
            class="mt-5 p-3 border-none bg-transparent text-blue-400 text-base"
            @click="push({ name: '', value: '' })">
            {{ t('WEBHOOKS_MODAL.ADD_HEADER') }}
          </b-button>
          <div
            v-for="(field, idx) in fields"
            :key="idx"
            class="flex flex-row gap-4 justify-between">
            <b-input
              :name="`headers[${idx}].name`"
              class="w-1/2 mb-4"
              :label="`* ${t('WEBHOOKS_MODAL.HEADER_NAME')}`" />
            <b-input
              :name="`headers[${idx}].value`"
              class="w-1/2 mb-4"
              :label="`* ${t('WEBHOOKS_MODAL.HEADER_VALUE')}`" />
            <div class="pt-7">
              <b-button
                class="bg-transparent p-0 h-0"
                @click="remove(idx)">
                <b-icon
                  icon-name="trash"
                  class="text-xl text-gray-600 hover:text-blue-600" />
              </b-button>
            </div>
          </div>
        </FieldArray>
      </div>
      <save-discard-footer
        class="shadow-top rounded-b-lg"
        :is-discardable="meta.dirty"
        :is-submittable="meta.dirty && meta.valid"
        @submit="onSubmit"
        @discard="resetForm" />
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalWebhooks'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { isEmpty } from 'lodash';
import { FieldArray, useForm } from 'vee-validate';
import { computed, defineEmits, defineProps, ref } from 'vue';
import { array, object, string } from 'yup';

import BButton from '@/components/atoms/Button';
import BCheckbox from '@/components/atoms/Checkbox';
import BIcon from '@/components/atoms/Icon';
import BInput from '@/components/atoms/Input';
import BMultiSelect from '@/components/molecules/MultiSelect';
import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter';
import BModal from '@/components/organisms/BModal';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  isEdit: {
    type: Boolean,
    required: true
  },
  isShown: {
    type: Boolean,
    default: false
  },
  shippingAccountList: {
    type: Array,
    default: () => []
  },
  webhooksEventsList: {
    type: Array,
    default: () => []
  },
  usedNames: {
    type: Array,
    default: () => []
  }
});
const emit = defineEmits(['create', 'edit', 'close']);

const accountsMultiSelectRef = ref(null);

const localValueFactory = () => {
  const data = {
    name: '',
    description: '',
    shipping_account_ids: [],
    events: [],
    url: '',
    ...props.value
  };

  if (isEmpty(data.headers)) {
    delete data.headers;
  }

  return data;
};
//urlRerex to allined with API validation
const urlReStr =
  '^(?:(?:http|https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[0-1]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))\\.?|localhost)(?::\\d{2,5})?(?:[/?#]\\S*)?$';
const urlRe = new RegExp(urlReStr, 'i');
const schema = computed(() =>
  object({
    name: string()
      .max(50)
      .test('unique-name', t('WEBHOOKS_MODAL.NAME_ALREADY_TAKEN'), (value) => {
        return !props.usedNames.find(
          (name) => name === value.trim().toLowerCase() && name !== props.value?.name?.toLowerCase()
        );
      })
      .required()
      .label(t('WEBHOOKS_MODAL.NAME')),
    shipping_account_ids: array().min(1).required().label(t('WEBHOOKS_MODAL.SHIPPING_ACCOUNT')),
    url: string().matches(urlRe, t('WEBHOOKS_MODAL.ERROR_FOR_URL')).required().label(t('WEBHOOKS_MODAL.URL')),
    headers: array().of(
      object().shape({
        name: string().max(100).required().label(t('WEBHOOKS_MODAL.HEADER_NAME')),
        value: string().max(1000).required().label(t('WEBHOOKS_MODAL.HEADER_VALUE'))
      })
    ),
    events: array().min(1, t('WEBHOOKS_MODAL.EVENTS_ERROR_MESSSAGE')).required()
  })
);

const { values, handleSubmit, resetForm, meta, errors, setFieldValue } = useForm({
  validationSchema: schema,
  initialValues: localValueFactory()
});

const allSelected = computed(() => values.events?.length === props.webhooksEventsList?.length);
const toggleAll = (value) => {
  setFieldValue('events', value ? [...props.webhooksEventsList] : []);
};

const selectAccounts = (_, selectedAccounts) => {
  setFieldValue('shipping_account_ids', selectedAccounts);
};

const onSubmit = handleSubmit((values) => {
  if (isEmpty(values.headers)) {
    values.headers = [];
  }

  emit(props.isEdit ? 'edit' : 'create', values);
  onClose();
});
const onClose = () => {
  emit('close');
};
</script>

<style>
.custom-max-height {
  max-height: calc(100vh - 64px);
}
</style>
