import countries from '../../../assets/js/countries.json';

const countryOptions = Object.entries(countries).map(([value, label]) => ({
  value,
  label
}));

export const RULE_TYPE = {
  SHIPPING: 'SHIPPING',
  RETURN: 'RETURN'
};

/**
 * OPERATORS
 */
const NUMBER_OPERATORS = [
  'EQUALS',
  'NOT_EQUALS',
  'LESS_THAN',
  'LESS_THAN_OR_EQUALS',
  'GREATER_THAN',
  'GREATER_THAN_OR_EQUALS',
  'IN_RANGE'
];

const STRING_OPERATORS = [
  'STARTS_WITH',
  'NOT_STARTS_WITH',
  'ENDS_WITH',
  'NOT_ENDS_WITH',
  'EQUALS',
  'NOT_EQUALS',
  'INCLUDE',
  'EXCLUDE'
];

const ARRAY_OPERATORS = ['IN', 'NOT_IN', 'ARRAY_INTERSECTION_HAS_ONE'];

/**
 * TRIGGERS
 */
export const TRIGGERS = {
  SHIP_TO: {
    type: 'ARRAY',
    values: countryOptions,
    operators: ARRAY_OPERATORS
  },
  SHIP_FROM: {
    type: 'ARRAY',
    values: countryOptions,
    operators: ARRAY_OPERATORS
  },
  TOTAL_WEIGHT: {
    type: 'NUMBER',
    operators: NUMBER_OPERATORS
  },
  TOTAL_VALUE: {
    type: 'NUMBER',
    operators: NUMBER_OPERATORS
  },
  FULFILLMENT_SOURCE_ID: {
    type: 'STRING',
    operators: STRING_OPERATORS,
    ruleType: RULE_TYPE.SHIPPING
  },
  REQUIRED_SERVICE_LEVEL: {
    type: 'STRING',
    operators: STRING_OPERATORS,
    ruleType: RULE_TYPE.SHIPPING
  }
};

export const ACTIONS = {
  DUPLICATE: 'DUPLICATE',
  MOVE_UP: 'MOVE_UP',
  MOVE_DOWN: 'MOVE_DOWN',
  DELETE: 'DELETE'
};

export const actionOptions = [
  { bringgIcon: 'copy', value: ACTIONS.DUPLICATE, label: 'SHIPPING_RULES.ACTIONS.DUPLICATE' },
  { bringgIcon: 'arrow-up', value: ACTIONS.MOVE_UP, label: 'SHIPPING_RULES.ACTIONS.MOVE_UP' },
  { bringgIcon: 'arrow-down', value: ACTIONS.MOVE_DOWN, label: 'SHIPPING_RULES.ACTIONS.MOVE_DOWN' },
  { bringgIcon: 'trash', value: ACTIONS.DELETE, label: 'SHIPPING_RULES.ACTIONS.DELETE' }
];

export const ADD_ACTIONS = {
  IMPORT: 'IMPORT',
  CREATE: 'CREATE'
};

export const addActionOptions = [
  { value: ADD_ACTIONS.IMPORT, label: 'RETURN_RULES.ADD_ACTIONS.IMPORT' },
  { value: ADD_ACTIONS.CREATE, label: 'RETURN_RULES.ADD_ACTIONS.CREATE' }
];

export const ALL_LOCATIONS = 'All';
