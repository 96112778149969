<template>
  <div
    data-id="search"
    class="relative w-full">
    <input
      ref="searchInput"
      v-model="searchQuery"
      type="text"
      class="box-border flex items-center search-input px-2 py-1 w-full h-8 border border-gray-300 rounded text-sm"
      :placeholder="t('SEARCH.PLACEHOLDER')"
      @keyup.enter="search" />
    <button
      type="button"
      class="absolute right-2 top-1/2 transform -translate-y-1/2 icon-button"
      @click="search">
      <icon
        class="relative text-gray-600 text-lg"
        icon-name="search" />
    </button>
  </div>
</template>

<script>
import Icon from '../../atoms/Icon';

export default {
  name: 'SearchInput',
  components: {
    Icon
  }
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { ref, watch } from 'vue';

const { t } = useTranslation();

const emit = defineEmits(['update-search-query']);

const props = defineProps({
  searchQuery: {
    type: String,
    default: ''
  }
});

const searchQuery = ref(props.searchQuery);

watch(
  () => props.searchQuery,
  (newVal) => {
    if (newVal !== searchQuery.value) {
      searchQuery.value = newVal;
    }
  }
);

watch(searchQuery, (newVal, oldVal) => {
  if (newVal === '' && oldVal !== '') {
    emit('update-search-query', '');
  }
});

const search = () => {
  const inputValue = searchQuery.value ? searchQuery.value.trim() : '';

  emit('update-search-query', inputValue);
};
</script>
