<template>
  <button
    :data-id="dataId"
    :class="classList"
    :disabled="disabled || loading"
    :type="submit ? 'submit' : 'button'"
    @click="attrs.onClick">
    <span
      v-if="$slots['start-icon']"
      class="-ml-0.5 mr-2 h-4 w-4"
      aria-hidden="true">
      <slot name="start-icon" />
    </span>

    <div
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center">
      <b-icon
        class="text-lg"
        :icon-name="BringgFontIcons.Loader"
        spin />
    </div>

    <span :class="{ 'opacity-0': loading }">
      <slot />
    </span>

    <span
      v-if="$slots['end-icon']"
      class="-ml-0.5 mr-2 h-4 w-4"
      aria-hidden="true">
      <slot name="end-icon" />
    </span>

    <icon
      v-if="icon"
      :icon-name="icon" />
  </button>
</template>

<script>
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Button',
  inheritAttrs: false
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { computed, defineProps, useAttrs } from 'vue';

import BIcon from '@/components/atoms/Icon/index.vue';

import twMerge from '../../../twMerge';
import Icon from '../Icon';
import constants from './constants';

const attrs = useAttrs();

const props = defineProps({
  primary: {
    type: Boolean,
    default: false
  },
  secondary: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'primary',
    validator: function (value) {
      return ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'blank'].indexOf(value) !== -1;
    }
  },
  size: {
    type: String,
    default: 'small',
    validator: function (value) {
      return ['small', 'large'].indexOf(value) !== -1;
    }
  },
  icon: {
    type: String,
    default: ''
  },
  submit: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  dataId: {
    type: String,
    default: ''
  }
});

const classList = computed(() => {
  const data = [
    [true, constants.defaultClassList],
    [props.loading, constants.loadingClassList],
    [props.type in constants.typeClassList, constants.typeClassList[props.type]],
    [props.size in constants.sizeClassList, constants.sizeClassList[props.size]],
    [props.primary, constants.primaryClassList],
    [props.secondary, constants.secondaryClassList],
    [props.icon, constants.iconClassList[props.size]]
  ];

  return twMerge(...data.map(([condition, classList]) => (condition ? classList : '')), attrs.class);
});
</script>

<style lang="scss" scoped>
.button-shadow {
  box-shadow: 0px 1px 2px rgba(0, 51, 80, 0.16);
}
</style>
