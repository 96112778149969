import { faker } from '@faker-js/faker';
import { ref } from 'vue';

class NotificationsService {
  name = 'Notifications';

  constructor() {
    this.notifications = ref([]);
  }

  addNotification({ message, type = 'success', duration = 5000 }) {
    const notification = { message, type, id: faker.string.uuid() };

    this.notifications.value.push(notification);

    setTimeout(() => {
      this.filterNotifications(notification.id);
    }, duration);
  }

  filterNotifications(id) {
    this.notifications.value = this.notifications.value.filter((notification) => notification.id !== id);
  }
}

export default new NotificationsService();
