<template>
  <div :class="`preview-wrapper px-8 py-7 ${provider}-background`">
    <div class="preview-heading flex justify-between">
      <h5 class="text-gray-800">
        <strong>Template preview -</strong>
        {{ body.type }}
      </h5>

      <button
        type="button"
        @click="emit('close')">
        <icon
          class="text-xl text-gray-700"
          :icon-name="BringgFontIcons.Close" />
      </button>
    </div>

    <div class="preview-content">
      <template v-if="provider === CustomerNotificationProvider.EMAIL">
        <styles-reset>
          <div
            v-if="body.data.html"
            class="overflow-hidden overflow-ellipsis"
            v-html="previewWithVariables(body.data.html)" />

          <div
            v-if="body.data.content"
            class="overflow-hidden overflow-ellipsis"
            v-text="previewWithVariables(body.data.content)" />
        </styles-reset>
      </template>

      <template v-if="provider === CustomerNotificationProvider.SMS">
        {{ previewWithVariables(body.data.text.substring(0, 180)) }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplatePreview'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';

import Icon from '@/components/atoms/Icon';
import StylesReset from '@/components/atoms/StylesReset';
import { CustomerNotificationProvider } from '@/components/organisms/CustomerNotification/constants';
import { previewWithVariables } from '@/services/customerNotifications';

defineProps({
  provider: {
    type: String,
    required: true
  },
  body: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['close']);
</script>

<style scoped>
.preview-wrapper {
  width: 490px;
  height: 730px;
  @apply shadow bg-white;
}

.sms-background {
  background-image: url('../../../assets/img/iphone.svg');
  background-repeat: no-repeat;
  background-position: center 98px;
  position: relative;

  .preview-content {
    padding: 12px;
    position: absolute;
    top: 192px;
    left: 132px;
    width: 230px;
    height: 200px;
    line-height: 22px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;

    @apply text-gray-700 text-sm select-none;
  }
}

.email-background {
  background-image: url('../../../assets/img/email.svg');
  background-repeat: no-repeat;
  background-position: center 80px;
  position: relative;

  .preview-content {
    @apply bg-white;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 130px;
    bottom: 60px;
    left: 64px;
    right: 64px;
  }
}
</style>
