<template>
  <Drawer
    :top="top"
    :open="data.open"
    @close="emit('close')">
    <template #content>
      <carrier-details
        v-if="data.type === 'carrier'"
        :value="data.value"
        @close="emit('close')" />
      <addresses-details
        v-else-if="data.type === 'addresses'"
        :value="data.value"
        @open-carrier-modal="emit('open-carrier-modal')"
        @close="emit('close')" />
      <activity-log
        v-else-if="data.type === 'activityLog'"
        :value="data.value"
        @close="emit('close')" />
      <items-table
        v-else-if="data.type === 'packages'"
        :shipment-details="data.value.shipmentDetails"
        edit
        @open-carrier-modal="emit('items-open-carrier-modal')"
        @close="emit('close')" />
    </template>
  </Drawer>
</template>

<script>
export default {
  name: 'DrawerShipment'
};
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';

import ActivityLog from '@/components/molecules/ActivityLog';
import AddressesDetails from '@/components/molecules/AddressesDetails';
import CarrierDetails from '@/components/molecules/CarrierDetails';
import Drawer from '@/components/organisms/Drawer';
import ItemsTable from '@/components/organisms/ItemsTable';

const props = defineProps({
  data: {
    type: Object,
    require: true
  },
  top: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(['close', 'open-carrier-modal', 'items-open-carrier-modal']);
</script>

<style></style>
