<template>
  <collapse v-if="changes.length">
    <template #lable>
      <span class="text-sm font-medium">{{ t('SHIPMENT_DETAILS.DETAILS') }}</span>
    </template>

    <template #content>
      <div class="p-2">
        <table class="min-w-full border-collapse table-auto">
          <thead>
            <tr>
              <th class="border p-2">{{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.CHANGES.FIELD_NAME') }}</th>
              <th class="border p-2">{{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.CHANGES.OLD_VALUE') }}</th>
              <th class="border p-2">{{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.CHANGES.NEW_VALUE') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="change in changes"
              :key="change.name"
              class="hover:bg-gray-100">
              <td class="border p-2">{{ change.name }}</td>
              <td class="border p-2">
                <Tooltip
                  v-if="change.oldValueTooltip"
                  placement="top">
                  <template #content>
                    <span>{{ change.oldValue ?? '-' }}</span>
                  </template>
                  <template #title>
                    <span class="font-semibold text-sm">
                      {{ change.oldValueTooltip }}
                    </span>
                  </template>
                </Tooltip>
                <template v-else>{{ change.oldValue ?? '-' }}</template>
              </td>
              <td class="border p-2">
                <Tooltip
                  v-if="change.newValueTooltip"
                  placement="top">
                  <template #content>
                    <span>{{ change.newValue ?? '-' }}</span>
                    <icon
                      :icon-name="BringgFontIcons.Info"
                      class="text-lg text-gray-600 ml-1" />
                  </template>
                  <template #title>
                    <span class="font-semibold text-sm">
                      {{ change.newValueTooltip }}
                    </span>
                  </template>
                </Tooltip>
                <template v-else>{{ change.newValue ?? '-' }}</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </collapse>
</template>

<script>
export default {
  name: 'Changes'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';

import Icon from '@/components/atoms/Icon';
import Collapse from '@/components/molecules/Collapse';

import Tooltip from '../../atoms/ToolTip';

defineProps({
  changes: {
    type: Array,
    default: () => []
  }
});

const { t } = useTranslation();
</script>
