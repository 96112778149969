<template>
  <div>
    <label
      v-if="label || $slots.label"
      :for="id"
      class="block text-xs text-gray-600 mb-1">
      <template v-if="required">
        <span class="text-xs text-red-600 mr-0.5">*</span>
      </template>
      <slot name="label">
        {{ label }}
      </slot>
      <Tooltip
        v-if="tooltipLabel"
        placement="topRight">
        <template #content>
          <icon
            :icon-name="BringgFontIcons.Info"
            class="text-lg text-gray-600 ml-1" />
        </template>
        <template #title>
          <span class="font-semibold text-s">
            {{ tooltipLabel }}
          </span>
        </template>
      </Tooltip>
    </label>

    <div class="flex relative">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
        <div
          v-if="$slots['start-icon']"
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <slot name="start-icon" />
        </div>

        <input
          v-if="!multiline"
          v-bind="numberProps"
          :id="id"
          ref="inputRef"
          :data-id="dataId"
          :class="inputClasses"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          :disabled="disabled"
          :size="widthByContent && inputValue.length ? inputValue.length : null"
          @input="handleInput($event.target.value)"
          @click="attrs.onClick"
          @focus="attrs.onFocus" />

        <textarea
          v-else
          :value="inputValue"
          :class="textareaClasses"
          :placeholder="placeholder"
          @input="handleInput($event.target.value)"
          @click="attrs.onClick"
          @focus="attrs.onFocus" />

        <div
          v-if="$slots['end-icon']"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <slot name="end-icon" />
        </div>
      </div>

      <button
        v-if="password"
        type="button"
        class="show-button h-10 px-3 py-2 bg-gray-100 text-sm text-gray-600 border border-l-0 rounded-r border-gray-300"
        @click="togglePassword">
        {{ data.showPassword ? t('INPUTS.HIDE') : t('INPUTS.SHOW') }}
      </button>

      <template v-if="$slots['end-button']">
        <slot name="end-button" />
      </template>
    </div>

    <p
      v-if="errorMessage"
      class="mt-2 text-red-600"
      :class="errorStyles ? errorStyles : 'text-sm'">
      <slot name="error-message">
        {{ errorMessage }}
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'BInput'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps, reactive, ref, useAttrs, useSlots } from 'vue';

import twMerge from '@/twMerge';
import { createDebounce } from '@/utils';

import Icon from '../../atoms/Icon';
import Tooltip from '../../atoms/ToolTip/index.vue';

const { t } = useTranslation();

const slots = useSlots();
const attrs = useAttrs();
const debounce = createDebounce();

const data = reactive({
  showPassword: false
});

const props = defineProps({
  value: {
    type: null,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  tooltipLabel: {
    type: String,
    default: ''
  },
  multiline: {
    type: Boolean,
    default: false
  },
  password: {
    type: Boolean,
    default: false
  },
  status: {
    type: String,
    default: '',
    validator(value) {
      return ['success', 'warning', 'danger', ''].indexOf(value) !== -1;
    }
  },
  disabled: {
    type: Boolean,
    default: false
  },
  timeout: {
    type: Number,
    default: 0
  },
  validation: {
    type: Boolean,
    default: true
  },
  min: {
    type: Number,
    default: null
  },
  max: {
    type: Number,
    default: null
  },
  errorMessage: {
    type: String,
    default: ''
  },
  rounded: {
    type: Boolean,
    default: true
  },
  widthByContent: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'large',
    validator: (value) => ['small', 'large'].includes(value)
  },
  inputClass: {
    type: String,
    default: ''
  },
  dataId: {
    type: String,
    default: ''
  },
  decimal: {
    type: Boolean,
    default: false
  },
  errorStyles: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  }
});

const type = computed(() => {
  if (props.password && !data.showPassword) {
    return 'password';
  }
  if (props.decimal) {
    return 'text';
  }

  return props.type;
});

const inputRef = ref(null);

const emit = defineEmits(['update:value', 'end-button-click']);

const numberProps = computed(() => {
  const result = {};

  if (props.min !== null) {
    result.min = props.min;
  }
  if (props.max !== null) {
    result.max = props.max;
  }

  return result;
});

let inputValue = computed(() => props.value);
let errorMessage = false;
let meta = { validated: false, valid: true };

let handleInput = (value) => {
  if (props.decimal) {
    let num = Number(value.replace(',', '.'));

    if (Number.isNaN(num)) {
      inputRef.value.value = props.value;

      return;
    }

    if (props.value === Number(value)) {
      return;
    }
  } else if (props.type === 'number') {
    value = Number(value);
  }

  if (props.timeout) {
    debounce(() => emit('update:value', value), props.timeout);

    return;
  }

  emit('update:value', value);
};

const name = computed(() => props.name || props.id);

if (props.validation) {
  const connectedField = useField(name, undefined, {
    initialValue: props.value
  });

  inputValue = connectedField.value;
  errorMessage = connectedField.errorMessage;
  meta = connectedField.meta;

  handleInput = (value) => {
    let triggeredValue = value;

    if (props.type === 'number') {
      let num = props.decimal ? Number(value.replace(',', '.')) : Number(value);

      if (props.decimal) {
        if (Number.isNaN(num)) {
          inputRef.value.value = props.value;

          return;
        }

        if (props.value === Number(value)) {
          return;
        }
      }

      if (props.max !== null) {
        num = Math.min(num, props.max);
      }

      if (props.min !== null) {
        num = Math.max(num, props.min);
      }

      inputRef.value.value = num;

      triggeredValue = num;
    }

    const triggerChange = () => {
      connectedField.handleChange(triggeredValue);
      emit('update:value', triggeredValue);
    };

    if (props.timeout) {
      debounce(triggerChange, props.timeout);

      return;
    }

    triggerChange();
  };
}
const togglePassword = () => {
  data.showPassword = !data.showPassword;
};

const classes = computed(() => {
  const withStatus = [
    [
      (meta.validated && !meta.valid) || props.status === 'danger',
      'focus:ring-red-500 focus:border-red-500 border-red-300'
    ],
    [props.status === 'success', 'focus:ring-green-500 focus:border-green-500 border-green-300'],
    [props.status === 'warning', 'focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300']
  ];
  const withoutStatus = [[true, 'border-gray-300 focus:border-blue-400 focus:shadow-sm']];
  const result = [
    [slots['start-icon'], 'pl-10'],
    [slots['end-icon'], 'pr-10'],
    ...((meta.validated && !meta.valid) || props.status ? withStatus : withoutStatus)
  ];

  return result.map(([condition, classList]) => (condition ? classList : ''));
});

const inputClasses = computed(() =>
  twMerge(
    ...classes.value,
    'block w-full sm:text-sm disabled:cursor-not-allowed text-gray-800 placeholder-gray-400',
    slots['end-button'] || props.password ? 'rounded-none rounded-l' : props.rounded ? 'rounded' : '',
    props.size === 'small' ? 'h-8' : 'h-10',
    props.inputClass
  )
);

const textareaClasses = computed(() =>
  twMerge(
    ...classes.value,
    'block h-10 min-h-min w-full sm:text-sm rounded disabled:cursor-not-allowed text-gray-800 placeholder-gray-400 textarea',
    props.inputClass
  )
);
</script>

<style lang="scss" scoped>
.show-button {
  min-width: 58px;
}

.textarea:focus {
  box-shadow: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}
</style>
