<template>
  <alert
    v-if="error"
    class="mx-6 my-5"
    type="danger"
    :close="true"
    @close="clearError">
    {{ t(error) }}
  </alert>
  <div class="relative h-full overflow-auto">
    <div class="relative px-8 pt-8 flex items-center justify-between">
      <div class="flex items-center gap-4">
        <h1 class="text-lg text-gray-800 font-medium">
          {{ props.value.shipmentId ? t('SHIPMENT_DETAILS.ADDRESSES') : t('ADDRESS_DETAILS.FULFILLMENT_DETAILS') }}
        </h1>
        <b-icon
          v-if="isEditable"
          data-id="edit-button"
          :icon-name="BringgFontIcons.Pencil"
          class="text-blue-400 cursor-pointer"
          @click="toggleEdit" />
      </div>
      <button
        ref="closeDrawerButton"
        type="button"
        @click="emit('close')">
        <b-icon
          class="text-m text-gray-800"
          :icon-name="BringgFontIcons.Close" />
      </button>
    </div>
    <div class="p-7 flex flex-col gap-5">
      <template
        v-for="itemField in allFields"
        :key="itemField.title">
        <div :data-id="itemField.title">
          <div class="flex text-lg items-center text-gray-800 gap-2 mb-4">
            <div class="flex w-6 h-6 items-center justify-center rounded-full bg-gray-100">
              <b-icon
                class="text-gray-800"
                :icon-name="itemField.icon" />
            </div>
            <h2>{{ itemField.title }}</h2>
          </div>
          <div
            v-for="field in itemField.fields"
            :key="field"
            :data-id="field.key"
            class="px-8 w-full flex gap-6 items-center mb-2">
            <div class="w-1/4 text-sm text-gray-800 font-medium leading-6 flex items-center">
              <span>{{ field.label }}</span>
              <template v-if="field.key === 'pudo'">
                <Tooltip
                  class="ml-1"
                  placement="top">
                  <template #content>
                    <icon
                      class="text-xl text-gray-600"
                      :icon-name="BringgFontIcons.Info" />
                  </template>
                  <template #title>
                    <div class="w-52">{{ t('PUDO_ID_TOOLTIP') }}</div>
                  </template>
                </Tooltip>
              </template>
              <template v-else>
                <span>:</span>
              </template>
            </div>
            <div
              v-show="field.editable && isEdit"
              class="flex-1">
              <b-select
                v-if="field.options && field.editable"
                :value="field.value"
                :name="field.key"
                :options="field.options"
                size="extraSmall"
                class=""></b-select>
              <b-input
                v-else-if="field.editable"
                validation
                :name="field.key"
                size="small"
                input-class="px-2 py-0 h-6" />
            </div>
            <div
              v-if="!has(controlledValues, field.key) || !isEdit"
              class="max-width-220 text-sm text-gray-600 leading-6">
              {{ field.value }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="isEdit"
      class="sticky bottom-0 bg-white z-10 border-t border-gray-200">
      <SaveDiscardFooter
        data-id="addressesFooter"
        :is-discardable="meta.dirty"
        :is-submittable="meta.dirty && meta.valid"
        :submitting="loading"
        :submit-label="t('SAVE_AND_CONTINUE')"
        @discard="resetForm"
        @submit="preSubmit" />
    </div>
  </div>
  <confirm-modal
    :is-shown="showOrderMessage"
    @close="showOrderMessage = false"
    @reject="saveOnly"
    @accept="saveToOrder">
    <template #title>
      {{ t('ADDRESS_DETAILS.SAVE_CHANGES_TO_ORDER_LEVEL') }}
    </template>
    <template #description>
      {{ t('ADDRESS_DETAILS.SAVE_CHANGES_TO_ORDER_LEVEL_DESCRIPTION') }}
    </template>
    <template #reject-label>
      {{ t('ADDRESS_DETAILS.SAVE_HERE_ONLY') }}
    </template>
    <template #accept-label>
      {{ t('ADDRESS_DETAILS.SAVE_TO_ORDER') }}
    </template>
  </confirm-modal>
</template>

<script>
export default {
  name: 'AddressDetails'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { get, has, isEmpty, isEqual, omit, pick, set } from 'lodash';
import { useForm } from 'vee-validate';
import { computed, defineEmits, defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import { object, string } from 'yup';

import countryList from '@/assets/js/countries.json';
import Alert from '@/components/atoms/Alert';
import BSelect from '@/components/atoms/BSelect';
import BIcon from '@/components/atoms/Icon';
import Icon from '@/components/atoms/Icon/index.vue';
import BInput from '@/components/atoms/Input';
import Tooltip from '@/components/atoms/ToolTip/index.vue';
import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter';
import ConfirmModal from '@/components/organisms/ConfirmModal/index.vue';
import { AddressType, FulfillmentMethod, ShipmentStatus } from '@/enums';
import { NotificationService } from '@/services';
import { phoneRegex } from '@/utils';

const { t } = useTranslation();

const store = useStore();

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['close', 'open-carrier-modal']);

const isEdit = ref(false);
const showOrderMessage = ref(false);
const saveChangesToOrderLevel = ref(false);
const duplicates = ref([]);

const orderDetails = computed(() => store.state.orders.itemDetails);
const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);
const fulfillments = computed(() => store.state.fulfillments.items);
const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);
const getAddresses = (data, type) => {
  const address = get(data, ['value', `${type}_address`]);
  const customer = get(data, ['value', type]);
  const stateObj = {
    label: t('ADDRESS.STATE'),
    value: address.state,
    key: `${type}_address.state`
  };
  const isRecipient = type === AddressType.RECIPIENT;
  const isPudo = fulfillmentDetails.value.fulfillment_method === FulfillmentMethod.PUDO;

  const fields = [
    {
      label: t('ADDRESS.NAME'),
      value: customer.name,
      key: `${type}.name`
    },
    {
      label: t('ADDRESS.PHONE'),
      value: customer.phone,
      key: `${type}.phone`
    },
    {
      label: t('ADDRESS.EMAIL'),
      value: customer.email,
      key: `${type}.email`
    },
    {
      label: t('ADDRESS.COMPANY'),
      value: address.company,
      key: `${type}_address.company`
    },
    {
      label: t('ADDRESS.STREET1'),
      value: address.street1,
      key: `${type}_address.street1`
    },
    {
      label: t('ADDRESS.STREET2'),
      value: address.street2,
      key: `${type}_address.street2`
    },
    {
      label: t('ADDRESS.CITY'),
      value: address.city,
      key: `${type}_address.city`
    },
    ...(isEmpty(address.state) ? [] : [stateObj]),
    {
      label: t('ADDRESS.POSTAL_CODE'),
      value: address.postal_code,
      key: `${type}_address.postal_code`
    },
    {
      label: t('ADDRESS.COUNTRY'),
      value: address.country,
      key: `${type}_address.country`,
      options: Object.keys(countryList).map((value) => ({
        value,
        label: countryList[value]
      }))
    }
  ];

  if (isRecipient) {
    fields.push({
      label: t('ADDRESS.FULFILLMENT_METHOD'),
      value: fulfillmentDetails.value.fulfillment_method,
      key: `fulfillment_method`
    });
  }

  if (isPudo && isRecipient) {
    fields.push({
      label: t('ADDRESS.PUDO'),
      value: fulfillmentDetails.value.pudo,
      key: `pudo`
    });
  }

  return fields;
};

const shipmentStatusNew = computed(() => shipmentDetails.value.status === ShipmentStatus.New);
const senderLocationId = computed(() => fulfillmentDetails.value.sender_location_id);
const recipientLocationId = computed(() => fulfillmentDetails.value.recipient_location_id);
const isEditable = computed(
  () =>
    (store.getters['auth/isAdmin'] || store.getters['auth/isPacker']) &&
    (props.value.shipmentId ? shipmentStatusNew : allowToEditFulfillmentAddress(fulfillmentDetails.value.status))
);
const allowEditing = (fields, locationId) => {
  const editableFieldsForLocationId = ['name', 'email', 'phone'];
  const nonEditableKeys = ['pudo', 'fulfillment_method'];

  return fields.map((field) => {
    const isNonEditableField = nonEditableKeys.some((key) => field.key.includes(key));
    const isFieldEditable = editableFieldsForLocationId.some((editableField) => field.key.includes(editableField));
    const editable = !isNonEditableField && (!locationId || isFieldEditable);

    return {
      ...field,
      editable
    };
  });
};

const senderFields = computed(() =>
  allowEditing(getAddresses(fulfillmentDetails, AddressType.SENDER), senderLocationId.value)
);
const recipientFields = computed(() =>
  allowEditing(getAddresses(fulfillmentDetails, AddressType.RECIPIENT), recipientLocationId.value)
);
const billing = computed(() => {
  return getAddresses(
    {
      value: {
        billing: orderDetails.value.customer,
        billing_address: orderDetails.value.billing_address
      }
    },
    AddressType.BILLING
  );
});
const allFields = computed(() => [
  {
    fields: senderFields.value,
    icon: BringgFontIcons.Send,
    title: t('SHIPMENT_DETAILS.SENDER')
  },
  {
    fields: recipientFields.value,
    icon: BringgFontIcons.Location,
    title: t('SHIPMENT_DETAILS.RECIPIENT')
  },
  {
    fields: billing.value,
    icon: BringgFontIcons.Recipt,
    title: t('SHIPMENT_DETAILS.BILLING')
  }
]);

const initialData = computed(() => {
  const result = {};

  [...senderFields.value, ...recipientFields.value].forEach(({ key, value, editable }) => {
    editable && set(result, key, value);
  });

  return result;
});

const schema = computed(() => {
  const address = object({
    company: string()
      .when('address.company', (_, schema, options) => {
        if (options.value?.length > 0) {
          return string().min(2).max(35).label(t('ADD_LOCATION.COMPANY'));
        } else {
          return string().nullable();
        }
      })
      .label(t('ADD_LOCATION.COMPANY')),
    street1: string().required().min(2).max(35).label(t('ADD_LOCATION.ADDRESS_LINE_1')),
    street2: string()
      .when('address.street2', (_, schema, options) => {
        if (options.value?.length > 0) {
          return string().min(1).max(35).label(t('ADD_LOCATION.ADDRESS_LINE_2'));
        } else {
          return string().nullable();
        }
      })
      .label(t('ADD_LOCATION.ADDRESS_LINE_2')),
    city: string().required().min(2).max(35).label(t('ADD_LOCATION.CITY')),
    state: string()
      .when('address.state', (_, schema, options) => {
        if (options.value?.length > 0) {
          return string().min(2).max(35).label(t('ADD_LOCATION.STATE'));
        } else {
          return string().nullable();
        }
      })
      .label(t('ADD_LOCATION.STATE')),
    postal_code: string().min(2).max(20).required().label(t('ADD_LOCATION.POST_CODE')),
    country: string().required().label(t('ADD_LOCATION.COUNTRY'))
  });

  const customer = object({
    name: string().min(2).max(100).required().label(t('ADD_LOCATION.NAME')),
    phone: string()
      .min(10)
      .max(15)
      .matches(phoneRegex, { message: t('INVALID_PHONE') })
      .required()
      .label(t('ADD_LOCATION.PHONE')),
    email: string().nullable().email().label(t('EMAIL'))
  });

  const schemaObject = {
    sender: customer,
    recipient: customer
  };

  if (!senderLocationId.value) {
    schemaObject.sender_address = address;
  }

  if (!recipientLocationId.value) {
    schemaObject.recipient_address = address;
  }

  return object({
    ...schemaObject
  });
});

const error = computed(() => store.state.fulfillments.error);

const { resetForm, handleSubmit, meta, controlledValues } = useForm({
  initialValues: initialData.value,
  validationSchema: schema
});

const toggleEdit = () => {
  isEdit.value = !isEdit.value;
};

const allowToEditFulfillmentAddress = (status) => ['New', 'PartiallyFulfilled'].includes(status);

const loading = computed(() => store.state.fulfillments.loading);

const findDuplicateFulfillments = (fulfillmentList, targetFulfillment) => {
  const compareProperties = ['sender_address', 'recipient_address', 'sender', 'recipient'];
  const propertiesToIgnore = ['created_at', 'updated_at', 'id', 'user_id'];

  const omitNestedProperties = (obj) =>
    Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, omit(value, propertiesToIgnore)]));

  const target = omitNestedProperties(pick(targetFulfillment, compareProperties));

  return fulfillmentList.filter(
    (fulfillment) =>
      fulfillment.id !== targetFulfillment.id &&
      allowToEditFulfillmentAddress(fulfillment.status) &&
      isEqual(omitNestedProperties(pick(fulfillment, compareProperties)), target)
  );
};

const preSubmit = () => {
  duplicates.value = findDuplicateFulfillments(fulfillments.value, fulfillmentDetails.value);
  if (duplicates.value.length) {
    showOrderMessage.value = true;

    return;
  }
  submit();
};

const saveToOrder = () => {
  saveChangesToOrderLevel.value = true;
  showOrderMessage.value = false;
  submit();
};
const saveOnly = () => {
  saveChangesToOrderLevel.value = false;
  showOrderMessage.value = false;
  submit();
};

const updateFulfillment = async (values) => {
  const { orderId, fulfillmentId } = props.value;

  await store.dispatch('fulfillments/updateFulfillmentDetails', { orderId, fulfillmentId, data: values });

  if (saveChangesToOrderLevel.value) {
    for (const duplicate of duplicates.value) {
      await store.dispatch('fulfillments/updateFulfillmentDetails', {
        orderId,
        fulfillmentId: duplicate.id,
        data: values
      });
    }

    await store.dispatch('fulfillments/loadOrderFulfillments', orderId);
  }
};

const submit = handleSubmit.withControlled(async (values, actions) => {
  await updateFulfillment(values);

  if (!error.value) {
    actions.resetForm({ values });
    NotificationService.addNotification({ message: t('ADDRESS_DETAILS.CHANGES_HAVE_BEEN_SAVED') });
    emit('close');
    emit('open-carrier-modal');
  } else {
    actions.resetForm();
  }

  saveChangesToOrderLevel.value = false;
  toggleEdit();
});

const clearError = () => store.commit('fulfillments/clearError');
</script>
<style>
.max-width-220 {
  max-width: 220px;
}
</style>
