<template>
  <div
    :data-id="`shipment-card-${value.id}`"
    class="relative"
    @mouseenter="hover = true"
    @mouseleave="hover = false">
    <div
      v-if="hover"
      class="absolute inset-0 bg-blue-25 flex justify-center items-center">
      <router-link
        :key="value.id"
        :to="{
          name: 'shipment-details',
          params: { id: value.id, orderId: value.orderId, fulfillmentId: value.fulfillmentId }
        }">
        <div class="p-4 flex-1 text-sm font-semibold text-blue-400 flex items-center text-center">
          <div>
            <div>{{ t('VIEW') }} {{ t('SHIPMENT_DETAILS.SHIPMENT') }} {{ t('DETAILS') }}</div>
            <div>{{ t('SHIPMENT_DETAILS.SHIPMENT') }} #{{ value.id }}</div>
          </div>
          <Icon
            icon-name="chevron-right"
            class="text-2xl" />
        </div>
      </router-link>
    </div>
    <div :class="classList">
      <div class="flex justify-between items-start">
        <div class="flex-1 flex items-center text-sm font-semibold text-gray-800 capitalize">
          <img
            v-if="value.carrier_logo"
            class="w-11 h-11 mr-2 rounded"
            :src="value.carrier_logo"
            :alt="`logo ${value.carrier}`" />
          <span v-if="value.carrier">{{ value.carrier }}, {{ value.service }}</span>
        </div>
        <div class="flex-none">
          <badge :type="statusBadgeTypeMapping[value.status]">
            {{ t(value.status) }}
          </badge>
        </div>
      </div>
      <ul class="my-1 text-sm font-light">
        <li
          v-for="item in infoData"
          :key="item">
          <template v-if="item.label">
            {{ t(item.label) + ' ' }}
          </template>
          <b>
            {{ item.value }}
          </b>
        </li>
      </ul>
      <span
        data-id="shipment-description"
        class="text-xm text-gray-700">
        {{ description }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentCard',
  inheritAttrs: false
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineProps, ref, useAttrs } from 'vue';

import { statusBadgeTypeMapping } from '../../../enums';
import twMerge from '../../../twMerge';
import Badge from '../../atoms/Badge';
import Icon from '../../atoms/Icon';

const { t } = useTranslation();
const attrs = useAttrs();

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
});

const hover = ref(false);

const classList = computed(() => twMerge('p-4', attrs.class));

const infoData = computed(() =>
  [
    { label: t('SHIPMENT_DETAILS.SHIPMENT'), value: props.value.id },
    { label: t('SHIPMENT_DETAILS.SOURCE_ID'), value: props.value.source_id },
    { label: t('SHIPMENT_DETAILS.REFERENCE'), value: props.value.reference }
  ].map((item) => ({
    label: item.label,
    value: item.value ? `#${item.value}` : 'n/a'
  }))
);

const description = computed(() => {
  const result = [`${props.value.packages || 0} ${t('ORDER_DETAILS.PACKAGES')}`];

  if (props.value.items) {
    const itemsDescription = [`${props.value.items.length} ${t('ORDER_DETAILS.ITEMS')}`];
    const itemsDetails = props.value.items.map(({ count, product: { name } }) => `${count} ${name}`).join(', ');

    if (itemsDetails) {
      itemsDescription.push(itemsDetails);
    }

    result.push(itemsDescription.join(': '));
  }

  return result.join(', ');
});
</script>
