import { executeRequest } from '../../utils';

const state = {
  items: new Map(),
  created: null,
  loading: false,
  error: ''
};

const getters = {
  items(state) {
    return Array.from(state.items.values());
  },
  created(state) {
    return state.created;
  }
};

const actions = {
  async loadApiApps(options) {
    const request = {
      request: {
        ethod: 'GET',
        url: '/oauth/applications'
      },
      options,
      errorMessage: 'API_APPS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setApiApps', response.data));
  },

  async createApiApp(options, data) {
    const request = {
      request: {
        method: 'POST',
        url: `/oauth/applications`,
        data
      },
      options,
      errorMessage: 'API_APPS_CREATING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setApiApp', response.data));
  },

  async enableApiApp(options, id) {
    const request = {
      request: {
        method: 'POST',
        url: `/oauth/applications/enable/${id}`
      },
      options,
      errorMessage: 'API_APPS_ENABLING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setApiApp', response.data));
  },

  async disableApiApp(options, id) {
    const request = {
      request: {
        method: 'POST',
        url: `/oauth/applications/disable/${id}`
      },
      options,
      errorMessage: 'API_APPS_DISABLING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setApiApp', response.data));
  },

  async removeApiApp(options, data) {
    const request = {
      request: {
        method: 'DELETE',
        url: `/oauth/applications/${data.id}`,
        data
      },
      options,
      errorMessage: 'API_APPS_REMOVING_FAIL'
    };

    await executeRequest(request, () => options.commit('removeApiApp', data));
  }
};

const mutations = {
  setApiApps(state, apiApps) {
    const apiAppsEntries = apiApps.map((apiApp) => [apiApp.id, apiApp]);

    state.items = new Map(apiAppsEntries);
  },

  removeApiApp(state, apiApp) {
    state.items.delete(apiApp.id);
  },

  setApiApp(state, apiApp) {
    state.items.set(apiApp.id, apiApp);
    state.created = apiApp;
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
