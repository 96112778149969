import axios from 'axios';
import { filter, keyBy } from 'lodash';

import { CustomerNotificationProvider } from '@/components/organisms/CustomerNotification/constants';

const state = {
  isLoaded: false,
  customerNotifications: []
};

const getters = {
  sms(state) {
    return keyBy(filter(state.customerNotifications, { provider: CustomerNotificationProvider.SMS }), 'event_type');
  },

  email(state) {
    return keyBy(filter(state.customerNotifications, { provider: CustomerNotificationProvider.EMAIL }), 'event_type');
  }
};

const actions = {
  async loadCustomerNotifications({ commit, state, rootGetters }) {
    if (state.isLoaded) {
      return;
    }

    const response = await axios.get(
      `${rootGetters['auth/apiUrl']}/deliveryhub-orders-service/v1/customer-notifications`
    );

    commit('setCustomerNotifications', response.data);
    commit('setIsLoaded', true);
  },

  async saveCustomerNotification({ commit, rootGetters }, { item, values }) {
    if (item.id) {
      const { data: updated } = await axios.put(
        `${rootGetters['auth/apiUrl']}/deliveryhub-orders-service/v1/customer-notifications/${item.id}`,
        values
      );

      commit('updateCustomerNotification', { item, updated });
    } else {
      const { data: added } = await axios.post(
        `${rootGetters['auth/apiUrl']}/deliveryhub-orders-service/v1/customer-notifications`,
        values
      );

      commit('addCustomerNotification', { added });
    }
  }
};

const mutations = {
  setIsLoaded(state, isLoaded) {
    state.isLoaded = isLoaded;
  },
  setCustomerNotifications(state, customerNotifications) {
    state.customerNotifications = customerNotifications;
  },
  addCustomerNotification(state, { added }) {
    state.customerNotifications = [...state.customerNotifications, added];
  },
  updateCustomerNotification(state, { item, updated }) {
    state.customerNotifications = state.customerNotifications.map((customerNotification) =>
      customerNotification === item ? updated : customerNotification
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
