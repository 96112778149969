<template>
  <div class="h-full overflow-hidden">
    <div
      :class="{
        'without-header': state.withoutHeader,
        'h-full': !state.withoutHeader
      }">
      <iframe
        ref="frame"
        class="h-full w-full border-0"
        :src="src"
        sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-scripts allow-same-origin allow-downloads allow-popups" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsFrame'
};
</script>

<script setup>
import { WindowEventTypes } from '@bringg/frontend-utils';
import { onBeforeMount, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const frame = ref(null);

import router from '@/router';

const props = defineProps({
  routeName: {
    type: String, // AnalyticsRouteName enum from src/constants.js
    required: true
  },
  paths: {
    type: Array,
    required: true
  }
});

const isEdge = window.location.href.includes('edge');
const frameAddress = `https://${isEdge ? 'edge-app' : 'app'}.bringg.com/apps/analytics/reports/index.html`;
// const frameAddress = `http://localhost:3005`; // for local development
const src = ref(``);

const state = reactive({
  withoutHeader: true,
  src: ''
});

const messagesFromAnalyticsFrame = new Set([
  WindowEventTypes.RequestCredentials,
  WindowEventTypes.RequestInitialData,
  WindowEventTypes.LocationChanged
]);

const onMessage = (event) => {
  if (!messagesFromAnalyticsFrame.has(event.data) && !messagesFromAnalyticsFrame.has(event.data?.type)) {
    return;
  }

  const { data } = event;
  const contentWindow = frame.value.contentWindow;

  const token = store.getters['auth/token'];
  const region = store.getters['auth/region'];
  const { language } = store.getters['auth/user'];
  const { country_code: countryCode } = store.getters['auth/merchantConfiguration'];

  if (data === WindowEventTypes.RequestCredentials) {
    contentWindow.postMessage(
      {
        type: WindowEventTypes.Credentials,
        token,
        region
      },
      '*'
    );

    return;
  }

  if (data === WindowEventTypes.RequestInitialData) {
    const initialRoute = props.paths.join('/');

    contentWindow.postMessage(
      {
        type: WindowEventTypes.InitialData,
        language,
        countryCode,
        parentApp: 'delivery-hub',
        initialRoute
      },
      '*'
    );

    return;
  }

  if (data?.type === WindowEventTypes.LocationChanged) {
    const { path } = data;

    const paths = decodeURIComponent(path).split('/');

    // Those are report routes. For dashboards, there is no need for this logic,
    // since dashboards page in analytics-app has no header conflicting with delivery-hub app
    state.withoutHeader = ['my', 'system', 'legacy'].includes(path);

    router.push({ name: props.routeName, params: { paths } });
  }
};

onBeforeMount(async () => {
  await store.dispatch('auth/getMerchantConfiguration');
  src.value = frameAddress;
  window.addEventListener('message', onMessage, false);
});

onBeforeUnmount(() => {
  window.removeEventListener('message', onMessage, false);
});

watch(
  () => props.paths,
  (paths) => {
    const contentWindow = frame.value.contentWindow;
    const path = paths.join('/');

    contentWindow.postMessage(
      {
        type: WindowEventTypes.LocationChanged,
        path
      },
      '*'
    );
  }
);
</script>

<style lang="scss" scoped>
.without-header {
  margin-top: -56px;
  height: calc(100% + 56px);
}
</style>
