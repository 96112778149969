<template>
  <div class="h-full flex flex-col">
    <div class="flex-1 px-10 pb-12">
      <div class="title">{{ t('PROOF_OF_DELIVERY_MODAL.TITLE') }}</div>
      <div class="my-5 flex flex-row divide-x-2 divide-gray-300">
        <template
          v-for="(item, index) in documentIdFields"
          :key="index">
          <span
            class="flex items-center"
            :class="[index === 0 ? 'pr-4' : 'px-4']">
            <span class="label-color mr-2">{{ item.label }}:</span>
            <span class="value-color">{{ item.value }}</span>
          </span>
        </template>
      </div>
      <div
        v-for="(field, index) in documentsData"
        :key="index"
        class="mb-2 flex items-center">
        <span class="label-color mr-1">
          <b-icon
            class="text-lg"
            :icon-name="field.icon" />
          {{ field.label }}:
        </span>
        <span class="value-color">{{ field.value }}</span>
      </div>
      <template v-if="images.length">
        <div class="attach-files-label mt-6 mb-4">{{ t('PROOF_OF_DELIVERY_MODAL.ATTACHED_FILES_LABEL') }}:</div>
        <div class="flex pl-2 gap-4">
          <div
            v-for="(image, index) in images"
            :key="index"
            class="mr-4 image-container overflow-hidden p-2 gap-0 flex justify-center items-center cursor-zoom-in"
            @click="openImageModal(image)">
            <img
              :src="image"
              alt="Image" />
          </div>
        </div>
      </template>
    </div>
    <div class="sticky bottom-0 bg-white flex items-center px-6 py-3 shadow-top">
      <div class="ml-auto"></div>
      <b-button
        data-id="print-all"
        class="h-8 px-6 py-1 bg-blue-400 text-white border-none text-base font-medium self-end"
        @click="downloadAll">
        <b-icon
          class="text-lg"
          :icon-name="BringgFontIcons.Download" />
        {{ t('PROOF_OF_DELIVERY_MODAL.DOWNLOAD_ALL_FILES_BUTTON') }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProofOfDelivery'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';

import BButton from '@/components/atoms/Button';
import BIcon from '@/components/atoms/Icon';
import { formatDateTime } from '@/utils';

const { t } = useTranslation();

const props = defineProps({
  documents: {
    type: Array,
    required: true
  }
});

const store = useStore();

const pods = computed(() => store.getters['shipments/getPODs']);

const recipientLabel = computed(() => {
  if (!pods.value) {
    return null;
  }

  const set = new Set(pods.value.map((pod) => pod.recipient_name));

  return [...set].join(', ');
});

const deliveredAt = computed(() => {
  if (!pods.value) {
    return null;
  }

  return pods.value[0].received_at;
});

const images = computed(() => pods.value?.map((pod) => pod?.document)?.filter(Boolean) ?? []);

const recipeintAddress = computed(() => {
  const fulfillmentDetails = store.state.fulfillments.itemDetails;

  if (!fulfillmentDetails?.recipient_address) {
    return '-';
  }

  const { street1, city, state, postal_code } = fulfillmentDetails.recipient_address;

  let addressString = `${street1}, ${city},`;

  if (state && state.trim() !== '') {
    addressString += ` ${state}`;
  }

  addressString += ` ${postal_code}`;

  return addressString;
});

const trackingNumbers = computed(() => {
  return props.documents
    .flatMap((document) => [document.trackingNumber, ...document.packages.map((pkg) => pkg.trackingNumber)])
    .filter((trackingNumber) => trackingNumber)
    .join(', ');
});

const orderDetails = computed(() => store.state.orders.itemDetails);
const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);
const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);

const documentIdFields = computed(() => {
  return [
    { label: t('SHIPMENT_DETAILS.ORDER'), value: orderDetails.value.source_id || props.documents[0].orderId },
    {
      label: t('SHIPMENT_DETAILS.FULFILLMENT'),
      value: fulfillmentDetails.value.source_id || props.documents[0].fulfillmentId
    },
    { label: t('SHIPMENT_DETAILS.SHIPMENT'), value: shipmentDetails.value.source_id || props.documents[0].id }
  ];
});

const documentsData = computed(() => [
  {
    label: t('PROOF_OF_DELIVERY_MODAL.RECIPIENT_LABEL'),
    value: recipientLabel.value || '-',
    icon: BringgFontIcons.User
  },
  { label: t('ADDRESS.ADDRESS'), value: recipeintAddress.value, icon: BringgFontIcons.Home },
  {
    label: t('PROOF_OF_DELIVERY_MODAL.TRACKING_NUMBERS_LABEL'),
    value: trackingNumbers.value,
    icon: BringgFontIcons.Radar
  },
  {
    label: t('PROOF_OF_DELIVERY_MODAL.DELIVERED_AT_LABEL'),
    value: deliveredAt.value ? formatDateTime(deliveredAt.value, 'MMM. d, Y HH:mm a') : '-',
    icon: BringgFontIcons.Clock
  }
]);

const openImageModal = (src) => store.dispatch('imageModal/setSource', { src });

const downloadAll = async () => {
  if (!pods.value.length || !images.value.length) {
    return window.print();
  }

  return Promise.allSettled(
    pods.value
      .filter((pod) => Boolean(pod.document))
      .map(async (pod, i) => {
        const name = `POD image ${i + 1}`;

        const image = await fetch(pod.document);
        const blob = await image.blob();
        const imageURL = URL.createObjectURL(blob);
        const mimeType = blob.type;

        const extension = mimeType === 'image/png' ? 'png' : 'jpg';

        const downloadLink = document.createElement('a');

        downloadLink.href = imageURL;
        downloadLink.download = `${name}.${extension}`;

        document.body.appendChild(downloadLink);

        downloadLink.click();
        document.body.appendChild(downloadLink);
      })
  );
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--color-neutrals-800, #2d3748);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.label-color {
  color: #4a5568;
}

.value-color {
  color: #718096;
}

.attach-files-label {
  color: var(--color-neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
}
.image-container {
  width: 88px;
  height: 88px;
  border-radius: 7.5px;
  background: var(--color-surface-background, #fff);
  box-shadow:
    0 3.75px 7.5px 0 rgba(0, 51, 80, 0.16),
    0 0 1.875px 0 rgba(0, 51, 80, 0.08);

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.cursor-zoom-in {
  cursor: zoom-in;
}
</style>
