<template>
  <b-table
    class="my-6"
    :wide-loader="shouldShowLoader && useRules"
    :show-delimiter="!withRules"
    :style="styleTable"
    :show-total="!withRules"
    :cell-class-function="handleSelectedRowClass"
    :fields="fieldsToUse"
    :no-data-placeholder="useRules ? t('CARRIER_ASSIGN_MODAL.NO_RULES') : ''"
    :force-update="ratesUpdated"
    :total="options.length"
    :data="useRules ? optionsWithRule : options"
    :total-label="t('CARRIER_ASSIGN_MODAL.TOTAL_LABEL')"
    carrier-table
    default-expand
    @click-row="(index) => selectRow(index)">
    <template
      v-if="!withRules"
      #right-filter-bar>
      <b-input
        v-model:value="data.search"
        data-id="search"
        input-class="h-8 py-1"
        :placeholder="t('CARRIER_ASSIGN_MODAL.SEARCH_PLACEHOLDER')"
        :validation="false">
        <template #end-icon>
          <icon
            icon-name="search"
            class="text-base" />
        </template>
      </b-input>
    </template>
    <template
      v-if="!withRules"
      #info-bar>
      <div>
        <div
          v-if="(error || innerError) && !withRules && !isCarrierAssignedToShipment(shipmentDetails)"
          class="">
          <badge
            type="danger"
            class="p-1 mt-2 mb-5">
            <icon
              icon-name="warning"
              class="text-base text-red-500 pt-0.5 pr-2" />
            <span class="font-normal text-sm flex text-left">
              {{ error || innerError }}
            </span>
          </badge>
        </div>
        <div
          v-if="
            !isCarrierAssignedToShipment(shipmentDetails) &&
            !withRules &&
            replaceCarrierError &&
            Object.keys(rejectedServices).length
          ">
          <div class="flex flex-col">
            <span
              v-if="!updateMode"
              class="mr-auto font-bold text-base">
              {{ t('CARRIER_ASSIGN_MODAL.CREATED') }}
            </span>
            <div class="flex flex-col w-full text-red-500 mr-auto font-normal text-sm pb-1 pr-1 mb-5">
              <span class="mr-auto">
                {{ `${serviceName} ${t('CARRIER_ASSIGN_MODAL.ERROR_TITLE')}` }}
              </span>
              <span class="mr-auto">
                {{ `${t('CARRIER_ASSIGN_MODAL.REJECTION_REASON')} ${replaceCarrierError}` }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #cell(name)="{ row, columnIndex, rowIndex }">
      <div class="flex flex-row items-center">
        <template v-if="row && row.children && columnIndex === 0">
          <icon
            class="text-base mr-2 cursor-pointer"
            :icon-name="row.opened ? 'chevron-up' : 'chevron'"
            @click="row.opened = !row.opened" />
          <span class="font-medium">
            {{ useRules ? t('CARRIER_ASSIGN_MODAL.ACCOUNTS_PRIORITIZED') : row.carrier_name }} [{{ row.name }}]
          </span>
        </template>
        <template v-else>
          <div class="flex flex-row items-center font-normal">
            <input
              v-model="picked"
              class="mr-4 mb-0.5 ml-1 text-blue-400"
              type="radio"
              :value="{ carrier: row.carrier, service: row.type, id: row.id }" />
            <Tooltip placement="topRight">
              <template #content>
                <icon
                  v-if="!row.children && shouldSetwarningIcon(row)"
                  class="relative text-md top-0.5 mr-1"
                  :class="handleColorClass(row)"
                  :icon-name="BringgFontIcons.Warning" />
              </template>
              <template #title>
                {{ getTooltipText(row) }}
              </template>
            </Tooltip>
            <span>
              {{
                useRules
                  ? `${rowIndex[1] + 1}. ${row.carrier_name} [${row.shipping_account_name}] / ${row.name}`
                  : `${row.name}`
              }}
            </span>
          </div>
        </template>
      </div>
    </template>
    <template #cell(date)="{ row }">
      <div
        v-if="row.loading"
        class="loader w-24 rounded" />
      <template v-else-if="row && row.children" />
      <template v-else>
        {{ row.date || '-' }}
      </template>
    </template>
    <template #cell(time)="{ row }">
      <div
        v-if="row.loading"
        class="loader w-16 rounded" />
      <template v-else-if="row && row.children" />
      <template v-else>
        {{ row.time || '-' }}
      </template>
    </template>
    <template #cell(cost)="{ row }">
      <div
        v-if="row.loading"
        class="loader w-10 rounded" />
      <template v-else-if="row && row.children" />
      <template v-else>
        {{ row.cost || '-' }}
      </template>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'CarriersTable'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

import Badge from '@/components/atoms/Badge';
import Icon from '@/components/atoms/Icon';
import BInput from '@/components/atoms/Input';
import Tooltip from '@/components/atoms/ToolTip/index.vue';
import BTable from '@/components/organisms/Table';
import { getLastEvent, isCarrierAssignedToShipment, trimErrorText } from '@/utils';

const { t } = useTranslation();
const store = useStore();

const emit = defineEmits(['has-same-carrier', 'select-carrier', 'rules-found', 'search', 'predefine-carrier']);

const props = defineProps({
  afterEditingItems: {
    type: Boolean,
    default: false
  },
  updateMode: {
    type: Boolean,
    default: false
  },
  // this component should use rules
  useRules: {
    type: Boolean,
    default: false
  },
  // parent modal already have rules component
  withRules: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  },
  packages: {
    type: Array,
    default: () => []
  },
  labelType: {
    type: String,
    default: ''
  },
  clearPicked: {
    type: Boolean,
    default: false
  },
  tableToUpdate: {
    type: String,
    default: ''
  },
  predefinedPicked: {
    type: Object,
    default: () => ({})
  },
  search: {
    type: String,
    default: ''
  }
});

const data = reactive({
  val: {},
  search: '',
  ruleName: ''
});

const picked = ref('');
const shouldShowLoader = ref(true);
const innerError = ref('');

const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);
const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);
const ruleName = computed(() => store.state.shippingAccounts.ruleName);

const shippingAccountId = computed(() => shipmentDetails.value.shipping_account_id);

const rates = computed(() => store.state.shippingAccounts.rates);
const ratesUpdated = computed(() => store.state.shippingAccounts.updated);

const recipientCountry = computed(() => fulfillmentDetails.value.recipient_address.country);

const predefinedCarrier = computed(() => ({
  carrier: shipmentDetails.value.carrier,
  service: shipmentDetails.value.service,
  id: shipmentDetails.value.shipping_account_id
}));

const predefinedFulfillmentCarrier = computed(() => ({
  carrier: fulfillmentDetails.value.shipping_account?.carrier,
  service: fulfillmentDetails.value.service,
  id: fulfillmentDetails.value.shipping_account_id
}));

const shippingAccountsByRule = computed(() => store.state.shippingAccounts.shippingAccountsByRule);

const options = computed(() =>
  rates.value
    .filter((item) => {
      const search = data.search || props.search;

      const shippingAccountCountryMatches = item.country === recipientCountry.value;
      const searchMatches = (item.name + item.carrier_name).toLowerCase().includes(search.toLowerCase());

      return (
        (shippingAccountCountryMatches && searchMatches) ||
        (item.children &&
          item.children.some((subItem) => {
            const childShippingAccountCountryMatches = subItem.country === recipientCountry.value;
            const childSearchMatches = subItem.name.toLowerCase().includes(search.toLowerCase());

            return childShippingAccountCountryMatches && childSearchMatches;
          }))
      );
    })
    .map((item) => {
      if (!item.children) {
        return item;
      }

      return {
        ...item,
        children: item.children.filter((subItem) => subItem.isPredefined)
      };
    })
);

const getTooltipText = (row) => {
  const event = rejectionEvents.value?.find(
    (event) => event.shipping_account_id === row.id && event.service === row.type
  );

  return event ? event.value : '';
};

const selectRow = (rowIndex) => {
  clearError();

  const { row, parent } = getRow(rowIndex, props.useRules ? optionsWithRule : options);

  if (!row) {
    return;
  }

  data.val = {
    id: row.id,
    carrier: row.carrier,
    service: row.type,
    carrierName: row.carrier_name ?? parent?.name,
    serviceName: row.name
  };

  picked.value = { carrier: row.carrier, service: row.type, id: row.id };

  emit('select-carrier', {
    value: data.val,
    useRules: props.useRules,
    name: ruleName.value,
    hasSameCarrier: props.updateMode ? false : hasSameCarrier.value
  });
};

const shippingAccountsMap = () => {
  const map = {};

  options.value.forEach((element) => {
    element.children.forEach((child) => {
      if (!map[child.id]) {
        map[child.id] = {};
      }

      map[child.id][child.type] = {
        ...child,
        carrier_name: element.carrier_name,
        table_type: 'rules',
        shipping_account_name: element.name
      };
    });
  });

  return map;
};

const optionsWithRule = computed(() => {
  if (!shippingAccountsByRule.value?.length) {
    return [];
  }

  const map = shippingAccountsMap();

  const children = shippingAccountsByRule.value
    .map((service) => {
      const mappedService = map[service.shipping_account_id]?.[service.service];

      if (mappedService) {
        return mappedService;
      }
    })
    .filter((item) => item !== undefined);

  if (children.length === 0) {
    return [];
  }

  return [
    {
      name: ruleName.value,
      children
    }
  ];
});

const hasSameCarrier = computed(() => {
  if (props.afterEditingItems) {
    return false;
  }

  const sameService = picked.value?.service === predefinedCarrier.value?.service;
  const sameCarrier = picked.value?.carrier === predefinedCarrier.value?.carrier;
  const sameAccountId = picked.value?.id === shippingAccountId.value;

  return Boolean(sameService && sameCarrier && sameAccountId);
});

const rejectionReason = computed(() => {
  if (shipmentDetails.value.shipment_events && Object.keys(shipmentDetails.value.shipment_events).length) {
    return getLastEvent({ events: shipmentDetails.value?.shipment_events, type: 'carrier_assignment_failure' });
  }

  return null;
});

const replaceCarrierError = computed(() => {
  if (!Object.keys(rejectedServices.value).length) {
    return null;
  }

  const text = rejectionReason.value.value;

  return trimErrorText(text);
});

const rejectionEvents = computed(() => {
  const packageCreatedAt = shipmentDetails.value.shipments_packages?.[0]?.created_at;

  if (shipmentDetails.value.shipment_events) {
    return shipmentDetails.value.shipment_events.filter(
      (item) => 'carrier_assignment_failure' === item.type && new Date(item.created_at) >= new Date(packageCreatedAt)
    );
  }

  return {};
});

const rejectedServices = computed(() => {
  if (!rejectionEvents.value?.length) {
    return {};
  }

  return rejectionEvents.value.reduce((result, item) => {
    const { shipping_account_id, service } = item;

    if (shipping_account_id && !result[shipping_account_id]) {
      result[shipping_account_id] = new Set();
    }

    result[shipping_account_id].add(service);

    return result;
  }, {});
});

const serviceName = computed(() => {
  if (!rejectionReason.value) {
    return null;
  }

  const { service, shipping_account_id } = rejectionReason.value;

  for (const carrier of rates.value) {
    const currentService = carrier.children.find(({ id, type }) => id === shipping_account_id && type === service);

    if (currentService) {
      return currentService.name;
    }
  }

  return service || null;
});

const fieldsToUse = computed(() => {
  if (props.withRules && !props.useRules) {
    return additionalFields.value;
  }

  return defaultFields.value;
});

const additionalFields = computed(() => [
  { key: 'name', label: t('CARRIER_ASSIGN_MODAL.MORE_ACCOUNTS') },
  { key: 'date', label: '' },
  { key: 'time', label: '' },
  { key: 'cost', label: '' }
]);

const defaultFields = computed(() => [
  { key: 'name', label: t('CARRIER_ASSIGN_MODAL.CARRIER_FIELD') },
  { key: 'date', label: t('CARRIER_ASSIGN_MODAL.DELIVERY_DATE_FIELD') },
  { key: 'time', label: t('CARRIER_ASSIGN_MODAL.DELIVERY_TIME_FIELD') },
  { key: 'cost', label: t('CARRIER_ASSIGN_MODAL.DELIVERY_COST_FIELD') }
]);

const handleAfterEditingItems = (pickedRef) => {
  if (props.afterEditingItems) {
    data.val = { ...pickedRef.value };
  }
};

const calculateNextRowIndex = (services) => {
  const rejectedIndexesSet = new Set();

  services.forEach((row, index) => {
    if (rejectedServices.value[row.id]?.has(row.type)) {
      rejectedIndexesSet.add(index);
    }
  });

  const remainingIndexes = Array.from({ length: services.length }, (_, index) => index).filter(
    (index) => !rejectedIndexesSet.has(index)
  );

  return remainingIndexes[0];
};

const handleRejections = () => {
  if (!props.useRules) {
    return;
  }

  const services = optionsWithRule.value[0]?.children;

  if (!services?.length) {
    return;
  }

  const index = calculateNextRowIndex(services);

  if (index === undefined) {
    innerError.value = t('CARRIER_ASSIGN_MODAL.ALL_SERVICES_REJECTED');
  } else {
    selectRow([0, index, 0]);
  }
};

const processPredefinedRuleOptions = () => {
  handleRejections();

  handleAfterEditingItems(picked);
};

const processPredefinedDefaultOptions = () => {
  if (!props.withRules) {
    const { carrier, service, id } = predefinedFulfillmentCarrier.value;
    const fulfillmentMethodIsPudo = fulfillmentDetails.value.fulfillment_method === 'pudo';

    picked.value =
      !props.updateMode && fulfillmentMethodIsPudo && carrier && service && id
        ? predefinedFulfillmentCarrier.value
        : predefinedCarrier.value;

    emit('predefine-carrier', picked.value);
  }

  handleAfterEditingItems(picked);
};

const loadShippingRates = async () => await store.dispatch('shippingAccounts/loadShippingRates', props.updateMode);

onMounted(async () => {
  if (!rates.value || !rates.value.length) {
    await loadShippingRates();
  }

  await store.dispatch('users/loadUserSettings');

  const packages = props.updateMode ? shipmentDetails.value.shipments_packages : props.packages;

  if (props.useRules) {
    shouldShowLoader.value = true;

    const shouldUpdateServicesWithRules =
      (props.updateMode && !Array.isArray(shippingAccountsByRule.value)) || !props.updateMode;

    if (shouldUpdateServicesWithRules) {
      await store.dispatch('shippingAccounts/loadShippingAccountsByRule', {
        labelType: props.labelType,
        packages: packages,
        orderId: fulfillmentDetails.value.order_id,
        fulfillmentId: fulfillmentDetails.value.id
      });
    }

    shouldShowLoader.value = false;

    processPredefinedRuleOptions();

    if (optionsWithRule.value.length) {
      emit('rules-found');
    }
  } else if (!shippingAccountsByRule.value?.length) {
    processPredefinedDefaultOptions();
  }
});

const shouldSetwarningIcon = (row) => rejectedServices.value[row.id]?.has(row.type);

const handleColorClass = (row) => {
  const lastFailedCarrier = row.id === rejectionReason.value.shipping_account_id;
  const lastFailedService = row.type === rejectionReason.value.service;

  if (
    rejectionReason.value &&
    lastFailedCarrier &&
    lastFailedService &&
    !isCarrierAssignedToShipment(shipmentDetails.value)
  ) {
    return 'text-red-500';
  }

  return 'text-gray-500';
};

const handleSelectedRow = ([rowIndex, subRowIndex, columnIndex], result) => {
  if (columnIndex === 0) {
    result.push('w-1/2');
  }

  const optionsParam = props.useRules ? optionsWithRule : options;

  const rowIsSelected = isSelectedRow([rowIndex, subRowIndex], optionsParam);

  if (subRowIndex !== null && rowIsSelected) {
    result.push('bg-blue-25');
  }
};

const handleSelectedRowClass = (value) => {
  const result = [];

  handleSelectedRow(value, result);

  return result.join(' ');
};

const getRow = (rowIndex, options) => {
  if (typeof rowIndex === 'number') {
    return null;
  }

  const [parentIndex, subRowIndex] = rowIndex;
  const parent = options.value[parentIndex];
  const row = parent.children[subRowIndex];

  return { row, parent };
};

watch(
  () => rejectedServices.value,
  () => {
    handleRejections();
  }
);

watch(
  () => props.clearPicked,
  (value) => {
    if (value) {
      picked.value = '';
      data.val = {};
    }
  }
);

watch(
  () => data.search,
  (value) => {
    if (props.useRules) {
      emit('search', value);
    }
  }
);

const processSelectedRow = ({ carrier, service, shippingAccountId, row }) => {
  return (
    (carrier === row?.carrier && service === row?.type && shippingAccountId === row?.id) ||
    shouldSelectInUpdateMode(row)
  );
};

const isSelectedRow = (indexes, options) => {
  if (typeof rowIndex === 'number') {
    return false;
  }

  const [parentIndex, subRowIndex] = indexes;
  const parent = options.value[parentIndex];
  const row = parent.children[subRowIndex];
  const { carrier, service, id: shippingAccountId } = data.val;

  return processSelectedRow({ carrier, service, shippingAccountId, row });
};

const clearError = () => {
  store.commit('shipments/clearError');
};

const shouldSelectInUpdateMode = (row) =>
  picked.value?.carrier === row?.carrier && picked.value?.service === row?.type && picked.value?.id === row?.id;

const styleTable = computed(() => (props.useRules || props.withRules ? {} : { height: '55vh', overflowY: 'auto' }));
</script>

<style scoped>
input {
  @apply py-1 !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.loader {
  position: relative;
  height: 20px;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eff4f8 8%, #d7e0e9 18%, #eff4f8 33%);
  background-size: 800px 104px;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}
</style>
