import { executeRequest } from '../../utils';

const state = {
  items: [],
  total: 0,
  loading: false,
  error: ''
};

const getters = {};

const actions = {
  async loadLogs(options, params) {
    const request = {
      request: {
        method: 'GET',
        url: '/deliveryhub-orders-service/v1/logs',
        params
      },
      options,
      errorMessage: 'LOGS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setLogs', response.data);
      options.commit('setLogsTotal', Number(response.headers['total-count']));
    });
  },
  async filterLogs(options, data) {
    const request = {
      request: {
        method: 'POST',
        url: '/deliveryhub-orders-service/v1/logs/filter',
        data
      },
      options,
      errorMessage: 'LOGS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setLogs', response.data);
      options.commit('setLogsTotal', Number(response.headers['total-count']));
    });
  }
};

const mutations = {
  setLogs(state, logs) {
    state.items = logs;
  },
  setLogsTotal(state, value) {
    state.total = value;
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
