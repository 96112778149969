<template>
  <div
    v-if="!isEnabled"
    class="h-full flex justify-center pt-20 px-8">
    <div class="flex flex-col items-center text-center notifications-placeholder">
      <img
        src="../../../assets/img/notifications-illustration.svg"
        alt="Delivery Notifications illustration" />

      <h2 class="font-semibold text-xl text-gray-800 mt-10">{{ t('CUSTOMER_NOTIFICATIONS.PLACEHOLDER_TITLE') }}</h2>
      <p class="text-gray-700 mt-4">{{ t('CUSTOMER_NOTIFICATIONS.PLACEHOLDER_SUBTITLE') }}</p>
      <a
        href="mailto:dhsupport@bringg.com?subject=Delivery%20Notifications"
        class="mt-10 bg-blue-400 px-4 py-2 rounded text-white">
        {{ t('CUSTOMER_NOTIFICATIONS.CONTACT_BRINGG') }}
      </a>

      <a
        href="https://help.bringg.com/delivery-hub/docs/configure-delivery-notifications"
        target="_blank"
        class="text-blue-400 text-sm inline-flex font-semibold mt-4">
        {{ t('CUSTOMER_NOTIFICATIONS.LEARN_MORE') }}
      </a>
    </div>
  </div>

  <div
    v-else
    v-disable-if-not-admin="isAdmin"
    class="h-full overflow-auto flex flex-col">
    <div
      v-if="!store.state.customerNotifications.isLoaded"
      class="px-10 py-4">
      <animated-placeholder :count="3" />
    </div>

    <template v-else>
      <div class="flex justify-between items-center mb-5 pl-10 pt-4">
        <div class="text-sm text-gray-700 max-w-4xl">
          {{ t(`CUSTOMER_NOTIFICATIONS.${provider.toUpperCase()}.HEADING_1`) }}
          <br />
          {{ t(`CUSTOMER_NOTIFICATIONS.${provider.toUpperCase()}.HEADING_2`) }}
          <a
            class="text-blue-400 underline"
            href="/#/account/setup/webhooks">
            {{ t(`CUSTOMER_NOTIFICATIONS.${provider.toUpperCase()}.HEADING_LINK`) }}
          </a>
        </div>
      </div>

      <div
        v-if="store.state.customerNotifications.isLoaded"
        class="flex relative overflow-auto h-full pl-10">
        <div class="notifications-wrapper">
          <div
            v-for="type of customerNotificationsEventType"
            :key="type"
            class="pr-10 pb-6 relative">
            <customer-notification
              ref="forms"
              :provider="provider"
              :type="type"
              :item="store.getters[`customerNotifications/${provider}`][type]"
              @show-preview="onShowPreview"
              @change-preview="onChangePreview"
              @submit="onSubmit" />
          </div>
        </div>

        <div class="sticky top-0 pr-10">
          <template-preview
            v-if="state.previewShown"
            :provider="provider"
            :body="state.previewBody"
            @close="onHidePreview" />
        </div>
      </div>

      <save-discard-footer
        :is-discardable="isDirty"
        :is-submittable="isDirty && isValid"
        :submitting="state.isSaving"
        @submit="onSave"
        @discard="onDiscard" />
    </template>
  </div>

  <modal-unsaved-changes
    :is-dirty="isDirty"
    :is-valid="isValid"
    :is-saving="state.isSaving"
    @save="onSave" />
</template>

<script>
export default {
  name: 'CustomerNotifications'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import AnimatedPlaceholder from '@/components/atoms/AnimatedPlaceholder';
import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter';
import TemplatePreview from '@/components/molecules/TemplatePreview';
import CustomerNotification from '@/components/organisms/CustomerNotification';
import {
  CustomerNotificationEmailVariables,
  CustomerNotificationEventType,
  CustomerNotificationProvider,
  CustomerNotificationProviderByRoute,
  CustomerNotificationVariables
} from '@/components/organisms/CustomerNotification/constants';
import ModalUnsavedChanges from '@/components/organisms/ModalUnsavedChanges';

const store = useStore();
const route = useRoute();
const { t } = useTranslation();

const provider = CustomerNotificationProviderByRoute[route.name];

const customerNotificationVariables = {
  [CustomerNotificationProvider.EMAIL]: CustomerNotificationEmailVariables,
  [CustomerNotificationProvider.SMS]: CustomerNotificationVariables
};

const customerNotificationsEventType = Object.keys(customerNotificationVariables[provider]);

const state = reactive({
  isSaving: false,
  previewShown: false,
  previewBody: null
});

const isAdmin = computed(() => store.getters['auth/isAdmin']);
const isEnabled = computed(() => store.getters['auth/accountSettings'].customer_notifications_enabled);

const forms = ref([]);

const isDirty = computed(() => forms.value.some((form) => form.isDirty()));
const isValid = computed(() => forms.value.every((form) => form.isValid()));

const onDiscard = () => {
  forms.value.forEach((form) => form.reset());
};

const onSave = () => {
  forms.value.forEach((form) => form.submit());
};

const onShowPreview = () => {
  state.previewShown = true;
};

const onHidePreview = () => {
  state.previewShown = false;
};

const onChangePreview = (body) => {
  state.previewBody = body;
};

const onSubmit = async (data) => {
  try {
    state.isSaving = true;
    await store.dispatch('customerNotifications/saveCustomerNotification', data);
  } finally {
    state.isSaving = false;
  }
};

onBeforeMount(() => {
  store.dispatch('customerNotifications/loadCustomerNotifications');
});
</script>

<style scoped>
.notifications-wrapper {
  width: 100%;
  max-width: 882px;
}

.notifications-placeholder {
  width: 100%;
  max-width: 526px;
}
</style>
