<template>
  <div
    v-if="isMounted"
    class="relative flex overflow-hidden flex-col h-full">
    <alert
      v-if="error"
      class="mb-8 absolute inset-x-0 top-0"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>
    <alert
      v-if="notification"
      class="mb-8 absolute inset-x-0 top-0"
      type="success">
      {{ t('CREATE_ORDER.SUCCESSES_MESSAGE') }}
    </alert>
    <teleport to="#pageTitle">
      <div class="flex items-top">
        <span
          class="mr-1 cursor-pointer"
          @click="$router.back()">
          <b-icon
            icon-name="arrow-left"
            class="w-6 h-6 text-blue-500" />
        </span>
        <span class="mr-4">
          {{ t('CREATE_ORDER.TITLE') }}
        </span>
      </div>
    </teleport>

    <div class="px-6 pt-6 flex h-full flex-col overflow-auto">
      <div class="px-6 flex flex-col h-5/6">
        <div class="mb-6 flex item-center gap-2"></div>
        <div class="w-5/6 h-full">
          <textarea
            id="order-data"
            v-model="data.order"
            :validation="false"
            class="mb-4 w-full h-full"></textarea>
        </div>
      </div>
    </div>

    <save-discard-footer
      :is-discardable="!isDiscardChangesDisabled"
      :submitting="creating"
      :submit-label="t('CREATE_BUTTON')"
      @submit="onCreate"
      @discard="onDiscardChanges" />
  </div>
</template>

<script>
export default {
  name: 'CreateOrder'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { isEqual } from 'lodash';
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import Alert from '@/components/atoms/Alert';
import BIcon from '@/components/atoms/Icon';
import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter';
import { useNotification } from '@/composables';

import orderPayload from './orderPayload.json';

const store = useStore();
const { t } = useTranslation();
const { notification, notify } = useNotification();

const data = reactive({});

const isMounted = ref(false);
const creating = ref(false);
const discardingChanges = ref(false);

onMounted(async () => {
  isMounted.value = true;

  const payload = JSON.stringify(orderPayload, null, 4);

  data.order = payload;
  data.orderSnapshotForDiscard = payload;
});

const error = computed(() => store.state.orders.error);

const clearError = () => {
  store.commit('orders/clearError');
};

const onDiscardChanges = async () => {
  discardingChanges.value = true;

  data.order = data.orderSnapshotForDiscard;

  discardingChanges.value = false;
};
const user = computed(() => store.getters['auth/user']);

const onCreate = async () => {
  creating.value = true;

  const evaluated = data.order.replaceAll('{{timestamp}}', Date.now()).replaceAll('{{username}}', user.value.name);

  let payload;

  try {
    payload = JSON.parse(evaluated);
  } catch (error) {
    creating.value = false;
    store.commit('orders/setError', 'Invalid JSON');

    return;
  }

  await store.dispatch('orders/createOrder', { returnError: false, ...payload });

  if (!error.value) {
    notify();
  }

  creating.value = false;
};

const isDiscardChangesDisabled = computed(() => {
  return isEqual(data.orderSnapshotForDiscard, data.order);
});
</script>
