import { ConfigLoader } from '@bringg/frontend-utils';

class Config {
  constructor() {
    this.config = {};
  }

  async load() {
    this.config = await new ConfigLoader().load();
  }

  get(key) {
    return this.config[key];
  }
}

export default new Config();
