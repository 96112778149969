<template>
  <div class="flex flex-row">
    <div>
      <span class="title">{{ t('SHIPMENT_DETAILS.ORDER') }}:</span>
      <router-link
        class="text-blue-400 text-sm"
        :to="{ name: 'order-details', params: { id: shipmentDetails?.order_id } }">
        <span class="ml-1 source-id-title">{{ orderDetails?.source_id ?? shipmentDetails?.order_id }}</span>
      </router-link>
    </div>
    <div class="divider ml-4"></div>
    <div class="ml-4">
      <span class="title">{{ t('SHIPMENT_DETAILS.FULFILLMENT') }}:</span>
      <router-link
        class="text-blue-400 text-sm"
        :to="{
          name: 'fulfillment-details',
          params: { orderId: shipmentDetails?.order_id, id: shipmentDetails?.fulfillment_id }
        }">
        <span class="ml-1 source-id-title">{{ fulfillmentDetails?.source_id ?? shipmentDetails?.fulfillment_id }}</span>
      </router-link>
    </div>
    <div class="divider ml-4"></div>
    <div class="ml-4 flex flex-col justify-center">
      <v-form
        v-slot="{ values, meta }"
        :validation-schema="shipmentSourceIdSchema"
        :initial-values="{ source_id: shipmentDetails?.source_id }"
        @submit="submitShipmentSourceId">
        <div class="text-sm text-gray-800 flex flex-row">
          <span
            class="title"
            :class="isShipmentSourceIdEdited ? ['self-auto'] : ['whitespace-nowrap self-center']">
            {{ t('SHIPMENT_DETAILS.SHIPMENT') }}:
          </span>
          <div
            v-show="isShipmentSourceIdEdited"
            class="flex flex-row justify-center align-center w-full">
            <b-input
              id="source_id"
              :value="values.source_id"
              class="ml-1 w-full"
              size="small"
              input-class="px-2 py-0 h-6">
              <template #end-button>
                <b-button
                  :disabled="!(meta.dirty && meta.valid)"
                  class="bg-transparent text-blue-400 rounded-none h-6 px-3 text-sm border border-l-0 rounded-r border-gray-300"
                  submit>
                  {{ t('SAVE_BUTTON') }}
                </b-button>
              </template>
            </b-input>
          </div>
          <div
            v-show="!isShipmentSourceIdEdited"
            class="flex flex-row">
            <span class="ml-1 source-id">
              {{ shipmentDetails?.source_id ?? '-' }}
            </span>
            <b-icon
              data-id="edit-source-id"
              :icon-name="BringgFontIcons.Pencil"
              class="ml-2 text-blue-400 cursor-pointer"
              @click="isShipmentSourceIdEdited = true" />
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentDetailsHeaderOrder'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { Form as VForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { string } from 'yup';

import BButton from '@/components/atoms/Button';
import BIcon from '@/components/atoms/Icon';
import BInput from '@/components/atoms/Input';
import { NotificationService } from '@/services';

const { t } = useTranslation();
const store = useStore();

const orderDetails = computed(() => store.state.orders.itemDetails);
const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);
const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);
const error = computed(() => store.state.shipments.error);

const isShipmentSourceIdEdited = ref(false);

const shipmentSourceIdSchema = computed(() => ({
  source_id: string().required().min(1).max(255).label(t('SHIPMENT_DETAILS.SOURCE_ID'))
}));

const submitShipmentSourceId = async (value, actions) => {
  const { order_id, fulfillment_id, id } = shipmentDetails.value;

  await store.dispatch('shipments/updateShipment', {
    orderId: order_id,
    fulfillmentId: fulfillment_id,
    shipmentId: id,
    data: {
      source_id: value.source_id
    }
  });

  const notification = {
    message: error.value || 'SHIPMENT_DETAILS.SOURCE_ID_SAVED_NOTIFICATION',
    ...(error.value && { type: 'danger' })
  };

  NotificationService.addNotification(notification);

  isShipmentSourceIdEdited.value = false;
  actions.resetForm(value);
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--Color-Neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.source-id-title {
  color: var(--Color-Primary-400, #3091ce);
  text-align: center;

  font-family: 'Open Sans', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.divider {
  height: 24px;
  stroke-width: 1px;
  stroke: var(--Color-Neutrals-400, #cbd5e0);
}

.source-id {
  color: var(--Color-Neutrals-700, #4a5568);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
