import { executeRequest } from '../../utils';

const state = {
  items: new Map(),
  events: [],
  loading: false,
  error: '',
  isLoaded: false
};

const getters = {
  items(state) {
    return Array.from(state.items.values());
  }
};

const actions = {
  async loadWebhooks(options) {
    if (options.state.isLoaded) {
      return;
    }

    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/webhooks?limit=100`
      },
      options,
      errorMessage: 'WEBHOOKS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setWebhooks', response.data));
    options.commit('setIsLoaded', true);
  },

  async loadWebhooksEvents(options) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/webhooks/events`
      },
      options,
      errorMessage: 'WEBHOOKS_EVENTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setWebhooksEvents', response.data));
  },

  async createWebhook(options, data) {
    const request = {
      request: {
        method: 'post',
        url: `/deliveryhub-orders-service/v1/webhooks`,
        data
      },
      options,
      errorMessage: 'WEBHOOK_CREATING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setWebhook', response.data));
  },

  async editWebhook(options, data) {
    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/webhooks/${data.id}`,
        data
      },
      options,
      errorMessage: 'WEBHOOK_EDITING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setWebhook', response.data));
  },

  async deleteWebhook(options, data) {
    const request = {
      request: {
        method: 'DELETE',
        url: `/deliveryhub-orders-service/v1/webhooks/${data.id}`
      },
      options,
      errorMessage: 'WEBHOOK_DELETING_FAIL'
    };

    await executeRequest(request, () => options.commit('deleteWebhook', data));
  }
};

const mutations = {
  setWebhooks(state, webhooks) {
    const webhookEntries = webhooks.map((item) => [item.id, item]);

    state.items = new Map(webhookEntries);
  },

  setWebhooksEvents(state, events) {
    state.events = events;
  },

  deleteWebhook(state, item) {
    state.items.delete(item.id);
  },

  setWebhook(state, item) {
    state.items.set(item.id, item);
  },

  clearError(state) {
    state.error = '';
  },

  setIsLoaded(state, isLoaded) {
    state.isLoaded = isLoaded;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
