<template>
  <div
    v-if="isMounted"
    class="relative h-full">
    <teleport to="#pageTitle">
      <div class="flex items-top">
        <span class="mr-4">
          {{ t('CREATE_SHIPMENT.TITLE') }}
        </span>
      </div>
    </teleport>
    <alert
      v-if="error"
      class="mb-9 absolute inset-x-0 top-0 z-10"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>
    <b-modal
      :value="!!carrierRejectedReason"
      @close="closeCarrierRejectedModal">
      <template #title>
        <h3 class="px-6 py-4 text-xl font-semibold text-gray-800">{{ t('CREATE_SHIPMENT.CARRIER_REJECTED') }}</h3>
      </template>

      <template #body>
        <div class="px-6 text-base text-gray-700">
          <p>{{ t('CREATE_SHIPMENT.CARRIER_REJECTED_TITLE') }}</p>
          <p class="mt-2">{{ t('CREATE_SHIPMENT.REJECTED_REASON') }}: {{ carrierRejectedReason }}</p>
        </div>
      </template>
      <template #footer>
        <div class="px-6 py-6 flex justify-end">
          <b-button @click="closeCarrierRejectedModal">Got It</b-button>
        </div>
      </template>
    </b-modal>
    <div class="relative h-full flex flex-col">
      <div class="px-8 py-10 flex-1 overflow-auto">
        <div>
          <h2>
            {{ t('CREATE_SHIPMENT.ORDER_DETAILS') }}
          </h2>
          <div
            :id="SOURCE_IDS"
            class="mt-4 flex flex-row gap-6">
            <b-input
              class="flex-1"
              size="small"
              name="shipment_source_id">
              <template #label>
                <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.SHIPMENT_SOURCE_ID') }}</span>
              </template>
            </b-input>
            <b-input
              class="flex-1"
              size="small"
              name="fulfillment.source_id">
              <template #label>
                <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.FULFILLMENT_SOURCE_ID') }}</span>
              </template>
            </b-input>
            <b-input
              class="flex-1"
              size="small"
              name="order_source_id">
              <template #label>
                <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.ORDER_SOURCE_ID') }}</span>
              </template>
            </b-input>
          </div>
        </div>
        <div class="mt-10">
          <h2>
            {{ t('CREATE_SHIPMENT.PACKAGES_PRODUCTS') }}
          </h2>
          <FieldArray
            v-slot="{ fields: packages, push: pushPackage, remove: removePackage }"
            name="packages">
            <div
              v-for="(pack, idxPackages) in packages"
              :id="`packages-${idxPackages}`"
              :key="pack.key"
              class="mt-4 border-b border-gray-300 gap-6">
              <div class="flex">
                <div class="flex-1 mb-10">
                  <div class="flex gap-6">
                    <b-select
                      class="flex-1"
                      data-id="type"
                      :name="`packages[${idxPackages}].id`"
                      size="small"
                      :options="packageOptions"
                      :placeholder="t('CREATE_SHIPMENT.SELECT_PACKAGE_PLACEHOLDER')"
                      required>
                      <template #label>
                        <span class="block text-xs text-gray-600 font-normal">
                          {{ t('CREATE_SHIPMENT.PACKAGE_DETAILS') }}
                        </span>
                      </template>
                    </b-select>
                    <div class="flex gap-2">
                      <b-input
                        size="small"
                        :name="`packages[${idxPackages}].weight`"
                        decimal
                        required>
                        <template #label>
                          <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.WEIGHT') }}</span>
                        </template>
                      </b-input>
                      <span class="ml-2 mt-7 text-xs text-gray-700">Kg</span>
                    </div>
                  </div>
                  <FieldArray
                    v-slot="{ fields: products, remove: removeProduct, push: pushProduct }"
                    :name="`packages[${idxPackages}].products`">
                    <div class="flex items-center">
                      <div class="flex-1 mt-10">
                        <div
                          v-for="(product, idxProduct) in products"
                          :id="`packages-${idxPackages}-products-${idxProduct}`"
                          :key="product.key"
                          class="mt-8 flex gap-6">
                          <div class="w-3 text-xs text-gray-700">{{ idxProduct + 1 }}.</div>
                          <div class="flex-1 flex flex-col gap-2">
                            <b-input
                              class="flex-1"
                              size="small"
                              :name="`packages[${idxPackages}].products[${idxProduct}].name`"
                              :placeholder="t('CREATE_SHIPMENT.PRODUCT_NAME_PLACEHOLDER')"
                              required>
                              <template #label>
                                <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.PRODUCT_NAME') }}</span>
                              </template>
                            </b-input>
                            <b-input
                              class="flex-1"
                              size="small"
                              :name="`packages[${idxPackages}].products[${idxProduct}].source_id`"
                              :placeholder="t('CREATE_SHIPMENT.PRODUCT_SOURCE_ID_PLACEHOLDER')">
                              <template #label>
                                <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.PRODUCT_SOURCE_ID') }}</span>
                              </template>
                            </b-input>
                            <b-select
                              class="mt-6"
                              :name="`packages[${idxPackages}].products[${idxProduct}].special_services`"
                              size="small"
                              :options="specialServicesOptions"
                              multiple
                              :placeholder="t('CREATE_SHIPMENT.SELECT_SPECIAL_SERVICES_PLACEHOLDER')">
                              <template #label>
                                <span class="block text-xs text-gray-600 font-normal mb-1">
                                  {{ t('CREATE_SHIPMENT.SPECIAL_SERVICES') }}
                                </span>
                              </template>
                            </b-select>
                          </div>
                          <b-counter
                            :min="1"
                            :name="`packages[${idxPackages}].products[${idxProduct}].count`"
                            placeholder="0"
                            required>
                            <template #label>
                              <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.QUANTITY') }}</span>
                            </template>
                          </b-counter>
                          <div class="flex">
                            <b-input
                              size="small"
                              class="w-36"
                              :name="`packages[${idxPackages}].products[${idxProduct}].price`"
                              :tooltip-label="t('CREATE_SHIPMENT.TOOLTIP_PRODUCT_PRICE')"
                              decimal>
                              <template #label>
                                <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.PRICE') }}</span>
                              </template>
                            </b-input>
                          </div>

                          <div class="flex justify-center items-center">
                            <b-button
                              :disabled="products.length <= 1"
                              class="bg-transparent text-gray-600 h-8"
                              @click="removeProduct(idxProduct)">
                              <b-icon :icon-name="BringgFontIcons.Trash" />
                            </b-button>
                          </div>
                        </div>
                        <div class="flex gap-6">
                          <div class="w-3"></div>
                          <b-button
                            type="blank"
                            class="my-4 p-0"
                            @click="pushProduct(defaultProduct())">
                            <b-icon
                              :icon-name="BringgFontIcons.Plus"
                              class="flex justify-center items-center text-white bg-blue-400 w-4 h-4 rounded-full" />
                            <span class="text-blue-400 text-sm font-semibold ml-1.5">
                              {{ t('CREATE_SHIPMENT.ADD_PRODUCT') }}
                            </span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </FieldArray>
                </div>
                <b-button
                  :disabled="packages.length <= 1"
                  class="mt-6 bg-transparent text-gray-600 h-8"
                  @click="removePackage(idxPackages)">
                  <b-icon :icon-name="BringgFontIcons.Trash" />
                </b-button>
              </div>
            </div>

            <b-button
              type="blank"
              class="mt-4 p-0"
              @click="pushPackage(defaultPackage())">
              <b-icon
                :icon-name="BringgFontIcons.Plus"
                class="flex justify-center items-center text-white bg-blue-400 w-4 h-4 rounded-full" />
              <span class="text-blue-400 text-sm font-semibold ml-1.5">{{ t('CREATE_SHIPMENT.ADD_PACKAGE') }}</span>
            </b-button>
          </FieldArray>
        </div>

        <div
          v-for="group in addressInputGroups"
          :key="group.type"
          class="mt-10">
          <h2 class="mb-4">{{ group.title }}</h2>
          <b-select
            v-if="group.type === AddressType.SENDER || group.type === AddressType.RECIPIENT"
            class="flex-1 mb-4"
            :value="addresses[group.type]"
            data-id="type"
            size="small"
            :options="shippingLocationsOptions"
            :placeholder="t('CREATE_SHIPMENT.SELECT_LOCATION_PLACEHOLDER')"
            @update:value="(event) => updateAddress(group.type, event)">
            <template #label>
              <span class="block text-xs text-gray-600 font-normal mb-1">
                {{ t('CREATE_SHIPMENT.LOCATION') }}
              </span>
            </template>
          </b-select>
          <div
            v-if="group.type === AddressType.BILLING"
            class="mb-8">
            <div class="flex gap-8">
              <b-radio
                v-for="addressType of addressTypeOptions"
                :key="addressType"
                name="same_as"
                :value="addressType.value"
                class="h-8 cursor-pointer text-blue-400 mr-2">
                <template #label>
                  <span class="text-xs text-gray-700">{{ addressType.title }}</span>
                </template>
              </b-radio>
            </div>
          </div>
          <div
            v-if="group.type !== AddressType.BILLING || values.same_as === AddressType.OTHER"
            class="grid grid-cols-2 gap-x-6 gap-y-4">
            <div
              v-for="input in group.inputs"
              :key="input.key">
              <b-select
                v-if="input.options"
                :data-id="input.key"
                :name="input.key"
                size="small"
                :options="input.options"
                placeholder="Select country"
                :required="input.required">
                <template #label>
                  <span class="block text-xs text-gray-600 font-normal">{{ input.label }}</span>
                </template>
              </b-select>
              <b-input
                v-else
                size="small"
                :name="input.key"
                :required="input.required"
                :placeholder="input.placeholder">
                <template #label>
                  <span class="text-xs text-gray-700">{{ input.label }}</span>
                </template>
              </b-input>
            </div>
          </div>
          <div
            v-if="group.type === AddressType.RECIPIENT"
            class="mt-4">
            <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.FULFILLMENT_TYPE') }}</span>

            <div>
              <b-radio
                v-for="ffMethod of fulfillmentMethods"
                :key="ffMethod.value"
                name="fulfillment.fulfillment_method"
                :value="ffMethod.value"
                class="h-8 cursor-pointer text-blue-400 mr-2"
                @update:value="resetPudoAndCarrier">
                <template #label>
                  <span class="text-xs text-gray-700">{{ ffMethod.title }}</span>
                </template>
              </b-radio>
            </div>
            <div
              v-show="showPudo"
              class="mt-4 flex flex-row gap-6">
              <b-select
                class="flex-1"
                data-id="carrier-service"
                :options="shippingAccountsOptions"
                :loading="shippingAccountLoading"
                :disabled="disabledSelectCarrier"
                name="carrier"
                required
                @update:value="resetPudo">
                <template #label>
                  <div class="flex justify-center items-center gap-1">
                    <span class="text-xs text-gray-700">{{ t('CREATE_SHIPMENT.SHIPPING_ACCOUNT_SERVICE') }}</span>
                    <Tooltip
                      placement="bottomRight"
                      width="w-96">
                      <template #content>
                        <icon
                          :icon-name="BringgFontIcons.Info"
                          class="text-gray-600" />
                      </template>
                      <template #title>
                        <span class="font-semibold text-sm">
                          {{ t('CREATE_SHIPMENT.PUDO_INFO') }}
                        </span>
                      </template>
                    </Tooltip>
                  </div>
                </template>
              </b-select>

              <b-select
                class="flex-1"
                :disabled="disabledSelectPudo"
                name="fulfillment.pudo_location"
                :loading="shippingAccountLoading"
                :options="pudoLocationsOptions"
                :placeholder="t('CREATE_SHIPMENT.PUDO_PLACEHOLDER')"
                :label="t('CREATE_SHIPMENT.PUDO_DETAILS')"
                required />
            </div>
          </div>
        </div>
      </div>

      <div class="sticky bottom-0 bg-white z-10">
        <SaveDiscardFooter
          data-id="create-shipment-footer"
          :is-discardable="meta.dirty"
          :is-submittable="meta.dirty && meta.valid"
          :submitting="submitting"
          :submit-label="t('CREATE_SHIPMENT.SUBMIT')"
          @discard="resetForm"
          @submit="submit" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateShipment'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { get, isEmpty, isEqual } from 'lodash';
import { FieldArray, useForm } from 'vee-validate';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { array, number, object, string } from 'yup';

import countryList from '@/assets/js/countries.json';
import Icon from '@/components/atoms/Icon/index.vue';
import Tooltip from '@/components/atoms/ToolTip/index.vue';
import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter/index.vue';
import BModal from '@/components/organisms/Modal/index.vue';
import { ShipmentCreatedBy } from '@/constants';
import { AddressType, FulfillmentMethod, SpecialServices } from '@/enums';
import { formatPackageDimensions, getPudoLocationLabel, phoneRegex } from '@/utils';

import Alert from '../../atoms/Alert';
import BCounter from '../../atoms/BCounter';
import BSelect from '../../atoms/BSelect';
import BButton from '../../atoms/Button';
import BIcon from '../../atoms/Icon';
import BInput from '../../atoms/Input';
import BRadio from '../../atoms/Radio';

const SOURCE_IDS = 'source-ids';
const store = useStore();
const { t } = useTranslation();
const router = useRouter();

const isMounted = ref(false);
const submitting = ref(false);
const carrierRejectedReason = ref('');

onMounted(async () => {
  await Promise.all([loadPackages(), loadShippingLocation(), loadShippingAccounts()]);
  resetForm();
  isMounted.value = true;
});

const loadPackages = async () => await store.dispatch('packages/loadPackages');
const loadShippingLocation = async () => await store.dispatch('shippingLocations/loadShippingLocations');
const loadShippingAccounts = async () => await store.dispatch('shippingAccounts/loadShippingAccounts');
const accountCountryCode = store.getters['auth/accountSettings'].country_code;

const countryCurrencyMap = {
  gb: 'GBP',
  fr: 'EUR'
};

const shippingAccountLoading = computed(() => store.state.shippingAccounts.loading);

const shippingAccountsOptions = computed(() => {
  const shippingAccounts = store.getters['shippingAccounts/carriers'];
  const serviceTypes = store.getters['carriers/serviceTypes'];

  return shippingAccounts
    .map((shippingAccount) => {
      const name =
        shippingAccount.carrier === 'custom'
          ? shippingAccount.name
          : `${shippingAccount.carrier_name} [${shippingAccount.name}]`;
      const services = serviceTypes[shippingAccount.carrier];
      const shippingAccountsAndServices = services.map((service) => {
        return {
          label: `${name} / ${service.label}`,
          value: { shipping_account_id: String(shippingAccount.id), service: service.value }
        };
      });

      return shippingAccountsAndServices;
    })
    .flat();
});

const pudoLocationsOptions = computed(() => {
  return store.state.shippingAccounts.pudoLocations.map((location) => ({
    label: getPudoLocationLabel(location),
    value: location
  }));
});

const shippingLocations = computed(() => store.getters['shippingLocations/items']);
const shippingLocationsOptions = computed(() => {
  return shippingLocations.value.map((item) => ({
    value: item.id,
    label: item.name
  }));
});

const packageOptions = computed(() =>
  Array.from(store.state.packages.items.values()).map((item) => ({
    value: item.id,
    label: `${item.name}: ${formatPackageDimensions(item)}`
  }))
);

const fulfillmentMethods = computed(() => [
  {
    value: FulfillmentMethod.DIRECT,
    title: t('CREATE_SHIPMENT.DIRECT')
  },
  {
    value: FulfillmentMethod.PUDO,
    title: t('CREATE_SHIPMENT.PUDO')
  }
]);

const addressTypeOptions = computed(() => [
  {
    value: AddressType.RECIPIENT,
    title: t('CREATE_SHIPMENT.SAME_AS_RECIPIENT')
  },
  {
    value: AddressType.SENDER,
    title: t('CREATE_SHIPMENT.SAME_AS_SENDER')
  },
  {
    value: AddressType.OTHER,
    title: t('CREATE_SHIPMENT.OTHER')
  }
]);

const error = computed(
  () => store.state.shippingLocations.error || store.state.orders.error || store.state.shipments.error
);

const schema = computed(() => {
  const addressSchema = object({
    company: string()
      .when('address.company', (_, schema, options) => {
        if (options.value?.length > 0) {
          return string().min(2).max(35).label(t('ADD_LOCATION.COMPANY'));
        } else {
          return string().nullable();
        }
      })
      .label(t('ADD_LOCATION.COMPANY')),
    street1: string().required().min(2).max(35).label(t('ADD_LOCATION.ADDRESS_LINE_1')),
    street2: string()
      .when('address.street2', (_, schema, options) => {
        if (options.value?.length > 0) {
          return string().min(2).max(35).label(t('ADD_LOCATION.ADDRESS_LINE_2'));
        } else {
          return string().nullable();
        }
      })
      .label(t('ADD_LOCATION.ADDRESS_LINE_2')),
    city: string().required().min(2).max(35).label(t('ADD_LOCATION.CITY')),
    state: string()
      .when('address.state', (_, schema, options) => {
        if (options.value?.length > 0) {
          return string().min(2).max(35).label(t('ADD_LOCATION.STATE'));
        } else {
          return string().nullable();
        }
      })
      .label(t('ADD_LOCATION.STATE')),
    postal_code: string().min(2).max(20).required().label(t('ADD_LOCATION.POST_CODE')),
    country: string().required().label(t('ADD_LOCATION.COUNTRY'))
  });

  const customerSchema = object({
    name: string().min(2).max(100).required().label(t('ADD_LOCATION.NAME')),
    phone: string()
      .min(10)
      .max(15)
      .matches(phoneRegex, { message: t('INVALID_PHONE') })
      .required()
      .label(t('ADD_LOCATION.PHONE')),
    email: string().nullable().email().label(t('EMAIL'))
  });

  return object({
    order_source_id: string().label(t('CREATE_SHIPMENT.ORDER_SOURCE_ID')),
    shipment_source_id: string().label(t('CREATE_SHIPMENT.SHIPMENT_SOURCE_ID')),
    packages: array().of(
      object().shape({
        id: string().required().label(t('CREATE_SHIPMENT.PACKAGE_DETAILS')),
        weight: number()
          .required()
          .label(t('CREATE_SHIPMENT.WEIGHT'))
          .typeError(({ label, type }) => `${label} must be a ${type}`),
        products: array()
          .of(
            object().shape({
              name: string().required().label(t('CREATE_SHIPMENT.PRODUCT_NAME')),
              source_id: string().label(t('CREATE_SHIPMENT.SOURCE_ID')),
              count: number().required().min(1).label(t('CREATE_SHIPMENT.QUANTITY')),
              price: number()
                .nullable()
                .transform((value, originalValue) => {
                  if (originalValue === '' || originalValue === null) {
                    return null;
                  }

                  return value;
                })
                .test('maxDigitsAfterDecimal', t('CREATE_SHIPMENT.ERROR_DECIMAL_PRODUCT_PRICE'), (value) => {
                  if (value === null) {
                    return true;
                  }

                  return /^\d+(\.\d{1,2})?$/.test(value.toString());
                })
                .label(t('CREATE_SHIPMENT.PRICE'))
                .typeError(({ label, type }) => `${label} must be a ${type}`),
              special_services: array()
            })
          )
          .test('unique-product-names', t('CREATE_SHIPMENT.PRODUCT_NAME_UNIQUE'), function (products) {
            const productNameSet = new Set();
            let index = 0;

            while (index < products.length) {
              const product = products[index];

              const productName = product.name.toLowerCase().trim();

              if (productNameSet.has(productName)) {
                return this.createError({
                  path: `${this.path}[${index}].name`,
                  message: t('CREATE_SHIPMENT.PRODUCT_NAME_DUPLICATE_ERROR')
                });
              }
              !!productName && productNameSet.add(productName);
              index++;
            }

            return true;
          })
      })
    ),
    fulfillment: object().shape({
      sender: customerSchema,
      sender_address: addressSchema,
      recipient: customerSchema,
      recipient_address: addressSchema,
      source_id: string().label(t('CREATE_SHIPMENT.FULFILLMENT_SOURCE_ID')),
      fulfillment_method: string()
        .oneOf([FulfillmentMethod.DIRECT, FulfillmentMethod.PUDO])
        .default(FulfillmentMethod.DIRECT),
      pudo_location: object().when('fulfillment_method', {
        is: FulfillmentMethod.PUDO,
        then: () =>
          object().shape({
            location_code: string().required('PUDO location code is required for PUDO fulfillment method')
          }),
        otherwise: () => object()
      })
    }),
    carrier: object().strip(),
    save_as: string(),
    billing: object().when('same_as', {
      is: AddressType.OTHER,
      then: () => customerSchema,
      otherwise: () => object().strip()
    }),
    billing_address: object().when('same_as', {
      is: AddressType.OTHER,
      then: () => addressSchema,
      otherwise: () => object().strip()
    })
  });
});

const defaultPackage = () => ({
  id: '',
  weight: null,
  products: [defaultProduct()]
});

const defaultProduct = () => ({
  name: '',
  source_id: '',
  count: null,
  price: null,
  special_services: []
});

const { values, handleSubmit, resetForm, meta, setFieldValue, setFieldError } = useForm({
  validationSchema: schema,
  initialValues: {
    fulfillment: {
      source_id: '',
      fulfillment_method: FulfillmentMethod.DIRECT,
      pudo_location: {},
      sender: {
        email: '',
        name: '',
        phone: ''
      },
      recipient: {
        email: '',
        name: '',
        phone: ''
      },
      sender_address: {
        city: '',
        company: '',
        country: '',
        postal_code: '',
        street1: '',
        street2: '',
        state: ''
      },
      recipient_address: {
        city: '',
        company: '',
        country: '',
        postal_code: '',
        street1: '',
        street2: '',
        state: ''
      }
    },
    carrier: '',
    order_source_id: '',
    shipment_source_id: '',
    packages: [defaultPackage()],
    same_as: AddressType.RECIPIENT
  }
});

const specialServicesOptions = computed(() => {
  return Object.entries(SpecialServices).map(([key, value]) => ({
    value: key,
    label: t(`SPECIAL_SERVICES.${value}`)
  }));
});

const getAddressFields = (type) => [
  {
    label: t('ADDRESS.NAME'),
    key: `${type}.name`,
    required: true
  },
  {
    label: t('ADDRESS.COMPANY'),
    key: `${type}_address.company`
  },
  {
    label: t('ADDRESS.PHONE'),
    key: `${type}.phone`,
    required: true
  },
  {
    label: t('ADDRESS.EMAIL'),
    key: `${type}.email`
  },
  {
    label: t('ADDRESS.STREET1'),
    key: `${type}_address.street1`,
    required: true
  },
  {
    label: t('ADDRESS.STREET2'),
    key: `${type}_address.street2`,
    placeholder: t('CREATE_SHIPMENT.STREET2_PLACEHOLDER')
  },
  {
    label: t('ADDRESS.CITY'),
    key: `${type}_address.city`,
    required: true
  },
  {
    label: t('ADDRESS.POSTAL_CODE'),
    key: `${type}_address.postal_code`,
    required: true
  },
  {
    label: t('ADDRESS.STATE'),
    key: `${type}_address.state`
  },
  {
    label: t('ADDRESS.COUNTRY'),
    key: `${type}_address.country`,
    options: Object.keys(countryList).map((value) => ({
      value,
      label: countryList[value]
    })),
    required: true
  }
];

const addressInputGroups = ref([
  {
    type: AddressType.SENDER,
    title: t('CREATE_SHIPMENT.SENDER_DETAILS'),
    inputs: getAddressFields('fulfillment.sender')
  },
  {
    type: AddressType.RECIPIENT,
    title: t('CREATE_SHIPMENT.RECIPIENT_DETAILS'),
    inputs: getAddressFields('fulfillment.recipient')
  },
  {
    type: AddressType.BILLING,
    title: t('CREATE_SHIPMENT.BILLING_DETAILS'),
    inputs: getAddressFields('billing')
  }
]);

const addresses = reactive({
  sender: '',
  recipient: ''
});
const fieldsToUpdate = (type) => [
  [`fulfillment.${type}.name`, 'name'],
  [`fulfillment.${type}.phone`, 'address.phone'],
  [`fulfillment.${type}.email`, 'address.email'],
  [`fulfillment.${type}_address.street1`, 'address.street1'],
  [`fulfillment.${type}_address.street2`, 'address.street2'],
  [`fulfillment.${type}_address.city`, 'address.city'],
  [`fulfillment.${type}_address.postal_code`, 'address.postal_code'],
  [`fulfillment.${type}_address.country`, 'address.country'],
  [`fulfillment.${type}_address.state`, 'address.state']
];

const disabledSelectCarrier = computed(() => {
  const { city, country, postal_code, street1 } = values.fulfillment.recipient_address;

  const ffMethod = values.fulfillment.fulfillment_method;

  if (ffMethod === FulfillmentMethod.DIRECT) {
    return true;
  }

  return [city, country, postal_code, street1].some(isEmpty);
});

const disabledSelectPudo = computed(() => {
  const carrier = values.carrier;

  return isEmpty(carrier) || pudoError.value;
});

const showPudo = computed(() => {
  return values.fulfillment.fulfillment_method === FulfillmentMethod.PUDO;
});

const pudoError = computed(() => store.state.shippingAccounts.pudoError);
const getPudoLocations = async () => {
  const { city, country, postal_code, state, street1 } = values.fulfillment.recipient_address;

  const shippingAccount = store.getters['shippingAccounts/getShippingAccountById'](
    Number(values?.carrier?.shipping_account_id)
  );

  const payload = {
    dopu: {
      carrier: shippingAccount.carrier,
      location: {
        city,
        country,
        postal_code,
        state,
        street1
      },
      shipping_account: Number(values.carrier.shipping_account_id),
      debug: true
    }
  };

  await store.dispatch('shippingAccounts/getPudoLocations', payload);

  if (pudoError.value) {
    setFieldError('carrier', pudoError.value);
  }
};

watch(
  () => values.carrier,
  async (newCarrierData, oldCarrierData) => {
    const propertiesToCompare = ['shipping_account_id', 'service'];
    const isNewCarrierDataEmpty = !propertiesToCompare.every((prop) => newCarrierData?.[prop]);
    const isSameCarrierAndService = propertiesToCompare.every(
      (prop) => newCarrierData?.[prop] === oldCarrierData?.[prop]
    );

    if (isNewCarrierDataEmpty || isSameCarrierAndService) {
      return;
    }

    await getPudoLocations();
  }
);

const fieldsToWatch = ['city', 'country', 'postal_code', 'street1', 'state'];

fieldsToWatch.forEach((field) => {
  watch(
    () => values.fulfillment.recipient_address[field],
    (newValue, oldValue) => {
      resetPudoAndCarrier();
    }
  );
});

const updateAddress = (type, value) => {
  const shippingLocation = store.getters['shippingLocations/shippingLocation'](value);

  fieldsToUpdate(type).forEach(([formKey, shippingLocationKey]) => {
    setFieldValue(formKey, get(shippingLocation, shippingLocationKey));
  });

  addresses[type] = value;
};

const checkAddress = (type) => {
  const shippingLocations = store.getters['shippingLocations/items'];

  for (let i = 0; i < shippingLocations.length; i++) {
    const shippingLocation = shippingLocations[i];

    const isMatch = fieldsToUpdate(type).every(([formKey, shippingLocationKey]) => {
      return get(values, formKey) === get(shippingLocation, shippingLocationKey);
    });

    if (isMatch) {
      addresses[type] = shippingLocation.id;

      return;
    }
  }

  addresses[type] = '';
};

watch(
  () => values,
  () => {
    [AddressType.SENDER, AddressType.RECIPIENT].forEach((type) => checkAddress(type));
  },
  {
    deep: true
  }
);

const labelType = store.getters['auth/accountSettings'].label_type;

const createOrderPayload = (values) => {
  const { fulfillment, billing, billing_address, same_as } = values;

  values.packages = values.packages.map((pck) => {
    const packageItem = store.getters['packages/itemById'](pck.id);

    return {
      name: packageItem.name,
      package_weight: Number(pck.weight),
      weight_units: 'kg',
      products: pck.products.map((product) => {
        const productProperties = {
          source_id: product.source_id || product.name,
          price: product.price * 100 || 100,
          length: 1,
          width: 1,
          height: 1,
          dim_units: 'cm',
          weight: 1,
          weight_units: 'g',
          special_services: product.special_services.reduce((accumulator, currentValue) => {
            accumulator[currentValue] = currentValue === 'age_restricted' ? 18 : true;

            return accumulator;
          }, {})
        };

        return {
          ...product,
          ...productProperties,
          product: {
            ...product,
            ...productProperties
          }
        };
      })
    };
  });

  fulfillment.products = Object.values(
    values.packages
      .reduce((allProducts, pkg) => allProducts.concat(pkg.products), [])
      .reduce((productMap, product) => {
        if (!productMap[product.name]) {
          productMap[product.name] = { ...product, count: 0 };
        }
        productMap[product.name].count += product.count;

        return productMap;
      }, {})
  );

  fulfillment.shipping_account_id = values.carrier?.shipping_account_id;
  fulfillment.service = values.carrier?.service;

  if (fulfillment.pudo_location) {
    fulfillment.pudo_location_id = fulfillment.pudo_location.location_code;
    fulfillment.pudo = getPudoLocationLabel(fulfillment.pudo_location);
  }
  delete fulfillment.pudo_location;

  fulfillment.shipments = [
    {
      source_id: values.shipment_source_id,
      packages: values.packages,
      label_type: labelType,
      created_by: ShipmentCreatedBy.HUB,
      disable_shipping_rules: true,
      shipping_account_id: fulfillment.shipping_account_id,
      service: fulfillment.service
    }
  ];

  const totalPrice = fulfillment.products.reduce((total, product) => total + product.count * +product.price, 0);

  const payload = {
    currency: countryCurrencyMap[accountCountryCode] || 'USD',
    source_id: values.order_source_id,
    total_price: totalPrice,
    fulfillments: [fulfillment],
    products: fulfillment.products
  };

  if (same_as === AddressType.SENDER) {
    payload.customer = fulfillment.sender;
    payload.billing_address = fulfillment.sender_address;
  } else if (same_as === AddressType.RECIPIENT) {
    payload.customer = fulfillment.recipient;
    payload.billing_address = fulfillment.recipient_address;
  } else {
    payload.customer = billing;
    payload.billing_address = billing_address;
  }

  if (addresses.sender) {
    const shippingLocation = store.getters['shippingLocations/shippingLocation'](addresses.sender);

    fulfillment.sender_location_id = shippingLocation.source_id || shippingLocation.id;
    delete fulfillment.sender;
    delete fulfillment.sender_address;
  }

  if (addresses.recipient) {
    const shippingLocation = store.getters['shippingLocations/shippingLocation'](addresses.recipient);

    fulfillment.recipient_location_id = shippingLocation.source_id || shippingLocation.id;
    delete fulfillment.recipient;
    delete fulfillment.recipient_address;
  }

  if (fulfillment.sender_address && !fulfillment.sender_address.company) {
    fulfillment.sender_address.company = fulfillment.sender.name;
  }

  return payload;
};

const validate = (response, values) => {
  const errorMap = {
    source_id: 'order_source_id',
    fulfillment_source_ids_duplicated: 'fulfillment.source_id',
    shipment_source_ids_duplicated: 'shipment_source_id'
  };

  Object.entries(errorMap).forEach(([key, fieldName]) => {
    if (response.data[key]) {
      setFieldError(fieldName, t('CREATE_SHIPMENT.DUPLICATE_SOURCE_ID'));
      scrollTo(SOURCE_IDS);
    }
  });

  if (response.data['product_not_found_in_parent_entity']) {
    compareProductsAcrossPackages(values.packages, response.data['product_not_found_in_parent_entity']?.name);
  }

  if (response.data['duplicates']) {
    compareProductsAcrossPackages(values.packages, response.data['duplicates'][0]);
  }

  if (response.data['rejected_reason']) {
    carrierRejectedReason.value = response.data['rejected_reason'];
  }
};

const compareProductsAcrossPackages = (packages, targetProductName) => {
  function setError(items, fieldName, message) {
    items.forEach((item) => {
      setFieldError(`packages[${item.packageIndex}].products[${item.productIndex}].${fieldName}`, message);
      errors.push({ packageIndex: item.packageIndex, productIndex: item.productIndex });
    });
  }

  function compareAndSetErrors(items, fieldName, message) {
    if (items.length === 0) {
      return;
    }

    const firstValue = items[0].value;
    const isDifferent = items.some((item) => !isEqual(firstValue, item.value));

    if (isDifferent) {
      setError(items, fieldName, message);
    }
  }
  const targetName = targetProductName.toLowerCase().trim();
  const prices = [];
  const specialServices = [];
  const duplicatesSourceIds = [];
  const productsSourceIds = [];
  const errors = [];

  packages.forEach((pkg, packageIndex) => {
    pkg.products.forEach((product, productIndex) => {
      const currentProductName = product.name.toLowerCase().trim();
      const currentProductSourceId = product.source_id.toLowerCase().trim();

      if (currentProductName === targetName) {
        prices.push({ value: product.price, packageIndex, productIndex });
        specialServices.push({ value: product.special_services, packageIndex, productIndex });
        productsSourceIds.push({ value: product.source_id, packageIndex, productIndex });
      }

      if (currentProductSourceId === targetName) {
        duplicatesSourceIds.push({ value: currentProductSourceId, packageIndex, productIndex });
      }
    });
  });

  compareAndSetErrors(prices, 'price', t(`CREATE_SHIPMENT.INCONSISTENT_PRICE_ERROR`));
  compareAndSetErrors(specialServices, 'special_services', t(`CREATE_SHIPMENT.INCONSISTENT_SPECIAL_SERVICES_ERROR`));
  compareAndSetErrors(productsSourceIds, 'source_id', t(`CREATE_SHIPMENT.INCONSISTENT_SOURCE_ID_ERROR`));
  setError(duplicatesSourceIds, 'source_id', t(`CREATE_SHIPMENT.DUPLICATE_SOURCE_ID`));

  if (errors.length) {
    const firstInput = errors.sort((a, b) => a.packageIndex - b.packageIndex || a.productIndex - b.productIndex)[0];

    scrollTo(`packages-${firstInput.packageIndex}-products-${firstInput.productIndex}`);
  }
};

function scrollTo(elementId) {
  const element = document.getElementById(elementId);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const submit = handleSubmit(async (values, actions) => {
  submitting.value = true;
  try {
    const orderPayload = createOrderPayload(values);
    const response = await store.dispatch('orders/createOrder', orderPayload);

    if (response.success === false) {
      if (response.status === 500) {
        store.commit('orders/setError', response?.errors?.reason || t('SOMETHING_WENT_WRONG'));

        return;
      }

      validate(response, values);
    } else {
      router.push({
        name: 'shipment-details',
        params: {
          orderId: response.data.id,
          fulfillmentId: response.data.fulfillments[0].id,
          id: response.data.shipments[0].id
        }
      });
      actions.resetForm();
    }
  } finally {
    submitting.value = false;
  }
});
const clearError = () => {
  store.commit('shippingLocations/clearError');
  store.commit('orders/clearError');
  store.commit('shipments/clearError');
};

const resetPudo = () => {
  setFieldValue('fulfillment.pudo_location', {});
};

const resetPudoAndCarrier = () => {
  setFieldValue('fulfillment.pudo_location', {});
  setFieldValue('carrier', '');
};
const closeCarrierRejectedModal = () => {
  carrierRejectedReason.value = '';
};
</script>
