export const documentLink = 'https://i.imgur.com/2MLmQs9.jpeg';

export const ModalIds = {
  ASSIGN_MODAL_ID: Symbol(),
  CANCEL_MODAL_ID: Symbol(),
  CANCEL_SUCCESS_MODAL_ID: Symbol(),
  CANCEL_FAILED_MODAL_ID: Symbol()
};

export const ModalStateKey = Symbol();
export const ShowModalKey = Symbol();
export const HideModalKey = Symbol();
export const ToggleDrawerKey = Symbol();
export const ShipmentEventsKey = Symbol();
export const ReversedShipmentEventsKey = Symbol();
export const RejectionReasonKey = Symbol();
export const ActivityLogKey = Symbol();
export const FulfilmentCarrierModalKey = Symbol();
