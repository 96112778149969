<template>
  <div class="relative">
    <div
      v-if="$slots['label']"
      class="block text-xs mb-1 flex">
      <template v-if="required">
        <span class="text-xs text-red-600 mr-0.5">*</span>
      </template>
      <slot name="label" />
    </div>

    <div
      v-if="label"
      class="block text-xs mb-1">
      <template v-if="required">
        <span class="text-xs text-red-600 mr-0.5">*</span>
      </template>
      {{ label }}
    </div>

    <div class="relative">
      <template v-if="$slots['button']">
        <div
          ref="button"
          @click="toggleDropdown">
          <slot name="button" />
        </div>
      </template>
      <div
        v-show="!$slots['button']"
        class="relative">
        <input
          :id="id"
          ref="mainInput"
          autocomplete="off"
          :readonly="!searchable"
          :value="inputValue"
          :placeholder="!top ? placeholder : ''"
          :disabled="disabled"
          :class="inputClasses"
          @input="onInputChange"
          @click="toggleDropdown"
          @blur="onBlur"
          @keydown.down="navigateOptions(1)"
          @keydown.up="navigateOptions(-1)"
          @keydown.enter.prevent.stop="selectFocusedOption" />

        <div class="absolute right-0 top-0 h-full flex items-center transform transition-transform duration-300">
          <b-icon
            v-if="loading"
            class="text-lg mx-2 text-gray-600"
            :icon-name="BringgFontIcons.Loader"
            spin />
          <b-icon
            v-else
            class="text-base mx-2 cursor-pointer text-gray-600"
            :class="{
              'cursor-not-allowed': disabled
            }"
            :icon-name="showDropdownList ? 'chevron-up' : 'chevron'"
            @click.stop="handleButtonClick" />
        </div>
      </div>
    </div>
    <ul
      v-show="showDropdownList"
      ref="dropdownList"
      class="absolute z-10 w-full mt-1 bg-white border border-gray-300 text-gray-700 rounded shadow-lg max-h-40 overflow-auto"
      :class="[contentWidth ? 'w-min' : 'w-full', { 'bottom-9': top }]"
      @mouseover="onMouseOverOption">
      <li v-if="loading">
        <b-icon
          class="text-lg"
          :icon-name="BringgFontIcons.Loader"
          spin />
      </li>
      <li
        v-else-if="filterOptions?.length === 0"
        class="px-4 py-2 pb-1 text-sm">
        {{ t('LIST_IS_EMPTY') }}
      </li>
      <li
        v-for="(option, index) in filterOptions"
        v-else
        :key="option"
        :class="{
          'bg-blue-400 text-white': index === hoverOptionIndex,
          'font-semibold': isSelected(option)
        }"
        tabindex="0"
        :data-id="option.value || option"
        class="relative pl-8 pr-4 py-2 cursor-pointer text-sm"
        @click="selectOption(option)">
        {{ option.label || option.value || option }}
        <div
          v-if="isSelected(option)"
          class="absolute inset-y-0 left-0 flex items-center pl-1.5"
          :class="[index === hoverOptionIndex ? 'text-white' : 'text-blue-400']">
          <b-icon
            icon-name="selected"
            class="text-xl"
            aria-hidden="true" />
        </div>
      </li>
    </ul>
    <p
      v-if="errorMessage"
      class="mt-2 text-red-600"
      :class="errorStyles ? errorStyles : 'text-sm'">
      <slot name="error-message">{{ errorMessage }}</slot>
    </p>
  </div>
</template>
<script>
export default {
  name: 'BSelect'
};
</script>
<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { find, isEqual, isNil, isObject } from 'lodash';
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps, onMounted, onUnmounted, onUpdated, ref, useSlots, watch } from 'vue';

import BIcon from '@/components/atoms/Icon';
import twMerge from '@/twMerge';

const props = defineProps({
  value: {
    type: [Array, String, Number, null],
    default: ''
  },
  multiple: {
    type: Boolean,
    default: false
  },
  searchable: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'small',
    validator: (value) => ['extraSmall', 'small', 'large'].includes(value)
  },
  id: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Select from list'
  },
  name: {
    type: String,
    required: false
  },
  options: {
    type: Array,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  contentWidth: {
    type: Boolean,
    default: false
  },
  top: {
    type: Boolean,
    default: false
  },
  unSelect: {
    type: Boolean,
    default: false
  },
  status: {
    type: String,
    default: '',
    validator(value) {
      return ['success', 'warning', 'danger', ''].indexOf(value) !== -1;
    }
  },
  errorStyles: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const slots = useSlots();
const { t } = useTranslation();

const emit = defineEmits(['update:value']);
const optionsMapping = computed(() =>
  props.options.reduce((result, { label, value }) => {
    result[value] = label;

    return result;
  }, {})
);

const displayValue = (value) => {
  if (value && Array.isArray(value)) {
    return value.map((item) => optionsMapping.value[item] || item).join(', ');
  }

  if (value && isObject(value)) {
    const selectedValue = find(props.options, (obj) => isEqual(obj.value, value));

    if (isNil(selectedValue)) {
      return '';
    }

    return selectedValue.label || selectedValue.value || selectedValue;
  }

  return optionsMapping.value[value] || value;
};
const inputValue = ref(displayValue(props.value) || '');
const showDropdownList = ref(false);
const dropdownList = ref(null);
const button = ref(null);
const mainInput = ref(null);
const hoverOptionIndex = ref(-1);
const selectedOptions = ref(props.value || []);
const dropdownListHeight = ref(0);

const handleClickOutside = (event) => {
  if (showDropdownList.value && !dropdownList?.value.contains(event.target)) {
    if (slots['button'] && !button?.value.contains(event.target)) {
      closeDropdown();
    } else if (!slots['button'] && !mainInput?.value.contains(event.target)) {
      closeDropdown();
    }
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

onUpdated(() => {
  if (showDropdownList.value && dropdownList.value) {
    dropdownListHeight.value = dropdownList.value.offsetHeight;
    scrollOptionIntoView();
  }
});
const scrollOptionIntoView = () => {
  if (hoverOptionIndex.value !== -1 && dropdownList.value) {
    const optionElements = dropdownList.value.querySelectorAll('li');
    const selectedOption = optionElements[hoverOptionIndex.value];

    if (selectedOption) {
      const dropdownTop = dropdownList.value.scrollTop;
      const dropdownBottom = dropdownTop + dropdownListHeight.value;
      const optionTop = selectedOption.offsetTop;
      const optionBottom = optionTop + selectedOption.offsetHeight;

      if (optionTop < dropdownTop) {
        dropdownList.value.scrollTop = optionTop;
      } else if (optionBottom > dropdownBottom) {
        dropdownList.value.scrollTop = optionBottom - dropdownListHeight.value;
      }
    }
  }
};

const name = computed(() => props.name || props.id);

const { value, handleChange, errorMessage, meta } = useField(name, undefined, {
  initialValue: props.value
});

const filterOptions = computed(() => {
  const inputValueLowerCase = slots['button'] ? '' : inputValue.value?.toLowerCase();

  const getOptionValue = (option) => option.label || option.value || option;

  return inputValueLowerCase && props.searchable
    ? props.options.filter((option) => getOptionValue(option).toLowerCase().includes(inputValueLowerCase))
    : props.options;
});

const classes = computed(() => {
  const withStatus = [
    [meta.validated && !meta.valid, 'focus:ring-red-500 focus:border-red-500 border-red-300'],
    [props.status === 'success', 'focus:ring-green-500 focus:border-green-500 border-green-300'],
    [props.status === 'warning', 'focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300']
  ];

  const withoutStatus = [[true, 'border-gray-300 focus:border-blue-400 focus:shadow-sm']];

  const result = [...((meta.validated && !meta.valid) || props.status ? withStatus : withoutStatus)];

  return result.map(([condition, classList]) => (condition ? classList : ''));
});

const inputClasses = computed(() => {
  let sizeClasses = {
    extraSmall: 'h-6',
    small: 'h-8',
    large: 'h-10'
  };

  return twMerge(
    'w-full px-2 pl-3 pr-6 border rounded border-gray-300 text-gray-800 placeholder-gray-400 text-sm font-normal focus:outline-none focus:ring-0 focus:ring-blue-400 focus:border-blue-400 overflow-hidden overflow-ellipsis',
    ...classes.value,
    sizeClasses[props.size],
    props.disabled ? 'cursor-not-allowed bg-gray-100' : ''
  );
});

const selectOption = (option) => {
  const value = option.value || option;

  if (props.multiple) {
    const isSelected = selectedOptions.value.includes(value);

    selectedOptions.value = isSelected
      ? selectedOptions.value.filter((item) => item !== value)
      : [...selectedOptions.value, value];
  } else {
    selectedOptions.value = !props.unSelect ? (!isEqual(selectedOptions.value, value) ? value : '') : value;
  }

  inputValue.value = displayValue(selectedOptions.value);
  emit('update:value', selectedOptions.value);
  handleChange(selectedOptions.value);
  showDropdownList.value = false;
};
const showDropdown = () => {
  showDropdownList.value = true;
};
const closeDropdown = () => {
  if (!showDropdownList.value) {
    return;
  }
  inputValue.value = displayValue(props.value || value.value);
  showDropdownList.value = false;
};
const toggleDropdown = () => {
  if (props.searchable) {
    inputValue.value = '';
  }

  showDropdownList.value = !showDropdownList.value;
};
const navigateOptions = (step) => {
  if (!showDropdownList.value) {
    showDropdownList.value = true;

    return;
  }
  hoverOptionIndex.value =
    (hoverOptionIndex.value + step + filterOptions.value.length) % (filterOptions.value.length || 1);
};
const onMouseOverOption = (event) => {
  const liElement = event.target.closest('li');

  if (liElement) {
    const index = Array.from(liElement.parentNode.children).indexOf(liElement);

    hoverOptionIndex.value = index;
  }
};
const selectFocusedOption = (event) => {
  if (showDropdownList.value) {
    if (hoverOptionIndex.value !== -1) {
      selectOption(filterOptions.value[hoverOptionIndex.value]);
    }
  }
};
const onInputChange = (e) => {
  inputValue.value = e.target.value;
  showDropdown();
};
const isSelected = (option) => {
  const value = option.value || option;

  if (slots['button']) {
    return false;
  }

  return props.multiple ? selectedOptions.value.includes(value) : isEqual(selectedOptions.value, value);
};
const handleButtonClick = () => {
  mainInput.value.click();
  mainInput.value.focus();
};

const updateValues = (newValue) => {
  if (!props.multiple) {
    inputValue.value = displayValue(newValue);
    selectedOptions.value = newValue;
  }
};

watch(() => props.value, updateValues);

watch(value, updateValues);
watch(
  () => props.options,
  () => updateValues(selectedOptions.value)
);
const onBlur = () => {
  if (showDropdownList.value) {
    return;
  }

  inputValue.value = displayValue(selectedOptions.value);
};
</script>
