<template>
  <div class="flex flex-col overflow-hidden h-full">
    <div class="flex justify-between">
      <div class="p-6 pl-10 text-sm text-gray-600 flex">
        <span>{{ t('WEBHOOKS.SUBTITLE') }}</span>
        <a
          href="https://developers.bringg.com/v2/docs/configure-webhooks"
          target="_blank"
          class="text-blue-400 text-sm inline-flex font-semibold ml-4">
          <b-icon
            :icon-name="BringgFontIcons.ExternalLink"
            class="text-4xl w-4 h-4" />
          <span>Learn More</span>
        </a>
      </div>
      <div
        v-if="isAdmin"
        class="flex p-6">
        <template v-if="isAdmin">
          <b-button
            :disabled="loading"
            class="ml-2 px-6 py-2"
            data-id="addWebhook"
            @click="isShownModal = true">
            {{ t('WEBHOOKS.ADD_WEBHOOK') }}
          </b-button>
        </template>
        <b-modal-webhooks
          v-if="isShownModal"
          :value="state.editWebhook"
          :shipping-account-list="shippingAccountList"
          :webhooks-events-list="webhooksEventsList"
          :used-names="usedNames"
          :is-edit="isEdit"
          :is-shown="isShownModal"
          @close="onClose"
          @create="onCreate"
          @edit="onEdit"></b-modal-webhooks>
      </div>
    </div>
    <div class="pl-10 pr-6 flex h-full flex-col overflow-hidden">
      <alert
        v-if="error"
        class="mb-8"
        type="danger"
        :close="true"
        @close="clearError">
        {{ t(error) }}
      </alert>
      <b-table
        class="webhooks"
        :loading="loading"
        :fields="fields"
        :data="webhooks"
        row-key="id"
        last-update>
        <template #cell(name)="{ row }">
          <span
            class="text-blue-400 hover:text-blue-600 cursor-pointer"
            @click="selectAction(row, 'edit-webhook')">
            {{ row.name }}
          </span>
        </template>
        <template #cell(description)="{ row }">
          {{ row.description }}
        </template>
        <template #cell(events)="{ row }">
          {{ formatEvents(row.events) }}
        </template>
        <template #cell(url)="{ row }">
          {{ row.url }}
        </template>
        <template #cell(actions)="{ row }">
          <dropdown
            :options="actionOptions"
            button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
            :left-align="false"
            @select="selectAction(row, $event)">
            <template #button>
              <b-icon
                class="mr-3 text-xl"
                icon-name="menu-horizontal" />
            </template>
          </dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Webhooks'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import Alert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import BIcon from '../../atoms/Icon';
import Dropdown from '../../molecules/Dropdown';
import BModalWebhooks from '../../organisms/ModalWebhooks';
import BTable from '../../organisms/Table';

const store = useStore();
const { t } = useTranslation();

const isShownModal = ref(false);
const state = reactive({
  editWebhook: {}
});

onMounted(async () => {
  await Promise.all([loadShippingAccounts(), loadWebhooksEvents(), loadWebhooks()]);
});

const loading = computed(() => store.state.shippingAccounts.loading || store.state.webhooks.loading);
const webhooks = computed(() => store.getters['webhooks/items']);
const usedNames = computed(() => webhooks.value.map((webhook) => webhook.name.toLowerCase()));

const formatShippingAccountLabel = (shippingAccount) => {
  let label = shippingAccount.carrier_name;

  if (shippingAccount.name) {
    label += ` - ${shippingAccount.name}`;
  }

  if (shippingAccount.country) {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

    label += ` - ${regionNames.of(shippingAccount.country)}`;
  }

  return label;
};

const shippingAccountList = computed(() => {
  const result = [];

  store.getters['shippingAccounts/items'].forEach((shippingAccount) => {
    if (shippingAccount.id) {
      result.push({ value: shippingAccount.id, label: formatShippingAccountLabel(shippingAccount) });

      return;
    }

    shippingAccount.children.forEach((childShippingAccount) => {
      if (!result.find((item) => item.value === childShippingAccount.id)) {
        result.push({ value: childShippingAccount.id, label: formatShippingAccountLabel(childShippingAccount) });
      }
    });
  });

  return result;
});

const webhooksEventsList = computed(() => store.state.webhooks.events);
const error = computed(() => store.state.webhooks.error);
const isEdit = computed(() => !(Object.keys(state.editWebhook).length === 0));
const isAdmin = computed(() => store.getters['auth/isAdmin']);

const fields = computed(() => {
  const fields = [
    { key: 'name', label: 'WEBHOOKS.NAME_FIELD' },
    { key: 'description', label: 'WEBHOOKS.DESCRIPTION_FIELD' },
    { key: 'events', label: 'WEBHOOKS.EVENTS_FIELD' },
    { key: 'url', label: 'WEBHOOKS.URL_FIELD' }
  ];

  if (isAdmin.value) {
    fields.push({ key: 'actions', label: 'ACTIONS_FIELD' });
  }

  return fields;
});

const actionOptions = [
  { bringgIcon: 'pencil', value: 'edit-webhook', label: 'QUICK_ACTIONS.EDIT' },
  { bringgIcon: 'trash', value: 'remove-webhook', label: 'QUICK_ACTIONS.DELETE' }
];

const loadWebhooks = async () => await store.dispatch('webhooks/loadWebhooks');
const loadShippingAccounts = async () => await store.dispatch('shippingAccounts/loadShippingAccounts');
const loadWebhooksEvents = async () => await store.dispatch('webhooks/loadWebhooksEvents');
const clearError = () => store.commit('webhooks/clearError');

const onCreate = async (value) => {
  await store.dispatch('webhooks/createWebhook', value);
};

const onEdit = async (value) => {
  await store.dispatch('webhooks/editWebhook', value);
  isShownModal.value = false;
  state.editWebhook = {};
};

const onClose = () => {
  isShownModal.value = false;
  state.editWebhook = {};
};

const selectAction = async (row, actionType) => {
  const actions = {
    'edit-webhook': () => {
      state.editWebhook = row;
      isShownModal.value = true;
    },
    'remove-webhook': async () => {
      await store.dispatch('webhooks/deleteWebhook', row);
    }
  };

  return await actions[actionType]();
};

const formatEvents = (events) => {
  return events?.length > 1 ? `${events?.length} events` : '1 event';
};
</script>
