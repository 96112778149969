<template>
  <div class="px-6 py-8 h-full overflow-auto">
    <div class="flex items-center justify-between mb-6">
      <h1 class="text-lg text-gray-800 font-medium">{{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.TITLE') }}</h1>
      <button
        ref="closeDrawerButton"
        type="button"
        @click="emit('close')">
        <b-icon
          class="text-m text-gray-800"
          :icon-name="BringgFontIcons.Close" />
      </button>
    </div>
    <div
      v-for="(event, key) in value"
      :key="key"
      class="mt-0.5 flex">
      <div class="flex flex-col items-center mr-3">
        <div>
          <div class="w-2 h-2 mt-1.5 rounded-full border border-gray-600"></div>
        </div>
        <div
          v-if="key !== value.length - 1"
          class="w-0.5 h-full mt-1 bg-gray-400"></div>
      </div>

      <div class="mb-6">
        <div>
          <div
            v-if="event.type === ShipmentEventType.StatusUpdate"
            class="text-xs">
            <h2 class="text-sm font-medium text-gray-800">
              {{ addSpaces(event.value) }}
              <b-button
                v-if="isAdmin"
                class="ml-1 h-5 p-2"
                :loading="loading"
                @click="retriggerWebhook(event)">
                {{ t('SHIPMENT_DETAILS.REPLAY_WEBHOOK') }}
              </b-button>
            </h2>
            <p v-if="event.value === ShipmentStatus.Canceled">{{ getEventInitiatorLabel(event.user_id) }}</p>
            <p v-else>{{ addSpaces(event.value) }}</p>
          </div>

          <div
            v-if="event.type === ShipmentEventType.Checkpoint"
            class="text-xs">
            {{ event.value }}
          </div>

          <div
            v-if="
              event.type === ShipmentEventType.CarrierAssignmentFailure ||
              event.type === ShipmentEventType.CarrierCancellationFailure
            "
            class="text-xs">
            <div class="flex items-centers">
              <b-icon
                :icon-name="BringgFontIcons.Warning"
                class="mt-0.5 mr-1 text-lg text-gray-500" />
              <h2 class="text-sm font-medium text-gray-800">
                {{ getShippingAccount(event.shipping_account_id).carrier_name }}
              </h2>
            </div>

            <p>{{ event.value }}</p>
            <div v-if="event.type === ShipmentEventType.CarrierAssignmentFailure && event.data">
              <payload :payload="event.data"></payload>
            </div>
          </div>

          <div
            v-if="event.type === ShipmentEventType.CarrierAssignmentSuccess"
            class="text-xs">
            <div class="flex items-centers">
              <b-icon
                :icon-name="BringgFontIcons.Planning"
                class="mt-0.5 mr-1 text-lg text-gray-500" />
              <h2 class="text-sm font-medium text-gray-800">
                {{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.CARRIER_ASSIGNMENT_SUCCESS_TITLE') }}
              </h2>
            </div>
            <p>{{ getShippingAccount(event.shipping_account_id).carrier_name }}</p>
          </div>

          <div
            v-if="event.type === ShipmentEventType.CarrierCancellationSuccess"
            class="text-xs">
            <div class="flex items-centers">
              <b-icon
                :icon-name="BringgFontIcons.Cancel"
                class="mt-0.5 mr-1 text-lg text-gray-500" />
              <h2 class="text-sm font-medium text-gray-800">
                {{ t('SHIPMENT_DETAILS.ACTIVITY_LOG.CARRIER_CANCELLATION_SUCCESS_TITLE') }}
              </h2>
            </div>
            <p>{{ getShippingAccount(event.shipping_account_id).carrier_name }}</p>
          </div>

          <div
            v-if="[ShipmentEventType.Created, ShipmentEventType.Updated].includes(event.type)"
            class="text-xs">
            <h2 class="text-sm font-medium text-gray-800">{{ addSpaces(toPascalCase(event.type)) }}</h2>
            <p>{{ getEventInitiatorLabel(event.user_id) }}</p>
            <div v-if="event.type === ShipmentEventType.Updated && event.data">
              <changes :changes="enrichChangesWithTooltip(event.data)"></changes>
            </div>
          </div>

          <div
            v-if="event.type === ShipmentEventType.WebhookSent"
            class="text-xs">
            <div class="flex items-centers">
              <b-icon
                :icon-name="BringgFontIcons.ConnectedHub"
                class="mt-0.5 mr-1 text-lg text-gray-500" />
              <h2 class="text-sm font-medium text-gray-800">
                {{ getShippingAccount(event.shipping_account_id).carrier_name }}
              </h2>
            </div>

            <p>{{ event.value }}</p>
          </div>

          <div class="mt-2 flex justify-between text-xs text-gray-600">
            <div>{{ formatDateTime(event.timestamp) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityLog'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, ref } from 'vue';
import { useStore } from 'vuex';

import BButton from '@/components/atoms/Button';
import Button from '@/components/atoms/Button/index.vue';
import BIcon from '@/components/atoms/Icon';
import { ShipmentEventType, ShipmentStatus } from '@/enums';
import { NotificationService } from '@/services';
import { formatDateTime } from '@/utils';

import Changes from './Changes.vue';
import Payload from './Payload.vue';

const { t } = useTranslation();

defineProps({
  value: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['close']);
const store = useStore();
const error = computed(() => store.state.shipments.error);
const loading = ref(false);
const shipment = computed(() => store.state.shipments.shipmentDetails);
const isAdmin = computed(() => store.getters['auth/isAdmin']);

const getShippingAccount = (id) => store.getters['shippingAccounts/getShippingAccountById'](id);
const getUserName = (id) => store.getters['users/user'](id)?.name;

const enrichChangesWithTooltip = (changes) =>
  changes.map((change) => {
    if (change.name !== 'shipping_account_id') {
      return change;
    }

    const mappedChanges = { ...change };

    if (change.oldValue) {
      mappedChanges.oldValueTooltip = getShippingAccount(change.oldValue).name;
    }
    if (change.newValue) {
      mappedChanges.newValueTooltip = getShippingAccount(change.newValue).name;
    }

    return mappedChanges;
  });

const getEventInitiatorLabel = (id) => {
  const prefix = t('SHIPMENT_DETAILS.ACTIVITY_LOG.UPDATED_DESCRIPTION_USER_PREFIX');
  const apiLabel = t('SHIPMENT_DETAILS.ACTIVITY_LOG.UPDATED_DESCRIPTION_API');

  const name = getUserName(id);

  return name ? `${prefix} ${name}` : apiLabel;
};

const addSpaces = (string) => string.replace(/([A-Z])/g, ' $1').trim();

const toPascalCase = (string) =>
  `${string}`
    .toLowerCase()
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(new RegExp(/\s+(.)(\w*)/, 'g'), ($1, $2, $3) => `${$2.toUpperCase() + $3}`)
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());

const retriggerWebhook = async (event) => {
  loading.value = true;
  await store.dispatch('shipments/retriggerWebhook', event);
  const notification = {
    message: error.value || 'SHIPMENT_DETAILS.RETRIGGER_WEBHOOK',
    ...(error.value && { type: 'danger' })
  };

  NotificationService.addNotification(notification);
  loading.value = false;

  if (!error.value) {
    setTimeout(async () => {
      await store.dispatch('shipments/loadShipmentDetail', {
        orderId: shipment.value.order_id,
        fulfillmentId: shipment.value.fulfillment_id,
        id: shipment.value.id
      });
    }, 1500);
  }
};
</script>
