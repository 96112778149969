import axios from 'axios';
import convert from 'convert-units';
import { format } from 'date-fns';
import { compact, curryRight, last, sortBy } from 'lodash';
import { nextTick } from 'vue';

import { MimeTypes } from '@/constants';
import { Role, ShipmentPackageStatus } from '@/enums';

import europeanCountries from './assets/js/euCountries.json';
import { settingsMap } from './components/pages/Printing/constants';
import config from './config';

const LOGOUT_CHECK_TIMEOUT = 60 * 1000;
const LOGIN_PERIOD = 60 * 30;
const DEFAULT_LIMIT = 25;
const DEFAULT_PAGE = 1;

export const convertStatusCode = (value) => {
  return {
    200: 'success',
    400: 'danger',
    500: 'danger'
  }[value];
};

export function createDebounce() {
  let timeout = null;

  return function (fnc, delayMs) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
}

export async function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function formatNumber(value) {
  return new Intl.NumberFormat().format(value);
}

export function copyToClipboard(text) {
  /* Select the text field */
  // input.select();
  // input.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  navigator.clipboard.writeText(text);
}

export function getApiUrl() {
  return config.get('API_EU_BASE_URL');
}

export function getCarrierServiceApiUrl() {
  return config.get('ZENKRAFT_API_EU_BASE_URL');
}

export function getQuery() {
  return Object.fromEntries(new URLSearchParams(location.hash.split('?')[1]));
}

export async function executeRequest(
  { request, options, errorMessage, errorHandler, waitForCallback, returnError = false },
  callback
) {
  const { commit, state, rootGetters } = options;

  commit('clearError');

  request.url = rootGetters['auth/apiUrl'] + request.url;

  state.loading = true;

  try {
    const response = await axios.request({
      ...request
    });

    if (response.data.success === false) {
      throw new Error(JSON.stringify(response.data));
    }

    if (waitForCallback) {
      await (callback && callback(response));
    } else {
      callback && callback(response);
    }

    return response;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    if (returnError) {
      return error.response.data;
    } else {
      if (errorHandler) {
        state.modalError = errorHandler(error);
      } else {
        state.error = error?.response?.data?.errors?.reason || error?.response?.data?.title || errorMessage;
      }
    }
  } finally {
    state.loading = false;
  }
}
export async function withLoading(context, handler) {
  context.loading = true;
  await handler();
  context.loading = false;
}

export async function showSuccessMessage(ctx, message) {
  ctx.message = '';
  await nextTick(() => {
    ctx.message = message;
    ctx.messageStatus = 'success';
  });
  setTimeout(() => {
    ctx.message = '';
  }, 3000);
}

export async function showFailMessage(ctx, message) {
  ctx.message = '';
  await nextTick(() => {
    ctx.message = message;
    ctx.messageStatus = 'danger';
  });
}

export const round = (value) => {
  return Number(value.toFixed(3));
};

const tonsToMetric = (value) => {
  if (value === 't') {
    return 'mt';
  }

  return value;
};

export const convertUnits = (value, from, to) => {
  from = tonsToMetric(from);
  to = tonsToMetric(to);

  return round(convert(value).from(from).to(to));
};

export const convertWeightToKg = (value, unit) => {
  return convertUnits(value || 0, unit, 'kg');
};

export const convertWeightFromKg = (value, unit) => {
  return convertUnits(value || 0, 'kg', unit);
};

export const formatWeight = (row, resultingUnit = 'kg') => {
  if (!row.weight) {
    return 'n/a';
  }

  return `${convertUnits(row.weight * (row.count || 1), row.weight_units, resultingUnit)} ${resultingUnit}`;
};

export const formatDateTime = (date, fmt = 'MMM. d, Y, HH:mm:ss') =>
  format(Date.parse(date || new Date().toISOString()), fmt);

export const convertMoneySubUnitToBaseUnit = (cents, currency = 'USD') => {
  return (cents / 100).toLocaleString('en-US', { style: 'currency', currency });
};

export function runAutoLogout(logoutFunc, init) {
  const key = 'bringg-dashboard-login-time';

  if (init) {
    localStorage.setItem(key, new Date().getTime().toString());
  }

  function checkLogout() {
    const rawValue = localStorage.getItem(key);

    if (!rawValue) {
      logoutFunc();

      return;
    }

    const loginDate = new Date(parseInt(rawValue));
    const currentDate = new Date();

    if ((currentDate - loginDate) / 1000 >= LOGIN_PERIOD) {
      logoutFunc();

      return;
    }

    setTimeout(checkLogout, LOGOUT_CHECK_TIMEOUT);
  }

  setTimeout(checkLogout, LOGOUT_CHECK_TIMEOUT);
}

export const getPageSettingsFromRoute = (route) => {
  return {
    page: +route?.query?.page || DEFAULT_PAGE,
    limit: +route?.query?.limit || null,
    ...(route?.query?.search ? { search: route?.query?.search } : {})
  };
};

export const replacePaginationState = async ({ page = DEFAULT_PAGE, limit = DEFAULT_LIMIT, search }) => {
  const [url, query] = window.location.href.split('?');
  const parsedQuery = new URLSearchParams(query);

  parsedQuery.set('page', page);
  parsedQuery.set('limit', limit);

  if (search !== '' && search !== undefined) {
    parsedQuery.set('search', search);
  } else {
    parsedQuery.delete('search');
  }

  window.history.pushState(null, '', url + '?' + parsedQuery.toString());
};

export const getCarrierLogo = (carrier) => {
  return `https://cdn.zenkraft.com/static/images/carriers/${carrier}.png`;
};

export const getCarrierNameByShippingAccount = (shippingAccount, carrierNames) => {
  const { name, carrier } = shippingAccount;

  return carrier === 'custom' ? name : carrierNames[carrier] || carrier;
};

export const getCarrierNameByShipment = (shipment, store) => {
  const shippingAccount = store.getters['shippingAccounts/getShippingAccountById'](shipment.shipping_account_id);

  return getCarrierNameByShippingAccount(shippingAccount, store.getters['carriers/carrierNames']);
};

export const formatAddress = (address) => {
  if (!address) {
    return '';
  }

  const street1 = address.street1;
  const city = address.city;
  const country = address.country;
  const postalCode = address.postal_code;
  let state = '';

  if (address.state) {
    state = address.state + ' ';
  }

  return [street1, city, `${state}${postalCode}`, country].join(', ');
};

export const convertDimensions = (item, unit) => {
  const convertToCm = curryRight(convertUnits)(unit);
  const length = convertToCm(item.length || 0, formatUnit(item.dim_units || unit));
  const width = convertToCm(item.width || 0, formatUnit(item.dim_units || unit));
  const height = convertToCm(item.height || 0, formatUnit(item.dim_units || unit));

  return { width, height, length };
};

const formatUnit = (value) => {
  const unitMapping = {
    inch: 'in',
    mile: 'mi',
    yard: 'yd'
  };

  if (value in unitMapping) {
    return unitMapping[value];
  }

  return value;
};

export const formatItemDimensions = (item) => {
  const unit = 'cm';
  const dimensions = convertDimensions(item, unit);

  return `${dimensions.length} x ${dimensions.width} x ${dimensions.height} ${unit}`;
};

export const formatDeliverByColumn = (row) => {
  try {
    return format(Date.parse(row.deliver_by), 'MMM. d, Y');
  } catch (e) {
    return '';
  }
};

export const checkIfIsInternational = (fulfillmentDetails) => {
  const senderCountry = fulfillmentDetails?.value.sender_address.country.toUpperCase();
  const recipientCountry = fulfillmentDetails?.value.recipient_address.country.toUpperCase();

  const skippedField = senderCountry === undefined || recipientCountry === undefined;
  const allEuCountries = europeanCountries.includes(senderCountry) && europeanCountries.includes(recipientCountry);

  if (skippedField || allEuCountries) {
    return false;
  }

  return senderCountry !== recipientCountry;
};

export const calculateProductsTotalWeight = ({ products, weightUnit = 'kg' }) =>
  products.reduce((result, value) => {
    const product = value.product || {};
    const weight = product.weight || 0;
    const unit = product.weight_units || weightUnit;

    return result + value.count * convertWeightToKg(weight, unit);
  }, 0);

export const formatShipment = (item) => ({
  id: item.id,
  orderId: item.order_id,
  fulfillmentId: item.fulfillment_id,
  carrier_logo: item.carrier_logo,
  carrier: item.carrier,
  status: item.status,
  service: item.service,
  reference: item.carrier_reference_number,
  packages: item.packages_count,
  shipping_account_id: item.shipping_account_id,
  items: item.items,
  source_id: item.source_id
});

export const phoneRegex = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,8}$');

export const isCarrierAssignedToShipment = (shipment) => {
  if (!shipment) {
    return false;
  }

  const { tracking_number, carrier_reference_number, carrier } = shipment;

  return [tracking_number, carrier_reference_number, carrier].every(Boolean);
};

export const stylizeLabelAsRequired = (label) => `* ${label}`;

export const capitalizeFirstLetter = (word) => (word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '');

export const checkReadyPrinterSettings = ({ type, state }) => {
  for (const value of settingsMap[type]) {
    if (!state.userSettings?.printersSettings?.[value]) {
      return false;
    }
  }

  return true;
};

export const getFailedCancelDescription = (textTemplate, carrier) => {
  return `${capitalizeFirstLetter(carrier)} ${textTemplate}`;
};

export const trimErrorText = (errorText) => {
  // Unique parcelforce errors, looks like that :
  // {"carrier_exception": "Parcelforce API: This shipment can't be canceled"}
  // {"reason":"carrier_exception: Parcelforce API: This shipment can't be canceled"}
  const token = 'carrier_exception: ';

  const parts = errorText.split(token);

  if (parts.length > 1) {
    return parts.at(-1);
  }

  return errorText;
};

export const formatPackageDimensions = (item) => {
  const unit = 'cm';
  const dimensions = convertDimensions(item, unit);

  return `${dimensions.length} (L) x ${dimensions.width} (W) x ${dimensions.height} (H) ${unit}`;
};

//  Retrieves the most recent event with the provided type.
export const getLastEvent = ({ events = [], type = '' }) => {
  return last(
    sortBy(
      events.filter((item) => type === item.type),
      'timestamp'
    )
  );
};

const detectMimeType = (base64) => {
  const signatures = {
    JVBERi0: MimeTypes.pdf,
    X: MimeTypes.zpl
  };

  for (const [signature, type] of Object.entries(signatures)) {
    if (base64.startsWith(signature)) {
      return type;
    }
  }

  return null;
};

export const decodeAndDetectMimeType = (base64EncodedStr) => {
  try {
    const mimeType = detectMimeType(base64EncodedStr);

    if (!mimeType) {
      return null;
    }

    const decodedValue = atob(base64EncodedStr);

    const uint8Array = new Uint8Array(decodedValue.length);

    for (let i = 0; i < decodedValue.length; i++) {
      uint8Array[i] = decodedValue.charCodeAt(i);
    }

    return {
      uint8Array,
      mimeType,
      base64DecodedValue: decodedValue
    };
  } catch (e) {
    return null;
  }
};

export const formatTotalWeight = (weight, weightUnit = 'kg') => {
  if (!weight) {
    return 'n/a';
  }

  return `${new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 3
  }).format(weight)} ${weightUnit}`;
};

export const getPudoLocationLabel = (location) => {
  return compact([
    location.location_code,
    location.location_name,
    location.street1,
    location.city,
    location.state,
    location.country
  ]).join(', ');
};

export const ShipmentPackageStatusComparator = (aStatus, bStatus) => {
  // lowest number would be first in view

  const mapper = {
    [ShipmentPackageStatus.ReadyToShip]: 1,
    [ShipmentPackageStatus.PickedUp]: 2,
    [ShipmentPackageStatus.InTransit]: 3,
    [ShipmentPackageStatus.OutForDelivery]: 4,
    [ShipmentPackageStatus.Delivered]: 5,
    [ShipmentPackageStatus.Exception]: 6,
    [ShipmentPackageStatus.CanceledByCarrier]: 7
  };

  const getValue = (status) => mapper[status] ?? Number.POSITIVE_INFINITY;

  return getValue(aStatus) - getValue(bStatus);
};

export const formatRole = (user) => {
  if (user.admin) {
    return Role.Admin;
  }

  if (user.dispatcher && !user.driver) {
    return Role.Packer;
  }

  return Role.CustomerService;
};
