<template>
  <div class="flex content-center">
    <b-input
      v-if="state.isEditing"
      :id="state.id"
      :value="modelValue"
      width-by-content
      class="editable-input" />

    <span
      v-if="!state.isEditing"
      class="text-gray-600 text-sm"
      @click="onEdit">
      {{ modelValue || placeholder }}
    </span>

    <b-button
      v-if="!state.isEditing"
      data-id="edit_button"
      class="bg-transparent h-auto py-0 px-1"
      @click="onEdit">
      <b-icon
        class="text-gray-600"
        :icon-name="BringgFontIcons.Pencil" />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'BEditable'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { uniqueId } from 'lodash';
import { defineEmits, defineProps, nextTick, reactive } from 'vue';

import BButton from '../../atoms/Button';
import BIcon from '../../atoms/Icon';
import BInput from '../../atoms/Input';

const emit = defineEmits(['update:modelValue']);

defineProps({
  modelValue: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  }
});

const state = reactive({
  id: `editable-${uniqueId()}`,
  isEditing: false
});

const onEdit = async () => {
  state.isEditing = !state.isEditing;

  await nextTick();

  const input = document.querySelector(`#${state.id}`);

  input.focus();

  input.addEventListener('blur', () => onSave(input.value));
  input.addEventListener('keypress', (event) => event.key === 'Enter' && onSave(input.value));
};

const onSave = (value) => {
  state.isEditing = false;
  emit('update:modelValue', value);
};
</script>

<style lang="scss">
.editable-input {
  input {
    @apply text-gray-600;
    @apply p-0;
    @apply h-full;
    @apply border-none;
    @apply bg-transparent;
    @apply leading-none;
    @apply rounded-none;
    @apply focus:shadow-none;

    /**
    To have same size as text
     */
    padding-top: 1px;
    padding-bottom: 1px;
  }
}
</style>
