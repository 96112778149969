import client from '../api';

class ReturnMethodService {
  name = 'Return Method';

  async get() {
    const {
      data: { return_methods: returnMethods }
    } = await client.getReturnMethods();

    return returnMethods;
  }

  async post(value) {
    const rawReturnMethod = { ...value, position: 0 };

    rawReturnMethod['filters'] = rawReturnMethod['filters'] || [];
    rawReturnMethod['return_charge'] = String(rawReturnMethod['return_charge']);

    const {
      data: { return_method: returnMethod }
    } = await client.postReturnMethod({
      return_method: rawReturnMethod
    });

    return returnMethod;
  }

  async put(value) {
    const rawReturnMethod = { ...value };
    const { id: returnMethodId } = rawReturnMethod;

    delete rawReturnMethod.id;
    delete rawReturnMethod.currency;

    rawReturnMethod['filters'] = rawReturnMethod['filters'] || [];
    rawReturnMethod['return_charge'] = String(rawReturnMethod['return_charge']);

    const {
      data: { return_method: returnMethod }
    } = await client.putReturnMethod(returnMethodId, { return_method: rawReturnMethod });

    return returnMethod;
  }

  async delete(id) {
    await client.deleteReturnMethod(id);
  }
}

export default new ReturnMethodService();
