export const packItemsFields = [
  { key: 'name', label: 'FULFILLMENT_DETAILS.ITEM_NAME_FIELD' },
  { key: 'count', label: 'FULFILLMENT_DETAILS.TOTAL_QUANTITY_FIELD' },
  { key: 'quantity-to-pack', label: 'FULFILLMENT_DETAILS.QUANTITY_TO_PACK_FIELD' },
  { key: 'total_weight', label: 'TOTAL_WEIGHT' },
  { key: 'dimensions', label: 'FULFILLMENT_DETAILS.DIMENSIONS_FIELD' },
  { key: 'actions', label: '' }
];

export const packagesFields = [
  { key: 'name', label: 'FULFILLMENT_DETAILS.PACKAGE_FIELD' },
  { key: 'count', label: 'FULFILLMENT_DETAILS.ITEM_COUNT_FIELD' },
  { key: 'total_weight', label: 'TOTAL_WEIGHT' },
  { key: 'price', label: 'FULFILLMENT_DETAILS.DECLARED_VALUE_FIELD' },
  { key: 'actions', label: '' }
];

export const packageActionOptions = [
  { bringgIcon: 'trash', value: 'remove-package', label: 'FULFILLMENT_DETAILS.REMOVE_PACKAGE_OPTION' }
  // { bringgIcon: 'copy', value: 'change-package-size', label: 'FULFILLMENT_DETAILS.CHANGE_PACKAGE_SIZE_OPTION' },
  // { bringgIcon: 'print', value: 'print-package-slip', label: 'FULFILLMENT_DETAILS.PRINT_PACKAGE_SLIP_OPTION' },
];

export const packedProductActionOptions = [
  { bringgIcon: '', value: 'unpack', label: 'FULFILLMENT_DETAILS.RETURN_PRODUCT_OPTION' }
  // { bringgIcon: '', value: 'move', label: 'FULFILLMENT_DETAILS.MOVE_PRODUCT_OPTION' },
];

export const carrierOptions = [
  {
    name: 'UPS',
    children: [
      {
        name: 'UPS Ground',
        date: '2022.10.10',
        time: '8:00',
        cost: 3.22
      },
      {
        name: 'UPS 2 day',
        date: '2022.10.10',
        time: '8:00',
        cost: 3.5
      },
      {
        name: 'UPS Next Day',
        date: '2022.10.10',
        time: null,
        cost: 4.22
      }
    ]
  },
  {
    name: 'Uber',
    children: [
      {
        name: 'On Demand',
        date: '2022.10.10',
        time: '10:00',
        cost: 10
      }
    ]
  },
  {
    name: 'FedEx',
    children: [
      {
        name: 'FedEx Priority Overnight',
        date: '2022.10.10',
        time: null,
        cost: 2.5
      },
      {
        name: 'FedEx 2 Day',
        date: '2022.10.10',
        time: null,
        cost: null
      },
      {
        name: 'FedEx Ground',
        date: '2022.10.10',
        time: null,
        cost: null
      }
    ]
  }
];
