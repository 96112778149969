<template>
  <TransitionRoot
    :show="value"
    as="template">
    <div class="fixed z-50 inset-0">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            @click="$emit('close')" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden md:inline-block md:align-middle md:h-screen"
          aria-hidden="true">
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          enter-to="opacity-100 translate-y-0 md:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 md:scale-100"
          leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
          <div
            class="modal-content inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all md:my-8 md:align-middle lg:w-5/12 md:w-5/12">
            <div class="hidden md:block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                class="bg-white rounded-md text-gray-400 hover:text-gray-500"
                @click="$emit('close')">
                <span class="sr-only">Close</span>
                <XIcon
                  class="h-6 w-6"
                  aria-hidden="true" />
              </button>
            </div>

            <slot />
          </div>
        </TransitionChild>
      </div>
    </div>
  </TransitionRoot>
</template>

<script>
export default {
  name: 'BModal'
};
</script>

<script setup>
import { TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { defineProps } from 'vue';

defineProps({
  value: {
    type: Boolean,
    default: false
  }
});
</script>
