import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import I18NextVue from 'i18next-vue';

export default function (app) {
  i18next.use(HttpApi).init({
    fallbackLng: 'en',
    returnEmptyString: false,
    debug: false,
    ns: 'default',
    keySeparator: '.',
    backend: {
      loadPath: '/locale/{{lng}}.json'
    },
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false
    },
    fallbackNS: 'default'
  });

  app.use(I18NextVue, { i18next });
}
