import client from '../api';

class ShipmentService {
  name = 'Shipment';

  async get() {
    const {
      data: { shipments }
    } = await client.getShipments();

    return shipments;
  }
}

export default new ShipmentService();
