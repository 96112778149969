<template>
  <div
    v-if="!isMounted || state.isLoading"
    class="h-full flex justify-center items-center">
    <spinner />
  </div>
  <div
    v-else
    class="relative flex flex-col h-full px-10 py-6">
    <alert
      v-if="error && errorFromCarrier"
      class="mx-6 my-5"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>

    <drawer-shipment
      v-if="drawerData.value.open"
      :top="drawerTop"
      :data="drawerData.value"
      @open-carrier-modal="showModal(ASSIGN_MODAL_ID)"
      @items-open-carrier-modal="state.showModalAfterEditing = true"
      @close="closeDrawer"></drawer-shipment>
    <template v-if="!error || !errorFromCarrier">
      <ShipmentDetailsHeader
        :id="id"
        :fulfillment-id="fulfillmentId"
        :order-id="orderId"
        @open-notes="state.showNotes = true" />
      <div class="h-full flex gap-14 pb-6">
        <div class="w-144 flex flex-col gap-6">
          <ShipmentDetailsCarrier />
          <ShipmentDetailsItems />
        </div>
        <div class="w-144 flex flex-col gap-8">
          <ShipmentDetailsAddresses />
          <ShipmentProgressBar />
        </div>
      </div>
    </template>
    <div class="overflow-auto">
      <drawer
        v-if="state.showNotes"
        :open="state.showNotes"
        :top="drawerTop"
        @close="closeNotes">
        <template #content>
          <notes
            with-tooltip
            :notes="orderDetails?.notes || []"
            :tooltip-text="t('NOTES.TOOLTIP')"
            @commit="commitNotesChanges"
            @close="closeNotes"></notes>
        </template>
      </drawer>
    </div>
    <span>
      <fulfillment-carrier-modal
        ref="fulfilmentCarrierModal"
        update-mode
        :shipment-details="shipmentDetails"
        :order-id="orderId"
        :is-packer="isPacker"
        :fulfillment-id="fulfillmentId"
        :is-open="state.isModalShown[ASSIGN_MODAL_ID]"
        @close="hideModal(ASSIGN_MODAL_ID)"
        @redispatched="redispatchedCallback" />
    </span>
    <span>
      <fulfillment-carrier-modal
        update-mode
        after-editing-items
        :shipment-details="shipmentDetails"
        :order-id="orderId"
        :fulfillment-id="fulfillmentId"
        :is-open="state.showModalAfterEditing"
        @close="state.showModalAfterEditing = false" />
    </span>
    <confirm-modal
      :is-shown="state.isModalShown[CANCEL_MODAL_ID]"
      @close="hideModal(CANCEL_MODAL_ID)"
      @accept="handleCancelShipment"
      @reject="hideModal(CANCEL_MODAL_ID)">
      <template #title>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.CONFIRM') }}
      </template>
      <template #description>
        {{ cancelShipmentModalDescription }}
      </template>
      <template #accept-label>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.CONFIRM') }}
      </template>
      <template #reject-label>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECT') }}
      </template>
    </confirm-modal>
    <confirm-modal
      :is-shown="state.isModalShown[CANCEL_SUCCESS_MODAL_ID]"
      :use-buttons="false"
      @close="hideModal(CANCEL_SUCCESS_MODAL_ID)">
      <template #title>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.CONFIRM') }}
      </template>
      <template #description>
        {{ state.successCancelDescription }}
      </template>
    </confirm-modal>
    <confirm-modal
      :is-shown="state.isModalShown[CANCEL_FAILED_MODAL_ID] && !isPacker"
      @close="hideModal(CANCEL_FAILED_MODAL_ID)"
      @accept="forceCancelShipment"
      @reject="hideModal(CANCEL_FAILED_MODAL_ID)">
      <template #title>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_HEADER') }}
      </template>
      <template #description>
        <div>
          {{
            getFailedCancelDescription(
              t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_TEXT_CONFIRMATION'),
              shipmentDetails.carrier
            )
          }}
        </div>
        <div class="text-red-500">{{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_TEXT_WARNING') }}</div>
      </template>
      <template #accept-label>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.CONFIRM') }}
      </template>
      <template #reject-label>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECT') }}
      </template>
    </confirm-modal>
    <confirm-modal
      :is-shown="state.isModalShown[CANCEL_FAILED_MODAL_ID] && isPacker"
      :use-ignore-button="false"
      @close="hideModal(CANCEL_FAILED_MODAL_ID)"
      @accept="hideModal(CANCEL_FAILED_MODAL_ID)">
      <template #title>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_HEADER') }}
      </template>
      <template #description>
        <div>
          {{
            getFailedCancelDescription(
              t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_TEXT_PACKER'),
              shipmentDetails.carrier
            )
          }}
        </div>
      </template>
      <template #accept-label>
        {{ t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECT') }}
      </template>
    </confirm-modal>
  </div>

  <popup-carrier-booked
    :is-shown="state.carrierBookedPopup.show"
    :name="state.carrierBookedPopup.name"
    @close="handleClosedPopup"></popup-carrier-booked>
</template>

<script>
export default {
  name: 'ShipmentDetails'
};
</script>

<script setup>
import format from 'date-fns/format';
import { useTranslation } from 'i18next-vue';
import { get, isEmpty } from 'lodash';
import { computed, defineProps, onMounted, provide, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import countryList from '@/assets/js/countries.json';
import Alert from '@/components/atoms/Alert';
import Spinner from '@/components/atoms/Spinner';
import Notes from '@/components/molecules/Notes';
import ConfirmModal from '@/components/organisms/ConfirmModal';
import Drawer from '@/components/organisms/Drawer';
import DrawerShipment from '@/components/organisms/DrawerShipment';
import FulfillmentCarrierModal from '@/components/organisms/ModalFulfillmentCarrier';
import PopupCarrierBooked from '@/components/organisms/PopupCarrierBooked';
import ShipmentDetailsAddresses from '@/components/organisms/ShipmentDetailsAddresses';
import ShipmentDetailsCarrier from '@/components/organisms/ShipmentDetailsCarrier';
import ShipmentDetailsHeader from '@/components/organisms/ShipmentDetailsHeader';
import ShipmentDetailsItems from '@/components/organisms/ShipmentDetailsItems';
import ShipmentProgressBar from '@/components/organisms/ShipmentProgressBar';
import { AddressType, FulfillmentMethod, ShipmentEventType, ShipmentStatus } from '@/enums';
import {
  capitalizeFirstLetter,
  convertMoneySubUnitToBaseUnit,
  getFailedCancelDescription,
  trimErrorText
} from '@/utils';

import {
  ActivityLogKey,
  FulfilmentCarrierModalKey,
  HideModalKey,
  ModalIds,
  ModalStateKey,
  RejectionReasonKey,
  ReversedShipmentEventsKey,
  ShipmentEventsKey,
  ShowModalKey,
  ToggleDrawerKey
} from './constants';

const { t } = useTranslation();
const store = useStore();
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  orderId: {
    type: String,
    required: true
  },
  fulfillmentId: {
    type: String,
    required: true
  }
});

const fulfilmentCarrierModal = ref(null);

provide(FulfilmentCarrierModalKey, fulfilmentCarrierModal);

const isMounted = ref(false);

const drawerTop = 56;

onMounted(async () => {
  const { orderId, fulfillmentId } = props;

  store.dispatch('shippingAccounts/resetShippingAccountsByRule');

  await Promise.all([
    store.dispatch('orders/loadOrdersById', orderId),
    store.dispatch('users/loadUsers'),
    store.dispatch('shipments/loadShipmentDetail', { ...props }),
    store.dispatch('shipments/loadFulfillmentShipments', { orderId, fulfillmentId }),
    store.dispatch('fulfillments/loadFulfillmentById', { orderId, fulfillmentId }),
    store.dispatch('shippingAccounts/loadShippingAccounts')
  ]);

  isMounted.value = true;
});

const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);
const orderDetails = computed(() => store.state.orders.itemDetails);
const shipmentDetails = computed(() => store.state.shipments.shipmentDetails);
const isPacker = computed(() => store.getters['auth/isPacker'] && !store.getters['auth/isAdmin']);

const shippingAccount = computed(() =>
  store.getters['shippingAccounts/getShippingAccountById'](shipmentDetails.value?.shipping_account_id)
);

const carrierRenderData = computed(() => {
  return [
    { label: t('SHIPMENT_DETAILS.SERVICE_LEVEL'), value: shipmentDetails.value?.service || '-' },
    {
      label: t('SHIPMENT_DETAILS.ESTIMATED_DELIVERY_DATE'),
      value: shipmentDetails.value?.deliver_by ? format(new Date(shipmentDetails.value?.deliver_by), 'MM.dd.yyyy') : '-'
    },
    { label: t('SHIPMENT_DETAILS.TRACKING_NUMBER'), value: shipmentDetails.value?.tracking_number || '-' },

    { label: t('SHIPMENT_DETAILS.REFERENCE_NUMBER'), value: shipmentDetails.value?.carrier_reference_number || '-' },
    {
      label: t('SHIPMENT_DETAILS.FULFILLMENT_TYPE'),
      value: fulfillmentDetails.value?.fulfillment_method || '-'
    },
    {
      label: t('FIELDS.CREATED_FIELD'),
      value: shipmentDetails.value?.created_at
        ? format(new Date(shipmentDetails.value?.created_at), 'MM.dd.yyyy K:s')
        : '-'
    }
  ];
});

const deliveryCostRenderData = computed(() => {
  const { delivery_cost, net_charge, taxes, surcharges, discounts } = shipmentDetails.value;
  const currency = orderDetails.value.currency;
  const totalCost = [delivery_cost, net_charge, taxes, surcharges].reduce((acc, cur) => acc + +cur, 0) - discounts;

  return [
    { label: t('COSTS.DELIVERY_COST'), value: delivery_cost, dataId: 'delivery-cost' },
    { label: t('COSTS.NET_CHARGE'), value: net_charge, dataId: 'net-charge' },
    { label: t('COSTS.TAXES'), value: taxes, dataId: 'taxes' },
    { label: t('COSTS.SURCHARGES'), value: surcharges, dataId: 'surcharges' },
    { label: t('COSTS.DISCOUNTS'), value: discounts, dataId: 'discounts' },
    { label: t('COSTS.TOTAL_COST'), value: totalCost, dataId: 'total-cost' }
  ].map((item) => ({
    label: item.label,
    dataId: item.dataId,
    value: convertMoneySubUnitToBaseUnit(item.value, currency)
  }));
});

const sortByTimestamp = (a, b) => new Date(a.timestamp) - new Date(b.timestamp);

const shipmentEvents = computed(() => {
  const events = shipmentDetails.value?.shipment_events;

  if (!events) {
    return [];
  }

  return [...events].sort(sortByTimestamp);
});

provide(ShipmentEventsKey, shipmentEvents);

const reversedShipmentEvents = computed(() => [...shipmentEvents.value].reverse());

provide(ReversedShipmentEventsKey, reversedShipmentEvents);

const rejectionReason = computed(() => {
  const event = shipmentEvents.value.filter((item) => ShipmentEventType.CarrierAssignmentFailure === item.type).at(-1);

  if (!event) {
    return null;
  }
  const shippingAccount = store.getters['shippingAccounts/getShippingAccountById'](event.shipping_account_id);

  return `${capitalizeFirstLetter(shippingAccount?.name ?? '-')} ${capitalizeFirstLetter(event.service ?? '-')} - ${
    event.value
  }`;
});

provide(RejectionReasonKey, rejectionReason);

const error = computed(
  () =>
    store.state.orders.error ||
    store.state.shipments.error ||
    store.state.packages.error ||
    store.state.shippingAccounts.error
);

const errorFromCarrier = typeof error === 'string' && error.includes('carrier_exception');

const clearError = () => {
  store.commit('shipments/clearError');
  store.commit('orders/clearError');
  store.commit('packages/clearError');
  store.commit('shippingAccounts/clearError');
};

const cancelShipment = async (force = false) => {
  state.isLoading = true;

  await store.dispatch('shipments/cancelShipment', {
    orderId: props.orderId,
    fulfillmentId: props.fulfillmentId,
    shipmentId: props.id,
    data: {
      force
    }
  });

  state.isLoading = false;
};

const loadShipmentDetails = async () => {
  state.isLoading = true;
  await store.dispatch('shipments/loadShipmentDetail', { ...props });
  state.isLoading = false;
};

const forceCancelShipment = async () => {
  const force = true;

  await cancelShipment(force);

  if (!error.value) {
    state.successCancelDescription = `${t('SHIPMENT_DETAILS.CANCEL_MODALS.REJECTED_TEXT').replace(
      '%%%',
      capitalizeFirstLetter(shipmentDetails.value.carrier)
    )}`;

    showModal(CANCEL_SUCCESS_MODAL_ID);
  }

  hideModal(CANCEL_FAILED_MODAL_ID);
  await loadShipmentDetails();
};

const handleCancelShipment = async () => {
  const previousStatus = shipmentDetails.value.status;

  hideModal(CANCEL_MODAL_ID);

  await cancelShipment();

  if (error.value) {
    const CANCEL_ERROR = 'The shipment was not cancelled';

    if (trimErrorText(error.value) !== error.value || error.value === CANCEL_ERROR) {
      showModal(CANCEL_FAILED_MODAL_ID);
    }

    return;
  }

  if (previousStatus === ShipmentStatus.New) {
    await loadShipmentDetails();

    return;
  }

  state.successCancelDescription = `${t('SHIPMENT_DETAILS.CANCEL_MODALS.SUCCESS_TEXT')} ${capitalizeFirstLetter(
    shipmentDetails.value.carrier
  )}`;

  showModal(CANCEL_SUCCESS_MODAL_ID);
  await loadShipmentDetails();
};

const cancelShipmentModalDescription = computed(
  () =>
    `${t('SHIPMENT_DETAILS.CANCEL_MODALS.CONFIRMATION_TEXT')} ${
      shipmentDetails.value.tracking_number ? `#${shipmentDetails.value.tracking_number}` : 'shipment'
    }${shipmentDetails.value.source_id ? ` ${shipmentDetails.value.source_id}` : ''}?`
);

const activityLogAllowedTypes = [
  ShipmentEventType.StatusUpdate,
  ShipmentEventType.Checkpoint,
  ShipmentEventType.CarrierAssignmentFailure,
  ShipmentEventType.CarrierCancellationFailure,
  ShipmentEventType.Created,
  ShipmentEventType.Updated,
  ShipmentEventType.CarrierAssignmentSuccess,
  ShipmentEventType.CarrierCancellationSuccess,
  ShipmentEventType.WebhookSent
];

const activityLog = computed(() =>
  reversedShipmentEvents.value
    // Strictly filer types, so no unmanaged type comes through inexplicitly when new type is added to the shipment events
    .filter((item) => activityLogAllowedTypes.includes(item.type))
);

provide(ActivityLogKey, activityLog);

const initialDrawerData = () => ({
  open: false,
  type: ''
});

const { ASSIGN_MODAL_ID, CANCEL_MODAL_ID, CANCEL_SUCCESS_MODAL_ID, CANCEL_FAILED_MODAL_ID } = ModalIds;

const state = reactive({
  isModalShown: {
    [ASSIGN_MODAL_ID]: false,
    [CANCEL_MODAL_ID]: false,
    [CANCEL_SUCCESS_MODAL_ID]: false,
    [CANCEL_FAILED_MODAL_ID]: false
  },
  showModalAfterEditing: false,
  showNotes: false,
  isLoading: false,
  successCancelDescription: '',
  carrierBookedPopup: {
    name: '',
    show: false
  }
});

provide(ModalStateKey, state);

const showModal = (id) => (state.isModalShown[id] = true);

provide(ShowModalKey, showModal);
const hideModal = (id) => (state.isModalShown[id] = false);

provide(HideModalKey, hideModal);

const drawerData = reactive({ value: initialDrawerData() });

const drawerValue = {
  activityLog,
  carrier: {
    carrierRenderData,
    deliveryCostRenderData,
    shippingAccount
  },
  addresses: {
    shipmentId: props.id,
    orderId: props.orderId,
    fulfillmentId: props.fulfillmentId
  },
  packages: {
    shipmentDetails
  }
};

const toggleDrawer = (type) => {
  if (drawerData.value.type === type) {
    closeDrawer();
  } else {
    drawerData.value = {
      type,
      open: true,
      value: drawerValue[type]
    };
  }
};

provide(ToggleDrawerKey, toggleDrawer);

const closeDrawer = () => {
  drawerData.value = initialDrawerData();
};

const closeNotes = () => {
  state.showNotes = false;
};

const commitNotesChanges = async (notes) => {
  await store.dispatch('orders/updateOrder', { orderId: props.orderId, data: { notes } });
};

const redispatchEnabled = computed(() => Boolean(!shipmentDetails.value.carrier && rejectionReason.value));

const showPopup = (carrier) => {
  state.carrierBookedPopup.name =
    !carrier?.carrierName || !carrier.serviceName ? '' : `${carrier.carrierName} - ${carrier.serviceName}`;

  state.carrierBookedPopup.show = true;
};

const redispatchedCallback = (carrier) => {
  hideModal(ASSIGN_MODAL_ID);

  if (!redispatchEnabled.value) {
    showPopup(carrier);

    return;
  }

  showModal(ASSIGN_MODAL_ID);
};

const handleClosedPopup = () => (state.carrierBookedPopup.show = false);
</script>
