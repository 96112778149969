export const defaultClassList =
  'h-10 inline-flex items-center py-2 border border-transparent font-medium justify-center ' +
  'rounded bg-blue-400 text-white text-base focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed';
export const loadingClassList = 'relative';
export const primaryClassList = '';
// + 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500';
export const secondaryClassList = '';
// + 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500';
export const sizeClassList = {
  small: 'px-3 text-sm',
  large: 'px-4 text-base'
};
export const typeClassList = {
  primary: primaryClassList,
  secondary: 'text-blue-400 bg-blue-50 hover:bg-blue-25 focus:ring-blue-100',
  info: 'text-white bg-blue-400 hover:bg-blue-700 focus:ring-blue-500',
  success: 'text-white bg-green-500 hover:bg-green-700 focus:ring-green-500',
  warning: 'text-white bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500',
  danger: 'text-white bg-red-600 hover:bg-red-700 focus:ring-red-500',
  blank: 'text-black bg-white-400'
};

export const iconClassList = {
  small: 'w-6 h-6 rounded-full p-0 bg-white text-gray-700 button-shadow',
  large: 'w-10 h-10 rounded-full p-0 bg-white text-gray-700 button-shadow'
};

export default {
  defaultClassList,
  loadingClassList,
  primaryClassList,
  secondaryClassList,
  sizeClassList,
  typeClassList,
  iconClassList
};
