import { executeRequest } from '@/utils';

const state = {
  items: [],
  itemDetails: {},
  loading: false,
  error: '',
  total: undefined
};

const getters = {
  items: (state) => state.items
};

const handleFulfillmentDetails = async (options, response) => {
  const fulfillment = response.data;

  if (fulfillment.shipping_account_id) {
    fulfillment.shipping_account = await options.dispatch(
      'shippingAccounts/loadShippingAccountById',
      fulfillment.shipping_account_id,
      { root: true }
    );
  }

  options.commit('setFulfillmentItem', fulfillment);
};

const actions = {
  async loadFulfillments(options, params) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/fulfillments`,
        params
      },
      options,
      errorMessage: 'FULFILLMENTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setFulfillments', response.data);
      options.commit('setFulfillmentsTotal', Number(response.headers['total-count']));
    });
  },

  async filterFulfilllments(options, data) {
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/fulfillments/filter`,
        data
      },
      options,
      errorMessage: 'FULFILLMENTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setFulfillments', response.data);
      options.commit('setFulfillmentsTotal', Number(response.headers['total-count']));
    });
  },

  async loadOrderFulfillments(options, orderId) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments`
      },
      options,
      errorMessage: 'FULFILLMENTS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setFulfillments', response.data));
  },

  async loadFulfillmentById(options, { orderId, fulfillmentId }) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}`
      },
      options,
      errorMessage: 'FULFILLMENT_DETAILS_LOADING_FAIL',
      waitForCallback: true
    };

    await executeRequest(request, async (response) => await handleFulfillmentDetails(options, response));
  },

  async updateFulfillmentDetails(options, { orderId, fulfillmentId, data }) {
    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}`,
        data
      },
      options,
      errorMessage: 'FULFILLMENT_DETAILS_UPDATING_FAIL'
    };

    await executeRequest(request, (response) => handleFulfillmentDetails(options, response));
  },

  async clearFulfillments(options) {
    options.commit('setFulfillments', []);
    options.commit('setFulfillmentsTotal', 0);
  }
};

const mutations = {
  setFulfillments(state, value) {
    state.items = value;
  },

  setFulfillmentsTotal(state, count) {
    state.total = count;
  },

  setFulfillmentItem(state, value) {
    state.itemDetails = value;
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
