<template>
  <modal
    :value="isShown"
    :validation="schema"
    @close="onClose">
    <template #title>
      <h3 class="px-6 py-4 text-lg leading-6 font-medium text-gray-900">
        {{ props.isEdit ? t('API_APPS_MODAL.EDIT_API_APP') : t('API_APPS_MODAL.NEW_API_APP') }}
      </h3>
      <divider></divider>
    </template>
    <template
      v-if="data.localValue"
      #body>
      <div class="px-6 py-4 w-4/5">
        <b-input
          id="name"
          v-model:value="data.localValue.name"
          :disabled="props.isEdit || created"
          class="mb-4">
          <template #label>
            {{ t('API_APPS_MODAL.NAME') }}
          </template>
        </b-input>
      </div>
      <div class="text-xs">
        <div
          v-if="created || props.isEdit"
          class="px-6 py-4 m-4 w-4/5 bg-gray-50">
          <div class="font-bold">{{ t('API_APPS_MODAL.CLIENT_ID') }}</div>
          <div class="text-gray-900">{{ data.localValue.uid }}</div>
        </div>
        <div v-if="created">
          <div class="px-6 py-4 mx-4 w-4/5 bg-gray-50">
            <div class="font-bold">{{ t('API_APPS_MODAL.CLIENT_SECRET_KEY') }}</div>
            <div class="text-gray-900">{{ data.localValue.secret }}</div>
          </div>
          <div class="px-6 py-1 mx-4 w-4/5 bg-yellow-50">
            <div class="font-bold">{{ t('API_APPS_MODAL.CLIENT_SECRET_KEY') }}</div>
            <div>
              {{ t('API_APPS_MODAL.SECRET_WARN') }}
            </div>
          </div>
        </div>
        <div class="flex flex-1 px-6 py-4 mx-4 w-4/5">
          <Switch
            v-if="props.isEdit || created"
            v-model:value="data.localValue.enabled">
            <template #before>
              <span class="mr-2 text-sm font-semibold">{{ t('API_APPS_MODAL.APP_STATUS') }}</span>
            </template>
            <template #after>
              <span class="ml-2 text-sm text-gray-600">
                {{ data.localValue.enabled ? t('API_APPS_MODAL.ENABLED') : t('API_APPS_MODAL.DISABLED') }}
              </span>
            </template>
          </Switch>
        </div>
        <div class="flex flex-1 px-6 py-4 mx-4">
          <span class="font-bold">Application Scopes</span>
        </div>
        <div class="flex flex-1 px-6 mx-4 mb-4 text-gray-600">
          <span>{{ t('API_APPS_MODAL.SCOPES_SUBTITLE') }}</span>
        </div>
        <div
          v-for="scope in scopes"
          :key="scope.name"
          class="flex flex-1 px-6 mx-4 my-2 text-gray-600">
          <checkbox
            :value="true"
            :disabled="true"
            class="mb-4">
            <template #label>
              {{ scope.name }}
            </template>
          </checkbox>
        </div>
        <div class="flex flex-1 px-6 mx-4 text-gray-600"></div>
      </div>
    </template>
    <template #footer="{ validate, submit, resetForm }">
      <div class="mt-7 px-6 py-4 flex flex-row-reverse gap-6 shadow-top">
        <template v-if="props.isEdit || created">
          <b-button @click="onSave()">
            {{ t('SAVE_BUTTON') }}
          </b-button>
        </template>
        <template v-else>
          <b-button @click="onSubmitAndEdit({ validate, submit, resetForm })">
            {{ t('MODAL.NEXT') }}
          </b-button>
        </template>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ModalApiApps'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, nextTick, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { object, string } from 'yup';

import BButton from '../../atoms/Button';
import Checkbox from '../../atoms/Checkbox';
import Divider from '../../atoms/Divider';
import BInput from '../../atoms/Input';
import Switch from '../../atoms/Switch';
import Modal from '../../organisms/Modal';

const { t } = useTranslation();
const store = useStore();

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  isEdit: {
    type: Boolean,
    required: true
  },
  isShown: {
    type: Boolean,
    default: false
  }
});

const scopes = [
  {
    name: 'Orders'
  },
  {
    name: 'Fulfillments'
  },
  {
    name: 'Shipments'
  },
  {
    name: 'Fulfillment Packages'
  },
  {
    name: 'Integrations'
  },
  {
    name: 'Packages'
  },
  {
    name: 'Shipping Locations'
  },
  {
    name: 'Webhooks'
  }
];

const emit = defineEmits(['create', 'edit', 'close']);

const localValueFactory = () => {
  return {
    name: '',
    uid: ''
  };
};

const data = reactive({
  localValue: localValueFactory()
});

const schema = computed(() =>
  object({
    name: string().max(50).required().label(t('API_APPS_MODAL.NAME'))
  })
);

watch(
  () => props.value,
  (value) => {
    data.localValue = {
      ...localValueFactory(),
      ...value
    };
  }
);

let created = false;

const onSubmitAndEdit = async (form) => {
  const valid = await form.validate();
  const value = data.localValue;

  if (valid.valid) {
    await store.dispatch('apiApps/createApiApp', { ...value, scopes: ['task'] });
    data.localValue = store.getters['apiApps/created'];
    created = true;
  }
};

const onSave = async () => {
  const { enabled } = data.localValue;

  // TODO do just whaen changed from original value
  if (enabled) {
    await store.dispatch('apiApps/enableApiApp', data.localValue.id);
  } else {
    await store.dispatch('apiApps/disableApiApp', data.localValue.id);
  }

  data.localValue = localValueFactory();
  await nextTick(() => {
    emit('close');
  });
};
const onClose = async () => {
  data.localValue = localValueFactory();
  await nextTick(() => {
    emit('close');
  });
};
</script>
