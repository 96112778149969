<template>
  <div
    v-if="isMounted"
    class="relative flex overflow-hidden flex-col h-full">
    <alert
      v-if="error"
      class="mb-8 absolute inset-x-0 top-0"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>
    <alert
      v-if="notification"
      class="mb-8 absolute inset-x-0 top-0"
      type="success">
      {{ t('PROFILE_MANAGEMENT.SUCCESSES_MESSAGE') }}
    </alert>
    <teleport to="#pageTitle">
      <div class="flex items-top">
        <span
          class="mr-1 cursor-pointer"
          @click="$router.back()">
          <b-icon
            icon-name="arrow-left"
            class="w-6 h-6 text-blue-500" />
        </span>
        <span class="mr-4">
          {{ t('PROFILE_MANAGEMENT.TITLE') }}
        </span>
      </div>
    </teleport>
    <div class="px-6 pt-6 flex h-full flex-col overflow-auto">
      <div class="px-6 pt-8 flex flex-col">
        <div class="mb-6 flex item-center gap-2">
          <div class="relative flex items-center justify-center bg-blue-400 w-14 h-14 text-white text-xl rounded-full">
            {{ initials }}
          </div>
          <h1
            class="mt-1 text-2xl leading-loose font-semibold"
            @click="showToken">
            {{ user.name }}
          </h1>
        </div>
        <div class="w-2/6">
          <b-input
            name="name"
            class="mb-4">
            <template #label>
              {{ t('PROFILE_MANAGEMENT.USERNAME') }}
            </template>
          </b-input>
          <b-input
            name="email"
            class="mb-4">
            <template #label>
              {{ t('PROFILE_MANAGEMENT.EMAIL') }}
            </template>
          </b-input>
          <b-select
            name="language"
            :value="values.language"
            :options="languageOptions"
            :placeholder="t('SELECT_PLACEHOLDER')"
            class="mb-4"
            size="small">
            <template #label>
              <span class="text-gray-600 font-normal text-sm leading-7">
                {{ t('PROFILE_MANAGEMENT.SYSTEM_LANGUAGE') }}
              </span>
            </template>
          </b-select>
        </div>
      </div>
    </div>

    <save-discard-footer
      :submitting="saving"
      :is-discardable="meta.dirty"
      :is-submittable="meta.dirty && meta.valid"
      @submit="onSubmit"
      @discard="resetForm" />
  </div>
</template>

<script>
export default {
  name: 'ProfileManagement'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import ISO6391 from 'iso-639-1';
import { useForm } from 'vee-validate';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { object, string } from 'yup';

import SaveDiscardFooter from '@/components/molecules/SaveDiscardFooter';

import { useNotification } from '../../../composables';
import Alert from '../../atoms/Alert';
import BSelect from '../../atoms/BSelect';
import BIcon from '../../atoms/Icon';
import BInput from '../../atoms/Input';

const store = useStore();
const { t } = useTranslation();
const { notification, notify } = useNotification();
const DEFAULT_LANG = 'en';

const isMounted = ref(false);
const saving = ref(false);

onMounted(async () => {
  isMounted.value = true;
});

const user = computed(() => store.getters['auth/user']);
const error = computed(() => store.state.users.error || store.state.auth.error);
const schema = computed(() =>
  object({
    name: string()
      .matches(/^[a-zA-Z0-9- _./:']+$/, t('USERS_MODAL.ERROR_FOR_USER_NAME'))
      .max(50)
      .required()
      .label(t('USERS_MODAL.USERNAME')),
    email: string().required().email().label(t('EMAIL')),
    language: string().required().label(t('ADD_LOCATION.COUNTRY'))
  })
);

const languageOptions = ISO6391.getAllCodes().map((code) => {
  return {
    value: code,
    label: ISO6391.getName(code)
  };
});

const localValueFactory = () => {
  const userInfo = store.getters['auth/user'];

  return { ...userInfo, language: userInfo?.language ? userInfo.language : DEFAULT_LANG };
};

const { values, handleSubmit, resetForm, meta } = useForm({
  validationSchema: schema,
  initialValues: localValueFactory()
});
const clearError = () => {
  store.commit('users/clearError');
  store.commit('auth/clearError');
};

const onSubmit = handleSubmit(async (values, actions) => {
  saving.value = true;

  const { id, name, email, language } = values;

  await updateProfile({ id, name, email, language });

  if (!error.value) {
    actions.resetForm({ values });
  } else {
    actions.resetForm();
  }

  saving.value = false;
});

const updateProfile = async ({ name, email, language, id }) => {
  await store.dispatch('users/editUser', { name, email, language, id });
  await store.dispatch('auth/updateUser', store.getters['users/user'](id));
  notify();
};

const showToken = () => {
  // eslint-disable-next-line no-console
  console.log('-------------API TOKEN--------------');
  // eslint-disable-next-line no-console
  console.log(store.getters['auth/token']);
  // eslint-disable-next-line no-console
  console.log('------------------------------------');
};

const initials = computed(() => {
  const [firstName = '', secondName = ''] = user.value.name.split(' ');

  return `${firstName.charAt(0).toUpperCase()}${secondName.charAt(0).toUpperCase()}`;
});
</script>
