<template>
  <div
    class="message-component flex text-sm"
    :class="classes"
    @mouseover="showIcons"
    @mouseleave="hideIcons">
    <div class="bg-gray-100 rounded-full p-2 inline-flex items-center justify-center h-6 w-6 mr-2 mt-2">
      <icon
        class="text-m text-black"
        :icon-name="BringgFontIcons.Feather" />
    </div>

    <div class="flex-1 mt-2">
      <div
        v-if="!state.editMode && !newNote"
        class="relative">
        <div
          class="whitespace-pre-wrap break-words rounded-xl p-2 text-gray-700"
          :class="state.showIcons ? 'bg-gray-50' : 'bg-white'">
          {{ note }}
        </div>
        <div
          v-show="state.showIcons"
          class="absolute right-0 mt-2">
          <button
            type="button"
            @click="deleteMessage(index)">
            <icon
              class="text-base mr-5 text-gray-500"
              :icon-name="BringgFontIcons.Trash" />
          </button>
          <button
            type="button"
            @click="showEditMode">
            <icon
              class="text-base mr-2 text-gray-500"
              :icon-name="BringgFontIcons.Pencil" />
          </button>
        </div>
      </div>
      <div v-else>
        <div>
          <textarea
            ref="textarea"
            v-model="state.noteToEdit"
            class="input w-full h-content text-sm border-gray-300 rounded-xl outline-none focus:ring-0 focus:border-gray-300 resize-none text-gray-700"
            style="min-height: auto"
            :rows="numberOfLines"
            @input="updateNote"></textarea>
        </div>
        <div class="flex justify-end mt-2">
          <button
            ref="discardButton"
            type="button"
            class="text-gray-600 mr-6"
            @click="discard">
            {{ newNote ? t('CANCEL') : t('NOTES.DISCARD_BUTTON') }}
          </button>
          <button
            ref="saveButton"
            type="button"
            class="text-blue-400"
            @click="saveNote">
            {{ t('NOTES.SAVE_BUTTON') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Note'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, reactive, ref } from 'vue';

import Button from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import { createDebounce } from '@/utils';

const emit = defineEmits(['delete', 'save', 'discard', 'edit', 'draft']);

const { t } = useTranslation();

const debounce = createDebounce();

const textarea = ref(null);
const discardButton = ref(null);
const saveButton = ref(null);

const numberOfLines = computed(() => {
  if (!textarea.value) {
    return 2;
  }

  let computedStyle = window.getComputedStyle(textarea.value);

  let lineHeight = parseInt(computedStyle.lineHeight);

  const rows = Math.floor(textarea.value.scrollHeight / lineHeight);

  return rows > 1 ? rows : 2;
});

const props = defineProps({
  note: {
    type: String,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  newNote: {
    type: Boolean,
    default: false
  },
  scrollContainer: {
    type: Object,
    default: null
  }
});

const state = reactive({
  showIcons: false,
  editMode: false,
  numberOfLines: 1,
  updatedNote: null,
  noteToEdit: props.note
});

const updateNote = ({ target: { value } }) => {
  state.updatedNote = value;
  state.saved = false;

  debounce(() => emit('draft', { note: value, index: props.index }), 100);
};

const saveNote = () => {
  state.saved = true;

  hideEditMode();

  if (state.updatedNote === null) {
    if (props.newNote) {
      emit('discard', props.index);
    }

    return;
  }

  if (state.updatedNote === '') {
    emit('delete', props.index);

    return;
  }

  emit('save', { note: state.updatedNote, index: props.index });
};

const showIcons = () => {
  state.showIcons = true;
};

const discard = () => {
  hideEditMode();

  if (props.newNote) {
    emit('discard', props.index);
  }
};

const hideIcons = () => {
  state.showIcons = false;
};

const showEditMode = () => {
  emit('edit', props.index);

  state.editMode = true;
};

const hideEditMode = () => {
  state.editMode = false;
  state.showIcons = false;
};

const deleteMessage = () => {
  emit('delete', props.index);
};

const classes = computed(() => {
  if (props.index === 0) {
    return 'pr-5 pl-5 pb-4 pt-2';
  }

  return 'p-4';
});
</script>
