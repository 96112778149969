<template>
  <div class="relative h-screen flex flex-col justify-center items-center bg-gray-50">
    <img
      class="absolute left-10 top-12"
      src="../../../assets/img/bringg-logo.svg"
      alt="Bringg" />

    <h1 class="text-3xl text-gray-800 font-semibold">
      {{ t('LOGIN.WELCOME') }}
    </h1>

    <div class="form mt-10">
      <v-form
        :validation-schema="schema"
        @submit="onSubmit">
        <b-input
          id="email"
          v-model:value="form.email"
          :label="t('LOGIN.EMAIL')" />

        <b-input
          id="password"
          v-model:value="form.password"
          :label="t('LOGIN.PASSWORD')"
          password
          class="my-6" />

        <router-link
          :to="{ name: 'remind' }"
          class="text-blue-600 dark:text-blue-500 hover:underline">
          {{ t('LOGIN.RESET_PASSWORD') }}
        </router-link>

        <b-select
          v-if="options.length > 0"
          id="merchants"
          v-model:value="form.merchant_uuid"
          :options="options"
          class="mt-6" />

        <b-alert
          v-if="state.error"
          class="mt-6"
          type="danger">
          {{ state.error }}
        </b-alert>

        <vue-recaptcha
          v-if="state.captchaRequired"
          class="mt-6"
          :sitekey="siteKey"
          @verify="onVerify" />

        <b-button
          class="w-full mt-10"
          :loading="state.loading"
          data-id="login"
          submit>
          {{ t('LOGIN.LOGIN') }}
        </b-button>
      </v-form>
    </div>
    <div class="flex gap-2 pt-8 text-sm text-gray-600">
      <span>
        {{ t('LOGIN.DONT_HAVE_AN_ACCOUNT') }}
      </span>
      <router-link
        v-if="isShopify()"
        class="text-blue-600 dark:text-blue-500 hover:underline"
        :to="{ name: 'signup', query: route.query }">
        {{ t('SIGNUP.SIGNUP') }}
      </router-link>
      <a
        v-else
        target="_blank"
        class="text-blue-600 dark:text-blue-500 hover:underline"
        href="mailto: support@bringg.com">
        {{ t('LOGIN.CONTACT_US') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { Form as VForm } from 'vee-validate';
import { computed, reactive } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { string } from 'yup';

import { isShopify } from '@/services/shopify';

import config from '../../../config';
import BAlert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import BInput from '../../atoms/Input';
import BSelect from '../../atoms/Select';

const { t } = useTranslation();
const store = useStore();
const route = useRoute();

const siteKey = config.get('CAPTCHA');

const form = reactive({
  email: '',
  password: '',
  recaptcha: null,
  merchant_uuid: null
});

const state = reactive({
  captchaRequired: false,
  error: null,
  loading: false,
  merchantsData: []
});

const options = computed(() =>
  state.merchantsData.map((merchant) => ({
    label: merchant.name,
    value: merchant.uuid
  }))
);

const schema = computed(() => ({
  email: string().email().required().label(t('LOGIN.EMAIL')),
  password: string().required().label(t('LOGIN.PASSWORD'))
}));

const onSubmit = async () => {
  state.error = null;
  state.loading = true;

  try {
    const merchant = state.merchantsData.find((merchant) => merchant.uuid === form.merchant_uuid);
    const response = await store.dispatch('auth/login', { ...form, merchant, query: route.query });

    if (response.data.merchants_data) {
      state.merchantsData = response.data.merchants_data;
      form.merchant_uuid = response.data.merchants_data[0].uuid;
    }

    if (response.data.recaptcha_stoken) {
      state.captchaRequired = true;
    }

    if (!response.data.success) {
      state.error = response.data.message;
    } else {
      state.error = null;
    }
  } catch (error) {
    state.error = error?.response?.data?.message || error?.response?.data?.title || error?.message;
  } finally {
    state.loading = false;
  }
};

const onVerify = (value) => {
  form.recaptcha = value;
};
</script>

<style lang="scss" scoped>
.form {
  width: 400px;
}
</style>
