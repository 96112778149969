<template>
  <modal
    :data-id="dataId"
    :value="isShown"
    @close="close">
    <template #title>
      <div class="flex items-center gap-2.5 p-6 pb-2 text-xl font-semibold">
        <slot name="title" />
      </div>
    </template>
    <template #body>
      <div class="px-6 text-sm">
        <slot name="description" />
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end gap-4 items-center p-10 pb-6">
        <b-button
          v-if="useIgnoreButton"
          class="h-8 px-4 py-1 bg-blue-50 text-blue-400 border-none text-base font-semibold"
          @click="reject">
          <slot name="reject-label" />
        </b-button>
        <b-button
          v-if="useButtons"
          class="h-8 px-4 py-1 bg-blue-400 text-white border-none text-base font-semibold"
          @click="accept">
          <slot name="accept-label" />
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ConfirmModal'
};
</script>

<script setup>
import { computed, defineEmits, defineProps } from 'vue';

import BButton from '../../atoms/Button';
import Modal from '../../organisms/Modal';

const props = defineProps({
  isShown: {
    type: Boolean,
    default: false
  },
  useButtons: {
    type: Boolean,
    default: true
  },
  useIgnoreButton: {
    type: Boolean,
    default: true
  },
  dataId: {
    type: String,
    default: ''
  }
});

const useIgnoreButton = computed(() => (props.useButtons ? props.useIgnoreButton : false));

const emit = defineEmits(['accept', 'reject', 'close']);
const accept = () => emit('accept');
const reject = () => emit('reject');
const close = () => emit('close');
</script>
