import { documentLink } from '@/components/pages/ShipmentDetails/constants';
import { ShipmentTrackingStages } from '@/constants';

export const checkpoints = [
  {
    description: 'Shipment delivered to the recipient',
    location: {
      address: 'Destination warehouse',
      city: '',
      country: 'US',
      postal_code: '06906',
      state: ''
    },
    status: 'D',
    tracking_stage: ShipmentTrackingStages.DELIVERED,
    pod: [
      {
        document: documentLink,
        type: 'photo',
        document_type: 'JPEG',
        description: 'Mr. Snacknabber'
      }
    ]
  },
  {
    description: 'Courier arrived to the destination',
    location: {
      address: 'Destination warehouse',
      city: '',
      country: 'US',
      postal_code: '06906',
      state: ''
    },
    status: 'TA',
    tracking_stage: ShipmentTrackingStages.OUT_OF_DELIVERY
  },
  {
    description: 'Courier having a delay',
    location: {
      address: 'Street',
      city: '',
      country: 'US',
      postal_code: '62223',
      state: ''
    },
    status: 'TS',
    tracking_stage: ShipmentTrackingStages.IN_TRANSIT
  },
  {
    description: 'Courier is on the way',
    location: {
      address: 'Street',
      city: '',
      country: 'US',
      postal_code: '62223',
      state: ''
    },
    status: 'TS',
    tracking_stage: ShipmentTrackingStages.IN_TRANSIT
  },
  {
    description: 'Courier started delivery',
    location: {
      address: 'Warehouse',
      city: '',
      country: 'US',
      postal_code: '62223',
      state: ''
    },
    status: 'EC',
    tracking_stage: ShipmentTrackingStages.IN_TRANSIT
  },
  {
    description: 'Shipment was picked up by the carrier',
    location: {
      address: 'Warehouse',
      city: '',
      country: 'US',
      postal_code: '62223',
      state: ''
    },
    status: 'DC',
    tracking_stage: ShipmentTrackingStages.PICKED_UP
  },
  {
    description: 'Shipment was created by the carrier',
    location: {
      address: 'Warehouse',
      city: '',
      country: 'US',
      postal_code: '62223',
      state: ''
    },
    status: 'PC',
    tracking_stage: ShipmentTrackingStages.PICKED_UP
  }
];

export const deliveryStatusTrackingStageFlowMap = {
  ReadyToShip: {
    nextTrackingStage: ShipmentTrackingStages.PICKED_UP,
    nextStatus: 'DC',
    checkpoints: checkpoints.filter(({ tracking_stage }) => tracking_stage === ShipmentTrackingStages.PICKED_UP)
  },
  PickedUp: {
    nextTrackingStage: ShipmentTrackingStages.IN_TRANSIT,
    nextStatus: 'TS',
    checkpoints: checkpoints.filter(({ tracking_stage }) => tracking_stage === ShipmentTrackingStages.IN_TRANSIT)
  },
  InTransit: {
    nextTrackingStage: ShipmentTrackingStages.OUT_OF_DELIVERY,
    nextStatus: 'TA',
    checkpoints: checkpoints.filter(({ tracking_stage }) => tracking_stage === ShipmentTrackingStages.OUT_OF_DELIVERY)
  },
  OutForDelivery: {
    nextTrackingStage: ShipmentTrackingStages.DELIVERED,
    nextStatus: 'D',
    checkpoints: checkpoints.filter(({ tracking_stage }) => tracking_stage === ShipmentTrackingStages.DELIVERED)
  }
};

export const getCancelCheckpoints = () => [
  {
    description:
      'The package caught fire, and then the bird grabbed it and tried to carry it away, and it also caught fire',
    location: {
      address: 'Destination warehouse',
      city: '',
      country: 'US',
      postal_code: '06906',
      state: ''
    },
    status: 'D',
    time: new Date().toISOString(),
    tracking_stage: 'SHIPMENT_VOIDED'
  }
];
