<template>
  <div
    v-if="!isMounted"
    class="h-full flex justify-center items-center">
    <spinner />
  </div>
  <div
    v-else
    class="h-full overflow-auto">
    <teleport
      v-if="orderDetails.name"
      to="#pageTitle">
      <div class="flex items-top">
        <span
          class="mr-1 cursor-pointer"
          @click="$router.back()">
          <icon
            icon-name="arrow-left"
            class="w-6 h-6 text-blue-500" />
        </span>
        <span class="mr-4">{{ orderDetails.name }} #{{ orderDetails.source_id ?? orderDetails.id }}</span>
        <badge
          class="h-5 mt-0.5"
          :type="statusBadgeTypeMapping[orderDetails.status]">
          {{ t(orderDetails.status) }}
        </badge>
      </div>
    </teleport>

    <details-info-line
      class="px-10 pt-5 pb-3 bg-white text-gray-600 text-sm"
      :value="info" />

    <alert
      v-if="error"
      class="mx-6 my-5"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>

    <div class="px-10 py-6 flex flex-row">
      <div class="mr-12 w-2/5">
        <div class="mb-3 text-base font-semibold">
          {{ fulfillments.length }} {{ t('ORDER_DETAILS.FULFILLMENT_ITEM') }}
        </div>

        <fulfillment-card
          v-for="row in fulfillments"
          :key="row.id"
          :row="row"
          :order-id="props.id">
          <div
            v-if="getShipmentsByFulfillmentId(row.id)"
            class="border-t border-gray-500">
            <shipment-card
              v-for="(shipment, index) in getShipmentsByFulfillmentId(row.id)"
              :key="shipment.id"
              :class="{ 'border-b border-gray-300': index !== getShipmentsByFulfillmentId(row.id).length - 1 }"
              :value="
                formatShipment({
                  ...shipment,
                  carrier: getCarrierNameByShipment(shipment, store),
                  service: getServiceTypeNameBySlugAndCarrier(shipment.service, shipment.carrier),
                  items: getProductsByShipmentId(shipment.id)
                })
              " />
          </div>
          <template #description>
            {{ formatFulfillmentDescription(row) }}
          </template>
        </fulfillment-card>
      </div>
      <div class="w-4/6 flex justify-between gap-5">
        <div>
          <b-table
            class="products"
            :loading="loading"
            :fields="itemFields"
            :data="products"
            :sticky="false"
            row-key="id">
            <template #total>
              <div class="text-base text-black font-semibold">
                {{ products.length }} {{ t('ORDER_DETAILS.ITEMS_IN_ORDER') }}
              </div>
            </template>
            <template #cell(name)="{ row }">
              <div class="flex">
                {{ row.name }}
              </div>
            </template>
            <template #cell(total_weight)="{ row }">
              {{ formatWeight(row, 'kg') }}
            </template>
            <template #cell(dimensions)="{ row }">
              {{ formatItemDimensions(row) }}
            </template>
          </b-table>
        </div>
        <modal-shipping-documents
          :documents="documents"
          @print="printDocument"
          @print-all="printAllDocuments">
          <template #label>
            <span
              data-id="documents-button"
              class="text-base text-blue-400 font-semibold cursor-pointer">
              <icon
                icon-name="link"
                class="text-xl" />
              {{ t('ORDER_DETAILS.DOCUMENTS_LABEL') }}
            </span>
          </template>
        </modal-shipping-documents>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetails'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineProps, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { useShippingDocumentsModal } from '../../../composables';
import { statusBadgeTypeMapping } from '../../../enums';
import {
  formatAddress,
  formatDeliverByColumn,
  formatItemDimensions,
  formatShipment,
  formatWeight,
  getCarrierNameByShipment
} from '../../../utils';
import Alert from '../../atoms/Alert';
import Badge from '../../atoms/Badge';
import Icon from '../../atoms/Icon';
import Spinner from '../../atoms/Spinner';
import DetailsInfoLine from '../../molecules/DetailsInfoLine';
import FulfillmentCard from '../../molecules/FulfillmentCard';
import ShipmentCard from '../../molecules/ShipmentCard';
import ModalShippingDocuments from '../../organisms/ModalShippingDocuments';
import BTable from '../../organisms/Table';

const itemFields = [
  { key: 'name', label: 'ORDER_DETAILS.ITEM_NAME_FIELD' },
  { key: 'count', label: 'ORDER_DETAILS.ITEM_TOTAL_QUANTITY_FIELD' },
  { key: 'total_weight', label: 'TOTAL_WEIGHT' },
  { key: 'dimensions', label: 'ORDER_DETAILS.ITEM_DIMENSIONS_FIELD' }
];

const store = useStore();
const { t } = useTranslation();

const isMounted = ref(false);

const props = defineProps({
  id: {
    type: String,
    required: true
  }
});

onMounted(async () => {
  await Promise.all([loadOrderDetails(), loadOrderProducts(), loadFulfillments(), loadShippingAccounts()]);
  await loadFulfillmentDependencies();

  isMounted.value = true;
});

const orderDetails = computed(() => store.state.orders.itemDetails);
const products = computed(() => store.state.products.items);
const fulfillments = computed(() => store.state.fulfillments.items);
const shipments = computed(() =>
  fulfillments.value.reduce((result, item) => {
    result.push(...getShipmentsByFulfillmentId(item.id));

    return result;
  }, [])
);
const loading = computed(
  () =>
    store.state.orders.loading ||
    store.state.products.loading ||
    store.state.fulfillments.loading ||
    store.state.shipments.loading
);
const error = computed(
  () =>
    store.state.orders.error ||
    store.state.products.error ||
    store.state.fulfillments.error ||
    store.state.shipments.error
);

const billingAddress = computed(() => formatAddress(orderDetails.value.billing_address));

const info = computed(() => [
  { label: 'ORDER_DETAILS.FULFILLMENTS_COUNT_FIELD', value: fulfillments.value.length },
  { label: 'ORDER_DETAILS.SHIPMENTS_COUNT_FIELD', value: shipments.value.length },
  { label: 'ORDER_DETAILS.PACKAGES_COUNT_FIELD', value: getPackages().length },
  { label: 'ORDER_DETAILS.ITEMS_COUNT_FIELD', value: orderDetails.items_count },
  { label: 'ORDER_DETAILS.PROMISED_TIME_FIELD', value: formatDeliverByColumn(orderDetails.value) },
  { label: 'ORDER_DETAILS.ADDRESS_FIELD', value: billingAddress.value },
  { label: 'ORDER_DETAILS.SHIPPING_METHOD_FIELD', value: '' }
]);

const { documents, printDocument, printAllDocuments } = useShippingDocumentsModal(shipments, store);

const loadOrderDetails = async () => await store.dispatch('orders/loadOrdersById', props.id);
const loadFulfillments = async () => await store.dispatch('fulfillments/loadOrderFulfillments', props.id);
const loadFulfillmentDependencies = async () =>
  await Promise.all(
    fulfillments.value.map(async ({ id: fulfillmentId }) => {
      const orderId = props.id;

      await store.dispatch('shipments/loadFulfillmentShipments', { orderId, fulfillmentId });
      await store.dispatch('products/loadShipmentProductsByFulfillmentId', { orderId, fulfillmentId });

      const shipments = getShipmentsByFulfillmentId(fulfillmentId);

      if (!shipments.length) {
        await store.dispatch('products/loadFulfillmentProducts', { orderId, fulfillmentId });
      }
    })
  );

const loadShippingAccounts = async () => await store.dispatch('shippingAccounts/loadShippingAccounts');

const loadOrderProducts = async () => await store.dispatch('products/loadOrderProducts', props.id);
const clearError = () => {
  store.commit('orders/clearError');
  store.commit('fulfillments/clearError');
  store.commit('shipments/clearError');
  store.commit('products/clearError');
};

const getShipmentsByFulfillmentId = (id) => store.getters['shipments/getItemsByFulfillmentId'](id);
const getPackagesByFulfillmentId = (id) => store.getters['packages/getItemsByAllFulfillmentShipmentsId'](id);
const getPackages = () =>
  fulfillments.value.reduce((result, { id: fulfillmentId }) => {
    result.push(...getPackagesByFulfillmentId(fulfillmentId));

    return result;
  }, []);
const getProductsByShipmentId = (id) => store.getters['products/getItemsByShipmentId'](id);
const getProductsByFulfillmentId = (id) => {
  const shipments = getShipmentsByFulfillmentId(id);

  if (shipments.length) {
    return shipments.reduce((result, item) => {
      result.push(...getProductsByShipmentId(item.id));

      return result;
    }, []);
  }

  return store.getters['products/getItemsByFulfillmentId'](id);
};
const getServiceTypeNameBySlugAndCarrier = (service, carrier) =>
  store.getters['carriers/getServiceTypeNameBySlugAndCarrier'](service, carrier);

const formatFulfillmentDescription = (row) => {
  const shipments = getShipmentsByFulfillmentId(row.id);
  const packages = getPackagesByFulfillmentId(row.id);
  const products = getProductsByFulfillmentId(row.id);

  if (!shipments.length) {
    const result = [`${row.items_count} items`];
    const itemsDetails = products.map(({ count, product: { name } }) => `${count} ${name}`).join(', ');

    if (itemsDetails) {
      result.push(itemsDetails);
    }

    return result.join(': ');
  }

  const result = [
    `${shipments.length} ${t('ORDER_DETAILS.SHIPMENTS')}`,
    `${packages.length} ${t('ORDER_DETAILS.PACKAGES')}`,
    `${products.length} ${t('ORDER_DETAILS.ITEMS')}`
  ];

  return result.join(', ');
};
</script>

<style scoped>
.shadow {
  box-shadow:
    0 0 1px rgba(0, 51, 80, 0.24),
    0 1px 2px rgba(0, 51, 88, 0.16);
}
</style>
