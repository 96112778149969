<template>
  <div class="reset-them-all">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'StylesReset'
};
</script>

<style lang="scss">
.reset-them-all {
  @import 'node_modules/unreset-css/unreset.scss';
}
</style>
