<template>
  <div>
    <breadcrumbs
      :value="breadcrumbsData"
      class="mb-6" />

    <slot name="message" />

    <b-table
      :loading="loading"
      :fields="returnFields"
      :data="value.return_lines"
      class="mb-10">
      <template #cell(reason)="{ row }">
        <template
          v-for="reason in row.reasons"
          :key="reason">
          <span>{{ reason.value }}: {{ reason.reasons.value }}</span>
          <br />
        </template>
      </template>
      <template #cell(country_of_manufacture)="{ row }">
        {{ row.country_of_manufacture && countryMapping[row.country_of_manufacture] }}
      </template>
    </b-table>

    <div
      v-if="trackData"
      class="bg-white shadow overflow-hidden sm:rounded-lg mb-10">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ t('RETURNS_DETAILS.TRACKING_INFO') }}
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 pt-5 pb-64 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div
            v-for="item in trackData"
            :key="item"
            class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ item.label }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              <template v-if="loading">
                <animated-placeholder
                  :count="2"
                  class="w-2/5" />
              </template>
              <template v-else-if="item.key === 'created'">
                <div :title="props.value.created">
                  {{ item.value }}
                </div>
              </template>
              <template v-else-if="item.key === 'status'">
                <z-select
                  id="status"
                  :value="item.value"
                  :options="statusOptions"
                  class="w-44 leading-10"
                  content-width
                  @update:value="changeStatus" />
              </template>
              <template v-else>
                {{ item.value }}
              </template>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div
      v-if="JSON.stringify(props.value.custom) !== '[]'"
      class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ t('RETURNS_DETAILS.CUSTOM_PARAMS') }}
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 pt-5 pb-24 sm:px-6">
        <json-formatter :value="props.value.custom" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnDetailsTemplate'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps } from 'vue';

import countryMapping from '../../../assets/js/countries.json';
import AnimatedPlaceholder from '../../atoms/AnimatedPlaceholder/index.vue';
import JsonFormatter from '../../atoms/JsonFormatter';
import ZSelect from '../../atoms/Select';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import BTable from '../../organisms/Table';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:status']);

const returnFields = computed(() => [
  { key: 'sku', label: t('RETURNS_DETAILS.SKU_FIELD') },
  { key: 'product_name', label: t('RETURNS_DETAILS.PRODUCT_NAME_FIELD') },
  { key: 'reason', label: t('RETURNS_DETAILS.REASON_FIELD') },
  { key: 'quantity', label: t('RETURNS_DETAILS.QUANTITY_FIELD') },
  { key: 'value', label: t('RETURNS_DETAILS.VALUE_FIELD') },
  { key: 'weight', label: t('RETURNS_DETAILS.WEIGHT_FIELD') },
  { key: 'hs_code', label: t('RETURNS_DETAILS.HS_CODE_FIELD') },
  { key: 'country_of_manufacture', label: t('RETURNS_DETAILS.COUNTRY_FIELD') }
]);

const statusOptions = computed(() => [
  { value: 'CREATED', label: t('CREATED_STATUS') },
  { value: 'PICKED_UP', label: t('PICKED_UP_STATUS') },
  { value: 'IN_TRANSIT', label: t('IN_TRANSIT_STATUS') },
  { value: 'OUT_FOR_DELIVERY', label: t('OUT_FOR_DELIVERY_STATUS') },
  { value: 'DELIVERED', label: t('DELIVERED_STATUS') },
  { value: 'EXCEPTION', label: t('EXCEPTION_STATUS') },
  { value: 'SHIPMENT_VOIDED', label: t('SHIPMENT_VOIDED_STATUS') }
]);

const trackData = computed(() => {
  let result = [];

  if (props.value.shipment) {
    result.push(
      ...[
        { key: 'status', label: t('RETURNS_DETAILS.STATUS_FIELD'), value: props.value.shipment.status },
        {
          key: 'carrier',
          label: t('RETURNS_DETAILS.CARRIER_FIELD'),
          value: props.value.return_method.carrier_name
        },
        {
          key: 'reference_number',
          label: t('RETURNS_DETAILS.REFERENCE_NUMBER_FIELD'),
          value: props.value.shipment.reference_number
        },
        {
          key: 'tracking_number',
          label: t('RETURNS_DETAILS.TRACKING_NUMBER_FIELD'),
          value: props.value.shipment.tracking_number
        }
      ]
    );
  }
  if (props.value.pickup) {
    result.push({
      key: 'confirmation_number',
      label: t('RETURNS_DETAILS.CONFIRMATION_NUMBER_FIELD'),
      value: props.value.pickup.confirmation_number
    });
  }

  return result ? result.filter((item) => item && item.value) : result;
});

const breadcrumbsData = computed(() => [
  {
    label: t('RETURNS_DETAILS.HOME'),
    to: { name: 'home' }
  },
  {
    label: t('RETURNS_DETAILS.RETURNS'),
    to: { name: 'returns' }
  },
  {
    label: props.value.id,
    to: ''
  }
]);

const changeStatus = (value) => emit('update:status', value);
</script>
