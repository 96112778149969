<template>
  <div
    v-if="open"
    ref="restRef"
    class="rest"
    :style="{ height: `${restHeight}px` }"
    @click="emit('close')"></div>
  <transition name="slide">
    <div
      v-if="open"
      class="animated-div bottom-0"
      :style="{ top: `${top}px` }">
      <div class="relative h-full">
        <slot name="content" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Drawer'
};
</script>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue';

const emit = defineEmits(['close']);

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  inheritAttrs: {
    type: Boolean,
    default: false
  },
  top: {
    type: Number,
    default: null
  }
});

const restRef = ref(null);

const restHeight = computed(() => {
  return restRef.value?.parentNode.scrollHeight;
});
</script>

<style>
.animated-div {
  position: fixed;
  right: 0;
  width: 464px;
  background-color: white;
  transition: width 0.3s ease;
  box-shadow:
    0px 16px 24px 0px rgba(0, 51, 80, 0.16),
    0px 2px 8px 0px rgba(0, 51, 80, 0.04);
  z-index: 10;
}
.rest {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 464px);
  height: 100vh;
}
.slide-enter-to,
.slide-leave-from {
  opacity: 1;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  width: 0;
}
</style>
