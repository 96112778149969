<template>
  <div
    :data-id="dataId"
    class="modal-unsaved-changes">
    <b-modal
      :value="state.isOpen"
      @close="onClose">
      <template #title>
        <h3 class="px-6 py-4 text-xl leading-6 font-medium text-gray-800">
          {{ t('UNSAVED_CHANGES_MODAL.TITLE') }}
        </h3>
      </template>

      <template #body>
        <div class="px-6 -mt-2">
          <p class="text-sm text-gray-700">
            {{ t('UNSAVED_CHANGES_MODAL.TEXT') }}
          </p>
        </div>
      </template>

      <template #footer>
        <div class="flex mt-3 flex-row-reverse px-6 py-6">
          <b-button
            class="px-6"
            :loading="isSaving"
            :disabled="!isValid"
            @click="onSave">
            {{ t('UNSAVED_CHANGES_MODAL.SAVE_CHANGES') }}
          </b-button>

          <b-button
            class="px-6 mr-4"
            type="secondary"
            :disabled="isSaving"
            @click="onDiscard">
            {{ t('DISCARD_BUTTON') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalUnsavedChanges'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { defineProps, reactive, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

import BButton from '../../atoms/Button/index.vue';
import BModal from '../../organisms/Modal/index.vue';

const { t } = useTranslation();

const props = defineProps({
  isDirty: {
    type: Boolean,
    default: true
  },
  isValid: {
    type: Boolean,
    required: true
  },
  isSaving: {
    type: Boolean,
    required: true
  },
  open: {
    type: Boolean,
    default: false
  },
  dataId: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['discard', 'save', 'close']);

const state = reactive({
  isOpen: false
});

let onSave;
let onDiscard;
let onDone;

const onClose = () => {
  if (props.isSaving) {
    return;
  }

  state.isOpen = false;
  emit('close');
};

const openModal = () => {
  if (props.isDirty) {
    state.isOpen = true;

    onSave = () => {
      emit('save');
    };

    onDiscard = () => {
      emit('discard');
    };
  }
};

onBeforeRouteLeave((to, from, next) => {
  if (props.isDirty) {
    state.isOpen = true;

    onSave = () => {
      onDone = next;
      emit('save');
    };

    onDiscard = () => {
      emit('discard');
      next();
    };
  } else {
    next();
  }
});

watch(
  () => props.isSaving,
  (isSaving) => {
    if (!isSaving && onDone) {
      onDone();
    }
  }
);

watch(
  () => props.open,
  (open) => {
    if (open) {
      openModal();
    } else {
      state.isOpen = false;
    }
  }
);
</script>

<style>
.modal-unsaved-changes {
  .modal-content {
    max-width: 640px;
  }
}
</style>
