<template>
  <div>
    <b-table
      :loading="loading"
      :fields="returnFields"
      :data="returns"
      :table-class="!!returns ? '' : 'mb-28'"
      class="mb-10">
      <template #title>
        {{ t('RETURNS.TITLE') }}
      </template>
      <template #filter-bar>
        <div class="flex items-end gap-2">
          <date-picker
            id="dateFrom"
            :value="filters.start_date"
            :placeholder="t('RETURNS.DATE_FROM_FILTER')"
            class="flex-1"
            @update:value="updateFilters({ start_date: $event })">
            <template #start-icon>
              <CalendarIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </date-picker>
          <date-picker
            id="dateTo"
            :value="filters.end_date"
            class="flex-1"
            :placeholder="t('RETURNS.DATE_TO_FILTER')"
            @update:value="updateFilters({ end_date: $event })">
            <template #start-icon>
              <CalendarIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </date-picker>
          <z-select
            id="returnMethod"
            :value="filters.return_method"
            :options="returnMethodOptions"
            class="flex-1"
            :placeholder="t('RETURNS.RETURN_METHOD_FILTER')"
            nullable
            @update:value="updateFilters({ return_method: $event || '' })" />
          <z-input
            id="search"
            :value="filters.search"
            :timeout="1500"
            class="flex-1"
            :placeholder="t('RETURNS.SEARCH_FILTER')"
            @update:value="updateFilters({ search: $event })">
            <template #start-icon>
              <FilterIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </z-input>
          <z-input
            id="customerId"
            :value="filters.customer_id"
            :timeout="1500"
            class="flex-1"
            :placeholder="t('RETURNS.CUSTOMER_ID_FILTER')"
            @update:value="updateFilters({ customer_id: $event })">
            <template #start-icon>
              <FilterIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </z-input>
          <z-input
            id="orderId"
            :value="filters.order_id"
            :timeout="1500"
            class="flex-1"
            :placeholder="t('RETURNS.ORDER_ID_FILTER')"
            @update:value="updateFilters({ order_id: $event })">
            <template #start-icon>
              <FilterIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </z-input>
          <z-select
            id="status"
            :value="filters.status"
            :options="statusOptions"
            class="w-44 leading-10"
            :placeholder="t('RETURNS.STATUS_FILTER')"
            content-width
            nullable
            @update:value="updateFilters({ status: $event })" />
          <custom-pair-filter
            :value="filters.custom"
            :timeout="1000"
            @update:value="updateFilters({ custom: $event })" />
        </div>
      </template>
      <template #cell(reference_number)="{ row }">
        <router-link
          :to="{ name: 'return-details', params: { id: row.id } }"
          class="text-indigo-400 hover:text-indigo-600">
          {{ row.shipment ? row.shipment.reference_number : row.pickup.confirmation_number }}
        </router-link>
      </template>
      <template #cell(return_method)="{ row }">
        {{ row.return_method.method_name }}
      </template>
      <template #cell(created)="{ row }">
        {{ row.created ? format(Date.parse(row.created), 'PPPppp') : '' }}
      </template>
      <template #footer>
        <button-pagination
          :page="returnPage"
          :last-page="returnLastPage"
          @prev="prev"
          @next="next" />
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ReturnsTemplate'
};
</script>

<script setup>
import { CalendarIcon, FilterIcon } from '@heroicons/vue/solid';
import format from 'date-fns/format';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps } from 'vue';

import DatePicker from '../../atoms/DatePicker';
import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';
import ButtonPagination from '../../molecules/ButtonPagination';
import CustomPairFilter from '../../molecules/CustomPairFilter';
import BTable from '../../organisms/Table';

const { t } = useTranslation();

const props = defineProps({
  returns: {
    type: Array,
    required: true
  },
  returnMethodOptions: {
    type: Array,
    required: true
  },
  filters: {
    type: Object,
    required: true
  },
  returnPage: {
    type: Number,
    required: true
  },
  returnLastPage: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:filters', 'prev', 'next']);

const returnFields = computed(() => [
  { key: 'reference_number', label: t('RETURNS.REFERENCE_NUMBER_FIELD') },
  { key: 'return_method', label: t('RETURNS.RETURN_METHOD_FIELD') },
  { key: 'shipment.tracking_number', label: t('RETURNS.TRACKING_NUMBER_FIELD') },
  { key: 'created', label: t('FIELDS.CREATED_FIELD') }
]);

const statusOptions = computed(() => [
  { value: 'CREATED', label: t('CREATED_STATUS') },
  { value: 'PICKED_UP', label: t('PICKED_UP_STATUS') },
  { value: 'IN_TRANSIT', label: t('IN_TRANSIT_STATUS') },
  { value: 'OUT_FOR_DELIVERY', label: t('OUT_FOR_DELIVERY_STATUS') },
  { value: 'DELIVERED', label: t('DELIVERED_STATUS') },
  { value: 'EXCEPTION', label: t('EXCEPTION_STATUS') }
]);

const updateFilters = (value) => {
  emit('update:filters', {
    ...props.filters,
    ...value
  });
};

const prev = (value) => emit('prev', value);
const next = (value) => emit('next', value);
</script>
