<template>
  <div class="flex justify-end bg-white pb-3.5 pt-3">
    <div class="mr-7 w-96 ml-5">
      <search-input
        :search-query="filterData.searchQuery"
        @update-search-query="search"></search-input>
    </div>
  </div>
  <div class="pl-10 pr-6 pt-6 flex h-full flex-col overflow-hidden">
    <alert
      v-if="error"
      class="mb-8"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>

    <b-table
      class="orders"
      :loading="loading || pageLoading"
      :fields="fields"
      :data="orders"
      pagination
      :page="pageSettings.page"
      :limit="pageSettings.limit"
      row-key="id"
      :total="total"
      :last-page="pageSettings.page * pageSettings.limit >= total"
      customize-columns
      with-save-customization
      entity-name="orders"
      last-update
      @update:page-settings="updatePageSettings">
      <template #cell(name)="{ row }">
        <div
          v-if="row?.id"
          @click.capture="navigateTo({ name: 'order-details', params: { id: row.id } }, pageSettings)">
          <router-link
            :to="{
              name: 'order-details',
              params: {
                id: row.id
              }
            }"
            class="text-blue-400 hover:text-blue-600 cursor-pointer">
            {{ row.name }}
          </router-link>
        </div>
      </template>
      <template #cell(source_id)="{ row }">
        <template v-if="row?.source_id?.length > 24">
          <tooltip
            width="w-72"
            placement="bottom"
            :hover="false">
            <template #content>
              <div class="max-w-40 truncate hover:text-clip">{{ row.source_id }}</div>
            </template>
            <template #title>
              {{ row.source_id }}
            </template>
          </tooltip>
        </template>
        <template v-else>
          <div class="max-w-xs whitespace-normal break-words">{{ row.source_id }}</div>
        </template>
      </template>
      <template #cell(created_at)="{ row }">
        {{ formatCreatedAtColumn(row) }}
      </template>
      <template #cell(deliver_by)="{ row }">
        {{ formatDeliverByColumn(row) }}
      </template>
      <template #cell(total_weight)="{ row }">
        {{ formatTotalWeight(row.total_weight) }}
      </template>
      <template #cell(carrier)="{ row, rowIndex }">
        <template v-if="formatCarrierColumn(row).length > 24">
          <tooltip
            :placement="placementCarrierTooltip(rowIndex)"
            :hover="false">
            <template #content>
              <div class="max-w-40 truncate">{{ formatCarrierColumn(row) }}</div>
            </template>
            <template #title>
              <div class="max-w-xs whitespace-normal break-words">{{ formatCarrierColumn(row) }}</div>
            </template>
          </tooltip>
        </template>
        <template v-else>
          <div>{{ formatCarrierColumn(row) }}</div>
        </template>
      </template>
      <template #cell(status)="{ row }">
        <div class="w-max">
          <badge :type="statusBadgeTypeMapping[row.status]">
            {{ t(row.status) }}
          </badge>
        </div>
      </template>
      <template #cell(actions)="{ row }">
        <dropdown
          :options="actionOptions"
          button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
          :left-align="false"
          @select="handleAction($event, row)">
          <template #button>
            <icon
              class="mr-3 text-xl"
              icon-name="menu-horizontal" />
          </template>
        </dropdown>
      </template>
    </b-table>
  </div>
  <confirm-modal
    data-id="print-warning"
    :is-shown="printData.showWarning"
    :use-ignore-button="false"
    @close="closeModal"
    @accept="goToPrintSettings">
    <template #title>
      {{ t('PRINT_CONFIRM_MODAL.TITLE') }}
    </template>
    <template #description>
      {{ printData.warningText }}
    </template>
    <template #accept-label>
      {{ t('PRINT_CONFIRM_MODAL.PRINT_BUTTON') }}
    </template>
  </confirm-modal>
</template>

<script>
export default {
  name: 'Orders'
};
</script>

<script setup>
import format from 'date-fns/format';
import { useTranslation } from 'i18next-vue';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import Alert from '@/components/atoms/Alert';
import Badge from '@/components/atoms/Badge';
import Icon from '@/components/atoms/Icon';
import Tooltip from '@/components/atoms/ToolTip/index.vue';
import Dropdown from '@/components/molecules/Dropdown';
import SearchInput from '@/components/molecules/Search';
import ConfirmModal from '@/components/organisms/ConfirmModal';
import BTable from '@/components/organisms/Table';
import { useFiltersAndSearch, useNavigateTo, usePagination, usePrintingModal } from '@/composables';
import { statusBadgeTypeMapping } from '@/enums';
import { formatDeliverByColumn, formatTotalWeight } from '@/utils';

const props = defineProps({
  page: {
    type: Number,
    required: true
  },
  limit: {
    type: [Number, null],
    required: true
  },
  timeout: {
    type: Number,
    default: 100
  },
  search: {
    type: String,
    default: ''
  }
});

const store = useStore();
const { t } = useTranslation();
const { pageSettings, updatePage } = usePagination('orders/loadOrders');
const { search, setInitialValues, filterData } = useFiltersAndSearch({
  props,
  updatePage,
  entity: 'orders',
  useFilters: false,
  pageSettings
});

const { printData, goToPrintSettings, checkIfPrinterReady, print, handleWarningModal, closeModal } = usePrintingModal();

const updatePageSettings = (pageSettings) =>
  updatePage({
    ...pageSettings,
    search: filterData.searchQuery
  });
const navigateTo = useNavigateTo();

const pageLoading = ref(true);

onMounted(async () => {
  const { page, limit, search } = props;

  store.dispatch('shippingAccounts/loadShippingAccounts');

  await store.dispatch('users/loadUserSettings');

  await setInitialValues({ search, page, limit });

  pageLoading.value = false;
});

const orders = computed(() => store.state.orders.items);
const total = computed(() => store.state.orders.total);
const loading = computed(() => store.state.orders.loading);
const error = computed(() => store.state.orders.error);

const fields = computed(() => [
  { key: 'name', label: 'ORDERS.NAME_FIELD' },
  { key: 'id', label: 'ORDERS.ID_FIELD' },
  { key: 'source_id', label: 'FIELDS.SOURCE_ID_FIELD' },
  { key: 'created_at', label: 'FIELDS.CREATED_FIELD' },
  { key: 'deliver_by', label: 'ORDERS.DELIVER_BY_FIELD' },
  { key: 'total_weight', label: 'TOTAL_WEIGHT' },
  { key: 'carrier', label: 'ORDERS.CARRIER_FIELD' },
  { key: 'status', label: 'ORDERS.STATUS_FIELD' },
  { key: 'actions', label: 'ACTIONS_FIELD' }
]);

const actionOptions = computed(() => [
  { value: 'label', label: t('ORDERS.PRINT_LABEL_ACTION') },
  { value: 'packing_slip', label: t('ORDERS.PRINT_SLIP_ACTION') }
]);

const clearError = () => store.commit('orders/clearError');

const loadOrderShipments = (id) => store.dispatch('shipments/loadOrderShipments', id);

const carrierNames = computed(() =>
  orders.value.reduce((acc, order) => {
    acc[order.id] = order.shipment_account_ids.map((shipment_account_id) =>
      store.getters['shippingAccounts/getCarrierNameByShippingAccountId'](shipment_account_id)
    );

    return acc;
  }, {})
);

const formatCarrierColumn = (row) => {
  const names = [...new Set(carrierNames.value[row.id])];

  return names?.length ? names.join(', ') : '-';
};

const formatCreatedAtColumn = (row) => {
  try {
    return format(Date.parse(row.created_at), 'MMM. d, Y, HH:mm');
  } catch (e) {
    return '';
  }
};

const handleAction = async (action, order) => {
  if (!checkIfPrinterReady(action)) {
    handleWarningModal(action);

    return;
  }

  await loadOrderShipments(order.id);

  const shipments = store.getters['shipments/getItemsByOrderId'](order.id);

  await print({ shipments, orderId: order.id, action });
};

const placementCarrierTooltip = (index) => (index + 1 === pageSettings.limit ? 'top' : 'bottom');
</script>
