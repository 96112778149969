<template>
  <div
    v-disable-if-not-admin="isAdmin"
    class="p-6 pl-10 h-full overflow-auto">
    <alert
      v-if="error"
      class="mb-8"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>
    <modal-copy-rules
      :rules="store.getters['shippingRules/shipping']"
      :is-shown="state.isShownCopyRulesModal"
      :is-saving="state.isCopying"
      @save="onCopyRules"
      @close="state.isShownCopyRulesModal = false" />

    <div class="mb-8">
      <div class="flex flex-1 mb-2">
        <div class="flex-1">
          <Switch
            v-model:value="createReturnLabels"
            :label="t('RETURN_RULES.CREATE_RETURN_LABELS')" />
          <div class="pr-6 text-sm text-gray-600 mt-2">
            <span>{{ t('RETURN_RULES.SUBTITLE') }}</span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <b-dropdown
            value="two"
            button-classes="mx-4 border-none shadow-none p-0 w-auto"
            :left-align="false"
            :options="addActionOptions"
            @select="onAdd">
            <template #button>
              <b-button data-id="addReturnRule">
                {{ t('RETURN_RULES.ADD_RULE') }}
                <b-icon
                  class="text-xl text-white ml-1"
                  :icon-name="BringgFontIcons.Chevron" />
              </b-button>
            </template>
          </b-dropdown>
        </div>
      </div>

      <b-divider />

      <div class="pr-6 text-sm text-gray-600 flex -mt-4">
        <span>{{ t('RETURN_RULES.HEADING') }}</span>

        <a
          href="https://help.bringg.com/delivery-hub/docs/set-up-shipping-rules"
          target="_blank"
          class="text-blue-400 text-sm inline-flex font-semibold ml-4">
          <b-icon
            :icon-name="BringgFontIcons.ExternalLink"
            class="text-4xl w-4 h-4" />
          <span class="whitespace-nowrap">{{ t('SHIPPING_RULES.LEARN_MORE') }}</span>
        </a>
      </div>
    </div>

    <animated-placeholder
      v-if="!state.isMounted"
      :count="2" />

    <draggable
      v-if="state.isMounted"
      v-disable-if-not-admin="isAdmin"
      :list="store.getters['shippingRules/return']"
      item-key="id"
      @change="onMove">
      <template #item="{ element, index }">
        <rule
          :rule="element"
          :services="store.getters['shippingRules/services']"
          :index="index"
          class="mb-6"
          @update:rule="onRuleChange"
          @settings="onSettings" />
      </template>
    </draggable>
  </div>
</template>

<script>
export default {
  name: 'ReturnRules'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, onBeforeMount, reactive } from 'vue';
import Draggable from 'vuedraggable';
import { useStore } from 'vuex';

import Alert from '@/components/atoms/Alert';
import AnimatedPlaceholder from '@/components/atoms/AnimatedPlaceholder';
import BButton from '@/components/atoms/Button';
import BDivider from '@/components/atoms/Divider';
import BIcon from '@/components/atoms/Icon';
import Switch from '@/components/atoms/Switch';
import BDropdown from '@/components/molecules/Dropdown';
import ModalCopyRules from '@/components/organisms/ModalCopyRules';
import Rule from '@/components/organisms/Rule';
import {
  ACTIONS,
  ADD_ACTIONS,
  addActionOptions,
  ALL_LOCATIONS,
  RULE_TYPE,
  TRIGGERS
} from '@/components/organisms/Rule/constants';

const store = useStore();
const { t } = useTranslation();

const state = reactive({
  isCopying: false,
  isShownCopyRulesModal: false,
  isMounted: false
});

const error = computed(() => store.state.shippingRules.error);

const isAdmin = computed(() => store.getters['auth/isAdmin']);

onBeforeMount(async () => {
  await Promise.all([
    store.dispatch('shippingRules/loadRules'),
    store.dispatch('shippingAccounts/loadShippingAccounts')
  ]);

  state.isMounted = true;
});

const createReturnLabels = computed({
  get() {
    return store.state.auth.accountSettings.create_return_labels;
  },
  async set(value) {
    await store.dispatch('auth/updateAccountSettings', { create_return_labels: value });
  }
});

const onAdd = (value) => {
  if (value === ADD_ACTIONS.IMPORT) {
    state.isShownCopyRulesModal = true;
  }

  if (value === ADD_ACTIONS.CREATE) {
    store.dispatch('shippingRules/addRule', RULE_TYPE.RETURN);
  }
};

const onCopyRules = async (rules) => {
  state.isCopying = true;

  for (const rule of rules) {
    await store.dispatch('shippingRules/duplicateRule', {
      ...rule,
      locations: [ALL_LOCATIONS],
      attributes: rule.attributes.filter((attribute) => TRIGGERS[attribute.path].ruleType !== RULE_TYPE.SHIPPING),
      type: RULE_TYPE.RETURN
    });
  }

  state.isShownCopyRulesModal = false;
  state.isCopying = false;
};

const onMove = (event) => {
  store.dispatch('shippingRules/moveRule', event.moved);
};

const onRuleChange = (rule) => {
  store.dispatch('shippingRules/saveRule', rule);
};

const onSettings = (value, element, index) => {
  if (value === ACTIONS.DUPLICATE) {
    store.dispatch('shippingRules/duplicateRule', element);
  }

  if (value === ACTIONS.DELETE) {
    store.dispatch('shippingRules/deleteRule', element);
  }

  if (value === ACTIONS.MOVE_UP && index > 0) {
    store.dispatch('shippingRules/moveRule', {
      element,
      newIndex: index - 1,
      oldIndex: index
    });
  }

  if (value === ACTIONS.MOVE_DOWN && index < store.state.shippingRules.rules.length - 1) {
    store.dispatch('shippingRules/moveRule', {
      element,
      newIndex: index + 1,
      oldIndex: index
    });
  }
};

const clearError = () => store.commit('shippingRules/clearError');
</script>
