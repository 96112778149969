import axiosLib from 'axios';

import { MimeTypes } from '@/constants';

const state = {
  pdfCache: new Map()
};

const getters = {
  pdf: (state) => (zplCommands) => state.pdfCache.get(zplCommands)
};

let axios = axiosLib.create({});

const actions = {
  async loadPDF({ commit, getters, rootGetters }, zplCommands) {
    if (getters.pdf(zplCommands)) {
      return;
    }

    try {
      const response = await axios.post(
        `${rootGetters['auth/apiUrl']}/templates-service/v1/printer/zpl`,
        {
          zplCommands: zplCommands,
          mime: MimeTypes.pdf
        },
        {
          responseType: 'arraybuffer',
          headers: { Authorization: `Bearer ${rootGetters['auth/token']}` }
        }
      );
      const pdfData = new Uint8Array(response.data);

      commit('setPDF', { zplCommands, pdfData });
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }
};

const mutations = {
  setPDF(state, { zplCommands, pdfData }) {
    state.pdfCache.set(zplCommands, pdfData);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
