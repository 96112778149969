import { get, uniqBy, uniqueId } from 'lodash';

import { ALL_LOCATIONS, RULE_TYPE } from '@/components/organisms/Rule/constants';
import { deserializeData, getPriority, getRuleName, isValidToSave, serializeData } from '@/services/shippingRules';
import { executeRequest } from '@/utils';

const state = {
  loading: false,
  rules: [],
  error: '',
  absentLocations: new Set()
};

const getters = {
  shipping(state) {
    return state.rules.filter((rule) => rule.type === RULE_TYPE.SHIPPING);
  },
  return(state) {
    return state.rules.filter((rule) => rule.type === RULE_TYPE.RETURN);
  },
  services(state, getters, rootState, rootGetters) {
    const carriers = rootGetters['shippingAccounts/carriers'];
    const serviceTypes = rootGetters['carriers/serviceTypes'];
    const shippingMethods = rootGetters['carriers/shippingMethods'];

    const locations = carriers.reduce((acc, carrier) => {
      if (!acc[carrier.id]) {
        acc[carrier.id] = [];
      }

      if (carrier.shipping_location_id) {
        acc[carrier.id].push(String(carrier.shipping_location_id));
      }

      return acc;
    }, {});

    return uniqBy(
      carriers
        .map((carrier) => {
          const types = serviceTypes[carrier.carrier] ?? [];
          const selected = carrier.extras?.selected_services;
          const services = selected ? types.filter(({ value }) => selected.includes(value)) : types;

          return services.map((service) => ({
            label: `${carrier.name} - ${service.label}`,
            value: `${carrier.id}::${service.value}`,
            supportRates: shippingMethods[carrier.carrier].includes('rate'),
            locations: locations[carrier.id]
          }));
        })
        .flat(),
      'value'
    );
  },
  locationAbsent(state) {
    return state.absentLocations.size !== 0;
  },
  locationAbsentForRule: (state) => (id) => state.absentLocations.has(id)
};

const TEMPORARY_PREFIX = 'temporary_';
const isRuleNew = (rule) => typeof rule.id === 'string' && rule.id.includes(TEMPORARY_PREFIX);

const actions = {
  async loadRules(options) {
    if (options.state.loading) {
      return;
    }

    let request = {
      request: { method: 'GET', url: `/deliveryhub-orders-service/v1/rules` },
      options,
      errorMessage: 'RULES_LOADING_FAIL'
    };

    await executeRequest(request, ({ data }) => options.commit('setRules', data.map(deserializeData)));
  },

  async addRule({ commit, state, getters }, type) {
    commit('setRules', [
      ...state.rules,
      {
        id: uniqueId(TEMPORARY_PREFIX),
        name: getRuleName(type, get(getters, type.toLowerCase()).length + 1),
        priority: getPriority(get(getters, type.toLowerCase())),
        enabled: true,
        cheapest: false,
        type,
        attributes: [],
        services: [],
        locations: [ALL_LOCATIONS]
      }
    ]);
  },

  async duplicateRule(options, rule) {
    const data = {
      ...serializeData(rule),
      name: getRuleName(rule.type, get(options.getters, rule.type.toLowerCase()).length + 1),
      priority: getPriority(get(options.getters, rule.type.toLowerCase()))
    };

    let request = {
      request: { method: 'POST', url: `/deliveryhub-orders-service/v1/rules`, data },
      options,
      errorMessage: 'RULES_DUPLICATE_FAIL'
    };

    await executeRequest(request, ({ data }) => options.commit('setRules', [...state.rules, deserializeData(data)]));
  },

  async saveRule(options, rule) {
    const data = serializeData(rule);
    const isValid = isValidToSave(data);

    const areLocationsAbsent = rule.locations.length === 0;

    options.commit('setLocationsAbsent', { id: rule.id, areLocationsAbsent });

    if (!isValid) {
      return;
    }

    const newRule = isRuleNew(rule);

    const request = {
      request: newRule
        ? { method: 'POST', url: `/deliveryhub-orders-service/v1/rules`, data }
        : { method: 'PUT', url: `/deliveryhub-orders-service/v1/rules/${rule.id}`, data },
      options,
      errorMessage: newRule ? 'RULES_CREATE_FAIL' : 'RULES_UPDATE_FAIL'
    };

    await executeRequest(request, (response) => {
      options.commit('setRule', { id: rule.id, rule: deserializeData(response.data) });
    });
  },

  async deleteRule(options, rule) {
    if (isRuleNew(rule)) {
      options.commit('removeRule', rule.id);

      return;
    }

    let request = {
      request: { method: 'DELETE', url: `/deliveryhub-orders-service/v1/rules/${rule.id}` },
      options,
      errorMessage: 'RULES_DELETE_FAIL'
    };

    await executeRequest(request, () => options.commit('removeRule', rule.id));
  },

  async moveRule(options, { newIndex, oldIndex, element }) {
    const priority = getPriority(get(options.getters, element.type.toLowerCase()), newIndex, oldIndex);

    options.commit('setRule', { id: element.id, rule: { ...element, priority } });
    options.commit('sortRules');

    if (isRuleNew(element.id)) {
      return;
    }

    const data = {
      ...serializeData(element),
      priority
    };
    let request = {
      request: { method: 'PUT', url: `/deliveryhub-orders-service/v1/rules/${element.id}`, data },
      options,
      errorMessage: 'RULES_UPDATE_FAIL'
    };

    await executeRequest(request, () => null);
  }
};
const mutations = {
  setRules(state, rules) {
    state.rules = rules;
  },

  setRule(state, { id, rule }) {
    state.rules = state.rules.map((item) => (item.id === id ? rule : item));
  },

  sortRules(state) {
    state.rules = state.rules.sort((a, b) => a.priority.localeCompare(b.priority));
  },

  removeRule(state, id) {
    state.rules = state.rules.filter((item) => item.id !== id);
  },

  clearError(state) {
    state.error = '';
  },
  setLocationsAbsent(state, { id, areLocationsAbsent }) {
    if (areLocationsAbsent) {
      state.absentLocations.add(id);
    } else {
      state.absentLocations.delete(id);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
