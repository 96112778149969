<template>
  <div
    v-if="datePicker"
    id="date-picker-container">
    <DatePicker
      v-model:value="createdAtRange"
      range></DatePicker>
  </div>
  <popover
    data-id="customization-table-modal"
    class="relative">
    <popover-button>
      <span class="p-2">
        <icon
          class="text-xl text-gray-600"
          :icon-name="BringgFontIcons.Columns" />
      </span>
    </popover-button>
    <popover-panel class="absolute right-0 z-20">
      <div class="w-80 p-2 bg-white tracking-tight rounded custom-shadow">
        <div class="flex justify-between">
          <span class="px-4 py-2 tracking-tight text-base font-semibold">
            {{ t('TABLE.CUSTOMIZATION_TITLE') }}
          </span>
          <span
            class="px-4 py-2 text-blue-400 text-right cursor-pointer"
            @click="reset">
            {{ t('TABLE.DEFAULT_STATE') }}
          </span>
        </div>

        <div class="pt-2 border-t border-gray-200">
          <div
            class="flex h-8 p-1 text-xs leading-6 text-gray-600 cursor-pointer select-none"
            @click="data.isSelectedOpen = !data.isSelectedOpen">
            <icon
              class="text-xl mr-2"
              :icon-name="data.isSelectedOpen ? BringgFontIcons.ChevronUp : BringgFontIcons.Chevron" />
            <span>
              {{ t('TABLE.SELECTED_COLUMNS') }}
            </span>
          </div>
          <template v-if="data.isSelectedOpen">
            <draggable
              class="list-group"
              :list="selected"
              group="columns"
              item-key="key"
              @change="update">
              <template #item="{ element, index }">
                <li class="flex items-center py-1">
                  <span class="pl-7">
                    <icon
                      class="text-xl mr-2 text-gray-500"
                      :icon-name="BringgFontIcons.Drag" />
                    <checkbox
                      :id="`selected-${index}`"
                      :value="true"
                      class="text-xm"
                      @update:value="update({ removed: { element, click: true } })">
                      <template #label>
                        {{ element.label }}
                      </template>
                    </checkbox>
                  </span>
                </li>
              </template>
            </draggable>
          </template>
          <div
            class="flex h-8 p-1 text-xs leading-6 text-gray-600 cursor-pointer select-none"
            @click="data.isUnselectedOpen = !data.isUnselectedOpen">
            <icon
              class="text-xl mr-2"
              :icon-name="data.isUnselectedOpen ? BringgFontIcons.ChevronUp : BringgFontIcons.Chevron" />
            <span>
              {{ t('TABLE.UNSELECTED_COLUMNS') }}
            </span>
          </div>
          <div v-if="data.isUnselectedOpen">
            <draggable
              class="list-group"
              :list="unselected"
              group="columns"
              item-key="key">
              <template #item="{ element, index }">
                <li class="flex items-center py-1">
                  <span class="pl-7">
                    <icon
                      class="text-xl mr-2 text-gray-500"
                      :icon-name="BringgFontIcons.Drag" />
                    <checkbox
                      :id="`unselected-${index}`"
                      :value="false"
                      class="text-xm"
                      @update:value="update({ added: { element, click: true } })">
                      <template #label>
                        {{ element.label }}
                      </template>
                    </checkbox>
                  </span>
                </li>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </popover-panel>
  </popover>
</template>

<script>
export default {
  name: 'TableCustomizationModal'
};
</script>

<script setup>
import 'vue-datepicker-next/index.css';

import { BringgFontIcons } from '@bringg/bringg-icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, reactive, ref, watch } from 'vue';
import DatePicker from 'vue-datepicker-next';
import Draggable from 'vuedraggable';

import Checkbox from '../../atoms/Checkbox';
import Icon from '../../atoms/Icon';

const { t } = useTranslation();

const props = defineProps({
  initValue: {
    type: Array,
    required: true
  },
  selected: {
    type: Array,
    required: true
  },
  unselected: {
    type: Array,
    required: true
  },
  datePicker: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update', 'update:created-at']);

const createdAtRange = ref();

watch(createdAtRange, ([createdAtFrom, createdAtTo]) => emit('update:created-at', { createdAtFrom, createdAtTo }));

const selectedFromProps = computed(() => props.selected);
const unselectedFromProps = computed(() => props.unselected);
const initValue = computed(() => props.initValue);

const data = reactive({
  isSelectedOpen: true,
  isUnselectedOpen: true
});

const removeItem = (array, item) => array.filter(({ key }) => key !== item.element.key);

const parseDraggableValue = (value) => {
  const operation = Object.keys(value)[0];

  return [operation, value[operation]];
};

const updateByDefault = () => {
  emit('update', { selected: selectedFromProps.value, unselected: unselectedFromProps.value });
};

const removeFromSelected = (item) => {
  const updated = {
    selected: removeItem(selectedFromProps.value, item),
    unselected: [...unselectedFromProps.value, item.element]
  };

  emit('update', updated);
};

const addToSelected = (item) => {
  const updated = {
    selected: [...selectedFromProps.value, item.element],
    unselected: removeItem(unselectedFromProps.value, item)
  };

  emit('update', updated);
};

const update = (value) => {
  const [operation, item] = parseDraggableValue(value);

  if (!item.click) {
    updateByDefault();

    return;
  }

  const operationMapping = {
    added: addToSelected,
    removed: removeFromSelected
  };

  const operationFunction = operationMapping[operation];

  if (operationFunction !== undefined) {
    operationFunction(item);
  } else {
    updateByDefault();
  }
};

const reset = () => {
  data.isSelectedOpen = true;
  emit('update', { selected: initValue.value, unselected: [] });
};
</script>

<style>
.custom-shadow {
  box-shadow:
    0 2px 8px rgba(0, 51, 80, 0.04),
    0 16px 24px rgba(0, 51, 80, 0.16);
}
</style>
