<template>
  <div
    data-id="addresses-block"
    class="ml-6">
    <h1 class="text-base text-gray-800 mb-2">
      <b-icon
        class="mr-1"
        :icon-name="BringgFontIcons.User" />
      {{ t('SHIPMENT_DETAILS.ADDRESSES') }}
    </h1>
    <div class="p-4 bg-white shadow">
      <div>
        <div class="text-sm text-gray-800">
          <span>{{ t('TO') }}:</span>
          <span class="ml-1">{{ fulfillmentDetails?.recipient?.name }}</span>
        </div>
        <div
          data-id="recipient-address"
          class="mt-1 text-xs text-gray-600">
          {{ getAddress(fulfillmentDetails?.recipient_address) }}
        </div>
      </div>
      <div class="mt-2">
        <div class="text-sm text-gray-800">
          <span>{{ t('SHIPMENT_DETAILS.SENDER') }}:</span>
          <span class="ml-1">{{ fulfillmentDetails?.sender?.name }}</span>
        </div>
        <div
          data-id="sender-address"
          class="mt-1 text-xs text-gray-600">
          {{ getAddress(fulfillmentDetails?.sender_address) }}
        </div>
      </div>
      <b-button
        data-id="addresses-drawer-button"
        type="blank"
        class="h-auto p-0 mt-3 text-xs text-blue-400 font-semibold"
        @click="toggleDrawer('addresses')">
        {{ t('SHIPMENT_DETAILS.SEE_MORE') }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentDetailsAddresses'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, inject } from 'vue';
import { useStore } from 'vuex';

import BButton from '@/components/atoms/Button';
import BIcon from '@/components/atoms/Icon';
import { ToggleDrawerKey } from '@/components/pages/ShipmentDetails/constants';

const { t } = useTranslation();
const store = useStore();

const fulfillmentDetails = computed(() => store.state.fulfillments.itemDetails);

const getAddress = (address) => {
  if (!address) {
    return '-';
  }

  const { street1, city, state, postal_code } = address;

  let addressString = `${street1}, ${city},`;

  if (state && state.trim() !== '') {
    addressString += ` ${state}`;
  }

  addressString += ` ${postal_code}`;

  return addressString;
};

const toggleDrawer = inject(ToggleDrawerKey);
</script>
