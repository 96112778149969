import { executeRequest } from '../../utils';

const state = {
  items: new Map(),
  fulfillmentItems: {},
  shipmentItems: {},
  loading: false,
  error: ''
};

const getters = {
  items: (state) => Array.from(state.items.values()),
  itemById: (state) => (id) => state.items.get(id),
  getItemsByShipmentId: (state) => (id) => state.shipmentItems[id] || [],
  getItemsByFulfillmentId: (state) => (id) => {
    let result = state.fulfillmentItems[id] || [];

    if (!Array.isArray(result)) {
      return [];
    }

    for (let i = 0; i < result.length; ++i) {
      result[i].children = result[i].products;
    }

    return result;
  },
  getItemsByAllFulfillmentShipmentsId: (state, getters, rootState, rootGetters) => (fulfillmentId) =>
    rootGetters['shipments/getItemsByFulfillmentId'](fulfillmentId).reduce((result, shipment) => {
      result.push(...getters.getItemsByShipmentId(shipment.id));

      return result;
    }, [])
};

const actions = {
  async loadPackages(options) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/packages`,
        params: {
          active: true
        }
      },
      options,
      errorMessage: 'PACKAGES_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setPackages', response.data));
  },

  async loadShipmentPackages(options, { orderId, fulfillmentId, shipmentId }) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/orders/${orderId}/fulfillments/${fulfillmentId}/shipments/${shipmentId}/shipments_packages`
      },
      options,
      errorMessage: 'SHIPMENT_PACKAGES_LOADING_FAIL'
    };

    await executeRequest(request, (response) =>
      options.commit('setShipmentPackages', { shipmentId, value: response.data })
    );
  },

  async createFulfillmentPackage(options, { fulfillmentId, value }) {
    options.commit('addFulfillmentPackage', { fulfillmentId, value });
  },

  async deleteFulfillmentPackage(options, { fulfillmentId, value }) {
    options.commit('deleteFulfillmentPackage', { fulfillmentId, value });
  },

  async deleteAllFulfillmentPackages(options, fulfillmentId) {
    options.commit('deleteAllFulfillmentPackages', fulfillmentId);
  },

  async createPackage(options, data) {
    const request = {
      request: {
        method: 'post',
        url: `/deliveryhub-orders-service/v1/packages`,
        data
      },
      options,
      errorMessage: 'PACKAGE_CREATION_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setPackage', response.data));
  },

  async editPackage(options, data) {
    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/packages/${data.id}`,
        data
      },
      options,
      errorMessage: 'PACKAGE_EDITING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setPackage', response.data));
  },

  async deletePackage(options, data) {
    const request = {
      request: {
        method: 'PUT',
        url: `/deliveryhub-orders-service/v1/packages/${data.id}`,
        data: {
          active: false
        }
      },
      options,
      errorMessage: 'PACKAGES_DELETION_FAIL'
    };

    await executeRequest(request, () => options.commit('deletePackage', data));
  }
};

const mutations = {
  setPackages(state, packages) {
    const packagesEntries = packages.map((item) => [item.id, item]);

    state.items = new Map(packagesEntries);
  },

  setShipmentPackages(state, { shipmentId, value }) {
    state.shipmentItems[shipmentId] = value;
  },

  massSetShipmentPackages(state, { value }) {
    state.shipmentItems = { ...state.shipmentItems, ...value };
  },

  setFulfillmentPackages(state, { fulfillmentId, value }) {
    state.fulfillmentItems[fulfillmentId] = value;
  },

  addFulfillmentPackage(state, { fulfillmentId, value }) {
    if (!(fulfillmentId in state.fulfillmentItems)) {
      state.fulfillmentItems[fulfillmentId] = [];
    }

    state.fulfillmentItems[fulfillmentId].push({ ...value, products: [] });
  },

  deleteFulfillmentPackage(state, { fulfillmentId, value }) {
    state.fulfillmentItems[fulfillmentId].splice(value, 1);
  },

  deleteAllFulfillmentPackages(state, fulfillmentId) {
    state.fulfillmentItems[fulfillmentId] = [];
  },

  deletePackage(state, item) {
    state.items.delete(item.id);
  },

  setPackage(state, item) {
    state.items.set(item.id, item);
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
