<template>
  <div>
    <integration-modal
      :value="modal.data.form"
      :is-shown="modal.data.isOpen"
      :is-create-form="modal.data.isCreateForm"
      :service-options="nameOptions"
      :shipping-accounts="shippingAccounts"
      :shipping-account-blacklist="shippingAccountBlacklist"
      :allowed-ftp-carriers="allowedFtpCarriers"
      @send="modal.handle"
      @close="modal.close">
      <template #title>
        {{ modal.data.isCreateForm ? t('INTEGRATIONS.CREATE_MODAL_TITLE') : t('INTEGRATIONS.UPDATE_MODAL_TITLE') }}
      </template>
      <template #send-button-label>
        {{ modal.data.isCreateForm ? t('INTEGRATIONS.SUBMIT_CREATE_BUTTON') : t('INTEGRATIONS.SUBMIT_UPDATE_BUTTON') }}
      </template>
    </integration-modal>

    <slot name="message" />

    <div class="p-6 pt-4 mt-4 border-gray-200 bg-white sm:rounded-lg shadow">
      <b-table
        :loading="loading"
        :fields="fields"
        :data="data">
        <template #title>
          {{ t('INTEGRATIONS.TITLE') }}
        </template>
        <template #filter-bar>
          <div class="w-full text-right">
            <z-button
              type="success"
              @click="modal.showCreateForm">
              {{ t('INTEGRATIONS.CREATE_BUTTON') }}
            </z-button>
          </div>
        </template>
        <template #before-table>
          <div class="border-b -mx-6 mt-2" />
        </template>
        <template #cell(name)="{ row }">
          {{ row.name ? row.name : serviceNameMapping[row.service] }}
        </template>
        <template #cell(service)="{ row }">
          {{ serviceNameMapping[row.service] }}
        </template>
        <template #cell(status)="{ row }">
          <span
            :class="statusClasses[row.status]"
            class="font-bold">
            {{ row.status }}
          </span>
        </template>
        <template #cell(actions)="{ row }">
          <z-button
            class="mr-4"
            secondary
            @click="modal.showEditForm(row)">
            {{ t('INTEGRATIONS.EDIT_BUTTON') }}
          </z-button>
          <z-button
            type="danger"
            @click="remove(row.id)">
            {{ t('INTEGRATIONS.DELETE_BUTTON') }}
          </z-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntegrationsTemplate'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps } from 'vue';

import useModal from '../../../mixins/useModal';
import ZButton from '../../atoms/Button';
import IntegrationModal from '../../organisms/IntegrationModal';
import BTable from '../../organisms/Table';

const { t } = useTranslation();

const fields = computed(() => [
  { key: 'name', label: t('INTEGRATIONS.NAME_FIELD') },
  { key: 'service', label: t('INTEGRATIONS.SERVICE_FIELD') },
  { key: 'status', label: t('INTEGRATIONS.STATUS_FIELD') },
  { key: 'actions', label: t('ACTIONS_FIELD') }
]);

const statusClasses = {
  created: 'text-gray-500',
  active: 'text-green-500',
  expired: 'text-red-500'
};

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  data: {
    type: Array,
    required: true
  },
  serviceNameMapping: {
    type: Object,
    required: true
  },
  shippingAccounts: {
    type: Array,
    default: () => []
  },
  allowedFtpCarriers: {
    type: Array,
    default: () => []
  }
});

const existedServiceSet = computed(() => new Set(props.data.map(({ service }) => String(service))));

const oneCopyServices = ['1', '2', '3'];

const nameOptions = computed(() =>
  Object.keys(props.serviceNameMapping)
    .filter(
      (value) =>
        !(
          oneCopyServices.includes(value) &&
          existedServiceSet.value.has(value) &&
          modal.data.isOpen &&
          modal.data.isCreateForm
        )
    )
    .map((value) => ({ label: props.serviceNameMapping[value], value }))
);

const shippingAccountBlacklist = computed(() => {
  const result = {
    8: new Set(),
    9: new Set(),
    10: new Set()
  };

  for (let i = 0; i < props.data.length; i++) {
    const { service, shipping_account_id: shippingAccountId } = props.data[i];

    if (shippingAccountId) {
      result[String(service)].add(shippingAccountId);
    }
  }

  return result;
});

const emit = defineEmits(['create', 'update', 'delete']);
const modal = useModal(emit);

const remove = (row) => emit('delete', row.id);
</script>
