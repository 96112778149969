<template>
  <div class="flex overflow-hidden flex-col overflow-hidden h-full">
    <b-modal-packages
      :value="state.editPackage"
      :is-edit="isEdit"
      :is-shown="state.isShownModal"
      @close="onClose"
      @create="onCreate"
      @edit="onEdit" />
    <div class="pl-10 pr-6 pt-6 flex h-full flex-col overflow-hidden">
      <div class="flex mb-6">
        <div class="flex items-center gap-1.5 mr-10">
          <Switch
            v-model:value="isSuggestPackaging"
            :label="t('PACKAGING.SUGGEST_PACKAGING')" />
          <Tooltip
            placement="bottomRight"
            width="w-96">
            <template #content>
              <icon
                :icon-name="BringgFontIcons.Info"
                class="text-2xl text-gray-600" />
            </template>
            <template #title>
              <span class="font-semibold text-sm">
                {{ t('PACKAGING.SUGGEST_PACKAGING_DESC') }}
              </span>
            </template>
          </Tooltip>
        </div>
        <div class="flex flex-1 items-center gap-1.5">
          <Switch
            v-model:value="isValidatePacking"
            :label="t('PACKAGING.VALIDATE_PACKING')" />
          <Tooltip
            placement="bottomRight"
            width="w-96">
            <template #content>
              <icon
                :icon-name="BringgFontIcons.Info"
                class="text-2xl text-gray-600" />
            </template>
            <template #title>
              <span class="font-semibold text-sm">
                {{ t('PACKAGING.VALIDATE_PACKAGING_DESC') }}
              </span>
            </template>
          </Tooltip>
        </div>
        <div class="flex items-center gap-1.5">
          <Switch
            v-model:value="isPrintPackingSlips"
            :label="t('PACKAGING.PRINT_PACKING_SLIPS')" />
          <Tooltip
            placement="bottomLeft"
            width="w-96">
            <template #content>
              <icon
                :icon-name="BringgFontIcons.Info"
                class="text-2xl text-gray-600" />
            </template>
            <template #title>
              <span class="font-semibold text-sm">
                {{ t('PACKAGING.PRINT_PACKING_SLIPS_DESC') }}
              </span>
            </template>
          </Tooltip>
        </div>
        <b-button
          v-if="isAdmin"
          class="ml-6 px-6 py-2"
          data-id="addPackage"
          @click="state.isShownModal = true">
          {{ t('PACKAGING.ADD_PACKAGE') }}
        </b-button>
      </div>

      <alert
        v-if="error"
        class="mb-8"
        type="danger"
        :close="true"
        @close="clearError">
        {{ t(error) }}
      </alert>

      <b-table
        class="packages"
        :loading="loading"
        :fields="fields"
        :data="packages"
        row-key="id"
        last-update>
        <template #cell(name)="{ row }">
          <span
            class="text-blue-400 hover:text-blue-600 cursor-pointer"
            @click="selectAction(row, 'edit-package')">
            {{ row.name }}
          </span>
        </template>
        <template #cell(dimensions)="{ row }">
          {{ formatDimensionsColumn(row) }}
        </template>
        <template #cell(weight)="{ row }">
          {{ formatWeight(row, 'g') }}
        </template>
        <template #cell(actions)="{ row }">
          <dropdown
            :data-id="`actions-${row.id}`"
            :options="actionOptions"
            button-classes="border-0 shadow-none bg-transparent focus:ring-transparent focus:ring-offset-0"
            :left-align="false"
            @select="selectAction(row, $event)">
            <template #button>
              <icon
                :data-id="`actions-${row.id}-button`"
                class="mr-3 text-xl"
                icon-name="menu-horizontal" />
            </template>
          </dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Packaging'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { curryRight } from 'lodash';
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';

import { convertUnits, formatWeight } from '../../../utils';
import Alert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Switch from '../../atoms/Switch';
import Tooltip from '../../atoms/ToolTip/index.vue';
import Dropdown from '../../molecules/Dropdown';
import BModalPackages from '../../organisms/ModalPackages';
import BTable from '../../organisms/Table';

const store = useStore();
const { t } = useTranslation();

const state = reactive({
  editPackage: {},
  isShownModal: false
});

onMounted(async () => await loadPackages());

const isSuggestPackaging = computed({
  get() {
    return store.state.auth.accountSettings.autopack;
  },
  async set(value) {
    await store.dispatch('auth/updateAccountSettings', { autopack: value });
  }
});

const isValidatePacking = computed({
  get() {
    return store.state.auth.accountSettings.validate_packing;
  },
  async set(value) {
    await store.dispatch('auth/updateAccountSettings', { validate_packing: value });
  }
});

const isPrintPackingSlips = computed({
  get() {
    return store.state.auth.accountSettings.print_packing_slips;
  },
  async set(value) {
    await store.dispatch('auth/updateAccountSettings', { print_packing_slips: value });
  }
});

const packages = computed(() => store.getters['packages/items']);
const loading = computed(() => store.state.packages.loading);
const error = computed(() => store.state.packages.error);
const isEdit = computed(() => !(Object.keys(state.editPackage).length === 0));
const isAdmin = computed(() => store.getters['auth/isAdmin']);

const fields = computed(() => {
  const result = [
    { key: 'name', label: 'PACKAGING.NAME_FIELD' },
    { key: 'dimensions', label: 'PACKAGING.DIMENSIONS_FIELD' },
    { key: 'weight', label: 'PACKAGING.WEIGHT_FIELD' }
  ];

  if (isAdmin.value) {
    result.push({ key: 'actions', label: 'ACTIONS_FIELD' });
  }

  return result;
});

const actionOptions = [
  { bringgIcon: 'pencil', value: 'edit-package', label: 'QUICK_ACTIONS.EDIT' },
  { bringgIcon: 'trash', value: 'delete-package', label: 'QUICK_ACTIONS.DELETE' }
];

const loadPackages = async () => await store.dispatch('packages/loadPackages');
const clearError = () => store.commit('packages/clearError');

const formatDimensionsColumn = (row) => {
  const unit = 'cm';
  const convertToCm = curryRight(convertUnits)(unit);

  return `${convertToCm(row.length, row.dim_units)} (L) x ${convertToCm(row.width, row.dim_units)} (W) x ${convertToCm(
    row.height,
    row.dim_units
  )} (H) ${unit}`;
};

const onCreate = async (value) => {
  await store.dispatch('packages/createPackage', value);
};

const onEdit = async (value) => {
  await store.dispatch('packages/editPackage', value);
  state.isShownModal = false;
  state.editPackage = {};
};

const onClose = () => {
  state.isShownModal = false;
  state.editPackage = {};
};

const selectAction = async (row, actionType) => {
  const actions = {
    'edit-package': () => {
      state.editPackage = row;
      state.isShownModal = true;
    },
    'delete-package': async () => {
      await store.dispatch('packages/deletePackage', row);
    }
  };

  return await actions[actionType]();
};
</script>
