<template>
  <div class="flex pb-6 w-10 md:w-auto">
    <div class="flex flex-col items-center flex-1 md:flex-row md:justify-between">
      <template
        v-for="item in renderData"
        :key="item">
        <div
          v-if="item.value === 'line'"
          class="h-6 w-0.5 mx-2 rounded md:flex-1 md:h-0.5"
          :class="[item.checked || item.expecting ? 'bg-blue-400' : 'bg-gray-400']"></div>
        <div
          v-else
          class="relative w-5 h-5 rounded-full border-2 text-center"
          :class="[
            item.value === 'Canceled' ? 'border-red-500' : item.expecting ? 'border-blue-400' : 'border-gray-400'
          ]">
          <Icon
            :icon-name="item.iconName || ''"
            class="absolute -top-1 -left-1 text-2xl"
            :class="{
              'text-blue-400': item.checked || item.expecting,
              'text-green-500': item.checked && item.value === 'Delivered',
              'text-yellow-500': item.value === 'Exception',
              'text-red-500': item.value === 'Canceled'
            }" />
          <div
            class="absolute -bottom-1 left-6 md:-bottom-6 md:-left-0.5 text-sm whitespace-nowrap"
            :class="{
              'text-gray-700': item.value === 'Exception' || item.value === 'Canceled',
              'text-gray-400': !item.checked && !item.expecting,
              'text-green-500': item.checked && item.value === 'Delivered'
            }">
            {{ addSpaces(item.value) }}
          </div>
        </div>
      </template>
    </div>
    <div class="w-10"></div>
  </div>
</template>

<script>
export default {
  name: 'StatusBarCustomerTracking'
};
</script>

<script setup>
import { computed, defineProps } from 'vue';

import Icon from '../../atoms/Icon/index.vue';

const props = defineProps({
  status: {
    type: String,
    default: 'ReadyToShip'
  }
});

const renderData = computed(() => {
  const statusListDefault = [
    { value: 'ReadyToShip' },
    { value: 'line' },
    { value: 'PickedUp' },
    { value: 'line' },
    { value: 'InTransit' },
    { value: 'line' },
    { value: 'OutForDelivery' },
    { value: 'line' },
    { value: 'Delivered' }
  ];

  if (props.status === 'Exception') {
    return [
      ...statusListDefault.slice(0, 4).map((status) => {
        status.checked = true;
        status.iconName = 'suffix';

        return status;
      }),
      {
        value: 'Exception',
        iconName: 'info-filled'
      },
      ...statusListDefault.slice(5)
    ];
  }

  if (['Canceled', 'CanceledByCarrier'].includes(props.status)) {
    return [
      ...statusListDefault.slice(0, 2).map((status) => {
        status.checked = true;
        status.iconName = 'suffix';

        return status;
      }),
      {
        value: 'Canceled',
        iconName: 'close-circle'
      },
      ...statusListDefault.slice(3)
    ];
  }

  const latestStatusIndex = statusListDefault.findIndex((status) => status.value === props.status);
  const expectingStatusIndex = latestStatusIndex + 2;

  return statusListDefault.map((item, index) => {
    if (index <= latestStatusIndex) {
      item.checked = true;
      item.iconName = 'suffix';
    }

    if (index > latestStatusIndex && index <= expectingStatusIndex) {
      item.expecting = true;
      item.iconName = 'small-circle';
    }

    return item;
  });
});

function addSpaces(string) {
  return string.replace(/([A-Z])/g, ' $1').trim();
}
</script>
<style></style>
