const colors = require('tailwindcss/colors');

const deprecatedColors = ['lightBlue'];

const defaultColors = Object.keys(colors).reduce((result, colorName) => {
  if (deprecatedColors.includes(colorName)) {
    return result;
  }

  result[colorName] = colors[colorName];

  return result;
}, {})

module.exports = {
  purge: ['./public/index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      margin: {
        '18': '4.5rem',
      },

      width: {
        '62.5': '15.625rem',
        '69': '17.25rem',
        '65': '16.35rem',
        '90': '22.5rem',
        '100': '25rem',
        '112': '28.5rem',
        '144': '36.25rem',
        '150': '37rem',
        '250': '62.5rem'
      },

      minWidth: {
        '9': '2.25rem'
      },

      maxWidth: {
        '40': '10rem'
      },

      maxHeight: {
        '144': '36.25rem',
        '88': '22rem'
      },

      boxShadow: {
        'bottom': '0px 1px 2px rgba(0, 51, 80, 0.16)',
        'top': '0px -2px 4px rgba(0, 51, 80, 0.08)',
      },
    },

    fontSize: {
      '2xs': '.625rem',
      'xs': '.75rem',
      'xm': '.8125rem',
      'sm': '.875rem',
      'base': '1rem',
      'md': '1.0625rem',
      'lg': '1.125rem',
      'xl': '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
    },
    fontFamily: {
      sans: ['Open Sans']
    },
    minHeight: {
      '1/2': '50%',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      ...defaultColors,
      gray: {
        50: '#F8FAFB',
        100: '#EFF4F8',
        200: '#E1E9F0',
        300: '#D7E0E9',
        400: '#CBD5E0',
        500: '#A0AEC0',
        600: '#718096',
        700: '#4A5568',
        800: '#2D3748',
        900: '#1E2634'
      },
      blue: {
        10: '#F4FAFF',
        25: '#EDF5FD',
        50: '#E5F4FF',
        100: '#CCE9FF',
        200: '#A7D9F8',
        300: '#6FB2DD',
        400: '#3091CE',
        500: '#067EBA',
        600: '#006497',
        700: '#004B73',
        800: '#003350',
        900: '#001E31',
      },
      purple: {
        100: 'rgba(162, 93, 220, 0.2)',
        500: '#A661E0',
      },
      orange: {
        100: 'rgba(253, 171, 61, 0.2)',
        500: 'rgba(253, 171, 61, 1)',
        600: '#FFB400'
      },
      sky: {
        100: 'rgba(0, 134, 192, 0.2)',
        500: '#0086C0',
      },
      teal: {
        100: 'rgba(73, 210, 186, 0.2)',
        500: '#49D2BA',
      },
      emerald: {
        100: 'rgba(3, 127, 76, 0.2)',
        500: '#037F4C',
      },
      pink: {
        100: 'rgba(255, 90, 196, 0.16)',
        500: '#FF5AC4',
      },
      rose: {
        100: 'rgba(230, 75, 56, 0.16)',
        500: '#E64B38',
      },
      slate: {
        75: '#F8FAFB'
      },
      warning: {
        600: '#FFB400'
      },
      danger: {
        900: '#D93A17'
      }
    }
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      cursor: ['disabled'],
      borderWidth: ['last'],
    }
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp')
  ],
}
