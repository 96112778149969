<template>
  <div class="px-6 pt-8 h-full overflow-auto">
    <div class="flex items-center justify-between">
      <h1 class="text-lg text-gray-800 font-medium">{{ t('SHIPMENT_DETAILS.CARRIER_DETAILS') }}</h1>
      <button
        ref="closeDrawerButton"
        type="button"
        @click="emit('close')">
        <b-icon
          class="text-m text-gray-800"
          :icon-name="BringgFontIcons.Close" />
      </button>
    </div>
    <div class="flex flex-row items-center gap-3 my-5">
      <div class="shadow rounded-md">
        <img
          class="h-12 w-12 rounded-md shadow-bottom"
          :src="value.shippingAccount?.carrier_logo"
          :alt="`logo ${value.shippingAccount?.carrier_name}`" />
      </div>
      <span class="font-semibold text-gray-800">{{ value.shippingAccount?.carrier_name }}</span>
    </div>
    <div
      v-for="item in value.carrierRenderData"
      :key="item"
      class="mt-1">
      <span class="text-sm text-gray-800 font-medium">{{ item.label }}:</span>
      <span class="ml-1 text-sm text-gray-600">{{ item.value }}</span>
    </div>
    <div>
      <h2 class="mt-6">
        <b-icon
          class="mr-1 text-lg"
          icon-name="sheet-with-dollar" />
        {{ t('SHIPMENT_DETAILS.CHARGES') }}
      </h2>
      <div
        v-for="item in costs"
        :key="item"
        :data-id="item.dataId"
        class="mt-1 ml-7">
        <span class="text-sm text-gray-800 font-medium">{{ item.label }}:</span>
        <span class="ml-1 text-sm text-gray-600">{{ item.value }}</span>
      </div>
      <div
        :data-id="totalCost.dataId"
        class="inline-block ml-7 mt-2 py-2 border-t border-gray-300">
        <span class="text-sm text-gray-800 font-medium">{{ totalCost.label }}:</span>
        <span class="ml-1 text-sm font-medium text-gray-800">
          {{ totalCost.value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarrierDetails'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps } from 'vue';

import BIcon from '@/components/atoms/Icon';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
});

const costs = computed(() => props.value.deliveryCostRenderData.slice(0, -1));

const totalCost = computed(() => {
  const arr = props.value.deliveryCostRenderData;

  return arr[arr.length - 1];
});

const emit = defineEmits(['close']);
</script>
