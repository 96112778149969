<template>
  <div class="p-6 bg-white rounded shadow">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Box'
};
</script>

<script setup></script>
