<template>
  <div class="flex-shrink-0 flex bg-white container-min-height">
    <div class="flex-1 pl-10 pr-4 flex justify-between">
      <div class="flex align-middle items-center whitespace-nowrap">
        <h2
          id="pageTitle"
          class="text-xl font-semibold leading-6 text-gray-800">
          {{ title ? t(`MENU.${title.toUpperCase().replaceAll(' ', '_')}`) : '' }}
        </h2>
      </div>
      <div
        v-if="items?.length"
        class="flex w-full tabs-margin whitespace-nowrap">
        <nav>
          <nav-item
            v-for="item in items"
            :key="item.name"
            :to="item.to"
            :current="item.to.name === route.name">
            {{ t(item.name) }}
          </nav-item>
          <slot />
        </nav>
      </div>
      <div class="flex flex-col container-min-height">
        <div class="flex flex-row mt-2">
          <div
            id="additionalItems"
            class="flex flex-row w-full justify-end items-center mr-8"></div>
          <div class="flex flex-row items-center gap-3">
            <div class="flex items-center">
              <Tooltip placement="bottomLeft">
                <template #content>
                  <a
                    href="https://help.bringg.com/delivery-hub"
                    target="_blank">
                    <icon
                      class="text-2xl text-gray-600"
                      :icon-name="BringgFontIcons.Help" />
                  </a>
                </template>
                <template #title>
                  <span class="font-semibold text-sm">
                    {{ t('NAV_BAR.DOCUMENTATION') }}
                  </span>
                </template>
              </Tooltip>
            </div>
            <span class="w-px h-8 bg-gray-200"></span>
            <div class="flex items-center">
              <slot name="right" />
            </div>
          </div>
        </div>
        <div
          id="additionalItemsFooter"
          class="flex flex-row justify-end items-center" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { defineProps } from 'vue';
import { useRoute } from 'vue-router';

import Icon from '../../atoms/Icon';
import NavItem from '../../atoms/NavItem';
import Tooltip from '../../atoms/ToolTip';

const { t } = useTranslation();
const route = useRoute();

defineProps({
  title: {
    type: String,
    required: true
  },
  items: {
    type: Array,
    required: false,
    default: () => []
  }
});
</script>

<style lang="scss" scoped>
.tabs-margin {
  margin-left: 72px;
}

.container-min-height {
  min-height: 3.5rem;
}
</style>
