import { executeRequest } from '../../utils';

const state = {
  printers: new Map(),
  printersSettings: {
    label_printer_id: '',
    label_tray_id: '',
    packing_slip_printer_id: '',
    packing_slip_tray_id: '',
    commercial_invoice_printer_id: '',
    commercial_invoice_tray_id: ''
  },
  loading: false,
  error: ''
};

const getters = {
  printersItems(state) {
    return Array.from(state.printers.values());
  },
  printers(state) {
    return state.printers;
  },
  settingsReady: (state) => Object.keys(state.printersSettings).every((key) => Boolean(state.printersSettings[key]))
};

const actions = {
  async loadPrinters(options) {
    const request = {
      request: {
        method: 'GET',
        url: '/deliveryhub-orders-service/v1/printing/printers'
      },
      options,
      errorMessage: 'PRINTERS_LOADING_FAIL'
    };

    await executeRequest(request, (response) => options.commit('setPrinters', response.data));
  },

  async print(options, { shipmentId, data }) {
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/printing/print/${shipmentId}`,
        data
      },
      options,
      errorMessage: 'PRINT_SHIPMENT_FAIL'
    };

    await executeRequest(request, () => ({}));
  }
};

const mutations = {
  setPrinters(state, printers) {
    const printersEntries = printers.map((item) => [item.id, item]);

    state.printers = new Map(printersEntries);
  },

  clearError(state) {
    state.error = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
