<template>
  <div class="relative h-screen flex flex-col justify-center items-center bg-gray-50">
    <template v-if="!state.redirect">
      <img
        class="absolute left-10 top-12"
        src="../../../assets/img/bringg-logo.svg"
        alt="Bringg" />

      <h1 class="text-3xl text-gray-800 font-semibold">
        {{ t('REMIND.TITLE') }}
      </h1>

      <div class="form mt-10">
        <v-form
          :validation-schema="schema"
          @submit="onSubmit">
          <b-input
            id="email"
            :label="t('REMIND.USERNAME')" />

          <b-alert
            v-if="state.error"
            class="mt-6"
            type="danger">
            {{ state.error }}
          </b-alert>

          <vue-recaptcha
            v-if="state.captchaRequired"
            class="mt-6"
            :sitekey="siteKey"
            @verify="onVerify" />

          <b-button
            class="w-full mt-10"
            :loading="state.loading"
            data-id="remind"
            submit>
            {{ t('REMIND.SUBMIT') }}
          </b-button>
        </v-form>
        <div class="flex justify-center pt-8 text-sm text-gray-600">
          <router-link
            :to="{ name: 'login' }"
            class="text-blue-600 dark:text-blue-500 hover:underline">
            {{ t('REMIND.BACK_TO_LOGIN') }}
          </router-link>
          <router-link
            v-if="false"
            :to="{ name: 'signup' }"
            class="text-blue-600 dark:text-blue-500 hover:underline">
            {{ t('SIGNUP.SIGNUP') }}
          </router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col items-center gap-2">
        <h1 class="text-3xl text-gray-800 font-semibold">{{ t('REMIND.REDIRECT_TITLE') }}</h1>
        <p class="text-sm text-gray-800 opacity-80 max-w-md">
          {{ t('REMIND.REDIRECT_MESSAGE') }},
          <span
            class="text-blue-600 dark:text-blue-500 hover:underline"
            @click="resend">
            {{ t('REMIND.CLICK_HERE_TO_RESEND') }}
          </span>
        </p>
        <router-link
          :to="{ name: 'login' }"
          class="text-blue-600 dark:text-blue-500 hover:underline">
          {{ t('REMIND.BACK_TO_LOGIN') }}
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ResetPage'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { Form as VForm, useForm } from 'vee-validate';
import { computed, reactive } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { useStore } from 'vuex';
import { string } from 'yup';

import config from '@/config';

import BAlert from '../../atoms/Alert';
import BButton from '../../atoms/Button';
import BInput from '../../atoms/Input';

const store = useStore();
const { t } = useTranslation();
const { resetForm } = useForm();

const siteKey = config.get('CAPTCHA');

const state = reactive({
  captchaRequired: false,
  error: null,
  loading: false,
  redirect: false,
  recaptcha: ''
});

const schema = computed(() => ({
  email: string().email().required().label(t('REMIND.USERNAME'))
}));

const onSubmit = async (data) => {
  state.loading = true;
  state.error = null;

  try {
    const response = await store.dispatch('auth/remind', { ...data, recaptcha: state.recaptcha });

    if (response.data.recaptcha_stoken) {
      state.captchaRequired = true;
    }

    if (!response.data.success) {
      state.error = response.data.message;
    } else {
      state.redirect = true;
    }
  } catch (error) {
    state.error = error.response.data.errors.reason;
  } finally {
    state.loading = false;
  }
};

const resend = () => {
  state.redirect = false;
  resetForm();
};

const onVerify = (value) => {
  state.recaptcha = value;
};
</script>

<style lang="scss" scoped>
.form {
  width: 400px;
}
</style>
