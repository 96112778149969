<template>
  <modal
    :data-id="props.isCreateModal ? 'create-shipping-account' : 'edit-shipping-account'"
    :value="isShown"
    :validation="schema"
    class="w-full"
    @close="closeModal"
    @submit="submit">
    <template #title>
      <div class="px-6 py-4 font-semibold text-base shadow-bottom">
        <template v-if="props.isCreateModal">
          {{ t('SHIPPING_ACCOUNTS_MODAL.NEW_TITLE') }}
        </template>
        <template v-else>
          {{ t('SHIPPING_ACCOUNTS_MODAL.EDIT_TITLE') }}
        </template>
      </div>
    </template>
    <template
      v-if="data.localValue"
      #body>
      <div class="w-full flex flex-col px-6 pt-4 mb-16">
        <b-input
          id="name"
          v-model:value="data.localValue.name"
          :timeout="0"
          class="mb-4 w-full"
          :placeholder="t('SHIPPING_ACCOUNTS_MODAL.NAME_FIELD_PLACEHOLDER')">
          <template #label>
            <span class="block text-xs mb-1">{{ t('SHIPPING_ACCOUNTS_MODAL.NAME_FIELD') }}</span>
          </template>
        </b-input>
        <b-select
          id="carrier"
          v-model:value="data.localValue.carrier"
          :options="carrierOptions"
          :disabled="!isCreateModal"
          :placeholder="t('SELECT_PLACEHOLDER')"
          class="mb-4 w-full">
          <template #label>
            {{ t('SHIPPING_ACCOUNTS_MODAL.CARRIER_FIELD') }}
          </template>
        </b-select>
        <div class="mb-4 w-full">
          <b-multi-select
            ref="servicesMultiSelectRef"
            :options="servicesOptions"
            :placeholder-text="t('SELECT_PLACEHOLDER')"
            :all-placeholder-name="t('SHIPPING_ACCOUNTS_MODAL.SELECT_ALL')"
            :allow-empty="false"
            :value="data.localValue.extras.selected_services"
            absent-data-warning="SHIPPING_ACCOUNTS_MODAL.ABSENT_SERVICES_WARNING"
            @select="selectServices">
            <template #label>
              <span class="block text-xs mb-1">
                {{ t('SHIPPING_ACCOUNTS_MODAL.SERVICES_FIELD') }}
                <span class="text-red-600">*</span>
              </span>
            </template>
          </b-multi-select>
        </div>

        <template v-if="data.formFields.length">
          <div>
            <h3 class="mb-2 leading-6 text-sm font-semibold text-gray-700">
              {{ t('SHIPPING_ACCOUNTS_MODAL.CREDENTIALS_FIELD') }}
            </h3>
          </div>
          <template
            v-for="{ type, name, label, options: fieldOptions } in data.formFields"
            :key="name">
            <b-select
              v-if="type === 'collection'"
              :id="`auth-${name}`"
              v-model:value="data.localValue.auth[name]"
              :options="fieldOptions"
              :placeholder="t('SELECT_PLACEHOLDER')"
              class="mb-4 w-full">
              <template #label>
                {{ label }}
              </template>
            </b-select>
            <b-input
              v-else
              :id="`auth-${name}`"
              v-model:value="data.localValue.auth[name]"
              :timeout="0"
              class="mb-4 w-full">
              <template #label>
                {{ label }}
              </template>
            </b-input>
          </template>
        </template>
        <template v-if="isTestCheckboxShown">
          <checkbox
            id="testShippingAccount"
            :value="data.localValue.test"
            class="mb-4"
            @update:value="updateTestField">
            <template #label>
              {{ t('SHIPPING_ACCOUNTS_MODAL.CREATE_TEST_ACCOUNT') }}
            </template>
          </checkbox>
        </template>

        <div>
          <h3 class="mb-2 leading-6 text-sm font-semibold text-gray-700">
            {{ t('SHIPPING_ACCOUNTS_MODAL.LOCATION_FIELD_TITLE') }}
          </h3>
        </div>
        <b-select
          id="location_id"
          v-model:value="data.localValue.location_id"
          :options="formattedLocationOptions"
          options-class="h-20"
          :placeholder="t('SELECT_PLACEHOLDER')"
          class="mb-4 w-full"
          multiple>
          <template #label>
            <div class="flex justify-between">
              <span>
                {{ t('SHIPPING_ACCOUNTS_MODAL.LOCATION_FIELD') }}
              </span>
              <router-link
                :to="{ name: 'shipping-locations' }"
                class="inline-flex text-blue-500 hover:text-blue-700"
                target="_blank">
                <b-icon
                  icon-name="external-link"
                  class="text-4xl w-4 h-4 -mt-1" />
                {{ t('SHIPPING_ACCOUNTS_MODAL.NEW_LOCATION_LINK') }}
              </router-link>
            </div>
          </template>
        </b-select>

        <div>
          <h3 class="mb-2 leading-6 text-sm font-semibold text-gray-700">
            {{ t('SHIPPING_ACCOUNTS_MODAL.DEFAULTS_FIELD') }}
          </h3>
        </div>
        <b-select
          id="country"
          :value="data.localValue.country"
          :options="countryOptions"
          options-class="h-20"
          :placeholder="t('SELECT_PLACEHOLDER')"
          class="mb-4 w-full"
          @update:value="updateCountry">
          <template #label>
            {{ t('SHIPPING_ACCOUNTS_MODAL.COUNTRY_FIELD') }}
          </template>
        </b-select>
      </div>
    </template>
    <template #footer>
      <div class="mt-7 px-6 py-4 flex flex-row-reverse gap-6 shadow-top">
        <b-button
          class="h-8 px-4 py-1"
          submit
          @click="touch">
          {{ t('SAVE_BUTTON') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ShippingAccountModal'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, nextTick, reactive, ref, watch } from 'vue';
import { array, object, string } from 'yup';

import BSelect from '../../atoms/BSelect';
import BButton from '../../atoms/Button';
import Checkbox from '../../atoms/Checkbox';
import BIcon from '../../atoms/Icon';
import BInput from '../../atoms/Input';
import BMultiSelect from '../../molecules/MultiSelect';
import Modal from '../../organisms/Modal';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Object,
    default: () => ({})
  },
  carriers: {
    type: Array,
    default: () => []
  },
  locations: {
    type: Array,
    default: () => []
  },
  countryOptions: {
    type: Array,
    default: () => []
  },
  currencyOptions: {
    type: Array,
    default: () => []
  },
  packagingTypes: {
    type: Object,
    default: () => ({})
  },
  serviceTypes: {
    type: Object,
    default: () => ({})
  },
  labelTypes: {
    type: Object,
    default: () => ({})
  },
  isShown: {
    type: Boolean,
    default: false
  },
  isCreateModal: {
    type: Boolean,
    default: true
  }
});

const localValueFactory = () => ({
  name: '',
  carrier: '',
  country: '',
  // defaults: {
  //   dim_units: '',
  //   weight_units: '',
  //   packaging_type: '',
  //   service: '',
  //   label_type: '',
  //   currency: ''
  // },
  location_id: [],
  auth: {},
  test: false,
  extras: {
    selected_services: []
  }
});

const data = reactive({
  localValue: {
    ...localValueFactory(),
    ...props.value
  },
  selectedCarrier: {},
  formValidation: {},
  formFields: []
});

const requiredBaseFieldsSchema = computed(() => ({
  name: string().max(50).required().label(t('SHIPPING_ACCOUNTS_MODAL.NAME_FIELD')),
  carrier: string().required().label(t('SHIPPING_ACCOUNTS_MODAL.CARRIER_FIELD')),
  country: string().required().label(t('SHIPPING_ACCOUNTS_MODAL.COUNTRY_FIELD')),
  // defaults: object({
  //   dim_units: string().required().label(t('SHIPPING_ACCOUNTS_MODAL.DIMENSION_FIELD')),
  //   weight_units: string().required().label(t('SHIPPING_ACCOUNTS_MODAL.WEIGHT_UNIT_FIELD'))
  // packaging_type: string().required().label('Packaging Type'),
  // service: string().required().label('Service'),
  // label_type: string().required().label('Label Type'),
  // }),
  location_id: array().of(string()).label(t('SHIPPING_ACCOUNTS_MODAL.LOCATION_FIELD'))
}));

const schema = computed(() => object({ ...requiredBaseFieldsSchema.value, ...data.formValidation }));

const carrierOptions = computed(() =>
  props.carriers
    .map((carrier) => ({
      value: carrier.slug,
      label: carrier.display_name.trim() || carrier.slug[0].toUpperCase() + carrier.slug.substr(1)
    }))
    .sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    })
);

const servicesMultiSelectRef = ref(null);

const servicesOptions = computed(() => {
  const { selectedCarrier } = data;

  if (!selectedCarrier.service_types) {
    return [];
  }

  return selectedCarrier.service_types.map((type) => ({
    label: type.name,
    value: type.slug
  }));
});

const carrierChanged = ref(false);

watch(servicesOptions, () => {
  nextTick(() => {
    const shouldSelectAllServicesFromCarrier =
      props.isCreateModal || carrierChanged.value || props.value.carrier !== data.selectedCarrier.slug;

    if (shouldSelectAllServicesFromCarrier) {
      servicesMultiSelectRef.value?.selectAll();
      carrierChanged.value = true;
    }
  });
});

const selectServices = (_, selectedServices) => (data.localValue.extras.selected_services = [...selectedServices]);

const formattedLocationOptions = computed(() =>
  props.locations.map(({ id: value, name: label }) => ({ value, label }))
);

// test checkbox could be either master or sandbox account
const useMasterTestAccount = computed(
  () =>
    data.selectedCarrier.has_master_account &&
    data.selectedCarrier.master_account_countries.includes(data.localValue.country)
);

const isTestCheckboxShown = computed(() => !!data.localValue.carrier);

watch(
  () => props.value,
  (value) => {
    const newLocalValue = localValueFactory();

    data.localValue = {
      ...newLocalValue,
      ...value,
      auth: {
        ...newLocalValue.auth,
        ...value.auth
      },
      extras: {
        ...newLocalValue.extras,
        ...value.extras
      }
      // defaults: {
      //   ...newLocalValue.defaults,
      //   ...value.defaults
      // }
    };

    const shouldUpdateSelectedServices = !props.isCreateModal && !value.extras?.selected_services;

    if (shouldUpdateSelectedServices) {
      nextTick(() => {
        data.localValue.extras = {
          selected_services: servicesOptions.value.map((option) => option.value)
        };
      });
    }

    carrierChanged.value = false;
  }
);

watch(
  () => data.localValue.carrier,
  async (value) => {
    data.localValue.packaging_type = '';
    data.localValue.service = '';
    data.localValue.label_type = '';

    if (!value) {
      data.selectedCarrier = {};
      await rebuildForm();

      return;
    }

    for (let i = 0; i < props.carriers.length; ++i) {
      if (props.carriers[i].slug === value) {
        data.selectedCarrier = props.carriers[i];
        await rebuildForm(!!Object.keys(props.value).length);
        break;
      }
    }
  }
);

const emit = defineEmits(['send', 'close']);

const rebuildForm = async (isEdited) => {
  data.formFields = [];
  data.form = object({});

  await nextTick(() => {
    const form = {};
    const fields = [];

    const { auth } = data.selectedCarrier;

    auth &&
      Array.isArray(auth) &&
      (!useMasterTestAccount.value || !data.localValue.test) &&
      auth.forEach((element) => {
        if (!element.ui) {
          return;
        }

        const key = `auth-${element.slug}`;

        form[key] = string();

        if (element.required) {
          form[key] = form[key].required();
        }
        form[key] = form[key].label(element.name);

        const fieldItem = { name: element.slug, label: element.name, type: element.type };

        if ('collection' in element) {
          fieldItem['options'] = element['collection'].map((item) => ({ value: item.slug, label: item.name }));
        }

        fields.push(fieldItem);
      });

    if (!Object.keys(props.value.auth || {}) || !isEdited || useMasterTestAccount.value) {
      data.localValue.auth = {};
    }

    data.formValidation = form;
    data.formFields = fields;
  });
};

const updateTestField = async (current) => {
  const previous = data.localValue.test;

  data.localValue.test = current;

  if (current || (previous && !current)) {
    await rebuildForm(true);
  }
};

const updateCountry = async (value) => {
  data.localValue.country = value;
  if (data.localValue.test) {
    await rebuildForm(true);
  }
};

const submit = () => emit('send', data.localValue);

const closeModal = async () => {
  data.localValue = localValueFactory();
  await nextTick(async () => {
    await nextTick(() => emit('close'));
  });
};

const touch = () => servicesMultiSelectRef.value?.touch();
</script>
