<template>
  <div class="border-none p-0">
    <Popover class="relative">
      <PopoverButton
        class="focus:outline-none flex items-center"
        :data-id="`button_${dataId}`">
        <b-icon
          :icon-name="BringgFontIcons.Plus"
          class="flex justify-center items-center text-2xl text-white bg-blue-400 w-10 h-10 rounded-full" />
        <span class="text-blue-400 text-sm font-semibold ml-1.5">
          <slot name="buttonText"></slot>
        </span>
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0">
        <PopoverPanel
          v-slot="{ close }"
          class="absolute bg-white p-2 w-max shadow rounded z-50">
          <VForm
            v-slot="{ values }"
            :initial-values="{ attribute: props.value }"
            @submit="submit($event, close)">
            <div
              v-for="option in options"
              :key="option">
              <b-checkbox
                :id="option.value"
                :data-id="option.value"
                class="px-2 py-1 text-gray-700 text-sm"
                name="attribute"
                :value="option.value"
                validation>
                <template #label>
                  {{ option.label }}
                </template>
              </b-checkbox>
            </div>
            <b-button
              data-id="done"
              class="w-48 mt-3"
              primary
              submit
              :disabled="!values.attribute.length">
              <slot name="submitText"></slot>
            </b-button>
          </VForm>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
<script>
export default {
  name: 'SelectRuleAttributes'
};
</script>
<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { Form as VForm } from 'vee-validate';
import { defineEmits, defineProps } from 'vue';

import BButton from '../../atoms/Button';
import BCheckbox from '../../atoms/Checkbox';
import BIcon from '../../atoms/Icon';

const props = defineProps({
  value: {
    type: Array,
    required: true
  },
  options: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  dataId: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:value']);

const submit = (values, close) => {
  emit('update:value', values.attribute);
  close();
};
</script>
