<template>
  <div class="inline-flex items-center">
    <input
      :id="id"
      :data-id="dataId"
      :name="name"
      :disabled="disabled"
      :value="value"
      :checked="check"
      type="radio"
      class="text-blue-400"
      :class="[error ? 'border-red-300' : 'border-gray-700']"
      @click="updateValue" />
    <label
      :for="id"
      class="ml-2 text-gray-700">
      <template v-if="$slots['label']">
        <slot name="label" />
      </template>
      <template v-else>
        {{ label }}
      </template>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Radio'
};
</script>

<script setup>
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps } from 'vue';

const props = defineProps({
  value: {
    type: null,
    default: false
  },
  id: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  dataId: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:value']);

const connnectedField = useField(props.name, undefined, {
  type: 'radio',
  checkedValue: props.value
});

const check = computed(() => {
  return connnectedField.checked.value;
});

const updateValue = (event) => {
  const value = event.target.value;

  connnectedField.handleChange(value);
  emit('update:value', value);
};
</script>
