<template>
  <Menu
    v-slot="{ open, close }"
    as="div"
    class="relative inline-block text-left">
    <div>
      <MenuButton
        class="flex items-center justify-center"
        @mouseover="(e) => hoverPopover(e, open)"
        @click="(e) => e.stopPropagation()"
        @mouseleave="closePopover(close)">
        <slot name="content" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0">
      <MenuItems
        :class="[classes, width]"
        class="absolute z-20 justify-center px-2 py-1 bg-gray-900 text-white rounded-md shadow-lg max-w-lg word-wrap break-words outline-none">
        <slot name="title"></slot>
        <span :class="classesArrow"></span>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
export default {
  name: 'Tooltip'
};
</script>

<script setup>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { computed, defineProps, ref } from 'vue';

const props = defineProps({
  placement: {
    type: String,
    default: 'bottomRight'
  },
  hover: {
    type: Boolean,
    default: true
  },
  width: {
    type: String,
    default: 'w-max'
  }
});

const popoverHover = ref(false);
const popoverTimeout = ref(null);

const hoverPopover = (e, open) => {
  if (!props.hover) {
    return;
  }

  popoverHover.value = true;
  if (!open) {
    e.target.click();
  }
};
const closePopover = (close) => {
  if (!props.hover) {
    return;
  }

  popoverHover.value = false;
  if (popoverTimeout.value) {
    clearTimeout(popoverHover.value);
  }

  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value) {
      close();
    }
  }, 100);
};

const classes = computed(() => ({
  ['inset-center bottom-8']: props.placement === 'top',
  ['-left-2 bottom-8']: props.placement === 'topRight',
  ['-right-2 bottom-8']: props.placement === 'topLeft',
  ['inset-center top-8']: props.placement === 'bottom',
  ['-left-2 top-8']: props.placement === 'bottomRight',
  ['-right-2 top-8']: props.placement === 'bottomLeft'
}));

const classesArrow = computed(() => ({
  ['-bottom-3 inset-center arrow-down']: props.placement === 'top',
  ['-bottom-3 left-3.5 arrow-down']: props.placement === 'topRight',
  ['-bottom-3 right-3.5 arrow-down']: props.placement === 'topLeft',
  ['-top-3 inset-center arrow-up']: props.placement === 'bottom',
  ['-top-3 left-3.5 arrow-up']: props.placement === 'bottomRight',
  ['-top-3 right-3.5 arrow-up']: props.placement === 'bottomLeft'
}));
</script>
<style scoped>
.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: #1e2634 transparent transparent transparent;
}

.arrow-up {
  position: absolute;
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #1e2634 transparent;
}

.inset-center {
  right: 50%;
  transform: translate(50%);
}

.outline-none {
  outline: none !important;
}
</style>
