<template>
  <div class="h-screen flex flex-col pb-96 items-center justify-center bg-gray-50">
    <div class="text-center text-blue-400 mb-2 text-xl">
      {{ mainText }}
    </div>
    <div
      v-if="subText"
      class="text-center text-gray-800 text-lg">
      {{ subText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyEntity'
};
</script>

<script setup>
import { defineProps } from 'vue';

defineProps({
  mainText: {
    type: String,
    required: true
  },
  subText: {
    type: String,
    default: ''
  }
});
</script>
