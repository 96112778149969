<template>
  <div v-if="$slots['label']">
    <slot name="label" />
  </div>

  <h1
    v-if="label"
    class="mr-2 whitespace-nowrap"
    :class="props.customInputClass ? 'text-gray-600' : 'text-gray-700'">
    {{ label }}
  </h1>
  <div
    :data-id="dataId"
    :class="[
      'relative',
      {
        'w-full': !$slots['button']
      }
    ]">
    <div
      v-if="$slots['button']"
      ref="mainInput"
      @click="toggleDropdown">
      <slot name="button" />
    </div>
    <template v-else>
      <div
        ref="mainInput"
        class="relative box-border flex items-center pl-1 py-2 h-8 border rounded text-sm"
        :class="[customInputClass || classes, dataIsAbsent ? 'border-alert-color' : 'border-gray-300']"
        @click="toggleDropdown">
        <div
          v-if="visibleSelectedValues?.length === 0 || disabled"
          class="text-gray-500 ml-1">
          {{ placeholderText }}
        </div>
        <div
          v-if="!disabled"
          ref="chip"
          class="flex mr-1">
          <div
            v-for="item in visibleSelectedValues"
            :key="item"
            ref="chipsContainer"
            class="flex inline-flex items-center bg-gray-100 text-blue-400 pl-2 pr-2 rounded-full">
            <div
              class="relative"
              @mouseover="(e) => (hoverChip = true)"
              @mouseleave="hoverChip = false">
              <div class="flex w-full items-center whitespace-nowrap overflow-hidden">
                <span
                  class="w-full overflow-hidden whitespace-nowrap overflow-ellipsis"
                  :style="{ maxWidth: `calc(${data.filterMaxOffsetWidth}px - 95px)` }">
                  {{ getOptionLabel(item) }}
                </span>
              </div>
              <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-1 opacity-0">
                <div
                  v-if="hoverChip"
                  class="fixed z-10 ml-10 w-content max-w-sm transform -translate-x-12 translate-y-2 px-1 sm:px-0 lg:max-w-3xl">
                  <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="relative bg-white p-7 lg:grid-cols-1 text-gray-700 text-base">
                      <div>
                        {{ getOptionLabel(item) }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <button
              v-if="showRemoveOptionButton"
              type="button"
              class="relative flex items-center justify-center rounded-full bg-white text-gray-500 focus:outline-none font-bold left-1"
              @click="removeOption(item)">
              <Icon
                class="text-xs h-4 w-4 items-center pl-0.5 cursor-pointer text-blue-400"
                icon-name="close" />
            </button>
          </div>
        </div>
        <div
          v-show="remainingSelectedValuesCount > 0 && !data.selectedAll && !disabled"
          class="relative z-index-5">
          <div class="relative">
            <div
              class="text-blue-400"
              @mouseover="(e) => (hoverRemaining = true)"
              @mouseleave="hoverRemaining = false">
              <span>+{{ remainingSelectedValuesCount }}</span>
            </div>

            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0">
              <div
                v-if="hoverRemaining"
                class="fixed z-30 ml-2 w-content max-w-sm -translate-x-1/2 transform translate-y-2 px-1 sm:px-0 lg:max-w-3xl">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div class="relative bg-white p-7 lg:grid-cols-1 text-gray-700 text-base">
                    <div>
                      {{ remainingOptionsLabel }}
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div
          class="absolute right-0 transform transition-transform duration-300 items-center"
          @click="() => toggleDropdown">
          <icon
            :class="[
              'text-base mx-2 text-gray-600',
              {
                'cursor-pointer': !disabled,
                'opacity-50': disabled
              }
            ]"
            :icon-name="dropdownOpen ? 'chevron-up' : 'chevron'" />
        </div>
      </div>
      <div
        v-show="dataIsAbsent && !dropdownOpen"
        class="absent-dropdown-warning mt-2">
        {{ t(absentDataWarning) }}
      </div>
    </template>
    <ul
      v-show="dropdownOpen"
      ref="dropdownList"
      :class="[
        'absolute z-20 mt-1 bg-white border border-gray-300 text-gray-700 rounded shadow-lg max-h-80 overflow-auto min-w-full',
        {
          'w-full': !$slots['button'] && !wideDropdown,
          'w-64': $slots['button'] && !wideDropdown,
          'w-content': wideDropdown,
          'whitespace-nowrap': wideDropdown
        }
      ]">
      <li
        v-if="withSearch && optionsExists"
        class="p-4 pb-2">
        <div class="relative">
          <input
            ref="searchInput"
            v-model="searchTerm"
            data-id="search_input"
            type="text"
            class="relative box-border flex w-full items-center px-2 py-1 h-8 border rounded border-gray-300 text-sm focus:border-blue-300 focus:ring-2 focus:ring-blue-200"
            :placeholder="t('SEARCH.PLACEHOLDER')" />
          <icon
            class="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600 text-lg"
            icon-name="search" />
        </div>
      </li>
      <li
        v-if="filteredOptions?.length === 0"
        ref="noMatchesOption"
        class="px-4 pt-1 pb-2 text-sm">
        {{ t('MULTIDROPDOWN.NO_RESULTS') }}
      </li>
      <li
        v-show="filteredOptions?.length === props.options.length && props.withAll && optionsExists"
        class="px-4 py-2 cursor-pointer hover:bg-gray-100 hover:text-blue-500 text-sm"
        :class="!withSearch ? 'pt-4 pb-4' : 'mb-2'"
        @click="toggleAll">
        <input
          type="checkbox"
          :checked="allSelected"
          :disabled="!props.allowEmpty && props.options.length === 1"
          class="relative rounded-sm bg-white border-gray-400 text-blue-400 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2" />
        <span>{{ allPlaceholderName }}</span>
      </li>
      <div
        v-show="filteredOptions?.length === props.options.length && props.withAll && optionsExists"
        class="border-b border-gray-200 mr-4 ml-4 mb-2"></div>
      <li
        v-for="option in filteredOptions"
        :key="option.value"
        class="px-4 py-2 cursor-pointer hover:bg-gray-100 hover:text-blue-500 text-sm"
        @click="toggleOption(option)">
        <div class="flex items-center">
          <input
            type="checkbox"
            :checked="isSelected(option.value)"
            :value="option.value"
            :disabled="(!props.allowEmpty && selectedValues.length === 1 && isSelected(option.value)) || loading"
            class="mr-2 rounded-sm bg-white border-gray-400 text-blue-400 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
            @change="onCheck" />
          <span
            v-if="!withBadge"
            class="overflow-hidden overflow-ellipsis">
            {{ option.label }}
          </span>
          <div
            v-else
            class="w-36">
            <badge :type="option.type">{{ option.value }}</badge>
          </div>
          <Tooltip
            v-if="withTooltip && !option.supportRates"
            class="ml-auto"
            placement="topLeft">
            <template #content>
              <icon
                :icon-name="BringgFontIcons.Info"
                class="text-m text-grey-400" />
            </template>
            <template #title>
              <span class="font-semibold text-sm">
                {{ t('SHIPPING_RULES.RATES_SUPPORT_INFO_TEXT') }}
              </span>
            </template>
          </Tooltip>
        </div>
      </li>
      <div
        v-if="props.withAll"
        class="h-2"></div>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect'
};
</script>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { isEqual } from 'lodash';
import { computed, defineProps, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

import Badge from '@/components/atoms/Badge';
import Icon from '@/components/atoms/Icon';
import Tooltip from '@/components/atoms/ToolTip';

const { t } = useTranslation();

const ALL_VALUE = 'All';

const props = defineProps({
  placeholderText: {
    type: String,
    required: false,
    default: 'PLACEHOLDER_TEXT'
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  options: {
    type: Array,
    required: true
  },
  savedFiltersPath: {
    type: Array,
    default: () => null
  },
  value: {
    type: Array,
    required: false,
    default: null
  },
  withBadge: {
    type: Boolean,
    default: false
  },
  withAll: {
    type: Boolean,
    default: true
  },
  withSearch: {
    type: Boolean,
    default: false
  },
  allPlaceholderName: {
    type: String,
    required: false,
    default: 'APP_PLACEHOLDER_NAME'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  wideDropdown: {
    type: Boolean,
    default: false
  },
  allowEmpty: {
    type: Boolean,
    default: true
  },
  customInputClass: {
    type: String,
    default: ''
  },
  dataId: {
    type: String,
    default: ''
  },
  withTooltip: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  absentDataWarning: {
    type: String,
    required: false,
    default: 'MULTIDROPDOWN.ABSENT_DATA_WARNING'
  }
});

const emit = defineEmits(['select']);

const store = useStore();

const touched = ref(false);
const searchTerm = ref('');
const dropdownOpen = ref(false);
const selectedValues = ref([]);
const dropdownList = ref(null);
const searchInput = ref(null);
const mainInput = ref(null);
const hoverChip = ref(false);
const hoverRemaining = ref(false);
const noMatchesOption = ref(null);
const chipsContainer = ref(null);
const data = reactive({
  selectedAll: false,
  filterMaxOffsetWidth: null
});

const allIsSelected = computed(() => selectedValues.value.length === props.options.length);

const optionsExists = computed(() => props.options.length > 0);

const savedFilters = computed(() => {
  const [entity, filterName] = props.savedFiltersPath;

  return store.state.users.userSettings?.filters?.[entity]?.[filterName] || [];
});

const filteredOptions = computed(() => {
  const term = searchTerm.value.toLowerCase();

  return props?.options?.filter((option) => option?.label?.toLowerCase().includes(term));
});

const visibleSelectedValues = computed(() => {
  if (!optionsExists.value) {
    return [];
  }

  return data.selectedAll ? [ALL_VALUE] : selectedValues.value.slice(0, 1);
});

const remainingOptionsLabel = computed(() =>
  selectedValues.value
    .slice(1)
    .map((value) => {
      const option = props.options.find((option) => isEqual(option.value, value));

      return option ? option.label : '';
    })
    .join(', ')
);

const remainingSelectedValuesCount = computed(() => Math.max(0, selectedValues.value.length - 1));

const showRemoveOptionButton = props.allowEmpty || (remainingSelectedValuesCount > 0 && !data.selectedAll);

const dataIsAbsent = computed(() => {
  if (props.allowEmpty || !touched.value) {
    return false;
  }

  return selectedValues.value.length === 0;
});

const handleClickOutside = (event) => {
  if (
    !dropdownList?.value.contains(event.target) &&
    !mainInput?.value.contains(event.target) &&
    !chipsContainer?.value?.some((chip) => chip.contains(event.target))
  ) {
    searchTerm.value = '';
    dropdownOpen.value = false;
  }
};

const hasChosenAll = (value) => props.value && value.length === props.options.length;

const handleInitialValues = (value) => {
  data.selectedAll = props.withAll && (value[0] === ALL_VALUE || hasChosenAll(value));

  selectedValues.value = data.selectedAll
    ? props.options.map((option) => option.value)
    : value.map((filter) => (isNaN(Number(filter)) ? filter : Number(filter)));
};

const updateFilterWidth = () => (data.filterMaxOffsetWidth = mainInput?.value?.offsetWidth);

onMounted(async () => {
  updateFilterWidth();

  window.addEventListener('resize', updateFilterWidth);

  document.addEventListener('click', handleClickOutside);

  if (props.savedFiltersPath) {
    await store.dispatch('users/loadUserSettings');

    handleInitialValues(savedFilters.value);
  }

  if (props.value) {
    handleInitialValues(props.value);
  }
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

watch(
  () => props.value,
  (value) => {
    if (value) {
      handleInitialValues(value);
    }
  }
);

watch(
  () => props.options,
  (newValue, oldValue) => {
    if (!isEqual(newValue, oldValue) && props.savedFiltersPath) {
      handleInitialValues(savedFilters.value);
    }
  }
);

const isSelected = (value) => selectedValues.value.some((selectedValue) => isEqual(selectedValue, value));

const toggleDropdown = () => {
  if (props.disabled) {
    return;
  }
  touched.value = true;

  dropdownOpen.value = !dropdownOpen.value;
};

const allSelected = computed(() => selectedValues?.value?.length === props?.options?.length);

const selectAll = () => {
  touched.value = true;

  const updated = props.options.map((option) => option.value);

  data.selectedAll = true;
  emit('select', [ALL_VALUE], updated);
  selectedValues.value = updated;
};

const toggleAll = () => {
  touched.value = true;

  if (!allSelected.value) {
    selectAll();

    return;
  }

  let updated = [];

  if (!props.allowEmpty) {
    if (props.options.length === 1) {
      return;
    }
    updated = [props.options[0].value];
  }

  data.selectedAll = false;
  emit('select', updated, updated);
  selectedValues.value = updated;
};

const toggleOption = (option) => {
  if (props.loading) {
    return;
  }

  touched.value = true;

  let updated;

  const checked = selectedValues.value.some((selectedValue) => isEqual(selectedValue, option.value));

  if (checked) {
    updated = selectedValues.value.filter((value) => !isEqual(value, option.value));
    if (!props.allowEmpty && updated.length === 0) {
      return;
    }

    data.selectedAll = false;

    emit('select', updated, updated);

    selectedValues.value = updated;
  } else {
    updated = [...selectedValues.value, option.value];
    selectedValues.value = updated;

    if (props.withAll && allIsSelected.value) {
      data.selectedAll = true;
      emit('select', [ALL_VALUE], updated);
    } else {
      data.selectedAll = false;
      emit('select', updated, updated);
    }
  }
};

const removeOption = (value) => {
  touched.value = true;

  let updated;

  if (value === ALL_VALUE) {
    data.selectedAll = false;
    updated = [];
  } else {
    updated = selectedValues.value.filter((option) => !isEqual(option, value));
  }

  emit('select', updated, updated);

  selectedValues.value = updated;
};

const onCheck = (event) => {
  touched.value = true;

  event.target.checked = selectedValues.value.some((selectedValue) => {
    const checkedValue = isNaN(Number(event.target._value)) ? event.target._value : Number(event.target._value);

    return isEqual(selectedValue, checkedValue);
  });
};

const getOptionLabel = (value) => {
  const option = [...props.options, { value: ALL_VALUE, label: props.allPlaceholderName }].find(
    (option) => option.value === Number(value) || option.value === value || isEqual(option.value, value)
  );

  return option?.label || 'Deleted';
};

const classes = computed(() =>
  props.disabled
    ? 'opacity-50 bg-gray-100'
    : 'cursor-pointer focus:border-blue-300 focus:ring-2 focus:ring-blue-200 bg-white'
);

const touch = () => (touched.value = true);

defineExpose({ selectAll, touch });
</script>

<style lang="scss">
.absent-dropdown-warning {
  color: var(--color-danger-600, #d93a17);
  font-family:
    Open Sans,
    serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
}

.border-alert-color {
  border-color: var(--color-danger-400, #f26c42);
}
</style>
