<template>
  <teleport
    v-if="isMounted && createShipmentEnabled"
    to="#additionalItems">
    <router-link
      :to="{ name: 'create-shipment' }"
      class="text-sm font-semibold text-blue-400">
      {{ t('SHIPMENTS.CREATE_CUSTOM_SHIPMENT') }}
    </router-link>
  </teleport>
  <div
    data-id="shipments-filters"
    class="flex justify-between bg-white pb-3.5 pt-3">
    <div class="ml-11 flex justify-left flex-wrap gap-5">
      <div class="flex items-center w-90">
        <b-multi-select
          data-id="locations-filter"
          :options="locations"
          :label="t('FILTER.LOCATIONS.NAME')"
          :saved-filters-path="['fulfillments', 'location']"
          with-search
          wide-dropdown
          :all-placeholder-name="t('FILTER.LOCATIONS.PLACEHOLDER_NAME')"
          :placeholder-text="t('FILTER.LOCATIONS.PLACEHOLDER_TEXT')"
          @select="handleSelectLocations" />
      </div>
      <div class="flex items-center w-65">
        <b-multi-select
          data-id="status-filter"
          :options="mappedStatuses"
          :label="t('FILTER.STATUS.NAME')"
          :saved-filters-path="['shipments', 'status']"
          :placeholder-text="t('FILTER.STATUS.PLACEHOLDER_TEXT')"
          :all-placeholder-name="t('FILTER.STATUS.PLACEHOLDER_NAME')"
          with-badge
          :with-all="false"
          :disabled="locationsIsEmpty"
          @select="handleSelectStatuses" />
      </div>
      <div class="flex items-center w-100">
        <b-multi-select
          data-id="carrier-service"
          :options="shippingAccountsOptions"
          :label="t('FILTER.CARRIER_SERVICE.NAME')"
          :loading="carrierServiceFilterLoading"
          with-search
          wide-dropdown
          :with-all="false"
          :saved-filters-path="['shipments', 'carrier_service']"
          :all-placeholder-name="t('FILTER.TYPE.PLACEHOLDER_NAME')"
          :placeholder-text="t('FILTER.TYPE.PLACEHOLDER_TEXT')"
          @select="handleCarrierService" />
      </div>
      <div class="flex items-center w-65">
        <b-multi-select
          data-id="type-filter"
          :options="mappedFulfillmentTypes"
          :label="t('FILTER.TYPE.NAME')"
          :with-all="false"
          :saved-filters-path="['shipments', 'delivery_type']"
          :all-placeholder-name="t('FILTER.TYPE.PLACEHOLDER_NAME')"
          :placeholder-text="t('FILTER.TYPE.PLACEHOLDER_TEXT')"
          :disabled="locationsIsEmpty"
          @select="handleSelectDeliveryTypes" />
      </div>
    </div>

    <div class="mr-7 w-96 ml-5">
      <search-input
        :search-query="filterData.searchQuery"
        @update-search-query="search"></search-input>
    </div>
  </div>

  <empty-entity
    v-if="locationsIsEmpty"
    :main-text="t('FULFILLMENTS.EMPTY_PAGE_MAIN_TEXT')"
    :sub-text="t('SHIPMENTS.EMPTY_PAGE_SUB_TEXT')" />
  <div
    v-else
    class="pl-10 pr-6 pt-6 flex h-full flex-col overflow-hidden">
    <alert
      v-if="error"
      class="mb-8"
      type="danger"
      :close="true"
      @close="clearError">
      {{ t(error) }}
    </alert>

    <b-table
      class="shipments"
      :loading="loading"
      :fields="fields"
      :data="shipments"
      pagination
      :page="pageSettings.page"
      :limit="pageSettings.limit"
      :total="total"
      :last-page="pageSettings.page * pageSettings.limit >= total"
      row-key="id"
      customize-columns
      with-save-customization
      entity-name="shipments"
      last-update
      @update:page-settings="updatePageSettings">
      <template #header(delivery_cost)="{ column }">{{ column.label }}</template>
      <template #cell(tracking_number)="{ row }">
        <div
          v-if="row?.id && row?.order_id && row?.fulfillment_id"
          @click.capture="
            navigateTo(
              {
                name: 'shipment-details',
                params: { id: row.id, orderId: row.order_id, fulfillmentId: row.fulfillment_id }
              },
              pageSettings
            )
          ">
          <router-link
            :to="{
              name: 'shipment-details',
              params: {
                id: row.id,
                orderId: row.order_id,
                fulfillmentId: row.fulfillment_id
              }
            }"
            class="text-blue-400 hover:text-blue-600 cursor-pointer">
            {{ row.tracking_number || 'n/a' }}
          </router-link>
        </div>
      </template>
      <template #cell(source_id)="{ row }">
        <template v-if="row?.source_id?.length > 24">
          <tooltip
            width="w-72"
            placement="top"
            :hover="false">
            <template #content>
              <div class="max-w-40 truncate hover:text-clip">{{ row.source_id }}</div>
            </template>
            <template #title>
              {{ row.source_id }}
            </template>
          </tooltip>
        </template>
        <template v-else>
          <div class="max-w-40 truncate hover:text-clip">{{ row.source_id }}</div>
        </template>
      </template>
      <template #cell(created_at)="{ row }">
        {{ formatCreatedAtColumn(row) }}
      </template>
      <template #cell(deliver_by)="{ row }">
        {{ formatDeliveredDateColumn(row) }}
      </template>
      <template #cell(delivery_cost)="{ row }">
        {{ formatDeliveryCostColumn(row) }}
      </template>
      <template #cell(carrier)="{ row }">
        <div class="w-36 break-words">{{ formatCarrierColumn(row) }}</div>
      </template>
      <template #cell(return)="{ row }">
        <div class="text-center text-base">
          <icon
            v-if="row.return"
            icon-name="selected" />
          <icon
            v-else
            icon-name="minus"
            aria-hidden="true" />
        </div>
      </template>
      <template #cell(packages_number)="{ row }">
        {{ calculatePackagesNumber(row) }}
      </template>
      <template #cell(status)="{ row }">
        <div class="w-max">
          <badge :type="statusBadgeTypeMapping[row.status]">
            {{ t(row.status) }}
          </badge>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'Shipments'
};
</script>

<script setup>
import format from 'date-fns/format';
import { useTranslation } from 'i18next-vue';
import { compact, omit } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import Tooltip from '@/components/atoms/ToolTip/index.vue';
import BMultiSelect from '@/components/molecules/MultiSelect';
import { useFiltersAndSearch, useNavigateTo, usePagination } from '@/composables';

import { FulfillmentType, ShipmentStatus, statusBadgeTypeMapping } from '../../../enums';
import { getCarrierNameByShippingAccount } from '../../../utils';
import Alert from '../../atoms/Alert';
import Badge from '../../atoms/Badge';
import Icon from '../../atoms/Icon';
import SearchInput from '../../molecules/Search';
import BTable from '../../organisms/Table';
import EmptyEntity from '../EmptyEntity';

const props = defineProps({
  page: {
    type: Number,
    required: true
  },
  limit: {
    type: [Number, null],
    required: true
  },
  timeout: {
    type: Number,
    default: 100
  },
  search: {
    type: String,
    default: ''
  }
});

const store = useStore();
const { t } = useTranslation();
const { pageSettings, updatePage } = usePagination('shipments/filterShipments');
const {
  filterData,
  search,
  handleSelectStatuses,
  setInitialValues,
  handleSelectDeliveryTypes,
  handleSelectLocations,
  handleCarrierService,
  locationsIsEmpty
} = useFiltersAndSearch({
  props,
  updatePage,
  clearEntityAction: 'shipments/clearShipments',
  entity: 'shipments',
  pageSettings
});
const navigateTo = useNavigateTo();
const isMounted = ref(false);

onMounted(async () => {
  const { page, limit, search } = props;

  isMounted.value = true;

  await store.dispatch('shippingAccounts/loadShippingAccounts');

  await setInitialValues({ page, limit, search });
});

const createShipmentEnabled = computed(() => {
  const isPacker = store.getters['auth/isPacker'];
  const isAdmin = store.getters['auth/isAdmin'];

  return isPacker || isAdmin;
});

const updatePageSettings = (pageSettings) => {
  updatePage({
    ...pageSettings,
    filters: { shipments: omit(filterData, 'searchQuery') },
    search: filterData.searchQuery
  });
};

const shipments = computed(() => store.state.shipments.items);
const total = computed(() => store.state.shipments.total);
const loading = computed(
  () => store.state.shipments.loading || store.state.shippingAccounts.loading || store.state.shippingLocations.loading
);

const carrierServiceFilterLoading = computed(() => store.state.users.loading || loading.value);

const error = computed(() => store.state.shipments.error);

const locations = computed(() => {
  const locations = Array.from(store.state.shippingLocations.items.values()).map((location) => {
    const { id, name } = location;

    return {
      value: id,
      label: name
    };
  });

  return [{ value: 'null', label: 'No locations' }, ...locations];
});

const fields = computed(() => [
  { key: 'tracking_number', label: t('SHIPMENTS.TRACKING_NUMBER_FIELD') },
  { key: 'carrier_reference_number', label: t('SHIPMENTS.REFERENCE_FIELD') },
  { key: 'source_id', label: 'FIELDS.SOURCE_ID_FIELD' },
  { key: 'created_at', label: t('FIELDS.CREATED_FIELD') },
  { key: 'recipient_name', label: t('SHIPMENTS.RECIPIENT_NAME_FIELD') },
  { key: 'deliver_by', label: t('SHIPMENTS.DELIVERY_FIELD') },
  { key: 'delivery_cost', label: t('SHIPMENTS.DELIVERY_COST_FIELD') },
  { key: 'packages_number', label: t('SHIPMENTS.NUMBER_OF_PACKAGES_FIELD') },
  { key: 'carrier', label: t('SHIPMENTS.CARRIER_FIELD') },
  { key: 'return', label: t('SHIPMENTS.IS_RETURN_FIELD') },
  { key: 'status', label: t('SHIPMENTS.STATUS_FIELD') }
]);

const mappedStatuses = Object.entries(omit(statusBadgeTypeMapping, ShipmentStatus.PartiallyFulfilled)).map(
  ([key, value]) => ({
    label: key,
    type: value,
    value: key
  })
);

const shippingAccountsOptions = computed(() => {
  const shippingAccounts = store.getters['shippingAccounts/carriers'];
  const serviceTypes = store.getters['carriers/serviceTypes'];
  const seenCombinations = new Set();

  return shippingAccounts.flatMap((shippingAccount) => {
    const baseName =
      shippingAccount.carrier === 'custom'
        ? shippingAccount.name
        : `${shippingAccount.carrier_name} [${shippingAccount.name}]`;

    return serviceTypes[shippingAccount.carrier]
      .filter((service) => shippingAccount?.extras?.selected_services?.includes(service.value))
      .map((service) => {
        const idServiceCombo = `${shippingAccount.id}-${service.value}`;

        if (!seenCombinations.has(idServiceCombo)) {
          seenCombinations.add(idServiceCombo);

          return {
            label: `${baseName} / ${service.label}`,
            value: { shipping_account_id: String(shippingAccount.id), service: service.value }
          };
        }

        return null;
      })
      .filter(Boolean);
  });
});

const mappedFulfillmentTypes = Object.entries(FulfillmentType).map(([key, value]) => ({
  label: key,
  value
}));

const formatCarrierColumn = ({ service, shipping_account_id }) => {
  const shippingAccount = store.getters['shippingAccounts/getShippingAccountById'](shipping_account_id);

  const carrierName = getCarrierNameByShippingAccount(shippingAccount, store.getters['carriers/carrierNames']);
  const serviceName = store.getters['carriers/getServiceTypeNameBySlugAndCarrier'](service, shippingAccount.carrier);

  return compact([carrierName, serviceName]).join(', ');
};

const clearError = () => store.commit('orders/clearError');

const formatCreatedAtColumn = (row) => row.created_at && format(Date.parse(row.created_at), 'MMM. d, Y, HH:mm');
const formatDeliveredDateColumn = (row) => row.deliver_by && format(Date.parse(row.deliver_by), 'MMM. d, Y');
const formatDeliveryCostColumn = (row) => '$ ' + (row.delivery_cost / 100 || 0).toFixed(2);

const calculatePackagesNumber = (row) => row.packages_count;
</script>
