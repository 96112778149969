<template>
  <vue3-lottie
    :animation-data="spinner"
    :style="{ width: width + 'px', height: height + 'px' }" />
</template>

<script>
export default {
  name: 'Spinner'
};
</script>

<script setup>
import { Vue3Lottie } from 'vue3-lottie';

import spinner from '../../../../public/spinner.json';

defineProps({
  width: {
    type: Number,
    default: 50
  },
  height: {
    type: Number,
    default: 50
  }
});
</script>
