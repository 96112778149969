<template>
  <popover class="relative">
    <popover-button
      class="outline-none flex gap-1 text-left"
      :class="!required || isFilled ? 'text-blue-400' : 'text-red-600'"
      @click="validate">
      <span
        v-if="required && !isFilled"
        class="relative">
        <!-- TODO: it's a little hack to add a dark red color. If you can improve it please fix -->
        <div class="absolute bg-red-500 w-1 h-2 left-1 top-2" />
        <b-icon
          icon-name="promped-message"
          class="relative text-xm text-red-100"></b-icon>
      </span>
      {{ value.name }}
    </popover-button>
    <teleport to="#productsTable">
      <popover-panel
        :style="`top: ${index * 3 + 5}rem;`"
        class="absolute left-20 z-10 outline-none text-xs">
        <div class="flex flex-col gap-1 px-3.5 py-4 bg-white rounded custom-shadow">
          <div class="flex gap-2 justify-between items-center">
            <span
              class="w-32 text-xs"
              :class="handleSpanClass('description')">
              {{ t('PRODUCT_DETAILS_MODAL.DESCRIPTION_FIELD') }}
            </span>
            <b-input
              :disabled="disabled"
              error-styles="text-xs"
              name="description"
              input-class="w-40 h-8 sm:text-xm" />
          </div>
          <div class="flex gap-2 justify-between items-center">
            <span
              class="w-32 text-xs"
              :class="handleSpanClass('price')">
              {{ t('PRODUCT_DETAILS_MODAL.PRICE_FIELD') }}
            </span>
            <b-input
              :disabled="disabled"
              error-styles="text-xs"
              :min="0"
              name="price"
              type="number"
              input-class="w-40 h-8 sm:text-xm" />
          </div>
          <div class="flex gap-2 justify-between items-center">
            <span
              class="w-32 text-xs"
              :class="handleSpanClass('harmonized_code')">
              {{ t('PRODUCT_DETAILS_MODAL.HARMONIZED_CODE_FIELD') }}
            </span>
            <b-input
              :disabled="disabled"
              error-styles="text-xs"
              input-class="w-40 h-8 sm:text-xm"
              name="harmonized_code" />
          </div>
          <div class="flex gap-2 justify-between items-center">
            <span
              class="text-xs"
              :class="handleSpanClass('country_of_origin')">
              {{ t('PRODUCT_DETAILS_MODAL.COUNTRY_FIELD') }}
            </span>
            <b-select
              :value="values.country_of_origin || props.value.country_of_origin"
              name="country_of_origin"
              :disabled="disabled"
              error-styles="text-xs"
              :options="countryOptions"
              size="small"
              class="w-40"></b-select>
          </div>
          <div>
            <b-button
              :disabled="disabled || !isFormValid()"
              class="w-full h-8 mt-3 py-1 text-base"
              @click="save">
              {{ t('SAVE_BUTTON') }}
            </b-button>
          </div>
        </div>
      </popover-panel>
    </teleport>
  </popover>
</template>

<script>
export default {
  name: 'ModalProductDetails'
};
</script>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useTranslation } from 'i18next-vue';
import { useForm } from 'vee-validate';
import { computed, defineEmits, defineProps } from 'vue';
import { number, object, string } from 'yup';

import countryMapping from '../../../assets/js/countries.json';
import BSelect from '../../atoms/BSelect';
import BButton from '../../atoms/Button';
import BIcon from '../../atoms/Icon';
import BInput from '../../atoms/Input';

const { t } = useTranslation();

const countryOptions = Object.keys(countryMapping).map((value) => ({ value, label: countryMapping[value] }));

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    default: 1
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const schema = computed(() => {
  const rules = {
    harmonized_code: string()
      .transform((_, val) => val || null)
      .nullable()
      .test('isNumeric', t('PRODUCT_DETAILS_MODAL.ERR_TYPE_NUM'), (val) => !val || /^[0-9]*$/.test(val))
      .test('length', t('PRODUCT_DETAILS_MODAL.ERR_LEN_8'), (val) => !val || val.length === 8),
    description: string().nullable(),
    price: number().positive(t('PRODUCT_DETAILS_MODAL.ERR_POSITIVE')),
    country_of_origin: string()
  };

  if (props.required) {
    for (const ruleKey in rules) {
      rules[ruleKey] = rules[ruleKey].required(t('PRODUCT_DETAILS_MODAL.ERR_REQ'));
    }
  }

  return object({
    ...rules
  });
});

const { values, errors, handleSubmit, validate } = useForm({
  initialValues: props.value,
  validationSchema: schema
});

const emit = defineEmits(['update:value']);

const isFormValid = () => {
  return Object.keys(errors.value).length === 0;
};

const isFilled = computed(
  () => props.value.description && props.value.harmonized_code && props.value.country_of_origin && props.value.price
);

const handleSpanClass = (name) => (errors.value[name] ? 'text-red-600' : 'text-gray-600');

const save = handleSubmit((values) => {
  emit('update:value', { ...values });
});
</script>

<style>
.custom-shadow {
  box-shadow:
    0 2px 8px rgba(0, 51, 80, 0.04),
    0 16px 24px rgba(0, 51, 80, 0.16);
}
</style>
