import config from '../../config';
import { executeRequest } from '../../utils';

const state = {
  items: new Map(),
  loading: false,
  error: '',
  confirmationUrl: ''
};

const getters = {
  items(state) {
    return Array.from(state.items.values());
  }
};

const actions = {
  async ensureInstalledOnShop(options, data) {
    const request = {
      request: {
        method: 'GET',
        url: `/deliveryhub-orders-service/v1/integrations/shopify/auth/installed`,
        params: { shop: data.shop }
      },
      options,
      errorMessage: 'SHOPIFY_ENSURE_INSTALLED_ON_SHOP_FAIL'
    };

    await executeRequest(request, () => true);
  },

  async createSubscription(options, data) {
    const request = {
      request: {
        method: 'POST',
        url: `/deliveryhub-orders-service/v1/integrations/shopify/subscriptions`,
        data: {
          shop: options.rootState.auth.initQuery.shop,
          returnUrl: `https://admin.shopify.com/store/${options.rootState.auth.initQuery.shop.split('.')[0]}/apps/bringg-delivery-hub`
        }
      },
      options,
      errorMessage: 'SHOPIFY_CREATE_SUBSCRIPTION_FAIL',
      waitForCallback: true
    };

    await executeRequest(request, (response) => {
      options.commit('setConfirmationUrl', response.data.confirmation_url);
    });
  }
};

const mutations = {
  clearError(state) {
    state.error = '';
  },
  setConfirmationUrl(state, url) {
    state.confirmationUrl = url;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
