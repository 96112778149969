<template>
  <div>
    <label
      v-if="label || $slots.label"
      :for="id"
      class="block text-xs text-gray-600 mb-1">
      <slot name="label">
        {{ label }}
      </slot>
      <Tooltip
        v-if="tooltipLabel"
        placement="topRight">
        <template #content>
          <icon
            :icon-name="BringgFontIcons.Info"
            class="text-lg text-gray-600 ml-1" />
        </template>
        <template #title>
          <span class="font-semibold text-s">
            {{ tooltipLabel }}
          </span>
        </template>
      </Tooltip>
    </label>

    <div
      :id="id"
      class="flex text-sm flex-wrap items-center border p-1 w-72 bg-white w-150 rounded"
      :class="validationError ? 'focus:ring-red-500 focus:border-red-500 border-red-500' : 'border-gray-300'">
      <div
        v-for="chip in chips"
        :key="chip"
        class="flex inline-flex items-center bg-gray-100 text-blue-400 pl-2 pr-2 rounded-full m-1">
        {{ chip }}
        <button
          type="button"
          class="relative flex items-center justify-center rounded-full bg-white text-gray-500 focus:outline-none font-bold left-1"
          @click="removeChip(chip)">
          <Icon
            class="text-xs h-4 w-4 items-center pl-0.5 cursor-pointer text-blue-400"
            icon-name="close" />
        </button>
      </div>
      <input
        v-model="input"
        :data-id="dataId"
        autocomplete="off"
        class="flex-grow border-none outline-none p-1 py-0.5 text-sm placeholder-gray-400 ml-1 focus:bg-white text-gray-800"
        :placeholder="placeholderText"
        @input="handleInput"
        @keydown.enter="addChip" />
    </div>
    <div
      v-if="validationError"
      class="text-red-500 text-sm ml-1 mt-2">
      {{ validationErrorText }}
    </div>
  </div>
</template>

<script setup>
import { BringgFontIcons } from '@bringg/bringg-icons';
import { useTranslation } from 'i18next-vue';
import { computed, defineEmits, defineProps, ref, watch } from 'vue';

import Icon from '@/components/atoms/Icon';
import Tooltip from '@/components/atoms/ToolTip/index.vue';

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: ''
  },
  validationSchema: {
    type: Object,
    default: () => ({})
  },
  label: {
    type: String,
    default: ''
  },
  tooltipLabel: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  dataId: {
    type: String,
    default: ''
  }
});

const input = ref('');
const chips = ref(props.value);
const validationError = ref('');
const validationErrorText = computed(() => customErrors[validationError.value] || validationError.value);

const emit = defineEmits(['update:value']);

const customErrors = {
  WARN_TO_PRESS_ENTER: t('CHIP_INPUT.PRESS_ENTER_TO_ADD'),
  DUPLICATE_ERROR: t('CHIP_INPUT.DUPLICATE_ERROR')
};

const errorLabels = Object.keys(customErrors);

const isInputEmpty = computed(() => input.value === '');
const isDuplicate = computed(() => chips.value.includes(input.value));
const isErrorAcceptable = computed(() => !validationError.value || validationError.value === errorLabels[0]);

const validateInput = async () => {
  if (props.validationSchema) {
    try {
      await props.validationSchema.validate(input.value);

      validationError.value = isInputEmpty.value ? '' : errorLabels[0];
    } catch (err) {
      validationError.value = err.message;
    }
  }
};

const handleInput = () => {
  input.value = input.value.trim();

  validateInput();
};

const addChip = () => {
  if (!isInputEmpty.value && !isDuplicate.value && isErrorAcceptable.value) {
    chips.value = [...chips.value, input.value];

    input.value = '';

    emit('update:value', chips.value);
  } else if (isDuplicate.value) {
    validationError.value = errorLabels[1];
  }
};

const removeChip = (chip) => {
  chips.value = chips.value.filter((c) => c !== chip);

  emit('update:value', chips.value);
};

const placeholderText = computed(() => {
  return chips.value.length === 0 ? props.placeholder : '';
});

watch(input, validateInput);
</script>
