<template>
  <returns-template
    v-model:filters="data.returnFilters"
    :loading="data.loading"
    :return-method-options="data.returnMethodOptions"
    :returns="data.returns"
    :return-page="data.page"
    :return-last-page="data.isLastPage"
    @prev="loadReturns"
    @next="loadReturns"
    @update:filters="loadReturns(1)">
    <template #message>
      <alert
        v-if="data.message"
        :type="data.messageStatus"
        :close="true"
        @close="data.message = ''">
        {{ data.message }}
      </alert>
    </template>
  </returns-template>
</template>

<script>
export default {
  name: 'Returns'
};
</script>

<script setup>
import { useTranslation } from 'i18next-vue';
import { onMounted, reactive } from 'vue';

import client from '../../../api';
import { showFailMessage, withLoading } from '../../../utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Alert from '../../atoms/Alert';
import ReturnsTemplate from '../../templates/Returns';

const { t } = useTranslation();

const data = reactive({
  loading: false,
  message: '',
  messageStatus: '',
  returns: [],
  returnMethodOptions: [],
  page: 1,
  pageSize: 20,
  isLastPage: false,
  returnFilters: {
    start_date: null,
    end_date: null,
    return_method: '',
    search: '',
    customer_id: '',
    order_id: '',
    status: '',
    custom: ''
  }
});

onMounted(async () => {
  await withLoading(data, async () => {
    try {
      const {
        data: { return_methods: returnMethods }
      } = await client.getReturnMethods();

      data.returnMethodOptions = returnMethods.map((item) => ({ value: item.id, label: item.display_name }));
    } catch (e) {
      await showFailMessage(data, t('RETURN_METHODS_LOADING_FAIL'));
    }

    await loadReturns();
  });
});

const loadReturns = async (pageNum) => {
  if (pageNum) {
    data.page = pageNum;
  }

  try {
    const { data: returns } = await client.getReturns({
      page: pageNum || data.page,
      page_size: data.pageSize,
      ...data.returnFilters
    });

    data.isLastPage = !returns.length;

    if (!data.isLastPage || data.page === 1) {
      data.returns = returns;
    } else {
      data.page -= 1;
    }
  } catch (e) {
    await showFailMessage(data, t('RETURNS_LOADING_FAIL'));
  }
};
</script>
